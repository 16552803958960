import HumburgerDarkIcon from 'assets/icons/humburger-icon-dark.svg';
import HumburgerIcon from 'assets/icons/humburger-icon.svg';
import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import HamburgerIconAnimation from 'components/common/Icons/ver2/HamburgerIconAnimation';
import InformPopup from 'components/common/InformPopup';
import { preventOnClick } from 'components/common/func';
import {
  ACCOUNT_ACTIVATION_PREFIX,
  FORGOT_PASSWORD_PREFIX,
  HOME_URL,
  LOGIN_PREFIX,
  NAV_MENUS_UNAUTHENTICATE,
  ORDER_EMAIL,
  PRODUCT_LOGO,
  REGISTER_PREFIX,
  RESET_PASSWORD_PREFIX,
  pathsHideLoginResgisterButton,
} from 'constants';
import { NAV_MENUS_AUTHENTICATED } from 'constants/header/ver2';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import { FX_MAKE_PAYMENT_KEY } from 'hooks/fx/useFxAddTransaction';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import usePage from 'hooks/usePage';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ButtonLinkCommon from '../../../ButtonLinkCommon';
import CustomLangDropdown from '../../components/CustomLangDropdown';
import CustomNotificationDropdownMobile from '../../components/CustomNotificationDropdown/mobile';

const HeaderMobile = () => {
  const { t, locate } = useLang();
  const { isAuthenticated, logout } = useAuth();
  const { theme, isHHMTTheme } = useTheme();
  const navigate = useNavigate();

  const { state } = useStore();
  const { notifications, isPending } = state || {};

  const { pathname } = useLocation();
  const isLoginButtonActive = pathname.includes(LOGIN_PREFIX);
  const isRegisterButtonActive = pathname.includes(REGISTER_PREFIX);

  const [isShowLogoutPopup, setShowLogoutPopup] = useState(false);
  const [isShowNotificationsNavbar, setShowNotificationsNavbar] =
    useState(false);
  const [isOpenNavMenu, setOpenNavMenu] = useState(false);

  const isHeaderHidden = pathsHideLoginResgisterButton?.find((path) => {
    return pathname === path;
  });

  const {
    isAddReceiverPage,
    isAddTransactionPage,
    isFxAddTransactionPage,
    isFxAddTransactionAsGuestPage,
    isFxLoginPage,
  } = usePage();
  const isNavLinkAuthenticatedHidden =
    (isAuthenticated &&
      (isAddReceiverPage || isAddTransactionPage || isFxAddTransactionPage)) ||
    isAuthenticated;
  const isNavLinkUnAuthenticateHidden =
    !isAuthenticated && (isFxAddTransactionAsGuestPage || isFxLoginPage);

  const handleLogoutPopupToggle = () => {
    setShowLogoutPopup(!isShowLogoutPopup);
  };

  const handleNavbarMenuOnToggle = (isOpen) => {
    setOpenNavMenu(isOpen);
  };

  const handleOnClickANavLinkItem = (e, isPreventRedirect) => {
    if (isPreventRedirect) {
      preventOnClick(e);
      return;
    }
    Cookies.remove(MAKE_PAYMENT_KEY);
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);
  };

  return (
    <>
      <style>
        {`
          .header-mobile-offcanvas {
            width: 100%!important;
            height: fit-content;
            border: none!important;
            transition: transform .2s ease-in!important;
            margin-top: 70px;
            z-index: 1060;
          }
          .header-mobile-offcanvas-backdrop {
            opacity: 0.25!important;
          }
        `}
      </style>

      {isShowLogoutPopup && (
        <InformPopup
          isOpen={isShowLogoutPopup}
          content={t('popup_logout_confirm_desc')}
          buttonLabel1={t('button_no')}
          buttonLabel2={t('button_yes')}
          onClose={handleLogoutPopupToggle}
          onClick={() => {
            handleLogoutPopupToggle();

            document.getElementsByClassName('nav-menu-icon')[0].click();

            logout();
          }}
          isBackdropOverlap={true}
          zIndexModal={1101}
        />
      )}

      <NavbarStyled expand="xxl" onToggle={handleNavbarMenuOnToggle}>
        {isPending && <MaskNavbarDisable />}
        <NavbarBrand
          href={`${HOME_URL[theme]}?lang=${locate}`}
          onClick={() => {
            Cookies.remove(MAKE_PAYMENT_KEY);
            Cookies.remove(FX_MAKE_PAYMENT_KEY);
            Cookies.remove(ORDER_EMAIL);
          }}
        >
          <img
            src={PRODUCT_LOGO[theme]}
            className="d-inline-block align-top"
            alt={`${theme} logo`}
          />
        </NavbarBrand>
        <ButtonWrap>
          {isAuthenticated && (
            <CustomNotificationDropdownMobile
              isShowNavbar={isShowNotificationsNavbar}
              setShowNavbar={setShowNotificationsNavbar}
            />
          )}
          {!isHeaderHidden && !isAuthenticated && (
            <>
              {pathname === REGISTER_PREFIX && (
                <ButtonLinkCommon
                  href={LOGIN_PREFIX}
                  value={t('button_login')}
                  styles={{
                    marginRight: '10px',
                    height: '32px',
                    border: !isLoginButtonActive && 'var(--border-primary)',
                    display: isLoginButtonActive && 'none',
                    fontWeight: '600',
                  }}
                  color={
                    isLoginButtonActive
                      ? 'var(--root-header-text-active)'
                      : 'var(--c-secondary)'
                  }
                  background={
                    isLoginButtonActive
                      ? 'var(--root-header-btn-active)'
                      : 'var(--root-header-btn-active)'
                  }
                  isFill={true}
                />
              )}
              {(pathname === LOGIN_PREFIX ||
                pathname === RESET_PASSWORD_PREFIX ||
                pathname === FORGOT_PASSWORD_PREFIX ||
                pathname === ACCOUNT_ACTIVATION_PREFIX) && (
                <ButtonLinkCommon
                  href={REGISTER_PREFIX}
                  value={t('button_register')}
                  styles={{
                    marginRight: '10px',
                    height: '32px',
                    border: !isRegisterButtonActive && 'var(--border-primary)',
                    display: isRegisterButtonActive && 'none',
                  }}
                  color={
                    isRegisterButtonActive
                      ? 'var(--root-header-text-active)'
                      : 'var(--c-secondary)'
                  }
                  background={
                    isRegisterButtonActive
                      ? 'var(--root-header-btn-active)'
                      : 'var(--root-header-btn-active)'
                  }
                  isFill={true}
                />
              )}
            </>
          )}
          <CustomLangDropdown styles={{ marginRight: '0px' }} />
          <NavbarToggle
            aria-controls="offcanvasNavbar-expand-false"
            $isHHMTTheme={isHHMTTheme}
            className="nav-menu-icon"
          >
            <HamburgerIconAnimation
              isOpen={isOpenNavMenu}
              color={isHHMTTheme ? '#fff' : 'var(--ds-c-grey-dark)'}
            />
          </NavbarToggle>
        </ButtonWrap>

        <NavbarOffcanvas
          id="offcanvasNavbar-expand-false"
          aria-labelledby="offcanvasNavbarLabel-expand-false"
          placement="top"
          className="header-mobile-offcanvas"
          backdropClassName="header-mobile-offcanvas-backdrop"
          $isHidden={isNavLinkAuthenticatedHidden}
        >
          <OffcanvasBody>
            <NavStyled className="justify-content-end flex-grow-1 pe-3">
              {isAuthenticated &&
                NAV_MENUS_AUTHENTICATED.map((nav) => (
                  <NavLink
                    key={nav.href}
                    href={nav.href}
                    onClick={(e) => {
                      e.preventDefault();

                      document
                        .getElementsByClassName('nav-menu-icon')[0]
                        .click();

                      if (isShowNotificationsNavbar) {
                        document
                          .getElementsByClassName('notification-mobile-icon')[0]
                          .click();
                      }

                      Cookies.remove(MAKE_PAYMENT_KEY);
                      Cookies.remove(FX_MAKE_PAYMENT_KEY);
                      Cookies.remove(ORDER_EMAIL);

                      navigate(nav.href);
                    }}
                    $isHidden={isNavLinkAuthenticatedHidden}
                  >
                    {t(nav.text)}
                    <ArrowDownIcon
                      color="var(--ds-c-white)"
                      size={{ width: 14, height: 10 }}
                      styles={{
                        transform: 'rotate(270deg)',
                        position: 'absolute',
                        right: '32px',
                      }}
                    />
                  </NavLink>
                ))}
              {!isAuthenticated &&
                NAV_MENUS_UNAUTHENTICATE.map((nav) => (
                  <NavLink
                    key={nav.href}
                    href={`${HOME_URL[theme]}${nav.href}?lang=${locate}`}
                    onClick={(e) =>
                      handleOnClickANavLinkItem(
                        e,
                        isNavLinkUnAuthenticateHidden
                      )
                    }
                  >
                    {t(nav.text)}
                    <ArrowDownIcon
                      color="var(--ds-c-white)"
                      size={{ width: 14, height: 10 }}
                      styles={{
                        transform: 'rotate(270deg)',
                        position: 'absolute',
                        right: '32px',
                      }}
                    />
                  </NavLink>
                ))}
              {isAuthenticated && (
                <NavLink
                  onClick={handleLogoutPopupToggle}
                  style={{ color: 'var(--ds-c-red)' }}
                >
                  {t('button_logout')}
                </NavLink>
              )}
            </NavStyled>
          </OffcanvasBody>
        </NavbarOffcanvas>
      </NavbarStyled>
    </>
  );
};

const MaskNavbarDisable = styled.div`
  position: fixed;
  top: 0;
  z-index: 1066;
  width: 100%;
  margin-inline: -16px;
  background-color: transparent;
  height: 70px;
  padding: 0px;
  cursor: not-allowed;
`;

const NavbarStyled = styled(Navbar)`
  position: fixed;
  top: 0;
  z-index: 1065;
  width: 100%;
  margin: 0;
  background-color: var(--root-header-bg);
  box-shadow: var(--root-header-bs);
  height: 70px;
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavbarBrand = styled(Navbar.Brand)`
  display: flex;
  align-items: center;
  margin: 0;

  img {
    width: 138px;
    height: 39px;
    margin-right: 0px;
    object-fit: contain;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
`;

const NavbarToggle = styled(Navbar.Toggle)`
  padding: 0;
  border: none;

  &:focus {
    border: none;
    box-shadow: none;
  }

  .navbar-toggler-icon {
    background-image: ${(props) =>
      props.$isHHMTTheme
        ? `url(${HumburgerIcon})`
        : `url(${HumburgerDarkIcon})`};
    border-color: var(--ds-c-white);
    width: 48px;
  }
`;

const NavbarOffcanvas = styled(Navbar.Offcanvas)`
  height: ${(props) => (props.$isHidden ? '60px!important' : '30vh')};
`;

const OffcanvasBody = styled(Offcanvas.Body)`
  background: var(--ds-c-grey-dark);
  padding: 0px !important;
  overflow: unset;
`;
const NavStyled = styled(Nav)`
  padding-right: 0px !important;
`;
const NavLink = styled(Nav.Link)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  width: 100%;
  height: 60px;

  display: ${(props) => (props.$isHidden ? 'none' : 'flex')};
  align-items: center;
  padding-inline: 24px;
  background: var(--ds-c-grey-dark);

  &.active {
    color: #fff !important;
  }

  &:hover {
    color: #fff;
  }
`;

export default HeaderMobile;
