/* eslint-disable no-unused-vars */
import SizeBox from 'components/common/SizeBox';
import {
  DESKTOP_MIN_WIDTH,
  MOBILE_MIN_WIDTH,
  TABLET_MAX_WIDTH,
} from 'constants';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import BodyConditionsTime from './components/BodyConditionsTime';
import FooterActions from './components/FooterActions';
import HeaderAmountType from './components/HeaderAmountType';
import HeaderCodeUsage from './components/HeaderCodeUsage';
import HeaderOfferDesc from './components/HeaderOfferDesc';

export const DiscountModal = ({
  isOpen,
  discount,
  onClose,
  onClick,
  onApplyError,
  inactive,
  isBackdropOverlap,
  zIndexBackdropOverlap,
  zIndexModal = 0,
}) => {
  return (
    <>
      <style>
        {zIndexModal &&
          `
          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal {
              z-index: ${zIndexModal || '1101'};
            }
          }
        `}
        {isBackdropOverlap
          ? `
          .discount-modal-backdrop {
            z-index: ${zIndexBackdropOverlap || '1100'};
            background: #212121;
            opacity: 0.25!important;
            display: initial!important;
          }
        `
          : `
        .discount-modal-backdrop {
          background: #212121;
          opacity: 0.25!important;
          display: initial!important;
        }
      `}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={onClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="discount-modal-backdrop"
        centered
        $isOpen={isOpen}
      >
        <ModalHeader>
          <HeaderAmountType offer={discount} inactive={inactive} />
          <SizeBox height={10} />
          <HeaderOfferDesc offer={discount} />
          <HeaderCodeUsage offer={discount} />
          <Divider />
        </ModalHeader>
        <ModalBody>
          <BodyConditionsTime offer={discount} inactive={inactive} />
        </ModalBody>
        <ModalFooter>
          <FooterActions
            offer={discount}
            inactive={inactive}
            onClick={onClick}
            onApplyError={onApplyError}
            onClose={onClose}
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  padding-left: 0px !important;
  padding-right: 0px !important;
  transform: ${(props) => (props.$isOpen ? 'opacity: 1' : 'opacity: 0')};
  transition: 1s ease;

  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    & .modal-dialog {
      position: absolute;
      margin: 0;
      bottom: 0;
      height: fit-content;
      width: 100%;
      max-width: 100%;
      align-items: end;
      transition: ease-out 0.3s !important;
      transform: none;

      & .modal-content {
        padding: 16px;
        border-radius: 0px;
        box-shadow: none;
        background: var(--ds-c-white);
        width: 100%;
        height: fit-content;
        margin-inline: auto;
        border: none;
        border-radius: 8px 8px 0 0;
      }
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & .modal-dialog {
      transform: none;
      & .modal-content {
        padding: 16px;
        border-radius: 12px;
        box-shadow: var(--ds-bs-4);
        background: var(--ds-c-white);
        width: 375px;
        height: fit-content;
        margin-inline: auto;
        border: none;
      }
    }
  }
`;
const ModalHeader = styled(Modal.Header)`
  display: block;
  padding: 0px;
  border: none;
  width: 100%;
`;
const ModalBody = styled(Modal.Body)`
  padding: 0px;
  padding-top: 10px;
  padding-bottom: 16px;
  width: 100%;
`;
const ModalFooter = styled(Modal.Footer)`
  padding: 0;
  margin: 0;
  background: #fff;
  border: none;
  width: 100%;
`;

const Divider = styled.div`
  border-top: 2px dashed var(--ds-bg-2);
  margin-block: 0px;
`;
