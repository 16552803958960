import NotificationsEmptyIcon from 'assets/icons/notifications_empty-2.svg';
import NotifyAnimationBlueIcon from 'components/common/Icons/NotifyAnimationBlueIcon';
import NotifyAnimationLightIcon from 'components/common/Icons/NotifyAnimationLightIcon';
import NotificationIcon from 'components/common/Icons/NotifyIcon';
import {
  DESKTOP_MIN_WIDTH,
  PROFILE_PREFIX,
  RESET_BACKDROP_STORE,
} from 'constants';
import { datePicker } from 'helpers';
import useLang from 'hooks/useLang';
import useNotify from 'hooks/useNotify';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import { SET_NEW_NOTIFICATION, SET_SHOW_BACKDROP } from 'store/action';
import styled from 'styled-components';

export const NOTIFICATION_NAME = 'notification-name';

const CustomNotificationDropdown = () => {
  const { t, locate } = useLang();
  const { state, dispatch } = useStore();
  let { isNewNotification, notifications, backdrop } = state;
  const navigate = useNavigate();
  const { isHHMTTheme } = useTheme();

  const [isShowDropdown, setShowDropdown] = useState(false);

  const { handleNotifyOnClick } = useNotify();

  let notificationsLimit;
  if (notifications && Array.isArray(notifications)) {
    notificationsLimit = notifications.slice(0, 5);
  }

  const handleDropdownOnToggle = () => {
    setShowDropdown(!isShowDropdown);

    dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });

    dispatch({
      type: SET_NEW_NOTIFICATION,
      payload: false,
    });
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <ButtonToggle
      ref={ref}
      href="/"
      className="notification-desktop-icon"
      onClick={(e) => {
        e.preventDefault();

        if (!backdrop?.isShow) {
          dispatch({
            type: SET_SHOW_BACKDROP,
            payload: {
              isShow: !backdrop?.isShow,
              current: NOTIFICATION_NAME,
            },
          });
        }

        onClick(e);
      }}
    >
      {children}
      {Boolean(notificationsLimit) &&
        Boolean(notificationsLimit?.filter(({ read }) => !read).length) && (
          <HaveNotify>
            {notificationsLimit.filter(({ read }) => !read).length}
          </HaveNotify>
        )}
    </ButtonToggle>
  ));

  return (
    <>
      <style>
        {isShowDropdown &&
          `
          .backdrop-global {
            z-index: 3;
          }
        `}
      </style>

      <CustomNotificationDropdownStyled>
        <Dropdown onToggle={handleDropdownOnToggle}>
          <Dropdown.Toggle as={CustomToggle}>
            {isNewNotification ? (
              <>
                {isHHMTTheme ? (
                  <NotifyAnimationLightIcon />
                ) : (
                  <NotifyAnimationBlueIcon />
                )}
              </>
            ) : (
              <NotificationIcon
                color={isHHMTTheme ? 'var(--ds-c-white)' : 'var( --ds-c-black)'}
                styles={{
                  padding: '9.5px',
                }}
              />
            )}
          </Dropdown.Toggle>

          {isShowDropdown &&
            /* eslint-disable-next-line no-extra-boolean-cast */
            (Boolean(notificationsLimit?.length) ? (
              <DropdownMenu>
                {notificationsLimit.map(
                  ({ createdAt, message, read, uuid, icon }, index) => (
                    <DropdownItem
                      key={uuid}
                      eventKey={index}
                      onClick={(e) =>
                        handleNotifyOnClick(e, notificationsLimit[index])
                      }
                      $isRead={!read}
                    >
                      <Title>
                        <img src={icon} width={18} height={18} alt="" />{' '}
                        {message?.[locate?.toUpperCase()]?.title ||
                          message?.[locate]?.title}{' '}
                        {!read && <MarkUnread>●</MarkUnread>}
                      </Title>
                      <Desc>
                        {message?.[locate?.toUpperCase()]?.short ||
                          message?.[locate]?.short}
                      </Desc>
                      <DateTime>
                        {`${datePicker.convert24hhmmTo12hhmm(
                          datePicker.timeUTCToTimeStr(
                            new Date(parseInt(createdAt))
                          )
                        )} - ${datePicker.dateUTCToDateStr(
                          new Date(parseInt(createdAt))
                        )}`}
                      </DateTime>
                    </DropdownItem>
                  )
                )}
                <SeeAllNotifications
                  href={`${PROFILE_PREFIX}?tab=2`}
                  onClick={(e) => {
                    e.preventDefault();

                    document
                      .getElementsByClassName('notification-desktop-icon')[0]
                      .click();

                    navigate(`${PROFILE_PREFIX}?tab=2`);
                  }}
                >
                  {t('label_see_all_notifications')}
                </SeeAllNotifications>
              </DropdownMenu>
            ) : (
              <DropdownMenu
                style={{
                  display: 'grid',
                  justifyItems: 'center',
                  padding: '40px 120px',
                }}
              >
                <img
                  src={NotificationsEmptyIcon}
                  width={200}
                  height={200}
                  alt=""
                />
                <Desc
                  style={{
                    margin: 0,
                    color: 'var(--c-sub-info-hover)',
                  }}
                >
                  {t('label_empty_notification')}
                </Desc>
              </DropdownMenu>
            ))}
        </Dropdown>
      </CustomNotificationDropdownStyled>
    </>
  );
};

const CustomNotificationDropdownStyled = styled.div`
  display: flex;
  align-items: center;

  & .dropdown {
    width: 36px !important;
    margin-right: 32px;
  }
`;
const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  height: fit-content;
  min-width: fit-content;
  box-shadow: var(--ds-bs-4);
  border-radius: 4px;
  inset: 0px 0px auto auto !important;
  transform: translate(0px, 40px) !important;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    transform: translate(0px, 32px) !important;
  }
`;
const DropdownItem = styled(Dropdown.Item)`
  width: 406px;
  height: fit-content;
  padding: 8px 16px;
  background-color: ${(props) =>
    props.$isRead ? 'var(--ds-c-grey-disabled)' : 'var(--ds-c-white)'};
  border-bottom: 1px solid #d0dce5;

  &:hover {
    background-color: ${(props) =>
      props.$isRead ? 'var(--ds-c-grey-disabled)' : 'var(--ds-c-white)'};
  }

  &:active {
    background-color: ${(props) =>
      props.$isRead ? 'var(--ds-c-grey-disabled)' : 'var(--ds-c-white)'};
  }
`;
const Title = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #0e1012;

  margin-bottom: 8px;
  display: flex;
  align-items: center;
  text-wrap: wrap;
  white-space: break-spaces;

  & span {
    font-family: var(--ff-secondary);
    color: var(--ds-c-blue);
    font-weight: 700;
    margin-left: 4px;
  }

  & img {
    margin-right: 8px;
  }
`;
const MarkUnread = styled.span`
  font-size: 18px;
  color: var(--ds-c-red) !important;
`;
const Desc = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--ds-c-grey-dark);
  text-wrap: wrap;
  white-space: break-spaces;

  margin-bottom: 8px;
`;
const DateTime = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--ds-c-grey-neutral);
  text-align: right;

  margin-bottom: 0px;
`;
const SeeAllNotifications = styled.a`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--ds-c-blue);
  text-decoration: none;

  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;

  &:hover {
    color: var(--ds-c-blue-hover);
  }
`;

const ButtonToggle = styled.a`
  background: transparent;
  border: none;
  padding: 0;
  height: 44px;
  cursor: pointer;

  position: relative;

  &:hover {
    background: transparent;
    border: none;
    color: var(--ds-c-grey-hover);
  }

  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: 44px;

    &:hover {
      & img,
      & svg,
      & span {
        transform: scale(1.3);
      }
    }
  }
`;
const HaveNotify = styled.span`
  font-family: var(--ff-primary);
  line-height: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: var(--ds-c-white);
  text-decoration: none;
  text-align: center;
  padding-right: 1px;

  position: absolute;
  top: -1px;
  left: 18px !important;
  width: 12px;
  height: 12px;
  background: var(--ds-c-red);
  border-radius: 100%;
  user-select: none;
`;

export default CustomNotificationDropdown;
