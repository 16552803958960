import {
  redirectToFxTransactionDetails,
  redirectToScreenMapping,
  redirectToTransactionDetails,
  updateNotifyToReaded,
} from 'components/Profile/func';
import { LogUtil } from 'components/common/LogUtil';
import {
  NOTIFY_TYPE_EVENT,
  NOTIFY_TYPE_FX_TRANSACTION,
  NOTIFY_TYPE_TRANSACTION,
} from 'constants';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SET_NEW_NOTIFICATION, SET_SHOW_LOADING } from 'store/action';
import useAuth from './useAuth';
import { useDetectDevice } from './useDetectDevice';
import useStore from './useStore';

const COUNTDOWN = 30;

const useNotify = (isTriggerIconClick = false) => {
  const { state, dispatch } = useStore();
  const { token } = useAuth();
  const navigate = useNavigate();
  const { isDesktop } = useDetectDevice();

  const [countdown, setCountdown] = useState(COUNTDOWN);

  let { isNewNotification, notifications, currentUser } = state;

  useEffect(() => {
    /* eslint-disable-next-line no-extra-boolean-cast */
    if (!Boolean(notifications?.length)) {
      dispatch({
        type: SET_NEW_NOTIFICATION,
        payload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {
    let timer;

    if (isNewNotification) {
      timer = setTimeout(() => {
        setCountdown((count) => count - 1);
      }, 1 * 1000);
    }

    if (countdown === -1) {
      dispatch({
        type: SET_NEW_NOTIFICATION,
        payload: false,
      });

      setCountdown(COUNTDOWN);
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewNotification, countdown]);

  const handleNotifyOnClick = async (e, notiData) => {
    LogUtil.log('handleNotifyOnClick', 'notiData', notiData);
    if (!isEmpty(e)) {
      e.preventDefault();
    }

    if (!isDesktop && isTriggerIconClick) {
      document.getElementsByClassName('notification-mobile-icon')[0].click();
    }

    dispatch({ type: SET_SHOW_LOADING, payload: true });

    updateNotifyToReaded(currentUser, notiData);

    if (notiData?.type === NOTIFY_TYPE_TRANSACTION) {
      redirectToTransactionDetails(token, notiData, navigate);

      return;
    }

    if (notiData?.type === NOTIFY_TYPE_FX_TRANSACTION) {
      redirectToFxTransactionDetails(token, notiData, navigate);

      return;
    }

    if (notiData?.type === NOTIFY_TYPE_EVENT) {
      redirectToScreenMapping(notiData, navigate);

      return;
    }
  };

  return { handleNotifyOnClick };
};

export default useNotify;
