import { getAvatarName } from 'components/Profile/func';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useStore from 'hooks/useStore';
import { isEmpty } from 'lodash';
import styled from 'styled-components';

const Avatar = ({
  width,
  height,
  background,
  styles = {},
  mobileStyles = {},
  isShowName = true,
}) => {
  const { state } = useStore();
  const { currentUser } = state;
  const { firstName, lastName } = currentUser || {};

  return (
    <AvatarStyled>
      <AvatarIcon
        $width={width}
        $height={height}
        $background={background}
        $styles={styles}
        $mobileStyles={mobileStyles}
      >
        {getAvatarName(firstName, lastName)}
      </AvatarIcon>
      {isShowName && <AvatarName>{`${firstName} ${lastName}`}</AvatarName>}
    </AvatarStyled>
  );
};

const AvatarStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const AvatarIcon = styled.span`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-transform: capitalize;
  color: #757f9b;

  width: ${(props) => props.$width || 100}px;
  height: ${(props) => props.$height || 100}px;
  border-radius: 100%;
  background: ${(props) =>
    props.$background ||
    'linear-gradient(137.29deg, #7906a1 3.47%, #c99cda 107.8%)'};
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) => (isEmpty(props.$mobileStyles) ? `` : props.$mobileStyles)}

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 72px;
    line-height: 80px;
    ${(props) => (isEmpty(props.$styles) ? `` : props.$styles)}
  }
`;

const AvatarName = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
  color: #0e1012;

  margin: 0;
  padding: 0;
  margin-top: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

export default Avatar;
