import ArrowDownSVG from 'assets/ver2/icons/arrow-down-yellow-icon.svg';
import TooltipCommon from 'components/common/TooltipCommon';
import TooltipCommonMobile from 'components/common/TooltipCommon/mobile';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { format } from 'helpers';
import useAddTransaction from 'hooks/form-add-transaction/useAddTransaction';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import styled from 'styled-components';

const CustomCalculatorFormAccordion = ({
  status,
  totalLabelReference,
  totalPayAmount,
  sendAmount,
  feeAmount,
  isShowDiscountPercent,
  discountPercent,
  originalFee,
  isShowSurchargeField,
  surchargeAmount,
  isDiscountApplied,
  discountAmount,
  isExpandDefault = false,
  styleWrapper = {},
  paymentMethod = null,
  discountApplied,
  isShowFeeDiscount,
}) => {
  const { t } = useLang();
  const { isMobile, isTablet } = useDetectDevice();

  const [isExpand, setExpand] = useState(isExpandDefault);

  const { type, code } = discountApplied || {};

  const { renderDiscountFieldLabel } = useAddTransaction();

  const handleAccordionItemOnClick = () => {
    setExpand(!isExpand);
  };

  return (
    <Wrapper style={styleWrapper}>
      <Collapse in={isExpand} appear>
        <div id="calculator-accordion-collapse">
          <BodyContent $isExpand={isExpand}>
            <CalculatorFieldWrap>
              <CalculatorFieldLabel>
                {t('label_sending_amount')}
              </CalculatorFieldLabel>
              <CalculatorFieldValue>{`${format.toAmountFloatStr(
                sendAmount
              )} AUD`}</CalculatorFieldValue>
            </CalculatorFieldWrap>
            <CalculatorFieldWrap>
              <CalculatorFieldLabel>{t('label_fee')}</CalculatorFieldLabel>
              <CalculatorFieldValue>{`${format.toAmountFloatStr(
                originalFee
              )} AUD`}</CalculatorFieldValue>
            </CalculatorFieldWrap>
            {isShowFeeDiscount && (
              <CalculatorFieldWrap>
                <CalculatorFieldLabel>
                  {t('label_discounted_amount')}
                  {isShowDiscountPercent && (
                    <CalculatorFieldValue className="summary-discount">
                      {Boolean(discountPercent) && (
                        <>
                          {isMobile || isTablet ? (
                            <TooltipCommonMobile
                              name="form-transaction-details-mobile"
                              text={t(discountPercent.tooltip)}
                              icon={discountPercent.imgSrc}
                              percentValue={discountPercent.percent}
                              isUseImage={false}
                            />
                          ) : (
                            <TooltipCommon
                              text={t(discountPercent.tooltip)}
                              icon={discountPercent.imgSrc}
                              percentValue={discountPercent.percent}
                              isPopover
                              isDangerouslySetInnerHTML={true}
                              isUseImage={false}
                            />
                          )}
                        </>
                      )}
                    </CalculatorFieldValue>
                  )}
                </CalculatorFieldLabel>
                <CalculatorFieldValue className="fee-value">
                  -{`${format.toAmountFloatStr(originalFee - feeAmount)} AUD`}
                </CalculatorFieldValue>
              </CalculatorFieldWrap>
            )}
            {isDiscountApplied && (
              <CalculatorFieldWrap>
                <CalculatorFieldLabel>
                  {renderDiscountFieldLabel(type, code)}
                </CalculatorFieldLabel>
                <CalculatorFieldValue style={{ whiteSpace: 'nowrap' }}>
                  -{`${format.toAmountFloatStr(discountAmount)} AUD`}
                </CalculatorFieldValue>
              </CalculatorFieldWrap>
            )}
            {isShowSurchargeField && (
              <CalculatorFieldWrap>
                <CalculatorFieldLabel>
                  {t('label_surcharge_label')} <span>{'(1.2%)'}</span>
                </CalculatorFieldLabel>
                <CalculatorFieldValue>{`${format.toAmountFloatStr(
                  surchargeAmount
                )} AUD`}</CalculatorFieldValue>
              </CalculatorFieldWrap>
            )}
          </BodyContent>
        </div>
      </Collapse>
      <AccordionButton
        onClick={handleAccordionItemOnClick}
        aria-controls="calculator-accordion-collapse"
        aria-expanded={isExpand}
        $isExpand={isExpand}
      >
        <CalculatorTotal>
          <CalculatorTotalLabel>
            {status && totalLabelReference
              ? totalLabelReference[status]?.()
              : paymentMethod
              ? t('label_total_to_pay')
              : t('label_sub_total')}
          </CalculatorTotalLabel>
          <CalculatorTotalValue>
            {`${format.toAmountFloatStr(totalPayAmount)} AUD`}{' '}
            <ArrowIcon
              src={ArrowDownSVG}
              width={24}
              height={24}
              $isExpand={isExpand}
            />
          </CalculatorTotalValue>
        </CalculatorTotal>
      </AccordionButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  height: fit-content;
  margin-block: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-block: 16px;
  }
`;

const AccordionButton = styled(Button)`
  padding: 0;
  padding-inline: 16px;
  background-color: var(--ds-c-blue-remox-hover) !important;
  box-shadow: none !important;
  display: block;
  border-radius: 0px;
  transition: 0.6s ease;
  width: 100%;
  height: 52px;
  border: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding-inline: 10px;
    border-radius: ${(props) =>
      props.$isExpand ? '0px 0px 8px 8px!important' : '8px!important'};
  }
`;
const ArrowIcon = styled.img`
  transform: ${(props) =>
    props.$isExpand ? 'rotate(0deg)' : 'rotate(180deg)'};
  transition: 0.3s ease;
`;

const BodyContent = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue);

  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--ds-c-blue-remox-light);
  padding-inline: 16px;
  border-radius: 0px;
  transition: 0.6s ease;

  & p {
    cursor: text;
    margin: 0;
    padding: 0;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding-inline: 10px;
    border-radius: ${(props) => (props.$isExpand ? '8px 8px 0px 0px' : '0px')};
  }
`;

const CalculatorTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
  background: transparent;
  border-radius: 8px;
`;
const CalculatorTotalLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-white);
  text-transform: uppercase;

  margin: 0;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const CalculatorTotalValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  text-transform: uppercase;
  color: var(--ds-c-white);

  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}
`;

const CalculatorFieldWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const CalculatorFieldLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue);
  display: flex;
  align-items: flex-end;
  gap: 4px;

  margin: 0;
  padding: 0;

  & span {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    &.discount-code {
      color: var(--ds-c-green-default);
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const CalculatorFieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-blue);

  margin: 0;
  padding: 0;

  &.fee-value {
    display: flex;
    align-items: center;
    gap: 8px;

    & button {
      height: fit-content;
      background-color: transparent !important;
      padding-inline: 0px;
    }
  }

  &.summary-discount {
    display: flex;
    align-items: center;
    color: #b0b6bd;

    & button {
      padding-bottom: 0px;
    }
  }

  & span {
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;

    background-color: var(--ds-c-green-default);
    padding: 2px 8px;
    border-radius: 2px;
    margin-right: 8px;
  }

  & b {
    font-weight: 500;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default CustomCalculatorFormAccordion;
