import { deletePropsInObject } from '..';
import { ServiceAlertsStoreModal } from '../../model/ServiceAlertsLocalStore';

export const compareObject = (obj1: any, obj2: any) =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export const compareServiceAlertsStore = (
  store: ServiceAlertsStoreModal | any,
  newStore: ServiceAlertsStoreModal | any
) => {
  const propsDoNotNeedCompare = ['isResetReceiverDropdown'];

  deletePropsInObject(store, propsDoNotNeedCompare);
  deletePropsInObject(newStore, propsDoNotNeedCompare);

  return JSON.stringify(store) === JSON.stringify(newStore);
};
