import { api } from 'api';
import CancelPopup from 'components/FormRegister/CancelPopup';
import DocumentUpload from 'components/FormRegister/DocumentUpload';
import DocumentUploadMobile from 'components/FormRegister/DocumentUpload/mobile';
import InactivityTimePopup from 'components/FormRegister/InactivityTimePopup';
import LogInDetails from 'components/FormRegister/LogInDetails';
import PersonalDetails from 'components/FormRegister/PersonalDetails';
import ProcessSteps from 'components/FormRegister/ProcessSteps';
import RegistrationCompletedPopup from 'components/FormRegister/RegistrationCompletedPopup';
import ResidentialAddress from 'components/FormRegister/ResidentialAddress';
import StartRegister from 'components/FormRegister/StartRegister';
import StartRegisterMobile from 'components/FormRegister/StartRegister/mobile';
import {
  DESKTOP_MIN_WIDTH,
  REGISTER_ADDRESS_FINDER_NAME,
  REGISTER_ADDRESS_POST_CODE_NAME,
  REGISTER_ADDRESS_STATE_NAME,
  REGISTER_ADDRESS_STREET_NAME_NAME,
  REGISTER_ADDRESS_STREET_NO_NAME,
  REGISTER_ADDRESS_STREET_TYPE_NAME,
  REGISTER_ADDRESS_SUBURB_NAME,
  REGISTER_ADDRESS_UNITNAME_NAME,
  REGISTER_DOB_NAME,
  REGISTER_EMAIL_NAME,
  REGISTER_FISTNAME_NAME,
  REGISTER_GENDER_NAME,
  REGISTER_LASTNAME_NAME,
  REGISTER_MIDDLENAME_NAME,
  REGISTER_MOBILE_NAME,
  REGISTER_OCCUPATION_NAME,
  REGISTER_PAYLOAD_INIT,
  REGISTER_PREFIX,
  REGISTER_PREVIOUS_EMPLOYED_NAME,
  REGISTER_REEMAIL_NAME,
  REGISTER_STREET_TYPES,
  RESET_BACKDROP_STORE,
  RESET_REGISTER_INFORMATION_STORE,
} from 'constants';
import { address, datePicker } from 'helpers';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SET_REGISTER_INFORMATION, SET_SHOW_BACKDROP } from 'store/action';
import styled from 'styled-components';

const RegisterPage = () => {
  const { state, dispatch } = useStore();
  const { configOccupation } = state;
  const { isMobile, isTablet } = useDetectDevice();
  const query = useQuery();
  const signupIdQuery = query.get('signupId');
  const navigate = useNavigate();

  const [registerPayload, setRegisterPayload] = useState(REGISTER_PAYLOAD_INIT);
  const [isStarted, setStarted] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isOpenCancelPopup, setOpenCancelPopup] = useState(false);
  const [signupId, setSignupId] = useState(0);
  const [isOpenInactivityPopup, setOpenInactivityPopup] = useState(false);
  let timer;

  const isRegistrationSuccessfully = currentStep === 4;

  useEffect(() => {
    return () => {
      dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRegistrationInformation = async (id) => {
    try {
      const { data } = await api.getSignup(id);

      if (data) {
        const {
          email,
          mobile,
          optinNews,
          firstName,
          otherNames,
          lastName,
          gender,
          dob,
          job,
          previousJob,
          fullAddress,
          unitNumber,
          streetNumber,
          streetName,
          streetType,
          postCode,
          suburbName,
          stateCode,
          documents,
          incompleted,
        } = data || {};

        if (!incompleted) {
          navigate(`${REGISTER_PREFIX}`);

          return;
        }

        setRegisterPayload({
          ...data,
          dob: datePicker.dateArrToDateUTC(dob),
        });

        const currentFullAddress = address.addressCombine(
          unitNumber,
          streetNumber,
          streetName,
          streetType,
          postCode,
          suburbName,
          stateCode
        );

        const streetTypeInit = REGISTER_STREET_TYPES?.find(
          (st) => st.key === streetType
        );
        const occupationInit = configOccupation?.find((o) => o.key === job);
        const previousOccupationInit = configOccupation?.find(
          (o) => o.key === previousJob
        );

        dispatch({
          type: SET_REGISTER_INFORMATION,
          payload: {
            ...RESET_REGISTER_INFORMATION_STORE,
            logInDetails: {
              [REGISTER_EMAIL_NAME]: email,
              [REGISTER_REEMAIL_NAME]: email,
              [REGISTER_MOBILE_NAME]: mobile,
              isOptinNews: optinNews,
            },
            personalDetails: {
              [REGISTER_FISTNAME_NAME]: firstName,
              [REGISTER_MIDDLENAME_NAME]: otherNames,
              [REGISTER_LASTNAME_NAME]: lastName,
              [REGISTER_GENDER_NAME]: gender,
              [REGISTER_DOB_NAME]: datePicker.dateArrToDateUTC(dob),
              [REGISTER_OCCUPATION_NAME]: occupationInit,
              [REGISTER_PREVIOUS_EMPLOYED_NAME]: previousOccupationInit,
            },
            residentialAddress: {
              [REGISTER_ADDRESS_FINDER_NAME]: fullAddress || currentFullAddress,
              [REGISTER_ADDRESS_UNITNAME_NAME]: unitNumber,
              [REGISTER_ADDRESS_STREET_NO_NAME]: streetNumber,
              [REGISTER_ADDRESS_STREET_NAME_NAME]: streetName,
              [REGISTER_ADDRESS_STREET_TYPE_NAME]: streetTypeInit,
              [REGISTER_ADDRESS_POST_CODE_NAME]: postCode,
              [REGISTER_ADDRESS_SUBURB_NAME]: suburbName,
              [REGISTER_ADDRESS_STATE_NAME]: stateCode,
              isRegisterEnterAddressManuallySelected: false,
            },
          },
        });

        setStarted(true);

        setSignupId(signupIdQuery);

        if (!firstName) {
          setCurrentStep(1);

          return;
        }

        if (!postCode) {
          setCurrentStep(2);

          return;
        }

        if (!documents?.length) {
          setCurrentStep(3);

          return;
        }
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  const checkInactivityTime = () => {
    const timeOut = 270;
    const events = [
      'mousedown',
      'mousemove',
      'keypress',
      'scroll',
      'touchstart',
    ];

    const showInactivityTimePopup = () => {
      setOpenInactivityPopup(true);
    };

    const resetTimer = () => {
      clearTimeout(timer);

      timer = setTimeout(showInactivityTimePopup, timeOut * 1000);
    };

    window.addEventListener('load', resetTimer, true);

    events.forEach((name) => {
      document.addEventListener(name, resetTimer, true);
    });
  };

  const handleOnClickInactivityTimePopup = () => {
    setOpenInactivityPopup(false);

    timer = null;
  };

  useEffect(() => {
    checkInactivityTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  useEffect(() => {
    if ((isMobile || isTablet) && signupIdQuery) {
      fetchRegistrationInformation(signupIdQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupIdQuery]);

  useEffect(() => {
    return () => {
      dispatch({
        type: SET_REGISTER_INFORMATION,
        payload: RESET_REGISTER_INFORMATION_STORE,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnToggleCancelPopup = () => {
    setOpenCancelPopup(!isOpenCancelPopup);
  };

  const handleOnClickCancelPopup = () => {
    setOpenCancelPopup(false);

    setStarted(false);

    setCurrentStep(0);

    setSignupId(0);

    dispatch({
      type: SET_REGISTER_INFORMATION,
      payload: RESET_REGISTER_INFORMATION_STORE,
    });

    dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });
  };

  const registerScreentStepReferenceMobile = {
    0: (
      <LogInDetails
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setSignupId={setSignupId}
        registerPayload={registerPayload}
        setRegisterPayload={setRegisterPayload}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
      />
    ),
    1: (
      <PersonalDetails
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        signupId={signupId}
        registerPayload={registerPayload}
        setRegisterPayload={setRegisterPayload}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
      />
    ),
    2: (
      <ResidentialAddress
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        signupId={signupId}
        registerPayload={registerPayload}
        setRegisterPayload={setRegisterPayload}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
      />
    ),
    3: (
      <DocumentUploadMobile
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        signupId={signupId}
        registerPayload={registerPayload}
        setRegisterPayload={setRegisterPayload}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
      />
    ),
  };

  const registerScreentStepReference = {
    0: (
      <LogInDetails
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setSignupId={setSignupId}
        registerPayload={registerPayload}
        setRegisterPayload={setRegisterPayload}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
      />
    ),
    1: (
      <PersonalDetails
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        signupId={signupId}
        registerPayload={registerPayload}
        setRegisterPayload={setRegisterPayload}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
      />
    ),
    2: (
      <ResidentialAddress
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        signupId={signupId}
        registerPayload={registerPayload}
        setRegisterPayload={setRegisterPayload}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
      />
    ),
    3: (
      <DocumentUpload
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        signupId={signupId}
        registerPayload={registerPayload}
        setRegisterPayload={setRegisterPayload}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
      />
    ),
  };

  if (isRegistrationSuccessfully) {
    return <RegistrationCompletedPopup />;
  }

  if (isMobile || isTablet) {
    return (
      <>
        {currentStep > 0 && isOpenInactivityPopup && (
          <InactivityTimePopup
            isOpen={currentStep > 0 && isOpenInactivityPopup}
            onClick={handleOnClickInactivityTimePopup}
            onClose={handleOnClickCancelPopup}
          />
        )}

        <RegisterStyled>
          {!isStarted && <StartRegisterMobile setStarted={setStarted} />}
          {isStarted && (
            <>
              {isOpenCancelPopup && (
                <CancelPopup
                  isOpen={isOpenCancelPopup}
                  onClose={handleOnToggleCancelPopup}
                  onClick={handleOnClickCancelPopup}
                />
              )}
              <ProcessSteps
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              />
              {registerScreentStepReferenceMobile[currentStep]}
            </>
          )}
        </RegisterStyled>
      </>
    );
  }
  return (
    <>
      {currentStep > 0 && isOpenInactivityPopup && (
        <InactivityTimePopup
          isOpen={currentStep > 0 && isOpenInactivityPopup}
          onClick={handleOnClickInactivityTimePopup}
          onClose={handleOnClickCancelPopup}
        />
      )}
      <RegisterStyled>
        {!isStarted && <StartRegister setStarted={setStarted} />}
        {isStarted && (
          <>
            {isOpenCancelPopup && (
              <CancelPopup
                isOpen={isOpenCancelPopup}
                onClose={handleOnToggleCancelPopup}
                onClick={handleOnClickCancelPopup}
              />
            )}
            <ProcessSteps
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
            {registerScreentStepReference[currentStep]}
          </>
        )}
      </RegisterStyled>
    </>
  );
};

const RegisterStyled = styled.div`
  padding: 24px;
  padding-top: 96px;
  width: 100%;
  height: 100%;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding: 24px 0px 50px 0px;
    display: block;
    margin-top: 72px;
  }
`;

export default RegisterPage;
