import {
  getCurrenciesSupportString,
  getReceiveLimitsWithPayoutMethod,
} from 'components/FormAddTransaction/func';
import {
  ADD_TRANSACTION_METHOD_BANK_NAME,
  ADD_TRANSACTION_METHOD_EWALLET_NAME,
  ADD_TRANSACTION_METHOD_HOME_NAME,
  ADD_TRANSACTION_METHOD_PICKUP_NAME,
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  DESKTOP_MIN_WIDTH,
  METHOD_HOME,
  RESET_ADD_TRANSACTION_INFORMATION_STORE,
} from 'constants';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import useReceiver from 'hooks/receiver/useReceiver';
import useCheckServiceAlerts from 'hooks/service-alerts/useCheckServiceAlerts';
import useServiceAlertsConfig from 'hooks/service-alerts/useServiceAlertsConfig';
import useServiceAlertsStore from 'hooks/service-alerts/useServiceAlertsStore';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import { SET_ADD_TRANSACTION_INFORMATION } from 'store/action';
import styled from 'styled-components';

const initialFormField =
  RESET_ADD_TRANSACTION_INFORMATION_STORE.receiverPayoutMethod;

const PayoutMethodItem = ({
  countryConfig,
  method,
  information,
  setInformation,
  setValidation,
  setInformationChangeBackup,
  setOpenDiscountImportantPopup,
  isDisabled,
  isSelectRequired,
}) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const { state, dispatch } = useStore();
  const { addTransactionInformation } = state || {};
  const { isReceiverOfBusinessType, isReceiverOfIndividualType } =
    useReceiver();

  const query = useQuery();
  const refNumber = query.get('refNumber');
  const isMakePayment = Cookies.get(MAKE_PAYMENT_KEY) || refNumber;

  const { receiverPayoutMethod, paymentMethod } =
    addTransactionInformation || {};
  const { discountApplied } = paymentMethod || {};

  const receiveLimits = getReceiveLimitsWithPayoutMethod({
    dmCode: method.method,
    receiveLimits: countryConfig?.receiveLimits,
    isReceiverOfBusinessType,
    isReceiverOfIndividualType,
    dmList: countryConfig?.dmList,
  });

  const { payoutMethod } = useServiceAlertsConfig();
  const {
    serviceAlertsByCountryCode,
    resetServiceAlertsStoreByPayoutMethodItem,
  } = useServiceAlertsStore();
  const { checkByPayoutMethod } = useCheckServiceAlerts();
  const { checkServiceAlertsOfPayoutMethodItemDisable } = checkByPayoutMethod;

  const isSelected =
    !checkServiceAlertsOfPayoutMethodItemDisable({
      serviceAlertsStore: serviceAlertsByCountryCode,
      payoutMethod: method.method,
    }) && information[ADD_TRANSACTION_PAYOUT_METHOD_NAME] === method.method;

  const currenciesSupportText = getCurrenciesSupportString({
    t,
    countryConfig,
    receiveLimits,
  });

  const handlePayoutMethodSelected = (method) => {
    if (!currenciesSupportText) return;

    if (isMakePayment) return;

    let newInformation = {
      ...information,
      [ADD_TRANSACTION_PAYOUT_METHOD_NAME]: method,
      [ADD_TRANSACTION_METHOD_PICKUP_NAME]: null,
      [ADD_TRANSACTION_METHOD_BANK_NAME]: null,
      [ADD_TRANSACTION_METHOD_EWALLET_NAME]: null,
      currentPayoutItemSelectedIndex: -1,
    };

    if (method === METHOD_HOME) {
      newInformation = {
        ...newInformation,
        [ADD_TRANSACTION_METHOD_HOME_NAME]: {
          dmCode: METHOD_HOME,
          bank: null,
          pickupOffice: null,
          ewallet: null,
        },
        currentPayoutItemSelectedIndex: 0,
      };
    }

    if (discountApplied && payoutMethod !== method) {
      setOpenDiscountImportantPopup(true);

      setInformationChangeBackup(newInformation);

      return;
    }

    setInformation(newInformation);

    setValidation(initialFormField);

    let payloadStore = {
      ...addTransactionInformation,
      receiverPayoutMethod: {
        ...receiverPayoutMethod,
        [ADD_TRANSACTION_PAYOUT_METHOD_NAME]: method,
        [ADD_TRANSACTION_METHOD_PICKUP_NAME]: null,
        [ADD_TRANSACTION_METHOD_BANK_NAME]: null,
        [ADD_TRANSACTION_METHOD_EWALLET_NAME]: null,
        currentPayoutItemSelectedIndex: -1,
      },
      remittance: RESET_ADD_TRANSACTION_INFORMATION_STORE.remittance,
    };

    const isPayoutMethodSelectedChanged =
      payoutMethod && payoutMethod !== method;
    if (isPayoutMethodSelectedChanged) {
      if (method === METHOD_HOME) {
        payloadStore = {
          ...payloadStore,
          receiverPayoutMethod: {
            ...payloadStore.receiverPayoutMethod,
            [ADD_TRANSACTION_METHOD_HOME_NAME]: {
              dmCode: METHOD_HOME,
              bank: null,
              pickupOffice: null,
              ewallet: null,
            },
            currentPayoutItemSelectedIndex: 0,
          },
        };
      }
    }
    dispatch({
      type: SET_ADD_TRANSACTION_INFORMATION,
      payload: payloadStore,
    });

    resetServiceAlertsStoreByPayoutMethodItem();
  };

  return (
    <PayoutMethodItemStyled
      $isSelected={isSelected}
      $isDisabled={!isMakePayment && isDisabled}
      $isRequired={isSelectRequired}
      $deactive={(isDisabled && !isSelected) || isMakePayment}
      onClick={() => handlePayoutMethodSelected(method.method)}
    >
      <ImageWrap $isSelected={isSelected}>
        <img
          src={
            isSelected
              ? isHHMTTheme
                ? method.iconColor
                : method.iconRemoxColor
              : isHHMTTheme
              ? method.icon
              : method.iconRemox
          }
          alt={method.label}
        />
        <p className="image-label">{t(method.label)}</p>
        <p className="image-currency">{currenciesSupportText}</p>
      </ImageWrap>
    </PayoutMethodItemStyled>
  );
};

const PayoutMethodItemStyled = styled.div`
  width: calc(50% - 8px);
  height: 120px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${(props) => (props.$isSelected ? '#FEF0D1' : 'unset')};
  box-shadow: ${(props) =>
    props.$isSelected ? '0px 4px 15px 0px #0000001A' : 'none'};
  border: ${(props) =>
    props.$isRequired
      ? '1px solid var(--ds-c-red)'
      : props.$isSelected
      ? 'none'
      : '1px solid var(--ds-c-blue-remox-light)'};
  cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};

  ${(props) =>
    props.$deactive &&
    `
    cursor: not-allowed;

  `};

  ${(props) =>
    props.$isDisabled &&
    `
    background-color: #eeeeee;
    border: 1px dashed 1px solid transparent;
  `};

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 206px;
    height: 120px;
  }
`;

const ImageWrap = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  color: ${(props) =>
    props.$isSelected ? 'var(--ds-c-grey-dark)' : 'var(--c-sub-info)'};
  user-select: none;

  align-items: center;
  display: grid;
  justify-items: center;

  & p.image-label {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: var(--ds-c-grey-dark);

    margin-block: 4px;
  }

  & p.image-currency {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: var(--ds-c-grey-dark);

    margin-bottom: 0px;
  }
`;

export default PayoutMethodItem;
