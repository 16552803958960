import CustomSearchDropdown from 'components/common/CustomSearchDropdown';
import {
  REGISTER_ADDRESS_POST_CODE_NAME,
  REGISTER_ADDRESS_STREET_TYPE_NAME,
  REGISTER_STREET_TYPES,
} from 'constants';
import { useDetectDevice } from 'hooks/useDetectDevice';
import CustomAddressPostcodeDropdown from '../CustomAddressPostcodeDropdown';
import FormInputValidation from '../FormInputValidation';

const FormInputAddressManuallyValidation = ({
  field,
  information,
  setInformation,
  validation,
  setValidation,
  checkFormInputValidation,
}) => {
  const { isDesktop } = useDetectDevice();

  if (field.name === REGISTER_ADDRESS_STREET_TYPE_NAME) {
    return (
      <CustomSearchDropdown
        field={field}
        data={REGISTER_STREET_TYPES}
        keyFind="key"
        keyTranslate="value"
        noResultText="form_text_no_result"
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        styles={
          isDesktop
            ? {
                width: 'calc(50% - 12px)',
                display: 'inline-block',
                marginLeft: '12px',
              }
            : {
                width: 'calc(50% - 4px)',
                display: 'inline-block',
                marginLeft: '4px',
              }
        }
      />
    );
  }

  if (field.name === REGISTER_ADDRESS_POST_CODE_NAME) {
    return (
      <CustomAddressPostcodeDropdown
        field={field}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        checkFormInputValidation={checkFormInputValidation}
      />
    );
  }

  return (
    <FormInputValidation
      field={field}
      information={information}
      setInformation={setInformation}
      validation={validation}
      setValidation={setValidation}
      checkFormInputValidation={checkFormInputValidation}
    />
  );
};

export default FormInputAddressManuallyValidation;
