import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import React from 'react';
import styled from 'styled-components';
import ProcessSteps from '../../ProcessSteps';

const BodyHeader = ({ titleSection, renderTitleCancelSection, isDisabled }) => {
  const { addTransactionCurrentStep: currentStep } =
    useFxFormAddTransactionStore();
  const { fxAddTransactionTotalNumberOfSteps, isMakePayment } =
    useFxAddTransaction();

  const isHideProgressBarAndTitleSection = currentStep === 0;
  const renderProgressBarAndTitleSection = () => {
    if (isHideProgressBarAndTitleSection) return null;
    if (isMakePayment) return <TitleSection>{titleSection}</TitleSection>;
    if (currentStep > fxAddTransactionTotalNumberOfSteps)
      return <TitleSection>{titleSection}</TitleSection>;
    return (
      <>
        <ProcessSteps isPreview={isDisabled} />
        <TitleSection>{titleSection}</TitleSection>
      </>
    );
  };

  return (
    <TitleProcessStepsSection>
      {renderTitleCancelSection()}
      {renderProgressBarAndTitleSection()}
    </TitleProcessStepsSection>
  );
};

const TitleProcessStepsSection = styled.div``;
const TitleSection = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-green-default);
  margin-bottom: 16px;
  padding-block: 8px;
`;

export default BodyHeader;
