import FormInputMobileNumberValidation from 'components/FormRegister/LogInDetails/components/FormInputMobileNumberValidation';
import {
  FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_FIELD,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_FIELD,
} from 'constants/fx';
import useLang from 'hooks/useLang';
import React, { useState } from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import FormEmailInputValidation from '../../FormFxContactInformationModal/components/FormEmailInputValidation';
import { checkFormInputValidation } from './func';

const ContactInformation = ({
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();

  const [numberMaskStr, setNumberMaskStr] = useState(null);

  // eslint-disable-next-line no-useless-escape
  const numberFormat = `[\"#### ### ###\"]`;
  // eslint-disable-next-line no-useless-escape
  const prefixFormat = `[\"04\"]`;

  const renderMobileForm = () => {
    return (
      <FormInputMobileNumberValidation
        field={FX_ADD_TRANSACTION_ENTER_INFORMATION_MOBILE_FIELD}
        config={{
          visible: true,
          required: true,
          prefix: prefixFormat,
          format: numberFormat,
          example: '',
          max: 10,
          min: 10,
        }}
        numberMaskStr={numberMaskStr}
        setNumberMaskStr={setNumberMaskStr}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
    );
  };

  const renderEmailForm = () => {
    return (
      <FormEmailInputValidation
        field={FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_FIELD}
        information={information}
        validation={validation}
        setInformation={setInformation}
        setValidation={setValidation}
        checkFormEmailInputValidation={checkFormInputValidation}
      />
    );
  };

  return (
    <ContactInformationStyled>
      <Label>{t('label_contact_info')}</Label>
      {renderMobileForm()}
      {renderEmailForm()}
    </ContactInformationStyled>
  );
};

const ContactInformationStyled = styled.div`
  margin-bottom: 40px;
`;

const Label = styled.p`
  ${paragraphMixin};
  font-weight: 500;
  color: var(--ds-c-blue);
  margin-bottom: 16px;
`;

export default ContactInformation;
