import { getItemsConfig } from 'components/common/func';
import { useEffect, useState } from 'react';

const useGetConfig = ({
  countryConfig,
  dmCode = null,
  spCode = null,
  currencyCode = null,
}) => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    if (countryConfig) {
      let phoneConfig;
      let additionalInfoConfig;
      let addressConfig;
      let bankConfig;
      let ewalletConfig;

      phoneConfig = getItemsConfig(
        countryConfig?.phones,
        countryConfig?.countryCode,
        dmCode,
        spCode,
        currencyCode
      );

      additionalInfoConfig = getItemsConfig(
        countryConfig?.additionalInfos,
        countryConfig?.countryCode,
        dmCode,
        spCode,
        currencyCode
      );

      addressConfig = getItemsConfig(
        countryConfig?.addresses,
        countryConfig?.countryCode
      );

      bankConfig = getItemsConfig(
        countryConfig?.banks,
        countryConfig?.countryCode
      );

      ewalletConfig = getItemsConfig(
        countryConfig?.ewallets,
        countryConfig?.countryCode
      );

      setConfig({
        // mobiles/phones config
        mobile: {
          visible: phoneConfig?.mobileVisible,
          required: phoneConfig?.mobileRequired,
          prefix: phoneConfig?.mobilePrefix,
          format: phoneConfig?.mobileFormat,
          example: phoneConfig?.mobileExample,
          max: phoneConfig?.mobileMax,
          min: phoneConfig?.mobileMin,
          eitherRequired: phoneConfig?.eitherRequired,
        },
        phone: {
          visible: phoneConfig?.homeVisible,
          required: phoneConfig?.homeRequired,
          prefix: phoneConfig?.homePrefix,
          format: phoneConfig?.homeFormat,
          example: phoneConfig?.homeExample,
          max: phoneConfig?.homeMax,
          min: phoneConfig?.homeMin,
          eitherRequired: phoneConfig?.eitherRequired,
        },

        // additionalInfos config
        fundSource: {
          visible: additionalInfoConfig?.fundSourceVisible,
          required: additionalInfoConfig?.fundSourceRequired,
        },
        purpose: {
          visible: additionalInfoConfig?.purposeVisible,
          required: additionalInfoConfig?.purposeRequired,
        },
        occupation: {
          visible: additionalInfoConfig?.occupationVisible,
          required: additionalInfoConfig?.occupationRequired,
        },
        relationship: {
          visible: additionalInfoConfig?.relationshipVisible,
          required: additionalInfoConfig?.relationshipRequired,
        },
        message: {
          visible: additionalInfoConfig?.messageVisible,
          required: additionalInfoConfig?.messageRequired,
          min: additionalInfoConfig?.messageMin,
          max: additionalInfoConfig?.messageMax,
        },
        addressStreet: {
          visible: addressConfig?.streetVisible,
          required: addressConfig?.streetRequired,
        },
        addressState: {
          visible: addressConfig?.stateVisible,
          required: addressConfig?.stateRequired,
        },
        addressSuburb: {
          visible: addressConfig?.suburbVisible,
          required: addressConfig?.suburbRequired,
        },
        addressPostcode: {
          visible: addressConfig?.postcodeVisible,
          required: addressConfig?.postcodeRequired,
          format: addressConfig?.postcodeFormat,
          example: addressConfig?.postcodeExample,
          min: addressConfig?.postcodeMin,
          max: addressConfig?.postcodeMax,
        },
        addressManualDisable: addressConfig?.manualDisable,

        // banks config
        accountHolder: {
          visible: bankConfig?.holderVisible,
          required: bankConfig?.holderRequired,
          editEnabled: bankConfig?.holderEnabled,
        },
        accountNumber: {
          name: bankConfig?.accNumberName,
          type: bankConfig?.accNumberType,
          visible: bankConfig?.accNumberVisible,
          required: bankConfig?.accNumberRequired,
          format: bankConfig?.accNumberFormat,
          example: bankConfig?.accNumberExample,
          min: bankConfig?.accNumberMin,
          max: bankConfig?.accNumberMax,
          apiEnabled: bankConfig?.accNumberApiEnabled,
        },
        accountSwift: {
          name: bankConfig?.swiftName,
          type: bankConfig?.swiftType || '',
          visible: bankConfig?.swiftVisible,
          required: bankConfig?.swiftRequired,
          format: bankConfig?.swiftFormat,
          regex: bankConfig?.swiftRegex,
          example: bankConfig?.swiftExample,
          min: bankConfig?.swiftMin,
          max: bankConfig?.swiftMax,
          apiEnabled: bankConfig?.swiftApiEnabled,
        },
        accountBsb: {
          name: bankConfig?.bsbName,
          type: bankConfig?.bsbType || '',
          visible: bankConfig?.bsbVisible,
          required: bankConfig?.bsbRequired,
          format: bankConfig?.bsbFormat,
          regex: bankConfig?.bsbRegex,
          example: bankConfig?.bsbExample,
          min: bankConfig?.bsbMin,
          max: bankConfig?.bsbMax,
          apiEnabled: bankConfig?.bsbApiEnabled,
        },

        // ewallets config
        ewalletNumber: {
          visible: ewalletConfig?.ewalletNumberVisible,
          required: ewalletConfig?.ewalletNumberRequired,
          format: ewalletConfig?.ewalletNumberFormat,
          example: ewalletConfig?.ewalletNumberExample,
          min: ewalletConfig?.ewalletNumberMin,
          max: ewalletConfig?.ewalletNumberMax,
          tooltip: ewalletConfig?.ewalletNumberHint,
        },
        ewalletExpire: {
          visible: ewalletConfig?.expiryDateVisible,
          required: ewalletConfig?.expiryDateRequired,
        },
      });
    }
  }, [countryConfig, dmCode, spCode, currencyCode]);

  return config || {};
};

export default useGetConfig;
