import { fetchCurrentFee } from 'components/CalculatorForm/func';
import { getItemsConfig } from 'components/common/func';
import {
  AMOUNT_NUMBER_REGEX,
  CALCULATOR_DESKTOP_WIDTH,
  CALCULATOR_FORM_AMOUNT_FIELDS,
  CALCULATOR_FORM_COUNTRY_NAME,
  CALCULATOR_FORM_RECEIVER_GET_NAME,
  CALCULATOR_FORM_SEND_TO_NAME,
  COUNTRY_FLAG_REFERENCES,
  DEBOUNCE_SEC,
} from 'constants';
import { format } from 'helpers';
import numberHelper from 'helpers/numberHelper';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useLang from 'hooks/useLang';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { numberUtils, stringUtils } from 'utils';
import CustomCurrencyDropdown from '../CustomCurrencyDropdown';

const FormInputAmountValidation = ({
  field,
  countryConfig,
  receiveLimits,
  information,
  setInformation,
  validation,
  setValidation,
  currentRateValue,
  currentCurrencySelected,
}) => {
  const { t } = useLang();

  const [isPaste, setPaste] = useState(false);

  const sendLimitConfig =
    countryConfig &&
    getItemsConfig(
      countryConfig?.sendLimits,
      countryConfig?.countryCode,
      null,
      true,
      null
    );

  const receiveLimitConfig =
    countryConfig &&
    getItemsConfig(
      countryConfig?.receiveLimits,
      countryConfig?.countryCode,
      currentCurrencySelected?.dmCode,
      null,
      currentCurrencySelected?.currencyCode
    );

  const { maxSendLimit, minSendLimit } = countryConfig
    ? {
        maxSendLimit: Number(sendLimitConfig?.max || 30000),
        minSendLimit: Number(sendLimitConfig?.min || 20),
      }
    : {
        maxSendLimit: 30000,
        minSendLimit: 20,
      };

  const { maxReceiverLimit, minReceiverLimit } = countryConfig
    ? {
        maxReceiverLimit: Number(
          receiveLimitConfig?.maxAmount || maxSendLimit * currentRateValue
        ),
        minReceiverLimit: Number(receiveLimitConfig?.minAmount || 0),
      }
    : {
        maxReceiverLimit: 7000000000,
        minReceiverLimit: 0,
      };

  const isSendField = field.name === CALCULATOR_FORM_SEND_TO_NAME;
  const isReceiverField = field.name === CALCULATOR_FORM_RECEIVER_GET_NAME;

  const handleSendAmountValidation = (information, validation, isDisable) => {
    let newValidation = { ...validation };

    const sendAmount = numberUtils.mathRound(
      information[CALCULATOR_FORM_SEND_TO_NAME] || 0
    );

    if ((maxSendLimit && !minSendLimit) || (maxSendLimit && minSendLimit)) {
      if (sendAmount > maxSendLimit) {
        newValidation = {
          ...newValidation,
          [CALCULATOR_FORM_SEND_TO_NAME]: stringUtils.replaceKeyword(
            t(CALCULATOR_FORM_AMOUNT_FIELDS[0].msgMaxLimited),
            [
              {
                key: 'max',
                value: format.toAmountStr(maxSendLimit),
              },
            ]
          ),
        };
      }
    }

    if (sendAmount <= maxSendLimit) {
      newValidation = {
        ...newValidation,
        [CALCULATOR_FORM_SEND_TO_NAME]: '',
      };
    }

    if (!isDisable) {
      newValidation = handleGetReceiverAmountValidation(
        information,
        newValidation,
        true
      );
    }

    return newValidation;
  };

  const handleGetReceiverAmountValidation = (
    information,
    validation,
    isDisable = false
  ) => {
    let newValidation = { ...validation };

    const getReceiverAmount = Number(
      information[CALCULATOR_FORM_RECEIVER_GET_NAME] || 0
    );

    if (
      (maxReceiverLimit && !minReceiverLimit) ||
      (maxReceiverLimit && minReceiverLimit)
    ) {
      if (getReceiverAmount > maxReceiverLimit) {
        newValidation = {
          ...newValidation,
          [CALCULATOR_FORM_RECEIVER_GET_NAME]: stringUtils.replaceKeyword(
            t(CALCULATOR_FORM_AMOUNT_FIELDS[1].msgMaxLimited),
            [
              {
                key: 'max',
                value: format.toAmountStr(maxReceiverLimit),
              },
              {
                key: 'currency',
                value: currentCurrencySelected?.currencyCode,
              },
            ]
          ),
        };
      }
    }

    if (getReceiverAmount <= maxReceiverLimit) {
      newValidation = {
        ...newValidation,
        [CALCULATOR_FORM_RECEIVER_GET_NAME]: '',
      };
    }

    if (!isDisable) {
      newValidation = handleSendAmountValidation(
        information,
        newValidation,
        true
      );
    }

    return newValidation;
  };

  const handleSendAmountOnChange = (value, isChangeCurrency) => {
    if (isPaste && value > maxSendLimit) {
      value = Number(maxSendLimit);
    }

    let receiverValue = numberUtils.mathRound(
      numberUtils.mathRound(value) * currentRateValue
    );

    if (
      (value > maxSendLimit && validation[field.name]) ||
      (receiverValue > maxReceiverLimit &&
        validation[CALCULATOR_FORM_RECEIVER_GET_NAME])
    ) {
      value = Number(`${value}`.slice(0, -1));

      receiverValue = numberUtils.mathRound(
        numberUtils.mathRound(value) * currentRateValue
      );
    }

    if (currentCurrencySelected?.currencyCode === 'AUD') {
      receiverValue = Number(value);
    }

    if (currentCurrencySelected?.currencyCode === 'VND') {
      receiverValue = numberHelper.roundVndAmount(receiverValue);
    }

    if (
      currentCurrencySelected?.currencyCode !== 'VND' &&
      currentCurrencySelected?.currencyCode !== 'AUD'
    ) {
      receiverValue = numberUtils.mathRound(receiverValue);
    }

    if (isChangeCurrency) {
      receiverValue = numberUtils.mathRound(receiverValue);
    }

    document.getElementsByName(CALCULATOR_FORM_SEND_TO_NAME)[0].value =
      format.toAmountCentStr(value);
    document.getElementsByName(CALCULATOR_FORM_RECEIVER_GET_NAME)[0].value =
      format.toAmountStr(receiverValue);

    value = numberUtils.mathRound(value);

    return [value, receiverValue];
  };

  const handleReceiverGetOnChange = (value) => {
    if (isPaste && value > maxReceiverLimit) {
      value = Number(maxSendLimit);
    }

    let sendValue = numberUtils.mathRound(
      numberUtils.mathRound(value) / currentRateValue
    );

    if (
      (value > maxReceiverLimit && validation[field.name]) ||
      (sendValue > maxSendLimit && validation[CALCULATOR_FORM_SEND_TO_NAME])
    ) {
      value = Number(`${value}`.slice(0, -1));

      sendValue = numberUtils.mathRound(
        numberUtils.mathRound(value) / currentRateValue
      );
    }

    sendValue = numberUtils.mathRound(sendValue);

    document.getElementsByName(CALCULATOR_FORM_SEND_TO_NAME)[0].value =
      format.toAmountStr(sendValue);
    document.getElementsByName(CALCULATOR_FORM_RECEIVER_GET_NAME)[0].value =
      format.toAmountCentStr(value);

    value = Number(value);

    if (currentCurrencySelected?.currencyCode !== 'VND') {
      value = numberUtils.mathRound(value);
    }

    if (currentCurrencySelected?.currencyCode === 'VND') {
      value = numberHelper.roundVndAmount(value);

      sendValue = numberUtils.mathRound(value / currentRateValue);
    }

    return [sendValue, value];
  };

  const { debounce: handleFetchCurrentFee } = useDebounceCallback(
    (information, setInformation) => {
      fetchCurrentFee(
        information,
        setInformation,
        information[CALCULATOR_FORM_COUNTRY_NAME]?.code,
        maxSendLimit
      );

      setPaste(false);
    },
    DEBOUNCE_SEC
  );

  const handleOnChange = (e) => {
    const { value, name } = e.target;

    let valueTrim = value ? format.toStrAmount(value.trim()) : '';
    let sendValue;
    let receiverValue;

    if (valueTrim?.length <= 1 && !numberUtils.isNumber(valueTrim)) {
      document.getElementsByName(name)[0].value = '';

      return;
    }

    let newInformation = { ...information };
    let newValidation = { ...validation };

    if (!valueTrim) {
      newValidation = {
        ...newValidation,
        [CALCULATOR_FORM_SEND_TO_NAME]: '',
        [CALCULATOR_FORM_RECEIVER_GET_NAME]: '',
      };
      setValidation(newValidation);

      document.getElementsByName(CALCULATOR_FORM_SEND_TO_NAME)[0].value = '';
      document.getElementsByName(CALCULATOR_FORM_RECEIVER_GET_NAME)[0].value =
        '';

      newInformation = {
        ...newInformation,
        [CALCULATOR_FORM_SEND_TO_NAME]: '',
        [CALCULATOR_FORM_RECEIVER_GET_NAME]: '',
        feeAmount: 0,
      };
      handleFetchCurrentFee(newInformation, setInformation);

      return;
    }

    if (!numberUtils.isNumber(valueTrim)) {
      valueTrim = valueTrim.match(AMOUNT_NUMBER_REGEX)[0];

      document.getElementsByName(name)[0].value = valueTrim;
    }

    if (value.includes(' ')) {
      document.getElementsByName(name)[0].value = valueTrim;
    }

    if (isSendField) {
      [sendValue, receiverValue] = handleSendAmountOnChange(valueTrim);

      newInformation = {
        ...newInformation,
        [CALCULATOR_FORM_SEND_TO_NAME]: sendValue,
        [CALCULATOR_FORM_RECEIVER_GET_NAME]: receiverValue,
      };
    }

    if (isReceiverField) {
      [sendValue, receiverValue] = handleReceiverGetOnChange(valueTrim);

      newInformation = {
        ...newInformation,
        [CALCULATOR_FORM_SEND_TO_NAME]: sendValue,
        [CALCULATOR_FORM_RECEIVER_GET_NAME]: receiverValue,
      };
    }

    if (isSendField) {
      newValidation = handleSendAmountValidation(newInformation, newValidation);
    }

    if (isReceiverField) {
      newValidation = handleGetReceiverAmountValidation(
        newInformation,
        newValidation
      );
    }

    setValidation(newValidation);

    handleFetchCurrentFee(newInformation, setInformation);
  };

  const handleFormLabelOnClick = (e, name) => {
    e.preventDefault();
    const inputEle = document.getElementsByName(name)[0];

    if (inputEle) {
      inputEle.focus();
    }
  };

  return (
    <FormGroupStyled>
      <FormLabel onClick={(e) => handleFormLabelOnClick(e, field.name)}>
        {t(field.label)}
        {field.isRequired && <span>*</span>}
      </FormLabel>
      <FormControl
        type={field.type}
        inputMode="decimal"
        autoComplete="off"
        name={field.name}
        defaultValue={
          information && information[field.name]
            ? format.toAmountCentStr(information[field.name])
            : ''
        }
        placeholder={field.placeholder}
        isInvalid={validation[field.name]}
        onChange={handleOnChange}
        onPaste={() => setPaste(true)}
        readOnly={field.isReadOnly}
      />
      {isSendField && (
        <CountryName>
          <FlagIcon src={COUNTRY_FLAG_REFERENCES['AUD']} />
          AUD
        </CountryName>
      )}
      {isReceiverField && (
        <CustomCurrencyDropdown
          maxSendLimit={maxSendLimit}
          receiveLimits={receiveLimits}
          currentRateValue={currentRateValue}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          currentCurrencySelected={currentCurrencySelected}
          handleSendAmountOnChange={handleSendAmountOnChange}
          handleGetReceiverAmountValidation={handleGetReceiverAmountValidation}
        />
      )}
      {isReceiverField && Boolean(currentRateValue) && (
        <Rate>{`1 AUD = ${format.toAmountStr2(currentRateValue)} ${
          currentCurrencySelected?.currencyCode
        }`}</Rate>
      )}
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[field.name])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (width: ${CALCULATOR_DESKTOP_WIDTH}px) {
    margin-bottom: 27px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  color: var(--c-calculator-input);

  height: 50px;
  border: 1px solid var(--ds-c-grey-dark);
  border-radius: 12px;
  padding-inline: 16px;
  padding-right: 132px;
  background: var(--ds-c-white);

  &:focus {
    background: var(--ds-c-white) !important;
    border: 1px solid var(--ds-c-grey-dark) !important;
    color: var(--c-calculator-input);
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-inline: 10px !important;
  }

  &[readonly] {
    background: var(--ds-c-white) !important;
    cursor: not-allowed;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-size: 28px;
    font-weight: 700;
    line-height: 35px;
    text-transform: none;

    color: var(--ds-c-grey-hover);
  }
`;
const Rate = styled.span`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue);

  position: absolute;
  top: 0px;
  right: 0px;
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (width: ${CALCULATOR_DESKTOP_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const CountryName = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  width: 122px;
  height: 50px;
  background-color: transparent;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
`;
const FlagIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  object-fit: cover;
  border: 1px transparent;
  border-radius: 50%;
  margin-right: 4px;
`;

export default FormInputAmountValidation;
