import RadioCheckedIcon from 'assets/icons/radio-checked-icon.svg';
import RadioboxHHMTIcon from 'assets/icons/radiobox-hhmt-icon.svg';
import RadioboxRemoxIcon from 'assets/icons/radiobox-remox-icon.svg';
import { ButtonFormCheck } from 'components/FormEditReceiver/components/FormInputSelectTypeOfReceiverRadio';
import { DESKTOP_MIN_WIDTH } from 'constants';
import {
  FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_GENDER_FIELD,
} from 'constants/fx';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { domUtils } from 'utils';

const MALE = 'MALE';
const FEMALE = 'FEMALE';

const FormInputGenderRadio = ({
  information,
  setInformation,
  validation,
  setValidation,
  isDisabled,
}) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();

  const isMale =
    information[FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME] === MALE;
  const isFemale =
    information[FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME] === FEMALE;

  const validRef = useRef(null);

  useEffect(() => {
    if (validation[FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME]) {
      validRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation]);

  const handleOnChange = (value) => {
    const newInformation = {
      ...information,
      [FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME]: value,
    };
    setInformation(newInformation);

    const newValidation = {
      ...validation,
      [FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME]: '',
    };
    setValidation(newValidation);
  };

  const renderFormCheckRadio1 = () => {
    const formCheckRadioClassName1 =
      FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME + '1';

    return (
      <>
        <FormCheck
          className={`${formCheckRadioClassName1} ${isMale && 'active'}`}
          inline
          type={
            FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_GENDER_FIELD.type
          }
          label={t('form_gender_male')}
          name={FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME}
          checked={isMale}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
          $isDisabled={isDisabled}
          readOnly
        />
        <ButtonFormCheck
          $width={
            domUtils.getOffsetWidthByClassName(formCheckRadioClassName1) + 44
          }
          onClick={() => (!isDisabled ? handleOnChange(MALE) : {})}
        >
          {t('form_gender_male')}
        </ButtonFormCheck>
      </>
    );
  };

  const renderFormCheckRadio2 = () => {
    const formCheckRadioClassName2 =
      FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME + '2';

    return (
      <>
        <FormCheck
          className={`${formCheckRadioClassName2} ${isFemale && 'active'}`}
          inline
          type={
            FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_GENDER_FIELD.type
          }
          label={t('form_gender_female')}
          name={FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME}
          checked={isFemale}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
          $isDisabled={isDisabled}
          style={{ marginRight: 0 }}
          readOnly
        />
        <ButtonFormCheck
          $width={domUtils.getOffsetWidthByClassName(formCheckRadioClassName2)}
          onClick={() => (!isDisabled ? handleOnChange(FEMALE) : {})}
        >
          {t('form_gender_female')}
        </ButtonFormCheck>
      </>
    );
  };

  return (
    <FormGroupStyled>
      <FormLabel onClick={(e) => e.preventDefault()}>
        {t(
          FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_GENDER_FIELD.label
        )}
        <span>*</span>
      </FormLabel>
      {renderFormCheckRadio1()}
      {renderFormCheckRadio2()}
      {validation[FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME] && (
        <FormControlFeedback ref={validRef} hidden={false} type="invalid">
          {t(validation[FX_ADD_TRANSACTION_ENTER_INFORMATION_GENDER_NAME])}
        </FormControlFeedback>
      )}
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;
  display: table;
  width: 100%;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
    clear: both;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  display: block;
  color: var(--ds-c-grey-dark);
  margin-bottom: 16px;

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
  }
`;
const FormCheck = styled(Form.Check)`
  margin: 0;
  margin-right: 44px;

  label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    margin-left: 0px;
    cursor: ${(props) =>
      props.$isDisabled ? 'not-allowed !important' : 'pointer !important'};
    user-select: none;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: ${(props) => `url(${props.$checkboxSrc}) !important`};
    background-position: center !important;
    width: 16px;
    height: 16px;
    cursor: ${(props) =>
      props.$isDisabled ? 'not-allowed !important' : 'pointer !important'};

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }

    &:checked[type='radio'] {
      background-image: url(${RadioCheckedIcon}) !important;
      background-position: center !important;
    }
  }

  &.active {
    label {
      font-weight: 700;
    }
  }
`;
const FormControlFeedback = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;
  margin-bottom: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputGenderRadio;
