import { HHMT_THEME } from 'constants';

const isHHMT = process.env.REACT_APP_APP_NAME === HHMT_THEME;

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_NOTIFICATION_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: isHHMT
    ? process.env.REACT_APP_FIREBASE_MESSAGING_HHMT_APP_ID
    : process.env.REACT_APP_FIREBASE_MESSAGING_REMOX_APP_ID,

  measurementId: process.env.REACT_APP_FIREBASE_MESSAGING_MEASUREMENT_ID,
};

/* indexedDB */
export const FIREBASE_INSTALLATIONS_DB_NAME = 'firebase-installations-database';
export const FIREBASE_INSTALLATIONS_STORE_NAME = 'firebase-installations-store';
export const FIREBASE_MESSAGING_DB_NAME = 'firebase-messaging-database';
export const FIREBASE_MESSAGING_STORE_NAME = 'firebase-messaging-store';

/* serviceWorker */
export const FIREBASE_MESSAGING_SW = `${window.location.origin}/firebase-messaging-sw.js`;
