import React from 'react';
import styled from 'styled-components';

const SizeBox = ({
  height = 12,
  width = 12,
  color = 'transparent',
  isTransparent = true,
}) => {
  return (
    <Line
      style={{
        height,
        width,
        backgroundColor: isTransparent ? 'transparent' : color,
      }}
    />
  );
};

const Line = styled.div`
  width: 8;
  height: 8;
`;

export default SizeBox;
