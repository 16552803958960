import EditIcon from 'assets/ver2/icons/edit-black-icon.svg';
import LocationIcon from 'assets/ver2/icons/location-outline-black-icon.svg';
import MobileIcon from 'assets/ver2/icons/mobile-icon.svg';
import { format } from 'helpers';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';

import Avatar from 'components/Profile/components/Avatar';

const PersonalDetailsMobile = ({
  handleOnOpenFormEditProfileInformationPopup,
  handleOnOpenFormEditProfileAddressPopup,
}) => {
  const { t } = useLang();
  const { state } = useStore();
  const { currentUser } = state;
  const { firstName, lastName, mobile, email, address } = currentUser || {};
  const { isHHMTTheme } = useTheme();

  return (
    <PersonalDetailsStyled>
      <NameEmailWrap>
        <Avatar
          width={44}
          height={44}
          background="linear-gradient(180deg, #DEE4EF 0%, #94A2B9 100%)"
          isShowName={false}
        />
        <FieldWrap style={{ display: 'block' }}>
          <FieldValue $isBold={true}>{`${firstName} ${lastName}`}</FieldValue>
          <FieldValue $isUppercase={true} style={{ marginTop: '4px' }}>
            {email}
          </FieldValue>
        </FieldWrap>
      </NameEmailWrap>
      <Information>
        <SubTitle>
          {t('label_contact_details')}{' '}
          <EditIconStyled
            src={EditIcon}
            alt="edit-icon"
            style={{
              filter: isHHMTTheme
                ? 'var(--ds-f-c-yellow)'
                : 'var(--ds-f-c-blue-sky)',
            }}
            onClick={handleOnOpenFormEditProfileInformationPopup}
          />
        </SubTitle>
        <FieldWrap $width="60%" style={{ marginBottom: '24px' }}>
          {mobile && (
            <FieldValue $isFlexBox={true}>
              <img src={MobileIcon} width={20} height={20} />
              <FieldValue>
                {mobile ? format.toMobileNumberStr(mobile) : '-'}
              </FieldValue>
            </FieldValue>
          )}
        </FieldWrap>
        <Divider />
        <SubTitle>
          {t('label_address')}{' '}
          <EditIconStyled
            src={EditIcon}
            alt="edit-icon"
            style={{
              filter: isHHMTTheme
                ? 'var(--ds-f-c-yellow)'
                : 'var(--ds-f-c-blue-sky)',
            }}
            onClick={handleOnOpenFormEditProfileAddressPopup}
          />
        </SubTitle>
        <FieldWrap>
          <FieldValue $isFlexBox={true}>
            <img src={LocationIcon} width={20} height={20} />
            <FieldValue style={{ textTransform: 'uppercase' }}>
              {address?.fullAddress}
            </FieldValue>
          </FieldValue>
        </FieldWrap>
      </Information>
    </PersonalDetailsStyled>
  );
};

const PersonalDetailsStyled = styled.div`
  width: 100%;
`;

const NameEmailWrap = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 44px calc(100% - 44px);
  grid-gap: 10px;
  margin-bottom: 12px;
`;

const Information = styled.div`
  padding: 16px;
  box-shadow: 0px 4px 15px 0px #0000001a;
  width: 100%;
  border-radius: 8px;
`;

const Title = styled.h4`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: var(--ds-c-blue);
  text-transform: uppercase;

  margin-top: 42px;
  margin-bottom: 16px;
`;

const SubTitle = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: initial;
  color: var(--ds-c-blue);

  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const EditIconStyled = styled.img`
  margin-left: 8px;
  cursor: pointer;
  filter: var(--ds-f-c-yellow);
`;

const FieldWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${(props) => props.$width || '100%'};
  height: fit-content;
`;
const FieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: ${(props) => (props.$isBold ? 700 : 500)};
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);
  text-transform: ${(props) => (props.$isUppercase ? 'uppercase' : 'initial')};

  width: 100%;
  margin: 0;
  padding: 0;

  & span {
    color: #7a8189;
  }

  ${(props) =>
    props.$isFlexBox &&
    `
    display: flex;
    gap: 8px;
  `}
`;

const Divider = styled.div`
  border-bottom: 1px solid var(--ds-bg-2);
  margin-block: 16px;
`;

export default PersonalDetailsMobile;
