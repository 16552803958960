import SwitchCheckedHHMTIcon from 'assets/icons/switch-checked-hhmt-icon.svg';
import SwitchCheckedRemoxIcon from 'assets/icons/switch-checked-remox-icon.svg';
import SwitchboxHHMTIcon from 'assets/icons/switchbox-hhmt-icon.svg';
import SwitchboxRemoxIcon from 'assets/icons/switchbox-remox-icon.svg';
import {
  DESKTOP_MIN_WIDTH,
  EDIT_RECEIVER_POSTAL_NAME,
  EDIT_RECEIVER_STATE_NAME,
  EDIT_RECEIVER_STREET_NAME,
  EDIT_RECEIVER_SUBURB_NAME,
  REGISTER_ADDRESS_SWITCH,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
} from 'constants';
import useClickLabelInputSwitch from 'hooks/useClickLabelInputSwitch';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const initialFormField =
  RESET_EDIT_RECEIVER_INFORMATION_STORE.residentialAddress;

const FormInputAddressSwitch = ({
  information,
  setInformation,
  setValidation,
}) => {
  const { t } = useLang();
  const { state } = useStore();
  const { currentReceiver } = state;
  const { isHHMTTheme } = useTheme();

  const { isEditReceiverEnterAddressManuallySelected } = information;

  const handleOnChange = () => {
    let newInformation = { ...information };

    if (
      isEditReceiverEnterAddressManuallySelected !==
      currentReceiver?.address?.manual
    ) {
      newInformation = {
        [EDIT_RECEIVER_STREET_NAME]: currentReceiver?.address?.street,
        [EDIT_RECEIVER_STATE_NAME]: currentReceiver?.address?.city,
        [EDIT_RECEIVER_SUBURB_NAME]: currentReceiver?.address?.suburb,
        [EDIT_RECEIVER_POSTAL_NAME]: currentReceiver?.address?.postcode,
        isEditReceiverEnterAddressManuallySelected:
          !isEditReceiverEnterAddressManuallySelected,
      };

      setInformation(newInformation);

      setValidation(initialFormField);

      return;
    }

    newInformation = {
      ...initialFormField,
      isEditReceiverEnterAddressManuallySelected:
        !isEditReceiverEnterAddressManuallySelected,
    };

    setInformation(newInformation);

    setValidation(initialFormField);
  };

  useClickLabelInputSwitch('form-check-label', handleOnChange, [
    isEditReceiverEnterAddressManuallySelected,
  ]);

  return (
    <FormGroupStyled>
      <FormSwitch
        type="switch"
        label={t('button_address_manual_switch')}
        name={REGISTER_ADDRESS_SWITCH}
        checked={isEditReceiverEnterAddressManuallySelected}
        onClick={handleOnChange}
        $checkboxSrc={isHHMTTheme ? SwitchboxHHMTIcon : SwitchboxRemoxIcon}
        $checkedSrc={
          isHHMTTheme ? SwitchCheckedHHMTIcon : SwitchCheckedRemoxIcon
        }
        readOnly
      />
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  display: inherit;
  margin-bottom: 16px;
  position: relative;
`;

const FormSwitch = styled(Form.Check)`
  margin: 0;
  margin-right: 44px;

  label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    margin-left: 4px;
    cursor: pointer !important;
    user-select: none;
    height: 32px;
    display: flex;
    align-items: center;
    padding-block: 4px;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  input:checked[type='checkbox'] {
    background-image: ${(props) => `url(${props.$checkedSrc}) !important`};
    background-position: center !important;
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: ${(props) => `url(${props.$checkboxSrc}) !important`};
    background-position: center !important;
    width: 40px !important;
    height: 24px !important;
    cursor: pointer !important;

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }
  }
`;

export default FormInputAddressSwitch;
