import { api } from 'api';
import ButtonCommon from 'components/common/ButtonCommon';
import { checkFormInputValidation } from 'components/common/func';
import {
  ADD_TRANSACTION_MESSAGE_FIELD,
  ADD_TRANSACTION_MESSAGE_NAME,
  ADD_TRANSACTION_OCCUPATION_NAME,
  ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS,
  ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME,
  ADD_TRANSACTION_PURPOSE_FIELD,
  ADD_TRANSACTION_PURPOSE_NAME,
  ADD_TRANSACTION_RELATIONSHIP_FIELD,
  ADD_TRANSACTION_RELATIONSHIP_NAME,
  ADD_TRANSACTION_SOURCE_FIELD,
  ADD_TRANSACTION_SOURCE_NAME,
  ADD_TRANSACTION_STEP_REFERENCE,
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  CONFIG_OCCUPATION_EXTRAS,
  RESET_ADD_TRANSACTION_INFORMATION_STORE,
} from 'constants';
import useAuth from 'hooks/useAuth';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import {
  SET_ADD_TRANSACTION_CURRENT_STEP,
  SET_ADD_TRANSACTION_INFORMATION,
} from 'store/action';
import styled from 'styled-components';
import FormInputMessage from '../../FormInputMessage';

import CustomSearchDropdown from 'components/common/CustomSearchDropdown';
import {
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  DESKTOP_MIN_WIDTH,
  SCREEN_ID_REMITTANCE_STEP_3,
} from 'constants';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import useReceiver from 'hooks/receiver/useReceiver';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useEvent from 'hooks/useEvent';
import { numberUtils } from 'utils';
import BodySectionHeader from '../../BodySectionHeader';
import CalculatorForm from '../../CalculatorForm/ver2';
import CustomOccupationDropdown from '../../CustomOccupationDropdown';
import {
  ActionWrapper,
  ButtonCommonWrap,
  NextWrap,
} from '../../Remittance/ver2';

const initialFormField =
  RESET_ADD_TRANSACTION_INFORMATION_STORE.additionalInformation;

const SCREEN_ID = SCREEN_ID_REMITTANCE_STEP_3;

const AdditionalInformation = ({
  countryConfig,
  handleOnToggleCancelPopup,
  isDisabled,
}) => {
  const { token } = useAuth();
  const { t, locate } = useLang();
  const { state, dispatch } = useStore();
  const {
    addTransactionInformation,
    configOccupation,
    configPurpose: configPurposeOfIndividual,
    configPurposeOfBusiness,
    configRelationship: configRelationshipOfIndividual,
    configRelationshipOfBusiness,
    configSourcesOfWealth,
    currentReceiver,
    addTransactionCurrentStep: currentStep,
  } = state;
  const { remittance, receiverPayoutMethod } = addTransactionInformation;

  const { isReceiverOfBusinessType } = useReceiver();

  const configRelationship = isReceiverOfBusinessType
    ? configRelationshipOfBusiness
    : configRelationshipOfIndividual;

  const configPurpose = isReceiverOfBusinessType
    ? configPurposeOfBusiness
    : configPurposeOfIndividual;

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);

  const { isDesktop } = useDetectDevice();

  const payoutMethod =
    receiverPayoutMethod[ADD_TRANSACTION_PAYOUT_METHOD_NAME] || null;

  const config = useGetConfig({
    countryConfig,
    dmCode: payoutMethod,
  });
  const { relationship, occupation, purpose, fundSource, message } = config;

  const sendAmount = Number(remittance[ADD_TRANSACTION_YOU_SEND_NAME] || 0);
  const sendAnotherAmount = Number(
    remittance[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] || 0
  );
  const sendTotalAmount = numberUtils.mathRound(sendAmount + sendAnotherAmount);

  const isShowFieldRequired = Boolean(sendTotalAmount >= 10000);

  const { isMakePayment } = useFormAddTransactionStore();

  const initialInformation = async (token, information) => {
    try {
      const { data } = await api.getOccupation(token);

      if (data) {
        const occupationInit = configOccupation?.find(
          (o) => o.key === data.job
        );
        const previousOccupationInit = configOccupation?.find(
          (o) => o.key === data.previousJob
        );

        if (occupation?.required) {
          const occupationEle = document.getElementsByName(
            ADD_TRANSACTION_OCCUPATION_NAME
          )[0];
          occupationInit &&
            occupationEle &&
            (occupationEle.value = occupationInit?.value);

          const previousOccupationEle = document.getElementsByName(
            ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME
          )[0];
          previousOccupationInit &&
            previousOccupationEle &&
            (previousOccupationEle.value = previousOccupationInit?.value);
        }

        const relationshipInit = configRelationship?.find(
          (p) => p.key === currentReceiver.relationship
        );

        const newInformation = {
          ...information,
          [ADD_TRANSACTION_OCCUPATION_NAME]: occupationInit,
          [ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]: previousOccupationInit,
          [ADD_TRANSACTION_RELATIONSHIP_NAME]: relationshipInit,
        };

        setInformation(newInformation);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    const purposeInit = configPurpose?.find(
      (p) => p.key === information[ADD_TRANSACTION_PURPOSE_NAME]
    );
    const purposeEle = document.getElementsByName(
      ADD_TRANSACTION_PURPOSE_NAME
    )[0];

    purposeInit && purposeEle && (purposeEle.value = purposeInit?.value);
  }, [information, locate, configPurpose]);

  useEffect(() => {
    const informationStore = addTransactionInformation.additionalInformation;

    if (!informationStore[ADD_TRANSACTION_OCCUPATION_NAME]) {
      initialInformation(token, information);

      return;
    }

    if (informationStore[ADD_TRANSACTION_PURPOSE_NAME]) {
      const purposeInit = configPurpose?.find(
        (p) => p.key === informationStore[ADD_TRANSACTION_PURPOSE_NAME]
      );
      const purposeEle = document.getElementsByName(
        ADD_TRANSACTION_PURPOSE_NAME
      )[0];

      purposeInit && purposeEle && (purposeEle.value = purposeInit?.value);
    }

    if (informationStore[ADD_TRANSACTION_OCCUPATION_NAME]) {
      const occupationInit = configOccupation?.find(
        (o) => o.key === informationStore[ADD_TRANSACTION_OCCUPATION_NAME]
      );
      const occupationEle = document.getElementsByName(
        ADD_TRANSACTION_OCCUPATION_NAME
      )[0];

      const previousOccupationInit = configOccupation?.find(
        (o) =>
          o.key === informationStore[ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]
      );
      const previousOccupationEle = document.getElementsByName(
        ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME
      )[0];

      occupationInit &&
        occupationEle &&
        (occupationEle.value = occupationInit?.value);

      previousOccupationInit &&
        previousOccupationEle &&
        (previousOccupationEle.value = previousOccupationInit?.value);
    }

    if (informationStore[ADD_TRANSACTION_RELATIONSHIP_NAME]) {
      const relationshipInit = configRelationship?.find(
        (p) => p.key === informationStore[ADD_TRANSACTION_RELATIONSHIP_NAME]
      );
      const relationshipEle = document.getElementsByName(
        ADD_TRANSACTION_RELATIONSHIP_NAME
      )[0];

      relationshipInit &&
        relationshipEle &&
        (relationshipEle.value = relationshipInit?.value);
    }

    if (informationStore[ADD_TRANSACTION_SOURCE_NAME]) {
      const sourceInit = configSourcesOfWealth?.find(
        (s) => s.key === informationStore[ADD_TRANSACTION_SOURCE_NAME]
      );
      const sourceEle = document.getElementsByName(
        ADD_TRANSACTION_SOURCE_NAME
      )[0];

      sourceInit && sourceEle && (sourceEle.value = sourceInit?.value);
    }

    if (informationStore[ADD_TRANSACTION_MESSAGE_NAME]) {
      const msgEle = document.getElementsByName(
        ADD_TRANSACTION_MESSAGE_NAME
      )[0];

      msgEle && (msgEle.value = informationStore[ADD_TRANSACTION_MESSAGE_NAME]);
    }

    setInformation(informationStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, addTransactionInformation, configPurpose, configOccupation]);

  const handleNextStep = () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    if (!relationship?.required) {
      delete newInformation[ADD_TRANSACTION_RELATIONSHIP_NAME];
      delete newValidation[ADD_TRANSACTION_RELATIONSHIP_NAME];
    }

    if (!occupation?.required) {
      delete newInformation[ADD_TRANSACTION_OCCUPATION_NAME];
      delete newValidation[ADD_TRANSACTION_OCCUPATION_NAME];
    }

    if (
      !CONFIG_OCCUPATION_EXTRAS.find(
        (occupation) =>
          occupation === information[ADD_TRANSACTION_OCCUPATION_NAME]?.key
      )
    ) {
      delete newInformation[ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME];
      delete newValidation[ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME];
    }

    if (!purpose?.required) {
      delete newInformation[ADD_TRANSACTION_PURPOSE_NAME];
      delete newValidation[ADD_TRANSACTION_PURPOSE_NAME];
    }

    if (!fundSource?.required) {
      delete newInformation[ADD_TRANSACTION_SOURCE_NAME];
      delete newValidation[ADD_TRANSACTION_SOURCE_NAME];
    }

    if (!message?.visible || !newInformation[ADD_TRANSACTION_MESSAGE_NAME]) {
      delete newInformation[ADD_TRANSACTION_MESSAGE_NAME];
      delete newValidation[ADD_TRANSACTION_MESSAGE_NAME];
    }

    const isAllowSubmit =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowSubmit) {
      dispatch({
        type: SET_ADD_TRANSACTION_INFORMATION,
        payload: {
          ...addTransactionInformation,
          [ADD_TRANSACTION_STEP_REFERENCE[currentStep]]: information,
        },
      });
      dispatch({
        type: SET_ADD_TRANSACTION_CURRENT_STEP,
        payload: currentStep + 1,
      });
    } else {
      if (relationship?.required) {
        newValidation = checkFormInputValidation(
          information[ADD_TRANSACTION_RELATIONSHIP_NAME]?.key,
          ADD_TRANSACTION_RELATIONSHIP_FIELD,
          newValidation
        );
      }

      if (occupation?.required) {
        newValidation = checkFormInputValidation(
          information[ADD_TRANSACTION_OCCUPATION_NAME]?.key,
          ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS[0],
          newValidation
        );
      }

      if (
        occupation?.required &&
        CONFIG_OCCUPATION_EXTRAS.find(
          (occupation) =>
            occupation === information[ADD_TRANSACTION_OCCUPATION_NAME]?.key
        )
      ) {
        newValidation = checkFormInputValidation(
          information[ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]?.key,
          ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS[1],
          newValidation
        );
      }

      if (purpose?.required) {
        newValidation = checkFormInputValidation(
          information[ADD_TRANSACTION_PURPOSE_NAME]?.key,
          ADD_TRANSACTION_PURPOSE_FIELD,
          newValidation
        );
      }

      if (fundSource?.required) {
        newValidation = checkFormInputValidation(
          information[ADD_TRANSACTION_SOURCE_NAME]?.key,
          ADD_TRANSACTION_SOURCE_FIELD,
          newValidation
        );
      }

      setValidation(newValidation);
    }
  };

  const handleBackStep = () => {
    dispatch({
      type: SET_ADD_TRANSACTION_CURRENT_STEP,
      payload: currentStep - 1,
    });
  };

  const renderCalculatorFormMobile = () => {
    if (isDesktop) return null;
    return (
      <CalculatorForm
        countryConfig={countryConfig}
        isCalculatorFormAccordion={true}
      />
    );
  };

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [],
    modalStyles: {},
    modalOptions: {},
  });
  useEffect(() => {
    if (!isShowEvent) return;
    showEventModal();
  }, [isShowEvent]);

  return (
    <>
      <BodySectionHeader
        titleBodySection={
          isMakePayment
            ? t('add_transaction_title_make_payment')
            : t('add_transaction_title_new_transaction')
        }
        titleSection={t('add_transaction_title_additional_information')}
        CalculatorComponent={
          isDesktop && <CalculatorForm countryConfig={countryConfig} />
        }
        onToggleCancelPopup={handleOnToggleCancelPopup}
      />
      <AdditionalInformationStyled>
        {relationship?.visible && (
          <CustomSearchDropdown
            field={ADD_TRANSACTION_RELATIONSHIP_FIELD}
            isConfigRequired={relationship?.required}
            data={configRelationship}
            keyFind="key"
            keyTranslate="value"
            noResultText="form_text_no_result"
            placeholder={t('form_placeholder_receiver_is_your')}
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            isDisabled={isDisabled}
          />
        )}
        {(isShowFieldRequired || occupation?.visible) && (
          <CustomOccupationDropdown
            config={occupation}
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            isDisabled={isDisabled}
          />
        )}
        {(isShowFieldRequired || purpose?.visible) && (
          <CustomSearchDropdown
            field={ADD_TRANSACTION_PURPOSE_FIELD}
            isConfigRequired={purpose?.required}
            data={configPurpose}
            keyFind="key"
            keyTranslate="value"
            noResultText="form_text_no_result"
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            isDisabled={isDisabled}
          />
        )}
        {(isShowFieldRequired || fundSource?.visible) && (
          <CustomSearchDropdown
            field={ADD_TRANSACTION_SOURCE_FIELD}
            isConfigRequired={fundSource?.required}
            data={configSourcesOfWealth}
            keyFind="key"
            keyTranslate="value"
            noResultText="form_text_no_result"
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            isDisabled={isDisabled}
          />
        )}
        {message?.visible && (
          <FormInputMessage
            config={message}
            field={ADD_TRANSACTION_MESSAGE_FIELD}
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            isDisabled={isDisabled}
          />
        )}
        <NextWrap>
          {renderCalculatorFormMobile()}
          <ActionWrapper>
            <ButtonCommonWrap>
              <ButtonCommon
                value={t('button_back')}
                onClick={handleBackStep}
                styles={{
                  margin: '0px',
                  width: !isDesktop && '100%',
                }}
                color="var(--ds-c-blue)"
                background="var(--ds-c-white)"
                borderNameVariable={isDesktop && '--border-filter'}
                isFill={false}
              />
            </ButtonCommonWrap>
            <ButtonCommon
              value={t('button_next')}
              color="var(--c-primary)"
              background="var(--bg-primary)"
              isFill={true}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              onClick={handleNextStep}
              isUseKeyDown
            />
          </ActionWrapper>
        </NextWrap>
      </AdditionalInformationStyled>
    </>
  );
};

const AdditionalInformationStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 24px;
  padding-inline: 0px;
  padding-bottom: 140px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 437px;
    margin-top: 0px;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

export default AdditionalInformation;
