import { fetchCurrentFee } from 'components/CalculatorForm/func';
import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import {
  CALCULATOR_FORM_COUNTRY_NAME,
  CALCULATOR_FORM_RECEIVER_GET_NAME,
  CALCULATOR_FORM_SEND_TO_NAME,
  CURRENCY_FLAG_REFERENCES,
} from 'constants';
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';

export const CALCULATOR_FORM_CURRENCY_NAME = 'calculator-form-currency-name';

const CustomCurrencyDropdown = ({
  maxSendLimit,
  receiveLimits,
  currentRateValue,
  information,
  setInformation,
  validation,
  setValidation,
  currentCurrencySelected,
  handleSendAmountOnChange,
  handleGetReceiverAmountValidation,
}) => {
  const [isShowDropdown, setShowDropdown] = useState(false);

  const isOnlyReceiverCurrency = receiveLimits?.length === 1;

  const handleFetchCurrentFee = async (information, setInformation) => {
    await fetchCurrentFee(
      information,
      setInformation,
      information[CALCULATOR_FORM_COUNTRY_NAME]?.code,
      maxSendLimit
    );
  };

  useEffect(() => {
    const sendAmount =
      Number(information[CALCULATOR_FORM_SEND_TO_NAME] || 0) || '';
    const receiverAmount =
      Number(information[CALCULATOR_FORM_RECEIVER_GET_NAME] || 0) || '';

    if (receiverAmount) {
      const [sendValue, receiverValue] = handleSendAmountOnChange(
        sendAmount,
        true
      );

      const newInformation = {
        ...information,
        [CALCULATOR_FORM_SEND_TO_NAME]: sendValue,
        [CALCULATOR_FORM_RECEIVER_GET_NAME]: receiverValue,
      };

      let newValidation = {
        ...validation,
        [CALCULATOR_FORM_RECEIVER_GET_NAME]: '',
      };

      newValidation = handleGetReceiverAmountValidation(
        newInformation,
        newValidation
      );
      setValidation(newValidation);

      handleFetchCurrentFee(newInformation, setInformation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRateValue]);

  const handleCurrencySelected = async (currency) => {
    const newInformation = {
      ...information,
      currentCurrencySelected: currency,
    };
    setInformation(newInformation);

    setShowDropdown(false);
  };

  const handleDropdowOnClick = (e, cb) => {
    e.preventDefault();

    if (isOnlyReceiverCurrency) return;

    setShowDropdown(!isShowDropdown);

    cb(e);
  };

  const handleDropdownOnToggle = () => {
    setShowDropdown(!isShowDropdown);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <ButtonToggle
      href="/"
      ref={ref}
      onClick={(e) => handleDropdowOnClick(e, onClick)}
    >
      {children}
      {!isOnlyReceiverCurrency && (
        <ArrowDownIcon
          color="#fff"
          styles={{
            position: 'absolute',
            right: '8px',
            top: '22px',
            transform: isShowDropdown && 'rotate(180deg)',
          }}
        />
      )}
    </ButtonToggle>
  ));

  return (
    <CurrencyDropdownStyled>
      <Dropdown onToggle={handleDropdownOnToggle}>
        <Dropdown.Toggle as={CustomToggle}>
          <CountryName $isOnlyReceiverCurrency={isOnlyReceiverCurrency}>
            {currentCurrencySelected && (
              <>
                <FlagIcon
                  src={
                    CURRENCY_FLAG_REFERENCES[
                      currentCurrencySelected?.currencyCode
                    ]
                  }
                />
                {currentCurrencySelected?.currencyCode}
              </>
            )}
          </CountryName>
        </Dropdown.Toggle>

        <DropdownMenu>
          {receiveLimits?.map((currency, index) => (
            <DropdownItem
              key={currency.currencyCode}
              eventKey={index}
              onClick={() => handleCurrencySelected(currency)}
            >
              <CountryNameItem
                style={{
                  color: 'var(--ds-c-grey-dark)',
                }}
              >
                <FlagIcon
                  src={CURRENCY_FLAG_REFERENCES[currency.currencyCode]}
                />
                {currency.currencyCode}
              </CountryNameItem>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </CurrencyDropdownStyled>
  );
};

const CurrencyDropdownStyled = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 27px;
  right: 10.5px;
`;
const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  height: fit-content;
  min-width: fit-content;
  box-shadow: var(--ds-bs-4);
  border-radius: 12px !important;
  inset: 0px 0px auto auto !important;
  transform: translate(9px, 50px) !important;
`;
const DropdownItem = styled(Dropdown.Item)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  text-transform: capitalize;
  width: 122px;
  height: 50px;
  background: transparent;

  &:hover {
    background: var(--ds-c-grey-light);
  }

  &:active {
    color: var(--ds-c-grey-dark);
  }

  &:first-child {
    border-radius: 12px 12px 0 0;
  }

  &:last-child {
    border-radius: 0 0 12px 12px;
  }
`;
const ButtonToggle = styled.a`
  font-family: var(--ff-primary);
  line-height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--ds-c-grey-dark);
  text-decoration: none;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  height: 40px;
  padding: 0;

  &:hover {
    background: transparent;
    border: none;
    color: var(--ds-c-grey-hover);

    & svg {
      filter: var(--ds-f-c-grey-hover);
    }
  }

  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }
`;
const CountryName = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #fff;

  width: 122px;
  height: 50px;
  background-color: var(--ds-c-grey-dark);
  justify-content: flex-end;
  align-items: center;
  padding-right: ${(props) =>
    props.$isOnlyReceiverCurrency ? '16px' : '46px'};
  display: flex;
  position: absolute;
  top: 1px;
  right: 0.5px;
  margin-right: -10px;
  border-radius: 0 11px 11px 0px;
`;
const CountryNameItem = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  width: 122px;
  height: 50px;
  display: flex;
  align-items: center;
`;
const FlagIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  object-fit: cover;
  border: 1px transparent;
  border-radius: 50%;
  margin-right: 4px;
`;

export default CustomCurrencyDropdown;
