const useInputMoveCursorToEnd = () => {
  const moveCursorToEnd = (inputEle) => {
    if (!inputEle) {
      return;
    }
    setTimeout(function () {
      inputEle.selectionStart = inputEle.selectionEnd = inputEle.value?.length;
      inputEle?.focus();
    }, 0);
  };

  const inputMoveCursorToEnd = (e) => {
    const { name } = e.target;
    const inputEle = document.getElementsByName(name)[0];
    if (inputEle) {
      moveCursorToEnd(inputEle);
    }
  };

  return { inputMoveCursorToEnd };
};

export default useInputMoveCursorToEnd;
