import { REGISTER_ADDRESS_FINDER_NAME } from 'constants';
import { DESKTOP_MIN_WIDTH, RESET_BACKDROP_STORE } from 'constants/common';
import useStore from 'hooks/useStore';
import { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { SET_SHOW_BACKDROP } from 'store/action';
import styled from 'styled-components';
import FormInputAddressFinderValidation from '../FormInputAddressFinderValidation';

const CustomAddressFinderDropdown = ({
  field,
  information,
  setInformation,
  validation,
  setValidation,
  setAddressFinder,
  checkFormInputValidation,
}) => {
  const { state, dispatch } = useStore();
  const { backdrop } = state;

  const [addressSuggestion, setAddressSuggestion] = useState(null);

  const isShowDropdown = () =>
    Boolean(addressSuggestion?.length) &&
    backdrop?.current === REGISTER_ADDRESS_FINDER_NAME &&
    backdrop?.isShow;

  const handleDropdownItemSelected = (e, address) => {
    e.preventDefault();

    const { fullAddress } = address;

    document.getElementsByName(REGISTER_ADDRESS_FINDER_NAME)[0].value =
      fullAddress;

    setAddressFinder(address);

    setValidation({
      ...validation,
      [field.name]: '',
    });

    dispatch({
      type: SET_SHOW_BACKDROP,
      payload: RESET_BACKDROP_STORE,
    });
  };

  return (
    <CustomAddressFinderDropdownStyled>
      <FormInputAddressFinderValidation
        field={field}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        addressSuggestion={addressSuggestion}
        setAddressSuggestion={setAddressSuggestion}
        setAddressFinder={setAddressFinder}
        checkFormInputValidation={checkFormInputValidation}
      />
      <DropdownMenu show={isShowDropdown()}>
        {addressSuggestion?.map((address, index) => (
          <DropdownItem
            key={address.id}
            eventKey={index + 1}
            onClick={(e) => handleDropdownItemSelected(e, address)}
          >
            {address.fullAddress}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </CustomAddressFinderDropdownStyled>
  );
};

const CustomAddressFinderDropdownStyled = styled.div`
  position: relative;
  width: 100%;
`;
const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  box-shadow: var(--ds-bs-4);
  max-height: 160px;
  border-radius: 0.375rem;
  width: 100%;
  overflow: auto;
  background: var(--ds-c-white);
  inset: 0px auto auto 0px !important;
  transform: translate(0px, 72px) !important;

  &::-webkit-scrollbar {
    width: 5px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ds-c-grey-hover);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;
const DropdownItem = styled(Dropdown.Item)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  padding: 10px;
  text-transform: uppercase;
  min-height: 40px;
  height: fit-content;
  white-space: break-spaces;
  background: var(--ds-c-white);

  a:first-child {
    border-radius: 0.375rem 0.375rem 0 0;
  }

  &:hover {
    background: var(--ds-c-grey-light);
  }

  &:active {
    color: var(--ds-c-grey-dark);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default CustomAddressFinderDropdown;
