import Spinner from 'react-bootstrap/Spinner';
import styled from 'styled-components';

const LoadingScreen = ({ pageYOffset, loadingStyles = {} }) => {
  return (
    <>
      <LoadingStyled $pageYOffset={pageYOffset} style={{ ...loadingStyles }} />

      <SpinnerStyled
        animation="border"
        variant="primary"
        $pageYOffset={pageYOffset}
      />
    </>
  );
};

const LoadingStyled = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% + ${(props) => props.$pageYOffset || 0}px);
  background: #212121;
  opacity: 0.25;
  z-index: 10000;
  top: 0;
`;

const SpinnerStyled = styled(Spinner)`
  position: absolute;
  z-index: 10001;
  color: var(--bg-primary) !important;
  top: calc(50% + ${(props) => props.$pageYOffset || 0}px - 16px);
  left: calc(50% - 16px);
`;

export default LoadingScreen;
