const ProfileIcon2BlueDark = ({
  size = { width: 24, height: 24 },
  styles = {},
  onClick = () => {},
}) => {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...styles }}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.6522 13.4688C15.1393 13.4688 14.7432 13.5949 14.2983 13.7365C13.7283 13.918 13.0782 14.125 12 14.125C10.9226 14.125 10.274 13.9183 9.70473 13.737C9.25958 13.5951 8.86297 13.4688 8.34783 13.4688C5.32337 13.4688 2.86957 15.9379 2.86957 18.9812V20.0312C2.86957 21.1182 3.74593 22 4.82609 22H19.1739C20.2541 22 21.1304 21.1182 21.1304 20.0312V18.9812C21.1304 15.9379 18.6766 13.4688 15.6522 13.4688ZM12 12.8125C15.2405 12.8125 17.8696 10.167 17.8696 6.90625C17.8696 3.64551 15.2405 1 12 1C8.75951 1 6.13044 3.64551 6.13044 6.90625C6.13044 10.167 8.75951 12.8125 12 12.8125Z"
        fill="var(--ds-c-blue-dark)"
      />
    </svg>
  );
};

export default ProfileIcon2BlueDark;
