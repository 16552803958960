const CloseIcon = ({
  color = '#0E1012',
  size = { width: 24, height: 24 },
  styles = {},
  onClick = () => {},
}) => {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...styles }}
      onClick={onClick}
    >
      <path
        d="M9 3L3 9"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
      <path
        d="M3 3L9 9"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default CloseIcon;
