/* eslint-disable no-unused-vars */
import { api } from 'api';
import ClearIcon from 'assets/ver2/icons/close-black-icon.svg';
import SearchIcon from 'assets/ver2/icons/search-icon.svg';
import { preventKeyDownEnter } from 'components/common/func';
import {
  DEBOUNCE_SEC,
  DESKTOP_MIN_WIDTH,
  RESET_BACKDROP_STORE,
} from 'constants/common';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import Form from 'react-bootstrap/Form';
import { SET_SHOW_BACKDROP } from 'store/action';
import styled from 'styled-components';

const FormInputAddressFinderValidation = ({
  field,
  addressFinder,
  setAddressFinder,
  setAddressSuggestion,
  setPickupLocationDataByAusPost,
  setError,
}) => {
  const { t } = useLang();
  const { dispatch } = useStore();

  const fetchLookupAddressSuggestion = async (value) => {
    try {
      const { data } = await api.lookupAddressLocality(
        value,
        'd6fe8ff05352dfff3c3a3367d7140364'
      );

      if (data?.payload) {
        setAddressSuggestion(data.payload);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };
  const checkLookupAddressSuggestion = async (value) => {
    await fetchLookupAddressSuggestion(value);

    setPickupLocationDataByAusPost(null);
    setError('');

    dispatch({
      type: SET_SHOW_BACKDROP,
      payload: {
        isShow: true,
        current: field.name,
      },
    });
  };
  const { debounce: handleLookupAddressSuggestion } = useDebounceCallback(
    (value) => {
      checkLookupAddressSuggestion(value);
    },
    DEBOUNCE_SEC
  );

  const onReset = () => {
    document.getElementsByName(field.name)[0].value = '';
    setAddressSuggestion(null);
    setPickupLocationDataByAusPost(null);
    setError('');
    setAddressFinder(null);
    dispatch({
      type: SET_SHOW_BACKDROP,
      payload: {
        isShow: false,
        current: RESET_BACKDROP_STORE.current,
        onClick: () => {},
      },
    });
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    const valueTrim = value.trim();

    if (!valueTrim) {
      setAddressFinder('');
      onReset();
      setPickupLocationDataByAusPost(null);
      setError('');
      return;
    }

    setAddressFinder(valueTrim);
    handleLookupAddressSuggestion(valueTrim);
  };

  const handleOnClearSearchInput = () => {
    setAddressFinder('');
    onReset();
  };
  const renderSearchInputIcon = () => {
    if (addressFinder)
      return (
        <SearchInputIconStyled
          src={ClearIcon}
          width={24}
          height={24}
          alt=""
          onClick={handleOnClearSearchInput}
        />
      );
    return (
      <SearchInputIconStyled
        src={SearchIcon}
        width={24}
        height={24}
        alt=""
        onClick={() => {}}
      />
    );
  };

  return (
    <FormGroupStyled onKeyDown={preventKeyDownEnter}>
      <FormControl
        type={field.type}
        name={field.name}
        defaultValue={addressFinder}
        placeholder={t(field.placeholder)}
        isInvalid={false}
        onChange={handleOnChange}
      />
      {renderSearchInputIcon()}
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  position: relative;
  margin-bottom: 0px;
`;

const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  border: 1px solid var(--ds-bg-2);
  border-radius: 8px;
  background: var(--ds-c-white);
  width: 100%;
  height: 44px;
  padding: 10px;
  padding-right: 44px;
  margin-inline: 0px;

  &:focus {
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &[readonly] {
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--ds-c-blue-disabled);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }
`;

const SearchInputIconStyled = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export default FormInputAddressFinderValidation;
