import {
  REGISTER_DOB_NAME,
  REGISTER_FISTNAME_NAME,
  REGISTER_MIDDLENAME_NAME,
  REGISTER_PERSONAL_DETAILS_DOB_FIELD,
  REGISTER_PERSONAL_DETAILS_NAME_FIELDS,
} from 'constants';
import { datePicker } from 'helpers';

const checkFirstNameValidation = (name, value, validation) => {
  const isFirstNameField = name === REGISTER_FISTNAME_NAME;

  if (isFirstNameField && value.includes(' ')) {
    validation = {
      ...validation,
      [name]: REGISTER_PERSONAL_DETAILS_NAME_FIELDS[0].msgInvalid2,
    };
  }

  return validation;
};

const checkDobValidation = (name, value, validation, isCheckAge) => {
  const isDobField = name === REGISTER_DOB_NAME;

  if (isDobField) {
    const { msgInvalid, msgInvalid2 } = REGISTER_PERSONAL_DETAILS_DOB_FIELD;
    const dobEle = document.getElementsByName(name)[0];

    if (dobEle) {
      const dobValue = dobEle.value;

      if (dobValue.length > 0 && dobValue.length < 10) {
        validation = {
          ...validation,
          [name]: msgInvalid2,
        };
      }
    }

    if (isCheckAge) {
      const isOfAgeToUse = datePicker.getOfAgeToUse(value);

      if (!isOfAgeToUse) {
        validation = {
          ...validation,
          [name]: msgInvalid,
        };
      }
    }
  }

  return validation;
};

const handleFieldValidation = (
  value,
  name,
  information,
  validation,
  msgInvalid,
  checkValidFn
) => {
  if (value) {
    const isValid = Boolean(checkValidFn(value));

    if (isValid) {
      validation = {
        ...validation,
        [name]: '',
      };
    }

    if (!isValid) {
      validation = {
        ...validation,
        [name]: msgInvalid,
      };
    }

    validation = checkFirstNameValidation(name, value, validation);

    validation = checkDobValidation(name, value, validation, true);
  }

  return validation;
};

const handleFieldEmptyValidation = (value, name, validation, msgRequired) => {
  if (!value) {
    validation = {
      ...validation,
      [name]: msgRequired,
    };
  }

  if (name === REGISTER_MIDDLENAME_NAME) {
    validation = {
      ...validation,
      [name]: '',
    };
  }

  validation = checkDobValidation(name, value, validation);

  return validation;
};

export const checkFormInputValidation = (
  value,
  field,
  information,
  validation
) => {
  const { name, checkValidFn, msgInvalid, msgRequired } = field;

  validation = handleFieldEmptyValidation(value, name, validation, msgRequired);

  validation = handleFieldValidation(
    value,
    name,
    information,
    validation,
    msgInvalid,
    checkValidFn
  );

  return validation;
};
