import RadioCheckedIcon from 'assets/icons/radio-checked-icon.svg';
import RadioboxHHMTIcon from 'assets/icons/radiobox-hhmt-icon.svg';
import RadioboxRemoxIcon from 'assets/icons/radiobox-remox-icon.svg';
import {
  DESKTOP_MIN_WIDTH,
  EDIT_RECEIVER_BANK_EWALLET_NAME,
  METHOD_BANK,
  METHOD_EWALLET,
} from 'constants';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const FormInputBankEwalletRadio = ({
  deliveryMethods,
  banksLength,
  ewalletsLength,
  isBanksChecked,
  setBanksChecked,
}) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();

  const formCheckRadioClassName1 = EDIT_RECEIVER_BANK_EWALLET_NAME + '1';
  const formCheckRadioClassName2 = EDIT_RECEIVER_BANK_EWALLET_NAME + '2';

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleOnChange = (value) => {
    setBanksChecked(value);
  };

  const renderFormCheckRadio1 = () => {
    if (!deliveryMethods?.includes(METHOD_BANK)) return null;
    return (
      <div onClick={() => handleOnChange(true)}>
        <FormCheck
          className={`${formCheckRadioClassName1} ${
            isBanksChecked && 'active'
          }`}
          inline
          type="radio"
          label={`${t('label_bank_account')} (${banksLength})`}
          name={EDIT_RECEIVER_BANK_EWALLET_NAME}
          checked={isBanksChecked}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
          readOnly={isBanksChecked}
        />
      </div>
    );
  };

  const renderFormCheckRadio2 = () => {
    if (!deliveryMethods?.includes(METHOD_EWALLET)) return null;
    return (
      <div onClick={() => handleOnChange(false)}>
        <FormCheck
          className={`${formCheckRadioClassName2} ${
            !isBanksChecked && 'active'
          }`}
          inline
          type="radio"
          label={`${t('label_ewallet')} (${ewalletsLength})`}
          name={EDIT_RECEIVER_BANK_EWALLET_NAME}
          checked={!isBanksChecked}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
          readOnly={!isBanksChecked}
          style={{ marginRight: 0 }}
        />
      </div>
    );
  };

  return (
    <FormGroupStyled>
      <FormLabel onClick={(e) => e.preventDefault()}>
        {t('label_account_type')}
      </FormLabel>
      <FormCheckWrapper>
        {renderFormCheckRadio1()}
        {renderFormCheckRadio2()}
      </FormCheckWrapper>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 24px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  display: block;
  color: var(--ds-c-grey-dark);
  margin-bottom: 16px;

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
  }
`;
const FormCheck = styled(Form.Check)`
  margin: 0;
  margin-right: 40px;

  label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    margin-left: 0px;
    cursor: pointer !important;
    user-select: none;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: ${(props) => `url(${props.$checkboxSrc}) !important`};
    background-position: center !important;
    width: 16px;
    height: 16px;
    cursor: pointer !important;

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }

    &:checked[type='radio'] {
      background-image: url(${RadioCheckedIcon}) !important;
      background-position: center !important;
    }
  }

  &.active {
    label {
      font-weight: 700;
    }
  }
`;
const FormCheckWrapper = styled.div`
  display: flex;
`;

export default FormInputBankEwalletRadio;
