const AddIcon = ({
  color = '#0E1012',
  size = { width: 60, height: 60 },
  strokeWidth = '1.2',
  styles = {},
  onClick = () => {},
}) => {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...styles }}
      onClick={onClick}
    >
      {/* <circle cx="30.5" cy="30" r="21.9" stroke="#0E1012" strokeWidth="1.2"/> */}
      <path
        d="M30 15L30 45"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
      <path
        d="M45 30L15 30"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default AddIcon;
