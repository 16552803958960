/* eslint-disable no-unused-vars */
import { LogUtil } from 'components/common/LogUtil';
import { initializeApp } from 'firebase/app';
import { limitToLast, off, onValue, query, ref } from 'firebase/database';
import { isEmpty, isFunction } from 'lodash';
import { useEffect, useId } from 'react';
import { objectUtils } from 'utils';
import { getRealtimeDB } from './useFirebase';
import useStore from './useStore';

const TAG = 'useRetriveDatabaseInstance';

const useRetriveDatabaseInstance = ({
  databaseURL = '',
  path = '',
  prop = '',
  actionName = '',
  isDisable = false,
  storeOptions = {
    isAppendToObject: false,
  },
  queryOptions = {
    isQuery: false,
    limitToLast: 0,
  },
}) => {
  const appId = useId();
  const { state, dispatch } = useStore();
  const { currentUser } = state || {};

  const firebaseAppInstance = initializeApp(
    {
      databaseURL,
    },
    appId
  );
  const db =
    !isEmpty(firebaseAppInstance) && getRealtimeDB(firebaseAppInstance);
  let queryOfData;
  let dataSnapshot;

  const retriveDataFromRealtimeDatabase = ({
    path = '',
    prop = '',
    callback,
  }) => {
    try {
      const dbRef = ref(db, path);

      if (queryOptions.isQuery) {
        queryOfData = query(
          dbRef,
          queryOptions.limitToLast && limitToLast(queryOptions.limitToLast)
        );
      } else {
        queryOfData = dbRef;
      }

      onValue(
        queryOfData,
        (snapshot) => {
          dataSnapshot = snapshot.val();

          if (isFunction(callback)) {
            dataSnapshot && callback(dataSnapshot);
          } else {
            const store = state?.[actionName];
            const payloadStore = prop
              ? storeOptions.isAppendToObject
                ? {
                    ...(objectUtils.isObject(store) ? store : {}),
                    [prop]: dataSnapshot,
                  }
                : { [prop]: dataSnapshot }
              : storeOptions.isAppendToObject
              ? {
                  ...(objectUtils.isObject(store) ? store : {}),
                  dataSnapshot,
                }
              : dataSnapshot;
            dispatch({
              type: actionName,
              payload: payloadStore,
            });
          }
        },
        {
          onlyOnce: false,
        }
      );
    } catch (error) {
      LogUtil.error(TAG, error?.message);
    }
  };

  useEffect(() => {
    if (currentUser && firebaseAppInstance && !isDisable) {
      retriveDataFromRealtimeDatabase({
        path,
        prop,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, firebaseAppInstance, isDisable]);

  const closeRealtimeDatabaseConnection = ({
    path = '',
    callback = () => {},
  }) => {
    if (!isEmpty(db) && !isEmpty(queryOfData) && !isEmpty(dataSnapshot)) {
      LogUtil.log(TAG, 'closeRealtimeDatabaseConnection xxxx path xxxx', path);
      off(queryOfData);
      callback();
    }
  };

  return { retriveDataFromRealtimeDatabase, closeRealtimeDatabaseConnection };
};

export default useRetriveDatabaseInstance;
