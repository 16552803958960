import ButtonLinkCommon from 'components/common/ButtonLinkCommon';
import { FORGOT_PASSWORD_EMAIL_NAME, LOGIN_PREFIX } from 'constants';
import useLang from 'hooks/useLang';
import styled from 'styled-components';
import { stringUtils } from 'utils';

const FormSendEmailSuccessMobile = ({ information }) => {
  const { t } = useLang();

  return (
    <>
      <TitleH3>{t('forgot_password_text_submitted')}</TitleH3>
      <Paragraph>
        {stringUtils.replaceKeyword(
          t('forgot_password_text_send_instruction'),
          [{ key: 'email', value: information[FORGOT_PASSWORD_EMAIL_NAME] }]
        )}
      </Paragraph>
      <FormForgotPasswordMobileStyled>
        <BackToLogin>
          <ButtonLinkCommon
            href={LOGIN_PREFIX}
            value={t('button_back_to_login')}
            styles={{
              margin: '0px',
              width: '100%',
              float: 'right',
            }}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
          />
        </BackToLogin>
      </FormForgotPasswordMobileStyled>
    </>
  );
};

const FormForgotPasswordMobileStyled = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  padding-bottom: 96px;
`;

const BackToLogin = styled.div`
  position: fixed;
  bottom: 0px;
  background: var(--ds-c-white);
  width: 100%;
  margin-inline: -24px;
  padding: 16px 24px;
  border-top: 1px solid var(--ds-c-grey-disabled);
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 24px;
`;

const TitleH3 = styled.h3`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;
`;

export default FormSendEmailSuccessMobile;
