import {
  HHMT_THEME,
  REMOX_THEME,
  THEME_REDIRECTED,
  THEME_REFERENCE,
} from '../constants';
import Cookies from '../helpers/cookies';

const useTheme = (themeParam) => {
  const themeRedirected = Cookies.get(THEME_REDIRECTED);

  const theme =
    themeParam ??
    themeRedirected ??
    THEME_REFERENCE[process.env.REACT_APP_APP_NAME] ??
    HHMT_THEME;

  const isHHMTTheme = theme === HHMT_THEME;

  const isRemoxTheme = theme === REMOX_THEME;

  Cookies.setTheme(THEME_REDIRECTED, theme);

  return { theme, isHHMTTheme, isRemoxTheme };
};

export default useTheme;
