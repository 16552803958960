import { api } from 'api';
import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import { DESKTOP_MIN_WIDTH, EDIT_RECEIVER_STATE_NAME } from 'constants';
import useInputFocus from 'hooks/useInputFocus';
import useLang from 'hooks/useLang';
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import { arrayUtils } from 'utils';

const SUBURBS_SEARCH_NAME = 'suburbs-search-field';

const CustomSuburbsDropdown = ({
  field,
  isConfigRequired,
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();

  const [searchValue, setSearchValue] = useState('');
  const [suburbsSearch, setSuburbsSearch] = useState(null);
  const [currentSuburbSelected, setCurrentSuburbSelected] = useState(null);
  const [suburbs, setSuburbs] = useState(null);
  const [isShowDropdown, setShowDropdown] = useState(false);

  const fetchSuburbs = async (stateId) => {
    try {
      const { data } = await api.getSuburbs(stateId);

      if (data) {
        setCurrentSuburbSelected(null);

        setSuburbs(data);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    if (information[EDIT_RECEIVER_STATE_NAME]?.id) {
      fetchSuburbs(information[EDIT_RECEIVER_STATE_NAME].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information[EDIT_RECEIVER_STATE_NAME]?.id]);

  useEffect(() => {
    if (suburbs) {
      const suburb = suburbs?.find(
        (c) => c.name === information[field.name]?.name
      );

      if (suburb) {
        setCurrentSuburbSelected(suburb);

        const newInformation = {
          ...information,
          [field.name]: suburb,
        };
        setInformation(newInformation);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suburbs]);

  useInputFocus(SUBURBS_SEARCH_NAME);

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    if (!value) {
      setSuburbsSearch(null);

      return;
    }

    const newSuburbSearch = suburbs?.filter(
      (suburb) =>
        suburb?.name?.toLowerCase()?.includes(value?.toLowerCase()) && suburb
    );
    setSuburbsSearch(newSuburbSearch);
  };

  const handleSuburbSelected = (suburb) => {
    setCurrentSuburbSelected(suburb);

    setSearchValue('');

    setSuburbsSearch(null);

    setShowDropdown(false);

    const newInformation = {
      ...information,
      [field.name]: suburb,
    };
    setInformation(newInformation);

    const newValidation = {
      ...validation,
      [field.name]: '',
    };
    setValidation(newValidation);
  };

  const handleDropdowOnClick = (e, cb) => {
    e.preventDefault();

    setShowDropdown(!isShowDropdown);

    cb(e);
  };

  const handleDropdownOnToggle = () => {
    setSearchValue('');
    setSuburbsSearch(null);

    setShowDropdown(!isShowDropdown);
  };

  const renderDropdownItems = (data) => {
    if (arrayUtils.isArrayEmpty(data)) {
      return (
        <DropdownItem
          eventKey={0}
          $isReceiversEmpty={arrayUtils.isArrayEmpty(data)}
        >
          <NoItemText>{t('form_text_no_result')}</NoItemText>
        </DropdownItem>
      );
    }

    return (
      <>
        {data?.map((item, index) => (
          <DropdownItem
            key={item.id}
            eventKey={index}
            onClick={() => handleSuburbSelected(item)}
          >
            {item.name}
          </DropdownItem>
        ))}
      </>
    );
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <ButtonToggle
      href="/"
      ref={ref}
      onClick={(e) => handleDropdowOnClick(e, onClick)}
      $isPlaceholder={!currentSuburbSelected?.name}
    >
      {children}
      <ArrowDownIcon
        color="var(--ds-c-grey-dark)"
        size={{ width: 14, height: 10 }}
        styles={{
          transform: isShowDropdown && 'rotate(180deg)',
        }}
      />
    </ButtonToggle>
  ));

  return (
    <SuburbDropdownStyled>
      <Label>
        {t(field.label)}
        {(isConfigRequired ?? field.isRequired) && <span>*</span>}
      </Label>
      <DropdownStyled
        onToggle={handleDropdownOnToggle}
        $isInvalid={validation[field.name]}
      >
        <Dropdown.Toggle as={CustomToggle}>
          {currentSuburbSelected?.name || t(field.placeholder)}
        </Dropdown.Toggle>

        <DropdownMenuWrap>
          <SearchInput
            name={SUBURBS_SEARCH_NAME}
            value={searchValue || ''}
            placeholder={t('form_placeholder_type_to_search')}
            onChange={handleSearch}
          />
          <DropdownMenu>
            {renderDropdownItems(suburbsSearch || suburbs)}
          </DropdownMenu>
        </DropdownMenuWrap>
      </DropdownStyled>
      {validation[field.name] && (
        <FormControlFeedback>{t(validation[field.name])}</FormControlFeedback>
      )}
    </SuburbDropdownStyled>
  );
};

const SuburbDropdownStyled = styled.div`
  margin-bottom: 32px;
`;
const DropdownStyled = styled(Dropdown)`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 44px;
  height: fit-content;
  background: var(--ds-c-white);
  border: ${(props) =>
    props.$isInvalid ? '1px solid var(--ds-c-red)' : '1px solid #eef2f5'};
  border-radius: 12px;
`;
const DropdownMenuWrap = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  width: 100%;
  height: 44px;
  background: transparent;
  box-shadow: var(--ds-bs-4);
  position: absolute;
  inset: 0px auto auto 0px !important;
  transform: translate(0px, 44px) !important;
`;
const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  max-height: 160px;
  border-top: 1px solid #eef2f5;
  border-radius: 0 0 12px 12px;
  width: 100%;
  overflow: auto;
  background: var(--ds-c-white);
  margin-top: -1px !important;
  box-shadow: var(--ds-bs-3) !important;
  inset: 0px auto auto 0px !important;
  transform: translate(0px, 44px) !important;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ds-c-grey-hover);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;
const DropdownItem = styled(Dropdown.Item)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  padding: 10px;
  text-transform: capitalize;
  min-height: 40px;
  height: fit-content;
  white-space: break-spaces;
  background: var(--ds-c-white);

  a:first-child {
    border-radius: 0.375rem 0.375rem 0 0;
  }

  &:hover {
    background: ${(props) =>
      props.$isReceiversEmpty ? 'var(--ds-c-white)' : 'var(--ds-c-grey-light)'};
    cursor: ${(props) => (props.$isReceiversEmpty ? 'default' : 'pointer')};
  }

  &:active {
    color: var(--ds-c-grey-dark);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const SearchInput = styled.input`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: none;
  border-radius: 12px 12px 0 0;
  background: var(--ds-c-white);
  width: 100%;
  min-height: 44px;
  height: fit-content;
  padding: 10px;

  &:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: initial;
    color: var(--ds-c-grey-hover);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const NoItemText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #b0b6bd;
  text-transform: initial;

  margin: 0px;
  padding: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ButtonToggle = styled.a`
  font-family: var(--ff-primary);
  line-height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) =>
    props.$isPlaceholder ? '#b0b6bd' : 'var(--ds-c-grey-dark)'};
  text-transform: uppercase;
  text-decoration: none;

  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 8px 10px;
  padding-right: 32px;
  width: 100%;
  height: 44px;
  flex-wrap: wrap;
  gap: 4px;

  &:hover {
    background: transparent;
    border: none;
    color: var(--ds-c-grey-dark-hover);
  }

  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  & svg {
    position: absolute;
    right: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ds-c-grey-hover);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const Label = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  margin-bottom: 8px;

  & span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const FormControlFeedback = styled.span`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default CustomSuburbsDropdown;
