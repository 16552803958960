import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import useLang from 'hooks/useLang';
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';

const CustomNotificationActionDropdown = ({
  styles = {},
  dropdownMenuStyles = {},
  onReadAll,
  onClearAll,
}) => {
  const { t } = useLang();

  const [isShowDropdown, setShowDropdown] = useState(false);

  const handleDropdownOnToggle = () => {
    setShowDropdown(!isShowDropdown);
  };

  const CustomToggle = React.forwardRef(({ onClick }, ref) => (
    <ButtonToggle
      href="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();

        onClick(e);
      }}
      style={styles}
    >
      <p>{t('label_actions')}</p>
      <ArrowDownIcon
        color="var(--ds-c-sky)"
        size={{
          width: 16,
          height: 16,
        }}
        styles={{
          padding: '3px',
          transform: isShowDropdown && 'rotate(180deg)',
        }}
      />
    </ButtonToggle>
  ));

  return (
    <>
      <ActionDropdownStyled $dropdownMenuStyles={dropdownMenuStyles}>
        <Dropdown onToggle={handleDropdownOnToggle}>
          <Dropdown.Toggle as={CustomToggle} />
          <DropdownMenu>
            <DropdownItem key={1} eventKey={0} onClick={onReadAll}>
              {t('label_read_all')}
            </DropdownItem>
            <DropdownItem key={2} eventKey={1} onClick={onClearAll}>
              {t('label_clear_all')}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </ActionDropdownStyled>
    </>
  );
};

const ActionDropdownStyled = styled.div`
  display: flex;
  align-items: center;

  & .dropdown-menu {
    transform: ${(props) => props.$dropdownMenuStyles.transform};
  }
`;

const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  min-width: 94px;
  height: fit-content;
  box-shadow: var(--ds-bs-4);
  border-radius: 12px;
  inset: 0px 0px auto auto !important;
  transform: translate(0px, 35px) !important;
`;
const DropdownItem = styled(Dropdown.Item)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
  height: 40px;
  background: var(--ds-c-white);

  &:hover {
    background: var(--ds-c-grey-light);
  }

  &:active {
    color: var(--ds-c-grey-dark);
  }

  &:nth-child(1) {
    border-radius: 12px 12px 0px 0px;
  }

  &:nth-child(2) {
    border-radius: 0px 0px 12px 12px;
  }
`;

const ButtonToggle = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ds-c-white);
  border: 1px solid var(--ds-c-sky);
  border-radius: 12px;
  padding: 8px;
  text-decoration: none;
  width: fit-content;
  height: fit-content;

  &:hover {
    opacity: 0.5;
  }

  &:focus {
    background: var(--ds-c-white);
    border: 1px solid var(--ds-c-sky);
    border-radius: 12px;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background: var(--ds-c-white);
    border: 1px solid var(--ds-c-sky);
    border-radius: 12px;
    outline: none;
    box-shadow: none;
  }

  & p {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1px;
    color: var(--ds-c-sky);

    padding: 0;
    margin: 0;
    margin-right: 4px;
  }

  & img {
    padding-top: 3px;
    padding-bottom: 3px;
  }
`;

export default CustomNotificationActionDropdown;
