import useLang from 'hooks/useLang';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import ButtonCommon from '../../../../common/ButtonCommon';

const TableEstimatedPopupMobile = ({ isOpen, onClose }) => {
  const { t } = useLang();
  return (
    <>
      <style>
        {`.table-estimated-backdrop {
          background: #212121;
          opacity: 0.25!important;
          display: initial!important;
        }`}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={onClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="table-estimated-backdrop"
        centered
      >
        <ModalBody>
          {t('add_transaction_bank_table_title')}
          <OptionTitle className="option-1">
            {t('add_transaction_bank_table_row_2_column_1')}
          </OptionTitle>
          <OptionContent>
            <BoldText
              dangerouslySetInnerHTML={{
                __html: t('add_transaction_bank_table_row_1_column_2'),
              }}
            />
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html: t('add_transaction_bank_table_row_2_column_2'),
              }}
            />
          </OptionContent>
          <OptionContent>
            <BoldText
              dangerouslySetInnerHTML={{
                __html: t('add_transaction_bank_table_row_1_column_3'),
              }}
            />
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html: t('add_transaction_bank_table_row_2_column_3'),
              }}
            />
          </OptionContent>
          <OptionTitle className="option-2">
            {t('add_transaction_bank_table_row_3_column_1')}
          </OptionTitle>
          <OptionContent>
            {t('add_transaction_bank_table_row_3_column_2')}
          </OptionContent>
          <NoteText>{t('add_transaction_bank_table_note')}</NoteText>
        </ModalBody>
        <ButtonWrap>
          <ButtonCommon
            color="var(--ds-c-black)"
            background="var(--c-tertiary)"
            value={t('button_close')}
            borderNameVariable="--border-secondary"
            styles={{
              border: 'var(--border-primary)',
              width: '150px',
              minHeight: '40px',
            }}
            onClick={onClose}
            isFill={false}
          />
        </ButtonWrap>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    max-width: fit-content;
    transform: none;
  }

  & .modal-content {
    padding: 16px 16px 0px 16px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 866px;
    height: 388px;
    margin-inline: auto;
    border: none;
    overflow-y: scroll;
  }
`;
const ModalBody = styled(Modal.Body)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  padding: 0;
  margin-bottom: 0px;
`;
const OptionTitle = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  width: 100%;
  border-radius: 12px;
  padding: 10px;
  margin-top: 16px;

  &.option-1 {
    background: #d2d9e7;
  }

  &.option-2 {
    background: #e8c6d0;
  }
`;
const OptionContent = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  width: 100%;
  margin-top: 16px;
  padding-inline: 10px;

  & span {
    &.yellow {
      color: var(--bg-primary);
    }
  }
`;

const BoldText = styled.div`
  font-weight: 700;

  & b {
    color: var(--ds-c-yellow);
  }
`;

const NoteText = styled.div`
  margin-top: 16px;
  color: var(--ds-c-red);
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 72px;
  width: 100%;
  background: #fff;
  position: sticky;
  bottom: 0px;
`;

export default TableEstimatedPopupMobile;
