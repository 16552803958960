import { backgroundReference, colorReference } from 'constants';
import React from 'react';
import styled from 'styled-components';

const AddTxnStep1Icon = ({
  status = 'disable' || 'in-progress' || 'completed',
}) => {
  return (
    <SvgStyled $backgroundColor={backgroundReference[status]}>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_9249_63903)">
          <circle cx="14.5833" cy="14.5833" r="4.58333" fill="white" />
          <path
            d="M4.58301 0C4.35289 0 4.16634 0.186548 4.16634 0.416667V2.91667C4.16634 3.14679 4.35289 3.33333 4.58301 3.33333C4.81313 3.33333 4.99967 3.14679 4.99967 2.91667V0.416667C4.99967 0.186548 4.81313 0 4.58301 0Z"
            fill={colorReference[status]}
          />
          <path
            d="M10.4163 0C10.1862 0 9.99967 0.186548 9.99967 0.416667V2.91667C9.99967 3.14679 10.1862 3.33333 10.4163 3.33333C10.6465 3.33333 10.833 3.14679 10.833 2.91667V0.416667C10.833 0.186548 10.6465 0 10.4163 0Z"
            fill={colorReference[status]}
          />
          <path
            d="M3.33301 2.91667V1.66667H2.49967C1.5792 1.66667 0.833008 2.41286 0.833008 3.33333V16.6667C0.833008 17.5871 1.5792 18.3333 2.49967 18.3333H9.58255C8.79794 17.2888 8.33301 15.9903 8.33301 14.5833C8.33301 11.2716 10.9088 8.56141 14.1663 8.347V3.33333C14.1663 2.41286 13.4201 1.66667 12.4997 1.66667H11.6663V2.91667C11.6663 3.60702 11.1067 4.16667 10.4163 4.16667C9.72599 4.16667 9.16634 3.60702 9.16634 2.91667V1.66667H5.83301V2.91667C5.83301 3.60702 5.27336 4.16667 4.58301 4.16667C3.89265 4.16667 3.33301 3.60702 3.33301 2.91667Z"
            fill={colorReference[status]}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.16699 14.5834C9.16699 11.5953 11.5956 9.16669 14.5837 9.16669C17.5718 9.16669 20.0003 11.5953 20.0003 14.5834C20.0003 17.5714 17.5718 20 14.5837 20C11.5956 20 9.16699 17.5714 9.16699 14.5834ZM14.8456 13.9881H14.286C14.1194 13.9881 13.9884 13.8572 13.9884 13.6905C13.9884 13.5238 14.1194 13.3929 14.286 13.3929H15.7384C16.0658 13.3929 16.3337 13.125 16.3337 12.7976C16.3337 12.4703 16.0658 12.2024 15.7384 12.2024H15.1789V11.6072C15.1789 11.3215 14.975 11.3259 14.7056 11.3316C14.6662 11.3325 14.6254 11.3334 14.5837 11.3334C14.5419 11.3334 14.5011 11.3325 14.4618 11.3316C14.1923 11.3259 13.9884 11.3215 13.9884 11.6072V12.2024C13.9884 12.2084 13.9899 12.2128 13.9914 12.2173C13.9929 12.2217 13.9944 12.2262 13.9944 12.2322C13.3158 12.3691 12.7979 12.9703 12.7979 13.6905C12.7979 14.5119 13.4646 15.1786 14.286 15.1786H14.8456C15.0122 15.1786 15.1432 15.3095 15.1432 15.5C15.1432 15.6667 15.0122 15.7976 14.8456 15.7976H13.3932C13.0658 15.7976 12.7979 16.0655 12.7979 16.3929C12.7979 16.7262 13.0658 16.9881 13.3932 16.9881H13.9884V17.5595C13.9884 17.8267 14.1668 17.8176 14.4103 17.8052C14.4652 17.8025 14.5234 17.7995 14.5837 17.7995C14.6439 17.7995 14.7021 17.8025 14.757 17.8052C15.0005 17.8176 15.1789 17.8267 15.1789 17.5595V16.9524C15.8396 16.7976 16.3337 16.2084 16.3337 15.4762C16.3337 14.6548 15.667 13.9881 14.8456 13.9881Z"
            fill={colorReference[status]}
          />
          <path
            d="M3.33301 7.08335C3.33301 6.85324 3.51956 6.66669 3.74967 6.66669H11.2497C11.4798 6.66669 11.6663 6.85324 11.6663 7.08335C11.6663 7.31347 11.4798 7.50002 11.2497 7.50002H3.74967C3.51956 7.50002 3.33301 7.31347 3.33301 7.08335Z"
            fill="white"
          />
          <path
            d="M3.33301 10.4167C3.33301 10.1866 3.51956 10 3.74967 10H7.91634C8.14646 10 8.33301 10.1866 8.33301 10.4167C8.33301 10.6468 8.14646 10.8334 7.91634 10.8334H3.74967C3.51956 10.8334 3.33301 10.6468 3.33301 10.4167Z"
            fill="white"
          />
          <path
            d="M3.74967 13.3334C3.51956 13.3334 3.33301 13.5199 3.33301 13.75C3.33301 13.9801 3.51956 14.1667 3.74967 14.1667H6.24967C6.47979 14.1667 6.66634 13.9801 6.66634 13.75C6.66634 13.5199 6.47979 13.3334 6.24967 13.3334H3.74967Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_9249_63903">
            <rect width={20} height={20} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgStyled>
  );
};

const SvgStyled = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

export default AddTxnStep1Icon;
