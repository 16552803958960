import {
  DASHBOARD_PREFIX,
  FX_DASHBOARD_PREFIX,
  FX_TRANSACTION_ADD_PREFIX,
  OFFERS_PREFIX,
  PROFILE_PREFIX,
  PROMOTIONS_EVENTS_PREFIX,
  PROMOTIONS_PREFIX,
  RESET_PASSWORD_PREFIX,
  TRANSACTION_ADD_PREFIX,
} from 'constants/router';

export const SCREEN_ID_REMITTANCE_HOME = 'REMITTANCE_HOME';
export const SCREEN_ID_REMITTANCE_STEP_1 = 'REMITTANCE_STEP_1';
export const SCREEN_ID_REMITTANCE_STEP_2 = 'REMITTANCE_STEP_2';
export const SCREEN_ID_REMITTANCE_STEP_3 = 'REMITTANCE_STEP_3';
export const SCREEN_ID_REMITTANCE_STEP_4 = 'REMITTANCE_STEP_4';
export const SCREEN_ID_REMITTANCE_SUCCESS = 'REMITTANCE_SUCCESS';
export const SCREEN_ID_REMITTANCE_DETAILS = 'REMITTANCE_DETAILS';
export const SCREEN_ID_EXCHANGE_HOME = 'EXCHANGE_HOME';
export const SCREEN_ID_EXCHANGE_STEP_1 = 'EXCHANGE_STEP_1';
export const SCREEN_ID_EXCHANGE_STEP_2 = 'EXCHANGE_STEP_2';
export const SCREEN_ID_EXCHANGE_STEP_3 = 'EXCHANGE_STEP_3';
export const SCREEN_ID_EXCHANGE_STEP_4 = 'EXCHANGE_STEP_4';
export const SCREEN_ID_EXCHANGE_STEP_5 = 'EXCHANGE_STEP_5';
export const SCREEN_ID_EXCHANGE_STEP_6 = 'EXCHANGE_STEP_6';
export const SCREEN_ID_EXCHANGE_SUCCESS = 'EXCHANGE_SUCCESS';
export const SCREEN_ID_EXCHANGE_DETAILS = 'EXCHANGE_DETAILS';
export const SCREEN_ID_OFFER_PROMOTIONS = 'OFFER_PROMOTIONS';
export const SCREEN_ID_OFFER_VOUCHERS = 'OFFER_VOUCHERS';
export const SCREEN_ID_OFFER_EVENTS = 'OFFER_EVENTS';
export const SCREEN_ID_PROFILE_DETAILS = 'PROFILE_DETAILS';
export const SCREEN_ID_PROFILE_SECURITY = 'PROFILE_SECURITY';
export const SCREEN_ID_PROFILE_DETAILS_EDIT = 'PROFILE_DETAILS_EDIT';
export const SCREEN_ID_PROFILE_PASSWORD_RESET = 'PROFILE_PASSWORD_RESET';
export const SCREEN_ID_PASSWORD_RESET = 'PASSWORD_RESET';

export const SCREEN_ID_REFERENCE_PATH = {
  REMITTANCE_HOME: DASHBOARD_PREFIX,
  REMITTANCE_STEP_1: TRANSACTION_ADD_PREFIX,
  REMITTANCE_STEP_2: TRANSACTION_ADD_PREFIX,
  REMITTANCE_STEP_3: TRANSACTION_ADD_PREFIX,
  REMITTANCE_STEP_4: TRANSACTION_ADD_PREFIX,
  REMITTANCE_SUCCESS: TRANSACTION_ADD_PREFIX,
  EXCHANGE_HOME: FX_DASHBOARD_PREFIX,
  EXCHANGE_STEP_1: FX_TRANSACTION_ADD_PREFIX,
  EXCHANGE_STEP_2: FX_TRANSACTION_ADD_PREFIX,
  EXCHANGE_STEP_3: FX_TRANSACTION_ADD_PREFIX,
  EXCHANGE_STEP_4: FX_TRANSACTION_ADD_PREFIX,
  EXCHANGE_STEP_5: FX_TRANSACTION_ADD_PREFIX,
  EXCHANGE_STEP_6: FX_TRANSACTION_ADD_PREFIX,
  EXCHANGE_SUCCESS: FX_TRANSACTION_ADD_PREFIX,
  OFFER_PROMOTIONS: PROMOTIONS_PREFIX,
  OFFER_VOUCHERS: OFFERS_PREFIX,
  OFFER_EVENTS: PROMOTIONS_EVENTS_PREFIX,
  PROFILE_DETAILS: PROFILE_PREFIX,
  PROFILE_SECURITY: PROFILE_PREFIX,
  SCREEN_ID_PROFILE_DETAILS_EDIT: PROFILE_PREFIX,
  SCREEN_ID_PROFILE_PASSWORD_RESET: PROFILE_PREFIX,
  SCREEN_ID_PASSWORD_RESET: RESET_PASSWORD_PREFIX,
};
