import styled from 'styled-components';
import { arrayUtils } from 'utils';
import TransactionList from '../components/TransactionList';

const TransactionsMobile = ({
  transactions,
  setTransactions,
  searchValue,
  applyFiltered,
  currentSearchBy,
  currentPage,
  setCurrentPage,
  setTotalElements,
  setTotalPages,
  isFetching,
  setFetching,
  isSearched,
  setSearched,
}) => {
  return (
    <TransactionsMobileStyled $isEmpty={arrayUtils.isArrayEmpty(transactions)}>
      <TransactionList
        transactions={transactions}
        setTransactions={setTransactions}
        searchValue={searchValue}
        applyFiltered={applyFiltered}
        currentSearchBy={currentSearchBy}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalElements={setTotalElements}
        setTotalPages={setTotalPages}
        isFetching={isFetching}
        setFetching={setFetching}
        isSearched={isSearched}
        setSearched={setSearched}
      />
    </TransactionsMobileStyled>
  );
};

const TransactionsMobileStyled = styled.div`
  display: ${(props) => (props.$isEmpty ? 'flex' : 'block')};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(props) => (props.$isEmpty ? 'calc(100% - 200px)' : 'fit-content')};
  padding-top: ${(props) => (props.$isEmpty ? '0px' : '16px')};
  padding-bottom: ${(props) => (props.$isEmpty ? '40px' : '99px')};
`;

export default TransactionsMobile;
