import { DESKTOP_MIN_WIDTH, REGISTER_STEP_DESCS } from 'constants';
import useLang from 'hooks/useLang';
import styled from 'styled-components';

const ProcessSteps = ({ currentStep, setCurrentStep }) => {
  const { t } = useLang();

  const isDisable = currentStep === 4;

  const stepState = (stepIndex) => {
    if (isDisable) return 'disable';

    if (currentStep === stepIndex) return 'in-progress';

    if (currentStep > stepIndex) return 'completed';
  };

  const handleOnClick = (stepIndex) => {
    if (isDisable) {
      return;
    }

    if (currentStep > stepIndex) {
      setCurrentStep(stepIndex);
    }
  };

  return (
    <ProcessStepsStyled>
      {REGISTER_STEP_DESCS.map((stepDesc, index) => (
        <StepWrap
          key={stepDesc}
          className={stepState(index)}
          onClick={() => handleOnClick(index)}
        >
          <StepBar />
          <StepDesc>{t(stepDesc)}</StepDesc>
        </StepWrap>
      ))}
    </ProcessStepsStyled>
  );
};

const ProcessStepsStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 668px;
    margin-inline: auto;
    margin-top: 24px;
  }
`;

const StepWrap = styled.div`
  margin-right: 4px;
  width: 25%;
  cursor: not-allowed;

  &:last-child {
    margin-right: 0px;
  }

  &.in-progress {
    div {
      background: #06faa2;
      cursor: default;
    }

    p {
      color: var(--ds-c-grey-dark);
      cursor: default;
    }
  }

  &.completed {
    div {
      background: #04d288;
      cursor: pointer;
    }

    p {
      color: var(--ds-c-green-default);
      font-weight: 700;
      cursor: pointer;
    }
  }

  &.disable {
    div {
      background: #04d288;
      cursor: not-allowed;
    }

    p {
      color: var(--ds-c-green-default);
      font-weight: 700;
      cursor: not-allowed;
    }
  }
`;
const StepBar = styled.div`
  width: 100%;
  height: 8px;
  background: var(--ds-c-grey-disabled);
  border-radius: 10px;
`;
const StepDesc = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #d0dce6;
  padding-top: 8px;
  padding-right: 2px;
  margin-bottom: 0px;
  width: 100%;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default ProcessSteps;
