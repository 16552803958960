import VoucherIcon from 'assets/ver2/icons/voucher-icon-small-black.svg';
import {
  fetchAllDiscounts,
  getDiscountsTypePromotion,
  getDiscountsTypeVoucher,
} from 'components/Discount/func';
import {
  DESKTOP_MIN_WIDTH,
  DISCOUNT_ACTIVE,
  DISCOUNT_COMING_SOON,
  DISCOUNT_EXPIRED,
  DISCOUNT_FULLY_REDEEMED,
  DISCOUNT_REDEEMED,
  SCREEN_ID_OFFER_VOUCHERS,
  VOUCHER,
} from 'constants';
import useAuth from 'hooks/useAuth';
import useEvent from 'hooks/useEvent';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DiscountsTab from '../../DiscountsTab';
import OfferSection from '../components/OfferSection';

const SCREEN_ID = SCREEN_ID_OFFER_VOUCHERS;

const Offers = () => {
  const { t } = useLang();
  const { token } = useAuth();

  const [promotion, setPromotion] = useState(null);
  const [voucher, setVoucher] = useState(null);

  const isFetching = useRef();

  useEffect(() => {
    if (token && !voucher && !promotion && !isFetching.current) {
      isFetching.current = true;

      fetchAllDiscounts(token, (data) => {
        setPromotion(getDiscountsTypePromotion(data));

        setVoucher(getDiscountsTypeVoucher(data));

        isFetching.current = false;
      });
    }
  }, [token]);

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [],
    modalStyles: {},
    modalOptions: {},
  });
  useEffect(() => {
    if (!isShowEvent) return;
    showEventModal();
  }, [isShowEvent]);

  if (isEmpty(voucher))
    return (
      <OffersStyled $isCenter={true}>
        <DiscountsTab items={[]} type={VOUCHER} />
      </OffersStyled>
    );
  return (
    <>
      <OffersStyled>
        <OfferSection
          title={t('label_offers_special')}
          descIcon={VoucherIcon}
          descText={t('label_offers_special_desc')}
          emptyText={t('label_offers_voucher_empty')}
          offers={voucher?.filter((v) => v?.status === DISCOUNT_ACTIVE)}
        />
        <OfferSection
          title={t('label_offers_upcoming')}
          offers={voucher?.filter((v) => v?.status === DISCOUNT_COMING_SOON)}
          emptyText={t('label_offers_voucher_empty')}
        />
        <OfferSection
          title={t('label_offers_redeemed_expired')}
          offers={voucher?.filter(
            (v) =>
              v?.status === DISCOUNT_REDEEMED ||
              v?.status === DISCOUNT_EXPIRED ||
              v?.status === DISCOUNT_FULLY_REDEEMED
          )}
          emptyText={t('label_offers_voucher_empty')}
          isShowDivider={false}
        />
      </OffersStyled>
    </>
  );
};

const OffersStyled = styled.div`
  display: inline-block;
  width: 100%;
  height: fit-content;

  ${(props) =>
    props.$isCenter &&
    `
  height: calc(100vh - 248px);
  display: flex;
  align-items: center;
  justify-content: center;
  `}

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    ${(props) =>
      props.$isCenter &&
      `
    height: calc(100vh - 144px);
    `}
  }
`;

export default Offers;
