import InformPopup from 'components/common/InformPopup';
import { preventOnClick } from 'components/common/func';
import {
  ACCOUNT_ACTIVATION_PREFIX,
  DESKTOP_COLLAPSE_MIN_WIDTH,
  DESKTOP_MIN_WIDTH,
  FORGOT_PASSWORD_PREFIX,
  HOME_URL,
  LOGIN_PREFIX,
  NAV_MENUS_UNAUTHENTICATE,
  ORDER_EMAIL,
  PRODUCT_LOGO,
  REGISTER_PREFIX,
  RESET_PASSWORD_PREFIX,
  pathsHideLoginResgisterButton,
} from 'constants';
import { NAV_MENUS_AUTHENTICATED } from 'constants/header/ver2';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import { FX_MAKE_PAYMENT_KEY } from 'hooks/fx/useFxAddTransaction';
import useLang from 'hooks/useLang';
import usePage from 'hooks/usePage';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useAuth from '../../../../hooks/useAuth';
import ButtonCommon from '../../ButtonCommon';
import ButtonLinkCommon from '../../ButtonLinkCommon';
import CustomLangDropdown from '../components/CustomLangDropdown';
import CustomNotificationDropdown from '../components/CustomNotificationDropdown';

const navStyles = {
  0: {
    marginRight: '79px !important',
  },
  1: {
    marginRight: '188px !important',
  },
};

const navLinkColorReference = {
  'var(--ds-c-grey-dark)': 'var(--ds-c-grey)',
  'var(--ds-c-white)': 'var(--ds-c-grey-hover)',
  'var(--ds-c-blue-dark)': 'var(--ds-c-blue-hover)',
};

const Header = () => {
  const { t, locate } = useLang();
  const { theme, isHHMTTheme } = useTheme();
  const { isAuthenticated, logout } = useAuth();

  const { state } = useStore();
  const { notifications, isPending } = state || {};

  const { pathname } = useLocation();
  const isLoginButtonActive = pathname.includes(LOGIN_PREFIX);
  const isRegisterButtonActive = pathname.includes(REGISTER_PREFIX);

  const [isShowLogoutPopup, setShowLogoutPopup] = useState(false);

  const isHeaderHidden = pathsHideLoginResgisterButton?.find((path) => {
    return pathname === path;
  });

  const {
    isAddReceiverPage,
    isAddTransactionPage,
    isFxAddTransactionPage,
    isFxAddTransactionAsGuestPage,
    isFxLoginPage,
  } = usePage();
  const isNavLinkAuthenticatedHidden =
    isAuthenticated &&
    (isAddReceiverPage || isAddTransactionPage || isFxAddTransactionPage);
  const isNavLinkUnAuthenticateHidden =
    !isAuthenticated && (isFxAddTransactionAsGuestPage || isFxLoginPage);

  const isNavLinkActive = (href, href2, href3) => {
    return (
      pathname.includes(href) ||
      pathname.includes(href2) ||
      pathname.includes(href3)
    );
  };

  const navLinkActiveStyles = {
    0: {
      color: 'var(--ds-c-white)',
      borderBottom: '3px solid var(--root-header-btn-active)',
    },
    1: {
      fontWeight: 700,
      color: 'var(--root-header-bg)',
      backgroundColor: 'var(--root-header-btn-active)',
    },
  };

  const navLinkDeactiveStyles = {
    0: {
      color: isHHMTTheme ? 'var(--ds-c-white)' : 'var(--ds-c-blue)',
    },
    1: {
      color: isHHMTTheme ? 'var(--ds-c-white)' : 'var(--ds-c-blue)',
    },
  };

  const getNavLinkActiveStyles = (href, href2, href3) =>
    isNavLinkActive(href, href2, href3)
      ? isAuthenticated
        ? navLinkActiveStyles[1]
        : navLinkActiveStyles[0]
      : {};

  const getNavLinkColor = (href) =>
    isNavLinkActive(href)
      ? isAuthenticated
        ? navLinkActiveStyles[1].color
        : navLinkActiveStyles[0].color
      : isAuthenticated
      ? navLinkDeactiveStyles[1].color
      : navLinkDeactiveStyles[0].color;

  const getNavLinkColorHover = (href) =>
    isNavLinkActive(href)
      ? isAuthenticated
        ? navLinkColorReference[navLinkActiveStyles[1].color]
        : navLinkColorReference[navLinkActiveStyles[0].color]
      : isAuthenticated
      ? navLinkColorReference[navLinkDeactiveStyles[1].color]
      : navLinkColorReference[navLinkDeactiveStyles[0].color];

  const getNavStyles = () => (isAuthenticated ? navStyles[1] : navStyles[0]);

  const handleOnClickNavLinkItem = (e) => {
    const isPreventRedirect = !isAuthenticated || isNavLinkAuthenticatedHidden;
    if (isPreventRedirect) {
      preventOnClick(e);
      return;
    }
    Cookies.remove(MAKE_PAYMENT_KEY);
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);
  };
  const handleOnClickANavLinkItem = (e, isPreventRedirect) => {
    if (isPreventRedirect) {
      preventOnClick(e);
      return;
    }
    Cookies.remove(MAKE_PAYMENT_KEY);
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);
  };

  const handleLogoutPopupToggle = () => {
    setShowLogoutPopup(!isShowLogoutPopup);
  };

  return (
    <>
      {isShowLogoutPopup && (
        <InformPopup
          isOpen={isShowLogoutPopup}
          content={t('popup_logout_confirm_desc')}
          buttonLabel1={t('button_no')}
          buttonLabel2={t('button_yes')}
          onClose={handleLogoutPopupToggle}
          onClick={() => {
            handleLogoutPopupToggle();

            logout();
          }}
          isBackdropOverlap={false}
        />
      )}

      <NavbarStyled>
        {isPending && <MaskNavbarDisable />}
        <NavContainer>
          <NavbarBrand
            href={`${HOME_URL[theme]}?lang=${locate}`}
            onClick={() => {
              Cookies.remove(MAKE_PAYMENT_KEY);
              Cookies.remove(FX_MAKE_PAYMENT_KEY);
              Cookies.remove(ORDER_EMAIL);
            }}
          >
            <img
              src={PRODUCT_LOGO[theme]}
              className="d-inline-block align-top"
              alt="Brand logo"
            />
          </NavbarBrand>
          <NavRight>
            <NavStyled className="me-auto" styles={getNavStyles()}>
              {isAuthenticated &&
                NAV_MENUS_AUTHENTICATED.map((nav) => (
                  <NavLink
                    key={nav.href}
                    to={nav.href}
                    onClick={(e) => handleOnClickNavLinkItem(e)}
                    style={getNavLinkActiveStyles(
                      nav.href,
                      nav.href2,
                      nav.href3
                    )}
                    color={getNavLinkColor(nav.href)}
                    $colorHover={getNavLinkColorHover(nav.href)}
                    $isHidden={isNavLinkAuthenticatedHidden}
                  >
                    {isNavLinkActive(nav.href, nav.href2, nav.href3) ? (
                      <>
                        {nav.icon && (
                          <nav.icon
                            size={{ width: 24, height: 24 }}
                            color={'var(--root-header-bg)'}
                            reverseColor={'var(--root-header-btn-inactive)'}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {nav.iconOutline && (
                          <nav.iconOutline
                            size={{ width: 24, height: 24 }}
                            color={'var(--root-header-btn-inactive)'}
                            reverseColor={'var(--root-header-bg)'}
                          />
                        )}
                      </>
                    )}
                    {t(nav.text) || null}
                  </NavLink>
                ))}
              {!isAuthenticated &&
                NAV_MENUS_UNAUTHENTICATE.map((nav) => (
                  <ANavLink
                    key={nav.href}
                    href={`${HOME_URL[theme]}${nav.href}?lang=${locate}`}
                    style={getNavLinkActiveStyles(nav.href)}
                    color={getNavLinkColor(nav.href)}
                    $colorHover={getNavLinkColorHover(nav.href)}
                    $isHidden={isNavLinkUnAuthenticateHidden}
                    onClick={(e) =>
                      handleOnClickANavLinkItem(
                        e,
                        isNavLinkUnAuthenticateHidden
                      )
                    }
                  >
                    {isNavLinkActive(nav.href) ? (
                      <>
                        {nav.icon && (
                          <nav.icon
                            size={{ width: 24, height: 24 }}
                            color={'var(--root-header-bg)'}
                            reverseColor={'var(--root-header-btn-inactive)'}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {nav.iconOutline && (
                          <nav.iconOutline
                            size={{ width: 24, height: 24 }}
                            color={'var(--root-header-btn-inactive)'}
                            reverseColor={'var(--root-header-bg)'}
                          />
                        )}
                      </>
                    )}
                    {t(nav.text) || null}
                  </ANavLink>
                ))}
            </NavStyled>
            <ButtonWrap>
              {isAuthenticated && <CustomNotificationDropdown />}
              {isAuthenticated && (
                <ButtonCommon
                  value={t('button_logout')}
                  styles={{
                    width: 'fit-content',
                  }}
                  color="var(--button-logout-txt)"
                  background="var(--button-logout-bg)"
                  onClick={handleLogoutPopupToggle}
                />
              )}
              {!isHeaderHidden && !isAuthenticated && (
                <>
                  {pathname === REGISTER_PREFIX && (
                    <ButtonLinkCommon
                      href={LOGIN_PREFIX}
                      value={t('button_login')}
                      styles={{
                        width: '110px',
                        border: !isLoginButtonActive && 'var(--border-primary)',
                      }}
                      color={
                        isLoginButtonActive
                          ? 'var(--root-header-text-active)'
                          : 'var(--c-secondary)'
                      }
                      background={
                        isLoginButtonActive
                          ? 'var(--root-header-btn-active)'
                          : 'var(--root-header-btn-active)'
                      }
                      isFill={true}
                    />
                  )}
                  {(pathname === LOGIN_PREFIX ||
                    pathname === RESET_PASSWORD_PREFIX ||
                    pathname === FORGOT_PASSWORD_PREFIX ||
                    pathname === ACCOUNT_ACTIVATION_PREFIX) && (
                    <ButtonLinkCommon
                      href={REGISTER_PREFIX}
                      value={t('button_register')}
                      styles={{
                        width: '110px',
                        border:
                          !isRegisterButtonActive && 'var(--border-primary)',
                      }}
                      color={
                        isRegisterButtonActive
                          ? 'var(--root-header-text-active)'
                          : 'var(--c-secondary)'
                      }
                      background={
                        isRegisterButtonActive
                          ? 'var(--root-header-btn-active)'
                          : 'var(--root-header-btn-active)'
                      }
                      isFill={true}
                    />
                  )}
                </>
              )}
              <CustomLangDropdown />
            </ButtonWrap>
          </NavRight>
        </NavContainer>
      </NavbarStyled>
    </>
  );
};

const MaskNavbarDisable = styled.div`
  padding: 0px;
  background-color: transparent;
  height: 72px;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 3;
  cursor: not-allowed;
`;

const NavbarStyled = styled(Navbar)`
  padding: 16px 40px;
  background-color: var(--root-header-bg);
  box-shadow: var(--root-header-bs);
  height: 72px;
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 2;
`;

const NavContainer = styled.div`
  flex: 0 auto;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  padding-inline: 40px;
  display: flex;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: ${DESKTOP_MIN_WIDTH}px;
    min-width: ${DESKTOP_MIN_WIDTH}px;
    max-width: ${DESKTOP_MIN_WIDTH}px;
  }

  @media screen and (min-width: ${DESKTOP_COLLAPSE_MIN_WIDTH}px) {
    width: ${DESKTOP_COLLAPSE_MIN_WIDTH}px;
    min-width: ${DESKTOP_COLLAPSE_MIN_WIDTH}px;
    max-width: ${DESKTOP_COLLAPSE_MIN_WIDTH}px;
  }
`;

const NavbarBrand = styled(Navbar.Brand)`
  margin-right: 0px;

  img {
    width: 177px;
    height: 50px;
    object-fit: contain;
  }
`;

const NavRight = styled.div`
  display: flex;
`;

const NavStyled = styled(Nav)`
  height: 72px;

  @media screen and (min-width: 1336px) {
    margin-right: 72px !important;
  }

  @media screen and (min-width: 1920px) {
    margin-right: 72px !important;
  }
`;
const NavLink = styled(Link)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${(props) => props.color};
  text-decoration: none;

  padding-inline: 24px !important;
  display: flex;
  align-items: center;
  transition: 0.3s ease;
  opacity: ${(props) => (props.$isHidden ? 0 : 1)};
  cursor: ${(props) => (props.$isHidden ? 'default' : 'pointer')};

  svg {
    margin-right: 8px;
  }

  &:hover {
    color: ${(props) => props.$colorHover}!important;

    svg {
      opacity: 0.7;
    }
  }
`;

const ANavLink = styled.a`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-transform: uppercase;
  color: ${(props) => props.color};
  text-decoration: none;

  padding-inline: 24px !important;
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.$isHidden ? 0 : 1)};
  cursor: ${(props) => (props.$isHidden ? 'default' : 'pointer')};

  svg {
    margin-right: 8px;
  }

  &:hover {
    color: ${(props) => props.$colorHover}!important;

    svg {
      opacity: 0.7;
    }
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
`;

export default Header;
