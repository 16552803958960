import { checkFormInputValidation } from 'components/common/func';
import TooltipCommon from 'components/common/TooltipCommon';
import TooltipCommonMobile from 'components/common/TooltipCommon/mobile';
import {
  DESKTOP_MIN_WIDTH,
  EDIT_RECEIVER_STATE_NAME,
  EDIT_RECEIVER_STREET_NAME,
  EDIT_RECEIVER_SUBURB_NAME,
} from 'constants';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const customFormInputStyleMobile = {};

const customFormInputStyle = {};

const FormAddressInputValidation = ({
  field,
  isConfigRequired,
  styles = {},
  placeholder,
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();

  const { isMobile, isTablet, isDesktop } = useDetectDevice();

  const isTextColorDisabled = [].includes(field.name);

  const handleStreetNameValidation = (name, value, validation) => {
    const isStreetNameField = name === EDIT_RECEIVER_STREET_NAME;

    if (isStreetNameField) {
      if (value.length > 140) {
        validation = {
          ...validation,
          [name]: field.msgLimited,
        };
      }
    }

    return validation;
  };

  const handleStateSuburbValidation = (name, value, validation) => {
    if (
      name === EDIT_RECEIVER_STATE_NAME ||
      name === EDIT_RECEIVER_SUBURB_NAME
    ) {
      if (value.length > 35) {
        validation = {
          ...validation,
          [name]: field.msgLimited,
        };
      }
    }

    return validation;
  };

  const handleOnChange = async (e, field) => {
    let { value, name } = e.target;

    let newInformation = { ...information };

    let newValidation = { ...validation };

    if (name === EDIT_RECEIVER_STREET_NAME) {
      newInformation = {
        ...newInformation,
        [name]: value?.trim() ? value.toUpperCase() : '',
      };

      newValidation = checkFormInputValidation(
        newInformation[name],
        field,
        newValidation
      );
    } else {
      newInformation = {
        ...newInformation,
        [name]: {
          id: 0,
          name: value ? value.toUpperCase() : '',
        },
      };

      newValidation = checkFormInputValidation(
        newInformation[name].name,
        field,
        newValidation
      );
    }

    if (name === EDIT_RECEIVER_STREET_NAME) {
      newValidation = handleStreetNameValidation(name, value, newValidation);
    }

    if (name === EDIT_RECEIVER_SUBURB_NAME) {
      newValidation = handleStateSuburbValidation(name, value, newValidation);
    }

    setInformation(newInformation);

    setValidation(newValidation);
  };

  const handleOnBlur = async (e, field) => {
    handleOnChange(e, field);
  };

  const handleFormLabelOnClick = (e, name) => {
    e.preventDefault();

    if (isDesktop) {
      const inputEle = document.getElementsByName(name)[0];

      if (inputEle) {
        inputEle.focus();
      }
    }
  };

  return (
    <FormGroupStyled
      style={
        isMobile || isTablet
          ? { ...customFormInputStyleMobile[field.name], ...styles }
          : { ...customFormInputStyle[field.name], ...styles }
      }
    >
      <FormLabel onClick={(e) => handleFormLabelOnClick(e, field.name)}>
        {t(field.label)}
        {(isConfigRequired ?? field.isRequired) && <span>*</span>}
        {field.tooltip && (
          <>
            {isMobile || isTablet ? (
              <TooltipCommonMobile
                text={t(field.tooltip)}
                name={field.name}
                label={t(field.label)}
              />
            ) : (
              <TooltipCommon text={t(field.tooltip)} />
            )}
          </>
        )}
      </FormLabel>
      <FormControl
        type={field.type}
        name={field.name}
        value={
          field.name === EDIT_RECEIVER_STREET_NAME
            ? information[field.name]
            : information[field.name]?.name
        }
        placeholder={placeholder || t(field.placeholderText)}
        isInvalid={validation[field.name]}
        onChange={(e) => handleOnChange(e, field)}
        onBlur={(e) => handleOnBlur(e, field)}
        readOnly={field.isReadOnly}
        $isTextColorDisabled={isTextColorDisabled}
      />
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[field.name])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 32px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;
  height: 19px;

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: ${(props) =>
    props.$isTextColorDisabled
      ? 'var(--ds-c-grey-neutral)'
      : 'var(--ds-c-grey-dark)'};
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: ${(props) =>
    props.$isTextColorDisabled
      ? 'var(--ds-c-grey-disabled)'
      : 'var(--ds-c-white)'};
  cursor: ${(props) => props.$isTextColorDisabled && 'not-allowed'};

  &:focus {
    background: ${(props) =>
      props.$isTextColorDisabled && 'var(--ds-c-grey-disabled)!important'};
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-inline: 10px !important;
  }

  &[readonly] {
    background: ${(props) =>
      props.$isTextColorDisabled && 'var(--ds-c-grey-disabled)!important'};
    border: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormAddressInputValidation;
