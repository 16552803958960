const ReceiverIcon = ({ color = 'var(--ds-c-grey-dark)', size }) => {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="9"
        r="3"
        fill={color || '#FBFBFB'}
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4515 15.9084C17.5089 16.0121 17.4794 16.1415 17.3844 16.2125C15.8829 17.3352 14.0192 18 12.0002 18C9.98114 18 8.11738 17.3351 6.61588 16.2124C6.5209 16.1414 6.49137 16.0121 6.5488 15.9083C7.49896 14.1915 9.58257 13 12.0001 13C14.4177 13 16.5014 14.1915 17.4515 15.9084Z"
        fill={color || '#FBFBFB'}
        strokeWidth="1.5"
      />
      <path
        d="M17.5 3.5H17.7C19.4913 3.5 20.387 3.5 20.9435 4.0565C21.5 4.61299 21.5 5.50866 21.5 7.3V7.5"
        stroke={color || '#FBFBFB'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M17.5 20.5H17.7C19.4913 20.5 20.387 20.5 20.9435 19.9435C21.5 19.387 21.5 18.4913 21.5 16.7V16.5"
        stroke={color || '#FBFBFB'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.5 3.5H6.3C4.50866 3.5 3.61299 3.5 3.0565 4.0565C2.5 4.61299 2.5 5.50866 2.5 7.3V7.5"
        stroke={color || '#FBFBFB'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M6.5 20.5H6.3C4.50866 20.5 3.61299 20.5 3.0565 19.9435C2.5 19.387 2.5 18.4913 2.5 16.7V16.5"
        stroke={color || '#FBFBFB'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ReceiverIcon;
