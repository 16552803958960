import {
  ADD_TRANSACTION_PAYOUT_METHODS,
  ADD_TRANSACTION_PAYOUT_METHODS_OF_BUSINESS,
  DESKTOP_MIN_WIDTH,
} from 'constants';
import useReceiver from 'hooks/receiver/useReceiver';
import useCheckServiceAlerts from 'hooks/service-alerts/useCheckServiceAlerts';
import useServiceAlertsStore from 'hooks/service-alerts/useServiceAlertsStore';
import { Fragment } from 'react';
import styled from 'styled-components';
import PayoutMethodItem from '../PayoutMethodItem/ver2';

const PayoutMethodsGrid = ({
  countryConfig,
  information,
  setInformation,
  setValidation,
  setInformationChangeBackup,
  setOpenDiscountImportantPopup,
  isDisabled,
  isSelectRequired,
}) => {
  const { serviceAlertsByCountryCode } = useServiceAlertsStore();
  const { checkByPayoutMethod } = useCheckServiceAlerts();
  const { checkServiceAlertsOfPayoutMethodItemDisable } = checkByPayoutMethod;

  const { isReceiverOfBusinessType } = useReceiver();

  const payoutMethods = isReceiverOfBusinessType
    ? ADD_TRANSACTION_PAYOUT_METHODS_OF_BUSINESS
    : ADD_TRANSACTION_PAYOUT_METHODS;

  return (
    <PayoutMethodsGridStyled>
      {payoutMethods.map((method, index) => (
        <Fragment key={`${method.method}-${index}`}>
          {countryConfig?.dmList?.find((dm) => dm.code === method.method) && (
            <PayoutMethodItem
              key={method.method}
              countryConfig={countryConfig}
              method={method}
              information={information}
              setInformation={setInformation}
              setValidation={setValidation}
              setInformationChangeBackup={setInformationChangeBackup}
              setOpenDiscountImportantPopup={setOpenDiscountImportantPopup}
              isDisabled={
                isDisabled ||
                checkServiceAlertsOfPayoutMethodItemDisable({
                  serviceAlertsStore: serviceAlertsByCountryCode,
                  payoutMethod: method.method,
                })
              }
              isSelectRequired={isSelectRequired}
            />
          )}
        </Fragment>
      ))}
    </PayoutMethodsGridStyled>
  );
};

const PayoutMethodsGridStyled = styled.div`
  display: flex;
  flex-flow: wrap;
  grid-gap: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    grid-gap: 24px;
  }
`;

export default PayoutMethodsGrid;
