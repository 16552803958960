import CheckboxIcon from 'assets/icons/checkbox-icon.svg';
import CheckedIcon from 'assets/icons/checked-icon.svg';
import { DESKTOP_MIN_WIDTH } from 'constants';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const FormInputCheckbox = ({ isChecked }) => {
  return (
    <FormCheckStyled
      type="checkbox"
      label=""
      name=""
      checked={isChecked}
      readOnly
      disabled
    />
  );
};

const FormCheckStyled = styled(Form.Check)`
  display: flex;
  align-items: center;
  background-color: transparent;

  label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    margin-left: 10px;
    cursor: pointer !important;
    user-select: none;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  input:checked[type='checkbox'] {
    background-image: url(${CheckedIcon});
  }

  input {
    border: none;
    border-radius: 2px;
    background-color: transparent;
    background-image: url(${CheckboxIcon});
    width: 24px;
    height: 24px;
    cursor: pointer !important;

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }

    &[type='checkbox'][disabled] {
      opacity: 1 !important;
    }
  }
`;

export default FormInputCheckbox;
