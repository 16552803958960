import { TFunction } from 'react-i18next';
import { SERVICE_ALERTS_TYPE } from '../model/ServiceAlertsModel';

export const deletePropsInObject = (
  obj: { [key: string]: any },
  props: Array<string>
) => {
  props.forEach((prop) => delete obj?.[prop]);

  return obj;
};

export const getMsgInvalidObj = ({
  t,
  currencyCode = '',
  type = '',
}: {
  t: TFunction;
  currencyCode: string | null;
  type: string | null;
}) => {
  if (type === SERVICE_ALERTS_TYPE.DISABLE && currencyCode) {
    return `${t('form_service_alerts_validation_1', {
      currencyCode: currencyCode,
    })}`;
  }

  return ``;
};
