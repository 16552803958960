/* eslint-disable no-unused-vars */
import ServiceAlertsPopup from 'components/common/PortalModal/popups/ServiceAlertsPopup';
import useLang from 'hooks/useLang';
import usePortalModal from 'hooks/usePortalModal';
import { functionUtils } from 'utils';

const ServiceAlertsModal = ({ isShow, title, content, action }) => {
  const { t } = useLang();
  const { closePortalModalName } = usePortalModal();

  const handleOnClick = () => {
    if (functionUtils.isFunction(action)) {
      action();
    }

    closePortalModalName();
  };

  return (
    <ServiceAlertsPopup
      isOpen={isShow}
      text={title || t('popup_service_alerts_title')}
      content={content || ''}
      buttonLabel2={t('button_ok')}
      onClick={handleOnClick}
      isBackdropOverlap={true}
      zIndexBackdropOverlap={1055}
    />
  );
};

export default ServiceAlertsModal;
