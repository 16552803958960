const DashboardOutlineIcon = ({ color = 'var(--ds-c-white)', size }) => {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3"
        y="3"
        width="7"
        height="7"
        rx="1"
        stroke={color || '#FBFBFB'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <rect
        x="3"
        y="14"
        width="7"
        height="7"
        rx="1"
        stroke={color || '#FBFBFB'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <rect
        x="14"
        y="3"
        width="7"
        height="7"
        rx="1"
        stroke={color || '#FBFBFB'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <rect
        x="14"
        y="14"
        width="7"
        height="7"
        rx="1"
        stroke={color || '#FBFBFB'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DashboardOutlineIcon;
