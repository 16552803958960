import Introduction from 'components/FormLogIn/components/Introduction';
import FormResetPassword from 'components/FormResetPassword';
import FormResetPasswordMobile from 'components/FormResetPassword/mobile';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { useDetectDevice } from 'hooks/useDetectDevice';
import styled from 'styled-components';

const ResetPasswordPage = () => {
  const { isMobile, isTablet } = useDetectDevice();

  if (isMobile || isTablet) {
    return (
      <ResetPasswordStyled>
        <FormResetPasswordMobile />
      </ResetPasswordStyled>
    );
  }
  return (
    <ResetPasswordStyled>
      <Introduction />
      <FormWrap>
        <FormResetPassword />
      </FormWrap>
    </ResetPasswordStyled>
  );
};

const ResetPasswordStyled = styled.div`
  padding: 24px;
  padding-top: 94px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding: 0;
    display: grid;
    grid-template-columns: 55% 45%;
    align-items: end;
    margin-block: auto;
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: 60% 40%;
    margin-block: 112px auto;
  }

  @media screen and (min-width: 2560px) {
    margin-block: 148px auto;
  }
`;

const FormWrap = styled.div`
  background: transparent;

  @media screen and (min-width: 1280px) {
    width: 485px;
    height: 558px;
  }

  @media screen and (min-width: 1440px) {
    width: 517px;
    height: 659px;
  }
`;

export default ResetPasswordPage;
