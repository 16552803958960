import { useEffect } from 'react';

const useAutoScaleToFitContainer = ({
  className = '',
  maxWidth,
  ratioInit,
  step,
  alignHorizontal,
  alignVertical,
}) => {
  const autoScale = ({
    element,
    maxWidth = 85,
    ratioInit = 1,
    step = 0.1,
    alignHorizontal = 'center' || 'left' || 'right',
    alignVertical = 'center' || 'bottom' || 'top',
  }) => {
    const elWidth = element.offsetWidth;

    const checkIsOverFlown = (width) => width > maxWidth;

    let isOverflown = checkIsOverFlown(elWidth);

    let ratioScale = ratioInit;

    while (isOverflown) {
      element.style.transform = `scale(${ratioScale})`;

      const elWidthScale = element.getBoundingClientRect().width;

      if (ratioScale < ratioInit) {
        element.style.transformOrigin = `${alignHorizontal} ${alignVertical}`;
      }

      isOverflown = checkIsOverFlown(elWidthScale);

      if (isOverflown) {
        ratioScale = ratioScale - step;
      } else {
        isOverflown = false;
      }
    }
  };

  useEffect(() => {
    const element = document.getElementsByClassName(className)?.[0];

    if (element) {
      autoScale({
        element,
        maxWidth,
        ratioInit,
        step,
        alignHorizontal,
        alignVertical,
      });
    }
  }, [className, maxWidth, ratioInit, step, alignHorizontal, alignVertical]);
};

export default useAutoScaleToFitContainer;
