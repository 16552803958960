import { useEffect, useState } from 'react';

export function useCameraCheck() {
  const [checkAllowTakePhoto, setCheckAllowTakePhoto] = useState(false);

  useEffect(() => {
    if ('mediaDevices' in navigator) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          if (stream.getVideoTracks().length > 0) {
            setCheckAllowTakePhoto(true);
          } else {
            setCheckAllowTakePhoto(false);
          }
        })
        .catch(function (error) {
          console.error(error?.message);

          setCheckAllowTakePhoto(false);
        });
    }
  }, []);

  return {
    checkAllowTakePhoto: checkAllowTakePhoto,
  };
}
