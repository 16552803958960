/* eslint-disable no-unused-vars */
import useLang from 'hooks/useLang';
import usePortalModal from 'hooks/usePortalModal';
import { functionUtils } from 'utils';
import PickupLocationPreviewPopup from '../popups/PickupLocationPreviewPopup';

const PickupLocationPreviewModal = ({ isShow, action, actionClose }) => {
  const { t } = useLang();
  const { closePortalModalName } = usePortalModal();

  const handleOnClick = () => {
    if (functionUtils.isFunction(action)) {
      action();
    }
    closePortalModalName();
  };

  const handleOnClose = () => {
    if (functionUtils.isFunction(actionClose)) {
      actionClose();
    }
    closePortalModalName();
  };

  return (
    <PickupLocationPreviewPopup
      isOpen={isShow}
      buttonLabel1={t('label_change_pickup_location')}
      onClose={handleOnClick}
      buttonLabel2={t('button_confirm')}
      onClick={handleOnClose}
      isBackdropOverlap={true}
      zIndexBackdropOverlap={1055}
    />
  );
};

export default PickupLocationPreviewModal;
