import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH, LOGIN_PREFIX } from 'constants';
import useLang from 'hooks/useLang';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

const FormAccountActivationSuccessMobile = () => {
  const { t } = useLang();
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate(`${LOGIN_PREFIX}`);
  };
  return (
    <>
      <TitleH3>{t('account_activation_text_submitted')}</TitleH3>
      <Paragraph style={{ marginBottom: '24px' }}>
        {t('account_activation_text_submitted_desc')}
      </Paragraph>
      <ConfirmSubmit>
        <ButtonCommon
          value={t('button_login')}
          onClick={handleLogin}
          styles={{
            margin: '0px',
            width: '100%',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
        />
      </ConfirmSubmit>
    </>
  );
};

const ConfirmSubmit = styled.div`
  position: fixed;
  bottom: 0px;
  background: var(--ds-c-white);
  width: 100%;
  margin-inline: -24px;
  padding: 16px 24px;
  border-top: 1px solid var(--ds-c-grey-disabled);
`;

const TitleH3 = styled.h3`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default FormAccountActivationSuccessMobile;
