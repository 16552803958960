import SendIcon from 'assets/icons/send-icon.svg';
import FormInputFavoriteCheckbox from 'components/Receivers/components/FormInputFavoriteCheckbox';
import { fetchReceiverInformation } from 'components/Receivers/func';
import { DESKTOP_MIN_WIDTH, TRANSACTION_ADD_PREFIX } from 'constants';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const ReceiverItem = ({ receiver, onClick }) => {
  const { t } = useLang();
  const { token } = useAuth();
  const navigate = useNavigate();

  const handleOnSendReceiver = async (receiver) => {
    await fetchReceiverInformation(token, receiver?.receiverId);

    Cookies.remove(MAKE_PAYMENT_KEY);

    navigate(`${TRANSACTION_ADD_PREFIX}?rid=${receiver?.receiverId}`);
  };

  return (
    <ReceiverItemStyled>
      <GridItem>
        <FormInputFavoriteCheckbox
          id={receiver.receiverId}
          isChecked={receiver.favorited}
        />
      </GridItem>
      <GridItem onClick={onClick}>
        <ReceiverText>{receiver.fullname}</ReceiverText>
        <CountryText>{receiver.countryName}</CountryText>
      </GridItem>
      <GridItem>
        <SendText onClick={() => handleOnSendReceiver(receiver)}>
          {t('button_send')}{' '}
          <SendIconStyled src={SendIcon} width={24} height={24} alt="" />
        </SendText>
      </GridItem>
    </ReceiverItemStyled>
  );
};

const ReceiverItemStyled = styled.div`
  width: 100%;
  min-height: 80px;
  height: fit-content;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 12px;

  display: grid;
  grid-template-columns: 15% 55% 28%;
  grid-gap: 4px;
`;
const GridItem = styled.div`
  width: 100%;
  display: grid;
  align-items: center;

  &:nth-child(1) {
    justify-content: start;
    align-items: center;
    border-right: 1px solid #eef2f5;
  }
  &:nth-child(2) {
    border-right: 1px solid #eef2f5;
  }
  &:last-child {
    justify-content: space-around;
  }
`;
const CountryText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;
  text-align: left;

  margin: 0;
  padding: 0;
  padding-left: 16px;
`;
const ReceiverText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;
  text-align: left;

  margin: 0;
  padding: 0 10px 0 16px;

  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SendText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #4965a0;

  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: end;

  &:hover {
    color: var(--ds-c-blue-hover);

    & img {
      filter: var(--ds-f-c-blue-hover);
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const SendIconStyled = styled.img`
  margin-left: 4px;
  filter: var(--ds-f-c-blue);
`;

export default ReceiverItem;
