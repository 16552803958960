import { api } from 'api';
import FormInputAddressManuallyValidation from 'components/FormEditReceiver/components/FormInputAddressManuallyValidation';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  checkFormInputPortalCodeValidation,
  checkFormInputValidation,
} from 'components/common/func';
import {
  DESKTOP_MIN_WIDTH,
  EDIT_RECEIVER_POSTAL_NAME,
  EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS,
  EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME,
  EDIT_RECEIVER_STATE_NAME,
  EDIT_RECEIVER_STREET_NAME,
  EDIT_RECEIVER_SUBURB_NAME,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
} from 'constants';
import { ADD_RECEIVER_STEP_REFERENCE } from 'constants/form-add-receiver';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import { Fade } from 'react-bootstrap';
import { SET_ADD_RECEIVER_INFORMATION } from 'store/action';
import styled from 'styled-components';
import BodySectionHeader from '../../BodySectionHeader';
import FormInputAddressSwitch from '../../FormInputAddressSwitch';

const initialFormField =
  RESET_EDIT_RECEIVER_INFORMATION_STORE.residentialAddress;

const ResidentialAddress = ({
  countryConfig,
  currentStep,
  setCurrentStep,
  handleOnToggleCancelPopup,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { editReceiverInformation } = state;
  const { isMobile, isTablet } = useDetectDevice();

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [cities, setCities] = useState(null);
  const [portalCodeMaskStr, setPortalCodeMaskStr] = useState('');

  const isReceiverOfIndividualType =
    information[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME] === 1;

  const config = useGetConfig({ countryConfig });
  const {
    addressStreet,
    addressState,
    addressSuburb,
    addressPostcode,
    addressManualDisable,
  } = config;

  const isShowAddAddressManual =
    isReceiverOfIndividualType && addressManualDisable;

  const fetchCities = async () => {
    try {
      const { data } = await api.getCities(countryConfig?.countryCode);

      if (data) {
        setCities(data);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    const informationStore = editReceiverInformation.residentialAddress;

    const newInformation = {
      [EDIT_RECEIVER_STREET_NAME]: informationStore[EDIT_RECEIVER_STREET_NAME],
      [EDIT_RECEIVER_STATE_NAME]: informationStore[EDIT_RECEIVER_STATE_NAME],
      [EDIT_RECEIVER_SUBURB_NAME]: informationStore[EDIT_RECEIVER_SUBURB_NAME],
      [EDIT_RECEIVER_POSTAL_NAME]: informationStore[EDIT_RECEIVER_POSTAL_NAME],
      isEditReceiverEnterAddressManuallySelected:
        informationStore.isEditReceiverEnterAddressManuallySelected,
    };

    setInformation(newInformation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countryConfig) {
      fetchCities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryConfig]);

  const handleNextStep = async () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    delete newInformation['isEditReceiverEnterAddressManuallySelected'];
    delete newValidation['isEditReceiverEnterAddressManuallySelected'];

    if (!addressStreet.required) {
      delete newInformation[EDIT_RECEIVER_STREET_NAME];
      delete newValidation[EDIT_RECEIVER_STREET_NAME];
    }

    if (!addressState.required) {
      delete newInformation[EDIT_RECEIVER_STATE_NAME];
      delete newValidation[EDIT_RECEIVER_STATE_NAME];
    }

    if (!addressSuburb.required) {
      delete newInformation[EDIT_RECEIVER_SUBURB_NAME];
      delete newValidation[EDIT_RECEIVER_SUBURB_NAME];
    }

    if (!addressPostcode.required) {
      delete newInformation[EDIT_RECEIVER_POSTAL_NAME];
      delete newValidation[EDIT_RECEIVER_POSTAL_NAME];
    }

    const isAllowNext =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowNext) {
      dispatch({
        type: SET_ADD_RECEIVER_INFORMATION,
        payload: {
          ...editReceiverInformation,
          [ADD_RECEIVER_STEP_REFERENCE[currentStep]]: information,
        },
      });

      setCurrentStep(currentStep + 1);
    }

    if (!isAllowNext) {
      if (addressStreet.required) {
        newValidation = checkFormInputValidation(
          information[EDIT_RECEIVER_STREET_NAME],
          EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[0],
          newValidation
        );
      }

      if (addressState.required) {
        newValidation = checkFormInputValidation(
          information[EDIT_RECEIVER_STATE_NAME],
          EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[1],
          newValidation
        );
      }

      if (addressSuburb.required) {
        newValidation = checkFormInputValidation(
          information[EDIT_RECEIVER_SUBURB_NAME],
          EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[2],
          newValidation
        );
      }

      if (addressPostcode.required) {
        newValidation = checkFormInputPortalCodeValidation(
          information[EDIT_RECEIVER_POSTAL_NAME],
          EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[3],
          newValidation,
          addressPostcode
        );
      }

      setValidation(newValidation);
    }
  };

  const handleBackStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      <BodySectionHeader
        titleBodySection={(isMobile || isTablet) && t('add_receiver_title')}
        titleSection={t('label_address')}
        onToggleCancelPopup={handleOnToggleCancelPopup}
        isDisabled={false}
      />
      <ResidentialAddressStyled>
        {EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS.map((field) => (
          <FormInputAddressManuallyValidation
            key={field.name}
            field={field}
            countryConfig={countryConfig}
            cities={cities}
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            portalCodeMaskStr={portalCodeMaskStr}
            setPortalCodeMaskStr={setPortalCodeMaskStr}
          />
        ))}
        {isShowAddAddressManual && (
          <FormInputAddressSwitch
            information={information}
            setInformation={setInformation}
            setValidation={setValidation}
          />
        )}
        <Fade in appear>
          <NextWrap>
            <ButtonCommon
              value={t('button_back')}
              onClick={handleBackStep}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
            />
            <ButtonCommon
              value={t('button_next')}
              color="var(--c-primary)"
              background="var(--bg-primary)"
              isFill={true}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              onClick={handleNextStep}
              isUseKeyDown
            />
          </NextWrap>
        </Fade>
      </ResidentialAddressStyled>
    </>
  );
};

const ResidentialAddressStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -8px;
  padding-inline: 0px;
  padding-bottom: 140px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 437px;
    margin-top: 0px;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 77px;
  width: 100%;
  margin-inline: -16px;
  padding-inline: 16px;
  background: var(--ds-c-white);
  z-index: 1;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #eef2f5;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    width: 437px;
    height: 44px;
    border-top: unset;
  }
`;

export default ResidentialAddress;
