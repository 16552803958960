import AccountDetails from 'components/FormEditReceiver/components/AccountDetails';
import PersonalDetails from 'components/FormEditReceiver/components/PersonalDetails';
import ResidentialAddress from 'components/FormEditReceiver/components/ResidentialAddress';
import { fetchReceiverInformation } from 'components/Receivers/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  DESKTOP_MIN_WIDTH,
  EDIT_RECEIVER_TABS,
  EDIT_RECEIVER_TAB_CONTENT_REFERENCE,
  RECEIVERS_PREFIX,
} from 'constants';
import useAuth from 'hooks/useAuth';
import useCountryConfig from 'hooks/useCountryConfig';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import CancelCurrentTabPopup from 'components/FormEditReceiver/components/CancelCurrentTabPopup';
import CancelEditReceiverPopup from 'components/FormEditReceiver/components/CancelEditReceiverPopup';
import SaveEditReceiverPopup from 'components/FormEditReceiver/components/SaveEditReceiverPopup';
import useFormAddReceiverStore from 'hooks/form-add-receiver/useFormAddReceiverStore';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';

const EditReceiverPage = () => {
  const { t } = useLang();
  const navigate = useNavigate();
  const { token } = useAuth();
  const { receiverId } = useParams();
  const countryConfig = useCountryConfig();
  const { isMobile, isTablet } = useDetectDevice();

  const {
    editReceiverCurrentStep: currentTab,
    setEditReceiverCurrentStep: setCurrentTab,
    resetEditReceiverInformation,
  } = useFormAddReceiverStore();
  const { currentReceiver, resetCurrentReceiver } =
    useFormAddTransactionStore();

  const [isOpenCancelPopup, setOpenCancelPopup] = useState(false);
  const [isOpenUpdatePopup, setOpenUpdatePopup] = useState(false);
  const [isOpenCancelCurrentTabPopup, setOpenCancelCurrentTabPopup] =
    useState(false);

  useEffect(() => {
    if (!currentReceiver ?? receiverId) {
      const result = fetchReceiverInformation(token, receiverId);

      result
        .then((res) => {
          if (!res) {
            handleOnClickCancelPopup();

            return;
          }
        })
        .catch((error) => {
          console.error(error?.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReceiver, receiverId]);

  useEffect(() => {
    return () => {
      resetCurrentReceiver();
      resetEditReceiverInformation();
      setCurrentTab(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnToggleCancelPopup = () => {
    setOpenCancelPopup(!isOpenCancelPopup);
  };

  const handleOnClickCancelPopup = () => {
    setOpenCancelPopup(false);

    setCurrentTab(0);

    resetCurrentReceiver();

    resetEditReceiverInformation();

    navigate(RECEIVERS_PREFIX);
  };

  const handleOnToggleUpdatePopup = async () => {
    await fetchReceiverInformation(token, receiverId);

    setOpenUpdatePopup(!isOpenUpdatePopup);
  };

  const handleOnClickUpdatePopup = () => {
    setOpenUpdatePopup(false);

    handleOnClickCancelPopup();
  };

  const handleOnToggleCancelCurrentTabPopup = () => {
    setOpenCancelCurrentTabPopup(!isOpenCancelCurrentTabPopup);
  };

  const tabContentReference = {
    0: (
      <PersonalDetails
        countryConfig={countryConfig}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnToggleUpdatePopup={handleOnToggleUpdatePopup}
        handleOnToggleCancelCurrentTabPopup={
          handleOnToggleCancelCurrentTabPopup
        }
      />
    ),
    1: (
      <ResidentialAddress
        countryConfig={countryConfig}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnToggleUpdatePopup={handleOnToggleUpdatePopup}
        handleOnToggleCancelCurrentTabPopup={
          handleOnToggleCancelCurrentTabPopup
        }
      />
    ),
    2: (
      <AccountDetails
        countryConfig={countryConfig}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
        handleOnToggleUpdatePopup={handleOnToggleUpdatePopup}
        handleOnToggleCancelCurrentTabPopup={
          handleOnToggleCancelCurrentTabPopup
        }
      />
    ),
  };

  const handleTabOnClick = (index) => {
    setCurrentTab(index);
  };

  if (isMobile || isTablet) {
    return (
      <>
        {isOpenCancelPopup && (
          <CancelEditReceiverPopup
            isOpen={isOpenCancelPopup}
            onClose={handleOnToggleCancelPopup}
            onClick={handleOnClickCancelPopup}
          />
        )}

        {isOpenCancelCurrentTabPopup && (
          <CancelCurrentTabPopup
            isOpen={isOpenCancelCurrentTabPopup}
            currentTab={currentTab}
            onClose={handleOnToggleCancelCurrentTabPopup}
          />
        )}

        {isOpenUpdatePopup && (
          <SaveEditReceiverPopup
            isOpen={isOpenUpdatePopup}
            title={t(EDIT_RECEIVER_TAB_CONTENT_REFERENCE[currentTab])}
            onClose={handleOnToggleUpdatePopup}
            onClick={handleOnClickUpdatePopup}
          />
        )}

        <EditReceiverStyled>
          <Title>{t('edit_receiver_title')}</Title>
          {EDIT_RECEIVER_TABS.map((tab, index) => (
            <ButtonCommon
              key={tab.label}
              value={t(tab.label)}
              background={
                currentTab === index
                  ? 'var(--ds-c-grey-light)'
                  : 'var(--ds-c-white)'
              }
              styles={{
                width: '33.333%',
                height: '44px',
                fontSize: '14px',
                lineHeight: '17px',
                fontWeight: 500,
                padding: '0px 10px',
                paddingInline: '24px',
                whiteSpace: 'pre-wrap',
                float: 'left',
                ...(currentTab === index && {
                  fontWeight: 700,
                  borderRadius: '100px',
                }),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => handleTabOnClick(index)}
            />
          ))}
          {tabContentReference[currentTab]}
        </EditReceiverStyled>
      </>
    );
  }

  return (
    <>
      {isOpenCancelPopup && (
        <CancelEditReceiverPopup
          isOpen={isOpenCancelPopup}
          onClose={handleOnToggleCancelPopup}
          onClick={handleOnClickCancelPopup}
        />
      )}

      {isOpenCancelCurrentTabPopup && (
        <CancelCurrentTabPopup
          isOpen={isOpenCancelCurrentTabPopup}
          currentTab={currentTab}
          onClose={handleOnToggleCancelCurrentTabPopup}
        />
      )}

      {isOpenUpdatePopup && (
        <SaveEditReceiverPopup
          isOpen={isOpenUpdatePopup}
          title={t(EDIT_RECEIVER_TAB_CONTENT_REFERENCE[currentTab])}
          onClose={handleOnToggleUpdatePopup}
          onClick={handleOnClickUpdatePopup}
        />
      )}

      <EditReceiverStyled>
        <Title>{t('edit_receiver_title')}</Title>
        {EDIT_RECEIVER_TABS.map((tab, index) => (
          <ButtonCommon
            key={tab.label}
            value={t(tab.label)}
            background={
              currentTab === index
                ? 'var(--ds-c-grey-light)'
                : 'var(--ds-c-white)'
            }
            styles={{
              width: '223px',
              fontWeight: 500,
              paddingInline: '16px',
              float: 'left',
              ...(currentTab === index && {
                fontWeight: 700,
                borderRadius: '100px',
              }),
            }}
            onClick={() => handleTabOnClick(index)}
          />
        ))}
        {tabContentReference[currentTab]}
      </EditReceiverStyled>
    </>
  );
};

const EditReceiverStyled = styled.div`
  margin: 70px 0px 0px 0px;
  padding: 24px 24px;
  height: calc(100vh - 70px);
  width: 100%;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin: unset;
    padding: unset;
    height: fit-content;
    width: 669px;
    margin-inline: auto;
    margin-top: 104px;
  }
`;

const Title = styled.h2`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #7a8189;

  margin-bottom: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    margin-bottom: 40px;
  }
`;

export default EditReceiverPage;
