import { api } from 'api';
import { LogUtil } from 'components/common/LogUtil';
import { dispatchStore } from 'store';
import { SET_SHOW_LOADING } from 'store/action';

export const fetchSellRateCurrency = async (currencyCode) => {
  try {
    if (!currencyCode) {
      LogUtil.error('fetchSellRateCurrency', 'error');
      return null;
    }
    const { data } = await api.getFxSellRate(currencyCode);
    return data;
  } catch (error) {
    LogUtil.error('fetchSellRateCurrency', error);
  }
};

export const fetchSellCurrencies = async () => {
  try {
    const { data } = await api.getFxSellCurrencies();
    dispatchStore({ type: SET_SHOW_LOADING, payload: false });
    return data;
  } catch (error) {
    LogUtil.error('fetchSellCurrencies', error);
    dispatchStore({ type: SET_SHOW_LOADING, payload: false });
  }
};

export const fetchPickupLocationBranches = async () => {
  try {
    const { data } = await api.getFxPickupLocationBranches();
    return data;
  } catch (error) {
    LogUtil.error('fetchPickupLocationBranches', error);
  }
};

export const fetchPickupLocationViaAusPost = async (postcode) => {
  try {
    const { data } = await api.getPickupLocationsViaAusPost(postcode);
    return data;
  } catch (error) {
    LogUtil.error('fetchPickupLocationViaAusPost', error);
  }
};

export const fetchPickupDateDates = async (payload) => {
  try {
    const { data } = await api.getPickupDates(payload);
    return data;
  } catch (error) {
    LogUtil.error('fetchPickupDateDates', error);
  }
};

export const fetchAllFXRates = async () => {
  try {
    const { data } = await api.getAllFXRates();
    return data;
  } catch (error) {
    LogUtil.error('fetchAllFXRates', error);
  }
};

export const fetchOrderDetails = async (
  token,
  orderRef,
  errorCallback = () => {}
) => {
  try {
    const { data } = await api.getOrder(token, orderRef);
    return data;
  } catch (error) {
    LogUtil.error('fetchOrderDetails', error);
    errorCallback();
  }
};

export const fetchOrderDetailsAsGuest = async (
  orderRef,
  email,
  errorCallback = () => {}
) => {
  try {
    const { data } = await api.getOrderAsGuest(orderRef, email);
    return data;
  } catch (error) {
    LogUtil.error('fetchOrderDetailsAsGuest', error);
    errorCallback();
  }
};

export const createOrder = async (token, payload) => {
  try {
    const { data } = await api.createOrder(token, payload);
    return data;
  } catch (error) {
    LogUtil.error('createOrder', error);
  }
};
export const createOrderAsGuest = async (payload) => {
  try {
    const { data } = await api.createOrderAsGuest(payload);
    return data;
  } catch (error) {
    LogUtil.error('createOrderAsGuest', error);
  }
};

export const updateOrder = async (token, payload) => {
  try {
    const { data } = await api.updateOrder(token, payload);
    return data;
  } catch (error) {
    LogUtil.error('updateOrder', error);
  }
};

export const updateOrderAsGuest = async (payload) => {
  try {
    const { data } = await api.updateOrderAsGuest(payload);
    return data;
  } catch (error) {
    LogUtil.error('updateOrderAsGuest', error);
  }
};
