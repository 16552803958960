import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import {
  CONFIG_OCCUPATION_EXTRAS,
  DESKTOP_MIN_WIDTH,
  REGISTER_FISTNAME_NAME,
  REGISTER_LASTNAME_NAME,
  REGISTER_PERSONAL_DETAILS_NAME_FIELDS,
} from '../../../constants';

import { api } from 'api';
import InformPopup from 'components/common/InformPopup';
import useLang from 'hooks/useLang';
import Accordion from 'react-bootstrap/Accordion';
import { arrayUtils } from 'utils';
import {
  REGISTER_DOB_NAME,
  REGISTER_GENDER_NAME,
  REGISTER_MIDDLENAME_NAME,
  REGISTER_OCCUPATION_NAME,
  REGISTER_PERSONAL_DETAILS_DOB_FIELD,
  REGISTER_PERSONAL_DETAILS_GENDER_FIELD,
  REGISTER_PERSONAL_DETAILS_OCCUPATION_FIELDS,
  REGISTER_PREVIOUS_EMPLOYED_NAME,
  REGISTER_STEP_REFERENCE,
  RESET_REGISTER_INFORMATION_STORE,
} from '../../../constants';
import useStore from '../../../hooks/useStore';
import {
  SET_REGISTER_INFORMATION,
  SET_SHOW_LOADING,
} from '../../../store/action';
import ButtonCommon from '../../common/ButtonCommon';
import ArrowDownIcon from '../../common/Icons/ArrowDownIcon';
import CustomOccupationDropdown from './components/CustomOccupationDropdown';
import FormInputDatepickerValidation from './components/FormInputDatepickerValidation';
import FormInputGenderRadio from './components/FormInputGenderRadio';
import FormInputValidation from './components/FormInputValidation';
import { checkFormInputValidation } from './func';
import { Fade } from 'react-bootstrap';

const initialFormField = RESET_REGISTER_INFORMATION_STORE.personalDetails;

const PersonalDetails = ({
  currentStep,
  setCurrentStep,
  signupId,
  registerPayload,
  setRegisterPayload,
  handleOnToggleCancelPopup,
}) => {
  const { t, locate } = useLang();
  const { state, dispatch } = useStore();
  const { registerInformation, configOccupation } = state;

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [isShowExistedCustomerPopup, setShowExistedCustomerPopup] =
    useState(false);

  useEffect(() => {
    const occupationInit = configOccupation?.find(
      (o) => o.key === information[REGISTER_OCCUPATION_NAME]?.value
    );
    const previousOccupationInit = configOccupation?.find(
      (o) => o.key === information[REGISTER_PREVIOUS_EMPLOYED_NAME]?.value
    );

    if (occupationInit) {
      document.getElementsByName(REGISTER_OCCUPATION_NAME)[0].innerHTML =
        occupationInit?.value;
    }

    if (previousOccupationInit) {
      document.getElementsByName(REGISTER_PREVIOUS_EMPLOYED_NAME)[0].innerHTML =
        previousOccupationInit?.value;
    }
  }, [information, locate, configOccupation]);

  useEffect(() => {
    const informationStore = registerInformation.personalDetails;

    if (informationStore[REGISTER_OCCUPATION_NAME]) {
      const occupationInit = configOccupation?.find(
        (o) => o.key === informationStore[REGISTER_OCCUPATION_NAME]
      );
      const previousOccupationInit = configOccupation?.find(
        (o) => o.key === informationStore[REGISTER_PREVIOUS_EMPLOYED_NAME]
      );

      if (occupationInit) {
        document.getElementsByName(REGISTER_OCCUPATION_NAME)[0].innerHTML =
          occupationInit?.value;
      }

      if (previousOccupationInit) {
        document.getElementsByName(
          REGISTER_PREVIOUS_EMPLOYED_NAME
        )[0].innerHTML = previousOccupationInit?.value;
      }

      setInformation(informationStore);
    }
  }, [registerInformation, configOccupation]);

  const handleExistedCustomerContinue = (information) => {
    dispatch({
      type: SET_REGISTER_INFORMATION,
      payload: {
        ...registerInformation,
        [REGISTER_STEP_REFERENCE[currentStep]]: information,
      },
    });

    setCurrentStep((state) => state + 1);
  };

  const handleNextStep = async () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    delete newInformation[REGISTER_MIDDLENAME_NAME];
    delete newValidation[REGISTER_MIDDLENAME_NAME];

    if (
      information[REGISTER_OCCUPATION_NAME] &&
      !CONFIG_OCCUPATION_EXTRAS.find(
        (occupation) =>
          occupation === information[REGISTER_OCCUPATION_NAME]?.key
      )
    ) {
      delete newInformation[REGISTER_PREVIOUS_EMPLOYED_NAME];
      delete newValidation[REGISTER_PREVIOUS_EMPLOYED_NAME];
    }

    const isAllowSubmit =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowSubmit) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      let payload = {
        ...registerPayload,
        onlineSignupId: signupId,
        step: 2,
        firstName: information[REGISTER_FISTNAME_NAME],
        otherNames: information[REGISTER_MIDDLENAME_NAME],
        lastName: information[REGISTER_LASTNAME_NAME],
        gender: information[REGISTER_GENDER_NAME],
        dob: information[REGISTER_DOB_NAME],
        job: information[REGISTER_OCCUPATION_NAME]?.key,
        previousJob: information[REGISTER_PREVIOUS_EMPLOYED_NAME]?.key,
      };

      try {
        let { data } = await api.isExistingCustomer(
          payload.firstName,
          payload.otherNames,
          payload.lastName,
          payload.gender,
          payload.dob
        );

        if (data?.existed) {
          const { matchedSenderIds, agentOnly } = data || {};
          payload = {
            ...payload,
            customerType: 'EXISTING',
            matchedSenderIds: matchedSenderIds.toString(),
          };

          if (!agentOnly) {
            setRegisterPayload(payload);

            // WA-321: https://easternandallied.atlassian.net/browse/WA-321
            // setShowExistedCustomerPopup(true);

            dispatch({ type: SET_SHOW_LOADING, payload: false });

            // WA-321: https://easternandallied.atlassian.net/browse/WA-321
            // return;
          }
        }

        if (!data?.existed) {
          payload = {
            ...payload,
            customerType: 'NEW',
            matchedSenderIds: '',
          };
        }
      } catch (error) {
        console.error(error?.message);

        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }

      setRegisterPayload(payload);

      dispatch({
        type: SET_REGISTER_INFORMATION,
        payload: {
          ...registerInformation,
          [REGISTER_STEP_REFERENCE[currentStep]]: information,
        },
      });

      setCurrentStep((state) => state + 1);

      dispatch({ type: SET_SHOW_LOADING, payload: false });
    }

    arrayUtils
      .removeItemInArrayObject(
        REGISTER_PERSONAL_DETAILS_NAME_FIELDS,
        REGISTER_MIDDLENAME_NAME,
        'name'
      )
      .forEach((field) => {
        newValidation = checkFormInputValidation(
          information[field.name],
          field,
          information,
          newValidation
        );
      });

    if (information[REGISTER_MIDDLENAME_NAME]) {
      newValidation = checkFormInputValidation(
        information[REGISTER_MIDDLENAME_NAME],
        REGISTER_PERSONAL_DETAILS_NAME_FIELDS[1],
        information,
        newValidation
      );
    }

    newValidation = checkFormInputValidation(
      information[REGISTER_GENDER_NAME],
      REGISTER_PERSONAL_DETAILS_GENDER_FIELD,
      information,
      newValidation
    );

    newValidation = checkFormInputValidation(
      information[REGISTER_DOB_NAME],
      REGISTER_PERSONAL_DETAILS_DOB_FIELD,
      information,
      newValidation
    );

    newValidation = checkFormInputValidation(
      information[REGISTER_OCCUPATION_NAME]?.key,
      REGISTER_PERSONAL_DETAILS_OCCUPATION_FIELDS[0],
      information,
      newValidation
    );

    if (
      CONFIG_OCCUPATION_EXTRAS.find(
        (occupation) =>
          occupation === information[REGISTER_OCCUPATION_NAME]?.key
      )
    ) {
      newValidation = checkFormInputValidation(
        information[REGISTER_PREVIOUS_EMPLOYED_NAME]?.key,
        REGISTER_PERSONAL_DETAILS_OCCUPATION_FIELDS[1],
        information,
        newValidation
      );
    }

    setValidation(newValidation);
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      {isShowExistedCustomerPopup && (
        <InformPopup
          isOpen={isShowExistedCustomerPopup}
          text={t('registration_existed_customer_title')}
          content={t('registration_existed_customer_desc')}
          buttonLabel1={t('button_back')}
          buttonLabel2={t('button_continue')}
          onClose={() => setShowExistedCustomerPopup(false)}
          onClick={() => handleExistedCustomerContinue(information)}
          isBackdropOverlap={false}
        />
      )}

      <PersonalDetailsStyled>
        <Title>
          <ArrowDownIcon
            color="var(--ds-c-grey-dark)"
            size={{ width: 14 }}
            styles={{
              transform: 'rotate(90deg)',
              marginRight: '16px',
              cursor: 'pointer',
            }}
            onClick={handlePreviousStep}
          />
          {t('label_personal_details')}
        </Title>
        <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
          <AccordionItem eventKey="0">
            <AccordionBody>
              {REGISTER_PERSONAL_DETAILS_NAME_FIELDS.map((field) => (
                <FormInputValidation
                  key={field.name}
                  field={field}
                  information={information}
                  setInformation={setInformation}
                  validation={validation}
                  setValidation={setValidation}
                  checkFormInputValidation={checkFormInputValidation}
                />
              ))}
              <FormInputGenderRadio
                information={information}
                setInformation={setInformation}
                validation={validation}
                setValidation={setValidation}
              />
              <FormInputDatepickerValidation
                information={information}
                setInformation={setInformation}
                validation={validation}
                setValidation={setValidation}
              />
              <CustomOccupationDropdown
                information={information}
                setInformation={setInformation}
                validation={validation}
                setValidation={setValidation}
              />
            </AccordionBody>
          </AccordionItem>
        </Accordion>
        <Fade in appear>
          <NextWrap>
            <ButtonCommon
              value={t('button_cancel')}
              onClick={handleOnToggleCancelPopup}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              color="var(--c-primary)"
              background="var(--ds-c-white)"
            />
            <ButtonCommon
              value={t('button_next')}
              color="var(--c-primary)"
              background="var(--bg-primary)"
              isFill={true}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              onClick={handleNextStep}
              isUseKeyDown
            />
          </NextWrap>
        </Fade>
      </PersonalDetailsStyled>
    </>
  );
};

const PersonalDetailsStyled = styled(Form)`
  position: relative;
  width: 100%;
  height: fit-content;
  padding-bottom: 96px;
  margin-top: 24px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    position: unset;
    width: 437px;
    margin-inline: auto;
    margin-top: 40px;
  }
`;

const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  display: flex;
  align-items: center;
  color: var(--ds-c-grey-dark);
  margin-top: 0px;
  margin-bottom: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 32px;
    line-height: 38px;

    margin-bottom: 24px;
  }
`;

const AccordionItem = styled(Accordion.Item)`
  border: none;
`;

const AccordionBody = styled(Accordion.Body)`
  padding: 0 !important;
`;

const NextWrap = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ds-c-white);
  margin-inline: -24px;
  padding: 16px 24px;
  width: 100%;
  border-top: 1px solid var(--ds-c-grey-disabled);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    height: 40px;
    border-top: none;
  }
`;

export default PersonalDetails;
