import { backgroundReference, colorReference } from 'constants';
import React from 'react';
import styled from 'styled-components';

const AddTxnStep6IconAsGuest = ({
  status = 'disable' || 'in-progress' || 'completed',
}) => {
  return (
    <SvgStyled $backgroundColor={backgroundReference[status]}>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 4.35185C0 3.3291 0.746192 2.5 1.66667 2.5H18.3333C19.2538 2.5 20 3.3291 20 4.35185V16.0185C20 17.0413 19.2538 17.8704 18.3333 17.8704H1.66667C0.746192 17.8704 0 17.0413 0 16.0185V4.35185Z"
          fill={colorReference[status]}
        />
        <path
          d="M7.86821 10.8875C8.04477 10.8313 8.20196 10.7812 8.40547 10.7812C9.60565 10.7812 10.5794 11.761 10.5794 12.9687V13.3854C10.5794 13.8167 10.2316 14.1666 9.80299 14.1666H4.10941C3.68077 14.1666 3.33301 13.8167 3.33301 13.3854V12.9687C3.33301 11.761 4.30674 10.7812 5.50692 10.7812C5.71134 10.7812 5.86873 10.8314 6.04537 10.8877C6.27127 10.9596 6.52867 11.0416 6.9562 11.0416C7.38405 11.0416 7.64202 10.9595 7.86821 10.8875Z"
          fill="white"
        />
        <path
          d="M9.28539 8.17706C9.28539 9.47101 8.2421 10.5208 6.9562 10.5208C5.67029 10.5208 4.627 9.47101 4.627 8.17706C4.627 6.88312 5.67029 5.83331 6.9562 5.83331C8.2421 5.83331 9.28539 6.88312 9.28539 8.17706Z"
          fill="white"
        />
        <path
          d="M10.833 6.42855C10.833 6.09981 11.0995 5.83331 11.4282 5.83331H16.0711C16.3998 5.83331 16.6663 6.09981 16.6663 6.42855C16.6663 6.75729 16.3998 7.02379 16.0711 7.02379H11.4282C11.0995 7.02379 10.833 6.75729 10.833 6.42855Z"
          fill="white"
        />
        <path
          d="M11.9044 9.99998C11.9044 9.67124 12.1709 9.40474 12.4997 9.40474H16.0711C16.3998 9.40474 16.6663 9.67124 16.6663 9.99998C16.6663 10.3287 16.3998 10.5952 16.0711 10.5952H12.4997C12.1709 10.5952 11.9044 10.3287 11.9044 9.99998Z"
          fill="white"
        />
        <path
          d="M13.333 12.9762C13.0043 12.9762 12.7378 13.2427 12.7378 13.5714C12.7378 13.9001 13.0043 14.1666 13.333 14.1666H16.0711C16.3998 14.1666 16.6663 13.9001 16.6663 13.5714C16.6663 13.2427 16.3998 12.9762 16.0711 12.9762H13.333Z"
          fill="white"
        />
      </svg>
    </SvgStyled>
  );
};

const SvgStyled = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

export default AddTxnStep6IconAsGuest;
