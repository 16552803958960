import HHMTLogo from 'assets/icons/start/hhmt-logo.png';
import REMOXLogo from 'assets/icons/start/remox-logo.png';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';

const WelcomeScreen = ({ pageYOffset, loadingStyles = {} }) => {
  const { isHHMTTheme } = useTheme();
  return (
    <>
      <LoadingStyled $pageYOffset={pageYOffset} style={{ ...loadingStyles }} />
      <ImageStyled
        src={isHHMTTheme ? HHMTLogo : REMOXLogo}
        width={100}
        height={100}
      />
    </>
  );
};

const LoadingStyled = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% + ${(props) => props.$pageYOffset || 0}px);
  background: #212121;
  opacity: 0.25;
  z-index: 10000;
  top: 0;
`;

const ImageStyled = styled.img`
  position: absolute;
  z-index: 10001;
  color: var(--bg-primary) !important;
  width: 100px;
  height: 100px;
  top: calc(50% + ${(props) => props.$pageYOffset || 0}px - 50px);
  left: calc(50% - 50px);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 200px;
    height: 200px;
    top: calc(50% + ${(props) => props.$pageYOffset || 0}px - 100px);
    left: calc(50% - 100px);
  }
`;

export default WelcomeScreen;
