import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';
import ExchangeRateItem from '../../ExchangeRateItem/ver2';

const ExchangeRateSection = ({ remittanceRatesData, countries }) => {
  const { t } = useLang();
  const [current, setCurrent] = useState(0);

  const getCountryNameByCode = (countryCode) => {
    if (isEmpty(countries)) return null;
    const country = countries.find((country) => country.code === countryCode);
    return country ? country.name : '';
  };

  if (!remittanceRatesData) {
    return null;
  }

  return (
    <Wrapper>
      <RatesWrap>
        {Object.keys(remittanceRatesData).map((country, index) => (
          <>
            <ExchangeRateItem
              key={country}
              country={country}
              countryName={getCountryNameByCode(country)}
              rates={remittanceRatesData[country]}
              isActive={index === current}
              onClick={() => setCurrent(index)}
            />
          </>
        ))}
      </RatesWrap>
      <b>*</b>
      {t('label_exchange_rate_per_aud')}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  height: fit-content;

  font-family: var(--ff-primary);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--ds-c-black);

  padding-bottom: 40px;

  & b {
    color: var(--ds-c-red);
  }
`;

const RatesWrap = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 10px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    gap: 21px;
  }
`;

export default ExchangeRateSection;
