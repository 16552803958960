const ExchangeIconBlueDark = ({
  size = { width: 24, height: 24 },
  styles = {},
  onClick = () => {},
}) => {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...styles }}
      onClick={onClick}
    >
      <g clipPath="url(#clip0_8641_1797)">
        <path
          d="M5.35274 15.5516C5.93884 15.7571 6.50588 15.2894 6.59177 14.6743C6.73307 13.6623 7.03378 12.7014 7.46772 11.8178C6.12885 11.3979 5.15591 10.2887 4.79006 8.87411H4.2252C4.10134 8.87411 4 8.76562 4 8.63304V8.0625C4 7.92991 4.10134 7.82143 4.2252 7.82143H4.6268C4.60803 7.56228 4.61178 7.24688 4.63055 6.97366H4.2252C4.10134 6.97366 4 6.86518 4 6.73259V6.13393C4 6.00134 4.10134 5.89286 4.2252 5.89286H4.84636C5.38871 4.21138 6.86187 3 8.68409 3C9.08006 3 9.446 3.05826 9.67495 3.10446C9.80256 3.13058 9.88326 3.2692 9.84948 3.40379L9.62428 4.29576C9.59238 4.4183 9.47791 4.49464 9.36155 4.46853C9.18703 4.43237 8.92242 4.38616 8.65031 4.38616C7.62003 4.38616 6.82997 4.98884 6.44338 5.89286H9.03877C9.1814 5.89286 9.28837 6.03549 9.25834 6.18616L9.13636 6.78482C9.12527 6.83833 9.09747 6.88619 9.05756 6.92052C9.01764 6.95485 8.96799 6.97359 8.91679 6.97366H6.16376C6.13561 7.2529 6.13937 7.53415 6.16189 7.82143H8.68221C8.82671 7.82143 8.93368 7.96406 8.90178 8.11473L8.78167 8.68527C8.77058 8.73878 8.74279 8.78664 8.70287 8.82097C8.66296 8.8553 8.61331 8.87404 8.56211 8.87411H6.42837C6.7386 9.73147 7.38926 10.363 8.22496 10.5398C9.70338 8.43847 12.0121 6.96351 14.6742 6.59178C15.2893 6.50589 15.7571 5.93884 15.5516 5.35274C14.4589 2.23543 11.4905 0 8 0C3.58172 0 0 3.58172 0 8C0 11.4905 2.23543 14.4589 5.35274 15.5516Z"
          fill="var(--ds-c-blue-dark)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 16C8 11.5868 11.5868 8 16 8C20.4132 8 24 11.5868 24 16C24 20.4132 20.4132 24 16 24C11.5868 24 8 20.4132 8 16ZM16.3868 15.1209H15.5604C15.3143 15.1209 15.1209 14.9275 15.1209 14.6813C15.1209 14.4352 15.3143 14.2418 15.5604 14.2418H17.7055C18.189 14.2418 18.5846 13.8462 18.5846 13.3626C18.5846 12.8791 18.189 12.4835 17.7055 12.4835H16.8791V11.6044C16.8791 11.1825 16.578 11.189 16.18 11.1975C16.1219 11.1987 16.0617 11.2 16 11.2C15.9383 11.2 15.8781 11.1987 15.82 11.1975C15.422 11.189 15.1209 11.1825 15.1209 11.6044V12.4835C15.1209 12.4923 15.1231 12.4989 15.1253 12.5055C15.1275 12.5121 15.1297 12.5187 15.1297 12.5275C14.1275 12.7297 13.3626 13.6176 13.3626 14.6813C13.3626 15.8945 14.3473 16.8791 15.5604 16.8791H16.3868C16.633 16.8791 16.8264 17.0725 16.8264 17.3538C16.8264 17.6 16.633 17.7934 16.3868 17.7934H14.2418C13.7582 17.7934 13.3626 18.189 13.3626 18.6725C13.3626 19.1648 13.7582 19.5516 14.2418 19.5516H15.1209V20.3956C15.1209 20.7902 15.3843 20.7768 15.744 20.7585C15.8251 20.7544 15.911 20.75 16 20.75C16.089 20.75 16.1749 20.7544 16.256 20.7585C16.6157 20.7768 16.8791 20.7902 16.8791 20.3956V19.4989C17.8549 19.2703 18.5846 18.4 18.5846 17.3187C18.5846 16.1055 17.6 15.1209 16.3868 15.1209Z"
          fill="var(--ds-c-blue-dark)"
        />
        <path
          d="M16.4277 3.25043L18.3225 4.73081C18.6248 4.96705 19.0665 4.71342 19.0224 4.33L18.9006 3.26859C19.6714 3.19357 20.4231 3.41101 21.0321 3.88681C22.1246 4.74269 22.4755 6.24194 21.9104 7.504C21.8682 7.59586 21.8934 7.70339 21.9725 7.76521L22.7822 8.39776C22.8881 8.48056 23.0449 8.44588 23.1081 8.32421C24.0889 6.43691 23.6377 4.07575 21.9252 2.73778C20.987 2.00477 19.8409 1.71261 18.7345 1.8328L18.6304 0.926291C18.5864 0.542863 18.1004 0.410523 17.8615 0.716246L16.3577 2.64101C16.2086 2.83191 16.2398 3.10361 16.4277 3.25043Z"
          fill="var(--ds-c-blue-dark)"
        />
        <path
          d="M6.07945 19.1182L8.16182 20.3205C8.36834 20.4397 8.43705 20.7044 8.31592 20.9142L7.09464 23.0295C6.90065 23.3655 6.40102 23.3021 6.30406 22.9285L6.07482 22.0453C4.99584 22.3184 3.82022 22.1886 2.78913 21.5933C0.907085 20.5067 0.13169 18.2313 0.84024 16.2258C0.885895 16.0966 1.03634 16.0404 1.1528 16.1076L2.04258 16.6214C2.12954 16.6716 2.16947 16.7745 2.14047 16.8714C1.75654 18.1998 2.31271 19.6356 3.5137 20.3311C4.18297 20.7175 4.95759 20.8282 5.71047 20.6467L5.44207 19.6125C5.34511 19.239 5.74714 18.9263 6.07945 19.1182Z"
          fill="var(--ds-c-blue-dark)"
        />
      </g>
      <defs>
        <clipPath id="clip0_8641_1797">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExchangeIconBlueDark;
