import { initializeApp } from 'firebase/app';
import { createContext } from 'react';
import { firebaseConfig } from './config';

const firebaseContext = createContext(null);
const { Provider } = firebaseContext;

let firebaseAppDefault;

const FirebaseProvider = ({ children }) => {
  firebaseAppDefault = initializeApp(firebaseConfig);

  return (
    <Provider
      value={{
        firebaseAppDefault,
      }}
    >
      {children}
    </Provider>
  );
};

export { FirebaseProvider, firebaseAppDefault, firebaseContext };
