/* eslint-disable no-unused-vars */
import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import useLang from 'hooks/useLang';
import usePortalModal from 'hooks/usePortalModal';
import { functionUtils } from 'utils';
import FxCalculatorModalRateTimeoutPopup from '../popups/FxCalculatorModalRateTimeoutPopup';

const FxCalculatorModalRateTimeoutModal = ({ isShow, content, action }) => {
  const { t } = useLang();
  const { closePortalModalName } = usePortalModal();

  const handleOnClick = () => {
    if (functionUtils.isFunction(action)) {
      action();
    }
    closePortalModalName();
  };

  return (
    <FxCalculatorModalRateTimeoutPopup
      isOpen={isShow}
      imageSrc={NoticeIcon}
      imagesStyles={{
        width: '124px',
        height: '100px',
      }}
      text={''}
      content={content}
      buttonLabel2={t('button_ok')}
      onClick={handleOnClick}
      isBackdropOverlap={true}
      zIndexBackdropOverlap={1055}
    />
  );
};

export default FxCalculatorModalRateTimeoutModal;
