import FXDescIcon from 'assets/ver2/icons/offer/modal/event_desc_icon.svg';
import TODescIcon from 'assets/ver2/icons/offer/modal/offer_desc_icon.svg';
import { EN_LANG } from 'constants';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';

const HeaderOfferDesc = ({ offer }) => {
  const { locate = EN_LANG } = useLang();

  const { descriptions, serviceType, shortDescription } = offer || {};

  const { description } = descriptions?.[locate] || {};

  const getDescriptionText = () => {
    if (isEmpty(shortDescription)) return description;
    return shortDescription?.[locate];
  };

  const getDescIcon = () => {
    if (!serviceType) return TODescIcon;
    if (serviceType === 'TO') return TODescIcon;
    return FXDescIcon;
  };

  return (
    <HeaderOfferDescStyled>
      <img src={getDescIcon()} width={40} height={40} />
      <DescText>{getDescriptionText()}</DescText>
    </HeaderOfferDescStyled>
  );
};

const HeaderOfferDescStyled = styled.div`
  display: flex;
  align-items: start;
  height: fit-content;
  gap: 8px;
`;

const DescText = styled.p`
  ${paragraphMixin};
  font-weight: 700;
  text-transform: uppercase;
`;

export default HeaderOfferDesc;
