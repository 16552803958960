import { api } from 'api';
import { PORTAL_NAME_MODAL_DEFINE } from 'components/common/PortalModal/config';
import { getHeaderEaPlatform } from 'components/common/func';
import { HEADER_AGENT_CODE_REFERENCE, HHMT_THEME, SENDER_ID } from 'constants';
import Cookies from 'helpers/cookies';
import { isArray, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { domUtils } from 'utils';
import useAuth from './useAuth';
import useCommonStore from './useCommonStore';
import useLang from './useLang';
import usePortalModal from './usePortalModal';

const useEvent = ({
  event = null,
  screenId = '',
  params = [],
  modalStyles = {},
  modalOptions = {},
}) => {
  const { locate } = useLang();
  const { events } = useCommonStore();
  const { token } = useAuth();
  const { showPortalModalName } = usePortalModal();

  const [isShowEvent, setShowEvent] = useState(false);

  const senderIdStorage = Cookies.get(SENDER_ID);

  const checkEventEligible = async (eventId) => {
    try {
      const onlineRefParam =
        (isArray(params) && domUtils.findParam(params, 'onlineRef')) || {};
      const senderIdParam =
        (isArray(params) && domUtils.findParam(params, 'senderId')) || {};
      if (onlineRefParam?.value) {
        const { data } = token
          ? await api.checkEventEligible(token, eventId, onlineRefParam?.value)
          : await api.checkEventEligiblePublic(
              eventId,
              onlineRefParam?.value,
              senderIdParam?.value || senderIdStorage
            );
        if (data?.eligible) {
          setShowEvent(true);
        }
      } else {
        setShowEvent(false);
      }
    } catch (error) {
      console.error(error?.message);
      setShowEvent(false);
    }
  };

  const currentEvent =
    !isEmpty(events) && !isEmpty(screenId)
      ? events?.find((e) => e?.screenId?.includes(screenId)) || null
      : !isEmpty(event)
      ? event
      : null;

  const isEventExistedAndActive =
    !isEmpty(events) && !isEmpty(currentEvent) ? currentEvent?.active : false;

  useEffect(() => {
    if (isEventExistedAndActive) {
      if (modalOptions?.isCheckEventEligible) {
        checkEventEligible(currentEvent?.id);
      } else {
        setShowEvent(true);
      }
      Cookies.remove(SENDER_ID);
    }
  }, [isEventExistedAndActive]);

  const showEventModal = () => {
    const paramsInitialRequired = [
      {
        key: 'lang',
        value: locate,
      },
      {
        key: 'hide_nav',
        value: 'false',
      },
      {
        key: 'eventId',
        value: currentEvent?.id,
      },
      {
        key: 'xAgentCode',
        value:
          HEADER_AGENT_CODE_REFERENCE[
            process.env.REACT_APP_APP_NAME || HHMT_THEME
          ],
      },
      {
        key: 'eaPlatform',
        value: getHeaderEaPlatform(),
      },
      ...params,
    ];
    showPortalModalName({
      name: PORTAL_NAME_MODAL_DEFINE.EVENT_IFRAME_MODAL,
      modalStyles: {
        width: modalStyles?.width,
        borderRadius: modalStyles?.borderRadius,
      },
      modalOptions: {
        iframeSrc: domUtils.getIframeSrc(
          modalOptions?.iframeSrc || currentEvent?.formUrl,
          paramsInitialRequired
        ),
        action1Name: modalOptions?.action1Name,
        action2Name: modalOptions?.action2Name,
      },
      action: () => {
        Cookies.remove(SENDER_ID);
      },
      actionClose: () => {
        Cookies.remove(SENDER_ID);
      },
    });
  };

  return { isShowEvent, showEventModal };
};

export default useEvent;
