import Avatar from 'components/Profile/components/Avatar';
import ProfileBody from 'components/Profile/components/ProfileBody';
import ProfileBodyMobile from 'components/Profile/components/ProfileBody/mobile';
import NavbarFooter from 'components/common/NavbarFooter/ver2';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { useDetectDevice } from 'hooks/useDetectDevice';
import styled from 'styled-components';

const ProfilePage = () => {
  const { isMobile, isTablet } = useDetectDevice();

  if (isMobile || isTablet) {
    return (
      <ProfileStyled>
        <br />
        <Avatar
          mobileStyles={{
            color: '#fff',
            fontSize: '50px',
          }}
        />
        <ProfileBodyMobile />
        <NavbarFooter />
      </ProfileStyled>
    );
  }
  return (
    <ProfileStyled>
      <Avatar
        styles={{
          color: '#fff',
          fontSize: '50px',
        }}
      />
      <ProfileBody />
    </ProfileStyled>
  );
};

const ProfileStyled = styled.div`
  margin: 70px 0px 0px 0px;
  padding: 24px 24px;
  height: calc(100% - 70px);
  width: 100%;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin: unset;
    padding: unset;
    height: fit-content;
    width: 657px;
    margin-inline: auto;
    padding-bottom: 88px;
    margin-top: 114px;
  }
`;

export default ProfilePage;
