import CustomDatepickerSelected from 'components/common/CustomDatepickerSelected';
import { BACKSPACE_CODE, DELETE_CODE } from 'components/common/func';
import { datePicker, formatter } from 'helpers';
import useLang from 'hooks/useLang';
import useScrollIntoView from 'hooks/useScrollIntoView';
import { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import {
  DESKTOP_MIN_WIDTH,
  REGISTER_DOB_NAME,
  REGISTER_PERSONAL_DETAILS_DOB_FIELD,
} from '../../../../../constants';
import CalendarIcon from '../../../../common/Icons/CalendarIcon';

const FormInputDatepickerValidation = ({
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();

  const [isShowDatePickerIcon, setShowDatePickerIcon] = useState(true);
  const [isOpenDatePicker, setOpenDatePicker] = useState(false);
  const [datePickerISO, setDatePickerISO] = useState(null);
  const [dateMaskStr, setDateMaskStr] = useState(null);

  const validRef = useRef(null);
  useScrollIntoView(validRef, validation[REGISTER_DOB_NAME], [validation]);

  const dateFormat = formatter.formatParse(datePicker.dateFormat);

  useEffect(() => {
    if (information[REGISTER_DOB_NAME]) {
      const dateISO = datePicker.dateUTCToDateISO(
        information[REGISTER_DOB_NAME]
      );
      setDatePickerISO(dateISO);

      const { dateMask } = datePicker.getDateMask(
        '',
        information[REGISTER_DOB_NAME]
      );
      setDateMaskStr(formatter.mask(dateMask, dateFormat));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information]);

  const handleOnChange = (dateUTC) => {
    let newInfomation = { ...information };
    let newValidation = { ...validation };

    const dateISO = datePicker.dateUTCToDateISO(dateUTC);
    const dateMask = datePicker.dateUTCToDateStr(dateUTC)?.split('/')?.join('');

    setDatePickerISO(dateISO);

    setDateMaskStr(dateMask);

    newInfomation = {
      ...newInfomation,
      [REGISTER_DOB_NAME]: datePicker.getDateUTC(
        dateUTC.getDate(),
        dateUTC.getMonth(),
        dateUTC.getFullYear()
      ),
    };
    setInformation(newInfomation);

    const isOfAgeToUse = datePicker.getOfAgeToUse(dateUTC);
    newValidation = {
      ...newValidation,
      [REGISTER_DOB_NAME]: isOfAgeToUse
        ? ''
        : REGISTER_PERSONAL_DETAILS_DOB_FIELD.msgInvalid,
    };
    setValidation(newValidation);

    setOpenDatePicker(false);
  };

  const handleInputOnChange = (e) => {
    const { value } = e.target;

    if (value?.length > 10) return;

    let newValidation = { ...validation };

    setShowDatePickerIcon(true);

    if (!value) {
      const newInfomation = {
        ...information,
        [REGISTER_DOB_NAME]: '',
      };

      setDateMaskStr('');

      setInformation(newInfomation);

      return;
    }

    const { dateMask, dd, MM, yyyy } = datePicker.getDateMask(value);
    setDateMaskStr(formatter.mask(dateMask, dateFormat));

    newValidation = {
      ...newValidation,
      [REGISTER_DOB_NAME]: '',
    };

    if (value.length === 10) {
      const dateUTC = datePicker.getDateUTC(dd, MM - 1, yyyy);
      const dateISO = datePicker.dateUTCToDateISO(dateUTC);
      const currentYear = new Date(dateISO).getFullYear();

      if (
        datePicker.minYear > currentYear ||
        datePicker.maxYear < currentYear
      ) {
        setShowDatePickerIcon(false);

        setDatePickerISO(null);
      } else {
        setShowDatePickerIcon(true);

        setDatePickerISO(dateISO);
      }

      const newInfomation = {
        ...information,
        [REGISTER_DOB_NAME]: dateUTC,
      };
      setInformation(newInfomation);

      const isOfAgeToUse = datePicker.getOfAgeToUse(dateUTC);
      if (!isOfAgeToUse) {
        newValidation = {
          ...newValidation,
          [REGISTER_DOB_NAME]: REGISTER_PERSONAL_DETAILS_DOB_FIELD.msgInvalid,
        };
      }
    }

    setValidation(newValidation);
  };

  const handleInputOnKeyDown = (e) => {
    if ([BACKSPACE_CODE, DELETE_CODE].includes(e.which)) {
      const newInfomation = {
        ...information,
        [REGISTER_DOB_NAME]: '',
      };
      setInformation(newInfomation);

      const newValidation = {
        ...validation,
        [REGISTER_DOB_NAME]: '',
      };
      setValidation(newValidation);

      setDateMaskStr('');

      setShowDatePickerIcon(true);
    }
  };

  const handleOnToggleDatePicker = () => {
    setOpenDatePicker(!isOpenDatePicker);
  };

  return (
    <FormGroupStyled>
      <FormLabel>
        {t('form_date_of_birth')}
        <span>*</span>
      </FormLabel>
      <FormControl
        type={REGISTER_PERSONAL_DETAILS_DOB_FIELD.type}
        name={REGISTER_DOB_NAME}
        value={formatter.mask(dateMaskStr, dateFormat)}
        placeholder={REGISTER_PERSONAL_DETAILS_DOB_FIELD.placeholder}
        onClick={() => setOpenDatePicker(false)}
        onChange={handleInputOnChange}
        onKeyDown={handleInputOnKeyDown}
        isInvalid={validation[REGISTER_DOB_NAME]}
        readOnly={REGISTER_PERSONAL_DETAILS_DOB_FIELD.isReadOnly}
      />
      {isShowDatePickerIcon && (
        <CalendarIcon
          name={REGISTER_DOB_NAME}
          color="var(--ds-c-grey-dark)"
          size={{ width: 24, height: 24 }}
          onClick={handleOnToggleDatePicker}
          styles={{
            position: 'absolute',
            top: '36px',
            right: '10px',
            cursor: 'pointer',
          }}
        />
      )}
      {isOpenDatePicker && (
        <CustomDatepickerSelected
          isOpen={isOpenDatePicker}
          date={datePickerISO}
          onChange={handleOnChange}
          onClose={handleOnToggleDatePicker}
        />
      )}
      <FormControlFeedback ref={validRef} hidden={false} type="invalid">
        {t(validation[REGISTER_DOB_NAME])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  display: block;
  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-right: 8px;
  background: var(--ds-c-white);

  &:focus {
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &[readonly] {
    background: var(--ds-c-white);
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &[type='date']::-webkit-calendar-picker-indicator {
    background: none !important;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputDatepickerValidation;
