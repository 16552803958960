/* eslint-disable no-unused-vars */
import {
  checkDiscountAppliedInvalidWhenPaymentMethodChangeBeforeTxnCreated,
  checkDiscountAppliedValidWhenPaymentMethodChangeAfterTxnCreated,
  getDiscountDefault,
} from 'components/Discount/func';
import { PORTAL_NAME_MODAL_DEFINE } from 'components/common/PortalModal/config';
import { DESKTOP_MIN_WIDTH, METHOD_EPAYMENT } from 'constants';
import {
  FX_ADD_TRANSACTION_PAYMENT_METHOD_INSTRUCTION,
  FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  RESET_FX_ADD_TRANSACTION_PAYMENT_METHOD,
} from 'constants/fx';
import { QuestionIcon } from 'constants/icon';
import { format } from 'helpers';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useLang from 'hooks/useLang';
import usePortalModal from 'hooks/usePortalModal';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { stringUtils } from 'utils';

const initialFormField = RESET_FX_ADD_TRANSACTION_PAYMENT_METHOD;

const PaymentMethodItem = ({
  method,
  information,
  setInformation,
  validation,
  setValidation,
  informationChangeBackup,
  setInformationChangeBackup,
  setOpenDiscountImportantPopup,
  payLimit,
  isDisabled,
  isSelectRequired,
}) => {
  const { t } = useLang();
  const { showPortalModalName } = usePortalModal();

  const {
    paymentMethod: paymentMethodStore,
    setPaymentMethod,
    paymentMethodName,
  } = useFxFormAddTransactionStore();
  const {
    isUpdateTran: isUpdateTxn,
    isDisabled: isPreventRemoveDiscountWhenPaymentMethodChange,
  } = useFxAddTransaction();

  const isSelected = paymentMethodName === method.method;

  const isPayLimit = isDisabled;

  const {
    discountApplied,
    discountsAvailable,
    discountsInactive,
    discountDetails,
  } = paymentMethodStore || {};
  const discountDefault = getDiscountDefault({
    discountsAvailable,
    discountsInactive,
  });

  // const getDiscountsAvailable = (paymentMethodName) => {
  //   if (paymentMethodName) {
  //     fetchDiscountsAvailable({
  //       token,
  //       countryCode: '',
  //       payoutMethod: '',
  //       currencyCode: '',
  //       isOnlyCurrency: true,
  //       sendAmount: sendTotalAmount,
  //       receiverAmount: '',
  //       paymentMethod: paymentMethodName,
  //     });
  //   }
  // };
  // useEffect(() => {
  //   if (!isUpdateTxn && isSelected && paymentMethodName && !discountApplied) {
  //     getDiscountsAvailable(paymentMethodName);
  //   }
  // }, [isSelected, paymentMethodName]);

  const handlePaymentMethodSelected = (method) => {
    const newInformation = {
      ...information,
      [FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME]: method,
    };
    if (isPreventRemoveDiscountWhenPaymentMethodChange) {
      setInformation(newInformation);
      setValidation(initialFormField);
      const newPaymentMethod = {
        ...paymentMethodStore,
        [FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME]: method,
      };
      setPaymentMethod(newPaymentMethod);
      return;
    }

    const isCheckDiscountAppliedAfterTxnCreated =
      checkDiscountAppliedValidWhenPaymentMethodChangeAfterTxnCreated({
        isUpdateTxn,
        discount: discountDetails,
        currentPaymentMethodSelected: method,
      });

    const isCheckDiscountAppliedBeforeTxnCreated =
      checkDiscountAppliedInvalidWhenPaymentMethodChangeBeforeTxnCreated({
        isUpdateTxn,
        discountApplied,
        prevPaymentMethodSelected:
          paymentMethodStore[FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME],
        currentPaymentMethodSelected: method,
      }) && !isCheckDiscountAppliedAfterTxnCreated;

    if (
      paymentMethodName &&
      isEmpty(discountDefault) &&
      (isCheckDiscountAppliedBeforeTxnCreated ||
        (discountApplied && !isCheckDiscountAppliedAfterTxnCreated))
    ) {
      setOpenDiscountImportantPopup(true);

      setInformationChangeBackup(newInformation);

      return;
    }

    setInformation(newInformation);

    setValidation(initialFormField);

    const newPaymentMethod = {
      ...paymentMethodStore,
      ...newInformation,
      ...(paymentMethodName &&
        isEmpty(discountDefault) && { discountsAvailable: [] }),
      ...(paymentMethodName &&
        isEmpty(discountDefault) &&
        !isCheckDiscountAppliedAfterTxnCreated && {
          discountApplied: null,
        }),
    };
    setPaymentMethod(newPaymentMethod);
  };

  const handleShowPaymentInstructionModal = () => {
    showPortalModalName({
      name: PORTAL_NAME_MODAL_DEFINE.FX_PAYMENT_INSTRUCTION_MODAL,
      title: method.method,
      content: t(FX_ADD_TRANSACTION_PAYMENT_METHOD_INSTRUCTION[method.method], {
        amount: format.toAmountCentStr(2000),
      }),
    });
  };

  if (isPayLimit) {
    return (
      <PaymentMethodItemStyled
        $isSelected={isSelected}
        $isDisabled={isDisabled}
      >
        <img className="payment-icon" src={method.icon} alt={method.label} />
        <ImageWrap
          $isSelected={isSelected}
          $isDisabled={isDisabled}
          $isPayLimit={isPayLimit}
        >
          <p
            className="image-label"
            dangerouslySetInnerHTML={{
              __html: stringUtils.replaceBreakLine(t(method.label)),
            }}
          />
          <p className="image-desc">{t(method.desc)}</p>
          {method?.payLimitDesc &&
            isDisabled &&
            method.method === METHOD_EPAYMENT &&
            payLimit?.payLimitMax && (
              <p className="image-sub-desc">{`${t(
                method.payLimitDesc
              )} < ${format.toAmountCentStr(payLimit?.payLimitMax)} AUD`}</p>
            )}
        </ImageWrap>
        <InstructionImage
          src={QuestionIcon}
          width={20}
          height={20}
          onClick={handleShowPaymentInstructionModal}
        />
      </PaymentMethodItemStyled>
    );
  }
  return (
    <>
      <PaymentMethodItemStyled
        $isSelected={isSelected}
        $isDisabled={isDisabled}
        $isRequired={isSelectRequired}
      >
        <img
          className="payment-icon"
          src={isSelected ? method.iconColor : method.icon}
          alt={method.label}
          onClick={() => handlePaymentMethodSelected(method.method)}
        />
        <ImageWrap
          $isSelected={isSelected}
          $isPayLimit={false}
          onClick={() => handlePaymentMethodSelected(method.method)}
        >
          <p
            className="image-label"
            dangerouslySetInnerHTML={{
              __html: stringUtils.replaceBreakLine(t(method.label)),
            }}
          />
          {method?.desc && <p className="image-desc">{t(method.desc)}</p>}
          {method?.payLimitDesc &&
            method.method === METHOD_EPAYMENT &&
            payLimit?.payLimitMax && (
              <p className="image-sub-desc">{`${t(
                method.payLimitDesc
              )} < ${format.toAmountCentStr(payLimit?.payLimitMax)} AUD`}</p>
            )}
        </ImageWrap>
        <InstructionImage
          src={QuestionIcon}
          width={20}
          height={20}
          onClick={handleShowPaymentInstructionModal}
        />
      </PaymentMethodItemStyled>
    </>
  );
};

const PaymentMethodItemStyled = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background-color: ${(props) => (props.$isSelected ? '#FEF0D1' : 'unset')};
  box-shadow: ${(props) =>
    props.$isSelected ? '0px 4px 15px 0px #0000001a' : 'unset'};
  border: ${(props) =>
    props.$isRequired
      ? '1px solid var(--ds-c-red)'
      : props.$isSelected
      ? '1px solid transparent'
      : '1px solid var(--ds-bg-2)'};
  cursor: pointer;
  padding: 10px;

  ${(props) =>
    props.$isDisabled &&
    `
    background-color: #eeeeee;
    border: 1px dashed transparent;
  `};

  & img.payment-icon {
    width: 62px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 4px;
    ${(props) => props.$isDisabled && 'filter: grayscale(1)'};
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 206px;
    height: 162px;
  }
`;

const ImageWrap = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  color: var(--ds-c-grey-dark);
  user-select: none;

  display: grid;
  align-items: center;
  justify-items: start;
  margin-top: 0px;
  width: 100%;

  & p.image-label {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-align: left;
    white-space: break-spaces;

    width: 162px;
    margin-bottom: 4px;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & p.image-desc {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    text-transform: capitalize;

    margin-bottom: 0px;
  }

  & p.image-sub-desc {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    text-transform: capitalize;
    color: ${(props) =>
      props.$isPayLimit ? 'var(--ds-c-red)' : 'var(--ds-c-blue)'};

    margin-bottom: 0px;
    margin-top: 2px;
  }
`;

const InstructionImage = styled.img`
  margin-right: 6px;
`;
const InstructionBodyContent = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  width: 100%;
  padding-bottom: 8px;

  & p {
    cursor: text;
    margin: 0;
    padding: 0;
  }

  & p.instruction-title {
    font-weight: 600;
    text-transform: uppercase;
    color: var(--ds-c-green-default);
    margin-bottom: 20px;
  }

  & p.instruction-content {
  }

  & p.instruction-contact {
  }

  & a {
    color: var(--bg-primary) !important;
    text-decoration: none;
    text-transform: none;

    &:focus,
    &:focus-visible,
    &:active {
      color: var(--bg-primary) !important;
    }

    &:hover {
      color: var(--bg-primary-hover) !important;
    }
  }

  & span {
    font-weight: 700;
  }

  & span.warning {
    font-weight: 500;
    color: var(--ds-c-red);
  }

  & ol {
    margin: 0;
    padding-left: 24px;

    & li {
      list-style: auto;
      list-style-type: decimal;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default PaymentMethodItem;
