import { api } from 'api';
import EditIcon from 'assets/icons/edit-outline-icon.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import EmailIcon from 'components/common/Icons/Email';
import JobIcon from 'components/common/Icons/JobIcon';
import LocationIcon from 'components/common/Icons/LocationIcon';
import PhoneIcon from 'components/common/Icons/PhoneIcon';
import ProfileOutlineIcon from 'components/common/Icons/ProfileOutlineIcon';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { format } from 'helpers';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styled from 'styled-components';

import { SCREEN_ID_PROFILE_DETAILS } from 'constants';
import useEvent from 'hooks/useEvent';
import FormEditProfileAddress from '../../FormEditProfileAddress';
import FormEditProfileInformation from '../../FormEditProfileInformation';

const SCREEN_ID = SCREEN_ID_PROFILE_DETAILS;

const PersonalDetailsMobile = ({ currentTab, setCurrentTab }) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const { state } = useStore();
  const { currentUser, configOccupation } = state;
  const { firstName, lastName, mobile, email, address } = currentUser || {};
  const { token } = useAuth();

  const [currentJob, setCurrentJob] = useState(null);
  const [
    isShowFormEditProfileInformationPopup,
    setShowFormEditProfileInformationPopup,
  ] = useState(false);
  const [
    isShowFormEditProfileAddressPopup,
    setShowFormEditProfileAddressPopup,
  ] = useState(false);

  const fetchProfileOccupation = async (token) => {
    try {
      const { data } = await api.getOccupation(token);

      if (data) {
        const occupationInit = configOccupation?.find(
          (o) => o.key === data.job
        );
        const previousOccupationInit = configOccupation?.find(
          (o) => o.key === data.previousJob
        );

        setCurrentJob({
          job: occupationInit,
          previousJob: previousOccupationInit,
        });
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  const initialProfileInformation = async (token) => {
    await Promise.all([fetchProfileOccupation(token)]);
  };

  useEffect(() => {
    initialProfileInformation(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, currentUser, configOccupation]);

  const handleOnOpenFormEditProfileInformationPopup = async () => {
    setShowFormEditProfileInformationPopup(true);
  };

  const handleOnCloseFormEditProfileInformationPopup = () => {
    setShowFormEditProfileInformationPopup(false);
  };

  const handleOnOpenFormEditProfileAddressPopup = async () => {
    setShowFormEditProfileAddressPopup(true);
  };

  const handleOnCloseFormEditProfileAddressPopup = () => {
    setShowFormEditProfileAddressPopup(false);
  };

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [],
    modalStyles: {},
    modalOptions: {},
  });
  useEffect(() => {
    if (!isShowEvent) return;
    showEventModal();
  }, [isShowEvent]);

  return (
    <>
      <style>
        {`
          .personal-details-mobile-offcanvas {
            width: 100%!important;
            height: calc(100% - 70px);
            border: none!important;
          }
          .personal-details-mobile-offcanvas-backdrop {
            opacity: 0!important;
          }
        `}
      </style>

      {isShowFormEditProfileInformationPopup && (
        <FormEditProfileInformation
          isOpen={isShowFormEditProfileInformationPopup}
          onClose={handleOnCloseFormEditProfileInformationPopup}
          currentJob={currentJob}
          fetchProfileOccupation={fetchProfileOccupation}
        />
      )}

      {isShowFormEditProfileAddressPopup && (
        <FormEditProfileAddress
          isOpen={isShowFormEditProfileAddressPopup}
          onClose={handleOnCloseFormEditProfileAddressPopup}
        />
      )}

      <PersonalDetailsMobileStyled>
        <NavbarStyled
          expand={false}
          expanded={currentTab === 0}
          onToggle={() => setCurrentTab(null)}
        >
          <NavbarOffcanvas
            id="offcanvasNavbar-expand-false"
            aria-labelledby="offcanvasNavbarLabel-expand-false"
            placement="end"
            className="personal-details-mobile-offcanvas"
            backdropClassName="personal-details-mobile-offcanvas-backdrop"
          >
            <OffcanvasHeader closeButton>
              <OffcanvasTitle id="offcanvasNavbarLabel-expand-false">
                {t('label_personal_details')}
              </OffcanvasTitle>
            </OffcanvasHeader>
            <OffcanvasBody>
              <Title>
                {t('label_general_information')}{' '}
                <EditIconStyled
                  src={EditIcon}
                  alt="edit-icon"
                  onClick={handleOnOpenFormEditProfileInformationPopup}
                  $isHHMTTheme={isHHMTTheme}
                />
              </Title>
              <FieldWrap>
                <ProfileOutlineIcon
                  color="var(--ds-c-grey-dark)"
                  size={{ width: 24, height: 24 }}
                />
                <FieldValue>{`${firstName} ${lastName}`}</FieldValue>
              </FieldWrap>
              <FieldWrap>
                <EmailIcon size={{ width: 24, height: 24 }} />
                <FieldValue>{email}</FieldValue>
              </FieldWrap>
              <FieldWrap>
                <PhoneIcon size={{ width: 24, height: 24 }} />
                <FieldValue>
                  {mobile ? format.toMobileNumberStr(mobile) : '-'}
                </FieldValue>
              </FieldWrap>
              {currentJob?.job && (
                <FieldWrap>
                  <JobIcon size={{ width: 24, height: 24 }} />
                  <FieldValue>
                    {currentJob?.previousJob
                      ? `${currentJob?.job?.value} (${currentJob?.previousJob?.value})`
                      : currentJob?.job?.value}
                  </FieldValue>
                </FieldWrap>
              )}
              <Divider />
              <Title>
                {t('label_residential_address')}{' '}
                <EditIconStyled
                  src={EditIcon}
                  alt="edit-icon"
                  onClick={handleOnOpenFormEditProfileAddressPopup}
                  $isHHMTTheme={isHHMTTheme}
                />
              </Title>
              <FieldWrap>
                <LocationIcon size={{ width: 24, height: 24 }} />
                <FieldValue style={{ textTransform: 'uppercase' }}>
                  {address?.fullAddress}
                </FieldValue>
              </FieldWrap>
              <ButtonActions>
                <ButtonCommon
                  value={t('button_back')}
                  color="var(--c-primary)"
                  background="var(--bg-primary)"
                  isFill={true}
                  styles={{
                    margin: '0px',
                    width: '100%',
                  }}
                  onClick={() => setCurrentTab(null)}
                />
              </ButtonActions>
            </OffcanvasBody>
          </NavbarOffcanvas>
        </NavbarStyled>
      </PersonalDetailsMobileStyled>
    </>
  );
};

const PersonalDetailsMobileStyled = styled.div`
  position: relative;
`;

const NavbarStyled = styled(Navbar)`
  display: initial;
  position: absolute;
  top: -40px;
  right: 0;
  padding: 0;
  height: 40px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    top: unset;
    right: unset;
  }
`;

const NavbarOffcanvas = styled(Navbar.Offcanvas)`
  margin-top: 70px;
`;
const OffcanvasHeader = styled(Offcanvas.Header)`
  height: 56px;
  padding: 16px 24px;
  border-bottom: none;

  & button {
    display: none;
  }
`;
const OffcanvasTitle = styled(Offcanvas.Title)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #7a8189;
`;
const OffcanvasBody = styled(Offcanvas.Body)`
  padding-inline: 24px;
  padding-block: 0px;
`;

const FieldWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: fit-content;
  min-height: 40px;
  margin-top: 8px;
  padding: 10px 0;
`;
const FieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 16px;

  & span {
    color: #7a8189;
  }
`;
const Title = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #0e1012;

  display: flex;
  align-items: center;
`;
const EditIconStyled = styled.img`
  margin-left: 8px;
  filter: ${(props) =>
    props.$isHHMTTheme ? 'var(--ds-f-c-yellow)' : 'var(--ds-f-c-blue)'};
  cursor: pointer;
`;

const ButtonActions = styled.div`
  position: absolute;
  bottom: 0px;
  background: var(--ds-c-white);
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  border-top: 1px solid var(--ds-c-grey-disabled);
  height: 70px;
  display: flex;
  align-items: center;
  z-index: 3;
`;

const Divider = styled.div`
  border-bottom: 1px solid #eef2f5;
  margin-bottom: 24px;
  margin-top: 24px;
`;

export default PersonalDetailsMobile;
