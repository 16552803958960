/* eslint-disable no-unused-vars */
import DashedLineSvg from 'assets/images/discount/dashed_line.svg';
import DashedLineDarkSvg from 'assets/images/discount/dashed_line_dark.svg';
import { useDetectDevice } from 'hooks/useDetectDevice';
import { useCallback, useMemo } from 'react';

const CircleRoundCut = ({ isBorderDashedLine = false }) => {
  const { isSmallMobile } = useDetectDevice();

  const positionDashedLineStyle = useMemo(() => {
    if (isBorderDashedLine && isSmallMobile)
      return {
        right: '101px',
        top: '22px',
      };
    if (isBorderDashedLine)
      return {
        right: '101px',
        top: '18px',
      };

    if (isSmallMobile) {
      return { right: '103px', top: '24px' };
    }
    return { right: '103px', top: '19px' };
  }, [isBorderDashedLine, isSmallMobile]);
  const DashedLine = useCallback(() => {
    if (isBorderDashedLine)
      return (
        <img
          src={DashedLineDarkSvg}
          style={{ position: 'absolute', ...positionDashedLineStyle }}
          width={2}
          height={60}
        />
      );

    return (
      <img
        src={DashedLineSvg}
        style={{ position: 'absolute', ...positionDashedLineStyle }}
        width={2}
        height={60}
      />
    );
  }, [isBorderDashedLine]);

  const positionRoundCutStyle = useCallback(
    (isBottom) => {
      if (isBottom && isSmallMobile)
        return {
          top: '9px',
        };
      if (isBottom)
        return {
          top: '8.5px',
        };

      if (isSmallMobile)
        return {
          top: '-3px',
          transform: 'rotate(180deg)',
        };
      return {
        top: '-3px',
        transform: 'rotate(180deg)',
      };
    },
    [isSmallMobile]
  );
  const RoundCut = ({ isBottom }) => (
    <div
      style={{
        width: '26px',
        height: '14px',
        position: 'absolute',
        right: '-3px',
        ...positionRoundCutStyle(isBottom),
        borderRadius: '50% 50% 50% 50% / 0% 0% 100% 100%',
        background: 'white',
      }}
    />
  );

  const positionCircleStyle = useCallback(
    (isBottom) => {
      if (isBorderDashedLine && isBottom && isSmallMobile) {
        return {
          top: '95px',
          right: '90px',
          border: '2px dashed #A4B2CF',
        };
      }
      if (isBorderDashedLine && isBottom) {
        return {
          top: '86px',
          right: '90px',
          border: '2px dashed #A4B2CF',
        };
      }
      if (isBottom && isSmallMobile) {
        return {
          top: '98px',
          right: '92px',
        };
      }
      if (isBottom) {
        return {
          top: '90px',
          right: '92px',
        };
      }

      if (isBorderDashedLine && isSmallMobile) {
        return {
          top: '-15px',
          right: '90px',
          border: '2px dashed #A4B2CF',
        };
      }
      if (isBorderDashedLine) {
        return {
          top: '-15px',
          right: '90px',
          border: '2px dashed #A4B2CF',
        };
      }
      if (isSmallMobile) {
        return {
          top: '-13px',
          right: '92px',
        };
      }
      return {
        top: '-14px',
        right: '92px',
      };
    },
    [isBorderDashedLine, isSmallMobile]
  );
  const Circle = ({ isBottom }) => (
    <div
      style={{
        width: '24px',
        height: '24px',
        borderRadius: '50px',
        backgroundColor: 'white',
        position: 'absolute',
        ...positionCircleStyle(isBottom),
      }}
    >
      {isBorderDashedLine && <RoundCut isBottom={isBottom} />}
    </div>
  );

  return (
    <>
      <Circle />
      <DashedLine />
      <Circle isBottom />
    </>
  );
};

export default CircleRoundCut;
