/* eslint-disable no-extra-boolean-cast */
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useScrollIntoView from 'hooks/useScrollIntoView';
import { useRef } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import {
  DESKTOP_MIN_WIDTH,
  REGISTER_FISTNAME_NAME,
  REGISTER_LASTNAME_NAME,
  REGISTER_MIDDLENAME_NAME,
} from '../../../../../constants';
import TooltipCommon from '../../../../common/TooltipCommon';
import TooltipCommonMobile from '../../../../common/TooltipCommon/mobile';

const customFormInputStyleMobile = {};

const customFormInputStyle = {
  [REGISTER_FISTNAME_NAME]: {
    width: 'calc(50% - 12px)',
    float: 'left',
    marginRight: '12px',
    zIndex: 1,
  },
  [REGISTER_MIDDLENAME_NAME]: {
    width: 'calc(50% - 12px)',
    float: 'left',
    marginLeft: '12px',
    zIndex: 1,
  },
  [REGISTER_LASTNAME_NAME]: {
    width: '100%',
    float: 'left',
    zIndex: 1,
  },
};

const FormInputValidation = ({
  field,
  information,
  setInformation,
  validation,
  setValidation,
  checkFormInputValidation,
}) => {
  const { t } = useLang();
  const { isMobile, isTablet, isDesktop } = useDetectDevice();

  const validRef = useRef(null);
  useScrollIntoView(validRef, validation[field.name], [validation]);

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    const valueTrim =
      field.name === REGISTER_LASTNAME_NAME ||
      field.name === REGISTER_MIDDLENAME_NAME
        ? value
        : value?.trim();

    let newValidation = { ...validation };

    let newInformation = {
      ...information,
      [name]: valueTrim,
    };

    if (name === REGISTER_MIDDLENAME_NAME) {
      newInformation = {
        ...information,
        [name]: value?.trim() ? value.toUpperCase() : '',
      };
    }

    if (name === REGISTER_LASTNAME_NAME || name === REGISTER_FISTNAME_NAME) {
      if (!Boolean(value?.trim())) {
        newInformation = {
          ...newInformation,
          ...(name === REGISTER_FISTNAME_NAME && {
            [REGISTER_FISTNAME_NAME]: '',
          }),
          ...(name === REGISTER_LASTNAME_NAME && {
            [REGISTER_LASTNAME_NAME]: '',
          }),
        };
        setInformation(newInformation);

        newValidation = checkFormInputValidation('', field, newValidation);
        setValidation(newValidation);
        return;
      }
    }

    newValidation = checkFormInputValidation(
      newInformation[name],
      field,
      newInformation,
      validation
    );

    setInformation(newInformation);

    setValidation(newValidation);
  };
  const handleOnBlur = (e) => {
    handleOnChange(e);
  };

  const handleFormLabelOnClick = (e, name) => {
    e.preventDefault();

    if (isDesktop) {
      const inputEle = document.getElementsByName(name)[0];

      if (inputEle) {
        inputEle.focus();
      }
    }
  };

  return (
    <FormGroupStyled
      ref={validRef}
      style={
        isMobile || isTablet
          ? customFormInputStyleMobile[field.name]
          : customFormInputStyle[field.name]
      }
    >
      <FormLabel onClick={(e) => handleFormLabelOnClick(e, field.name)}>
        {t(field.label)}
        {field.isRequired && <span>*</span>}
        {field.tooltip && (
          <>
            {isMobile || isTablet ? (
              <TooltipCommonMobile
                text={t(field.tooltip)}
                name={field.name}
                label={t(field.label)}
              />
            ) : (
              <TooltipCommon text={t(field.tooltip)} />
            )}
          </>
        )}
      </FormLabel>
      <FormControl
        type={field.type}
        name={field.name}
        value={information ? information[field.name] : ''}
        placeholder={field.placeholder ? t(field.placeholder) : ''}
        isInvalid={validation[field.name]}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        readOnly={field.isReadOnly}
      />
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[field.name])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: var(--ds-c-white);

  &:focus {
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-inline: 10px !important;
  }

  &[readonly] {
    border: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputValidation;
