/* eslint-disable no-unused-vars */
import { PORTAL_NAME_MODAL_DEFINE } from 'components/common/PortalModal/config';
import { isString } from 'lodash';
import useLang from '../useLang';
import usePortalModal from '../usePortalModal';
import useStore from '../useStore';
import { CurrenciesModel, MessageModel } from './model/ServiceAlertsModel';

const useServiceAlertsModal = () => {
  const { locate: lang } = useLang();
  const { state } = useStore();

  const { portalModal } = state;
  const { isShowPortalModal, namePortalModal } = portalModal;
  const currentModal = namePortalModal;

  const {
    showPortalModalName: showModalName,
    closePortalModalName: closeModalName,
  } = usePortalModal();

  const isServiceAlertsModalShowing =
    isShowPortalModal &&
    currentModal === PORTAL_NAME_MODAL_DEFINE.SERVICE_ALERTS_MODAL;

  const getMessageWithBreakLine = (message1?: string, message2?: string) => {
    if (message1 && message2) {
      return `${message1}\n${message2}`;
    }

    if (message1 && !message2) {
      return message1;
    }

    if (!message1 && message2) {
      return message2;
    }

    return '';
  };
  const getServiceAlertsCurrenciesMessage = (
    currencies: CurrenciesModel | null,
    currencyCode?: string | null,
    currencyCodeAnother?: string | null
  ) => {
    if (!currencies) {
      return '';
    }

    if (currencyCode && !currencyCodeAnother) {
      return getMessageWithBreakLine(
        currencies?.[currencyCode]?.message?.[lang]
      );
    }

    if (!currencyCode && currencyCodeAnother) {
      return getMessageWithBreakLine(
        currencies?.[currencyCodeAnother]?.message?.[lang]
      );
    }

    if (currencyCode && currencyCodeAnother) {
      return getMessageWithBreakLine(
        currencies?.[currencyCode]?.message?.[lang],
        currencies?.[currencyCodeAnother]?.message?.[lang]
      );
    }

    return '';
  };
  const getServiceAlertsMessage = (
    message?: MessageModel | null,
    currencies?: CurrenciesModel | null,
    currencyCode?: string | null,
    currencyCodeAnother?: string | null
  ) => {
    let msgMessage: string = '';
    let msgCurrencies: string = '';

    if (message) {
      msgMessage = message[lang] || '';
    }

    if (currencies) {
      msgCurrencies = getServiceAlertsCurrenciesMessage(
        currencies,
        currencyCode,
        currencyCodeAnother
      );
    }

    return getMessageWithBreakLine(msgMessage, msgCurrencies);
  };

  const serviceAlertsModal = (message: string, onClick?: () => void) => {
    showModalName({
      name: PORTAL_NAME_MODAL_DEFINE.SERVICE_ALERTS_MODAL,
      title: '',
      content: `${isString(message) ? message.replace(/\\n/g, '\n') : ''}`,
      action: onClick ? onClick : () => {},
      actionClose: null,
      modalStyles: null,
      modalOptions: null,
    });
  };
  const onShowServiceAlertsModal = (
    message: MessageModel | null,
    onClick?: () => void
  ) => {
    serviceAlertsModal(getServiceAlertsMessage(message), onClick);
  };
  const handleShowServiceAlertsModal = ({
    message = null,
    currencies = null,
    type = '',
    onClick = () => {},
  }: {
    message?: MessageModel | null;
    currencies?: CurrenciesModel | null;
    type?: string | null;
    onClick?: () => void;
  }) => {
    if (type && message) {
      onShowServiceAlertsModal(message, onClick);
    }
  };

  const closeServiceAlertsModal = (sec?: number) => {
    const timer = setTimeout(() => {
      if (currentModal === PORTAL_NAME_MODAL_DEFINE.SERVICE_ALERTS_MODAL) {
        closeModalName();
      }
      clearTimeout(timer);
    }, (sec || 0) * 1000 || 0);
  };

  return {
    isServiceAlertsModalShowing,
    currentModal,
    handleShowServiceAlertsModal,
    closeServiceAlertsModal,
  };
};

export default useServiceAlertsModal;
