/* eslint-disable no-unused-vars */
import AddIcon from 'assets/icons/add-receiver-outline-icon.svg';
import BusinessIcon from 'assets/icons/business-icon.svg';
import IndividualIcon from 'assets/icons/individual-icon.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import { fetchReceiverInformation } from 'components/Receivers/func';
import {
  BUSINESS_OF_RECEIVER_TYPE,
  DESKTOP_MIN_WIDTH,
  RECEIVERS_ADD_SUFIX,
  RESET_ADD_TRANSACTION_INFORMATION_STORE,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import { ServiceAlertsByCountryCodeDefault } from 'hooks/service-alerts/model/ServiceAlertsLocalStore';
import useServiceAlertsStore from 'hooks/service-alerts/useServiceAlertsStore';
import useAuth from 'hooks/useAuth';
import useInputFocus from 'hooks/useInputFocus';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router';
import { SET_ADD_TRANSACTION_INFORMATION } from 'store/action';
import styled from 'styled-components';
import { arrayUtils, domUtils } from 'utils';

const RECEIVERS_SEARCH_NAME = 'receivers-search-field';

const CustomReceiversDropdown = ({
  field,
  receivers,
  information,
  setInformation,
  validation,
  setValidation,
  currentReceiverSelected,
  setCurrentReceiverSelected,
  isDisabled,
}) => {
  const { t } = useLang();
  const navigate = useNavigate();
  const { token } = useAuth();
  const { store, dispatch } = useStore();
  const { currentReceiver } = store || {};

  const query = useQuery();
  const rid = query.get('rid');

  const [searchValue, setSearchValue] = useState('');
  const [receiversSearch, setReceiversSearch] = useState(null);
  const [isShowDropdown, setShowDropdown] = useState(false);

  const { serviceAlertsByCountryCode, setServiceAlertsByCountryCode } =
    useServiceAlertsStore();
  const { isResetReceiverDropdown } = serviceAlertsByCountryCode || {};

  useEffect(() => {
    if (isResetReceiverDropdown) {
      setCurrentReceiverSelected(null);

      setServiceAlertsByCountryCode({
        ...ServiceAlertsByCountryCodeDefault,
        isResetReceiverDropdown: false,
      });
    }
  }, [isResetReceiverDropdown]);

  useInputFocus(RECEIVERS_SEARCH_NAME);

  useEffect(() => {
    if (isEmpty(receivers)) return;
    if (isEmpty(rid) && isEmpty(information[field.name])) return;
    const receiver = receivers?.find(
      (c) => c.receiverId === (rid || information[field.name]?.receiverId)
    );
    if (receiver) {
      setCurrentReceiverSelected(receiver);
      const newInformation = {
        ...information,
        [field.name]: receiver,
      };
      setInformation(newInformation);
    } else {
      setCurrentReceiverSelected(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rid, receivers]);

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    if (!value) {
      setReceiversSearch(null);

      return;
    }

    const newReceiverSearch = receivers?.filter(
      (receiver) =>
        receiver?.fullname?.toLowerCase()?.includes(value?.toLowerCase()) &&
        receiver
    );
    setReceiversSearch(newReceiverSearch);
  };

  /* eslint-disable no-constant-condition */
  const handleReceiverSelected = async (receiver) => {
    setSearchValue('');

    setReceiversSearch(null);

    setShowDropdown(false);

    setCurrentReceiverSelected(receiver);

    fetchReceiverInformation(token, receiver?.receiverId);

    const newInformation = {
      ...RESET_ADD_TRANSACTION_INFORMATION_STORE.receiverPayoutMethod,
      [field.name]: receiver,
    };
    setInformation(newInformation);

    const newValidation = {
      ...validation,
      [field.name]: '',
    };
    setValidation(newValidation);

    dispatch({
      type: SET_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...RESET_ADD_TRANSACTION_INFORMATION_STORE,
        receiverPayoutMethod: {
          ...RESET_ADD_TRANSACTION_INFORMATION_STORE.receiverPayoutMethod,
          [field.name]: receiver,
        },
      },
    });

    domUtils.changeAddressBarUrlWithoutReloadPage({
      prefix: TRANSACTION_ADD_PREFIX,
    });
  };

  const handleDropdowOnClick = (e, cb) => {
    e.preventDefault();

    if (isDisabled) return;

    setShowDropdown(!isShowDropdown);

    cb(e);
  };

  const handleDropdownOnToggle = () => {
    setSearchValue('');
    setReceiversSearch(null);

    setShowDropdown(!isShowDropdown);
  };

  const renderDropdownItems = (receiverArray) => {
    if (arrayUtils.isArrayEmpty(receiverArray)) {
      return (
        <DropdownItem
          eventKey={0}
          $isReceiversEmpty={arrayUtils.isArrayEmpty(
            receiversSearch || receivers
          )}
        >
          <NoReceiverText>{t('label_no_receiver')}</NoReceiverText>
        </DropdownItem>
      );
    }

    return (
      <>
        {receiverArray.map((receiver, index) => {
          const isReceiverOfBusinessType =
            receiver?.gender === BUSINESS_OF_RECEIVER_TYPE;

          return (
            <DropdownItem
              key={receiver.receiverId}
              eventKey={index}
              onClick={() => handleReceiverSelected(receiver)}
            >
              <p className="item-name-country">
                <img
                  src={isReceiverOfBusinessType ? BusinessIcon : IndividualIcon}
                  width={24}
                  height={24}
                  style={{ marginRight: '8px' }}
                />
                {`${receiver.fullname} (${receiver.countryName})`}
              </p>
            </DropdownItem>
          );
        })}
      </>
    );
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <ButtonToggle
      href="/"
      ref={ref}
      onClick={(e) => handleDropdowOnClick(e, onClick)}
      $isPlaceholder={!currentReceiverSelected?.fullname}
      $isDisabled={isDisabled}
      $isShowDropdown={isShowDropdown}
    >
      {children}
      {!isDisabled && (
        <ArrowDownIcon
          color="var(--ds-c-grey-dark)"
          size={{ width: 14, height: 10 }}
          styles={{
            transform: isShowDropdown && 'rotate(180deg)',
          }}
        />
      )}
    </ButtonToggle>
  ));

  return (
    <ReceiverDropdownStyled>
      <DropdownStyled
        onToggle={handleDropdownOnToggle}
        $isInvalid={validation[field.name]}
        $isShowDropdown={isShowDropdown}
        $isDisabled={isDisabled}
      >
        <Dropdown.Toggle as={CustomToggle}>
          {currentReceiverSelected?.fullname || t(field.placeholder)}
        </Dropdown.Toggle>

        <DropdownMenuWrap>
          <SearchInput
            name={RECEIVERS_SEARCH_NAME}
            value={searchValue || ''}
            placeholder={t('form_placeholder_type_a_name_to_search')}
            onChange={handleSearch}
          />
          <DropdownMenu>
            {renderDropdownItems(receiversSearch || receivers)}
          </DropdownMenu>
          <AddButton
            $isReceiversEmpty={arrayUtils.isArrayEmpty(
              receiversSearch || receivers
            )}
            $receiverLength={(receiversSearch || receivers)?.length}
          >
            <ButtonCommon
              value={t('button_add_new_receiver')}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
              styles={{
                paddingInline: 0,
                height: '36px',
                borderRadius: 0,
              }}
              buttonIconStyles={{
                height: '36px',
                flexDirection: 'row-reverse',
                gap: '6px',
              }}
              iconSrc={AddIcon}
              onClick={() => navigate(`${RECEIVERS_ADD_SUFIX}`)}
            />
          </AddButton>
        </DropdownMenuWrap>
      </DropdownStyled>
      {validation[field.name] && (
        <FormControlFeedback>{t(validation[field.name])}</FormControlFeedback>
      )}
    </ReceiverDropdownStyled>
  );
};

const ReceiverDropdownStyled = styled.div`
  margin-bottom: 32px;
  width: 100%;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 437px;
  }
`;
const DropdownStyled = styled(Dropdown)`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 44px;
  height: fit-content;
  background: ${(props) =>
    props.$isDisabled ? '#eef2f5' : 'var(--ds-c-white)'};
  border: ${(props) =>
    props.$isInvalid
      ? '1px solid var(--ds-c-red)'
      : props.$isShowDropdown
      ? 'none'
      : '1px solid var(--ds-c-grey-light)'};
  border-radius: ${(props) =>
    props.$isShowDropdown ? '12px 12px 0 0' : '12px'};
`;
const DropdownMenuWrap = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  width: 100%;
  height: 44px;
  background: transparent;
  box-shadow: var(--ds-bs-4);
  position: absolute;
  inset: 0px auto auto 0px !important;
  border-radius: 12px 12px 0 0;
  transform: translate(0px, 44px) !important;
  z-index: 10;
`;
const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  max-height: 180px;
  border-top: 1px solid #eef2f5;
  border-radius: 0;
  width: 100%;
  overflow: auto;
  background: var(--ds-c-white);
  margin-top: -1px !important;
  box-shadow: var(--ds-bs-3) !important;
  inset: 0px auto auto 0px !important;
  transform: translate(0px, 44px) !important;
  padding-bottom: 16px;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 5px;
    height: 0px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ds-c-grey-hover);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;
const DropdownItem = styled(Dropdown.Item)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: grid;
  align-items: center;
  padding: 8px 10px;
  text-transform: capitalize;
  min-height: 40px;
  height: fit-content;
  white-space: break-spaces;
  background: var(--ds-c-white);
  margin: 0px;
  padding: 8px 10px;

  a:first-child {
    border-radius: 0;
  }

  &:hover {
    background: ${(props) =>
      props.$isReceiversEmpty ? 'var(--ds-c-white)' : 'var(--ds-c-grey-light)'};
    cursor: ${(props) => (props.$isReceiversEmpty ? 'default' : 'pointer')};
  }

  &:active {
    color: var(--ds-c-grey-dark);
  }

  & p.item-name-country {
    font-weight: 700;
    margin-bottom: 4px;
    text-transform: uppercase;
    display: grid;
    grid-auto-flow: column;
    justify-content: flex-start;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const SearchInput = styled.input`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: none;
  border-radius: 12px 12px 0px 0px;
  background: var(--ds-c-white);
  width: 100%;
  min-height: 44px;
  height: fit-content;
  padding: 10px;

  &:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 20px;
    text-transform: initial;
    color: var(--ds-c-grey-hover);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const AddButton = styled.div`
  position: absolute;
  width: 100%;
  background: var(--ds-c-white);
  border-top: 1px solid #eef2f5;
  border-radius: 0px 0px 12px 12px;
  margin-top: ${(props) =>
    props.$isReceiversEmpty
      ? '40px'
      : props.$receiverLength < 4
      ? `${props.$receiverLength * 44}px`
      : '159.4px'};
  box-shadow: var(--ds-bs-3);
  z-index: 1;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: ${(props) =>
      props.$isReceiversEmpty
        ? '40px'
        : props.$receiverLength < 4
        ? `${props.$receiverLength * 44}px`
        : '159.4px'};
  }
`;

const NoReceiverText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #b0b6bd;
  text-transform: none;

  margin: 0px;
  padding: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ButtonToggle = styled.a`
  font-family: var(--ff-primary);
  line-height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) =>
    props.$isPlaceholder
      ? 'var(--ds-c-grey-neutral)'
      : 'var(--ds-c-grey-dark)'};
  color: ${(props) => props.$isDisabled && 'var(--ds-c-grey-neutral)'};
  text-decoration: none;
  text-transform: uppercase;
  cursor: ${(props) => props.$isDisabled && 'not-allowed'};

  display: flex;
  align-items: center;
  background: transparent;
  border: ${(props) =>
    props.$isShowDropdown && '1px solid var(--ds-c-grey-dark) !important'};
  border: ${(props) => props.$isDisabled && 'none!important'};
  border-radius: 12px;
  padding: 8px 10px;
  padding-right: 32px;
  width: 100%;
  min-height: 44px;
  height: fit-content;
  flex-wrap: wrap;
  gap: 4px;

  &:hover {
    background: transparent;
    border: none;
    color: ${(props) =>
      props.$isPlaceholder ? '#b0b6bd' : 'var(--ds-c-grey-dark-hover)'};
    color: ${(props) => props.$isDisabled && 'var(--ds-c-grey-neutral)'};
  }

  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
    border-radius: 12px;
  }

  &:active {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  & svg {
    position: absolute;
    right: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ds-c-grey-hover);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const FormControlFeedback = styled.span`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default CustomReceiversDropdown;
