import { renderDiscountImportantPopup } from 'components/Discount/func';
import {
  getPayoutItem,
  getReceiveLimitsWithPayoutMethod,
} from 'components/FormAddTransaction/func';
import ButtonCommon from 'components/common/ButtonCommon';
import { checkFormInputValidation } from 'components/common/func';
import {
  ADD_TRANSACTION_AMOUNT_ANOTHER_FIELDS,
  ADD_TRANSACTION_AMOUNT_FIELDS,
  ADD_TRANSACTION_METHOD_PICKUP_NAME,
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME,
  ADD_TRANSACTION_RECEIVER_GET_NAME,
  ADD_TRANSACTION_STEP_REFERENCE,
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  DESKTOP_MIN_WIDTH,
  METHOD_HOME,
  METHOD_PICKUP,
  RESET_ADD_TRANSACTION_INFORMATION_STORE,
  SCREEN_ID_REMITTANCE_STEP_2,
} from 'constants';
import { format } from 'helpers';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import useReceiver from 'hooks/receiver/useReceiver';
import useServiceAlertsUtil from 'hooks/service-alerts/useServiceAlertsUtil';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useEvent from 'hooks/useEvent';
import useLang from 'hooks/useLang';
import useScrollIntoViewDelay from 'hooks/useScrollIntoViewDelay';
import useStore from 'hooks/useStore';
import useValidationToCreateATransactionOnHoliday from 'hooks/useValidationToCreateATransactionOnHoliday';
import { useEffect, useRef, useState } from 'react';
import {
  SET_ADD_TRANSACTION_CURRENT_STEP,
  SET_ADD_TRANSACTION_INFORMATION,
} from 'store/action';
import styled from 'styled-components';
import BodySectionHeader from '../../BodySectionHeader';
import CalculatorForm from '../../CalculatorForm/ver2';
import FormInputAddAnotherReceivingCurrencySwitch from '../../FormInputAddAnotherReceivingCurrencySwitch';
import FormInputAmountAnotherValidation from '../../FormInputAmountAnotherValidation';
import FormInputAmountValidation from '../../FormInputAmountValidation';

const initialFormField = RESET_ADD_TRANSACTION_INFORMATION_STORE.remittance;

const SCREEN_ID = SCREEN_ID_REMITTANCE_STEP_2;

const Remittance = ({
  countryConfig,
  handleOnToggleCancelPopup,
  isDisabled,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { isReceiverOfBusinessType, isReceiverOfIndividualType } =
    useReceiver();

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [isFetching, setFetching] = useState(false);
  const [isChecking, setChecking] = useState(false);
  const [currentRateValue, setCurrentRateValue] = useState(0);
  const [currentRateValueAnother, setCurrentRateValueAnother] = useState(0);
  const [isOpenDiscountImportantPopup, setOpenDiscountImportantPopup] =
    useState(false);
  const [informationChangeBackup, setInformationChangeBackup] = useState(null);
  const [isShowCalculatorForm, setShowCalculatorForm] = useState(false);

  const { addTransactionInformation, addTransactionCurrentStep: currentStep } =
    state;
  const { receiverPayoutMethod, remittance } = addTransactionInformation || {};

  const formInputAmountRef = useRef(null);
  const formInputAmountAnotherRef = useRef(null);

  const {
    isAddAnotherReceivingCurrency,
    currentCurrencySelected,
    currentCurrencySelectedAnother,
    feeAmount,
  } = information || {};

  const isNextDisabled = isFetching;

  const { isPreventNextStep2 } = useServiceAlertsUtil();
  const { isMakePayment } = useFormAddTransactionStore();
  const { isDesktop } = useDetectDevice();

  const {
    feeAmount: feeAmountStore,
    currentCurrencySelected: currentCurrencySelectedStore,
    currentCurrencySelectedAnother: currentCurrencySelectedAnotherStore,
    currentRateStore,
    currentRateAnotherStore,
    rate,
    rateAnother,
    isAddAnotherReceivingCurrency: isAddAnotherReceivingCurrencyStore,
  } = remittance || {};

  const payoutMethod = receiverPayoutMethod[ADD_TRANSACTION_PAYOUT_METHOD_NAME];
  const isPayoutMethodByHome = payoutMethod === METHOD_HOME;
  const isPayoutMethodByPickup = payoutMethod === METHOD_PICKUP;

  const receiveLimits = getReceiveLimitsWithPayoutMethod({
    dmCode: payoutMethod,
    pickupOfficeId: isPayoutMethodByPickup
      ? receiverPayoutMethod[ADD_TRANSACTION_METHOD_PICKUP_NAME]?.pickupOffice
          ?.pickUpOfficeId
      : null,
    isReceiverOfBusinessType,
    isReceiverOfIndividualType,
    receiveLimits: countryConfig?.receiveLimits,
    dmList: countryConfig?.dmList,
  });

  const receiverAmount =
    Number(information[ADD_TRANSACTION_RECEIVER_GET_NAME] || 0) || '';
  const receiverAmountAnother =
    Number(information[ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME] || 0) || '';

  const {
    isOpenHolidayUrgentPopup,
    setOpenHolidayUrgentPopup,
    HolidayUrgentPopup,
  } = useValidationToCreateATransactionOnHoliday({
    isDisabled,
    countryCode: countryConfig?.countryCode,
    payoutMethod,
    payoutItem: getPayoutItem(receiverPayoutMethod, payoutMethod),
    currencyCode:
      countryConfig?.countryCode === 'VNM'
        ? currentCurrencySelected?.currencyCode === 'VND' ||
          currentCurrencySelectedAnother?.currencyCode === 'VND'
          ? 'VND'
          : ''
        : countryConfig?.countryCode === 'PHL'
        ? currentCurrencySelected?.currencyCode === 'PHP' ||
          currentCurrencySelectedAnother?.currencyCode === 'PHP'
          ? 'PHP'
          : ''
        : '',
    receiverAmount:
      countryConfig?.countryCode === 'VNM'
        ? currentCurrencySelected?.currencyCode === 'VND'
          ? receiverAmount
          : currentCurrencySelectedAnother?.currencyCode === 'VND'
          ? receiverAmountAnother
          : 0
        : countryConfig?.countryCode === 'PHL'
        ? currentCurrencySelected?.currencyCode === 'PHP'
          ? receiverAmount
          : currentCurrencySelectedAnother?.currencyCode === 'PHP'
          ? receiverAmountAnother
          : 0
        : 0,
  });

  // scroll to calculator form
  const validRef = useRef(null);
  useScrollIntoViewDelay(validRef, isAddAnotherReceivingCurrency && validRef, [
    isAddAnotherReceivingCurrency,
  ]);

  const checkCurrencySelectedCorresponding = (
    currencySelected,
    receiveLimits
  ) => {
    if (!currencySelected) return false;

    return receiveLimits?.find(
      (rl) => rl.currencyCode === currencySelected.currencyCode
    );
  };

  useEffect(() => {
    let newInformation = { ...remittance };

    const sendAmount = Number(remittance[ADD_TRANSACTION_YOU_SEND_NAME] || 0);
    const getReceiverAmount = Number(
      remittance[ADD_TRANSACTION_RECEIVER_GET_NAME] || 0
    );

    const sendAnotherAmount = Number(
      remittance[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] || 0
    );
    const getReceiverAnotherAmount = Number(
      remittance[ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME] || 0
    );

    newInformation = {
      ...newInformation,
      [ADD_TRANSACTION_YOU_SEND_NAME]: sendAmount,
      [ADD_TRANSACTION_RECEIVER_GET_NAME]: getReceiverAmount,
      currentRateStore,
      currentCurrencySelected:
        currentCurrencySelectedStore || receiveLimits?.[0],
      feeAmount: feeAmountStore,
    };

    const isResetCurrencyAnother =
      !isAddAnotherReceivingCurrencyStore &&
      currentRateAnotherStore &&
      currentCurrencySelectedAnotherStore;

    if (isPayoutMethodByHome && !isResetCurrencyAnother) {
      if (
        checkCurrencySelectedCorresponding(
          currentCurrencySelectedStore,
          receiveLimits
        )
      ) {
        newInformation = {
          ...newInformation,
          currentCurrencySelected:
            currentCurrencySelectedStore || receiveLimits?.[0],
        };
      }

      newInformation = {
        ...newInformation,
        [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: sendAnotherAmount,
        [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: getReceiverAnotherAmount,
        currentCurrencySelectedAnother: currentCurrencySelectedAnotherStore,
        currentRateAnotherStore,
        isAddAnotherReceivingCurrency: isAddAnotherReceivingCurrencyStore,
      };
    } else {
      newInformation = {
        ...newInformation,
        [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: 0,
        [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: 0,
        currentCurrencySelectedAnother: null,
        currentRateAnotherStore: null,
        isAddAnotherReceivingCurrency: false,
      };
    }

    setCurrentRateValue(
      isDisabled ? rate?.rateValue : currentRateStore?.rateValue
    );

    if (isAddAnotherReceivingCurrencyStore) {
      setCurrentRateValueAnother(
        isDisabled ? rateAnother?.rateValue : currentRateAnotherStore?.rateValue
      );
    }

    setInformation(newInformation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const sendAmount =
      Number(information[ADD_TRANSACTION_YOU_SEND_NAME] || 0) || '';
    const receiverAmount =
      Number(information[ADD_TRANSACTION_RECEIVER_GET_NAME] || 0) || '';

    document.getElementsByName(ADD_TRANSACTION_YOU_SEND_NAME)[0].value =
      sendAmount ? format.toAmountStr(sendAmount) : sendAmount;
    document.getElementsByName(ADD_TRANSACTION_RECEIVER_GET_NAME)[0].value =
      receiverAmount ? format.toAmountStr(receiverAmount) : receiverAmount;

    if (isAddAnotherReceivingCurrency) {
      const sendAmountAnother =
        Number(information[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] || 0) || '';
      const receiverAmountAnother =
        Number(information[ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME] || 0) ||
        '';

      document.getElementsByName(
        ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME
      )[0].value = sendAmountAnother
        ? format.toAmountStr(sendAmountAnother)
        : sendAmountAnother;
      document.getElementsByName(
        ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME
      )[0].value = receiverAmountAnother
        ? format.toAmountStr(receiverAmountAnother)
        : receiverAmountAnother;
    }
  }, [information, isAddAnotherReceivingCurrency]);

  const handleOnClickDiscountImportantPopup = () => {
    if (informationChangeBackup) {
      setInformation(informationChangeBackup);

      setInformationChangeBackup(null);

      setValidation(initialFormField);
    }
  };

  const handleOnCloseDiscountImportantPopup = () => {
    setInformationChangeBackup(null);

    setFetching(false);
  };

  const handleNextStep = () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    newValidation =
      formInputAmountRef.current.handleGetReceiverAmountValidation(
        newInformation,
        newValidation
      );

    if (isAddAnotherReceivingCurrency) {
      newValidation =
        formInputAmountAnotherRef.current.handleGetReceiverAmountValidation(
          newInformation,
          newValidation
        );
    }

    setValidation(newValidation);

    delete newInformation['remittanceId'];
    delete newValidation['remittanceId'];

    delete newInformation['remittanceAnotherId'];
    delete newValidation['remittanceAnotherId'];

    delete newInformation['rate'];
    delete newValidation['rate'];

    delete newInformation['rateAnother'];
    delete newValidation['rateAnother'];

    delete newInformation['isAddAnotherReceivingCurrency'];
    delete newValidation['isAddAnotherReceivingCurrency'];

    delete newInformation['currentCurrencySelected'];
    delete newValidation['currentCurrencySelected'];

    delete newInformation['currentCurrencySelectedAnother'];
    delete newValidation['currentCurrencySelectedAnother'];

    delete newInformation['currentRateStore'];
    delete newValidation['currentRateStore'];

    delete newInformation['currentRateAnotherStore'];
    delete newValidation['currentRateAnotherStore'];

    delete newInformation['feeAmount'];
    delete newValidation['feeAmount'];

    if (!isAddAnotherReceivingCurrency) {
      delete newInformation[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME];
      delete newValidation[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME];
      delete newInformation[ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME];
      delete newValidation[ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME];
    }

    const isAllowSubmit =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowSubmit) {
      if (isPreventNextStep2) {
        setChecking(true);
        return;
      }

      if (!isAddAnotherReceivingCurrency) {
        dispatch({
          type: SET_ADD_TRANSACTION_INFORMATION,
          payload: {
            ...addTransactionInformation,
            [ADD_TRANSACTION_STEP_REFERENCE[currentStep]]: {
              ...information,
              isAddAnotherReceivingCurrency,
              currentCurrencySelected,
              currentCurrencySelectedAnother: null,
              currentRateStore,
              currentRateAnotherStore: null,
              feeAmount,
            },
          },
        });
      } else {
        dispatch({
          type: SET_ADD_TRANSACTION_INFORMATION,
          payload: {
            ...addTransactionInformation,
            [ADD_TRANSACTION_STEP_REFERENCE[currentStep]]: {
              ...information,
              isAddAnotherReceivingCurrency,
              currentCurrencySelected,
              currentCurrencySelectedAnother,
              currentRateStore,
              currentRateAnotherStore,
              feeAmount,
            },
          },
        });
      }

      dispatch({
        type: SET_ADD_TRANSACTION_CURRENT_STEP,
        payload: currentStep + 1,
      });
    } else {
      if (
        !newValidation[ADD_TRANSACTION_YOU_SEND_NAME] &&
        !newValidation[ADD_TRANSACTION_RECEIVER_GET_NAME] &&
        !newValidation[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] &&
        !newValidation[ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]
      ) {
        ADD_TRANSACTION_AMOUNT_FIELDS.forEach((field) => {
          newValidation = checkFormInputValidation(
            information[field.name],
            field,
            newValidation
          );
        });

        if (isAddAnotherReceivingCurrency) {
          ADD_TRANSACTION_AMOUNT_ANOTHER_FIELDS.forEach((field) => {
            newValidation = checkFormInputValidation(
              information[field.name],
              field,
              newValidation
            );
          });
        }

        setValidation(newValidation);
      }
    }
  };

  const handleBackStep = () => {
    dispatch({
      type: SET_ADD_TRANSACTION_CURRENT_STEP,
      payload: currentStep - 1,
    });
  };

  const renderCalculatorFormMobile = () => {
    if (isDesktop) return null;
    if (!isShowCalculatorForm) return null;
    return (
      <CalculatorForm
        ref={validRef}
        information={information}
        countryConfig={countryConfig}
        setFetching={setFetching}
        isCalculatorFormAccordion={true}
      />
    );
  };
  const renderCalculatorFormDesktop = () => {
    if (!isDesktop) return null;
    if (!isShowCalculatorForm) return null;
    return (
      <CalculatorForm
        information={information}
        countryConfig={countryConfig}
        setFetching={setFetching}
      />
    );
  };
  useEffect(() => {
    if (isShowCalculatorForm) return;
  }, [isShowCalculatorForm]);

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [],
    modalStyles: {},
    modalOptions: {},
  });
  useEffect(() => {
    if (!isShowEvent) return;
    showEventModal();
  }, [isShowEvent]);

  return (
    <>
      {isOpenDiscountImportantPopup &&
        renderDiscountImportantPopup({
          t,
          isOpen: isOpenDiscountImportantPopup,
          setOpen: setOpenDiscountImportantPopup,
          onCloseExt: handleOnCloseDiscountImportantPopup,
          onClickExt: handleOnClickDiscountImportantPopup,
        })}

      {isOpenHolidayUrgentPopup && (
        <HolidayUrgentPopup
          isOpen={isOpenHolidayUrgentPopup}
          setOpen={setOpenHolidayUrgentPopup}
        />
      )}

      <BodySectionHeader
        titleBodySection={
          isMakePayment
            ? t('add_transaction_title_make_payment')
            : t('add_transaction_title_new_transaction')
        }
        titleSection={t('add_transaction_title_remittance')}
        CalculatorComponent={renderCalculatorFormDesktop()}
        onToggleCancelPopup={handleOnToggleCancelPopup}
      />
      <RemittanceStyled>
        {ADD_TRANSACTION_AMOUNT_FIELDS.map((field) => (
          <FormInputAmountValidation
            key={field.name}
            ref={formInputAmountRef}
            field={field}
            countryConfig={countryConfig}
            receiveLimits={receiveLimits}
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            currentRateValue={currentRateValue}
            setCurrentRateValue={setCurrentRateValue}
            currentCurrencySelected={currentCurrencySelected}
            isDisabled={isDisabled}
            isFetching={isFetching}
            setFetching={setFetching}
            isChecking={isChecking}
            setChecking={setChecking}
            setOpenDiscountImportantPopup={setOpenDiscountImportantPopup}
            isShowCalculatorForm={isShowCalculatorForm}
            setShowCalculatorForm={setShowCalculatorForm}
          />
        ))}
        {isPayoutMethodByHome && (
          <>
            <FormInputAddAnotherReceivingCurrencySwitch
              countryConfig={countryConfig}
              information={information}
              setInformation={setInformation}
              validation={validation}
              setValidation={setValidation}
              receiveLimits={receiveLimits}
              isFetching={isFetching}
              setFetching={setFetching}
              isChecking={isChecking}
              setChecking={setChecking}
              isDisabled={isDisabled}
              setInformationChangeBackup={setInformationChangeBackup}
              setOpenDiscountImportantPopup={setOpenDiscountImportantPopup}
            />
          </>
        )}
        {isAddAnotherReceivingCurrency && (
          <>
            {ADD_TRANSACTION_AMOUNT_ANOTHER_FIELDS.map((field) => (
              <FormInputAmountAnotherValidation
                key={field.name}
                ref={formInputAmountAnotherRef}
                field={field}
                countryConfig={countryConfig}
                receiveLimits={receiveLimits}
                information={information}
                setInformation={setInformation}
                validation={validation}
                setValidation={setValidation}
                currentRateValue={currentRateValueAnother}
                setCurrentRateValue={setCurrentRateValueAnother}
                currentCurrencySelected={currentCurrencySelectedAnother}
                isDisabled={isDisabled}
                isFetching={isFetching}
                setFetching={setFetching}
                setOpenDiscountImportantPopup={setOpenDiscountImportantPopup}
              />
            ))}
          </>
        )}
        <NextWrap>
          {renderCalculatorFormMobile()}
          <ActionWrapper>
            <ButtonCommonWrap>
              <ButtonCommon
                value={t('button_back')}
                onClick={handleBackStep}
                styles={{
                  margin: '0px',
                  width: !isDesktop && '100%',
                }}
                color="var(--ds-c-blue)"
                background="var(--ds-c-white)"
                borderNameVariable={isDesktop && '--border-filter'}
                isFill={false}
              />
            </ButtonCommonWrap>
            <ButtonCommon
              value={t('button_next')}
              color="var(--c-primary)"
              background="var(--bg-primary)"
              isFill={true}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              onClick={handleNextStep}
              isDisabled={isNextDisabled}
              isUseKeyDown={!isNextDisabled}
            />
          </ActionWrapper>
        </NextWrap>
      </RemittanceStyled>
    </>
  );
};

const RemittanceStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -8px;
  padding-inline: 0px;
  padding-bottom: 140px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 437px;
    margin-top: 0px;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

export const NextWrap = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: fit-content;
  margin-inline: -16px;
  padding-inline: 0px;
  background: transparent;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    width: 437px;
    height: fit-content;
    border-top: unset;
  }
`;
export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  width: 100%;
  background: var(--ds-c-white);
  border-top: 1px solid var(--ds-bg-2);
  padding-inline: 16px;
  margin: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 100%;
    height: 44px;
    border-top: unset;
    padding-inline: unset;
    margin: 0px;
  }
`;
export const ButtonCommonWrap = styled.div`
  width: 50%;
`;

export default Remittance;
