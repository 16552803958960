import {
  REGISTER_EMAIL_FIELD,
  REGISTER_EMAIL_NAME,
  REGISTER_REEMAIL_FIELD,
  REGISTER_REEMAIL_NAME,
} from 'constants';

const handleEmailValueValidation = (value, name, information, validation) => {
  if (value && name === REGISTER_EMAIL_NAME) {
    const isValid = Boolean(REGISTER_EMAIL_FIELD.checkValidFn(value));

    if (!isValid) {
      validation = {
        ...validation,
        [name]: REGISTER_EMAIL_FIELD.msgInvalid,
      };
    }

    if (isValid) {
      validation = {
        ...validation,
        [name]: '',
      };
    }

    if (information[REGISTER_REEMAIL_NAME]) {
      if (value === information[REGISTER_REEMAIL_NAME]) {
        validation = {
          ...validation,
          [REGISTER_REEMAIL_NAME]: '',
        };
      }

      if (value !== information[REGISTER_REEMAIL_NAME]) {
        validation = {
          ...validation,
          [REGISTER_REEMAIL_NAME]: REGISTER_REEMAIL_FIELD.msgNotMatch,
        };
      }
    }
  }

  return validation;
};

const handleReemailValueValidation = (value, name, information, validation) => {
  if (value && name === REGISTER_REEMAIL_NAME) {
    const isValid = Boolean(REGISTER_REEMAIL_FIELD.checkValidFn(value));

    if (!isValid) {
      validation = {
        ...validation,
        [name]: REGISTER_REEMAIL_FIELD.msgInvalid,
      };
    }

    if (isValid) {
      validation = {
        ...validation,
        [name]: '',
      };
    }

    if (information[REGISTER_EMAIL_NAME]) {
      if (value !== information[REGISTER_EMAIL_NAME]) {
        validation = {
          ...validation,
          [name]: REGISTER_REEMAIL_FIELD.msgNotMatch,
        };
      }
    }
  }

  return validation;
};

const handleFieldValidation = (value, name, information, validation) => {
  if (value) {
    validation = handleEmailValueValidation(
      value,
      name,
      information,
      validation
    );

    validation = handleReemailValueValidation(
      value,
      name,
      information,
      validation
    );
  }

  return validation;
};

const handleFieldEmptyValidation = (value, name, validation, msgRequired) => {
  if (!value) {
    validation = {
      ...validation,
      [name]: msgRequired,
    };
  }

  return validation;
};

export const checkFormInputValidation = (
  value,
  field,
  information,
  validation
) => {
  const { name, msgRequired } = field;

  validation = handleFieldEmptyValidation(value, name, validation, msgRequired);

  validation = handleFieldValidation(value, name, information, validation);

  return validation;
};
