import { useEffect } from 'react';

const useClickLabelInputSwitch = (name, cb, deps) => {
  useEffect(() => {
    const labelEle = document.getElementsByClassName(name)[0];
    if (labelEle) {
      labelEle.addEventListener('click', () => {
        cb();
      });
    }

    return () => {
      labelEle.removeEventListener('click', () => {
        cb();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useClickLabelInputSwitch;
