import TransactionStatus from 'components/Transactions/components/TransactionStatus';
import {
  fetchTransactionInformation,
  fetchTransactionsFiltered,
  getBodyPayloadFiltered,
} from 'components/Transactions/func';
import ButtonLinkCommon from 'components/common/ButtonLinkCommon';
import SortIcon from 'components/common/Icons/SortIcon';
import {
  PAGE_DEFAULT,
  RECENT_TRANSACTION_TABLE_HEADING,
  SORT_ASC,
  SORT_DESC,
  TRANSACTION_TABLE_HEADING,
  TRANSACTION_TABLE_HEADING_TRANSLATE,
  TRANSACTION_TABLE_HEADING_UNABLE_SORT,
  TRANSACTION_TABLE_SORT_ORDER_INITIAL,
} from 'constants';
import {
  DASHBOARD_PREFIX,
  TRANSACTIONS_PREFIX,
  TRANSACTION_ADD_PREFIX,
} from 'constants/router';
import { format, sort } from 'helpers';
import Cookies from 'helpers/cookies';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useLocation, useNavigate } from 'react-router-dom';
import { SET_SHOW_LOADING, SET_TRANSACTION_DETAILS } from 'store/action';
import styled from 'styled-components';
import { arrayUtils, objectUtils } from 'utils';
import NoResult from '../NoResult';
import NoTransaction from '../NoTransaction';
import Pagination from '../Pagination';

import FormTransactionDetails from 'components/Transactions/components/FormTransactionDetails';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import { isNull } from 'lodash';

const TransactionTableSort = ({
  currentPage,
  totalPages,
  transactions,
  setTransactions,
  searchValue,
  currentSearchBy,
  applyFiltered,
  setTotalPages,
  setCurrentPage,
  sortOrder,
  setSortOrder,
  totalElements,
  setTotalElements,
  isFetching,
  setFetching,
  isSearched,
  setSearched,
}) => {
  const { state, dispatch } = useStore();
  const { transactionDetails } = state;
  const { t } = useLang();
  const { token } = useAuth();
  const { pathname } = useLocation();
  const isDashboardPage = pathname.includes(DASHBOARD_PREFIX);
  const navigate = useNavigate();

  const [information, setInformation] = useState(null);
  const [current, setCurrent] = useState('');

  const isAbleSort = (heading) =>
    !TRANSACTION_TABLE_HEADING_UNABLE_SORT.includes(heading);

  const isShowNoResult =
    !isFetching &&
    isNull(transactions) &&
    (!objectUtils.isObjectEmpty(applyFiltered) || searchValue);
  const isShowNoTransaction =
    (Array.isArray(transactions) && arrayUtils.isArrayEmpty(transactions)) ||
    (isDashboardPage && isNull(transactions));
  const isShowBlank = isNull(transactions);

  useEffect(() => {
    if (transactionDetails) {
      setInformation(transactionDetails);
    }
  }, [transactionDetails]);

  const handleRecentTransactionsSort = (transactions, order, property) => {
    order = order === SORT_ASC ? SORT_DESC : SORT_ASC;

    const recentTransactionsSortByReference = {
      receiverFullname: () => {
        transactions = sort.arrayObjectByString(transactions, order, property);
      },
      countryName: () => {
        transactions = sort.arrayObjectByString(transactions, order, property);
      },
      createDateStr: () => {
        transactions = sort.arrayObjectByDate(transactions, order, property);
      },
      amount: () => {
        transactions = sort.arrayObjectByNumber(transactions, order, property);
      },
    };

    recentTransactionsSortByReference[property]();

    return [transactions, order];
  };

  const handleTransactionsSort = (order, property) => {
    order = order === SORT_ASC ? SORT_DESC : SORT_ASC;

    const sortPayload = {
      sortOrder: order,
      sortField: property,
    };
    let bodyPayload = getBodyPayloadFiltered(applyFiltered);

    bodyPayload = {
      ...bodyPayload,
      [currentSearchBy]: searchValue,
    };

    setTransactions(null);
    fetchTransactionsFiltered({
      token,
      body: bodyPayload,
      sort: sortPayload,
      page: PAGE_DEFAULT,
      setTransactions,
      setCurrentPage,
      setTotalPages,
      setTotalElements,
    });

    return order;
  };

  const handleSortByHeading = (heading, order) => {
    let newTransactions = [];

    if (isDashboardPage) {
      [newTransactions, order] = handleRecentTransactionsSort(
        transactions,
        order,
        RECENT_TRANSACTION_TABLE_HEADING[heading]
      );

      setTransactions(newTransactions);
    } else {
      order = handleTransactionsSort(order, TRANSACTION_TABLE_HEADING[heading]);
    }

    const newSortOrder = {
      ...TRANSACTION_TABLE_SORT_ORDER_INITIAL,
      [heading]: order,
    };
    setSortOrder(newSortOrder);
  };

  const handleOnOpenTransactionDetailsPopup = async (transaction) => {
    const { refNumber, status } = transaction;

    dispatch({ type: SET_SHOW_LOADING, payload: true });

    const newInformation = await fetchTransactionInformation(
      token,
      refNumber,
      status
    );

    setInformation(newInformation);

    dispatch({ type: SET_SHOW_LOADING, payload: false });
  };

  const handleOnCloseTransactionDetailsPopup = () => {
    setInformation(null);

    dispatch({ type: SET_TRANSACTION_DETAILS, payload: null });
  };

  const handleOnMakePayment = () => {
    navigate(`${TRANSACTION_ADD_PREFIX}?refNumber=${information?.refNumber}`);

    Cookies.set(MAKE_PAYMENT_KEY, true);
  };

  if (isShowNoResult) {
    return <NoResult />;
  }
  if (isShowNoTransaction) {
    return <NoTransaction />;
  }
  if (isShowBlank) {
    return null;
  }
  return (
    <>
      {Boolean(information) && (
        <FormTransactionDetails
          isOpen={Boolean(information)}
          information={information}
          setInformation={setInformation}
          setTransactions={setTransactions}
          applyFiltered={applyFiltered}
          searchValue={searchValue}
          currentSearchBy={currentSearchBy}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setTotalElements={setTotalElements}
          setTotalPages={setTotalPages}
          onClose={handleOnCloseTransactionDetailsPopup}
          onClick={handleOnMakePayment}
        />
      )}
      <TransactionTableSortStyled>
        <Table responsive>
          <thead>
            <THeadTR>
              {(isDashboardPage
                ? Object.keys(RECENT_TRANSACTION_TABLE_HEADING)
                : Object.keys(TRANSACTION_TABLE_HEADING)
              )?.map((heading, index) => (
                <THeadTH
                  key={index}
                  $isAbleSort={isAbleSort(heading)}
                  onClick={
                    isAbleSort(heading)
                      ? () => handleSortByHeading(heading, sortOrder[heading])
                      : () => {}
                  }
                >
                  {t(TRANSACTION_TABLE_HEADING_TRANSLATE[heading])}
                  {isAbleSort(heading) && (
                    <SortIcon order={sortOrder[heading]} />
                  )}
                </THeadTH>
              ))}
            </THeadTR>
          </thead>
          <tbody>
            {transactions?.map((tran) => (
              <TBodyTR
                key={tran.tranId}
                isDashboardPage={isDashboardPage}
                onClick={() => handleOnOpenTransactionDetailsPopup(tran)}
              >
                <TBodyTD className="refNumber">{tran.refNumber}</TBodyTD>
                <TBodyTD>
                  <span>{tran.receiverFullname}</span>
                </TBodyTD>
                <TBodyTD>
                  <TransactionStatus transaction={tran} />
                </TBodyTD>
                <TBodyTD className="createDateStr">
                  {tran.createDateStr}
                </TBodyTD>
                <TBodyTD className="amount">{`AUD ${format.toAmountStr(
                  tran.amount
                )}`}</TBodyTD>
              </TBodyTR>
            ))}
          </tbody>
        </Table>
        {!isDashboardPage && Boolean(transactions.length) && (
          <Pagination
            totalPages={totalPages}
            applyFiltered={applyFiltered}
            searchValue={searchValue}
            currentSearchBy={currentSearchBy}
            sortOrder={sortOrder}
            totalElements={totalElements}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            transactions={transactions}
            setTransactions={setTransactions}
            current={current}
            setCurrent={setCurrent}
            setFetching={setFetching}
            isSearched={isSearched}
          />
        )}
        {isDashboardPage && (
          <ButtonLinkCommon
            href={TRANSACTIONS_PREFIX}
            value={t('transactions_view_all_transactions')}
            color="var(--ds-c-blue-hyperlink-default)"
            background="var(--ds-c-white)"
            styles={{
              marginInline: 'auto',
              height: 'fit-content',
              fontWeight: 'normal',
            }}
          />
        )}
      </TransactionTableSortStyled>
    </>
  );
};

const TransactionTableSortStyled = styled.div`
  width: 100%;
  height: 100%;

  & .table-responsive {
    width: 100%;
    height: 100%;

    & table {
      margin: 0;
    }
  }
`;

const THeadTR = styled.tr`
  display: flex;
  justify-content: space-between;
`;
const THeadTH = styled.th`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--c-sub-info);

  padding-inline: 16px !important;
  box-shadow: none;
  border: none;
  cursor: ${(props) => (props.$isAbleSort ? 'pointer' : 'default')};
  user-select: none;

  &:nth-child(1) {
    width: 10%;
  }
  &:nth-child(2) {
    width: 25%;
  }
  &:nth-child(3) {
    width: 12%;
    display: flex;
    justify-content: center;
  }
  &:nth-child(4) {
    width: 18%;
    display: flex;
    justify-content: center;
  }
  &:nth-child(5) {
    width: 16%;
    display: flex;
    justify-content: end;
  }
  &:last-child {
    width: 19%;
    display: flex;
    justify-content: end;
    padding-right: 0px !important;
  }
`;

const TBodyTR = styled.tr`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 16px;
  margin-inline: 3px;
  cursor: pointer;

  &:last-child {
    margin-bottom: ${(props) => !props.isDashboardPage && '4px'};
  }
`;
const TBodyTD = styled.td`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  padding-inline: 16px !important;
  min-height: 50px;
  height: fit-content;
  border-bottom: none;
  border-radius: 12px;

  &:nth-child(1) {
    width: 10%;
  }
  &:nth-child(2) {
    width: 25%;
    span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:nth-child(3) {
    width: 12%;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  &:nth-child(4) {
    width: 18%;
    display: flex;
    justify-content: center;
  }
  &:nth-child(5) {
    width: 16%;
    display: flex;
    justify-content: end;
  }
  &:last-child {
    width: 19%;
  }

  &.refNumber {
    font-family: var(--ff-secondary);
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: var(--ds-c-blue-hyperlink-default);
  }

  &.createDateStr {
    color: var(--ds-c-grey-neutral);
  }

  &.amount {
    font-weight: 700;
    color: var(--ds-c-blue);
    display: flex;
    justify-content: end;
    text-align: right;
  }
`;

export default TransactionTableSort;
