import { isEmpty } from 'lodash';
import { ServiceAlertsModel } from '../../model/ServiceAlertsModel';

export const transformServiceAlertsData = (data: ServiceAlertsModel | null) => {
  if (isEmpty(data)) {
    return null;
  }

  const { message = null, name = '', type = '', min = 0, max = 0 } = data || {};

  const serviceAlertsTransform: ServiceAlertsModel = {
    ...(!isEmpty(message) && { message }),
    ...(name && { name }),
    ...(type && { type }),
    ...(min && { min }),
    ...(max && { max }),
  };

  return serviceAlertsTransform;
};
