import RadioCheckedIcon from 'assets/icons/radio-checked-icon.svg';
import RadioboxHHMTIcon from 'assets/icons/radiobox-hhmt-icon.svg';
import RadioboxRemoxIcon from 'assets/icons/radiobox-remox-icon.svg';
import { ButtonFormCheck } from 'components/FormEditReceiver/components/FormInputSelectTypeOfReceiverRadio';
import { DESKTOP_MIN_WIDTH } from 'constants';
import {
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SWITCH,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME,
} from 'constants/fx';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { domUtils } from 'utils';

const FormInputAddressSwitch = ({
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();
  const { isEnterAddressManuallySelected } = information;
  const { isHHMTTheme } = useTheme();

  const isFinder = !isEnterAddressManuallySelected;
  const isManual = isEnterAddressManuallySelected;

  const isDisabled = false;

  const handleOnChange = (value) => {
    setInformation({
      ...information,
      isEnterAddressManuallySelected: value,
    });

    setValidation({
      ...validation,
      [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME]: '',
      [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME]: '',
      [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME]: '',
      [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME]: '',
      [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME]: '',
      [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME]: '',
      [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME]: '',
      [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME]: '',
    });
  };

  const renderFormCheckRadio1 = () => {
    const formCheckRadioClassName1 =
      FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SWITCH + '1';

    return (
      <>
        <FormCheck
          className={`${formCheckRadioClassName1} ${isFinder && 'active'}`}
          inline
          type="radio"
          label={t('fx_confirm_personal_address_option_1')}
          name={FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SWITCH}
          checked={isFinder}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
          $isDisabled={isDisabled}
          readOnly
        />
        <ButtonFormCheck
          $width={
            domUtils.getOffsetWidthByClassName(formCheckRadioClassName1) + 44
          }
          onClick={() => (!isDisabled ? handleOnChange(false) : {})}
        >
          {t('fx_confirm_personal_address_option_1')}
        </ButtonFormCheck>
      </>
    );
  };

  const renderFormCheckRadio2 = () => {
    const formCheckRadioClassName2 =
      FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SWITCH + '2';

    return (
      <>
        <FormCheck
          className={`${formCheckRadioClassName2} ${isManual && 'active'}`}
          inline
          type="radio"
          label={t('fx_confirm_personal_address_option_2')}
          name={FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SWITCH}
          checked={isManual}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
          $isDisabled={isDisabled}
          style={{ marginRight: 0 }}
          readOnly
        />
        <ButtonFormCheck
          $width={domUtils.getOffsetWidthByClassName(formCheckRadioClassName2)}
          onClick={() => (!isDisabled ? handleOnChange(true) : {})}
        >
          {t('fx_confirm_personal_address_option_2')}
        </ButtonFormCheck>
      </>
    );
  };

  return (
    <FormGroupStyled>
      {renderFormCheckRadio1()}
      {renderFormCheckRadio2()}
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 12px;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    display: table;
    margin-bottom: 12px;
    clear: both;
  }
`;

const FormCheck = styled(Form.Check)`
  margin: 0;
  margin-right: 0px;

  label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    margin-left: 0px;
    cursor: ${(props) =>
      props.$isDisabled ? 'not-allowed !important' : 'pointer !important'};
    user-select: none;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      margin-right: 44px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: ${(props) => `url(${props.$checkboxSrc}) !important`};
    background-position: center !important;
    width: 16px;
    height: 16px;
    cursor: ${(props) =>
      props.$isDisabled ? 'not-allowed !important' : 'pointer !important'};

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }

    &:checked[type='radio'] {
      background-image: url(${RadioCheckedIcon}) !important;
      background-position: center !important;
    }
  }

  &.active {
    label {
      font-weight: 500;
    }
  }
`;

export default FormInputAddressSwitch;
