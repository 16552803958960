import useLang from 'hooks/useLang';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  DESKTOP_MIN_WIDTH,
  FORGOT_PASSWORD_PREFIX,
  LOGIN_PREFIX,
} from '../../../../../constants';
import ButtonLinkCommon from '../../../../common/ButtonLinkCommon';

const AccountExistedInform = () => {
  const { t } = useLang();

  return (
    <AccountLimitedInformStyled>
      <Paragraph>{t('registration_inform_login_required')}</Paragraph>
      <ForgotPasswordLogin>
        <LinkStyled to={FORGOT_PASSWORD_PREFIX}>
          {t('button_forgot_password')}
        </LinkStyled>
        <ButtonLinkCommon
          href={LOGIN_PREFIX}
          value={t('button_login')}
          styles={{
            margin: '0px',
            width: '63px',
            border: '1px solid var(--c-primary)',
          }}
          color="var(--c-primary)"
          background="var(--ds-c-white)"
          isFill={false}
        />
      </ForgotPasswordLogin>
    </AccountLimitedInformStyled>
  );
};

const AccountLimitedInformStyled = styled.div`
  margin-top: 32px;
  padding: 10px 16px;
  width: 100%;
  height: fit-content;
  background: #eef2f5;
  border-radius: 12px;
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const LinkStyled = styled(Link)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;

  color: var(--ds-c-blue);
  display: block;
  margin-right: 38.5px;

  &:hover {
    color: var(--ds-c-blue-hover) !important;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ForgotPasswordLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
`;

export default AccountExistedInform;
