/* eslint-disable no-unused-vars */
import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import AlertPopup from 'components/common/PortalModal/popups/AlertPopup';
import useLang from 'hooks/useLang';
import usePortalModal from 'hooks/usePortalModal';
import { functionUtils } from 'utils';

const FxCalculatorModalTimeoutModal = ({ isShow, action }) => {
  const { t } = useLang();
  const { closePortalModalName } = usePortalModal();

  const handleOnClick = () => {
    if (functionUtils.isFunction(action)) {
      action();
    }
    closePortalModalName();
  };

  return (
    <AlertPopup
      isOpen={isShow}
      imageSrc={NoticeIcon}
      imagesStyles={{
        width: '124px',
        height: '100px',
      }}
      text={''}
      content={t('popup_fx_calculator_modal_timeout_modal_desc')}
      buttonLabel2={t('button_close')}
      onClick={handleOnClick}
      isBackdropOverlap={true}
      zIndexBackdropOverlap={1055}
    />
  );
};

export default FxCalculatorModalTimeoutModal;
