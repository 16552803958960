import { api } from 'api';
import { useEffect } from 'react';
import { useState } from 'react';
import useStore from './useStore';

const useDeliveryMethods = (countryCode) => {
  const { state } = useStore();
  const { currentReceiver } = state;

  const [deliveryMethods, setDeliveryMethods] = useState(null);

  const fetchDeliveryMethods = async (countryCode) => {
    try {
      const { data } = await api.getDeliveryMethods(countryCode);

      setDeliveryMethods(data);
    } catch (error) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    if (currentReceiver) {
      fetchDeliveryMethods(currentReceiver?.country?.code);

      return;
    }

    if (countryCode) {
      fetchDeliveryMethods(countryCode);
    }
  }, [countryCode, currentReceiver]);

  return deliveryMethods?.map((dm) => dm.code);
};

export default useDeliveryMethods;
