import ArrowIcon from 'assets/icons/arrow-down-icon.svg';
import { PROFILE_TABS } from 'constants';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import BuildVersionSection from '../../BuildVersionSection';
import NotificationsMobile from '../../Notifications/mobile';
import PersonalDetailsMobile from '../../PersonalDetails/mobile';
import SecurityMobile from '../../Security/mobile';

const ProfileBodyMobile = () => {
  const { t } = useLang();
  const { state } = useStore();
  let { notifications } = state;
  const query = useQuery();
  const tab = query.get('tab');

  const [currentTab, setCurrentTab] = useState(null);

  useEffect(() => {
    if (tab) {
      setCurrentTab(parseInt(tab));
    }
  }, [tab]);

  const tabContentReference = {
    0: (
      <PersonalDetailsMobile
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
    ),
    1: <SecurityMobile currentTab={currentTab} setCurrentTab={setCurrentTab} />,
    2: (
      <NotificationsMobile
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
    ),
  };

  const handleTabOnClick = (index) => {
    setCurrentTab(index);
  };

  return (
    <ProfileBodyMobileStyled>
      {PROFILE_TABS.map((tab, index) => (
        <ButtonTab key={tab.label} onClick={() => handleTabOnClick(index)}>
          <TabImage src={tab.iconOutline} width={24} height={24} />
          {index !== 2 ? (
            <TabLabel>{t(tab.label)}</TabLabel>
          ) : (
            <TabLabel>
              {t(tab.label)}
              {Boolean(notifications) &&
                Boolean(notifications?.filter(({ read }) => !read).length) && (
                  <HaveNotify>
                    {notifications.filter(({ read }) => !read).length}
                  </HaveNotify>
                )}
            </TabLabel>
          )}
          <ArrowImage src={ArrowIcon} width={24} height={24} />
        </ButtonTab>
      ))}
      {tabContentReference[currentTab]}
      <BuildVersionSection />
    </ProfileBodyMobileStyled>
  );
};

const ProfileBodyMobileStyled = styled.div`
  margin-top: 24px;
  margin-bottom: 74px;
`;

const ButtonTab = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding-inline: 10px;
  position: relative;
  margin-bottom: 12px;
`;
const TabLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  align-items: center;
  margin: 0;
`;

const TabImage = styled.img`
  margin-right: 8px;
`;

const ArrowImage = styled.img`
  padding: 6px;
  transform: rotate(270deg);
  position: absolute;
  right: 10px;
  object-fit: contain;
`;

const HaveNotify = styled.span`
  font-family: var(--ff-primary);
  line-height: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: var(--ds-c-white);
  text-decoration: none;
  text-align: center;
  padding-top: 2.5px;
  margin-left: 4px;

  display: inline-block;
  width: 16px;
  height: 16px;
  background: #e72631;
  border-radius: 100%;
  user-select: none;
`;

export default ProfileBodyMobile;
