import { backgroundReference, colorReference } from 'constants';
import React from 'react';
import styled from 'styled-components';

const AddTxnStep1Icon = ({
  status = 'disable' || 'in-progress' || 'completed',
}) => {
  return (
    <SvgStyled $backgroundColor={backgroundReference[status]}>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8908_12971)">
          <rect
            x="0.833252"
            y="0.833252"
            width="6.66667"
            height="6.66667"
            rx={2}
            fill={colorReference[status]}
          />
          <rect
            x="0.833252"
            y="9.16675"
            width="6.66667"
            height="6.66667"
            rx={2}
            fill={colorReference[status]}
          />
          <rect
            x="9.16675"
            y="0.833252"
            width="6.66667"
            height="6.66667"
            rx={2}
            fill={colorReference[status]}
          />
          <path
            d="M12.8341 18.8124L10.0285 10.691C9.93168 10.4107 10.0894 10.1117 10.3804 10.024C10.51 9.98497 10.6486 9.99399 10.7751 10.0478L18.8464 13.5047C18.985 13.5645 19.0918 13.6775 19.1395 13.8154C19.2318 14.0826 19.0816 14.3673 18.8043 14.4508L16.1195 15.2595L18.2688 17.3615C18.5679 17.6535 18.5677 18.1179 18.2683 18.3991C17.9689 18.6803 17.484 18.6716 17.1848 18.3796L14.9841 16.2302L13.8213 18.8656C13.7622 18.9995 13.6439 19.1004 13.5007 19.1436C13.2256 19.2264 12.9264 19.0796 12.8341 18.8124Z"
            fill={colorReference[status]}
          />
        </g>
        <defs>
          <clipPath id="clip0_8908_12971">
            <rect width={20} height={20} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgStyled>
  );
};

const SvgStyled = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

export default AddTxnStep1Icon;
