import { checkFormInputValidation } from 'components/common/func';
import {
  LOGIN_EMAIL_NAME,
  LOGIN_FIELDS,
  LOGIN_PASSWORD_NAME,
  LOGIN_UNAUTHORIZED_INFORM,
  RESET_LOGIN_INFORMATION,
} from 'constants';
import { FORGOT_PASSWORD_PREFIX, REGISTER_PREFIX } from 'constants/router';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import useAuth from '../../../hooks/useAuth';
import ButtonCommon from '../../common/ButtonCommon';
import FormInputValidation from '../components/FormInputValidation';

const initialField = RESET_LOGIN_INFORMATION;

const FormLogInMobile = () => {
  const { t } = useLang();
  const { dispatch } = useStore();
  const { login } = useAuth();

  const [information, setInformation] = useState(initialField);
  const [validation, setValidation] = useState(initialField);
  const [error, setError] = useState('');

  const handleSubmitError = (error) => {
    setError(error?.response?.data?.message?.toLowerCase());

    dispatch({ type: SET_SHOW_LOADING, payload: false });
  };

  const handleSubmit = async () => {
    let newValidation = { ...validation };

    LOGIN_FIELDS.forEach((field) => {
      newValidation = checkFormInputValidation(
        information[field.name],
        field,
        newValidation
      );
    });

    setValidation(newValidation);

    const isAllowSubmit =
      Object.values(information).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowSubmit) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      try {
        const error = await login(
          information[LOGIN_EMAIL_NAME],
          information[LOGIN_PASSWORD_NAME]
        );

        handleSubmitError(error);
      } catch (error) {
        console.error(error?.message);

        handleSubmitError(error);
      }
    }
  };

  return (
    <FormLogInMobileStyled>
      {LOGIN_FIELDS.map((field) => (
        <FormInputValidation
          key={field.name}
          field={field}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          error={error}
          setError={setError}
        />
      ))}
      {LOGIN_UNAUTHORIZED_INFORM[error]}

      <LinkForgotPasswordStyled to={FORGOT_PASSWORD_PREFIX}>
        {t('button_forgot_password')}
      </LinkForgotPasswordStyled>
      <Register>
        {t('login_text_no_account')}{' '}
        <LinkRegisterStyled to={REGISTER_PREFIX}>
          {t('button_register_now')}
        </LinkRegisterStyled>
      </Register>
      <RegisterSubmit>
        <ButtonCommon
          value={t('button_login')}
          onClick={handleSubmit}
          styles={{
            margin: '0px',
            width: '100%',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
        />
      </RegisterSubmit>
    </FormLogInMobileStyled>
  );
};

const FormLogInMobileStyled = styled(Form)`
  position: relative;
  width: 100%;
  height: fit-content;
  padding-bottom: 180px;
`;

const LinkForgotPasswordStyled = styled(Link)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  text-decoration: none;

  color: #002ff1;
  display: block;
  margin-top: 16px;

  &:hover {
    color: var(--ds-c-blue) !important;
  }
`;

const LinkRegisterStyled = styled(Link)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;

  color: var(--bg-primary);

  &:hover {
    color: var(--bg-primary-hover);
  }
`;

const RegisterSubmit = styled.div`
  position: fixed;
  bottom: 0px;
  background: var(--ds-c-white);
  width: 100%;
  margin-inline: -24px;
  padding: 16px 24px;
  border-top: 1px solid var(--ds-c-grey-disabled);
`;

const Register = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  color: var(--ds-c-grey-dark);
  margin-bottom: 0px;
  position: fixed;
  bottom: 76px;
  left: 24px;
  width: 100%;
  height: 44px;
  background: #fff;
`;

export default FormLogInMobile;
