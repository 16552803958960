import { NextWrap } from 'components/fx/FxFormAddTransaction/PickupLocation';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import useScrollToTop from 'hooks/useScrollToTop';
import React from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';

const ReceiptDetails = ({ header, body, footer, calculator }) => {
  const { t } = useLang();

  useScrollToTop();

  return (
    <ReceiptDetailsStyled>
      <Title>{t('label_transaction_summary')}</Title>
      {header}
      {body}
      <NextWrap>
        <Calculator>{calculator}</Calculator>
        <Footer>{footer}</Footer>
      </NextWrap>
    </ReceiptDetailsStyled>
  );
};

const ReceiptDetailsStyled = styled.div`
  padding: 0px;
  margin-top: 0px;
  border-radius: 12px;
  background: var(--ds-c-white);
  width: 100%;
  height: fit-content;
  margin-inline: auto;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: -32px;
    padding: 16px;
    width: 470px;
  }
`;

const Title = styled.p`
  ${paragraphMixin};
  color: var(--ds-c-blue-disabled);
  margin-bottom: 24px;
  text-transform: uppercase;
  font-weight: 500;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    color: var(--ds-c-green-default);
    font-weight: 600;
  }
`;

const Calculator = styled.div``;

const Footer = styled.div`
  padding: 16px;
  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding: 0px;
  }
`;

export default ReceiptDetails;
