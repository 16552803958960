import { api } from 'api';
import KeyIcon from 'assets/icons/key-icon.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH, HHMT_BRAND, REMOX_BRAND } from 'constants';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styled from 'styled-components';
import { stringUtils } from 'utils';
import FormInputSwitch from '../../FormInputSwitch';

import { SCREEN_ID_PROFILE_SECURITY } from 'constants';
import useEvent from 'hooks/useEvent';
import useStore from 'hooks/useStore';
import FormChangePasswordPopup from '../../FormChangePasswordPopup';

const SCREEN_ID = SCREEN_ID_PROFILE_SECURITY;

const SecurityMobile = ({ currentTab, setCurrentTab }) => {
  const { t } = useLang();
  const { token } = useAuth();
  const { isHHMTTheme } = useTheme();
  const { state } = useStore();
  const { currentUser } = state;

  const [isEmailChecked, setEmailChecked] = useState(false);
  const [isSMSChecked, setSMSChecked] = useState(false);
  const [isShowFormResetPasswordPopup, setShowFormResetPasswordPopup] =
    useState(false);

  const fetchProfileOptins = async () => {
    try {
      const { data } = await api.getOptin(token);

      if (data) {
        const { optInByEmail, optInByText } = data;

        setEmailChecked(optInByEmail);

        setSMSChecked(optInByText);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchProfileOptins();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const handleOptin = async (type, value, setValue) => {
    try {
      const { data } = await api.saveOptin(token, type, value);

      if (data) {
        setValue(value);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  const handleOnToggleFormResetPasswordPopup = () => {
    setShowFormResetPasswordPopup(!isShowFormResetPasswordPopup);
  };

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [],
    modalStyles: {},
    modalOptions: {},
  });
  useEffect(() => {
    if (!isShowEvent) return;
    showEventModal();
  }, [isShowEvent]);

  return (
    <>
      <style>
        {`
          .security-mobile-offcanvas {
            width: 100%!important;
            height: calc(100% - 70px);
            border: none!important;
          }
          .security-mobile-offcanvas-backdrop {
            opacity: 0!important;
          }
        `}
      </style>

      {isShowFormResetPasswordPopup && (
        <FormChangePasswordPopup
          isOpen={isShowFormResetPasswordPopup}
          onClose={handleOnToggleFormResetPasswordPopup}
        />
      )}

      <SecurityMobileStyled>
        <NavbarStyled
          expand={false}
          expanded={currentTab === 1}
          onToggle={() => setCurrentTab(null)}
        >
          <NavbarOffcanvas
            id="offcanvasNavbar-expand-false"
            aria-labelledby="offcanvasNavbarLabel-expand-false"
            placement="end"
            className="security-mobile-offcanvas"
            backdropClassName="security-mobile-offcanvas-backdrop"
          >
            <OffcanvasHeader closeButton>
              <OffcanvasTitle id="offcanvasNavbarLabel-expand-false">
                {t('label_security')}
              </OffcanvasTitle>
            </OffcanvasHeader>
            <OffcanvasBody>
              <Title>{t('form_password')}</Title>
              <ButtonCommon
                value={t('button_change_password')}
                color="var(--c-tertiary)"
                background="var(--ds-c-white)"
                isFill={false}
                styles={{
                  paddingInline: 0,
                }}
                borderNameVariable="--border-secondary"
                iconSrc={KeyIcon}
                iconStyles={{
                  filter: isHHMTTheme
                    ? 'var(--ds-f-c-yellow)'
                    : 'var(--ds-f-c-blue-dark)',
                }}
                onClick={handleOnToggleFormResetPasswordPopup}
              />
              <Divider />
              <Title>{t('label_communication_preferences')}</Title>
              <Desc>
                {stringUtils.replaceKeyword(
                  t('label_communication_preferences_desc'),
                  [
                    {
                      key: 'brand',
                      value: isHHMTTheme ? HHMT_BRAND : REMOX_BRAND,
                    },
                  ]
                )}
              </Desc>
              <Button
                onClick={() =>
                  handleOptin('EMAIL', !isEmailChecked, setEmailChecked)
                }
              >
                Email <FormInputSwitch isChecked={isEmailChecked} />
              </Button>
              <Button
                onClick={() => handleOptin('SMS', !isSMSChecked, setSMSChecked)}
              >
                SMS <FormInputSwitch isChecked={isSMSChecked} />
              </Button>
              <ButtonActions>
                <ButtonCommon
                  value={t('button_back')}
                  color="var(--c-primary)"
                  background="var(--bg-primary)"
                  isFill={true}
                  styles={{
                    margin: '0px',
                    width: '100%',
                  }}
                  onClick={() => setCurrentTab(null)}
                />
              </ButtonActions>
            </OffcanvasBody>
          </NavbarOffcanvas>
        </NavbarStyled>
      </SecurityMobileStyled>
    </>
  );
};

const SecurityMobileStyled = styled.div`
  position: relative;
`;

const NavbarStyled = styled(Navbar)`
  display: initial;
  position: absolute;
  top: -40px;
  right: 0;
  padding: 0;
  height: 40px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    top: unset;
    right: unset;
  }
`;

const NavbarOffcanvas = styled(Navbar.Offcanvas)`
  margin-top: 70px;
`;
const OffcanvasHeader = styled(Offcanvas.Header)`
  height: 56px;
  padding: 16px 24px;
  border-bottom: none;

  & button {
    display: none;
  }
`;
const OffcanvasTitle = styled(Offcanvas.Title)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #7a8189;
`;
const OffcanvasBody = styled(Offcanvas.Body)`
  padding-inline: 24px;
  padding-block: 0px;
`;

const Title = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #0e1012;

  display: flex;
  align-items: center;
`;
const Desc = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  align-items: center;
`;

const ButtonActions = styled.div`
  position: absolute;
  bottom: 0px;
  background: var(--ds-c-white);
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  border-top: 1px solid var(--ds-c-grey-disabled);
  height: 70px;
  display: flex;
  align-items: center;
  z-index: 3;
`;

const Divider = styled.div`
  border-bottom: 1px solid #eef2f5;
  margin-bottom: 24px;
  margin-top: 24px;
`;

const Button = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  height: 24px;
`;

export default SecurityMobile;
