import DlImage from 'assets/ver2/icons/doc-upload-dl-icon.svg';
import PImage from 'assets/ver2/icons/doc-upload-p-icon.svg';
import FormInputFileUploadValidation from 'components/FormRegister/DocumentUpload/components/FormInputFileUploadValidation';
import { DESKTOP_MIN_WIDTH } from 'constants';
import {
  FX_ADD_TRANSACTION_UPLOAD_ID_DOCUMENT_UPLOAD_DRIVER_LICENCE_FIELDS,
  FX_ADD_TRANSACTION_UPLOAD_ID_DOCUMENT_UPLOAD_PASSPORT_FIELD,
  FX_ADD_TRANSACTION_UPLOAD_ID_DRIVER_LICENCE_FRONT_NAME,
  FX_ADD_TRANSACTION_UPLOAD_ID_PASSPORT_NAME,
} from 'constants/fx';
import useLang from 'hooks/useLang';
import React from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import FormInputDocumentUploadSwitch from '../components/FormInputDocumentUploadSwitch';

const UploadDetails = ({
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();

  const { isDriverLicenceSelected } = information;

  return (
    <UploadDetailsStyled>
      <Title
        $isRequired={
          validation[FX_ADD_TRANSACTION_UPLOAD_ID_DRIVER_LICENCE_FRONT_NAME] ||
          validation[FX_ADD_TRANSACTION_UPLOAD_ID_PASSPORT_NAME]
        }
      >
        {t('fx_document_upload_label')}
      </Title>
      <FormInputDocumentUploadSwitch
        information={information}
        setInformation={setInformation}
      />
      <Label>
        <span>*</span>
        {t('registration_accept_file_types')}
      </Label>
      {isDriverLicenceSelected && (
        <FormUploadWrap>
          <>
            {FX_ADD_TRANSACTION_UPLOAD_ID_DOCUMENT_UPLOAD_DRIVER_LICENCE_FIELDS.map(
              (field) => (
                <FormInputFileUploadValidation
                  key={field.name}
                  field={field}
                  docType="D"
                  idType="D"
                  information={information}
                  setInformation={setInformation}
                  validation={validation}
                  setValidation={setValidation}
                  styles={{
                    width: '321px',
                    height: '200px',
                    marginBottom: '0px',
                  }}
                  stylesMobile={{
                    height: '200px',
                  }}
                  uploadImageStyles={{
                    top: '52px',
                  }}
                />
              )
            )}
          </>
          <Image src={DlImage} />
        </FormUploadWrap>
      )}
      {!isDriverLicenceSelected && (
        <FormUploadWrap>
          <FormInputFileUploadValidation
            field={FX_ADD_TRANSACTION_UPLOAD_ID_DOCUMENT_UPLOAD_PASSPORT_FIELD}
            docType="P"
            idType="P"
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            styles={{
              width: '321px',
              height: '200px',
              marginBottom: '0px',
            }}
            stylesMobile={{
              height: '200px',
            }}
            uploadImageStyles={{
              top: '52px',
            }}
          />
          <Image src={PImage} />
        </FormUploadWrap>
      )}
    </UploadDetailsStyled>
  );
};

const UploadDetailsStyled = styled.div`
  height: fit-content;
  width: 100%;
`;

const Title = styled.p`
  ${paragraphMixin};
  margin-bottom: 8px;
  ${(props) =>
    props.$isRequired &&
    `
    color: var(--ds-c-red);
  `}
`;
const Label = styled.label`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  padding: 0;
  margin: 0;

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const FormUploadWrap = styled.div`
  display: table;
  width: 100%;
  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    display: flex;
    gap: 24px;
  }
`;

const Image = styled.img`
  width: 100%;
  object-fit: contain;
  margin-top: -22px;
  border-radius: 12px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 321px;
    height: 200px;
    display: block;
    margin-top: 16px;
    object-position: left;
  }
`;

export default UploadDetails;
