import {
  FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_FIELD,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_NAME,
} from 'constants/fx';

const handleEmailValueValidation = (value, name, information, validation) => {
  if (value && name === FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_NAME) {
    const isValid = Boolean(
      FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_FIELD.checkValidFn(value)
    );

    if (!isValid) {
      validation = {
        ...validation,
        [name]: FX_ADD_TRANSACTION_ENTER_INFORMATION_EMAIL_FIELD.msgInvalid,
      };
    }

    if (isValid) {
      validation = {
        ...validation,
        [name]: '',
      };
    }
  }

  return validation;
};

const handleFieldValidation = (value, name, information, validation) => {
  if (value) {
    validation = handleEmailValueValidation(
      value,
      name,
      information,
      validation
    );
  }

  return validation;
};

const handleFieldEmptyValidation = (value, name, validation, msgRequired) => {
  if (!value) {
    validation = {
      ...validation,
      [name]: msgRequired,
    };
  }

  return validation;
};

export const checkFormInputValidation = (
  value,
  field,
  information,
  validation
) => {
  const { name, msgRequired } = field;

  validation = handleFieldEmptyValidation(value, name, validation, msgRequired);

  validation = handleFieldValidation(value, name, information, validation);

  return validation;
};
