import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const CancelPopup = ({ isOpen, onClose, onClick }) => {
  const { t } = useLang();

  return (
    <ModalStyled
      show={isOpen}
      onHide={() => {}}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <SubText>{t('popup_text_cancel_register')}</SubText>
      </ModalBody>
      <ModalFooter>
        <ButtonCommon
          value={t('button_no')}
          onClick={onClose}
          styles={{
            margin: '0px',
            width: '50%',
          }}
          color="var(--ds-c-blue)"
          background="var(--ds-c-white)"
        />
        <ButtonCommon
          value={t('button_yes')}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
          styles={{
            margin: '0px',
            width: '50%',
          }}
          onClick={onClick}
          isUseKeyDown
        />
      </ModalFooter>
    </ModalStyled>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
  }
  & .modal-content {
    padding: 24px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 327px;
    height: fit-content;
    margin-inline: auto;
    border: none;
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 32px;
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default CancelPopup;
