const JobIcon = ({ color = 'var(--ds-c-grey-dark)', size }) => {
  return (
    <svg
      width={size?.width}
      height={size?.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3"
        y="7"
        width="18"
        height="13"
        rx="2"
        stroke={color || '#FBFBFB'}
      />
      <path
        d="M8 7L8 6C8 5.05719 8 4.58579 8.29289 4.2929C8.58579 4 9.05719 4 10 4L14 4C14.9428 4 15.4142 4 15.7071 4.29289C16 4.58579 16 5.05719 16 6L16 7"
        stroke={color || '#FBFBFB'}
        strokeLinecap="round"
      />
      <path
        d="M9 13L9 14C9 14.9428 9 15.4142 9.29289 15.7071C9.58579 16 10.0572 16 11 16L13 16C13.9428 16 14.4142 16 14.7071 15.7071C15 15.4142 15 14.9428 15 14L15 13"
        stroke={color || '#FBFBFB'}
        strokeLinecap="round"
      />
      <path d="M3 13L21 13" stroke={color || '#FBFBFB'} strokeLinecap="round" />
    </svg>
  );
};

export default JobIcon;
