import { isEmpty } from 'lodash';
import {
  SERVICE_ALERTS_TYPE,
  ServiceAlertsModel,
} from './model/ServiceAlertsModel';
import useServiceAlertsConfig from './useServiceAlertsConfig';
import useServiceAlertsStore from './useServiceAlertsStore';

const useServiceAlertsUtil = () => {
  const {
    serviceAlertsByCountryCode: serviceAlertsByCountryCodeStore,
    serviceAlertsByPayments: serviceAlertsByPaymentsStore,
    setServiceAlertsByCountryCode: setServiceAlertsByCountryCodeStore,
    setServiceAlertsByPayments: setServiceAlertsByPaymentsStore,
  } = useServiceAlertsStore();

  const { isStep1, isStep2, isStep4 } = useServiceAlertsConfig();

  const {
    serviceAlertsByCountryCode = null,
    serviceAlertsByPayoutMethod = null,
    serviceAlertsByPayoutMethodItem = null,
    serviceAlertsByPayoutMethodItemChild = null,
    serviceAlertsByCurrencyCode1 = null,
    serviceAlertsByCurrencyCode2 = null,
    serviceAlertsByRangeOfCurrencyCode1 = null,
    serviceAlertsByRangeOfCurrencyCode2 = null,
  } = serviceAlertsByCountryCodeStore || {};
  const { serviceAlertsByPaymentMethod = null } =
    serviceAlertsByPaymentsStore || {};

  const serviceAlertStoreVariant: any = {
    countryCode: {
      serviceAlertsStore: serviceAlertsByCountryCode,
      setServiceAlertsStore: setServiceAlertsByCountryCodeStore,
    },
    payments: {
      serviceAlertsStore: serviceAlertsByPaymentsStore,
      setServiceAlertsStore: setServiceAlertsByPaymentsStore,
    },
  };

  const checkServiceAlertsDISABLE = (
    serviceAlertsList: Array<ServiceAlertsModel | null>
  ) => {
    const serviceAlertsDISABLE =
      serviceAlertsList.find(
        (serviceAlerts: ServiceAlertsModel | null) =>
          serviceAlerts?.type === SERVICE_ALERTS_TYPE.DISABLE
      ) || null;

    if (!isEmpty(serviceAlertsDISABLE)) return true;

    return false;
  };

  const isPreventNextStep1 =
    isStep1 &&
    checkServiceAlertsDISABLE([
      serviceAlertsByCountryCode,
      serviceAlertsByPayoutMethod,
      serviceAlertsByPayoutMethodItem,
      serviceAlertsByPayoutMethodItemChild,
    ]);

  const isPreventNextStep2 =
    isStep2 &&
    checkServiceAlertsDISABLE([
      serviceAlertsByCurrencyCode1,
      serviceAlertsByCurrencyCode2,
      serviceAlertsByRangeOfCurrencyCode1,
      serviceAlertsByRangeOfCurrencyCode2,
    ]);

  const isPreventNextStep4 =
    isStep4 && checkServiceAlertsDISABLE([serviceAlertsByPaymentMethod]);

  return {
    serviceAlertStoreVariant,
    isPreventNextStep1,
    isPreventNextStep2,
    isPreventNextStep4,
  };
};

export default useServiceAlertsUtil;
