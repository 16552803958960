export const CALCULATOR_FORM_COUNTRY_NAME = 'calculator-form-country-name';
export const CALCULATOR_FORM_COUNTRY_FIELD = {
  label: 'label_send_to',
  name: CALCULATOR_FORM_COUNTRY_NAME,
  type: 'text',
  placeholder: 'form_placeholder_please_select',
  isRequired: false,
  isReadOnly: false,
  msgRequired: 'form_required',
};
export const CALCULATOR_FORM_PAYOUT_METHOD_NAME =
  'calculator-form-payout-method-name';
export const CALCULATOR_FORM_PAYOUT_METHOD_FIELD = {
  label: 'label_payout_method',
  name: CALCULATOR_FORM_PAYOUT_METHOD_NAME,
  type: 'text',
  placeholder: 'form_placeholder_please_select',
  isRequired: false,
  isReadOnly: false,
  msgRequired: 'form_required',
};
export const CALCULATOR_FORM_SEND_TO_NAME = 'calculator-form-you-send-name';
export const CALCULATOR_FORM_RECEIVER_GET_NAME =
  'calculator-form-receiver-get-name';
export const CALCULATOR_FORM_AMOUNT_FIELDS = [
  {
    label: 'form_you_send',
    name: CALCULATOR_FORM_SEND_TO_NAME,
    type: 'text',
    placeholder: '0.00',
    isRequired: false,
    isReadOnly: false,
    msgMaxLimited: 'form_amount_invalid_5',
    msgMinLimited: 'form_amount_invalid_2',
    msgRequired: 'form_required',
  },
  {
    label: 'form_receiver_get',
    name: CALCULATOR_FORM_RECEIVER_GET_NAME,
    type: 'text',
    placeholder: '0.00',
    isRequired: false,
    isReadOnly: false,
    msgMaxLimited: 'form_amount_invalid_6',
    msgMinLimited: 'form_amount_invalid_4',
    msgRequired: 'form_required',
  },
];

export const FX_CALCULATOR_FORM_CONVERT_FROM_NAME =
  'fx-calculator-form-convert-from-name';
export const FX_CALCULATOR_FORM_TO_NAME = 'fx-calculator-form-to-name';
export const FX_CALCULATOR_FORM_AMOUNT_FIELDS = [
  {
    label: 'fx_buy_label',
    name: FX_CALCULATOR_FORM_TO_NAME,
    type: 'text',
    placeholder: '0.00',
    isRequired: false,
    isReadOnly: false,
    msgRequired: 'form_required',
  },
  {
    label: 'fx_pay_label',
    name: FX_CALCULATOR_FORM_CONVERT_FROM_NAME,
    type: 'text',
    placeholder: '0.00',
    isRequired: false,
    isReadOnly: false,
    msgRequired: 'form_required',
  },
];

export const FX_CALCULATOR_FORM_AMOUNT_FIELDS_YOU_SELL = [
  {
    label: 'label_you_sell',
    name: FX_CALCULATOR_FORM_CONVERT_FROM_NAME,
    type: 'text',
    placeholder: '0.00',
    isRequired: false,
    isReadOnly: false,
    msgRequired: 'form_required',
  },
  {
    label: 'fx_you_receive',
    name: FX_CALCULATOR_FORM_TO_NAME,
    type: 'text',
    placeholder: '0.00',
    isRequired: false,
    isReadOnly: false,
    msgRequired: 'form_required',
  },
];

export const CALCULATOR_DESKTOP_WIDTH = 376;

export const FX_CALCULATOR_DESKTOP_MIN_WIDTH = 486;
