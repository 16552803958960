/* eslint-disable no-unused-vars */
import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import AlertPopup from 'components/common/PortalModal/popups/AlertPopup';
import useLang from 'hooks/useLang';
import usePortalModal from 'hooks/usePortalModal';
import { functionUtils } from 'utils';

const CreateReceiverModal = ({
  isShow,
  title,
  content,
  action,
  actionClose,
}) => {
  const { t } = useLang();
  const { closePortalModalName } = usePortalModal();

  const handleOnClick = () => {
    if (functionUtils.isFunction(action)) {
      action();
    }
    closePortalModalName();
  };

  const handleOnClose = () => {
    if (functionUtils.isFunction(actionClose)) {
      actionClose();
    }
    closePortalModalName();
  };

  return (
    <AlertPopup
      isOpen={isShow}
      imageSrc={NoticeIcon}
      imagesStyles={{
        width: '124px',
        height: '100px',
      }}
      text={title || ''}
      content={content || ''}
      buttonLabel1={t('button_no')}
      onClose={handleOnClose}
      buttonLabel2={t('button_yes')}
      onClick={handleOnClick}
      isBackdropOverlap={true}
      zIndexBackdropOverlap={1055}
    />
  );
};

export default CreateReceiverModal;
