import {
  DESKTOP_MIN_WIDTH,
  FORGOT_PASSWORD_PREFIX,
  HHMT_EMAIL,
  HHMT_PHONE,
  REMOX_EMAIL,
  REMOX_PHONE,
} from 'constants';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';
import { stringUtils } from 'utils';

const FormResetPasswordExpired = () => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();

  return (
    <FormResetPasswordExpiredWrap>
      <TitleH3>{t('reset_password_text_token_expired_title')}</TitleH3>
      <Paragraph
        style={{ marginBottom: '0px' }}
        dangerouslySetInnerHTML={{
          __html: stringUtils.replaceKeyword(
            t('reset_password_text_token_expired_desc'),
            [
              { key: 'href', value: FORGOT_PASSWORD_PREFIX },
              { key: 'email', value: isHHMTTheme ? HHMT_EMAIL : REMOX_EMAIL },
              { key: 'phone', value: isHHMTTheme ? HHMT_PHONE : REMOX_PHONE },
            ]
          ),
        }}
      />
    </FormResetPasswordExpiredWrap>
  );
};

const FormResetPasswordExpiredWrap = styled.div`
  padding: 40px;
  background: var(--ds-c-white);
  box-shadow: 0px 4px 15px rgb(0 0 0 / 12%);
  border-radius: 40px;

  @media screen and (min-width: 1280px) {
    width: 437px;
    height: fit-content;
    margin-top: 64px;
  }

  @media screen and (min-width: 1440px) {
    width: 517px;
    height: fit-content;
    margin-top: 0px;
  }
`;

const TitleH3 = styled.h3`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;

  & a {
    text-decoration: none;
    color: var(--ds-c-blue);

    &:first-child {
      font-weight: 700;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default FormResetPasswordExpired;
