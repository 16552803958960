import { backgroundReference, colorReference } from 'constants';
import React from 'react';
import styled from 'styled-components';

const AddTxnStep5IconAsGuest = ({
  status = 'disable' || 'in-progress' || 'completed',
}) => {
  return (
    <SvgStyled $backgroundColor={backgroundReference[status]}>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_9975_19293)">
          <circle cx="14.5833" cy="14.5833" r="4.58333" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.33301 9.84375C10.9948 9.84375 13.1544 7.63916 13.1544 4.92188C13.1544 2.20459 10.9948 0 8.33301 0C5.67118 0 3.51158 2.20459 3.51158 4.92188C3.51158 7.63916 5.67118 9.84375 8.33301 9.84375ZM11.1445 10.3978C10.8105 10.4233 10.53 10.5139 10.2209 10.6138C9.75267 10.765 9.21867 10.9375 8.33301 10.9375C7.44802 10.9375 6.91522 10.7653 6.4476 10.6141C6.08195 10.4959 5.75616 10.3906 5.33301 10.3906C2.84863 10.3906 0.833008 12.4482 0.833008 14.9844V15.8594C0.833008 16.7651 1.55287 17.5 2.44015 17.5H10.0179C9.47884 16.6581 9.16634 15.6572 9.16634 14.5833C9.16634 12.8973 9.93666 11.3912 11.1445 10.3978Z"
            fill={colorReference[status]}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.2022 16.419H13.6481C13.341 16.419 13.0939 16.666 13.0939 16.9731C13.0939 17.2802 13.341 17.5273 13.6481 17.5273H15.4953C15.8024 17.5273 16.0494 17.2802 16.0494 16.9731C16.0494 16.666 15.8024 16.419 15.4953 16.419H15.3106V14.3871C15.3106 14.08 15.0635 13.8329 14.7564 13.8329H13.6481C13.341 13.8329 13.0939 14.08 13.0939 14.3871C13.0939 14.6942 13.341 14.9412 13.6481 14.9412H14.2022V16.419ZM15.0941 11.8327C14.9556 11.6941 14.7676 11.6163 14.5717 11.6163C14.3757 11.6163 14.1878 11.6941 14.0492 11.8327C13.9107 11.9713 13.8328 12.1592 13.8328 12.3552C13.8328 12.5511 13.9107 12.7391 14.0492 12.8776C14.1878 13.0162 14.3757 13.094 14.5717 13.094C14.7676 13.094 14.9556 13.0162 15.0941 12.8776C15.2327 12.7391 15.3106 12.5511 15.3106 12.3552C15.3106 12.1592 15.2327 11.9713 15.0941 11.8327ZM14.5833 19.1667C17.1146 19.1667 19.1667 17.1146 19.1667 14.5833C19.1667 12.052 17.1146 10 14.5833 10C12.052 10 10 12.052 10 14.5833C10 17.1146 12.052 19.1667 14.5833 19.1667Z"
            fill={colorReference[status]}
          />
        </g>
        <defs>
          <clipPath id="clip0_9975_19293">
            <rect width={20} height={20} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgStyled>
  );
};

const SvgStyled = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

export default AddTxnStep5IconAsGuest;
