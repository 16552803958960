import { screenHelper } from 'helpers';
import { useEffect } from 'react';

const useScaleMobileViewport = () => {
  useEffect(() => {
    const handlerResize = () => screenHelper.applyScaleMobileViewport();

    handlerResize();
  }, []);
};

export default useScaleMobileViewport;
