import ButtonCommon from 'components/common/ButtonCommon';
import {
  INSTRUCTION_BANK_IMAGES_CAROUSEL,
  INSTRUCTION_PAYID_IMAGES_CAROUSEL,
  METHOD_PAYID,
  MOBILE_MIN_WIDTH,
  TABLET_MAX_WIDTH,
} from 'constants';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const InstructionPopupMobile = ({ isOpen, onClose, method }) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <style>
        {`
          @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${
          TABLET_MAX_WIDTH - 1
        }px) {
            .instruction-modal-backdrop {
              top: 70px;
            }
          }
          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal-backdrop {
              opacity: 0!important;
              z-index: 1045!important;
            }
            .modal {
              top: 70px;
              padding-left: 0px;
              background: #fff;
            }
          }
        `}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={onClose}
        animation={false}
        backdropClassName="instruction-modal-backdrop"
      >
        <ModalHeader>
          {method === METHOD_PAYID
            ? t('popup_instruction_how_to_pay_by_payid')
            : t('popup_instruction_how_to_pay_by_bank_transfer')}
        </ModalHeader>
        <ModalBody>
          <CarouselStyled
            activeIndex={index}
            onSelect={handleSelect}
            interval={3000}
            controls={true}
            variant="dark"
            $isHHMTTheme={isHHMTTheme}
          >
            {(method === METHOD_PAYID
              ? INSTRUCTION_PAYID_IMAGES_CAROUSEL
              : INSTRUCTION_BANK_IMAGES_CAROUSEL
            )?.map(({ label, imgHHMTSrc, imgRemoxSrc }) => (
              <CarouselItem key={label}>
                <ImageStyled>
                  <img
                    className="d-block"
                    src={isHHMTTheme ? imgHHMTSrc : imgRemoxSrc}
                    alt="carousel-item"
                    width={180}
                    height={347}
                  />
                </ImageStyled>
              </CarouselItem>
            ))}
          </CarouselStyled>
          <StepDesc>
            <StepText>{`${t('label_step')} ${index + 1}`}</StepText>
            <DescText>
              {method === METHOD_PAYID
                ? t(INSTRUCTION_PAYID_IMAGES_CAROUSEL[index].desc)
                : t(INSTRUCTION_BANK_IMAGES_CAROUSEL[index].desc)}
            </DescText>
          </StepDesc>
        </ModalBody>
        <ModalFooter>
          <NextWrap>
            <ButtonCommon
              value={t('button_close')}
              onClick={onClose}
              styles={{
                margin: '0px',
                width: '100%',
              }}
              color="var(--c-primary)"
              background="var(--bg-primary)"
              isFill={true}
            />
          </NextWrap>
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  z-index: 1100;
  & .modal-dialog {
    position: absolute;
    top: 0px;
    margin: 0;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    transform: none;
    & .modal-content {
      padding: 24px 24px 0 24px;
      border-radius: 0px;
      box-shadow: none;
      background: var(--ds-c-white);
      width: 100%;
      height: calc(100vh - 70px);
      margin-inline: auto;
      border: none;
    }
  }
`;
const ModalHeader = styled(Modal.Header)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: #1c3f88;
  text-align: center;

  padding: 0;
  margin-bottom: 0;
  display: block;
  padding-bottom: 16px;
  border-bottom: none;
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
`;
const StepDesc = styled.div`
  position: absolute;
  width: 100%;
  padding-bottom: 190px;

  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) {
    top: calc(50vw - -212px);
  }

  @media screen and (min-width: 390px) {
    top: calc(50vw - -206px);
  }

  @media screen and (min-width: 414px) {
    top: calc(50vw - -194px);
  }

  @media screen and (min-width: 428px) {
    top: calc(50vw - -188px);
  }
`;
const StepText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: var(--ds-c-grey-dark);
  margin: 0;
  margin-bottom: 4px;
`;
const DescText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: var(--ds-c-grey-dark);
  margin: 0;
  padding: 0;
`;

const CarouselStyled = styled(Carousel)`
  width: 100%;
  height: fit-content;
  background-color: var(--ds-c-white);

  & .carousel-inner {
    height: 100%;
  }

  & .carousel-control-prev,
  & .carousel-control-next {
    width: 24px;

    & span.carousel-control-next-icon,
    & span.carousel-control-prev-icon {
      background-size: 80%;
      width: 24px;
      height: 24px;
    }
  }

  & .carousel-control-prev {
    left: 0px;
  }

  & .carousel-control-next {
    right: 0px;
  }

  & .carousel-indicators {
    margin-bottom: 0;
    bottom: -24px;

    button {
      background: ${(props) =>
        props.$isHHMTTheme
          ? 'var(--ds-c-white)'
          : 'var(--ds-c-grey-disabled)'} !important;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      border: ${(props) =>
        props.$isHHMTTheme ? '1px solid var(--bg-primary)' : 'none'} !important;
    }

    & .active {
      background: var(--bg-primary) !important;
    }
  }
`;
const CarouselItem = styled(Carousel.Item)`
  height: 100%;
  background-color: var(--ds-c-white);

  & .carousel-caption {
    bottom: 0px;
    top: 108px;
    padding: 0;

    p {
      font-family: var(--ff-primary);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;

      color: var(--ds-c-grey-dark);
      margin-bottom: 0px;
    }
  }
`;
const ImageStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  & img {
    object-fit: contain;
    // image-rendering: pixelated !important;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  background: var(--ds-c-white);
  margin-block: 0px;
  position: fixed;
  left: 24px;
  bottom: 0;
  z-index: 1;
  border-top: 1px solid var(--ds-c-grey-disabled);
`;

export default InstructionPopupMobile;
