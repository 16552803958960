import CalculatorModal from 'components/fx/CalculatorModal';
import {
  CURRENCY_FLAG_REFERENCES,
  CURRENCY_NAME_REFERENCE,
  MOBILE_SMALL_MIN_WIDTH,
  TABLET_MIN_WIDTH,
} from 'constants';
import { format } from 'helpers';
import useFxCalculator from 'hooks/fx/useFxCalculator';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';
import ButtonCommon from '../../../common/ButtonCommon';

const FXRateItem = ({ currency, rate, rateData }) => {
  const { t } = useLang();

  const [isShowCalculatorModal, setShowCalculatorModal] = useState(false);
  const [orderEdit, setOrderEdit] = useState(null);

  const { handleRateItemOnShowCalculatorModal } = useFxCalculator();
  const { resetAddTransactionInformation } = useFxFormAddTransactionStore();

  const handleOnShowCalculatorModal = async () => {
    handleRateItemOnShowCalculatorModal({
      sellRate: rateData,
      callback: (order) => {
        setOrderEdit(order);
        setShowCalculatorModal(true);
        resetAddTransactionInformation();
      },
    });
  };
  const handleOnCloseCalculatorModal = () => {
    setShowCalculatorModal(false);
  };
  const handleOnBuyCurrency = () => {
    handleOnShowCalculatorModal();
  };

  return (
    <>
      {isShowCalculatorModal && !isEmpty(orderEdit) && (
        <CalculatorModal
          isOpen={isShowCalculatorModal && !isEmpty(orderEdit)}
          onClose={handleOnCloseCalculatorModal}
          orderEdit={orderEdit}
        />
      )}
      <ItemWrap>
        <CurrencyCode>
          <FlagIcon src={CURRENCY_FLAG_REFERENCES[currency]} />
          {currency}
        </CurrencyCode>
        <CurrencyName>{CURRENCY_NAME_REFERENCE[currency]}</CurrencyName>
        <Rate>{format.toAmountStr2(rate.toFixed(4))}</Rate>
        <Wrap>
          <ButtonCommon
            value={t('button_buy')}
            styles={{
              width: '62px',
              float: 'right',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
            onClick={handleOnBuyCurrency}
          />
        </Wrap>
      </ItemWrap>
    </>
  );
};

const ItemWrap = styled.div`
  padding: 18px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 180px;
  flex: 1;

  border-radius: 8px;
  background: var(--ds-c-black);
  color: var(--ds-c-white);
  cursor: pointer;
  padding: 12px;

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: left;

  align-items: stretch;

  @media screen and (min-width: ${MOBILE_SMALL_MIN_WIDTH}px) and (max-width: ${TABLET_MIN_WIDTH -
    1}px) {
    min-width: 220px;
  }
`;

const CurrencyCode = styled.h4`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex: 1;

  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  margin: 0 0 10px 0;
  color: var(--ds-c-yellow);
`;

const CurrencyName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1;
`;

const FlagIcon = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
  border: 1px solid var(--ds-c-blue-remox-light);
  border-radius: 50%;
`;

const Rate = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  margin: 10px 0 4px 0;
`;

const Wrap = styled.div`
  width: 100%;
`;
export default FXRateItem;
