import { ENTER_KEY_DOWN, THROTTLE_SEC } from 'constants';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDetectDevice } from './useDetectDevice';
import useThrottle from './useThrottle';

const useEnterKeyDown = (isUseKeyDown, cb, isUseKeyDownException) => {
  const { isDesktop } = useDetectDevice();
  const throttle = useThrottle(THROTTLE_SEC);

  useEffect(() => {
    if (
      (isUseKeyDownException || isDesktop) &&
      isUseKeyDown &&
      isEmpty(document.getElementsByClassName('modal-backdrop'))
    ) {
      const listener = (e) => {
        const { key } = e;

        if (key === ENTER_KEY_DOWN) {
          throttle(() => cb());
        }
      };

      document.addEventListener('keydown', listener);

      return () => {
        document.removeEventListener('keydown', listener);
      };
    }
  }, [isUseKeyDown, cb]);
};

export default useEnterKeyDown;
