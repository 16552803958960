import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH, TABLET_MAX_WIDTH } from 'constants';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const ServiceAlertsPopup = ({
  isOpen,
  text,
  content,
  buttonLabel1,
  buttonLabel2,
  onClose,
  onClick,
  isBackdropOverlap,
  zIndexBackdropOverlap,
  zIndexModal = 0,
}) => {
  return (
    <>
      <style>
        {zIndexModal
          ? `
          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal {
              z-index: ${zIndexModal || '1101'};
            }
          }
        `
          : ``}
        {isBackdropOverlap
          ? `
          .inform-modal-backdrop {
            z-index: ${zIndexBackdropOverlap || '1100'};
            background: #212121;
            opacity: 0.25!important;
            display: initial!important;
          }
        `
          : `
        .inform-modal-backdrop {
          background: #212121;
          opacity: 0.25!important;
          display: initial!important;
        }
      `}
      </style>
      <ModalStyled
        show={isOpen}
        onHide={onClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="inform-modal-backdrop"
        centered
      >
        <ModalBody>
          {text && <Text>{text}</Text>}
          {content && <SubText dangerouslySetInnerHTML={{ __html: content }} />}
        </ModalBody>
        <ModalFooter
          style={{
            justifyContent: !buttonLabel1 && 'center',
          }}
        >
          {buttonLabel1 && (
            <ButtonCommon
              value={buttonLabel1}
              onClick={onClose}
              styles={{
                margin: '0px',
                width: '100%',
              }}
              color="var(--c-primary)"
              background="var(--ds-c-white)"
            />
          )}
          <ButtonCommon
            value={buttonLabel2}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: '100%',
            }}
            onClick={onClick}
            isUseKeyDown
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
    & .modal-content {
      padding: 24px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 328px;
      height: fit-content;
      margin-inline: auto;
      border: none;
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 24px;
  text-align: center;
`;
const Text = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: var(--ds-c-blue);
  margin: 0;
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;

  color: var(--ds-c-grey-dark);
  margin: 0;

  & a {
    text-decoration: none;
    color: var(--ds-c-blue);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default ServiceAlertsPopup;
