import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH, LOGIN_PREFIX } from 'constants';
import { TimerIcon } from 'constants/icon';
import useLang from 'hooks/useLang';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const COUNTDOWN = 60;

const FxInactivityTimePopup = ({ isOpen, onClick, onClose }) => {
  const { t } = useLang();
  const navigate = useNavigate();

  const [countdown, setCountdown] = useState(COUNTDOWN);

  const handleNoActionOnClick = () => {
    onClose();

    navigate(`${LOGIN_PREFIX}`);
  };

  useEffect(() => {
    let timer;

    if (isOpen) {
      timer = setTimeout(() => {
        setCountdown((count) => count - 1);
      }, 1 * 1000);
    }

    if (countdown === -1) {
      handleNoActionOnClick();
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, countdown]);

  return (
    <ModalStyled
      show={isOpen}
      onHide={() => {}}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <TimeoutImage src={TimerIcon} width={124} height={100} alt="" />
        <Text>{t('fx_add_transaction_guest_inactivity_modal_title')}</Text>
        <SubText>
          {t('fx_add_transaction_guest_inactivity_modal_desc', {
            countDownSec: countdown,
          })}
        </SubText>
      </ModalBody>
      <ModalFooter>
        <ButtonCommon
          value={t('button_resume')}
          onClick={onClick}
          styles={{
            margin: '0px',
            marginInline: 'auto',
            width: '200px',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
        />
      </ModalFooter>
    </ModalStyled>
  );
};

const ModalStyled = styled(Modal)`
  z-index: 1100;
  & .modal-dialog {
    transform: none;
  }
  & .modal-content {
    padding: 24px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 327px;
    height: fit-content;
    margin-inline: auto;
    border: none;
    z-index: 1100;
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 24px;
`;
const TimeoutImage = styled.img`
  display: block;
  margin-inline: auto;
  margin-bottom: 16px;
  width: 124px;
  height: 100px;
`;
const Text = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: var(--ds-c-blue);
  margin: 0;
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  color: var(--ds-c-grey-dark);
  margin: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default FxInactivityTimePopup;
