/* eslint-disable no-unused-vars */
import CloseSmallIcon from 'assets/icons/close-small-icon.svg';
import { fetchReceiversCountries } from 'components/Receivers/func';
import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useAuth from 'hooks/useAuth';
import useInputFocus from 'hooks/useInputFocus';
import useLang from 'hooks/useLang';
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import { arrayUtils } from 'utils';
import { isNull } from 'lodash';
import FormInputCheckbox from '../FormInputCheckbox';
import useDetectEndpointVisibleOnScreenWithId from 'hooks/useDetectEndpointVisibleOnScreenWithId';
import Endpoint from 'components/common/Endpoint';

const FILTER_COUNTRY_SEARCH_NAME = 'receivers-filter-country-search';
const ENDPOINT_ID = 'CustomFilterCountryDropdown-endpoint';

const CustomFilterCountryDropdown = ({ filtered, setFiltered, currentTab }) => {
  const { t } = useLang();
  const { token } = useAuth();

  const [countries, setCountries] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [countriesSearch, setCountriesSearch] = useState(null);
  const [isShowDropdown, setShowDropdown] = useState(false);

  const { countriesSelected } = filtered;

  const isIntersecting = useDetectEndpointVisibleOnScreenWithId(ENDPOINT_ID);
  const isFetchReceiversCountriesInititial =
    isIntersecting && isNull(countries);
  useEffect(() => {
    if (isFetchReceiversCountriesInititial) {
      const isReceiverOfBusinessType = currentTab === 1;
      fetchReceiversCountries(token, setCountries, isReceiverOfBusinessType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchReceiversCountriesInititial, currentTab]);

  useInputFocus(FILTER_COUNTRY_SEARCH_NAME);

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    if (!value) {
      setCountriesSearch(countries);

      return;
    }

    const newCountriesSearch = countries?.filter(
      (country) =>
        country?.name?.toLowerCase()?.includes(value?.toLowerCase()) && country
    );
    setCountriesSearch(newCountriesSearch);
  };

  const handleCountrySelected = (e, country) => {
    e.preventDefault();
    e.stopPropagation();

    let newCountriesSelected = countriesSelected ? [...countriesSelected] : [];

    if (
      newCountriesSelected?.some(
        (countrySelected) => countrySelected.code === country.code
      )
    ) {
      newCountriesSelected = countriesSelected?.filter(
        (countrySelected) => countrySelected.code !== country.code
      );
    } else {
      newCountriesSelected.push(country);
    }

    const newFiltered = {
      ...filtered,
      countriesSelected: newCountriesSelected,
    };
    setFiltered(newFiltered);

    setSearchValue('');

    setCountriesSearch(null);
  };

  const handleDropdowOnClick = (e, cb) => {
    e.preventDefault();

    setShowDropdown(!isShowDropdown);

    cb(e);
  };

  const handleDropdownOnToggle = () => {
    setSearchValue('');
    setCountriesSearch(countries);

    setShowDropdown(!isShowDropdown);
  };

  const handleDeleteCountrySelected = (e, country) => {
    e.preventDefault();
    e.stopPropagation();

    const newCountriesSelected = countriesSelected?.filter(
      (countrySelected) => countrySelected.code !== country.code
    );

    const newFiltered = {
      ...filtered,
      countriesSelected: newCountriesSelected,
    };
    setFiltered(newFiltered);
  };

  const renderDropdownItems = (data) => {
    if (arrayUtils.isArrayEmpty(data)) {
      return (
        <DropdownItem
          eventKey={0}
          isReceiversEmpty={arrayUtils.isArrayEmpty(data)}
        >
          <NoItemText>{t('form_text_no_result')}</NoItemText>
        </DropdownItem>
      );
    }

    return (
      <>
        {data?.map((item, index) => (
          <DropdownItem
            key={item.id}
            eventKey={index}
            onClick={(e) => handleCountrySelected(e, item)}
          >
            <FormInputCheckbox
              isChecked={
                Array.isArray(countriesSelected) &&
                countriesSelected?.some(
                  (coutrySelected) => coutrySelected.code === item.code
                )
              }
            />
            {item.name}
          </DropdownItem>
        ))}
      </>
    );
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <ButtonToggle
      href="/"
      ref={ref}
      onClick={(e) => handleDropdowOnClick(e, onClick)}
    >
      {children}
      <ArrowDownIcon
        color="var(--ds-c-grey-dark)"
        size={{ width: 14, height: 10 }}
        styles={{
          transform: isShowDropdown && 'rotate(180deg)',
        }}
      />
    </ButtonToggle>
  ));

  return (
    <CountryDropdownStyled>
      <Label>{t('label_country')}</Label>
      <DropdownStyled onToggle={handleDropdownOnToggle}>
        <Dropdown.Toggle as={CustomToggle}>
          {!arrayUtils.isArrayEmpty(countriesSelected)
            ? countriesSelected.map((country) => (
                <Pill key={country.code}>
                  {country.name}{' '}
                  <img
                    src={CloseSmallIcon}
                    width={12}
                    height={12}
                    alt=""
                    onClick={(e) => handleDeleteCountrySelected(e, country)}
                  />
                </Pill>
              ))
            : ''}
        </Dropdown.Toggle>
        {(countriesSearch || countries) && (
          <DropdownMenuWrap>
            <SearchInput
              name={FILTER_COUNTRY_SEARCH_NAME}
              value={searchValue || ''}
              placeholder={t('form_placeholder_type_to_search')}
              onChange={handleSearch}
            />
            <DropdownMenu>
              {renderDropdownItems(countriesSearch || countries)}
            </DropdownMenu>
          </DropdownMenuWrap>
        )}
      </DropdownStyled>
      <Endpoint id={ENDPOINT_ID} />
    </CountryDropdownStyled>
  );
};

const CountryDropdownStyled = styled.div`
  margin-bottom: 32px;
`;
const DropdownStyled = styled(Dropdown)`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 44px;
  height: fit-content;
  background: var(--ds-c-white);
  border: 1px solid #eef2f5;
  border-radius: 12px;
`;
const DropdownMenuWrap = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  width: 100%;
  height: 44px;
  background: transparent;
  box-shadow: var(--ds-bs-4);
`;
const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  max-height: 160px;
  border-top: 1px solid #eef2f5;
  border-radius: 0 0 12px 12px;
  width: 100%;
  overflow: auto;
  background: var(--ds-c-white);
  margin-top: -1px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12) !important;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ds-c-grey-hover);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;
const DropdownItem = styled(Dropdown.Item)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  padding: 10px;
  text-transform: capitalize;
  min-height: 40px;
  height: fit-content;
  white-space: break-spaces;
  background: var(--ds-c-white);

  a:first-child {
    border-radius: 0.375rem 0.375rem 0 0;
  }

  &:hover {
    background: var(--ds-c-grey-light);
  }

  &:active {
    color: var(--ds-c-grey-dark);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const SearchInput = styled.input`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: none;
  border-radius: 12px 12px 0 0;
  background: var(--ds-c-white);
  width: 100%;
  min-height: 44px;
  height: fit-content;
  padding: 10px;

  &:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: initial;
    color: var(--ds-c-grey-hover);
  }
`;

const NoItemText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #b0b6bd;
  text-transform: initial;

  margin: 0px;
  padding: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const ButtonToggle = styled.a`
  font-family: var(--ff-primary);
  line-height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--ds-c-grey-dark);
  text-transform: capitalize;
  text-decoration: none;

  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 8px 10px;
  padding-right: 32px;
  width: 100%;
  min-height: 44px;
  height: fit-content;
  flex-wrap: wrap;
  gap: 4px;

  &:hover {
    background: transparent;
    border: none;
    color: var(--ds-c-grey-dark-hover);
  }

  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  & svg {
    position: absolute;
    right: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar:horizontal {
    background: red;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ds-c-grey-hover);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const Pill = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;

  padding: 4px 8px;
  min-width: fit-content;
  height: 24px;
  background: #1c3f88;
  border-radius: 100px;

  &:last-child {
    margin-right: 0px;
  }

  & img {
    margin-top: -2px;
    margin-left: 2px;
    cursor: pointer;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;
const Label = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  margin-bottom: 8px;
`;

export default CustomFilterCountryDropdown;
