export const formatter = {
  formatParse: (format) => (format ? JSON.parse(format) : ''),
  singleMask: function (value, mask, masked = true) {
    value = value || '';
    if (!mask) {
      return value;
    }
    mask = mask || '';
    let iMask = 0;
    let iValue = 0;
    let output = '';
    const tokens = {
      '#': {
        pattern: /[a-zA-Z0-9]/,
      },
      '!': {
        escape: true,
      },
      0: {
        pattern: /\d/,
      },
      1: {
        pattern: /\d/,
      },
      3: {
        pattern: /\d/,
      },
      4: {
        pattern: /\d/,
      },
      6: {
        pattern: /\d/,
      },
      8: {
        pattern: /\d/,
      },
      9: {
        pattern: /\d/,
      },
      A: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      a: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      B: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      b: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      C: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      c: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      D: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      d: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      E: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      e: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      F: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      f: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      G: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      g: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      H: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      h: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      I: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      i: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      J: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      j: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      K: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      k: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      L: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      l: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      M: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      m: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      N: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      n: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      O: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      o: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      P: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      p: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      Q: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      q: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      R: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      r: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      S: {
        pattern: /[a-zA-Z]/,
      },
      s: {
        pattern: /[a-zA-Z]/,
      },
      T: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      t: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      U: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      u: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      V: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      v: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      W: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      w: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      X: {
        pattern: /[0-9a-zA-Z]/,
      },
      x: {
        pattern: /[0-9a-zA-Z]/,
      },
      Y: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      y: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
      Z: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleUpperCase(),
      },
      z: {
        pattern: /[a-zA-Z]/,
        transform: (v) => v.toLocaleLowerCase(),
      },
    };
    while (iMask < mask.length && iValue < value.length) {
      let cMask = mask[iMask];
      const masker = tokens[cMask];
      const cValue = value[iValue];
      if (masker && !masker.escape) {
        if (masker.pattern.test(cValue)) {
          output += masker.transform ? masker.transform(cValue) : cValue;
          iMask++;
        }
        iValue++;
      } else {
        if (masker && masker.escape) {
          iMask++; // take the next mask char and treat it as char
          cMask = mask[iMask];
        }
        if (masked) {
          output += cMask;
        }
        if (cValue === cMask) {
          iValue++;
        } // user typed the same char
        iMask++;
      }
    }

    // fix mask that ends with a char: (#)
    let restOutput = '';
    // eslint-disable-next-line no-unmodified-loop-condition
    while (iMask < mask.length && masked) {
      const cMask = mask[iMask];
      if (tokens[cMask]) {
        restOutput = '';
        break;
      }
      restOutput += cMask;
      iMask++;
    }

    return output + restOutput;
  },
  dynamicMask: function (masks) {
    masks = masks.sort((a, b) => a.length - b.length);
    return function (value, mask, masked = true) {
      let i = 0;
      while (i < masks.length) {
        const currentMask = masks[i];
        i++;
        const nextMask = masks[i];
        if (
          !(
            nextMask &&
            formatter.singleMask(value, nextMask, true).length >
              currentMask.length
          )
        ) {
          return formatter.singleMask(value, currentMask, masked);
        }
      }
      return ''; // empty masks
    };
  },
  mask: function (value, mask, masked = true) {
    return Array.isArray(mask)
      ? formatter.dynamicMask(mask)(value, mask, masked)
      : formatter.singleMask(value, mask, masked);
  },
  unMask: function (value, mask, masked = false) {
    return Array.isArray(mask)
      ? formatter.dynamicMask(mask)(value, mask, masked)
      : formatter.singleMask(value, mask, masked);
  },
};
