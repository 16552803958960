/* eslint-disable no-unused-vars */
import {
  checkDiscountAppliedInvalidWhenPaymentMethodChangeBeforeTxnCreated,
  checkDiscountAppliedValidWhenPaymentMethodChangeAfterTxnCreated,
  fetchDiscountsAvailable,
  getDiscountDefault,
} from 'components/Discount/func';
import { PORTAL_NAME_MODAL_DEFINE } from 'components/common/PortalModal/config';
import {
  ADD_TRANSACTION_PAYMENT_METHOD_INSTRUCTION,
  ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_RECEIVER_GET_NAME,
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  DESKTOP_MIN_WIDTH,
  HHMT_EMAIL,
  HHMT_PHONE,
  HOME_URL,
  METHOD_EPAYMENT,
  METHOD_POLI,
  REMOX_EMAIL,
  REMOX_PHONE,
  RESET_ADD_TRANSACTION_INFORMATION_STORE,
  STATUS_CANCELLED,
  STATUS_FAIL,
} from 'constants';
import { QuestionIcon } from 'constants/icon';
import { format } from 'helpers';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import useCheckServiceAlerts from 'hooks/service-alerts/useCheckServiceAlerts';
import useServiceAlertsConfig from 'hooks/service-alerts/useServiceAlertsConfig';
import useServiceAlertsStore from 'hooks/service-alerts/useServiceAlertsStore';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import usePortalModal from 'hooks/usePortalModal';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { SET_ADD_TRANSACTION_INFORMATION } from 'store/action';
import styled from 'styled-components';
import { numberUtils, stringUtils } from 'utils';
import UrgentPopup from '../../../UrgentPopup';

const initialFormField = RESET_ADD_TRANSACTION_INFORMATION_STORE.paymentMethod;

const PaymentMethodItem = ({
  countryCode,
  method,
  information,
  setInformation,
  validation,
  setValidation,
  informationChangeBackup,
  setInformationChangeBackup,
  setOpenDiscountImportantPopup,
  payLimit,
  isDisabled,
  isSelectRequired,
}) => {
  const { t, locate } = useLang();
  const { state, dispatch } = useStore();
  const { addTransactionInformation } = state;
  const {
    receiverPayoutMethod,
    remittance,
    paymentMethod: paymentMethodStore,
  } = addTransactionInformation || {};
  const { isHHMTTheme, theme } = useTheme();
  const { token } = useAuth();
  const query = useQuery();

  const [isShowUrgentPopup, setShowUrgentPopup] = useState(false);

  const { showPortalModalName } = usePortalModal();

  const { paymentMethod } = useServiceAlertsConfig();

  const { serviceAlertsByPayments: serviceAlertsStore } =
    useServiceAlertsStore();
  const { checkByPaymentMethod } = useCheckServiceAlerts();
  const { checkServiceAlertsOfPaymentMethodItemDisable } = checkByPaymentMethod;
  const isServiceAlertsOfPaymentMethodItemDisable =
    checkServiceAlertsOfPaymentMethodItemDisable({
      serviceAlertsStore,
      paymentMethod: method.method,
    });

  const isSelected =
    !isServiceAlertsOfPaymentMethodItemDisable &&
    information[ADD_TRANSACTION_PAYMENT_METHOD_NAME] === method.method;

  const isPayLimit = isDisabled && !isServiceAlertsOfPaymentMethodItemDisable;

  const payoutMethod = receiverPayoutMethod[ADD_TRANSACTION_PAYOUT_METHOD_NAME];

  const currencyCode = remittance?.currentCurrencySelected?.currencyCode;

  const isOnlyCurrency = !remittance.isAddAnotherReceivingCurrency;

  const sendAmount = numberUtils.mathRound(
    remittance[ADD_TRANSACTION_YOU_SEND_NAME] || 0
  );
  const sendAnotherAmount = numberUtils.mathRound(
    remittance[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] || 0
  );
  const sendTotalAmount = numberUtils.mathRound(sendAmount + sendAnotherAmount);

  const receiverAmount = Number(
    remittance[ADD_TRANSACTION_RECEIVER_GET_NAME] || 0
  );

  const {
    discountApplied,
    discountsAvailable,
    discountsInactive,
    discountDetails,
  } = paymentMethodStore || {};
  const discountDefault = getDiscountDefault({
    discountsAvailable,
    discountsInactive,
  });

  const paymentStatus = query.get('paymentStatus');
  const refNumber = query.get('refNumber');
  const isMakePayment = Cookies.get(MAKE_PAYMENT_KEY) || refNumber;
  const isUpdateTxn =
    paymentStatus === STATUS_FAIL ||
    paymentStatus === STATUS_CANCELLED ||
    isMakePayment;

  const getDiscountsAvailable = (paymentMethod) => {
    if (paymentMethod) {
      fetchDiscountsAvailable({
        token,
        countryCode,
        payoutMethod,
        currencyCode,
        isOnlyCurrency,
        sendAmount: sendTotalAmount,
        receiverAmount,
        paymentMethod,
      });
    }
  };

  useEffect(() => {
    if (!isUpdateTxn && isSelected && paymentMethod && !discountApplied) {
      getDiscountsAvailable(paymentMethod);
    }
  }, [isSelected, paymentMethod]);

  const handlePaymentMethodSelected = (method) => {
    const newInformation = {
      ...information,
      [ADD_TRANSACTION_PAYMENT_METHOD_NAME]: method,
    };

    const isCheckDiscountAppliedAfterTxnCreated =
      checkDiscountAppliedValidWhenPaymentMethodChangeAfterTxnCreated({
        isUpdateTxn,
        discount: discountDetails,
        currentPaymentMethodSelected: method,
      });

    const isCheckDiscountAppliedBeforeTxnCreated =
      checkDiscountAppliedInvalidWhenPaymentMethodChangeBeforeTxnCreated({
        isUpdateTxn,
        discountApplied,
        prevPaymentMethodSelected:
          paymentMethodStore[ADD_TRANSACTION_PAYMENT_METHOD_NAME],
        currentPaymentMethodSelected: method,
      }) && !isCheckDiscountAppliedAfterTxnCreated;

    if (
      paymentMethod &&
      isEmpty(discountDefault) &&
      (isCheckDiscountAppliedBeforeTxnCreated ||
        (discountApplied && !isCheckDiscountAppliedAfterTxnCreated))
    ) {
      setOpenDiscountImportantPopup(true);

      setInformationChangeBackup(newInformation);

      return;
    }

    setInformation(newInformation);

    setValidation(initialFormField);

    dispatch({
      type: SET_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        paymentMethod: {
          ...addTransactionInformation.paymentMethod,
          ...newInformation,
          ...(paymentMethod &&
            isEmpty(discountDefault) && { discountsAvailable: [] }),
          ...(paymentMethod &&
            isEmpty(discountDefault) &&
            !isCheckDiscountAppliedAfterTxnCreated && {
              discountApplied: null,
            }),
        },
      },
    });

    if (method === METHOD_POLI) {
      setShowUrgentPopup(true);
    }
  };

  const handlePaymentMethodDisabledSelected = () => {
    const newInformation = {
      ...information,
      [ADD_TRANSACTION_PAYMENT_METHOD_NAME]: '',
    };
    setInformation(newInformation);

    dispatch({
      type: SET_ADD_TRANSACTION_INFORMATION,
      payload: {
        ...addTransactionInformation,
        paymentMethod: {
          ...addTransactionInformation.paymentMethod,
          [ADD_TRANSACTION_PAYMENT_METHOD_NAME]: method,
        },
      },
    });
  };

  const handleShowPaymentInstructionModal = () => {
    showPortalModalName({
      name: PORTAL_NAME_MODAL_DEFINE.PAYMENT_INSTRUCTION_MODAL,
      content: (
        <InstructionBodyContent
          dangerouslySetInnerHTML={{
            __html: stringUtils.replaceKeyword(
              t(ADD_TRANSACTION_PAYMENT_METHOD_INSTRUCTION[method.method]),
              [
                {
                  key: 'email',
                  value: isHHMTTheme ? HHMT_EMAIL : REMOX_EMAIL,
                },
                {
                  key: 'phone',
                  value: isHHMTTheme ? HHMT_PHONE : REMOX_PHONE,
                },
                {
                  key: 'lang',
                  value: locate,
                },
                {
                  key: 'host',
                  value: HOME_URL[theme],
                },
              ]
            ),
          }}
        />
      ),
    });
  };

  if (isPayLimit) {
    return (
      <PaymentMethodItemStyled
        $isSelected={isSelected}
        $isDisabled={isDisabled}
        onClick={() => handlePaymentMethodDisabledSelected()}
      >
        <ImageWrap
          $isSelected={isSelected}
          $isDisabled={isDisabled}
          $isPayLimit={isPayLimit}
        >
          <img src={method.icon} alt={method.label} />
          <p
            className="image-label"
            dangerouslySetInnerHTML={{
              __html: stringUtils.replaceBreakLine(t(method.label)),
            }}
          />
          <p className="image-desc">{t(method.desc)}</p>
          {method?.payLimitDesc &&
            isDisabled &&
            method.method === METHOD_EPAYMENT &&
            payLimit?.payLimitMax && (
              <p className="image-sub-desc">{`${t(
                method.payLimitDesc
              )} < ${format.toAmountCentStr(payLimit?.payLimitMax)} AUD`}</p>
            )}
        </ImageWrap>
        <InstructionImage
          src={QuestionIcon}
          width={20}
          height={20}
          onClick={handleShowPaymentInstructionModal}
        />
      </PaymentMethodItemStyled>
    );
  }
  return (
    <>
      {isShowUrgentPopup && (
        <UrgentPopup
          isOpen={isShowUrgentPopup}
          onClose={() => setShowUrgentPopup(false)}
        />
      )}

      <PaymentMethodItemStyled
        $isSelected={isSelected}
        $isDisabled={isDisabled}
        $isRequired={isSelectRequired}
      >
        <ImageWrap
          $isSelected={isSelected}
          $isPayLimit={false}
          onClick={() => handlePaymentMethodSelected(method.method)}
        >
          <img
            src={isSelected ? method.iconColor : method.icon}
            alt={method.label}
          />
          <p
            className="image-label"
            dangerouslySetInnerHTML={{
              __html: stringUtils.replaceBreakLine(t(method.label)),
            }}
          />
          {method?.desc && <p className="image-desc">{t(method.desc)}</p>}
          {method?.payLimitDesc &&
            method.method === METHOD_EPAYMENT &&
            payLimit?.payLimitMax && (
              <p className="image-sub-desc">{`${t(
                method.payLimitDesc
              )} < ${format.toAmountCentStr(payLimit?.payLimitMax)} AUD`}</p>
            )}
        </ImageWrap>
        <InstructionImage
          src={QuestionIcon}
          width={20}
          height={20}
          onClick={handleShowPaymentInstructionModal}
        />
      </PaymentMethodItemStyled>
    </>
  );
};

const PaymentMethodItemStyled = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  background-color: ${(props) => (props.$isSelected ? '#FEF0D1' : 'unset')};
  box-shadow: 0px 4px 15px 0px #0000001a;
  border: ${(props) =>
    props.$isRequired ? '1px solid var(--ds-c-red)' : '1px solid transparent'};
  cursor: pointer;
  padding: 10px;

  ${(props) =>
    props.$isDisabled &&
    `
    background-color: #eeeeee;
    border: 1px dashed 1px solid transparent;
  `};

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 206px;
    height: 162px;
  }
`;

const ImageWrap = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  color: var(--ds-c-grey-dark);
  user-select: none;

  display: grid;
  align-items: center;
  justify-items: center;
  margin-top: 0px;
  width: 100%;

  & img {
    width: 62px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 4px;
    ${(props) => props.$isDisabled && 'filter: grayscale(1)'};
  }

  & p.image-label {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;
    white-space: break-spaces;

    width: 162px;
    margin-bottom: 4px;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & p.image-desc {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    text-transform: capitalize;

    margin-bottom: 0px;
  }

  & p.image-sub-desc {
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    text-transform: capitalize;
    color: ${(props) =>
      props.$isPayLimit ? 'var(--ds-c-red)' : 'var(--ds-c-blue)'};

    margin-bottom: 0px;
    margin-top: 2px;
  }
`;

const InstructionImage = styled.img`
  align-self: end;
`;
const InstructionBodyContent = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  width: 100%;
  padding-bottom: 8px;

  & p {
    cursor: text;
    margin: 0;
    padding: 0;
  }

  & p.instruction-title {
    font-weight: 600;
    text-transform: uppercase;
    color: var(--ds-c-green-default);
    margin-bottom: 20px;
  }

  & p.instruction-content {
  }

  & p.instruction-contact {
  }

  & a {
    color: var(--ds-c-blue-hyperlink-default) !important;
    text-decoration: none;
    text-transform: none;

    &:focus,
    &:focus-visible,
    &:active {
      color: var(--ds-c-blue-hyperlink-default) !important;
    }

    &:hover {
      color: var(--ds-c-blue-hyperlink-hover) !important;
    }
  }

  & span {
    font-weight: 700;
  }

  & span.warning {
    font-weight: 500;
    color: var(--ds-c-red);
  }

  & ol {
    margin: 0;
    padding-left: 24px;

    & li {
      list-style: auto;
      list-style-type: decimal;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default PaymentMethodItem;
