import ArrowDownIcon from 'assets/ver2/icons/arrow-down-icon.svg';
import DiscountGrayEmptyIcon from 'assets/ver2/icons/voucher-icon-small-gray.svg';
import GiftGrayEmptyIcon from 'assets/ver2/icons/gift-gray-icon.svg';
import DiscountTicket from 'components/Discount/components/DiscountTicket';
import { DESKTOP_MIN_WIDTH, PROMOTION } from 'constants';
import { Fade } from 'helpers/animation';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const OfferSection = ({
  title = '',
  descIcon = null,
  descText = '',
  emptyText = '',
  offers = null,
  isShowDivider = true,
  type = '',
}) => {
  const { t } = useLang();

  const [offersShortlist, setOffersShortlist] = useState(null);
  const [isExpand, setExpand] = useState(false);

  const isExpandable = !isEmpty(offers) && offers?.length > 4;

  const isPromotion = type === PROMOTION;

  useEffect(() => {
    if (isExpandable) {
      setOffersShortlist(offers?.slice(0, 4));
    } else {
      setOffersShortlist(offers);
    }
  }, [isExpandable]);

  const handleExpand = () => {
    if (isExpand) {
      setOffersShortlist(offers?.slice(0, 4));
      setExpand(false);
    } else {
      setOffersShortlist(offers);
      setExpand(true);
    }
  };

  if (isEmpty(offers))
    return (
      <>
        <Title>{title}</Title>
        <DescSection>
          <DescIcon
            src={isPromotion ? GiftGrayEmptyIcon : DiscountGrayEmptyIcon}
            width={20}
            height={20}
          />
          <DescText style={{ color: 'var(--ds-c-blue-disabled)' }}>
            {emptyText}
          </DescText>
        </DescSection>
        {isShowDivider && <Divider />}
      </>
    );

  const getExpandText = () => {
    if (isPromotion) {
      return isExpand
        ? t('label_offers_promotions_seeless')
        : t('label_offers_promotions_seemore');
    }
    return isExpand ? t('label_offers_seeless') : t('label_offers_seemore');
  };

  return (
    <>
      <Title>{title}</Title>
      {!isEmpty(descText) && (
        <DescSection>
          {!isEmpty(descIcon) && (
            <DescIcon src={descIcon} width={20} height={20} />
          )}
          {!isEmpty(descText) && <DescText>{descText}</DescText>}
        </DescSection>
      )}
      <ContentBody>
        <GridWrap>
          {offersShortlist?.map((v) => (
            <GridItem key={v?.code}>
              <DiscountTicket item={v} styles={{ marginTop: 0 }} />
            </GridItem>
          ))}
        </GridWrap>
      </ContentBody>
      {isExpandable && (
        <ExpandSection onClick={handleExpand}>
          <ExpandText>{getExpandText()}</ExpandText>
          <ExpandIcon
            src={ArrowDownIcon}
            width={24}
            height={24}
            $isExpand={isExpand}
          />
        </ExpandSection>
      )}
      {isShowDivider && <Divider />}
    </>
  );
};

const Title = styled.h4`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-black);
  text-transform: uppercase;

  margin-top: 0px;
  margin-bottom: 10px;
`;

const DescSection = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 10px;
  gap: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    align-items: center;
  }
`;
const DescIcon = styled.img``;
const DescText = styled.p`
  margin: 0;
  padding: 0;
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--c-primary);
`;

const ExpandSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 24px;
  margin-top: 12px;
  width: 100%;
  cursor: pointer;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    justify-content: start;
    width: fit-content;
  }
`;
const ExpandText = styled.p`
  margin: 0;
  padding: 0;
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue-hyperlink-default);
`;
const ExpandIcon = styled.img`
  filter: var(--ds-f-c-blue-hyperlink);
  transform: ${(props) => props.$isExpand && 'rotate(180deg)'};
  transition: 0.3s;
`;

const ContentBody = styled.div`
  width: 100%;
  height: fit-content;
`;

const GridWrap = styled.div`
  display: grid;
  grid-template-columns: 100%;
  gap: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    grid-template-columns: 436px 436px;
  }
`;
const GridItem = styled.div`
  ${Fade}
`;

const Divider = styled.div`
  border-top: 2px dashed var(--ds-bg-2);
  margin-block: 40px;
`;

export default OfferSection;
