import { backgroundReference, colorReference } from 'constants';
import React from 'react';
import styled from 'styled-components';

const AddTxnStep5Icon = ({
  status = 'disable' || 'in-progress' || 'completed',
}) => {
  return (
    <SvgStyled $backgroundColor={backgroundReference[status]}>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_9249_63939)">
          <circle cx="14.5833" cy="14.5833" r="4.58333" fill="white" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5833 19.1667C17.1146 19.1667 19.1667 17.1146 19.1667 14.5833C19.1667 12.052 17.1146 10 14.5833 10C12.052 10 10 12.052 10 14.5833C10 17.1146 12.052 19.1667 14.5833 19.1667ZM17.0116 13.3816L16.2291 12.7295L14.0399 15.3567L12.9064 14.2232L12.1862 14.9434L14.1083 16.8655L17.0116 13.3816Z"
            fill={colorReference[status]}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.33301 9.84375C10.9948 9.84375 13.1544 7.63916 13.1544 4.92188C13.1544 2.20459 10.9948 0 8.33301 0C5.67118 0 3.51158 2.20459 3.51158 4.92188C3.51158 7.63916 5.67118 9.84375 8.33301 9.84375ZM11.1445 10.3978C10.8105 10.4233 10.53 10.5139 10.2209 10.6138C9.75267 10.765 9.21867 10.9375 8.33301 10.9375C7.44802 10.9375 6.91522 10.7653 6.4476 10.6141C6.08195 10.4959 5.75616 10.3906 5.33301 10.3906C2.84863 10.3906 0.833008 12.4482 0.833008 14.9844V15.8594C0.833008 16.7651 1.55287 17.5 2.44015 17.5H10.0179C9.47884 16.6581 9.16634 15.6572 9.16634 14.5833C9.16634 12.8973 9.93666 11.3912 11.1445 10.3978Z"
            fill={colorReference[status]}
          />
        </g>
        <defs>
          <clipPath id="clip0_9249_63939">
            <rect width={20} height={20} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgStyled>
  );
};

const SvgStyled = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

export default AddTxnStep5Icon;
