import ButtonLinkCommon from 'components/common/ButtonLinkCommon';
import {
  DESKTOP_MIN_WIDTH,
  FORGOT_PASSWORD_EMAIL_NAME,
  LOGIN_PREFIX,
} from 'constants';
import useLang from 'hooks/useLang';
import styled from 'styled-components';
import { stringUtils } from 'utils';

const FormSendEmailSuccess = ({ information }) => {
  const { t } = useLang();

  return (
    <FormForgotPasswordWrap>
      <TitleH3>{t('forgot_password_text_submitted')}</TitleH3>
      <Paragraph>
        {stringUtils.replaceKeyword(
          t('forgot_password_text_send_instruction'),
          [{ key: 'email', value: information[FORGOT_PASSWORD_EMAIL_NAME] }]
        )}
      </Paragraph>
      <FormForgotPasswordStyled>
        <BackToLogin>
          <ButtonLinkCommon
            href={LOGIN_PREFIX}
            value={t('button_back_to_login')}
            styles={{
              margin: '0px',
              width: '200px',
              marginInline: 'auto 0px',
            }}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
          />
        </BackToLogin>
      </FormForgotPasswordStyled>
    </FormForgotPasswordWrap>
  );
};

const FormForgotPasswordWrap = styled.div`
  padding: 40px;
  background: var(--ds-c-white);
  box-shadow: 0px 4px 15px rgb(0 0 0 / 12%);
  border-radius: 40px;

  @media screen and (min-width: 1280px) {
    width: 437px;
    height: fit-content;
  }

  @media screen and (min-width: 1440px) {
    width: 517px;
    height: fit-content;
  }
`;

const FormForgotPasswordStyled = styled.div`
  position: relative;
  width: 100%;
  height: calc(100% - 99px);
`;

const TitleH3 = styled.h3`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;
`;

const BackToLogin = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 24px;

  button {
    width: 200px;
  }
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 24px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default FormSendEmailSuccess;
