import {
  fetchTransactionsFiltered,
  getBodyPayloadFiltered,
} from 'components/FXTransactions/func';
import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import {
  PAGE_DEFAULT,
  PAGINATION_NEXT_BUTTON,
  PAGINATION_PREV_BUTTON,
  SORT_DESC,
} from 'constants';
import { FX_TRANSACTION_TABLE_HEADING } from 'constants/fx';
import useAuth from 'hooks/useAuth';
import styled from 'styled-components';
import { objectUtils } from 'utils/func';
import useStore from '../../../../hooks/useStore';
import { SET_REFRESH_TRANSACTION_LIST } from '../../../../store/action';

const Pagination = ({
  totalPages,
  applyFiltered,
  searchValue,
  currentSearchBy,
  sortOrder,
  totalElements,
  currentPage,
  setCurrentPage,
  transactions,
  setTransactions,
  current,
  setCurrent,
  setFetching,
}) => {
  const { token } = useAuth();
  const { dispatch } = useStore();

  const isDisableNext = currentPage === totalPages;
  const isDisablePrev = currentPage === PAGE_DEFAULT;

  const fetchTransactionsPagination = (page) => {
    const currentFieldSort = Object.keys(
      objectUtils.deletePropIsFalsy(sortOrder)
    )[0];
    const sortPayload = {
      sortField: FX_TRANSACTION_TABLE_HEADING[currentFieldSort] || 'createDate',
      sortOrder: sortOrder[currentFieldSort] || SORT_DESC,
    };

    let bodyPayload = getBodyPayloadFiltered(applyFiltered);

    bodyPayload = {
      ...bodyPayload,
      ...(currentSearchBy && { [currentSearchBy]: searchValue }),
    };

    setTransactions(null);
    fetchTransactionsFiltered({
      token,
      body: bodyPayload,
      sort: sortPayload,
      page,
      setTransactions,
      setCurrentPage,
      setFetching,
    });
    dispatch({ type: SET_REFRESH_TRANSACTION_LIST, payload: false });
  };

  const handleNextPage = () => {
    if (currentPage === totalPages) {
      return;
    }

    if (currentPage === totalPages - 1) {
      setCurrent('');
    } else {
      setCurrent(PAGINATION_NEXT_BUTTON);
    }

    setFetching(true);

    fetchTransactionsPagination(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage === PAGE_DEFAULT) {
      return;
    }

    if (currentPage === 2) {
      setCurrent('');
    } else {
      setCurrent(PAGINATION_PREV_BUTTON);
    }

    setFetching(true);

    fetchTransactionsPagination(currentPage - 1);
  };

  return (
    <PaginationStyled>
      <ArrowDownIcon
        color={current === PAGINATION_PREV_BUTTON ? '#0E1012' : '#B0B6BD'}
        styles={{
          transform: 'rotate(90deg)',
          marginRight: '25px',
          cursor: isDisablePrev ? 'not-allowed' : 'pointer',
        }}
        onClick={handlePrevPage}
      />
      {`${(currentPage - 1) * 10 + 1}-${
        /* eslint-disable-next-line no-extra-boolean-cast */
        Boolean(transactions.length)
          ? (currentPage - 1) * 10 + transactions.length
          : ''
      } of ${totalElements}`}
      <ArrowDownIcon
        color={current === PAGINATION_NEXT_BUTTON ? '#0E1012' : '#B0B6BD'}
        styles={{
          transform: 'rotate(270deg)',
          marginLeft: '25px',
          cursor: isDisableNext ? 'not-allowed' : 'pointer',
        }}
        onClick={handleNextPage}
      />
    </PaginationStyled>
  );
};

const PaginationStyled = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

export default Pagination;
