import '@babel/register';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import ReactDOM from 'react-dom/client';
import { FirebaseProvider } from 'store/firebase';
import { FxStateProvider } from 'store/fx';
import 'utils/console';
import App from './App';
import './i18n';
import { StateProvider } from './store';
import GlobalStyle from './styles/globalStyle';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StateProvider>
      <FxStateProvider>
        <FirebaseProvider>
          <GlobalStyle />
          <App />
        </FirebaseProvider>
      </FxStateProvider>
    </StateProvider>
  </React.StrictMode>
);
