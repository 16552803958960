import ValidIcon from 'assets/icons/done-icon.svg';
import InvalidIcon from 'assets/icons/warnning-red-icon.svg';
import { findCountry } from 'components/common/func';
import {
  ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_RECEIVER_GET_NAME,
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  DISCOUNT_METHOD,
  FX_TRANSACTION_ADD_PREFIX,
  PAYMENT_METHOD_TEXT_REFERENCE,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import { datePicker, format, getDiscountStatus, getValidText } from 'helpers';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import { numberUtils } from 'utils';

const BodyConditionsTime = ({ offer, inactive }) => {
  const { t } = useLang();
  const { pathname } = useLocation();
  const { state } = useStore();
  const { addTransactionInformation, currentReceiver } = state;
  const { receiverPayoutMethod, remittance } = addTransactionInformation || {};

  const countryCode = currentReceiver?.country?.code;
  const sendAmount = Number(remittance[ADD_TRANSACTION_YOU_SEND_NAME] || 0);
  const sendAnotherAmount = Number(
    remittance[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] || 0
  );
  const sendTotalAmount = Number(sendAmount + sendAnotherAmount);
  const receivedAmount = Number(remittance[ADD_TRANSACTION_RECEIVER_GET_NAME]);
  const currencyCodeSelected =
    remittance?.currentCurrencySelected?.currencyCode;
  const feeAmount = numberUtils.mathRound(
    remittance?.feeAmount?.feeAmount || 0
  );
  const paymentMethodStore =
    addTransactionInformation?.paymentMethod?.[
      ADD_TRANSACTION_PAYMENT_METHOD_NAME
    ];
  const payoutMethodStore =
    receiverPayoutMethod[ADD_TRANSACTION_PAYOUT_METHOD_NAME];

  const isCreateTxn =
    pathname === `${TRANSACTION_ADD_PREFIX}` ||
    pathname.includes(`${FX_TRANSACTION_ADD_PREFIX}`);

  const {
    country,
    discountMethod,
    discountAmount,
    startDate = 0,
    endDate = 0,
    limitTxnPerCustomer = 0,
    lastRedeemAt = 0,
    minTxnAmount = 0,
    maxTxnAmount = 0,
    currencyCode,
    deliveryMethod,
    paymentMethod,
  } = offer || {};

  const { status, isActive, isActiveRedeemLast } =
    getDiscountStatus(offer || null) || {};

  const isActiveAndUnavailable = isActive && inactive;

  const isShowFlatFeeOfDiscountCondition = Boolean(
    discountMethod === DISCOUNT_METHOD.FLAT_FEE &&
      Number(discountAmount) > 0 &&
      Boolean(Number(discountAmount) > Number(feeAmount))
  );
  /* eslint-disable no-extra-boolean-cast */
  const isFlatFeeOfDiscountInvalid =
    isShowFlatFeeOfDiscountCondition &&
    (inactive || Boolean(Number(discountAmount) > Number(feeAmount)));

  const getConditionListStyle = (props) => {
    const {
      checkByCountryCode,
      checkByReceiveAmountMin,
      checkByReceiveAmountMax,
      checkByCurrencyCode,
      checkByPaymentMethod,
      checkByPayoutMethod,
      checkByFlatFeeOfDiscount,
    } = props || {};

    if (!isCreateTxn) return <Disc>●</Disc>;

    if (isActiveAndUnavailable) {
      const isCheckByCountryCodeInvalid = Boolean(
        checkByCountryCode && country && country !== countryCode
      );
      if (isCheckByCountryCodeInvalid) {
        return <img src={InvalidIcon} width={18} alt="" />;
      }

      const isCheckByReceiveAmountMaxInvalid =
        checkByReceiveAmountMax &&
        maxTxnAmount > 0 &&
        Boolean(
          currencyCode
            ? receivedAmount > maxTxnAmount
            : sendTotalAmount > maxTxnAmount
        );
      if (isCheckByReceiveAmountMaxInvalid) {
        return <img src={InvalidIcon} width={18} alt="" />;
      }

      const isCheckByReceiveAmountMinInvalid = Boolean(
        checkByReceiveAmountMin &&
          Boolean(
            currencyCode
              ? receivedAmount < minTxnAmount
              : sendTotalAmount < minTxnAmount
          )
      );
      if (isCheckByReceiveAmountMinInvalid) {
        return <img src={InvalidIcon} width={18} alt="" />;
      }

      const isCheckByCurrencyCodeOfReceiveInvalid = Boolean(
        currencyCode &&
          (checkByReceiveAmountMax || checkByReceiveAmountMin) &&
          checkByCurrencyCode &&
          currencyCodeSelected !== currencyCode
      );
      if (isCheckByCurrencyCodeOfReceiveInvalid) {
        return <img src={InvalidIcon} width={18} alt="" />;
      }

      // const isCheckByCurrencyCodeOfSendInvalid = Boolean(
      //   !currencyCode &&
      //     (checkByReceiveAmountMax || checkByReceiveAmountMin) &&
      //     checkByCurrencyCode &&
      //     isCheckByReceiveAmountMaxInvalid &&
      //     isCheckByReceiveAmountMinInvalid
      // );
      // if (isCheckByCurrencyCodeOfSendInvalid) {
      //   return <img src={InvalidIcon} width={18} alt="" />;
      // }

      const isCheckByPayoutMethodInvalid = Boolean(
        checkByPayoutMethod && deliveryMethod !== payoutMethodStore
      );
      if (isCheckByPayoutMethodInvalid) {
        return <img src={InvalidIcon} width={18} alt="" />;
      }

      const isCheckByPaymentMethodInvalid = Boolean(
        checkByPaymentMethod && paymentMethod !== paymentMethodStore
      );
      if (isCheckByPaymentMethodInvalid) {
        return <img src={InvalidIcon} width={18} alt="" />;
      }

      const isCheckByFlatFeeOfDiscountInvalid = Boolean(
        checkByFlatFeeOfDiscount && isFlatFeeOfDiscountInvalid
      );
      if (isCheckByFlatFeeOfDiscountInvalid) {
        return <img src={InvalidIcon} width={18} alt="" />;
      }
    }
    return <img src={ValidIcon} width={18} alt="" />;
  };

  const condition1 = (props = {}) => {
    return {
      text: t('label_offers_conditions_txt_1', {
        countryName: findCountry(country)?.label,
      }),
      icon: getConditionListStyle(props),
      isHide: !country,
    };
  };
  const condition2 = (props = {}) => {
    return {
      text: t('label_offers_conditions_txt_2', {
        minTxnAmount: format.toAmountStr(minTxnAmount),
        currencyCode: currencyCode || 'AUD',
      }),
      icon: getConditionListStyle(props),
      isHide: Number(minTxnAmount) === 0,
    };
  };
  const condition3 = (props = {}) => {
    return {
      text: t('label_offers_conditions_txt_3', {
        maxTxnAmount: format.toAmountStr(maxTxnAmount),
        currencyCode: currencyCode || 'AUD',
      }),
      icon: getConditionListStyle(props),
      isHide: Number(maxTxnAmount) === 0,
    };
  };
  const condition4 = (props = {}) => {
    return {
      text: t('label_offers_conditions_txt_4', {
        limitTxnPerCustomer,
      }),
      icon: getConditionListStyle(props),
      isHide:
        Number(limitTxnPerCustomer) === 0 || Number(limitTxnPerCustomer) === 1,
    };
  };
  const condition5 = (props = {}) => {
    return {
      text: t('label_offers_conditions_txt_5', {
        paymentMethod: t(
          PAYMENT_METHOD_TEXT_REFERENCE[paymentMethod]
        )?.toUpperCase(),
      }),
      icon: getConditionListStyle(props),
      isHide: !paymentMethod,
    };
  };
  const condition6 = (props = {}) => {
    return {
      text: t(`label_offers_conditions_txt_6_${deliveryMethod?.toLowerCase()}`),
      icon: getConditionListStyle(props),
      isHide: !deliveryMethod,
    };
  };
  const condition7 = (props = {}) => {
    return {
      text: t(`label_offers_conditions_txt_7`),
      icon: getConditionListStyle(props),
      isHide: discountMethod !== DISCOUNT_METHOD.FLAT_FEE,
    };
  };
  const conditions = [
    condition1({ checkByCountryCode: true }),
    condition2({
      checkByReceiveAmountMin: true,
      checkByCurrencyCode: true,
    }),
    condition3({
      checkByReceiveAmountMax: true,
      checkByCurrencyCode: true,
    }),
    condition6({
      checkByPayoutMethod: true,
    }),
    condition4(),
    condition5({ checkByPaymentMethod: true }),
    condition7({ checkByFlatFeeOfDiscount: true }),
  ];

  return (
    <BodyConditionsTimeStyled>
      {Boolean(conditions?.length) && (
        <>
          <ConditionsText>{t('label_offers_conditions')}:</ConditionsText>
          <ConditionList>
            {conditions?.map((item, key) => (
              <li key={key} style={{ display: item?.isHide && 'none' }}>
                {item?.icon}
                <Paragraph
                  style={{
                    color:
                      item?.icon?.props?.src?.includes('warnning-red-icon') &&
                      'red',
                  }}
                  dangerouslySetInnerHTML={{ __html: item?.text }}
                />
              </li>
            ))}
          </ConditionList>
        </>
      )}
      {isActiveRedeemLast && (
        <LastUseText>
          {`${t(
            'label_offers_last_use'
          )} ${datePicker.convertToLocalDateTimeWithTimeZone(
            new Date(lastRedeemAt)
          )}`}
        </LastUseText>
      )}
      {endDate && (
        <ExpiredText
          style={{ marginBottom: 0 }}
          dangerouslySetInnerHTML={{
            __html: getValidText({
              t,
              startDate,
              endDate,
              lastRedeemAt,
              isAustraliaTime: true,
            })[status],
          }}
        />
      )}
    </BodyConditionsTimeStyled>
  );
};

const BodyConditionsTimeStyled = styled.div``;

const LastUseText = styled.p`
  ${paragraphMixin};
  text-align: left;
  margin-block: 8px;
  font-weight: 500;

  & p {
    font-weight: 500;
    font-size: 12px;
    color: var(--ds-c-grey-dark);
    margin: 0;
  }

  & span {
    color: red;
  }
`;

const ExpiredText = styled.p`
  ${paragraphMixin};
  text-align: left;
  margin-block: 8px;
  color: var(--ds-c-blue);
  font-weight: 500;

  & p {
    font-weight: 500;
    font-size: 12px;
    color: var(--ds-c-grey-dark);
    margin: 0;
  }

  & span {
    color: red;
  }
`;

const ConditionsText = styled.p`
  ${paragraphMixin};
  text-align: left;
  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;
`;
const ConditionList = styled.ul`
  margin: 0;
  margin-bottom: 0px;
  padding-left: 0px;

  & li {
    display: flex;
    align-items: flex-start;

    & img {
      margin-right: 4px;
    }

    & p {
      margin-bottom: 0px;
    }
  }
`;

const Paragraph = styled.p`
  ${paragraphMixin};
  font-weight: 500;
  margin-bottom: 8px;
`;

const Disc = styled.span`
  font-size: 6px;
  line-height: 3.5;
  margin-right: 8px;
`;

export default BodyConditionsTime;
