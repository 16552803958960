/* eslint-disable no-unused-vars */
import TooltipCommon from 'components/common/TooltipCommon';
import TooltipCommonMobile from 'components/common/TooltipCommon/mobile';
import { getNumberUnmaskStr } from 'components/FormEditReceiver/func';
import { DEBOUNCE_SEC, DESKTOP_MIN_WIDTH } from 'constants';
import { formatter } from 'helpers';
import useDebounceCallback from 'hooks/useDebounceCallback';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { stringUtils } from 'utils';

const customFormInputStyleMobile = {};

const customFormInputStyle = {};

const FormInputPortalCodeNumberValidation = ({
  field,
  config,
  numberMaskStr,
  setNumberMaskStr,
  information,
  setInformation,
  validation,
  setValidation,
  styles = {},
}) => {
  const { t } = useLang();
  const { isMobile, isTablet, isDesktop } = useDetectDevice();

  const isConfigRequired = config?.required;

  const isConfigVisible = config?.visible;

  const placeholder = config?.example;

  const numberFormat = formatter.formatParse(config?.format);

  const numberMaxLength = config?.max;

  const numberMinLength = config?.min;

  const label = config?.type || t(field.label);

  useEffect(() => {
    if (information[field.name]) {
      const numberMask = formatter.mask(
        getNumberUnmaskStr(information[field.name], numberFormat),
        numberFormat
      );
      setNumberMaskStr(numberMask);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!information[field.name]) {
      setNumberMaskStr('');
    }
  }, [information]);

  const handleNumberValidation = async (
    value,
    name,
    validation,
    information
  ) => {
    try {
      return validation;
    } catch (error) {
      console.error(error?.message);

      validation = {
        ...validation,
        [name]: field.msgNumberInvalid,
      };
    }

    return validation;
  };
  const checkNumberValid = async (value, validation, information) => {
    if (!validation[field.name]) {
      validation = await handleNumberValidation(
        value,
        field.name,
        validation,
        information
      );
    }

    setValidation(validation);
  };
  const { debounce: handleNumberValid } = useDebounceCallback(
    (value, validation, information) => {
      checkNumberValid(value, validation, information);
    },
    DEBOUNCE_SEC
  );

  const handleOnChange = (e) => {
    let { value, name } = e.target;
    let valueTrim = value.trim();

    const numberUnmaskStr = getNumberUnmaskStr(valueTrim, numberFormat);

    if (numberUnmaskStr?.length > numberMaxLength) return;

    if (!numberUnmaskStr) {
      const newInfomation = {
        ...information,
        [name]: '',
      };
      setInformation(newInfomation);

      let newValidation = {
        ...validation,
        [name]: '',
      };

      if (isConfigVisible && !numberUnmaskStr) {
        newValidation = {
          ...newValidation,
          [name]: field.msgRequired,
        };
      }

      setValidation(newValidation);

      setNumberMaskStr('');

      return;
    }

    let newInformation = {
      ...information,
    };

    let newValidation = {
      ...validation,
    };

    const numberStr = formatter.mask(numberUnmaskStr, numberFormat);
    setNumberMaskStr(numberStr);

    newValidation = {
      ...newValidation,
      [name]: '',
    };

    newInformation = {
      ...newInformation,
      [name]: numberUnmaskStr,
    };

    const isNumberMaxEqualNumberMin =
      numberMaxLength && numberMaxLength === numberMinLength;
    if (isConfigVisible && numberUnmaskStr) {
      if (
        isNumberMaxEqualNumberMin &&
        numberUnmaskStr.length < numberMinLength
      ) {
        newValidation = {
          ...newValidation,
          [name]: field.msgInvalid,
        };
      }
    }

    handleNumberValid(numberUnmaskStr, newValidation, newInformation);

    setInformation(newInformation);

    setValidation(newValidation);
  };

  const handleFormLabelOnClick = (e, name) => {
    e.preventDefault();

    if (isDesktop) {
      const inputEle = document.getElementsByName(name)[0];

      if (inputEle) {
        inputEle.focus();
      }
    }
  };

  const getMessageValidation = (validation) => {
    const numberUnmaskStr = getNumberUnmaskStr(numberMaskStr, numberFormat);

    if (
      numberMinLength &&
      numberUnmaskStr.length < numberMinLength &&
      numberMinLength === numberMaxLength
    ) {
      return stringUtils.replaceKeyword(t(validation[field.name]), [
        {
          key: 'range',
          value: `${numberMaxLength}`,
        },
      ]);
    }

    if (numberMinLength && numberUnmaskStr.length < numberMinLength) {
      return stringUtils.replaceKeyword(t(validation[field.name]), [
        {
          key: 'range',
          value: `${numberMinLength}-${numberMaxLength}`,
        },
      ]);
    }

    if (numberUnmaskStr?.length < numberMinLength) {
      return stringUtils.replaceKeyword(t(validation[field.name]), [
        {
          key: 'range',
          value: numberMaxLength,
        },
      ]);
    }

    return t(validation[field.name]);
  };

  return (
    <FormGroupStyled
      style={
        isMobile || isTablet
          ? { ...customFormInputStyleMobile[field.name], ...styles }
          : { ...customFormInputStyle[field.name], ...styles }
      }
    >
      <FormLabel onClick={(e) => handleFormLabelOnClick(e, field.name)}>
        {label}
        {(isConfigRequired ?? field.isRequired) && <span>*</span>}
        {field.tooltip && (
          <>
            {isMobile || isTablet ? (
              <TooltipCommonMobile
                text={t(field.tooltip)}
                name={field.name}
                label={t(field.label)}
              />
            ) : (
              <TooltipCommon text={t(field.tooltip)} />
            )}
          </>
        )}
      </FormLabel>
      <FormControl
        type={field.type}
        name={field.name}
        value={numberMaskStr}
        placeholder={placeholder || t(field.placeholder)}
        onChange={handleOnChange}
        isInvalid={validation[field.name]}
        readOnly={field.isReadOnly}
      />
      <FormControlFeedback hidden={false} type="invalid">
        {getMessageValidation(validation)}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: ${(props) =>
    props.istextcolordisabled
      ? 'var(--ds-c-grey-neutral)'
      : 'var(--ds-c-grey-dark)'};
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: ${(props) =>
    props.istextcolordisabled
      ? 'var(--ds-c-grey-disabled)'
      : 'var(--ds-c-white)'};
  cursor: ${(props) => props.istextcolordisabled && 'not-allowed'};

  &:focus {
    background: ${(props) =>
      props.istextcolordisabled && 'var(--ds-c-grey-disabled)!important'};
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-inline: 10px !important;
  }

  &[readonly] {
    background: ${(props) =>
      props.istextcolordisabled && 'var(--ds-c-grey-disabled)!important'};
    border: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputPortalCodeNumberValidation;
