import { api } from 'api';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  DESKTOP_MIN_WIDTH,
  PAYMENT_BY_DEPOSIT_CVC_NUMBER_FIELD,
  PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME,
  PAYMENT_BY_DEPOSIT_CVV_NUMBER_FIELD,
  PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME,
  PAYMENT_BY_DEPOSIT_EXPIRY_FIELD,
  PAYMENT_BY_DEPOSIT_EXPIRY_NAME,
  PAYMENT_BY_DEPOSIT_NAME,
  PAYMENT_BY_DEPOSIT_NAME_FIELD,
  PAYMENT_BY_DEPOSIT_NUMBER_FIELD,
  PAYMENT_BY_DEPOSIT_NUMBER_NAME,
  RESET_PAYMENT_BY_DEPOSIT_STORE,
} from 'constants';
import { format, masterCardNumberValid, visaNumberValid } from 'helpers';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import { numberUtils } from 'utils';
import FormInputCardExpiryDateValidation from '../FormInputCardExpiryDateValidation';
import FormInputPaymentCardNumberValidation from '../FormInputPaymentCardNumberValidation';
import FormInputValidation from '../FormInputValidation';

const FormPaymentByDeposit = ({
  isFx = false,
  isFxAsGuest = false,
  isOpen,
  transactionInformation,
  cardInformation,
  setCardInformation,
  cardValidation,
  setCardValidation,
  onClose,
  onClick,
}) => {
  const { t } = useLang();

  const { token } = useAuth();
  const { dispatch } = useStore();

  useEffect(() => {
    return () => {
      setCardInformation(RESET_PAYMENT_BY_DEPOSIT_STORE);
      setCardValidation(RESET_PAYMENT_BY_DEPOSIT_STORE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const nameOnCard = cardInformation[PAYMENT_BY_DEPOSIT_NAME]?.trim();
  const nameOnCardCustom = isEmpty(transactionInformation)
    ? ''
    : !isFx || !isFxAsGuest
    ? ''
    : `${transactionInformation?.customer?.firstName} ${transactionInformation?.customer?.lastName}`;

  const isCardTypeOther =
    !masterCardNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME]) &&
    !visaNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME]);

  const isCardCVCCVVNumberLengthValid = masterCardNumberValid(
    cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME]
  )
    ? cardInformation[PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME]?.length === 3
    : cardInformation[PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME]?.length === 3;

  const isCardNumberLengthValid =
    cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME]?.length === 16;

  const isPayDisabled =
    !cardInformation[PAYMENT_BY_DEPOSIT_NAME] ||
    cardValidation[PAYMENT_BY_DEPOSIT_NAME] ||
    !cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME] ||
    cardValidation[PAYMENT_BY_DEPOSIT_NUMBER_NAME] ||
    !cardInformation[PAYMENT_BY_DEPOSIT_EXPIRY_NAME] ||
    cardValidation[PAYMENT_BY_DEPOSIT_EXPIRY_NAME] ||
    (masterCardNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME])
      ? !cardInformation[PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME] ||
        cardValidation[PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME]
      : !cardInformation[PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME] ||
        cardValidation[PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME]) ||
    !isCardNumberLengthValid ||
    !isCardCVCCVVNumberLengthValid ||
    isCardTypeOther;

  const totalPay = isFx
    ? transactionInformation?.orderTotal
    : transactionInformation?.totalPay;

  const parseAmountValue = (value) =>
    format.toAmountFloatStr(numberUtils.mathRound(value));

  const payMintValidate = async () => {
    if (isFx) {
      if (isFxAsGuest) {
        return await api.fxPayMintAsGuest(
          token,
          transactionInformation.orderRef,
          cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME],
          cardInformation[PAYMENT_BY_DEPOSIT_EXPIRY_NAME].split('/')[0],
          cardInformation[PAYMENT_BY_DEPOSIT_EXPIRY_NAME].split('/')[1],
          masterCardNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME])
            ? cardInformation[PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME]
            : cardInformation[PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME],
          nameOnCard,
          false
        );
      }
      return await api.fxPayMint(
        token,
        transactionInformation.orderRef,
        cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME],
        cardInformation[PAYMENT_BY_DEPOSIT_EXPIRY_NAME].split('/')[0],
        cardInformation[PAYMENT_BY_DEPOSIT_EXPIRY_NAME].split('/')[1],
        masterCardNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME])
          ? cardInformation[PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME]
          : cardInformation[PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME],
        nameOnCard,
        false
      );
    }
    return await api.payMint(
      token,
      transactionInformation.refNumber,
      cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME],
      cardInformation[PAYMENT_BY_DEPOSIT_EXPIRY_NAME].split('/')[0],
      cardInformation[PAYMENT_BY_DEPOSIT_EXPIRY_NAME].split('/')[1],
      masterCardNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME])
        ? cardInformation[PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME]
        : cardInformation[PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME],
      nameOnCard,
      false
    );
  };
  const handlePay = async () => {
    const newCardInformation = { ...cardInformation };
    const newCardValidation = { ...cardValidation };

    if (visaNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME])) {
      delete newCardInformation[PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME];
      delete newCardValidation[PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME];
    }

    if (
      masterCardNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME])
    ) {
      delete newCardInformation[PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME];
      delete newCardValidation[PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME];
    }

    const isAllowSubmit =
      Object.values(newCardInformation).every((value) => value) &&
      Object.values(newCardValidation).every((value) => !value) &&
      !isPayDisabled;

    if (isAllowSubmit) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      try {
        const { data } = await payMintValidate();

        if (data) {
          const { success, redirect3dsUrl } = data;

          if (success) {
            onClick(redirect3dsUrl);

            return;
          }

          onClick();

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      } catch (error) {
        console.error(error?.message);

        onClick();

        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }
    } else {
      let newCardValidation = { ...cardValidation };

      if (isCardTypeOther) {
        newCardValidation = {
          ...newCardValidation,
          [PAYMENT_BY_DEPOSIT_NUMBER_NAME]:
            PAYMENT_BY_DEPOSIT_NUMBER_FIELD.msgNotSupport,
        };

        setCardValidation(newCardValidation);

        return;
      }

      if (!isCardNumberLengthValid) {
        newCardValidation = {
          ...newCardValidation,
          [PAYMENT_BY_DEPOSIT_NUMBER_NAME]:
            PAYMENT_BY_DEPOSIT_NUMBER_FIELD.msgInvalid,
        };

        setCardValidation(newCardValidation);

        return;
      }

      if (
        masterCardNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME])
      ) {
        newCardValidation = {
          ...newCardValidation,
          [PAYMENT_BY_DEPOSIT_CVC_NUMBER_NAME]:
            PAYMENT_BY_DEPOSIT_CVC_NUMBER_FIELD.msgInvalid,
        };
      }

      if (visaNumberValid(cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME])) {
        newCardValidation = {
          ...newCardValidation,
          [PAYMENT_BY_DEPOSIT_CVV_NUMBER_NAME]:
            PAYMENT_BY_DEPOSIT_CVV_NUMBER_FIELD.msgInvalid,
        };
      }

      setCardValidation(newCardValidation);
    }
  };

  const renderActionsButton = () => {
    return (
      <>
        <ButtonCommon
          value={t('button_cancel')}
          onClick={onClose}
          styles={{
            margin: '0px',
            width: '50%',
          }}
          color="var(--ds-c-blue)"
          background="var(--ds-c-white)"
        />
        <ButtonCommon
          value={t('button_pay')}
          onClick={handlePay}
          styles={{
            margin: '0px',
            width: '50%',
            textTransform: 'uppercase',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
          isDisabled={isPayDisabled}
        />
      </>
    );
  };

  return (
    <>
      <style>
        {`
        .add-transaction-payment-by-deposit-modal-backdrop {
          background: #212121;
          opacity: 0.25!important;
          display: initial!important;
        }
      `}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="add-transaction-payment-by-deposit-modal-backdrop"
        centered
      >
        <ModalHeader>{t('popup_payment_via_card')}</ModalHeader>
        <ModalBody>
          <TotalPay>{`${parseAmountValue(totalPay)} AUD`}</TotalPay>
          <FormInputValidation
            field={PAYMENT_BY_DEPOSIT_NAME_FIELD}
            information={cardInformation}
            setInformation={setCardInformation}
            validation={cardValidation}
            setValidation={setCardValidation}
            nameOnCardCustom={nameOnCardCustom}
          />
          <FormInputPaymentCardNumberValidation
            field={PAYMENT_BY_DEPOSIT_NUMBER_FIELD}
            information={cardInformation}
            setInformation={setCardInformation}
            validation={cardValidation}
            setValidation={setCardValidation}
          />
          <FormInputCardExpiryDateValidation
            field={PAYMENT_BY_DEPOSIT_EXPIRY_FIELD}
            information={cardInformation}
            setInformation={setCardInformation}
            validation={cardValidation}
            setValidation={setCardValidation}
          />
          <>
            {masterCardNumberValid(
              cardInformation[PAYMENT_BY_DEPOSIT_NUMBER_NAME]
            ) ? (
              <FormInputValidation
                field={PAYMENT_BY_DEPOSIT_CVC_NUMBER_FIELD}
                information={cardInformation}
                setInformation={setCardInformation}
                validation={cardValidation}
                setValidation={setCardValidation}
              />
            ) : (
              <FormInputValidation
                field={PAYMENT_BY_DEPOSIT_CVV_NUMBER_FIELD}
                information={cardInformation}
                setInformation={setCardInformation}
                validation={cardValidation}
                setValidation={setCardValidation}
              />
            )}
          </>
        </ModalBody>
        <ModalFooter>{renderActionsButton()}</ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
  }
  & .modal-content {
    padding: 24px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 470px;
    height: fit-content;
    margin-inline: auto;
  }
`;
const ModalHeader = styled(Modal.Header)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-green-default);
  text-transform: uppercase;

  padding: 0;
  margin-bottom: 0;
  display: block;
  margin-bottom: 24px;
  border: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 0px;
`;
const TotalPay = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: var(--ds-c-blue-hyperlink-default);

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ds-c-black-disabled);
  height: 44px;
  width: 100%;
  border-radius: 12px;
  margin: 0;
  margin-bottom: 24px;
  padding: 0;
`;
const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
  justify-content: end;
`;

export default FormPaymentByDeposit;
