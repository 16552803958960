import {
  AMOUNT_NUMBER_REGEX,
  DEBOUNCE_SEC,
  FX_CALCULATOR_DESKTOP_MIN_WIDTH,
  FX_CALCULATOR_FORM_CONVERT_FROM_NAME,
  FX_CALCULATOR_FORM_TO_NAME,
  CURRENCY_FLAG_REFERENCES,
} from 'constants';
import { format } from 'helpers';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useLang from 'hooks/useLang';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { numberUtils } from 'utils';
import CustomCurrencyDropdown from '../CustomCurrencyDropdown';

const FormInputAmountValidation = ({
  field,
  currentTab,
  currencies,
  information,
  setInformation,
  validation,
  setValidation,
  rate,
  setRate,
}) => {
  const { t } = useLang();

  const { currencyCode } = information['currentCurrencySelected'] || {};

  const isConvertFromField =
    field.name === FX_CALCULATOR_FORM_CONVERT_FROM_NAME;
  const isToField = field.name === FX_CALCULATOR_FORM_TO_NAME;

  const currentMinDenomination =
    currencies?.find((c) => c.currencyCode === currencyCode)?.minDenomination ||
    1;

  const handleConvertFromAmountValidation = (
    information,
    validation,
    isDisable
  ) => {
    let newValidation = { ...validation };

    if (!isDisable) {
      newValidation = handleToAmountValidation(
        information,
        newValidation,
        true
      );
    }

    return newValidation;
  };

  const handleToAmountValidation = (
    information,
    validation,
    isDisable = false
  ) => {
    let newValidation = { ...validation };

    if (!isDisable) {
      newValidation = handleConvertFromAmountValidation(
        information,
        newValidation,
        true
      );
    }

    return newValidation;
  };

  const handleConvertFromOnChange = (value) => {
    let toValue = 0;

    if (currentTab === 0) {
      toValue = Math.round(value * rate);
    }
    if (currentTab === 1) {
      toValue = numberUtils.mathRound(value / rate);
    }

    document.getElementsByName(FX_CALCULATOR_FORM_CONVERT_FROM_NAME)[0].value =
      format.toAmountCentStr(value);
    document.getElementsByName(FX_CALCULATOR_FORM_TO_NAME)[0].value =
      format.toAmountStr(toValue);

    if (currentTab === 0) {
      toValue =
        Math.round((value * rate) / currentMinDenomination) *
        currentMinDenomination;

      value = numberUtils.mathRound(toValue / rate);
    }
    if (currentTab === 1) {
      value =
        Math.round((toValue * rate) / currentMinDenomination) *
        currentMinDenomination;

      toValue = numberUtils.mathRound(value / rate);
    }

    value = Number(value);

    toValue = Number(toValue);

    return [value, toValue];
  };

  const handleToOnChange = (value) => {
    let convertFromValue = 0;

    if (currentTab === 0) {
      convertFromValue = numberUtils.mathRound(value / rate);
    }
    if (currentTab === 1) {
      convertFromValue = Math.round(value * rate);
    }

    document.getElementsByName(FX_CALCULATOR_FORM_CONVERT_FROM_NAME)[0].value =
      format.toAmountStr(convertFromValue);
    document.getElementsByName(FX_CALCULATOR_FORM_TO_NAME)[0].value =
      format.toAmountCentStr(value);

    if (currentTab === 0) {
      value =
        Math.round((convertFromValue * rate) / currentMinDenomination) *
        currentMinDenomination;

      convertFromValue = numberUtils.mathRound(value / rate);
    }
    if (currentTab === 1) {
      convertFromValue =
        Math.round((value * rate) / currentMinDenomination) *
        currentMinDenomination;

      value = numberUtils.mathRound(convertFromValue / rate);
    }

    convertFromValue = Number(convertFromValue);

    value = Number(value);

    return [convertFromValue, value];
  };

  const { debounce: handleOnChangeDebounce } = useDebounceCallback(
    (information, setInformation) => {
      setInformation(information);
    },
    DEBOUNCE_SEC
  );

  const handleOnChange = async (e) => {
    const { value, name } = e.target;

    let valueTrim = value ? format.toStrAmount(value.trim()) : '';
    let convertFromValue;
    let toValue;

    if (valueTrim?.length <= 1 && !numberUtils.isNumber(valueTrim)) {
      document.getElementsByName(name)[0].value = '';

      return;
    }

    let newInformation = { ...information };
    let newValidation = { ...validation };

    if (!valueTrim) {
      newValidation = {
        ...newValidation,
        [FX_CALCULATOR_FORM_CONVERT_FROM_NAME]: '',
        [FX_CALCULATOR_FORM_TO_NAME]: '',
      };
      setValidation(newValidation);

      document.getElementsByName(
        FX_CALCULATOR_FORM_CONVERT_FROM_NAME
      )[0].value = '';
      document.getElementsByName(FX_CALCULATOR_FORM_TO_NAME)[0].value = '';

      newInformation = {
        ...newInformation,
        [FX_CALCULATOR_FORM_CONVERT_FROM_NAME]: '',
        [FX_CALCULATOR_FORM_TO_NAME]: '',
      };
      handleOnChangeDebounce(newInformation, setInformation);

      return;
    }

    if (!numberUtils.isNumber(valueTrim)) {
      valueTrim = valueTrim.match(AMOUNT_NUMBER_REGEX)[0];

      document.getElementsByName(name)[0].value = valueTrim;
    }

    if (value.includes(' ')) {
      document.getElementsByName(name)[0].value = valueTrim;
    }

    if (isConvertFromField) {
      [convertFromValue, toValue] = handleConvertFromOnChange(valueTrim);

      newInformation = {
        ...newInformation,
        [FX_CALCULATOR_FORM_CONVERT_FROM_NAME]: convertFromValue,
        [FX_CALCULATOR_FORM_TO_NAME]: toValue,
      };
    }

    if (isToField) {
      [convertFromValue, toValue] = handleToOnChange(valueTrim);

      newInformation = {
        ...newInformation,
        [FX_CALCULATOR_FORM_CONVERT_FROM_NAME]: convertFromValue,
        [FX_CALCULATOR_FORM_TO_NAME]: toValue,
      };
    }

    if (isConvertFromField) {
      newValidation = handleConvertFromAmountValidation(
        newInformation,
        newValidation
      );
    }

    if (isToField) {
      newValidation = handleToAmountValidation(newInformation, newValidation);
    }

    setValidation(newValidation);

    handleOnChangeDebounce(newInformation, setInformation);
  };

  const handleFormLabelOnClick = (e, name) => {
    e.preventDefault();
    const inputEle = document.getElementsByName(name)[0];

    if (inputEle) {
      inputEle.focus();
    }
  };

  return (
    <FormGroupStyled>
      <FormLabel onClick={(e) => handleFormLabelOnClick(e, field.name)}>
        {t(field.label)}
        {field.isRequired && <span>*</span>}
      </FormLabel>
      <FormControl
        type={field.type}
        inputMode="decimal"
        autoComplete="off"
        name={field.name}
        defaultValue={
          information && information[field.name]
            ? format.toAmountCentStr(information[field.name])
            : ''
        }
        placeholder={field.placeholder}
        isInvalid={validation[field.name]}
        onChange={handleOnChange}
        readOnly={field.isReadOnly}
        style={{
          color: isConvertFromField
            ? 'var(--ds-c-blue)'
            : 'var(--ds-c-blue)',
        }}
      />
      {isConvertFromField && (
        <>
          {currentTab === 0 ? (
            <CountryName><FlagIcon src={CURRENCY_FLAG_REFERENCES['AUD']} />AUD</CountryName>
          ) : (
            <CustomCurrencyDropdown
              setRate={setRate}
              currentTab={currentTab}
              currencies={currencies}
              information={information}
              setInformation={setInformation}
            />
          )}
        </>
      )}
      {isConvertFromField && currentTab === 1 && Boolean(rate) && (
        <Rate>{`*1 AUD = ${format.toAmountStr2(rate)} ${currencyCode}`}</Rate>
      )}
      {isToField && (
        <>
          {currentTab === 0 ? (
            <CustomCurrencyDropdown
              setRate={setRate}
              currentTab={currentTab}
              currencies={currencies}
              information={information}
              setInformation={setInformation}
            />
          ) : (
            <CountryName><FlagIcon src={CURRENCY_FLAG_REFERENCES['AUD']} />AUD</CountryName>
          )}
        </>
      )}
      {isToField && currentTab === 0 && Boolean(rate) && (
        <Rate>{`1 AUD = ${format.toAmountStr2(rate)} ${currencyCode}`}</Rate>
      )}
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[field.name])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 24px;
  position: relative;

  @media screen and (width: ${FX_CALCULATOR_DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 24px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--c-fxcalculator-text);

  span {
    color: var(--c-required);
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  color: var(--c-calculator-input);

  height: 50px;
  border: 1px solid var(--ds-c-black);
  border-radius: 12px;
  padding-inline: 16px;
  padding-right: 132px;
  background: var(--ds-c-white);

  &:focus {
    background: var(--ds-c-white) !important;
    border: 1px solid var(--c-calculator-border) !important;
    color: var(--c-calculator-input);
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-inline: 10px !important;
  }

  &[readonly] {
    background: var(--ds-c-white) !important;
    cursor: not-allowed;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-size: 28px;
    font-weight: 700;
    line-height: 35px;
    text-transform: none;

    color: var(--ds-c-grey-hover);
  }
`;
const Rate = styled.span`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue-hyperlink-default);

  position: absolute;
  top: 0px;
  right: 0px;
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (width: ${FX_CALCULATOR_DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const CountryName = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: var(--ds-c-black);

  width: 122px;
  height: 50px;
  background-color: transparent;
  justify-content: flex-end;
  align-items: center;
  padding-right: 16px;
  display: flex;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
`;

const FlagIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  object-fit: cover;
  border: 1px solid var(--ds-bg-2);
  border-radius: 50%;
  margin-right: 8px;
`;

export default FormInputAmountValidation;
