import { backgroundReference, colorReference } from 'constants';
import React from 'react';
import styled from 'styled-components';

const AddTxnStep4Icon = ({
  status = 'disable' || 'in-progress' || 'completed',
}) => {
  return (
    <SvgStyled $backgroundColor={backgroundReference[status]}>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.4289 0.833344C6.92369 0.833344 7.32175 1.2339 7.32175 1.73178V3.22918H12.6789V1.73178C12.6789 1.2339 13.077 0.833344 13.5718 0.833344C14.0665 0.833344 14.4646 1.2339 14.4646 1.73178V3.22918H15.9527C17.266 3.22918 18.3337 4.30356 18.3337 5.62501V7.50001H1.66699V5.62501C1.66699 4.30356 2.7347 3.22918 4.04794 3.22918H5.53604V1.73178C5.53604 1.2339 5.93411 0.833344 6.4289 0.833344Z"
          fill={colorReference[status]}
        />
        <path
          d="M1.66699 8.75001V17.6042C1.66699 18.9256 2.7347 20 4.04794 20H15.9527C17.266 20 18.3337 18.9256 18.3337 17.6042V8.75001H1.66699Z"
          fill={colorReference[status]}
        />
        <path
          d="M13.906 11.9515L9.73935 16.1442C9.38965 16.4961 8.82417 16.4961 8.47819 16.1442L6.09724 13.7484C5.74754 13.3965 5.74754 12.8275 6.09724 12.4793C6.44694 12.1312 7.01242 12.1275 7.3584 12.4793L9.10691 14.2388L12.6411 10.6825C12.9908 10.3306 13.5563 10.3306 13.9023 10.6825C14.2483 11.0344 14.252 11.6034 13.9023 11.9515H13.906Z"
          fill="white"
        />
      </svg>
    </SvgStyled>
  );
};

const SvgStyled = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

export default AddTxnStep4Icon;
