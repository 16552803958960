import BrowsersIcon from 'assets/icons/browsers-icon.png';
import DeviceSettingsIcon from 'assets/icons/device-settings.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import useLang from 'hooks/useLang';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const AllowCameraAccessPopupMobile = ({ isOpen, onClose }) => {
  const { t } = useLang();
  return (
    <>
      <style>
        {`.table-estimated-backdrop {
          background: #212121;
          opacity: 0.25!important;
          display: initial!important;
        }`}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={onClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="table-estimated-backdrop"
        centered
      >
        <ModalBody>
          <Title>{t('registration_allow_camera_access')}</Title>
          <Paragraph>{t('registration_allow_camera_access_intro')}</Paragraph>

          <Paragraph>
            <StepText>{t('label_step')} 1: </StepText>
            {t('registration_allow_camera_access_step_1')}
          </Paragraph>

          <Image src={DeviceSettingsIcon} />

          <Paragraph>
            <StepText>{t('label_step')} 2: </StepText>
            {t('registration_allow_camera_access_step_2')}
          </Paragraph>

          <Image src={BrowsersIcon} />

          <Paragraph>
            <StepText>{t('label_step')} 3: </StepText>
            {t('registration_allow_camera_access_step_3')}
          </Paragraph>
        </ModalBody>
        <ButtonWrap>
          <ButtonCommon
            color="var(--c-tertiary)"
            background="var(--ds-c-white)"
            value={t('button_close')}
            borderNameVariable="--border-secondary"
            styles={{
              border: 'var(--border-primary)',
              width: '150px',
              minHeight: '40px',
            }}
            onClick={onClose}
            isFill={false}
          />
        </ButtonWrap>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    max-width: fit-content;
    tranform: none;
  }

  & .modal-content {
    padding: 16px 16px 0px 16px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    max-width: 480px;
    height: 420px;
    max-height: calc(vh - 80px);
    margin-inline: auto;
    border: none;
    overflow-y: scroll;
  }
`;
const ModalBody = styled(Modal.Body)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  padding: 0;
  margin-bottom: 0px;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: var(--ds-c-blue);
  text-transform: uppercase;
  text-align: center;
  margin: 5px 0 15px 0;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
`;

const StepText = styled.span`
  font-weight: 700;
`;

const Image = styled.img`
  height: 45px;
  margin-bottom: 15px;
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-height: 72px;
  width: 100%;
  background: #fff;
  position: sticky;
  bottom: 0px;
`;

export default AllowCameraAccessPopupMobile;
