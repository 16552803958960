/* eslint-disable no-unused-vars */
import CalculatorModal from 'components/fx/CalculatorModal';
import {
  NAVBAR_FOOTER_ACTIONS,
  ORDER_EMAIL,
  RECEIVERS_ADD_SUFIX,
  TABLET_MIN_WIDTH,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import { NAV_MENUS_MOBILE_AUTHENTICATED } from 'constants/header/ver2';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import { FX_MAKE_PAYMENT_KEY } from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useLang from 'hooks/useLang';
import { Fragment, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import BackdropCommon from '../../BackdropCommon';
import AddIcon from '../../Icons/AddIcon';
import ArrowDownIcon from '../../Icons/ArrowDownIcon';

const NavbarFooter = () => {
  const { t } = useLang();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [isOpenActions, setOpenActions] = useState(false);
  const [isShowCalculatorModal, setShowCalculatorModal] = useState(false);

  const { resetAddTransactionInformation } = useFxFormAddTransactionStore();

  const isNavLinkActive = (href, href2, href3) => {
    return (
      pathname.includes(href) ||
      pathname.includes(href2) ||
      pathname.includes(href3)
    );
  };

  const handleActionButtonOnClick = () => {
    setOpenActions(!isOpenActions);
  };

  const handleOnShowCalculatorModal = () => {
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);
    resetAddTransactionInformation();
    setShowCalculatorModal(true);
  };
  const handleOnCloseCalculatorModal = () => {
    setShowCalculatorModal(false);
  };
  const handleActionItemOnClick = (action) => {
    if (action === NAVBAR_FOOTER_ACTIONS[0].name) {
      Cookies.remove(MAKE_PAYMENT_KEY);
      navigate(`${TRANSACTION_ADD_PREFIX}`);
    }

    if (action === NAVBAR_FOOTER_ACTIONS[1].name) {
      handleOnShowCalculatorModal();
    }

    if (action === NAVBAR_FOOTER_ACTIONS[2].name) {
      navigate(`${RECEIVERS_ADD_SUFIX}`);
    }
  };

  return (
    <>
      {isShowCalculatorModal && (
        <CalculatorModal
          isOpen={isShowCalculatorModal}
          onClose={handleOnCloseCalculatorModal}
        />
      )}

      <NavbarStyled>
        <NavStyled className="me-auto">
          {NAV_MENUS_MOBILE_AUTHENTICATED.slice(0, 2).map((nav, index) => (
            <Fragment key={nav.href || index}>
              {nav.href ? (
                <NavLink
                  to={nav.href}
                  $isActive={isNavLinkActive(nav.href, nav.href2, nav.href3)}
                  $color={'var(--ds-c-blue-dark)'}
                >
                  {isNavLinkActive(nav.href, nav.href2, nav.href3) ? (
                    <>
                      {nav.icon && (
                        <nav.icon size={{ width: 20, height: 20 }} />
                      )}
                    </>
                  ) : (
                    <>
                      {nav.iconOutline && (
                        <nav.iconOutline size={{ width: 20, height: 20 }} />
                      )}
                    </>
                  )}
                  {t(nav.text)}
                </NavLink>
              ) : (
                <NavLink />
              )}
            </Fragment>
          ))}
          <NavLink $isActive={true}>
            <AddIcon
              size={{ width: 44, height: 44 }}
              color="var(--ds-c-grey-dark)"
              strokeWidth="5"
              styles={{
                backgroundColor: 'var(--bg-primary)',
                borderRadius: '50%',
                boxShadow: '0px -2px 2px rgba(0, 0, 0, 0.05)',
              }}
              onClick={handleActionButtonOnClick}
            />
          </NavLink>
          {NAV_MENUS_MOBILE_AUTHENTICATED.slice(2).map((nav, index) => (
            <Fragment key={nav.href || index}>
              {nav.href ? (
                <NavLink
                  to={nav.href}
                  $isActive={isNavLinkActive(nav.href, nav.href2, nav.href3)}
                  $color={'var(--ds-c-blue-dark)'}
                >
                  {isNavLinkActive(nav.href, nav.href2, nav.href3) ? (
                    <>
                      {nav.icon && (
                        <nav.icon size={{ width: 20, height: 20 }} />
                      )}
                    </>
                  ) : (
                    <>
                      {nav.iconOutline && (
                        <nav.iconOutline size={{ width: 20, height: 20 }} />
                      )}
                    </>
                  )}
                  {t(nav.text)}
                </NavLink>
              ) : (
                <NavLink />
              )}
            </Fragment>
          ))}
          {isOpenActions && (
            <BackdropCommon
              styles={{
                background: '#21212140',
              }}
              onClose={() => setOpenActions(false)}
            >
              <ActionWrap>
                {NAVBAR_FOOTER_ACTIONS.map((action, index) => (
                  <ActionItem
                    key={action.icon || index}
                    onClick={() => handleActionItemOnClick(action.name)}
                  >
                    <img src={action.icon} width={24} height={24} alt="" />
                    {t(action.text)}
                    <ArrowDownIcon
                      color="var(--ds-c-grey-dark)"
                      size={{ width: 14, height: 10 }}
                      styles={{
                        transform: 'rotate(270deg)',
                        position: 'absolute',
                        right: '24px',
                      }}
                    />
                  </ActionItem>
                ))}
              </ActionWrap>
            </BackdropCommon>
          )}
        </NavStyled>
      </NavbarStyled>
    </>
  );
};

const NavbarStyled = styled(Navbar)`
  position: fixed;
  bottom: 0px;
  padding: 0;
  background-color: var(--ds-c-white);
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-inline: -24px;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px 12px 0px 0px;
`;

const NavStyled = styled(Nav)`
  height: 60px;
  margin: 0 !important;
  width: 100%;
  justify-content: space-between;

  @media screen and (min-width: ${TABLET_MIN_WIDTH}px) {
    width: unset;
    justify-content: unset;
  }
`;
const NavLink = styled(Link)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: ${(props) => (props.$isActive ? 700 : 400)};
  font-size: 10px;
  line-height: 12px;
  text-transform: capitalize;
  color: ${(props) => props.$color};
  text-align: center;
  text-decoration: none;

  display: grid;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  width: 75px;
  height: 100%;
  transition: 0.3s ease;

  svg {
    margin-inline: auto;

    opacity: ${(props) => (props.$isActive ? 'unset' : 0.5)};
  }

  &:hover {
    color: unset !important;
  }
`;

const ActionWrap = styled.div`
  position: absolute;
  z-index: 40;
  bottom: 0px;
  left: 0px;
  width: 100%;
`;
const ActionItem = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: var(--ds-c-black);
  text-align: start;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 104px;
  background: #ffffff;
  padding-inline: 24px;

  &:first-child {
    // border-bottom: 1px solid #eef2f5;
  }

  & img {
    margin-right: 8px;
  }
`;

export default NavbarFooter;
