/* eslint-disable no-unused-vars */
import { LogUtil } from 'components/common/LogUtil';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { deleteDB, openDB } from 'idb';

const TAG = 'indexed_db';

const IndexedDB = {
  isSupport() {
    const indexedDB =
      (window.indexedDB ||
        window.mozIndexedDB ||
        window.webkitIndexedDB ||
        window.msIndexedDB ||
        window.shimIndexedDB ||
        'indexedDB' in window) &&
      window.innerWidth >= DESKTOP_MIN_WIDTH;

    if (!indexedDB) {
      LogUtil.log(TAG, "IndexedDB doesn't supported!");
      return false;
    }

    return true;
  },
  async open(dbName, storeName, version = 1) {
    return openDB(dbName, version, {
      upgrade(db, oldVersion, newVersion, transaction, event) {
        db.createObjectStore(storeName);
      },
      blocked(currentVersion, blockedVersion, event) {},
      blocking(currentVersion, blockedVersion, event) {},
    });
  },
  async get(dbName, storeName) {
    if (IndexedDB.isSupport()) {
      try {
        const db = await IndexedDB.open(dbName, storeName);

        const tx = db.transaction(storeName, 'readonly');

        const store = tx.objectStore(storeName);

        return await store.getAll();
      } catch (error) {
        console.error(error?.message);
      }

      return null;
    }

    return null;
  },
  async getKeys(dbName, storeName) {
    if (IndexedDB.isSupport()) {
      try {
        const db = await IndexedDB.open(dbName, storeName);

        const tx = db.transaction(storeName, 'readonly');

        const store = tx.objectStore(storeName);

        return await store.getAllKeys();
      } catch (error) {
        console.error(error?.message);
      }

      return null;
    }

    return null;
  },
  async set(dbName, storeName, item) {
    if (!dbName || !storeName || !item) return;

    if (IndexedDB.isSupport()) {
      try {
        const db = await IndexedDB.open(dbName, storeName);

        const tx = db.transaction(storeName, 'readwrite');

        const store = tx.objectStore(storeName);

        store.put(item.value, item.key);

        tx.done;
      } catch (error) {
        console.error(error?.message);
      }
    }
  },
  async delete(dbName) {
    if (IndexedDB.isSupport()) {
      deleteDB(dbName);
    }
  },
};

export default IndexedDB;
