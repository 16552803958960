import { api } from 'api';
import WarnningIcon from 'assets/icons/warnning-red-icon.svg';
import FormInputValidation from 'components/FormResetPassword/components/FormInputValidation';
import {
  checkFormInputValidation,
  checkTokenExpired,
} from 'components/FormResetPassword/func';
import {
  DESKTOP_MIN_WIDTH,
  PASSWORD_LIVE_CHECKS,
  RESET_PASSWORD_FIELDS,
  RESET_PASSWORD_INFORMATION,
  RESET_PASSWORD_PASSWORD_NAME,
} from 'constants';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import ButtonCommon from '../common/ButtonCommon';

import FormAccountActivationExpired from './components/FormAccountActivationExpired';
import FormAccountActivationSuccess from './components/FormAccountActivationSuccess';

const initialField = RESET_PASSWORD_INFORMATION;

const FormAccountActivation = () => {
  const { t } = useLang();
  const { dispatch } = useStore();
  const query = useQuery();
  const token = query.get('token');

  const [information, setInformation] = useState(initialField);
  const [validation, setValidation] = useState(initialField);
  const [isAccountActivationSuccess, setAccountActivationSuccess] =
    useState(false);
  const [isAccountActivationExpired, setAccountActivationExpired] =
    useState(false);
  const [liveCheckStatus, setLiveCheckStatus] = useState({
    0: WarnningIcon,
    1: WarnningIcon,
    2: WarnningIcon,
    3: WarnningIcon,
  });

  useEffect(() => {
    if (checkTokenExpired(token)) {
      setAccountActivationExpired(true);
    }
  }, [token]);

  const handleSubmit = async () => {
    if (checkTokenExpired(token)) {
      setAccountActivationExpired(true);

      return;
    }

    let newInformation = { ...information };
    let newValidation = { ...validation };

    RESET_PASSWORD_FIELDS.forEach((field) => {
      newValidation = checkFormInputValidation(
        information[field.name],
        field,
        newInformation,
        newValidation
      );
    });

    setValidation(newValidation);

    const isAllowSubmit =
      Object.values(information).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowSubmit) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      try {
        const { data } = await api.activate(
          query.get('token'),
          information[RESET_PASSWORD_PASSWORD_NAME]
        );

        if (data?.success) {
          setAccountActivationSuccess(true);

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      } catch (error) {
        console.error(error?.message);

        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }
    }
  };

  if (isAccountActivationExpired) {
    return <FormAccountActivationExpired />;
  }
  if (isAccountActivationSuccess) {
    return <FormAccountActivationSuccess />;
  }
  return (
    <FormAccountActivationWrap>
      <TitleH3>{t('account_activation_text_account_activation')}</TitleH3>
      <Paragraph>{t('reset_password_text_password_requirements')}</Paragraph>
      <PasswordRequires>
        {PASSWORD_LIVE_CHECKS.map((check, index) => (
          <li key={check.id}>
            <img src={liveCheckStatus[index]} alt="" />
            <Paragraph>{t(check.require)}</Paragraph>
          </li>
        ))}
      </PasswordRequires>
      <FormAccountActivationStyled>
        {RESET_PASSWORD_FIELDS.map((field) => (
          <FormInputValidation
            key={field.name}
            field={field}
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            setLiveCheckStatus={setLiveCheckStatus}
          />
        ))}

        <ConfirmSubmit>
          <ButtonCommon
            value={t('button_confirm')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            styles={{
              margin: '0px',
              width: '200px',
            }}
            onClick={handleSubmit}
            isUseKeyDown
            isFill={true}
          />
        </ConfirmSubmit>
      </FormAccountActivationStyled>
    </FormAccountActivationWrap>
  );
};

const FormAccountActivationWrap = styled.div`
  padding: 40px;
  background: var(--ds-c-white);
  box-shadow: 0px 4px 15px rgb(0 0 0 / 12%);
  border-radius: 40px;

  @media screen and (min-width: 1280px) {
    width: 437px;
    height: fit-content;
  }

  @media screen and (min-width: 1440px) {
    width: 517px;
    height: fit-content;
  }
`;

const FormAccountActivationStyled = styled(Form)`
  position: relative;
  width: 100%;
  height: calc(100% - 96px);
`;

const ConfirmSubmit = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

const TitleH3 = styled.h3`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const PasswordRequires = styled.ul`
  margin: 0;
  margin-bottom: 24px;
  padding-left: 0px;

  & li {
    display: flex;
    align-items: center;
    list-style: disc;
    list-style-type: disc;

    & img {
      margin-right: 8px;
    }

    & p {
      margin-bottom: 0px;
    }
  }
`;

export default FormAccountActivation;
