import RadioCheckedIcon from 'assets/icons/radio-checked-icon.svg';
import RadioboxHHMTIcon from 'assets/icons/radiobox-hhmt-icon.svg';
import RadioboxRemoxIcon from 'assets/icons/radiobox-remox-icon.svg';
import { ButtonFormCheck } from 'components/FormEditReceiver/components/FormInputSelectTypeOfReceiverRadio';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { domUtils } from 'utils';

const FILTER_RECEIVER_FAVORITE_NAME = 'filter-receiver-favorite-field';

const FormFavoriteCheckbox = ({ filtered, setFiltered }) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();

  const isFavorited = filtered['favoriteSelected'] === 'yes';
  const isNoneFavorite = filtered['favoriteSelected'] === 'no';
  const isAllFavorite = filtered['favoriteSelected'] === 'all';

  const handleOnChange = (value) => {
    const newFiltered = {
      ...filtered,
      favoriteSelected: value,
    };

    setFiltered(newFiltered);
  };

  const renderFormCheckRadio1 = () => {
    const formCheckRadioClassName1 = FILTER_RECEIVER_FAVORITE_NAME + '1';

    return (
      <>
        <FormCheck
          className={`${formCheckRadioClassName1} favorited ${
            isFavorited && 'active'
          }`}
          inline
          type="radio"
          label={t('button_yes')}
          name={FILTER_RECEIVER_FAVORITE_NAME}
          checked={isFavorited ? true : false}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
          readOnly
          onClick={() => handleOnChange('yes')}
        />
        <ButtonFormCheck
          $width={
            domUtils.getOffsetWidthByClassName(formCheckRadioClassName1) + 44
          }
          onClick={() => handleOnChange('yes')}
        >
          {t('button_yes')}
        </ButtonFormCheck>
      </>
    );
  };

  const renderFormCheckRadio2 = () => {
    const formCheckRadioClassName2 = FILTER_RECEIVER_FAVORITE_NAME + '2';

    return (
      <>
        <FormCheck
          className={`${formCheckRadioClassName2} none-favorite ${
            isNoneFavorite && 'active'
          }`}
          inline
          type="radio"
          label={t('button_no')}
          name={FILTER_RECEIVER_FAVORITE_NAME}
          checked={isNoneFavorite ? true : false}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
          readOnly
          onClick={() => handleOnChange('no')}
        />
        <ButtonFormCheck
          $width={
            domUtils.getOffsetWidthByClassName(formCheckRadioClassName2) + 44
          }
          onClick={() => handleOnChange('no')}
        >
          {t('button_no')}
        </ButtonFormCheck>
      </>
    );
  };

  const renderFormCheckRadio3 = () => {
    const formCheckRadioClassName3 = FILTER_RECEIVER_FAVORITE_NAME + '3';

    return (
      <>
        <FormCheck
          className={`${formCheckRadioClassName3} all-favorite ${
            isAllFavorite && 'active'
          }`}
          inline
          type="radio"
          label={t('button_all')}
          name={FILTER_RECEIVER_FAVORITE_NAME}
          checked={isAllFavorite ? true : false}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
          readOnly
          style={{ marginRight: 0 }}
          onClick={() => handleOnChange('all')}
        />
        <ButtonFormCheck
          $width={domUtils.getOffsetWidthByClassName(formCheckRadioClassName3)}
          onClick={() => handleOnChange('all')}
        >
          {t('button_all')}
        </ButtonFormCheck>
      </>
    );
  };

  return (
    <FormFavoriteCheckboxStyled>
      <Label>{t('receivers_filter_by_favourite_receiver')}</Label>
      <FormCheckWrap>
        {renderFormCheckRadio1()}
        {renderFormCheckRadio2()}
        {renderFormCheckRadio3()}
      </FormCheckWrap>
    </FormFavoriteCheckboxStyled>
  );
};

const FormFavoriteCheckboxStyled = styled.div`
  margin-bottom: 32px;
`;
const Label = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  margin-bottom: 8px;
`;

const FormCheckWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;
const FormCheck = styled(Form.Check)`
  margin: 0;
  margin-right: 44px;

  label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    margin-left: 0px;
    cursor: pointer !important;
    user-select: none;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: ${(props) => `url(${props.$checkboxSrc}) !important`};
    background-position: center !important;
    width: 16px;
    height: 16px;
    cursor: pointer !important;

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }

    &:checked[type='radio'] {
      background-image: url(${RadioCheckedIcon}) !important;
      background-position: center !important;
    }
  }

  &.active {
    label {
      font-weight: 700;
    }
  }
`;

export default FormFavoriteCheckbox;
