import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH, TABLET_MAX_WIDTH } from 'constants';
import { useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { arrayUtils, stringUtils } from 'utils';

const InformPopup = ({
  isOpen,
  imageSrc,
  imagesStyles = {},
  text,
  textStyle = {},
  content,
  alignContent = 'left' || 'center' || 'right',
  buttonLabel1,
  buttonLabel2,
  onClose,
  onClick,
  isBackdropOverlap,
  zIndexBackdropOverlap,
  zIndexModal = 0,
  isClickBackdropToClose = true,
}) => {
  const bodyContent = useMemo(() => {
    if (!arrayUtils.isArrayEmpty(content)) {
      return (
        <ListContent>
          {content.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ListContent>
      );
    }

    return (
      <SubText
        $alignContent={alignContent}
        dangerouslySetInnerHTML={{
          __html: stringUtils.replaceBreakLine(content),
        }}
      />
    );
  }, [content]);
  return (
    <>
      <style>
        {zIndexModal
          ? `
            @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
              .modal {
                z-index: ${zIndexModal || '1101'};
              }
            }
          `
          : ``}
        {isBackdropOverlap
          ? `
            .inform-modal-backdrop {
              z-index: ${zIndexBackdropOverlap || '1100'};
              background: #212121;
              opacity: 0.25!important;
              display: initial!important;
            }
          `
          : `
            .inform-modal-backdrop {
              background: #212121;
              opacity: 0.25!important;
              display: initial!important;
            }
          `}
      </style>
      <ModalStyled
        show={isOpen}
        onHide={isClickBackdropToClose ? () => onClose() : () => {}}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="inform-modal-backdrop"
        centered
      >
        <ModalBody>
          {imageSrc && (
            <ImageWrap>
              <img
                src={imageSrc}
                alt=""
                width={50}
                height={50}
                style={imagesStyles}
              />
            </ImageWrap>
          )}
          {text && (
            <Text
              style={textStyle}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          {content && bodyContent}
        </ModalBody>
        <ModalFooter
          style={{
            justifyContent: !buttonLabel1 && 'center',
          }}
        >
          {buttonLabel1 && (
            <ButtonCommon
              value={buttonLabel1}
              onClick={onClose}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
            />
          )}
          <ButtonCommon
            value={buttonLabel2}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            onClick={onClick}
            isUseKeyDown
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
    & .modal-content {
      padding: 24px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 328px;
      height: fit-content;
      margin-inline: auto;
      border: none;
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 24px;
`;
const ImageWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Text = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: var(--ds-c-blue);
  margin: 0;
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: ${(props) => props.$alignContent};
  white-space: break-spaces;

  color: var(--ds-c-grey-dark);
  margin: 0;

  & a {
    text-decoration: none;
    color: var(--ds-c-blue);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const ListContent = styled.ul`
  margin: 0;
  margin-bottom: 0px;
  padding-left: 24px;

  & li {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    list-style: disc;
    list-style-type: disc;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default InformPopup;
