/* eslint-disable no-unused-vars */
import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import {
  DESKTOP_MIN_WIDTH,
  HHMT_ADD_TRANSACTION_PAYMENT_METHODS,
  REMOX_ADD_TRANSACTION_PAYMENT_METHODS,
} from 'constants';
import { FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME } from 'constants/fx';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { Fragment } from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import { DescValidation } from '../PickupDate';
import PaymentMethodItem from './PaymentMethodItem';
import PaymentMethodItemMobile from './PaymentMethodItem/mobile';

const PaymentMethodsGrid = ({
  information,
  setInformation,
  validation,
  setValidation,
  informationChangeBackup,
  setInformationChangeBackup,
  setOpenDiscountImportantPopup,
  isSelectRequired,
}) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const { isMobile, isTablet } = useDetectDevice();

  const { getPaymentAmountLimit } = useFxFormAddTransactionStore();
  const { getPayLimitRanger, isMakePayment } = useFxAddTransaction();

  const isPaymentMethodDisabled = (paymentMethodName) => {
    const payLimit = getPayLimitRanger()?.[paymentMethodName];
    const amountLimit = getPaymentAmountLimit();

    if (amountLimit > payLimit?.payLimitMax)
      return {
        ...payLimit,
        amountLimit,
      };

    return false;
  };

  const renderPaymentMethodItem = (method) => {
    if (isMobile || isTablet)
      return (
        <PaymentMethodItemMobile
          key={method.method}
          method={method}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          informationChangeBackup={informationChangeBackup}
          setInformationChangeBackup={setInformationChangeBackup}
          setOpenDiscountImportantPopup={setOpenDiscountImportantPopup}
          payLimit={getPayLimitRanger()?.[method.method]}
          isDisabled={isPaymentMethodDisabled(method.method)}
          isSelectRequired={isSelectRequired}
        />
      );

    return (
      <PaymentMethodItem
        key={method.method}
        method={method}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        informationChangeBackup={informationChangeBackup}
        setInformationChangeBackup={setInformationChangeBackup}
        setOpenDiscountImportantPopup={setOpenDiscountImportantPopup}
        payLimit={getPayLimitRanger()?.[method.method]}
        isDisabled={isPaymentMethodDisabled(method.method)}
        isSelectRequired={isSelectRequired}
      />
    );
  };

  const renderNoticeSection = () => {
    if (isMakePayment) return null;
    return (
      <NoticeSection>
        <img src={NoticeIcon} width={50} height={41} />
        <p>{t('fx_add_transaction_pm_notice_desc')}</p>
      </NoticeSection>
    );
  };

  return (
    <>
      {renderNoticeSection()}
      <DescValidation
        $isError={validation[FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME]}
      >
        {t('fx_add_transaction_choose_payment_method')}:
      </DescValidation>
      <PaymentMethodsGridStyled>
        {(isHHMTTheme
          ? HHMT_ADD_TRANSACTION_PAYMENT_METHODS
          : REMOX_ADD_TRANSACTION_PAYMENT_METHODS
        ).map((method) => (
          <Fragment key={method.method}>
            {renderPaymentMethodItem(method)}
          </Fragment>
        ))}
      </PaymentMethodsGridStyled>
    </>
  );
};

const PaymentMethodsGridStyled = styled.div`
  display: flex;
  flex-flow: wrap;
  grid-gap: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    grid-gap: 24px;
  }
`;

const NoticeSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--ds-c-yellow-light);
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;

  & p {
    ${paragraphMixin};
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 666px;
  }
`;

export default PaymentMethodsGrid;
