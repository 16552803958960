import BuyCurrencyIcon from 'assets/ver2/icons/buy-currency-icon.svg';
import TooltipCommon from 'components/common/TooltipCommon';
import TooltipCommonMobile from 'components/common/TooltipCommon/mobile';
import {
  default as TransferenceHHMTIcon,
  default as TransferenceRemoxIcon,
} from 'assets/ver2/icons/fx-transactions-empty-hhmt-icon.svg';
import { ORDER_EMAIL } from 'constants';
import Cookies from 'helpers/cookies';
import { FX_MAKE_PAYMENT_KEY } from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import { useState } from 'react';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';
import ButtonCommon from '../../../common/ButtonCommon';
import CalculatorModal from 'components/fx/CalculatorModal';

const NoTransaction = () => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const { isMobile, isTablet } = useDetectDevice();
  const [isShowCalculatorModal, setShowCalculatorModal] = useState(false);

  const promtoionTxt = t('discount_first_time_fx');
  const hasPromotion = promtoionTxt ? true : false;

  const { resetAddTransactionInformation } = useFxFormAddTransactionStore();

  const handleOnShowCalculatorModal = () => {
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);
    resetAddTransactionInformation();
    setShowCalculatorModal(true);
  };
  const handleOnCloseCalculatorModal = () => {
    setShowCalculatorModal(false);
  };

  return (
    <>
      {isShowCalculatorModal && (
        <CalculatorModal
          isOpen={isShowCalculatorModal}
          onClose={handleOnCloseCalculatorModal}
        />
      )}

      <NoTransactionStyled>
        <img
          src={isHHMTTheme ? TransferenceHHMTIcon : TransferenceRemoxIcon}
          width={isMobile ? 150 : 200}
          height={isMobile ? 150 : 200}
          alt=""
        />
        {hasPromotion ? (
          <>
            <p
              dangerouslySetInnerHTML={{
                __html: t('discount_first_time_fx'),
              }}
            />
            <Promotion>
              {t('discount_first_time_fx_desc')}
              {isMobile || isTablet ? (
                <TooltipCommonMobile
                text={t('label_terms_conditions_text_02')}
                name={''}
                label={''}
              />
              ) : (
                <TooltipCommon
                  text={t('label_terms_conditions_text_02')}
                  isPopover
                  isDangerouslySetInnerHTML={true}
                />
              )}
            </Promotion>
          </>
        ) : (
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: t('fx_no_transaction_desc'),
              }}
            />
          </>
        )}

        {!isMobile && (
          <ButtonCommon
            value={t('fx_buy_currency_label')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              paddingInline: 0,
            }}
            iconSrc={BuyCurrencyIcon}
            onClick={handleOnShowCalculatorModal}
          />
        )}
      </NoTransactionStyled>
    </>

  );
};

const NoTransactionStyled = styled.div`
  display: grid;
  justify-items: center;
  position: relative;
  margin-top: 0px;
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--ds-c-black);

  & span {
    margin-bottom: 24px;
  }

  & p {
    margin-bottom: 0px;
  }
`;

const Promotion = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: var(--ds-c-blue);
  margin: 5px 0 16px 0;

  display: flex;
  align-items: center;
  gap: 3px;
`;

export default NoTransaction;
