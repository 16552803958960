import CustomDatepickerSelected from 'components/common/CustomDatepickerSelected';
import CalendarIcon from 'components/common/Icons/CalendarIcon';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { datePicker } from 'helpers';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

export const TRANSACTION_FILTER_SENDING_DATE_TO_NAME =
  'transaction-filter-sending-date-to';

const CustomFilterSendingDateToDatePicker = ({ filtered, setFiltered }) => {
  const { t } = useLang();
  const { isMobile } = useDetectDevice();

  const { dateFromSelected, dateToSelected } = filtered;

  const [isOpenDatePicker, setOpenDatePicker] = useState(false);

  const handleOnChange = (dateUTC) => {
    const dateStr = datePicker.dateUTCToDateStr(dateUTC);
    document.getElementsByName(
      TRANSACTION_FILTER_SENDING_DATE_TO_NAME
    )[0].value = dateStr;

    const newFiltered = {
      ...filtered,
      dateToSelected: datePicker.dateUTCToDateISO(dateUTC),
    };
    setFiltered(newFiltered);

    setOpenDatePicker(false);
  };

  const handleOnToggleDatePicker = () => {
    setOpenDatePicker(!isOpenDatePicker);
  };

  useEffect(() => {
    if (!dateToSelected) {
      document.getElementsByName(
        TRANSACTION_FILTER_SENDING_DATE_TO_NAME
      )[0].value = '';
    } else {
      document.getElementsByName(
        TRANSACTION_FILTER_SENDING_DATE_TO_NAME
      )[0].value = datePicker.dateUTCToDateStr(new Date(dateToSelected));
    }
  }, [dateToSelected]);

  return (
    <FormGroupStyled>
      <FormLabel>{t('transactions_filter_by_sending_date_to')}</FormLabel>
      <FormControl
        type="text"
        name={TRANSACTION_FILTER_SENDING_DATE_TO_NAME}
        placeholder={t('form_placeholder_calendar')}
        onClick={handleOnToggleDatePicker}
        readOnly
      />
      <CalendarIcon
        name={TRANSACTION_FILTER_SENDING_DATE_TO_NAME}
        color="var(--ds-c-black)"
        size={{ width: 24, height: 24 }}
        onClick={handleOnToggleDatePicker}
        styles={{
          position: 'absolute',
          top: '33px',
          right: '11px',
          cursor: 'pointer',
        }}
      />
      {isOpenDatePicker && (
        <CustomDatepickerSelected
          isOpen={isOpenDatePicker}
          date={dateToSelected}
          minDate={
            dateFromSelected ||
            new Date(new Date().setDate(new Date().getDate() - 365))
          }
          styles={{
            position: isMobile ? 'unset' : 'absolute',
            zIndex: 2,
          }}
          onChange={handleOnChange}
          onClose={handleOnToggleDatePicker}
        />
      )}
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-black);
  display: block;
  margin-bottom: 4px;

  display: block;

  span {
    color: var(--c-required);
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-black);
  height: 44px;
  border: 1px solid var(--ds-c-blue-remox-light);
  border-radius: 8px;
  padding-right: 8px;
  background: var(--ds-c-white);
  cursor: pointer;

  &:focus {
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &[readonly] {
    background: var(--ds-c-white);
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-blue-disabled);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &[type='date']::-webkit-calendar-picker-indicator {
    background: none !important;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default CustomFilterSendingDateToDatePicker;
