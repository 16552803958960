import IframeResizer from '@iframe-resizer/react';
import CloseIcon from 'assets/ver2/icons/close-white-icon.svg';
import { DESKTOP_MIN_WIDTH, SCREEN_ID_REFERENCE_PATH } from 'constants';
import { Fade } from 'helpers/animation';
import useAuth from 'hooks/useAuth';
import { isEmpty } from 'lodash';
import { useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const EventIfamePopup = ({
  isOpen,
  onClose,
  onClick,
  modalStyles,
  modalOptions,
  isBackdropOverlap,
  zIndexBackdropOverlap,
  zIndexModal = 0,
}) => {
  const { token } = useAuth();
  const navigate = useNavigate();

  const [isHidden, setHidden] = useState(true);
  const [isShowCloseBtn, setShowCloseBtn] = useState(false);

  const iframeRef = useRef(null);

  const { iframeSrc, action1Name, action2Name } = modalOptions || {};

  const onReady = () => {
    iframeRef.current.sendMessage({ token });
  };

  const onMessage = (data) => {
    console.log('EVENT MESSAGE xxxx data ::: ', data);
    const { message: eventMessage } = data || {};
    const { message, type } = eventMessage || {};
    const { action, screenId, success } = message || {};

    if (!isEmpty(action1Name) && action === action1Name) {
      onClose();
    }
    if (!isEmpty(action2Name) && action === action2Name) {
      onClick();
    }

    if (screenId) {
      navigate(SCREEN_ID_REFERENCE_PATH[screenId]);
    }

    if (!success) {
      setShowCloseBtn(true);
    }
  };

  const onResized = () => {
    setHidden(false);
  };

  const renderCloseButton = () => {
    if (!isShowCloseBtn) return null;
    return (
      <CloseIconStyled
        src={CloseIcon}
        width={20}
        height={20}
        onClick={onClose}
      />
    );
  };

  return (
    <>
      <style>
        {zIndexModal
          ? `
            .modal {
              z-index: ${zIndexModal || 1101};
            }
        `
          : ``}
        {isBackdropOverlap
          ? `
          .event-iframe-modal-backdrop {
            z-index: ${zIndexBackdropOverlap || 1100}!important;
            background: #212121;
            opacity: 0.25!important;
            display: initial!important;
          }
        `
          : `
        .event-iframe-modal-backdrop {
          background: #212121;
          opacity: 0.25!important;
          display: initial!important;
        }
      `}
      </style>
      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="event-iframe-modal-backdrop"
        centered
        $styles={modalStyles}
        $isHidden={isHidden}
      >
        {renderCloseButton()}
        <IframeStyled
          license="GPLv3"
          forwardRef={iframeRef}
          src={iframeSrc}
          waitForLoad={false}
          checkOrigin={false}
          onReady={onReady}
          onMessage={onMessage}
          onResized={onResized}
          $styles={modalStyles}
          loading="lazy"
        />
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
    & .modal-content {
      padding: 0px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      margin-inline: auto;
      border: none;
      width: calc(100vw - 32px);
      border-radius: ${(props) => props.$styles?.borderRadius}px!important;
      opacity: ${(props) => props.$isHidden && '0'};
      ${(props) =>
        !props.$isHidden &&
        `
        ${Fade}
      `}
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & .modal-dialog {
      & .modal-content {
        width: ${(props) => props.$styles?.width}px;
      }
    }
  }
`;

const IframeStyled = styled(IframeResizer)`
  width: 100%;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  max-height: 80vh;
  border-radius: ${(props) => props.$styles?.borderRadius}px;
`;

const CloseIconStyled = styled.img`
  position: absolute;
  top: 8px;
  right: 8px;
  background: #000;
  border-radius: 50%;
  cursor: pointer;
`;

export default EventIfamePopup;
