import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import styled from 'styled-components';
import FXTopRateItem from '../FXTopRateItem';

const FXRatesSection = ({ FXRatesData }) => {
  const { t } = useLang();

  if (!FXRatesData) {
    return null;
  }
  return (
    <Wrapper>
      <RatesWrap>
        {FXRatesData.map((rate) => (
          <>
            <FXTopRateItem
              key={rate.currency}
              currency={rate.currencyCode}
              rate={parseFloat(rate.rate)}
              rateData={rate}
            />
          </>
        ))}
      </RatesWrap>
      {t('label_buy_rate_per_aud_full')}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  height: fit-content;

  font-family: var(--ff-primary);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--ds-c-black);

  padding-bottom: 40px;
`;

const RatesWrap = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 10px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    gap: 21px;
  }
`;

export default FXRatesSection;
