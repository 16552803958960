import {
  DESKTOP_MIN_WIDTH,
  MOBILE_MAX_WIDTH,
  MOBILE_MIN_WIDTH,
  MOBILE_SMALL_MAX_WIDTH,
  MOBILE_SMALL_MIN_WIDTH,
  TABLET_MAX_WIDTH,
  TABLET_MIN_WIDTH,
} from 'constants';
import { useEffect, useMemo, useState } from 'react';

const isMobile = (width) => {
  return width >= MOBILE_MIN_WIDTH && width < MOBILE_MAX_WIDTH;
};
const isSmallMobile = (width) => {
  return width >= MOBILE_SMALL_MIN_WIDTH && width <= MOBILE_SMALL_MAX_WIDTH;
};

const isTablet = (width) => {
  return width >= TABLET_MIN_WIDTH && width < TABLET_MAX_WIDTH;
};

const isDesktop = (width) => {
  return width >= DESKTOP_MIN_WIDTH;
};

export function useDetectDevice() {
  const [windowSize, setWindowSize] = useState({
    width: window?.innerWidth,
    height: window?.innerHeight,
  });

  const isMobileCSR = useMemo(
    () => {
      return isMobile(windowSize?.width);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowSize?.width]
  );
  const isSmallMobileCSR = useMemo(
    () => {
      return isSmallMobile(windowSize?.width);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowSize?.width]
  );

  const isTabletCSR = useMemo(
    () => {
      return isTablet(windowSize?.width);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowSize?.width]
  );

  const isDesktopCSR = useMemo(
    () => {
      return isDesktop(windowSize?.width);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowSize?.width]
  );

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isMobile: isMobileCSR,
    isSmallMobile: isSmallMobileCSR,
    isTablet: isTabletCSR,
    isDesktop: isDesktopCSR,
    windowSize,
  };
}
