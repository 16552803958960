import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { TimerIcon } from 'constants/icon';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { SET_OPEN_REFRESH_TOKEN_POPUP } from 'store/action';
import styled from 'styled-components';
import { stringUtils } from 'utils';

const COUNTDOWN = 60;

const RefreshTokenPopup = () => {
  const { state, dispatch } = useStore();
  const { isRefreshToken } = state;
  const { logout, refreshToken, isAuthenticated } = useAuth();
  const { t } = useLang();

  const [countdown, setCountdown] = useState(COUNTDOWN);

  const handleClosePopup = () => {
    dispatch({ type: SET_OPEN_REFRESH_TOKEN_POPUP, payload: false });
  };

  const handleNoActionOnClick = () => {
    if (isAuthenticated) {
      logout();
    }

    handleClosePopup();
  };
  const handleYesActionOnClick = () => {
    if (isAuthenticated) {
      refreshToken(
        () => {
          setCountdown(COUNTDOWN);

          handleClosePopup();
        },
        () => {
          handleNoActionOnClick();
        }
      );
    } else {
      handleClosePopup();
    }
  };

  useEffect(() => {
    let timer;
    if (isRefreshToken) {
      timer = setTimeout(() => {
        setCountdown((count) => count - 1);
      }, 1 * 1000);
    }

    if (countdown === -1) {
      handleNoActionOnClick();
    }

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshToken, countdown]);

  return (
    <ModalStyled
      show={isRefreshToken}
      onHide={() => {}}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <TimeoutImage src={TimerIcon} width={124} height={100} alt="" />
        <Text>{t('label_token_expired_title')}</Text>
        <SubText
          dangerouslySetInnerHTML={{
            __html: stringUtils.replaceBreakLine(
              stringUtils.replaceKeyword(t('label_token_expired_desc'), [
                {
                  key: 'countdown',
                  value: countdown,
                },
              ])
            ),
          }}
        />
      </ModalBody>
      <ModalFooter>
        <ButtonCommon
          value={t('button_no')}
          onClick={handleNoActionOnClick}
          styles={{
            margin: '0px',
            width: '50%',
          }}
          color="var(--ds-c-blue)"
          background="var(--ds-c-white)"
        />
        <ButtonCommon
          value={t('button_yes')}
          onClick={handleYesActionOnClick}
          styles={{
            margin: '0px',
            width: '50%',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
        />
      </ModalFooter>
    </ModalStyled>
  );
};

const ModalStyled = styled(Modal)`
  z-index: 1100;
  & .modal-dialog {
    transform: none;
    & .modal-content {
      padding: 24px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 327px;
      height: fit-content;
      margin-inline: auto;
      border: none;
      z-index: 1100;
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 32px;
`;
const TimeoutImage = styled.img`
  display: block;
  margin-inline: auto;
  margin-bottom: 16px;
  width: 124px;
  height: 100px;
`;
const Text = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: var(--ds-c-grey-dark);
  margin: 0;
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;

  color: var(--ds-c-grey-dark);
  margin: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default RefreshTokenPopup;
