import TooltipCommon from 'components/common/TooltipCommon';
import TooltipCommonMobile from 'components/common/TooltipCommon/mobile';
import { DESKTOP_MIN_WIDTH } from 'constants';
import {
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME,
} from 'constants/fx';
import { address } from 'helpers';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const customFormInputStyleMobile = {
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME]: {
    width: 'calc(50% - 4px)',
    float: 'left',
    marginRight: '4px',
    zIndex: 1,
  },
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME]: {
    width: 'calc(50% - 4px)',
    float: 'left',
    marginLeft: '4px',
    zIndex: 1,
  },
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME]: {
    width: 'calc(50% - 4px)',
    float: 'left',
    marginRight: '4px',
    zIndex: 1,
  },
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME]: {
    width: 'calc(50% - 4px)',
    float: 'left',
    marginRight: '4px',
    zIndex: 1,
    marginBottom: '96px',
  },
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME]: {
    width: 'calc(50% - 4px)',
    float: 'left',
    marginLeft: '4px',
    zIndex: 1,
    marginBottom: '96px',
  },
};

const customFormInputStyle = {
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME]: {
    width: 'calc(50% - 12px)',
    float: 'left',
    marginRight: '12px',
  },
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME]: {
    width: 'calc(50% - 12px)',
    float: 'left',
    marginLeft: '12px',
  },
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME]: {
    width: 'calc(50% - 12px)',
    float: 'left',
    marginRight: '12px',
  },
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME]: {
    width: 'calc(50% - 12px)',
    float: 'left',
    marginRight: '12px',
    marginBottom: '96px',
  },
  [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME]: {
    width: 'calc(50% - 12px)',
    float: 'left',
    marginLeft: '12px',
    marginBottom: '96px',
  },
};

const FormInputValidation = ({
  field,
  information,
  setInformation,
  validation,
  setValidation,
  checkFormInputValidation,
}) => {
  const { t } = useLang();
  const { isMobile, isTablet, isDesktop } = useDetectDevice();

  const isTextColorDisabled = [
    FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME,
    FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME,
  ].includes(field.name);

  const handleOnChange = (e) => {
    const { value, name } = e.target;

    let newInformation = {
      ...information,
      [name]: value,
    };

    newInformation = {
      ...newInformation,
      [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME]:
        newInformation[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME
        ],
      [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME]:
        newInformation[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME
        ],
      [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME]:
        newInformation[
          FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME
        ],
    };

    newInformation = {
      ...newInformation,
      [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME]:
        address.addressCombine(
          newInformation[
            FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME
          ],
          newInformation[
            FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME
          ],
          newInformation[
            FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME
          ],
          newInformation[
            FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME
          ]?.key,
          newInformation[
            FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME
          ],
          newInformation[
            FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME
          ],
          newInformation[
            FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME
          ]
        ),
    };

    setInformation(newInformation);

    let newValidation = checkFormInputValidation(
      newInformation[name],
      field,
      validation
    );

    setValidation(newValidation);
  };

  const handleOnBlur = (e) => {
    handleOnChange(e);
  };

  const handleFormLabelOnClick = (e, name) => {
    e.preventDefault();

    if (isDesktop) {
      const inputEle = document.getElementsByName(name)[0];

      if (inputEle) {
        inputEle.focus();
      }
    }
  };

  return (
    <FormGroupStyled
      style={
        isMobile || isTablet
          ? customFormInputStyleMobile[field.name]
          : customFormInputStyle[field.name]
      }
    >
      <FormLabel onClick={(e) => handleFormLabelOnClick(e, field.name)}>
        {t(field.label)}
        {field.isRequired && <span>*</span>}
        {field.tooltip && (
          <>
            {isMobile || isTablet ? (
              <TooltipCommonMobile
                text={t(field.tooltip)}
                name={field.name}
                label={t(field.label)}
              />
            ) : (
              <TooltipCommon text={t(field.tooltip)} />
            )}
          </>
        )}
      </FormLabel>
      <FormControl
        type={field.type}
        name={field.name}
        value={information ? information[field.name] : ''}
        placeholder={t(field.placeholder)}
        isInvalid={validation[field.name]}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        readOnly={field.isReadOnly}
        $isTextColorDisabled={isTextColorDisabled}
      />
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[field.name])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: ${(props) =>
    props.$isTextColorDisabled
      ? 'var(--ds-c-grey-neutral)'
      : 'var(--ds-c-grey-dark)'};
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: ${(props) =>
    props.$isTextColorDisabled
      ? 'var(--ds-c-grey-disabled)'
      : 'var(--ds-c-white)'};
  cursor: ${(props) => props.$isTextColorDisabled && 'not-allowed'};

  &:focus {
    background: ${(props) =>
      props.$isTextColorDisabled && 'var(--ds-c-grey-disabled)!important'};
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-inline: 10px !important;
  }

  &[readonly] {
    background: ${(props) =>
      props.$isTextColorDisabled && 'var(--ds-c-grey-disabled)!important'};
    border: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputValidation;
