import AddReceiverIcon from 'assets/icons/add-receiver-icon.svg';
import {
  default as FavoriteReceiverHHMTIcon,
  default as FavoriteReceiverRemoxIcon,
} from 'assets/ver2/icons/receivers-empty-hhmt-icon.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import { RECEIVERS_ADD_SUFIX } from 'constants';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

const NoReceiver = () => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const navigate = useNavigate();
  const { isMobile } = useDetectDevice();

  return (
    <NoReceiverStyled>
      <img
        src={isHHMTTheme ? FavoriteReceiverHHMTIcon : FavoriteReceiverRemoxIcon}
        width={isMobile ? 150 : 200}
        height={isMobile ? 150 : 200}
        alt=""
      />
      <span
        dangerouslySetInnerHTML={{ __html: t('receivers_no_receiver_desc') }}
      />
      {!isMobile && (
        <ButtonCommon
          value={t('button_add_new_receiver')}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
          styles={{
            paddingInline: 0,
          }}
          iconSrc={AddReceiverIcon}
          onClick={() => navigate(`${RECEIVERS_ADD_SUFIX}`)}
        />
      )}
    </NoReceiverStyled>
  );
};

const NoReceiverStyled = styled.div`
  display: grid;
  justify-items: center;
  position: relative;

  & span {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: var(--c-sub-info);
    margin-bottom: 24px;
  }
`;

export default NoReceiver;
