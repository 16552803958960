import DiscountTicket from 'components/Discount/components/DiscountTicket';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import styled from 'styled-components';
import OffersEmpty from '../OffersEmpty';
import { useLocation } from 'react-router-dom';
import { TRANSACTION_ADD_PREFIX } from 'constants';
import { arrayUtils } from 'utils';

const DiscountsTabInTxn = ({ itemsActive, itemsInactive, onApplyError }) => {
  const { t } = useLang();
  const { pathname } = useLocation();

  const isCreateTxn = pathname === `${TRANSACTION_ADD_PREFIX}`;

  const isItemsActiveEmpty = arrayUtils.isArrayEmpty(itemsActive);

  const isItemsInactiveEmpty = arrayUtils.isArrayEmpty(itemsInactive);

  if (isItemsActiveEmpty && isItemsInactiveEmpty) {
    return <OffersEmpty offersEmptyTxt={t('label_offers_no_vouchers')} />;
  }
  return (
    <DiscountsTabInTxnStyled $isCreateTxn={isCreateTxn}>
      <DiscountsTabInTxnListStyled>
        {!isItemsActiveEmpty && (
          <>
            <Label>{t('label_available_offers')}</Label>
            {itemsActive?.map((item) => (
              <DiscountTicket
                key={item?.code}
                item={item}
                onApplyError={onApplyError}
              />
            ))}
          </>
        )}
        {!isItemsInactiveEmpty && (
          <>
            {!isItemsActiveEmpty && <Divider />}
            <Label>{t('label_update_transaction_to_use_offers')}</Label>
            {itemsInactive?.map((item) => (
              <DiscountTicket
                key={item?.code}
                item={item}
                inactive
                onApplyError={onApplyError}
              />
            ))}
          </>
        )}
      </DiscountsTabInTxnListStyled>
    </DiscountsTabInTxnStyled>
  );
};

const DiscountsTabInTxnStyled = styled.div`
  width: 100%;
  height: fit-content;
  margin-top: ${(props) => (props.$isCreateTxn ? '0px' : '16px')};

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &:hover {
      &::-webkit-scrollbar {
        width: 5px;
        height: 5px !important;
      }
      &::-webkit-scrollbar-thumb {
        background: var(--ds-c-grey-hover);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
  }
`;

const DiscountsTabInTxnListStyled = styled.div``;

const Label = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--ds-c-grey-dark);

  margin-block: 0px 16px;
`;

const Divider = styled.div`
  border-bottom: 1px solid #eef2f5;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export default DiscountsTabInTxn;
