import { DESKTOP_MIN_WIDTH } from 'constants';
import { NoticeIcon } from 'constants/icon';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Tooltip from 'react-bootstrap/Tooltip';
import styled from 'styled-components';

export const TooltipCommon = ({
  text,
  icon = NoticeIcon,
  percentValue = 0,
  isPopover,
  isDangerouslySetInnerHTML = false,
  isUseImage = true,
}) => {
  const renderPopover = (
    <PopoverStyled>
      <PopoverBody dangerouslySetInnerHTML={{ __html: text }} />
    </PopoverStyled>
  );

  const renderOverlay = () => {
    if (isPopover) return renderPopover;

    if (isDangerouslySetInnerHTML)
      return (
        <Tooltip
          id="button-tooltip-2"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      );

    return <Tooltip id="button-tooltip-2">{text}</Tooltip>;
  };

  return (
    <OverlayTrigger placement="top" overlay={renderOverlay()}>
      {({ ref, ...triggerHandler }) => (
        <ButtonStyled
          variant="light"
          {...triggerHandler}
          className="d-inline-flex align-items-center"
          $isHidden={!isUseImage && !percentValue}
        >
          {isUseImage ? (
            <Image ref={ref} src={icon} />
          ) : (
            <PercentValue ref={ref}>-{percentValue}</PercentValue>
          )}
        </ButtonStyled>
      )}
    </OverlayTrigger>
  );
};

const ButtonStyled = styled(Button)`
  padding: 0;
  margin: 0;
  margin-left: 4px;
  margin-bottom: 0px;
  color: var(--ds-c-white) !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  transition: unset !important;
  cursor: default !important;
  display: ${(props) => props.$isHidden && 'none'};

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    color: var(--ds-c-white) !important;
    background-color: transparent !important;
  }

  &:disabled {
    color: var(--ds-c-white) !important;
    background-color: transparent !important;
    cursor: not-allowed;
  }
`;

const PopoverStyled = styled(Popover)`
  & .popover-arrow::after {
    border-top-color: rgba(33, 33, 33, 0.9);
    border-width: 4px;
  }
  & .popover-arrow::before {
    display: none;
  }
`;
const PopoverBody = styled(Popover.Body)`
  padding: 6px 10px;
  gap: 10px;
  width: fit-content;
  min-width: 292px;
  max-width: 320px;
  height: fit-content;
  background: rgba(33, 33, 33, 0.9);
  border-radius: 4px;
  border: none;

  & p {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;

    padding: 0;
    margin: 0;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 14px;
      line-height: 17px;
    }
  }

  & ul {
    padding: 0;
    padding-left: 16px;
    margin: 0;
    list-style: disc;
    list-style-type: disc;

    & li {
      font-family: var(--ff-primary);
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;

      @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
        font-size: 14px;
        line-height: 17px;
      }

      &::before {
        content: '•';
        color: #ffffff;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
`;

const PercentValue = styled.span`
  font-family: var(--ff-primary);
  font-size: 12px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 15px !important;
  color: #fff;

  display: flex;
  align-items: center;
  padding: 3px 4px;
  border-radius: 10px !important;
  background: var(--ds-c-green-default);
  width: fit-content;
  margin: 0px !important;
`;

export default TooltipCommon;
