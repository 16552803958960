import {
  ExchangeIcon,
  ExchangeIconBlueDark,
  ExchangeOutlineIcon,
  OfferIcon,
  OfferIconBlueDark,
  OfferOutlineIcon,
  ProfileIcon2,
  ProfileIcon2BlueDark,
  ProfileOutlineIcon2,
  SendIcon,
  SendIconBlueDark,
  SendOutlineIcon,
} from 'components/common/Icons';
import {
  DASHBOARD_PREFIX,
  FX_DASHBOARD_PREFIX,
  OFFERS_PREFIX,
  PROFILE_PREFIX,
  TRANSACTIONS_PREFIX,
  RECEIVERS_PREFIX,
  FX_TRANSACTIONS_PREFIX,
} from 'constants/router';

export const NAV_MENUS_AUTHENTICATED = [
  {
    icon: SendIcon,
    iconOutline: SendOutlineIcon,
    text: 'transactions_nav_label',
    href: `${DASHBOARD_PREFIX}`,
    href2: `${TRANSACTIONS_PREFIX}`,
    href3: `${RECEIVERS_PREFIX}`,
  },
  {
    icon: ExchangeIcon,
    iconOutline: ExchangeOutlineIcon,
    text: 'fx_nav_label',
    href: `${FX_DASHBOARD_PREFIX}`,
    href2: `${FX_TRANSACTIONS_PREFIX}`,
  },
  {
    icon: OfferIcon,
    iconOutline: OfferOutlineIcon,
    text: 'offer_nav_label',
    href: `${OFFERS_PREFIX}`,
  },
  {
    icon: ProfileIcon2,
    iconOutline: ProfileOutlineIcon2,
    text: 'profile_title',
    href: `${PROFILE_PREFIX}`,
  },
];

export const NAV_MENUS_MOBILE_AUTHENTICATED = [
  {
    icon: SendIconBlueDark,
    iconOutline: SendIconBlueDark,
    text: 'transactions_nav_label',
    href: `${DASHBOARD_PREFIX}`,
    href2: `${TRANSACTIONS_PREFIX}`,
    href3: `${RECEIVERS_PREFIX}`,
  },
  {
    icon: ExchangeIconBlueDark,
    iconOutline: ExchangeIconBlueDark,
    text: 'fx_nav_label',
    href: `${FX_DASHBOARD_PREFIX}`,
    href2: `${FX_TRANSACTIONS_PREFIX}`,
  },
  {
    icon: OfferIconBlueDark,
    iconOutline: OfferIconBlueDark,
    text: 'offer_nav_label',
    href: `${OFFERS_PREFIX}`,
  },
  {
    icon: ProfileIcon2BlueDark,
    iconOutline: ProfileIcon2BlueDark,
    text: 'profile_title',
    href: `${PROFILE_PREFIX}`,
  },
];
