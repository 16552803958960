/* eslint-disable no-unused-vars */
import ButtonCommon from 'components/common/ButtonCommon';
import Drawer from 'components/common/Drawer';
import {
  FX_ADD_TRANSACTION_ADDRESS_FINDER_FIELD,
  FX_ADD_TRANSACTION_PICKUP_LOCATION,
  PICKUP_LOCATION_STATES,
  PICKUP_LOCATION_TYPE_1,
  PICKUP_LOCATION_TYPE_2,
  RESET_FX_ADD_TRANSACTION_PICKUP_LOCATION,
} from 'constants/fx';
import { Fade } from 'helpers/animation';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CustomAddressFinderDropdown from '../CustomAddressFinderDropdown';
import CustomPickupLocationAccordion from '../CustomPickupLocationAccordion';
import HorizontalSwiper from '../HorizontalSwiper';

const SelectPickupLocationDrawer = ({
  information,
  setInformation,
  pickupLocationData,
  pickupLocationType,
  NavBarToggle,
}) => {
  const { t } = useLang();

  const [pickupLocationDataByAusPost, setPickupLocationDataByAusPost] =
    useState(null);
  const [currentStateSelected, setCurrentStateSelected] = useState(
    PICKUP_LOCATION_STATES[0]
  );
  const [error, setError] = useState('');

  const drawerRef = useRef();

  const {
    setPickupLocation,
    pickupLocationSelected,
    pickupLocationType: pickupLocationTypeStore,
    setPickupLocationType,
  } = useFxFormAddTransactionStore();

  const getCurrentPickupLocationSelected = () => {
    const currentLocation = information[FX_ADD_TRANSACTION_PICKUP_LOCATION];
    if (
      pickupLocationTypeStore === PICKUP_LOCATION_TYPE_1 &&
      !isEmpty(currentLocation)
    ) {
      const currentEALocation = pickupLocationData?.find(
        (p) => p?.code === currentLocation?.code
      );
      if (!isEmpty(currentEALocation)) return currentEALocation;
      return null;
    }
    if (
      pickupLocationTypeStore === PICKUP_LOCATION_TYPE_2 &&
      !isEmpty(currentLocation)
    ) {
      const currentAusLocation = pickupLocationDataByAusPost?.find(
        (p) => p?.code === currentLocation?.code
      );
      if (!isEmpty(currentAusLocation)) return currentAusLocation;
      return null;
    }
    return currentLocation;
  };
  const currentPickupLocationSelected = getCurrentPickupLocationSelected();
  const isApplyButtonDisable = isEmpty(currentPickupLocationSelected);

  useEffect(() => {
    if (isEmpty(pickupLocationSelected)) return;
    const currentStateSelectedStore =
      PICKUP_LOCATION_STATES.find(
        ({ value }) => value === pickupLocationSelected?.state
      ) || PICKUP_LOCATION_STATES[0];
    setCurrentStateSelected(currentStateSelectedStore);
  }, [pickupLocationSelected]);

  useEffect(() => {
    if (pickupLocationTypeStore !== PICKUP_LOCATION_TYPE_1) return;
    if (isEmpty(pickupLocationData)) return;
    if (!isEmpty(currentPickupLocationSelected)) return;

    const currentPickupLocationSelectedExistInCurrentStateSelected =
      currentStateSelected?.value === pickupLocationSelected?.state;
    if (currentPickupLocationSelectedExistInCurrentStateSelected) {
      const newInformation = {
        ...information,
        [FX_ADD_TRANSACTION_PICKUP_LOCATION]: pickupLocationSelected,
      };
      setInformation(newInformation);
    }
  }, [
    pickupLocationData,
    pickupLocationSelected,
    information,
    currentStateSelected,
  ]);

  const handleOnClickCloseButton = () => {
    setPickupLocationDataByAusPost(null);
    setError('');
    if (pickupLocationTypeStore && isEmpty(pickupLocationSelected)) {
      setPickupLocationType('');
      return;
    }
    if (isEmpty(pickupLocationSelected)) {
      setInformation(RESET_FX_ADD_TRANSACTION_PICKUP_LOCATION);
      setCurrentStateSelected(PICKUP_LOCATION_STATES[0]);
    }
  };

  const handleOnApplyPickupLocation = () => {
    setPickupLocation(information);
    setPickupLocationDataByAusPost(null);
    setError('');
    drawerRef?.current?.handleOnToggleNavbar();
  };
  const renderActionsButton = () => {
    return (
      <ActionsWrap>
        <ButtonCommon
          value={t('button_ok')}
          color={
            isApplyButtonDisable
              ? 'var(--ds-c-blue-disabled)'
              : 'var(--c-primary)'
          }
          background={
            isApplyButtonDisable ? 'var(--ds-bg-2)' : 'var(--bg-primary)'
          }
          isFill={true}
          styles={{
            margin: '0px',
            width: '100%',
            cursor: isApplyButtonDisable && 'not-allowed',
          }}
          onClick={
            isApplyButtonDisable ? () => {} : handleOnApplyPickupLocation
          }
        />
      </ActionsWrap>
    );
  };
  const renderPickupLocationByEAContentBody = () => {
    if (isEmpty(pickupLocationData)) return null;
    const pickupLocationDataFilterByStateCode = pickupLocationData.filter(
      (item) => item?.state === currentStateSelected?.value
    );
    return (
      <ContentBodyWrap>
        <HorizontalSwiper
          data={PICKUP_LOCATION_STATES}
          information={information}
          setInformation={setInformation}
          currentSelected={currentStateSelected}
          setCurrentSelected={setCurrentStateSelected}
          styles={{
            marginInline: '16px',
          }}
        />
        <PickupLocationList>
          <CustomPickupLocationAccordion
            pickupLocationData={pickupLocationDataFilterByStateCode}
            information={information}
            setInformation={setInformation}
          />
        </PickupLocationList>
        {renderActionsButton()}
      </ContentBodyWrap>
    );
  };
  const renderPickupLocationByAPContentBody = () => {
    const isShowEmptyList = error;
    const pickupLocationDataSearchByStateCode = pickupLocationDataByAusPost;
    return (
      <ContentBodyWrap>
        <SearchPickupLocationWrap>
          <CustomAddressFinderDropdown
            field={FX_ADD_TRANSACTION_ADDRESS_FINDER_FIELD}
            setPickupLocationDataByAusPost={setPickupLocationDataByAusPost}
            setError={setError}
          />
        </SearchPickupLocationWrap>
        {isShowEmptyList ? (
          <EmptyList>{t(error)}</EmptyList>
        ) : (
          <>
            {!isEmpty(pickupLocationDataByAusPost) && (
              <PickupLocationList>
                <CustomPickupLocationAccordion
                  pickupLocationData={pickupLocationDataSearchByStateCode}
                  information={information}
                  setInformation={setInformation}
                />
                {renderActionsButton()}
              </PickupLocationList>
            )}
          </>
        )}
      </ContentBodyWrap>
    );
  };
  const renderContentBody = () => {
    if (pickupLocationType === PICKUP_LOCATION_TYPE_1)
      return renderPickupLocationByEAContentBody();
    return renderPickupLocationByAPContentBody();
  };

  return (
    <Drawer
      ref={drawerRef}
      navTitle={t('label_select_pickup_location')}
      NavBarToggle={NavBarToggle}
      ContentBody={renderContentBody()}
      onClose={handleOnClickCloseButton}
    />
  );
};

const ContentBodyWrap = styled.div`
  width: calc(100% + 32px);
  margin-inline: -16px;
  padding-bottom: 60px;
`;
const PickupLocationList = styled.div``;

const EmptyList = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-red);
  text-transform: initial;

  margin: 0;
  padding: 16px;
  height: fit-content;
  background: #fff;

  ${Fade}
`;

const SearchPickupLocationWrap = styled.div`
  width: 100%;
  height: fit-content;
  margin-top: -16px;
  padding-inline: 16px;
  padding-top: 16px;
`;

const ActionsWrap = styled.div`
  position: absolute;
  bottom: 0px;
  z-index: 3;
  background: var(--ds-c-white);
  width: 100%;
  padding: 16px;
  height: fit-content;
  border-top: 1px solid var(--ds-bg-2);
`;

export default SelectPickupLocationDrawer;
