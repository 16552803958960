/* eslint-disable no-unused-vars */
import LoginIcon from 'assets/ver2/icons/login-icon.svg';
import { preventOnClick } from 'components/common/func';
import SelectPickupLocationDrawer from 'components/fx/components/SelectPickupLocationDrawer';
import { DESKTOP_MIN_WIDTH } from 'constants';
import {
  FX_ADD_TRANSACTION_PICKUP_LOCATION_TYPE,
  PICKUP_LOCATION_TYPE_REFERENCE,
} from 'constants/fx';
import { format } from 'helpers';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useLang from 'hooks/useLang';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';

const PickupLocationTypes = ({ branches, information, setInformation }) => {
  const { t } = useLang();

  const { checkPickupLocationTypeDisable } = useFxFormAddTransactionStore();

  const handleOnClickPickupLocationTypeItem = (e, item, isDisabled) => {
    const { type } = item;

    if (isDisabled) {
      preventOnClick(e);
      return;
    }

    const newInformation = {
      ...information,
      [FX_ADD_TRANSACTION_PICKUP_LOCATION_TYPE]: type,
    };
    setInformation(newInformation);
  };
  const renderPickupLocationTypeItem = (item) => {
    const { type, labelKey, iconSrc, serviceFeeAmount, collect } = item;

    const isDisabled = checkPickupLocationTypeDisable(item);

    return (
      <TypeItemWrap $isDisabled={isDisabled}>
        <li>{t(labelKey, { number: branches?.length })}</li>
        <TypeItem $isDisabled={isDisabled}>
          <SelectPickupLocationDrawer
            information={information}
            setInformation={setInformation}
            pickupLocationData={branches}
            pickupLocationType={type}
            NavBarToggle={
              <>
                <img
                  src={iconSrc}
                  onClick={(e) =>
                    handleOnClickPickupLocationTypeItem(e, item, isDisabled)
                  }
                />
                <ServiceFeeCollectAmountImage
                  className={`${type}-navbar-toggle`}
                  onClick={(e) =>
                    handleOnClickPickupLocationTypeItem(e, item, isDisabled)
                  }
                  $isDisabled={isDisabled}
                >
                  <ServiceFeeCollectAmountWrap>
                    <ServiceFeeText $isDisabled={isDisabled}>{`${t(
                      'label_service_fee'
                    )}: ${format.toAmountCentStr(
                      serviceFeeAmount
                    )} AUD`}</ServiceFeeText>
                    <CollectAmountText $isDisabled={isDisabled}>
                      {`${t(
                        'label_collected_amount'
                      )}: ${format.toAmountCentStr(
                        collect.min
                      )} — ${format.toAmountCentStr(collect.max)} AUD`}
                    </CollectAmountText>
                  </ServiceFeeCollectAmountWrap>
                  <LoginIconWrap>
                    <img src={LoginIcon} />
                  </LoginIconWrap>
                </ServiceFeeCollectAmountImage>
              </>
            }
          />
        </TypeItem>
      </TypeItemWrap>
    );
  };

  return (
    <Wrap>
      {Object.keys(PICKUP_LOCATION_TYPE_REFERENCE).map((typeKey, index) => (
        <Fragment key={index}>
          {renderPickupLocationTypeItem(
            PICKUP_LOCATION_TYPE_REFERENCE[typeKey]
          )}
        </Fragment>
      ))}
    </Wrap>
  );
};

const Wrap = styled.div`
  padding-bottom: 50px;
  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding-bottom: 0px;
  }
`;

const TypeItemWrap = styled.div`
  margin-bottom: 30px;
  ${(props) => props.$isDisabled && 'cursor: not-allowed'};

  &:last-child {
    margin-bottom: 0px;
  }

  & li {
    list-style-type: disc;
    padding-inline: 10px;
    margin-bottom: 8px;
  }

  & img {
    margin-bottom: 16px;
  }
`;
const TypeItem = styled.div`
  padding: 10px;
  box-shadow: 0px 2px 8px 0px #0000001a;
  border-radius: 8px;
  background: ${(props) =>
    props.$isDisabled ? 'rgba(212, 220, 229, 0.3)' : 'var(--ds-c-white)'};

  & button {
    ${(props) => props.$isDisabled && 'cursor: not-allowed!important'};
    ${(props) => props.$isDisabled && 'pointer-events: none!important'};
  }

  & img {
    display: block;
    ${(props) => props.$isDisabled && 'filter: grayscale(1)'};
    ${(props) => props.$isDisabled && 'cursor: not-allowed'};
  }
`;
const ServiceFeeCollectAmountImage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  outline: ${(props) =>
    props.$isDisabled ? 'unset' : '1px dashed var(--ds-c-green-2)'};
  border-radius: 4px;
  background-color: ${(props) =>
    props.$isDisabled
      ? 'var(--ds-c-black-disabled)'
      : 'rgba(157, 245, 196, 0.05)'};
  padding: 6px 10px;
  cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};
`;
const ServiceFeeCollectAmountWrap = styled.div``;
const ServiceFeeText = styled.p`
  ${paragraphMixin};
  font-weight: 600;
  color: ${(props) =>
    props.$isDisabled
      ? 'var(--ds-c-grey-neutral)'
      : 'var(--ds-c-green-default)'};
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 4px;
`;
const CollectAmountText = styled.p`
  ${paragraphMixin};
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  color: ${(props) =>
    props.$isDisabled ? 'var(--ds-c-red)' : 'var(--ds-c-grey-dark)'};
`;
const LoginIconWrap = styled.div`
  & img {
    margin: 0;
  }
`;

export default PickupLocationTypes;
