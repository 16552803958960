import {
  fetchReceiversFiltered,
  getBodyPayloadFiltered,
} from 'components/Receivers/func';
import { BUSINESS_OF_RECEIVER_TYPE, THROTTLE_SEC } from 'constants';
import useAuth from 'hooks/useAuth';
import useDetectEndpointVisibleOnScreen from 'hooks/useDetectEndpointVisibleOnScreen';
import useThrottle from 'hooks/useThrottle';
import { useEffect } from 'react';

const PaginationMobile = ({
  applyFiltered,
  searchValue,
  currentPage,
  setCurrentPage,
  receivers,
  setReceivers,
  currentTab,
  isSearched,
}) => {
  const { token } = useAuth();
  const throttle = useThrottle(THROTTLE_SEC);
  const isIntersecting = useDetectEndpointVisibleOnScreen();

  const fetchReceiversPagination = (page) => {
    try {
      let bodyPayload = getBodyPayloadFiltered(applyFiltered);

      bodyPayload = {
        ...bodyPayload,
        name: isSearched ? searchValue : '',
        ...(currentTab === 1 && { gender: BUSINESS_OF_RECEIVER_TYPE }),
      };

      fetchReceiversFiltered({
        token,
        body: bodyPayload,
        page,
        receivers,
        setReceivers,
        setCurrentPage,
      });
    } catch (error) {
      console.error(error?.message);
    }
  };
  const handleNextPage = (page) => {
    throttle(() => fetchReceiversPagination(page));
  };

  useEffect(() => {
    if (isIntersecting) {
      handleNextPage(currentPage + 1);
    }
  }, [isIntersecting, currentPage]);

  return null;
};

export default PaginationMobile;
