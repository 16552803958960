/* eslint-disable no-unused-vars */
import EventPage from 'pages/EventPage';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const EventAvailableModal = ({ isOpen }) => {
  const [isHideEventForm, setHideEventForm] = useState(false);

  return (
    <ModalStyled
      show={isOpen}
      onHide={() => {}}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      $isHide={isHideEventForm}
    >
      <ModalBody>
        <EventPage setHideEventForm={setHideEventForm} />
      </ModalBody>
    </ModalStyled>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
    & .modal-content {
      padding: 16px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 438px;
      height: fit-content;
      margin-inline: auto;
      opacity: ${(props) => props.$isHide && '0!important'};
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin: 0;
`;

export default EventAvailableModal;
