import { api } from 'api';
import {
  CloseIcon,
  Title,
  TitleCancel,
} from 'components/FormAddTransaction/components/FormEditAddress/mobile/ver2';
import ButtonCommon from 'components/common/ButtonCommon';
import { checkFormInputValidation } from 'components/common/func';
import {
  ADD_EWALLET_EXPIRY_FIELD,
  ADD_EWALLET_EXPIRY_NAME,
  ADD_EWALLET_ID_NAME,
  ADD_EWALLET_NAME,
  ADD_EWALLET_NAME_FIELD,
  ADD_EWALLET_NUMBER_FIELD,
  ADD_EWALLET_NUMBER_NAME,
  DESKTOP_MIN_WIDTH,
  MOBILE_MIN_WIDTH,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
  TABLET_MAX_WIDTH,
} from 'constants';
import { CloseBlueIcon } from 'constants/icon';
import { datePicker } from 'helpers';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import CustomEwalletTypeDropdown from '../CustomEwalletTypeDropdown';
import CustomExpiryDatePicker from '../CustomExpiryDatePicker';
import FormInputEwalletNumberValidation from '../FormInputEwalletNumberValidation';

export const formAddEwalletAccountClassName = 'form-add-ewallet-account';

const initialFormEwallet =
  RESET_EDIT_RECEIVER_INFORMATION_STORE.accountDetails.ewallet;

const FormAddNewEwalletAccount = ({
  isOpen,
  isEdit,
  countryConfig,
  information,
  setInformation,
  ewalletInformation,
  setEwalletInformation,
  ewalletValidation,
  setEwalletValidation,
  onClick,
  onClose,
}) => {
  const { t } = useLang();
  const { isDesktop, isMobile, isTablet } = useDetectDevice();
  const { state } = useStore();
  const { isLoading } = state || {};

  const [ewallets, setEwallets] = useState(null);
  const [currentEwalletSelected, setCurrentEwalletSelected] = useState(null);
  const [accountNumberMaskStr, setAccountNumberMaskStr] = useState('');
  const [ewalletNumberConfig, setEwalletNumberConfig] = useState(null);

  const config = useGetConfig({ countryConfig });
  const { ewalletNumber, ewalletExpire } = config;
  const { ewallets: ewalletsConfig } = countryConfig || {};

  useEffect(() => {
    if (ewalletNumber) {
      setEwalletNumberConfig(ewalletNumber);
    }
  }, [ewalletNumber]);

  const getEwalletNumberConfig = () => {
    const config = ewalletsConfig.find(
      (e) => e.ewalletId === currentEwalletSelected?.id
    );

    if (config) {
      const ewalletNumberConfig = {
        label: config?.ewalletNumberName || null,
        visible: config?.ewalletNumberVisible,
        required: config?.ewalletNumberRequired,
        format: config?.ewalletNumberFormat,
        example: config?.ewalletNumberExample,
        min: config?.ewalletNumberMin,
        max: config?.ewalletNumberMax,
        tooltip: config?.ewalletNumberHint,
      };

      return ewalletNumberConfig;
    }

    return ewalletNumber;
  };
  useEffect(() => {
    if (currentEwalletSelected) {
      const newEwalletNumberConfig = getEwalletNumberConfig();

      setEwalletNumberConfig(newEwalletNumberConfig);
    }
  }, [currentEwalletSelected]);

  const isSaveDisabled =
    isLoading ||
    !currentEwalletSelected ||
    (ewalletNumberConfig?.visible &&
      (!ewalletInformation[ADD_EWALLET_NUMBER_NAME] ||
        ewalletValidation[ADD_EWALLET_NUMBER_NAME])) ||
    (ewalletExpire?.visible &&
      (!ewalletInformation[ADD_EWALLET_EXPIRY_NAME] ||
        ewalletValidation[ADD_EWALLET_EXPIRY_NAME]));

  const checkEwalletExpiryExpired = (dateArr) => {
    const [yyyy, MM, dd] = dateArr;

    const dateUTC = datePicker.getDateUTC(dd, MM - 1, yyyy);

    return datePicker.getExpired(dateUTC);
  };
  const isExpired =
    ewalletExpire?.visible &&
    checkEwalletExpiryExpired(ewalletInformation[ADD_EWALLET_EXPIRY_NAME]);

  useEffect(() => {
    return () => {
      if (!isEdit) {
        setCurrentEwalletSelected(null);

        setEwalletInformation(initialFormEwallet);

        setEwalletValidation(initialFormEwallet);

        setAccountNumberMaskStr('');
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    return () => {
      setEwalletValidation(initialFormEwallet);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEwalletsInCountry = async (countryCode) => {
    try {
      const { data } = await api.getEwallets(countryCode);
      if (data) {
        setEwallets(data);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };
  useEffect(() => {
    if (countryConfig) {
      fetchEwalletsInCountry(countryConfig.countryCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryConfig]);

  const getCurrentEwalletSelectedInitial = async () => {
    if (ewallets) {
      const currentEwallet = ewallets?.find(
        (b) => b.name === ewalletInformation[ADD_EWALLET_NAME]
      );

      if (currentEwallet) {
        setCurrentEwalletSelected(currentEwallet);

        setEwalletInformation(ewalletInformation);
      }
    }
  };
  useEffect(() => {
    if (isEdit && ewallets) {
      getCurrentEwalletSelectedInitial();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, ewallets]);

  const checkEwalletAccountExisted = (
    ewalletExisteds,
    information,
    validation
  ) => {
    const isExisted = ewalletExisteds?.find(
      (i) =>
        i.ewalletProviderName === information[ADD_EWALLET_NAME] &&
        i.ewalletNumber === information[ADD_EWALLET_NUMBER_NAME]
    );

    if (isExisted) {
      validation = {
        ...validation,
        [ADD_EWALLET_NUMBER_NAME]: ADD_EWALLET_NUMBER_FIELD.msgExisted,
      };
    } else {
      validation = {
        ...validation,
        [ADD_EWALLET_NUMBER_NAME]: '',
      };
    }

    return validation;
  };

  const checkEwalletAccountExpired = (validation) => {
    validation = {
      ...validation,
      [ADD_EWALLET_EXPIRY_NAME]: isExpired
        ? ADD_EWALLET_EXPIRY_FIELD.msgExpired
        : '',
    };

    return validation;
  };

  const handleSave = () => {
    let newEwalletInformation = { ...ewalletInformation };
    let newEwalletValidation = { ...ewalletValidation };

    if (!ewalletNumberConfig?.required) {
      delete newEwalletInformation[ADD_EWALLET_NUMBER_NAME];
      delete newEwalletValidation[ADD_EWALLET_NUMBER_NAME];
    }

    if (!ewalletExpire?.required) {
      delete newEwalletInformation[ADD_EWALLET_EXPIRY_NAME];
      delete newEwalletValidation[ADD_EWALLET_EXPIRY_NAME];
    }

    const isAllowSave =
      Object.values(newEwalletInformation).every((value) => value) &&
      Object.values(newEwalletValidation).every((value) => !value) &&
      !isExpired;

    if (isAllowSave) {
      let ewalletAdd = {
        ewalletId: ewalletInformation[ADD_EWALLET_ID_NAME],
        ewalletProviderName: ewalletInformation[ADD_EWALLET_NAME],
        ewalletNumber: ewalletInformation[ADD_EWALLET_NUMBER_NAME],
        expiryDate: ewalletInformation[ADD_EWALLET_EXPIRY_NAME],
      };

      if (isEdit) {
        ewalletAdd = {
          ...ewalletAdd,
          id: ewalletInformation.id,
        };

        onClick && onClick(ewalletAdd);
      } else {
        ewalletAdd = {
          ...ewalletAdd,
          id: ewalletInformation.id || Date.now(),
        };

        onClick && onClick(ewalletAdd);

        const newInformation = {
          ...information,
          ewallets: [...information.ewallets, ewalletAdd],
        };

        setInformation(newInformation);
      }

      onClose();
    }

    if (!isAllowSave) {
      if (!currentEwalletSelected) {
        newEwalletValidation = {
          ...newEwalletValidation,
          [ADD_EWALLET_NAME]: ADD_EWALLET_NAME_FIELD.msgRequired,
        };
      }

      if (ewalletNumberConfig?.required || ewalletExpire?.required) {
        newEwalletValidation = checkFormInputValidation(
          newEwalletInformation[ADD_EWALLET_NUMBER_NAME],
          ADD_EWALLET_NUMBER_FIELD,
          newEwalletValidation
        );

        newEwalletValidation = checkFormInputValidation(
          newEwalletInformation[ADD_EWALLET_EXPIRY_NAME],
          ADD_EWALLET_EXPIRY_FIELD,
          newEwalletValidation
        );

        newEwalletValidation = checkEwalletAccountExisted(
          newEwalletInformation?.ewallets,
          newEwalletInformation,
          newEwalletValidation
        );

        newEwalletValidation = checkEwalletAccountExpired(newEwalletValidation);
      }

      setEwalletValidation(newEwalletValidation);
    }
  };

  const renderActionsButton = () => {
    return (
      <>
        <ButtonCommon
          value={t('button_cancel')}
          onClick={onClose}
          styles={{
            margin: '0px',
            marginRight: '16px',
            width: '80px',
          }}
          color="var(--ds-c-blue)"
          background="var(--ds-c-white)"
        />
        <ButtonCommon
          value={t('button_save')}
          onClick={handleSave}
          styles={{
            margin: '0px',
            width: '80px',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
          isDisabled={isSaveDisabled}
        />
      </>
    );
  };

  const renderActionsButtonMobile = () => {
    return (
      <NextWrap>
        <ButtonCommon
          value={t('button_save')}
          onClick={handleSave}
          styles={{
            margin: '0px',
            width: '100%',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
          isDisabled={isSaveDisabled}
        />
      </NextWrap>
    );
  };

  const renderModalHeaderBody = () => {
    const value = isEdit ? t('popup_edit_ewallet') : t('popup_add_ewallet');

    if (isMobile || isTablet)
      return (
        <TitleCancel>
          <Title>{value}</Title>
          <CloseIcon src={CloseBlueIcon} onClick={onClose} />
        </TitleCancel>
      );

    return value;
  };

  return (
    <>
      <style>
        {`
          @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${
          TABLET_MAX_WIDTH - 1
        }px) {
            .add-ewallet-modal-backdrop {
              top: 70px;
            }

            .modal {
              top: 70px;
              padding-left: 0px;
            }
          }

          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal-backdrop {
              z-index: 1045!important;
            }
          }

          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
            .add-ewallet-modal-backdrop {
              background: #212121;
              opacity: 0.25!important;
              display: initial!important;
            }
          }
        `}
      </style>

      <ModalStyled
        show={isOpen}
        aria-labelledby={isDesktop && 'contained-modal-title-vcenter'}
        className={formAddEwalletAccountClassName}
        backdropClassName="add-ewallet-modal-backdrop"
        centered={isDesktop}
        animation={isDesktop}
      >
        <ModalHeader>{renderModalHeaderBody()}</ModalHeader>
        <ModalBody>
          {ewalletNumberConfig?.visible && (
            <CustomEwalletTypeDropdown
              config={ewalletNumberConfig}
              ewallets={ewallets}
              ewalletExisteds={information?.ewallets}
              information={ewalletInformation}
              setInformation={setEwalletInformation}
              validation={ewalletValidation}
              setValidation={setEwalletValidation}
              currentEwalletSelected={currentEwalletSelected}
              setCurrentEwalletSelected={setCurrentEwalletSelected}
              checkEwalletAccountExisted={checkEwalletAccountExisted}
            />
          )}
          {ewalletInformation[ADD_EWALLET_NAME] &&
            ewalletNumberConfig?.visible && (
              <FormInputEwalletNumberValidation
                field={ADD_EWALLET_NUMBER_FIELD}
                config={ewalletNumberConfig}
                numberMaskStr={accountNumberMaskStr}
                setNumberMaskStr={setAccountNumberMaskStr}
                accountNumberExisteds={information?.ewallets}
                information={ewalletInformation}
                setInformation={setEwalletInformation}
                validation={ewalletValidation}
                setValidation={setEwalletValidation}
                checkAccountExisted={checkEwalletAccountExisted}
              />
            )}
          {ewalletInformation[ADD_EWALLET_NAME] && ewalletExpire?.visible && (
            <CustomExpiryDatePicker
              isConfigRequired={ewalletExpire?.required}
              information={ewalletInformation}
              setInformation={setEwalletInformation}
              validation={ewalletValidation}
              setValidation={setEwalletValidation}
            />
          )}
        </ModalBody>
        <ModalFooter>
          {isDesktop ? renderActionsButton() : renderActionsButtonMobile()}
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
  }

  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    & .modal-dialog {
      position: absolute;
      top: 0px;
      margin: 0;
      height: 100vh;
      width: 100%;
      max-width: 100%;

      & .modal-content {
        padding: 0px 16px;
        border-radius: 0px;
        box-shadow: none;
        background: var(--ds-c-white);
        width: 100%;
        height: calc(100vh - 70px);
        margin-inline: auto;
        border: none;
      }
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & .modal-content {
      padding: 24px;
      border-radius: 12px;
      box-shadow: var(--ds-bs-4);
      background: var(--ds-c-white);
      width: 470px;
      height: fit-content;
      margin-inline: auto;
    }
  }
`;
const ModalHeader = styled(Modal.Header)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #1c3f88;

  padding: 0;
  margin-bottom: 0;
  display: block;
  border: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    color: var(--ds-c-green-default);
    font-weight: 700;
    margin-bottom: 16px;
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 0px;
`;
const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: end;
  margin-inline: -24px;
  padding-inline: 24px;
  padding-block: 16px;
  border-top: 1px solid var(--ds-c-grey-disabled);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding-block: unset;
    padding-inline: unset;
    margin-inline: unset;
    border-top: none;
  }
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  background: var(--ds-c-white);
  margin-block: 0px;
  position: fixed;
  left: 24px;
  bottom: 0;
  z-index: 1;
  border-top: 1px solid var(--ds-c-grey-disabled);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-inline: unset;
    padding-inline: unset;
    height: 40px;
    left: unset;
    border-top: none;
  }
`;

export default FormAddNewEwalletAccount;
