import { api } from 'api';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  checkFormInputPortalCodeValidation,
  checkFormInputValidation,
} from 'components/common/func';
import {
  DESKTOP_MIN_WIDTH,
  EDIT_RECEIVER_POSTAL_NAME,
  EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS,
  EDIT_RECEIVER_STATE_NAME,
  EDIT_RECEIVER_STREET_NAME,
  EDIT_RECEIVER_SUBURB_NAME,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
} from 'constants';
import useReceiver from 'hooks/receiver/useReceiver';
import useAuth from 'hooks/useAuth';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import { Fade } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { SET_EDIT_RECEIVER_INFORMATION, SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import FormInputAddressManuallyValidation from '../FormInputAddressManuallyValidation';
import FormInputAddressSwitch from '../FormInputAddressSwitch';

const initialFormField =
  RESET_EDIT_RECEIVER_INFORMATION_STORE.residentialAddress;

const ResidentialAddress = ({
  countryConfig,
  handleOnToggleCancelPopup,
  handleOnToggleUpdatePopup,
  handleOnToggleCancelCurrentTabPopup,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { currentReceiver } = state;
  const { token } = useAuth();
  const { receiverId } = useParams();

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [cities, setCities] = useState(null);
  const [portalCodeMaskStr, setPortalCodeMaskStr] = useState('');

  const { isEditReceiverEnterAddressManuallySelected } = information;

  const { isReceiverOfIndividualType } = useReceiver();

  const config = useGetConfig({ countryConfig });
  const {
    addressStreet,
    addressState,
    addressSuburb,
    addressPostcode,
    addressManualDisable,
  } = config;

  const isShowAddAddressManual =
    isReceiverOfIndividualType && addressManualDisable;

  const fetchCities = async () => {
    try {
      const { data } = await api.getCities(countryConfig?.countryCode);

      if (data) {
        setCities(data);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    if (currentReceiver) {
      const newInformation = {
        [EDIT_RECEIVER_STREET_NAME]: currentReceiver?.address?.street,
        [EDIT_RECEIVER_STATE_NAME]: currentReceiver?.address?.city,
        [EDIT_RECEIVER_SUBURB_NAME]: currentReceiver?.address?.suburb,
        [EDIT_RECEIVER_POSTAL_NAME]: currentReceiver?.address?.postcode,
        isEditReceiverEnterAddressManuallySelected:
          currentReceiver?.address?.manual,
      };

      setInformation(newInformation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReceiver]);

  useEffect(() => {
    if (countryConfig && !isEditReceiverEnterAddressManuallySelected) {
      fetchCities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryConfig, isEditReceiverEnterAddressManuallySelected]);

  useEffect(() => {
    if (currentReceiver) {
      let newValidation = { ...validation };

      if (addressStreet?.visible && !currentReceiver?.address?.street) {
        newValidation = {
          ...newValidation,
          [EDIT_RECEIVER_STREET_NAME]:
            EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[0].msgRequired,
        };
      }

      if (addressSuburb?.visible && !currentReceiver?.address?.suburb) {
        newValidation = {
          ...newValidation,
          [EDIT_RECEIVER_SUBURB_NAME]:
            EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[1].msgRequired,
        };
      }

      if (addressState?.visible && !currentReceiver?.address?.city) {
        newValidation = {
          ...newValidation,
          [EDIT_RECEIVER_STATE_NAME]:
            EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[2].msgRequired,
        };
      }

      if (addressPostcode?.visible && !currentReceiver?.address?.postcode) {
        newValidation = {
          ...newValidation,
          [EDIT_RECEIVER_POSTAL_NAME]:
            EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[3].msgRequired,
        };
      }

      setValidation(newValidation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReceiver]);

  const isAddressChanged = (information) => {
    if (
      information[EDIT_RECEIVER_STREET_NAME]?.toUpperCase() !==
      currentReceiver?.address?.street?.toUpperCase()
    )
      return true;

    return false;
  };

  const isStateChanged = (information) => {
    if (
      information[EDIT_RECEIVER_STATE_NAME]?.name?.toUpperCase() !==
      currentReceiver?.address?.city?.name?.toUpperCase()
    )
      return true;

    return false;
  };

  const isDistrictChanged = (information) => {
    if (
      information[EDIT_RECEIVER_SUBURB_NAME]?.name?.toUpperCase() !==
      currentReceiver?.address?.suburb?.name?.toUpperCase()
    )
      return true;

    return false;
  };

  const isPostalCodeChanged = (information) => {
    if (!information[EDIT_RECEIVER_POSTAL_NAME]) {
      return false;
    }

    if (
      information[EDIT_RECEIVER_POSTAL_NAME] !==
      currentReceiver?.address?.postcode
    )
      return true;

    return false;
  };

  const checkInformationChanged = (information) => {
    return (
      isAddressChanged(information) ||
      isStateChanged(information) ||
      isDistrictChanged(information) ||
      isPostalCodeChanged(information)
    );
  };

  const updateAddress = async () => {
    const payload = {
      id: currentReceiver.address.id,
      street: information[EDIT_RECEIVER_STREET_NAME]?.toUpperCase(),
      city: information[EDIT_RECEIVER_STATE_NAME],
      suburb: information[EDIT_RECEIVER_SUBURB_NAME],
      country: currentReceiver.address.country,
      fullAddress:
        `${information[EDIT_RECEIVER_STREET_NAME]}, ${information[EDIT_RECEIVER_SUBURB_NAME].name}, ${information[EDIT_RECEIVER_STATE_NAME].name}, ${currentReceiver.address.country.name}`?.toUpperCase(),
      postcode: information[EDIT_RECEIVER_POSTAL_NAME],
      manual: isEditReceiverEnterAddressManuallySelected,
    };

    return api.updateAddress(token, receiverId, payload.id, payload);
  };

  const handleSave = async () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    delete newInformation['isEditReceiverEnterAddressManuallySelected'];
    delete newValidation['isEditReceiverEnterAddressManuallySelected'];

    if (!addressStreet.required) {
      delete newInformation[EDIT_RECEIVER_STREET_NAME];
      delete newValidation[EDIT_RECEIVER_STREET_NAME];
    }

    if (!addressState.required) {
      delete newInformation[EDIT_RECEIVER_STATE_NAME];
      delete newValidation[EDIT_RECEIVER_STATE_NAME];
    }

    if (!addressSuburb.required) {
      delete newInformation[EDIT_RECEIVER_SUBURB_NAME];
      delete newValidation[EDIT_RECEIVER_SUBURB_NAME];
    }

    if (!addressPostcode.required) {
      delete newInformation[EDIT_RECEIVER_POSTAL_NAME];
      delete newValidation[EDIT_RECEIVER_POSTAL_NAME];
    }

    const isAllowSubmit =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);
    if (checkInformationChanged(information)) {
      if (isAllowSubmit) {
        dispatch({ type: SET_SHOW_LOADING, payload: true });

        try {
          await Promise.all([updateAddress()]);

          const newInformation = {
            ...information,
            fullAddress:
              `${information[EDIT_RECEIVER_STREET_NAME]}, ${information[EDIT_RECEIVER_SUBURB_NAME].name}, ${information[EDIT_RECEIVER_STATE_NAME].name}, ${currentReceiver.address.country.name}`?.toUpperCase(),
          };
          setInformation(newInformation);

          handleOnToggleUpdatePopup();

          dispatch({
            type: SET_EDIT_RECEIVER_INFORMATION,
            payload: RESET_EDIT_RECEIVER_INFORMATION_STORE,
          });

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        } catch (error) {
          console.error(error);

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }

        return;
      }

      if (!isAllowSubmit) {
        if (addressStreet.required) {
          newValidation = checkFormInputValidation(
            information[EDIT_RECEIVER_STREET_NAME],
            EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[0],
            newValidation
          );
        }

        if (addressState.required) {
          newValidation = checkFormInputValidation(
            information[EDIT_RECEIVER_STATE_NAME],
            EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[1],
            newValidation
          );
        }

        if (addressSuburb.required) {
          newValidation = checkFormInputValidation(
            information[EDIT_RECEIVER_SUBURB_NAME],
            EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[2],
            newValidation
          );
        }

        if (addressPostcode.required) {
          newValidation = checkFormInputPortalCodeValidation(
            information[EDIT_RECEIVER_POSTAL_NAME],
            EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[3],
            newValidation,
            addressPostcode
          );
        }

        setValidation(newValidation);

        return;
      }
    }

    if (!checkInformationChanged(information) && isAllowSubmit) {
      handleOnToggleCancelCurrentTabPopup();
    }
  };

  return (
    <ResidentialAddressStyled>
      <Title>{t('label_address')}</Title>
      {EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS.map((field) => (
        <FormInputAddressManuallyValidation
          key={field.name}
          field={field}
          countryConfig={countryConfig}
          cities={cities}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          portalCodeMaskStr={portalCodeMaskStr}
          setPortalCodeMaskStr={setPortalCodeMaskStr}
        />
      ))}
      {isShowAddAddressManual && (
        <FormInputAddressSwitch
          information={information}
          setInformation={setInformation}
          setValidation={setValidation}
        />
      )}
      <Fade in appear>
        <NextWrap>
          <ButtonCommon
            value={t('button_cancel')}
            onClick={handleOnToggleCancelPopup}
            styles={{
              margin: '0px',
              marginRight: '16px',
              width: '211px',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
          <ButtonCommon
            value={t('button_save')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: '211px',
            }}
            onClick={handleSave}
            isUseKeyDown
          />
        </NextWrap>
      </Fade>
    </ResidentialAddressStyled>
  );
};

const ResidentialAddressStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 19px;
  padding-inline: 0px;
  padding-bottom: 70px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 40px;
    padding-inline: 116px;
    padding-bottom: unset;
  }
`;

const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 24px;
  }
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: center;
  height: 70px;
  align-items: center;
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  background: var(--ds-c-white);
  z-index: 1;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #eef2f5;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    height: 40px;
    border-top: unset;
  }
`;

export default ResidentialAddress;
