import PlayIcon from 'assets/ver2/icons/play-icon.svg';
import { getItemsConfig } from 'components/common/func';
import {
  ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  DESKTOP_MIN_WIDTH,
  HHMT_ADD_TRANSACTION_PAYMENT_METHODS,
  LINK_REGISTRATION_HHMT_VIDEO_EMBED,
  LINK_REGISTRATION_REMOX_VIDEO_EMBED,
  METHOD_DEPOSIT,
  METHOD_EPAYMENT,
  METHOD_PAYID,
  METHOD_POLI,
  REMOX_ADD_TRANSACTION_PAYMENT_METHODS,
  SURCHARGE_RATE,
} from 'constants';
import useCheckServiceAlerts from 'hooks/service-alerts/useCheckServiceAlerts';
import useServiceAlertsStore from 'hooks/service-alerts/useServiceAlertsStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import { Fragment } from 'react';
import styled from 'styled-components';
import { numberUtils } from 'utils';
import PaymentMethodItem from '../PaymentMethodItem/ver2';
import PaymentMethodItemMobile from '../PaymentMethodItem/ver2/mobile';

const PaymentMethodsGrid = ({
  countryConfig,
  information,
  setInformation,
  validation,
  setValidation,
  informationChangeBackup,
  setInformationChangeBackup,
  setOpenDiscountImportantPopup,
  isSelectRequired,
}) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const { state } = useStore();
  const { addTransactionInformation } = state;
  const { remittance, paymentMethod } = addTransactionInformation || {};

  const { isMobile, isTablet } = useDetectDevice();

  const discountApplied = paymentMethod?.discountApplied;
  const { discountAmount, totalAmountAfterDiscount } = discountApplied || {};
  const isDiscountApplied = Boolean(Number(discountAmount));

  const payLimitConfig =
    countryConfig &&
    getItemsConfig(countryConfig?.payLimits, countryConfig?.countryCode);

  const { serviceAlertsByPayments: serviceAlertsStore } =
    useServiceAlertsStore();
  const { checkByPaymentMethod } = useCheckServiceAlerts();
  const { checkServiceAlertsOfPaymentMethodItemDisable } = checkByPaymentMethod;

  const paymentOptionsConfig =
    countryConfig &&
    getItemsConfig(countryConfig?.paymentOptions, countryConfig?.countryCode);

  const getPayLimitRanger = (payLimitConfig) => ({
    [METHOD_PAYID]: {
      payLimitMin: payLimitConfig['nppMin'],
      payLimitMax: payLimitConfig['nppMax'],
      feesIncluded: payLimitConfig['nppFeesIncluded'],
    },
    [METHOD_POLI]: {
      payLimitMin: payLimitConfig['poliMin'],
      payLimitMax: payLimitConfig['poliMax'],
      feesIncluded: payLimitConfig['poliFeesIncluded'],
    },
    [METHOD_DEPOSIT]: {
      payLimitMin: payLimitConfig['depositMin'],
      payLimitMax: payLimitConfig['depositMax'],
      feesIncluded: payLimitConfig['depositFeesIncluded'],
    },
    [METHOD_EPAYMENT]: {
      payLimitMin: payLimitConfig['epaymentMin'],
      payLimitMax: payLimitConfig['epaymentMax'],
      feesIncluded: payLimitConfig['epaymentFeesIncluded'],
    },
  });

  const isPaymentMethodDisabled = (paymentMethod) => {
    const payLimit = getPayLimitRanger(payLimitConfig)[paymentMethod];

    const sendAmount = numberUtils.mathRound(
      remittance[ADD_TRANSACTION_YOU_SEND_NAME] || 0
    );
    const sendAnotherAmount = numberUtils.mathRound(
      remittance[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] || 0
    );
    const sendTotalAmount = numberUtils.mathRound(
      sendAmount + sendAnotherAmount
    );

    const feeAmount = numberUtils.mathRound(
      remittance?.feeAmount?.feeAmount || 0
    );

    const surchargeAmount =
      paymentMethod && paymentMethod === METHOD_EPAYMENT
        ? isDiscountApplied
          ? numberUtils.mathRound(totalAmountAfterDiscount * SURCHARGE_RATE) ||
            0
          : numberUtils.mathRound(
              numberUtils.mathRound(sendTotalAmount + feeAmount) *
                SURCHARGE_RATE
            )
        : 0;

    const totalPayAmount = numberUtils.mathRound(
      feeAmount + surchargeAmount + sendTotalAmount
    );

    const amountLimit = payLimit?.feesIncluded
      ? totalPayAmount
      : sendTotalAmount;

    if (amountLimit > payLimit?.payLimitMax)
      return {
        ...payLimit,
        amountLimit,
      };

    return false;
  };

  const handleHowToMakePayment = () => {
    window.open(
      isHHMTTheme
        ? t(LINK_REGISTRATION_HHMT_VIDEO_EMBED)
        : t(LINK_REGISTRATION_REMOX_VIDEO_EMBED)
    );
  };

  const renderPaymentMethodItem = (method) => {
    if (isMobile || isTablet)
      return (
        <PaymentMethodItemMobile
          key={method.method}
          countryCode={countryConfig?.countryCode}
          method={method}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          informationChangeBackup={informationChangeBackup}
          setInformationChangeBackup={setInformationChangeBackup}
          setOpenDiscountImportantPopup={setOpenDiscountImportantPopup}
          payLimit={getPayLimitRanger(payLimitConfig)[method.method]}
          isDisabled={
            (payLimitConfig && isPaymentMethodDisabled(method.method)) ||
            checkServiceAlertsOfPaymentMethodItemDisable({
              serviceAlertsStore,
              paymentMethod: method.method,
            })
          }
          isSelectRequired={isSelectRequired}
        />
      );

    return (
      <PaymentMethodItem
        key={method.method}
        countryCode={countryConfig?.countryCode}
        method={method}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        informationChangeBackup={informationChangeBackup}
        setInformationChangeBackup={setInformationChangeBackup}
        setOpenDiscountImportantPopup={setOpenDiscountImportantPopup}
        payLimit={getPayLimitRanger(payLimitConfig)[method.method]}
        isDisabled={
          (payLimitConfig && isPaymentMethodDisabled(method.method)) ||
          checkServiceAlertsOfPaymentMethodItemDisable({
            serviceAlertsStore,
            paymentMethod: method.method,
          })
        }
        isSelectRequired={isSelectRequired}
      />
    );
  };

  return (
    <>
      <Label
        $isSelectRequired={validation[ADD_TRANSACTION_PAYMENT_METHOD_NAME]}
      >
        {t('add_transaction_title_payment_method_desc')}:
      </Label>
      <HowToMakePayment onClick={handleHowToMakePayment}>
        <img src={PlayIcon} alt="" />
        {t('button_how_to_make_payment')}
      </HowToMakePayment>
      <PaymentMethodsGridStyled>
        {(isHHMTTheme
          ? HHMT_ADD_TRANSACTION_PAYMENT_METHODS
          : REMOX_ADD_TRANSACTION_PAYMENT_METHODS
        ).map((method) => (
          <Fragment key={method.method}>
            {countryConfig && paymentOptionsConfig[method.visible] && (
              <>{renderPaymentMethodItem(method)}</>
            )}
          </Fragment>
        ))}
      </PaymentMethodsGridStyled>
    </>
  );
};

const PaymentMethodsGridStyled = styled.div`
  display: flex;
  flex-flow: wrap;
  grid-gap: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    grid-gap: 24px;
  }
`;

const Label = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: ${(props) =>
    props.$isSelectRequired ? 'var(--ds-c-red)' : 'var(--ds-c-grey-dark)'};
  padding: 0;
  margin: 0;
  margin-bottom: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const HowToMakePayment = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-blue-hyperlink-default);
  padding: 0;
  margin: 0;
  margin-bottom: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;

  & img {
    margin-right: 4px;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    &:hover {
      color: var(--ds-c-blue-hover);

      & img {
        opacity: 0.5;
      }
    }
  }
`;

export default PaymentMethodsGrid;
