import { SORT_DESC } from 'constants';
import { SORT_ASC } from 'constants';
import React from 'react';

const SortIcon = ({ order }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99992 13.8335L8.70703 14.5406L7.99992 15.2477L7.29281 14.5406L7.99992 13.8335ZM12.0404 11.2073L8.70703 14.5406L7.29281 13.1264L10.6261 9.79306L12.0404 11.2073ZM7.29281 14.5406L3.95948 11.2073L5.37369 9.79306L8.70703 13.1264L7.29281 14.5406Z"
        fill={order === SORT_DESC ? '#000000' : '#000000'}
      />
      <path
        d="M7.99992 3.1665L8.70703 2.4594L7.99992 1.75229L7.29281 2.4594L7.99992 3.1665ZM12.0404 5.79273L8.70703 2.4594L7.29281 3.87361L10.6261 7.20694L12.0404 5.79273ZM7.29281 2.4594L3.95948 5.79273L5.37369 7.20694L8.70703 3.87361L7.29281 2.4594Z"
        fill={order === SORT_ASC ? '#000000' : '#000000'}
      />
    </svg>
  );
};

export default SortIcon;
