import NotificationsEmptyIcon from 'assets/icons/notifications_empty.svg';
import {
  updateNotificationsToClearAll,
  updateNotificationsToReadAll,
} from 'components/Profile/func';
import ButtonCommon from 'components/common/ButtonCommon';
import InformPopup from 'components/common/InformPopup';
import { DESKTOP_MIN_WIDTH, PROFILE_PREFIX } from 'constants';
import { datePicker } from 'helpers';
import useLang from 'hooks/useLang';
import useNotify from 'hooks/useNotify';
import useStore from 'hooks/useStore';
import { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styled from 'styled-components';
import { arrayUtils, domUtils } from 'utils';
import CustomNotificationActionDropdown from '../../CustomNotificationActionDropdown';

const NotificationsMobile = ({ currentTab, setCurrentTab }) => {
  const { t } = useLang();
  const { locate } = useLang();
  const { state } = useStore();
  const { notifications, currentUser } = state;

  const [isShowDeleteNotificationPopup, setShowDeleteNotificationPopup] =
    useState(false);

  const { handleNotifyOnClick } = useNotify();

  const handleReadAll = () => {
    updateNotificationsToReadAll(notifications, currentUser);

    setShowDeleteNotificationPopup(false);
  };

  const handleClearAll = () => {
    updateNotificationsToClearAll(notifications, currentUser);

    setShowDeleteNotificationPopup(false);
  };

  return (
    <>
      <style>
        {`
          .notifications-mobile-offcanvas {
            width: 100%!important;
            height: calc(100% - 70px);
            border: none!important;
          }
          .notifications-mobile-offcanvas-backdrop {
            opacity: 0!important;
          }
        `}
      </style>

      {isShowDeleteNotificationPopup && (
        <InformPopup
          isOpen={isShowDeleteNotificationPopup}
          text={t('popup_delete_notification_title')}
          content={t('popup_delete_notification_text')}
          buttonLabel1={t('button_no')}
          buttonLabel2={t('button_yes')}
          onClose={() => setShowDeleteNotificationPopup(false)}
          onClick={handleClearAll}
        />
      )}

      <NotificationsMobileStyled>
        <NavbarStyled
          expand={false}
          expanded={currentTab === 2}
          onToggle={() => setCurrentTab(null)}
        >
          <NavbarOffcanvas
            id="offcanvasNavbar-expand-false"
            aria-labelledby="offcanvasNavbarLabel-expand-false"
            placement="end"
            className="notifications-mobile-offcanvas"
            backdropClassName="notifications-mobile-offcanvas-backdrop"
          >
            <OffcanvasHeader closeButton>
              <OffcanvasTitle id="offcanvasNavbarLabel-expand-false">
                {t('label_notifications')}
              </OffcanvasTitle>
              {Boolean(notifications?.length) &&
                arrayUtils.otherThanNull(notifications) && (
                  <CustomNotificationActionDropdown
                    onReadAll={handleReadAll}
                    onClearAll={() => setShowDeleteNotificationPopup(true)}
                  />
                )}
            </OffcanvasHeader>
            <OffcanvasBody>
              <Desc>{t('label_notification_message')}</Desc>
              {/* eslint-disable-next-line no-extra-boolean-cast */}
              {Boolean(notifications?.length) ? (
                <NotificationsBody>
                  {notifications?.map(
                    ({ createdAt, message, icon, read, uuid }, index) => (
                      <NotifyItem
                        key={uuid}
                        eventKey={index}
                        onClick={(e) =>
                          handleNotifyOnClick(e, notifications[index])
                        }
                        $isRead={!read}
                      >
                        <NotifyItemTitle>
                          <img src={icon} width={18} height={18} alt="" />{' '}
                          {message?.[locate?.toUpperCase()]?.title ||
                            message?.[locate]?.title}{' '}
                          {!read && <MarkUnread>●</MarkUnread>}
                        </NotifyItemTitle>
                        <NotifyItemDesc>
                          {message?.[locate?.toUpperCase()]?.short ||
                            message?.[locate]?.short}
                        </NotifyItemDesc>
                        <NotifyItemDateTime>
                          {`${datePicker.convert24hhmmTo12hhmm(
                            datePicker.timeUTCToTimeStr(
                              new Date(parseInt(createdAt))
                            )
                          )} - ${datePicker.dateUTCToDateStr(
                            new Date(parseInt(createdAt))
                          )}`}
                        </NotifyItemDateTime>
                      </NotifyItem>
                    )
                  )}
                </NotificationsBody>
              ) : (
                <NotificationsEmpty>
                  <img
                    src={NotificationsEmptyIcon}
                    width={100}
                    height={100}
                    alt=""
                  />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('label_empty_notification'),
                    }}
                  />
                </NotificationsEmpty>
              )}
              <ButtonActions>
                <ButtonCommon
                  value={t('button_back')}
                  color="var(--c-primary)"
                  background="var(--bg-primary)"
                  isFill={true}
                  styles={{
                    margin: '0px',
                    width: '100%',
                  }}
                  onClick={() => {
                    setCurrentTab(null);

                    domUtils.changeAddressBarUrlWithoutReloadPage({
                      prefix: PROFILE_PREFIX,
                    });
                  }}
                />
              </ButtonActions>
            </OffcanvasBody>
          </NavbarOffcanvas>
        </NavbarStyled>
      </NotificationsMobileStyled>
    </>
  );
};

const NotificationsMobileStyled = styled.div`
  position: relative;
`;

const NavbarStyled = styled(Navbar)`
  display: initial;
  position: absolute;
  top: -40px;
  right: 0;
  padding: 0;
  height: 40px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    top: unset;
    right: unset;
  }
`;

const NavbarOffcanvas = styled(Navbar.Offcanvas)`
  margin-top: 70px;
`;
const OffcanvasHeader = styled(Offcanvas.Header)`
  height: 56px;
  padding: 16px 24px;
  border-bottom: none;

  display: flex;
  justify-content: space-between;

  & button {
    display: none;
  }

  & span {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: var(--ds-c-sky);
    cursor: pointer;
    user-select: none;

    &:hover {
      color: var(--ds-c-sky-hover);
    }
  }
`;
const OffcanvasTitle = styled(Offcanvas.Title)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  color: #7a8189;
`;
const OffcanvasBody = styled(Offcanvas.Body)`
  padding-inline: 0px;
  padding-block: 0px;
`;

const NotificationsBody = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 70px;
`;
const NotificationsEmpty = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  margin-top: 110px;

  & span {
    margin-top: 40px;
  }
`;
const NotifyItem = styled.div`
  width: 100%;
  height: fit-content;
  padding: 8px 24px;
  background-color: ${(props) => (props.$isRead ? '#EEF2F5' : '#fff')};
  border-bottom: 1px solid #d0dce5;
  cursor: pointer;
`;
const NotifyItemTitle = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #0e1012;

  margin-bottom: 8px;
  display: flex;
  align-items: center;

  & span {
    font-family: var(--ff-secondary);
    color: var(--ds-c-blue);
    font-weight: 700;
    margin-left: 4px;
  }

  & img {
    margin-right: 8px;
  }
`;
const MarkUnread = styled.span`
  font-size: 18px;
  color: var(--ds-c-red) !important;
`;
const NotifyItemDesc = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--ds-c-grey-dark);
  text-wrap: wrap;

  margin-bottom: 8px;
`;
const NotifyItemDateTime = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--ds-c-grey-neutral);
  text-align: right;

  margin-bottom: 0px;
`;

const Desc = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  align-items: center;
  padding-inline: 24px;
`;

const ButtonActions = styled.div`
  position: absolute;
  bottom: 0px;
  left: 24px;
  background: var(--ds-c-white);
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  border-top: 1px solid var(--ds-c-grey-disabled);
  height: 70px;
  display: flex;
  align-items: center;
  z-index: 3;
`;

export default NotificationsMobile;
