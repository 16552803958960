import WarningIcon from 'assets/icons/alert-animation.gif';
import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const UrgentPopup = ({ isOpen, onClose }) => {
  const { t } = useLang();

  return (
    <>
      <style>
        {`
          .urgent-modal-backdrop {
            z-index: 1100;
            background: #212121;
            opacity: 0.25!important;
            display: initial!important;
          }
        `}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="urgent-modal-backdrop"
        centered
      >
        <ModalBody>
          <img src={WarningIcon} alt="alert-animation.gif" />
          <SubText>
            {t('add_transaction_stop_poli_service_warning_message')}
          </SubText>
        </ModalBody>
        <ModalFooter>
          <ButtonCommon
            value={t('button_close')}
            onClick={onClose}
            borderNameVariable="--border-secondary"
            styles={{
              margin: '0px',
              width: '100%',
            }}
            color="var(--c-tertiary)"
            background="#fff"
            isFill={false}
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  z-index: 1100;
  & .modal-dialog {
    transform: none;
  }
  & .modal-content {
    padding: 24px 24px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 327px;
    height: fit-content;
    margin-inline: auto;
    border: none;
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 24px;

  & img {
    display: block;
    margin-inline: auto;
    margin-bottom: 16px;
    width: 50px;
    height: 50px;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 32px;
  }
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin: 0;

  & b {
    margin-inline: auto;
    display: table;
    text-transform: uppercase;

    &.holder-from {
      color: var(--ds-c-red);
    }

    &.holder-to {
      color: var(--ds-c-blue);
    }
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default UrgentPopup;
