import {
  fetchBuyRateCurrency,
  fetchSellRateCurrency,
} from 'components/FXCalculatorForm/func';
import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import {
  CURRENCY_FLAG_REFERENCES,
  DESKTOP_MIN_WIDTH,
  FX_CALCULATOR_FORM_CONVERT_FROM_NAME,
  FX_CALCULATOR_FORM_TO_NAME,
} from 'constants';
import useInputFocus from 'hooks/useInputFocus';
import useLang from 'hooks/useLang';
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import { arrayUtils, numberUtils } from 'utils';

const SEARCH_NAME_FIELD = 'fx-calculator-search-currency';

const CustomCurrencyDropdown = ({
  setRate,
  currentTab,
  currencies,
  information,
  setInformation,
}) => {
  const { t } = useLang();

  const [searchValue, setSearchValue] = useState('');
  const [dataSearch, setDataSearch] = useState(null);
  const [currentSelected, setCurrentSelected] = useState(null);
  const [isShowDropdown, setShowDropdown] = useState(false);

  const { currencyCode } = information['currentCurrencySelected'] || {};

  const NO_RESULT_TEXT = t('form_text_no_result');

  const convertFromAmount =
    information[FX_CALCULATOR_FORM_CONVERT_FROM_NAME] || '';
  const toAmount = information[FX_CALCULATOR_FORM_TO_NAME] || '';

  useEffect(() => {
    const item = currencies?.find((c) => c?.['currencyCode'] === currencyCode);
    setCurrentSelected(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currencyCode, currencies]);

  useInputFocus(SEARCH_NAME_FIELD);

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    if (!value) {
      setDataSearch(null);

      return;
    }

    const newDataSearch = currencies?.filter(
      (item) =>
        (item?.['currencyCode']
          ?.toLowerCase()
          ?.includes(value?.toLowerCase()) ||
          item?.['country']?.toLowerCase()?.includes(value?.toLowerCase())) &&
        item
    );
    setDataSearch(newDataSearch);
  };

  const handleItemSelected = async (currency) => {
    setCurrentSelected(currency);

    setSearchValue('');

    setDataSearch(null);

    setShowDropdown(false);

    let newInformation = {
      ...information,
      currentCurrencySelected: currency,
    };

    let currentCurrency;
    let currentRate;
    let toValue = 0;
    let convertFromValue = 0;

    const currentMinDenomination = currency?.minDenomination || 1;

    if (currentTab === 0) {
      currentCurrency = await fetchSellRateCurrency(currency?.currencyCode);

      currentRate = currentCurrency?.rate;

      toValue =
        Math.round((convertFromAmount * currentRate) / currentMinDenomination) *
        currentMinDenomination;

      convertFromValue = numberUtils.mathRound(toValue / currentRate);

      newInformation = {
        ...newInformation,
        [FX_CALCULATOR_FORM_CONVERT_FROM_NAME]: convertFromValue,
        [FX_CALCULATOR_FORM_TO_NAME]: toValue,
      };
    }

    if (currentTab === 1) {
      currentCurrency = await fetchBuyRateCurrency(currency?.currencyCode);

      currentRate = currentCurrency?.rate;

      convertFromValue =
        Math.round((toAmount * currentRate) / currentMinDenomination) *
        currentMinDenomination;

      toValue = numberUtils.mathRound(convertFromValue / currentRate);

      newInformation = {
        ...newInformation,
        [FX_CALCULATOR_FORM_CONVERT_FROM_NAME]: convertFromValue,
        [FX_CALCULATOR_FORM_TO_NAME]: toValue,
      };
    }

    setRate(currentCurrency?.rate);

    setInformation(newInformation);
  };

  const handleDropdowOnClick = (e, cb) => {
    e.preventDefault();

    setShowDropdown(!isShowDropdown);

    cb(e);
  };

  const handleDropdownOnToggle = () => {
    setSearchValue('');

    setDataSearch(null);

    setShowDropdown(!isShowDropdown);
  };

  const renderDropdownItems = (data) => {
    if (arrayUtils.isArrayEmpty(data)) {
      return (
        <DropdownItem
          eventKey={0}
          isReceiversEmpty={arrayUtils.isArrayEmpty(data)}
        >
          <NoItemText>{NO_RESULT_TEXT}</NoItemText>
        </DropdownItem>
      );
    }

    return (
      <>
        {data?.map((item, index) => (
          <DropdownItem
            key={item.currencyCode}
            eventKey={index}
            onClick={() => handleItemSelected(item)}
          >
            <CountryNameItem
              style={{
                color: '#0E1012',
              }}
            >
              <FlagIcon src={CURRENCY_FLAG_REFERENCES[item.currencyCode]} />
              {item.currencyCode}
            </CountryNameItem>
          </DropdownItem>
        ))}
      </>
    );
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <ButtonToggle
      href="/"
      ref={ref}
      onClick={(e) => handleDropdowOnClick(e, onClick)}
    >
      {children}
      <ArrowDownIcon
        color="#FFFFFF"
        styles={{
          position: 'absolute',
          right: '8px',
          top: '22px',
          transform: isShowDropdown && 'rotate(180deg)',
        }}
      />
    </ButtonToggle>
  ));

  return (
    <CurrencyDropdownStyled>
      <Dropdown onToggle={handleDropdownOnToggle}>
        <Dropdown.Toggle as={CustomToggle}>
          {currentSelected && (
            <CountryName>
              <FlagIcon
                src={CURRENCY_FLAG_REFERENCES[currentSelected?.currencyCode]}
              />
              {currentSelected?.currencyCode}
            </CountryName>
          )}
        </Dropdown.Toggle>

        <DropdownMenuWrap $currentTab={currentTab}>
          <SearchInput
            name={SEARCH_NAME_FIELD}
            value={searchValue || ''}
            placeholder={t('form_placeholder_type_to_search')}
            onChange={handleSearch}
          />
          <DropdownMenu>
            {renderDropdownItems(dataSearch || currencies)}
          </DropdownMenu>
        </DropdownMenuWrap>
      </Dropdown>
    </CurrencyDropdownStyled>
  );
};

const CurrencyDropdownStyled = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 27px;
  right: 10.5px;
`;
const DropdownMenuWrap = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  width: 100%;
  min-height: 44px;
  height: fit-content;
  background: #fff;
  box-shadow: var(--ds-bs-4);
  position: absolute;
  inset: 0px auto auto 0px !important;
  border-radius: 12px 12px 0 0;
  transform: ${(props) =>
    props.$currentTab === 0
      ? 'translate(-150px, 8px)!important'
      : 'translate(-150px, 52px)!important'};

  & input {
    border-radius: 12px 12px 0 0;
  }
`;
const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  max-height: 150px;
  border-top: 1px solid #eef2f5;
  border-radius: 0 0 12px 12px;
  width: 100%;
  overflow-y: scroll;
  background: var(--ds-c-white);
  margin-top: -3px !important;
  box-shadow: var(--ds-bs-3) !important;
  inset: 0px auto auto 0px !important;
  transform: translate(0px, 46px) !important;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ds-c-grey-hover);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;
const DropdownItem = styled(Dropdown.Item)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  text-transform: capitalize;
  width: 100%;
  height: 50px;
  background: transparent;

  &:hover {
    background: var(--ds-c-grey-light);
  }

  &:active {
    color: var(--ds-c-grey-dark);
  }

  &:first-child {
    border-radius: 12px 12px 0 0;
  }

  &:last-child {
    border-radius: 0 0 12px 12px;
  }
`;
const ButtonToggle = styled.a`
  font-family: var(--ff-primary);
  line-height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--ds-c-grey-dark);
  text-decoration: none;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
  height: 40px;

  &:hover {
    background: transparent;
    border: none;
    color: var(--ds-c-grey-hover);

    & svg {
      filter: var(--ds-f-c-grey-hover);
    }
  }

  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }
`;
const CountryName = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-white);

  width: 122px;
  height: 50px;
  background-color: var(--ds-c-black);
  justify-content: flex-end;
  align-items: center;
  padding-right: 46px;
  display: flex;
  position: absolute;
  top: 1px;
  right: 0.5px;
  margin-right: -10px;
  border-radius: 0 11px 11px 0px;
`;
const CountryNameItem = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  width: 122px;
  height: 50px;
  display: flex;
  align-items: center;
`;
const FlagIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  object-fit: cover;
  border: 1px solid var(--ds-bg-2);
  border-radius: 50%;
  margin-right: 8px;
`;

const SearchInput = styled.input`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: none;
  border-radius: 12px 12px 0 0;
  background: var(--ds-c-white);
  width: 100%;
  padding: 10px;

  &:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: initial;
    color: var(--ds-c-grey-hover);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const NoItemText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #b0b6bd;
  text-transform: initial;

  margin: 0px;
  padding: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default CustomCurrencyDropdown;
