import {
  getOccupationOptions,
  getPrevOccupationOptions,
} from 'components/common/func';
import {
  ADD_TRANSACTION_OCCUPATION_NAME,
  ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS,
  ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME,
} from 'constants';
import { CONFIG_OCCUPATION_EXTRAS } from 'constants/common';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import CustomSearchDropdown from 'components/common/CustomSearchDropdown';

const CustomOccupationDropdown = ({
  config,
  information,
  validation,
  setInformation,
  setValidation,
  isDisabled,
}) => {
  const { state } = useStore();
  const { configOccupation } = state;

  const [occupations, setOccupations] = useState(null);

  useEffect(() => {
    setOccupations({
      [ADD_TRANSACTION_OCCUPATION_NAME]: getOccupationOptions(configOccupation),
      [ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]: getPrevOccupationOptions(
        information[ADD_TRANSACTION_OCCUPATION_NAME]?.key,
        configOccupation
      ),
    });
  }, [configOccupation, information]);

  const isPreviousOccupationField = (field) =>
    field.name === ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME;

  const isHidePreviousOccupation = (field) =>
    isPreviousOccupationField(field) &&
    (!CONFIG_OCCUPATION_EXTRAS.find(
      (occupation) =>
        occupation === information[ADD_TRANSACTION_OCCUPATION_NAME]?.key
    ) ||
      isDisabled);

  return (
    <>
      {ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS.map((field) => (
        <AddressDropdownStyled
          key={field.name}
          $isHidden={isHidePreviousOccupation(field)}
        >
          <CustomSearchDropdown
            field={field}
            isConfigRequired={config?.required}
            data={occupations?.[field.name]}
            keyFind="key"
            keyTranslate="value"
            noResultText="form_text_no_result"
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            isDisabled={isDisabled}
          />
        </AddressDropdownStyled>
      ))}
    </>
  );
};

const AddressDropdownStyled = styled.div`
  position: relative;
  width: 100%;

  display: ${(props) => props.$isHidden && 'none'};
`;

export default CustomOccupationDropdown;
