import AccountBlockedInform from 'components/FormLogIn/components/AccountBlockedInform';
import AccountIncorrectInform from 'components/FormLogIn/components/AccountIncorrectInform';
import AccountLimitedInform from 'components/FormLogIn/components/AccountLimitedInform';
import { emailValid, fieldValid, passwordValid } from 'helpers/validation';

export const GREETING_MORNING_TEXT = 'Good morning';
export const GREETING_AFTERNOON_TEXT = 'Good afternoon';
export const GREETING_EVENING_TEXT = 'Good evening';

export const LOGIN_UNAUTHORIZED_INFORM = {
  'bad credentials': <AccountIncorrectInform />,
  'user account is locked': <AccountBlockedInform />,
  blocked: <AccountLimitedInform />,
};

export const LOGIN_EMAIL_NAME = 'login-email-field';
export const LOGIN_PASSWORD_NAME = 'login-password-field';
export const LOGIN_FIELDS = [
  {
    label: 'form_email',
    name: LOGIN_EMAIL_NAME,
    type: 'text',
    placeholder: 'form_email',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_email_invalid',
    msgRequired: 'form_required',
    checkValidFn: emailValid,
  },
  {
    label: 'form_password',
    name: LOGIN_PASSWORD_NAME,
    type: 'password',
    placeholder: 'form_password',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: '',
    msgRequired: 'form_required',
    checkValidFn: fieldValid,
  },
];
export const RESET_LOGIN_INFORMATION = {
  [LOGIN_EMAIL_NAME]: '',
  [LOGIN_PASSWORD_NAME]: '',
};

export const FORGOT_PASSWORD_EMAIL_NAME = 'forgot-password-email-field';
export const FORGOT_PASSWORD_FIELD = {
  label: 'form_email',
  name: FORGOT_PASSWORD_EMAIL_NAME,
  type: 'text',
  placeholder: 'form_email_placeholder_1',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_email_invalid',
  msgRequired: 'form_required',
  checkValidFn: emailValid,
};
export const RESET_FORGOT_PASSWORD_INFORMATION = {
  [FORGOT_PASSWORD_EMAIL_NAME]: '',
};

export const RESET_PASSWORD_PASSWORD_NAME =
  'reset-password-password-name-field';
export const RESET_PASSWORD_REPASSWORD_NAME =
  'reset-password-repassword-name-field';
export const RESET_PASSWORD_FIELDS = [
  {
    label: 'form_password',
    name: RESET_PASSWORD_PASSWORD_NAME,
    type: 'password',
    placeholder: 'form_placeholder',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_password_invalid',
    msgRequired: 'form_required',
    checkValidFn: passwordValid,
  },
  {
    label: 'form_confirm_password',
    name: RESET_PASSWORD_REPASSWORD_NAME,
    type: 'password',
    placeholder: 'form_placeholder',
    isRequired: true,
    isReadOnly: false,
    msgInvalid: 'form_password_invalid',
    msgNotMatch: 'form_confirm_password_invalid',
    msgRequired: 'form_required',
    checkValidFn: passwordValid,
  },
];
export const RESET_PASSWORD_INFORMATION = {
  [RESET_PASSWORD_PASSWORD_NAME]: '',
  [RESET_PASSWORD_REPASSWORD_NAME]: '',
};

export const PASSWORD_LIVE_CHECKS = [
  {
    id: 0,
    require: 'reset_password_password_requirement_1',
  },
  {
    id: 1,
    require: 'reset_password_password_requirement_2',
  },
  {
    id: 2,
    require: 'reset_password_password_requirement_3',
  },
  {
    id: 3,
    require: 'reset_password_password_requirement_4',
  },
];
