// base
export const HHMT_PREFIX = '/hhmt';
export const REMOX_PREFIX = '/remox';

// persist routes
export const HOME_APP_URL = '/';
export const LOGIN_PREFIX = '/login';
export const REGISTER_PREFIX = '/register';
export const DASHBOARD_PREFIX = '/dashboard';
export const RECEIVERS_PREFIX = '/receivers';
export const RECEIVERS_EDIT_SUFIX = '/receivers/edit-receiver';
export const RECEIVERS_ADD_SUFIX = '/receivers/add-receiver';
export const TRANSACTIONS_PREFIX = '/transactions';
export const TRANSACTION_ADD_PREFIX = '/tran';
export const PROFILE_PREFIX = '/profile';
export const FX_DASHBOARD_PREFIX = '/fx-dashboard';
export const FX_TRANSACTIONS_PREFIX = '/fx-transactions';
export const FX_TRANSACTION_ADD_PREFIX = '/fx-tran';
export const OFFERS_PREFIX = '/offers';
export const PROMOTIONS_PREFIX = '/promotions';
export const PROMOTIONS_EVENTS_PREFIX = '/promotions-of-event';

// public routes
export const FORGOT_PASSWORD_PREFIX = '/forgot-password';
export const RESET_PASSWORD_PREFIX = '/reset-password';
export const ACCOUNT_ACTIVATION_PREFIX = '/activate';
export const NOT_FOUND_PREFIX = '/404';
export const CALCULATOR_FORM_PREFIX = '/calculator-form';
export const FX_CALCULATOR_FORM_PREFIX = '/fx-calculator-form';
export const FX_LOGIN_PREFIX = '/fx-login';

// payment routes
export const PAYMENT_PROCESS_PREFIX = '/payment-process';
export const FX_PAYMENT_PROCESS_PREFIX = '/fx-tran/payment-process';

// webflow routes
export const MONEY_TRANSFER_PREFIX = '/money-transfer';
export const FOREIGN_EXCHANGE_PREFIX = '/foreign-exchange';
export const FAQ_PREFIX = '/faqs';
export const CONTACT_US_PREFIX = '/contact-us';

// event routes
export const EVENT_PREFIX = '/events';
