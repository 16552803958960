export const backgroundReference = {
  disable: 'transparent',
  'in-progress': 'var(--bg-primary)',
  completed: 'var(--bg-step-1)',
};
export const colorReference = {
  disable: '#A4B2CF',
  'in-progress': 'var(--c-primary)',
  completed: 'var(--bg-primary)',
};
