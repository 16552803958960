/* eslint-disable no-unused-vars */
import {
  BUSINESS_OF_RECEIVER_TYPE,
  CREATE_BUSINESS_RECEIVER_TYPE,
  CREATE_INDIVIDUAL_RECEIVER_TYPE,
  FEMALE,
  MALE,
} from 'constants';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import { isEmpty } from 'lodash';

const useReceiver = () => {
  const { state } = useStore();
  const { currentReceiver } = state;
  const {
    receiverId,
    firstName,
    lastName,
    country,
    countryName,
    gender,
    fullAddress,
    mobileNumber,
    phoneNumber,
    banks,
    ewallets,
    favorited,
  } = currentReceiver || {};

  const query = useQuery();
  const typeOfCreateReceiver = query.get('type');

  const isReceiverOfBusinessType =
    !isEmpty(currentReceiver) && gender === BUSINESS_OF_RECEIVER_TYPE;
  const isReceiverOfIndividualType =
    !isEmpty(currentReceiver) && (gender === MALE || gender === FEMALE);

  const isCreateBusinessReceiver =
    typeOfCreateReceiver === CREATE_BUSINESS_RECEIVER_TYPE;
  const isCreateIndividualReceiver =
    typeOfCreateReceiver === CREATE_INDIVIDUAL_RECEIVER_TYPE;

  return {
    isReceiverOfBusinessType,
    isReceiverOfIndividualType,
    isCreateBusinessReceiver,
    isCreateIndividualReceiver,
  };
};

export default useReceiver;
