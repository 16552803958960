import usePortalModal from 'hooks/usePortalModal';
import React from 'react';
import { functionUtils } from 'utils';
import PaymentInstructionPopup from '../popups/PaymentInstructionPopup';

const PaymentInstructionModal = ({ isShow, content, actionClose }) => {
  const { closePortalModalName } = usePortalModal();

  const handleOnClose = () => {
    if (functionUtils.isFunction(actionClose)) {
      actionClose();
    }
    closePortalModalName();
  };
  return (
    <PaymentInstructionPopup
      isOpen={isShow}
      bodyChildren={content}
      onClose={handleOnClose}
      isClickBackdropToClose={true}
      zIndexBackdropOverlap={1055}
      isBackdropOverlap={true}
    />
  );
};

export default PaymentInstructionModal;
