/* eslint-disable no-unused-vars */
import { RESET_PORTAL_MODAL_STORE } from 'constants';
import useStore from 'hooks/useStore';
import { memo, useEffect, useMemo } from 'react';
import { Fade } from 'react-bootstrap';
import { SET_PORTAL_MODAL } from 'store/action';
import { stringUtils } from 'utils';
import { PORTAL_MODAL_REFERENCE } from './config';

const PortalModal = () => {
  const { state, dispatch } = useStore();
  const { portalModal } = state || {};
  const {
    isShowPortalModal,
    namePortalModal,
    title,
    content,
    action,
    actionClose,
    modalStyles,
    modalOptions,
  } = portalModal || {};

  useEffect(() => {
    return () => {
      if (!isShowPortalModal) {
        dispatch({
          type: SET_PORTAL_MODAL,
          payload: RESET_PORTAL_MODAL_STORE,
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowPortalModal]);

  const ReferenceModal = useMemo(() => {
    const Component = PORTAL_MODAL_REFERENCE[namePortalModal];

    return Component || <></>;
  }, [namePortalModal]);

  if (!isShowPortalModal) return <></>;
  return (
    <Fade in appear>
      <ReferenceModal
        isShow={isShowPortalModal}
        title={title}
        content={stringUtils.stringTransform(content)}
        action={action}
        actionClose={actionClose}
        modalStyles={modalStyles}
        modalOptions={modalOptions}
      />
    </Fade>
  );
};

export default memo(PortalModal);
