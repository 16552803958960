import ButtonCommon from 'components/common/ButtonCommon';
import {
  DESKTOP_MIN_WIDTH,
  MOBILE_MIN_WIDTH,
  TABLET_MAX_WIDTH,
} from 'constants';
import useLang from 'hooks/useLang';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const PaymentInstructionPopup = ({
  isOpen,
  bodyChildren,
  onClose,
  isBackdropOverlap,
  zIndexBackdropOverlap,
  zIndexModal = 0,
  isClickBackdropToClose = false,
}) => {
  const { t } = useLang();

  const handleOnClickBackdropToClose = () => {
    if (isClickBackdropToClose) {
      onClose();
    }
  };

  return (
    <>
      <style>
        {zIndexModal
          ? `
          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal {
              z-index: ${zIndexModal || '1101'};
            }
          }
        `
          : ``}
        {isBackdropOverlap
          ? `
          .payment-instruction-modal-backdrop {
            z-index: ${zIndexBackdropOverlap || '1100'};
            background: #212121;
            opacity: 0.25!important;
            display: initial!important;
          }
        `
          : `
        .payment-instruction-modal-backdrop {
          background: #212121;
          opacity: 0.25!important;
          display: initial!important;
        }
      `}
      </style>
      <ModalStyled
        show={isOpen}
        onHide={handleOnClickBackdropToClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="payment-instruction-modal-backdrop"
        centered
      >
        <ModalBody>{bodyChildren}</ModalBody>
        <ModalFooter>
          <ButtonCommon
            value={t('button_ok')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: '100%',
            }}
            onClick={onClose}
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    & .modal-dialog {
      position: absolute;
      margin: 0;
      top: 0px;
      height: 100vh;
      width: 100%;
      max-width: 100%;
      align-items: end;
      transition: ease-out 0.3s !important;
      transform: none;

      & .modal-content {
        padding: 0px 16px;
        border-radius: 0px;
        box-shadow: none;
        background: var(--ds-c-white);
        width: 100%;
        height: fit-content;
        margin-inline: auto;
        border: none;
        border-radius: 8px 8px 0 0;
      }
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & .modal-dialog {
      transform: none;
      & .modal-content {
        padding: 16px;
        border-radius: 12px;
        box-shadow: var(--ds-bs-4);
        background: var(--ds-c-white);
        width: 452px;
        height: fit-content;
        margin-inline: auto;
        border: none;
        border-radius: 8px;
      }
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  text-align: left;
  margin-bottom: 16px;
  padding-top: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 24px;
    padding-top: unset;
  }
`;
const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: center;
  margin-inline: -16px;
  padding-inline: 16px;
  padding-block: 16px;
  border-top: 1px solid var(--ds-c-grey-disabled);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding-block: unset;
    padding-inline: unset;
    margin-inline: unset;
    border-top: none;
  }
`;

export default PaymentInstructionPopup;
