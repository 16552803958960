const OfferIcon = ({
  color = '#FCB519',
  size = { width: 24, height: 24 },
  styles = {},
  onClick = () => {},
  reverseColor ={}
}) => {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...styles }}
      onClick={onClick}
    >
      <path
        d="M22 3H2L1 7L3.5 9V13L1 15L0.5 17.5L2 19.5L20 20L23 19L22.5 15L20 12.5L21 9L23 6L22 3Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79695 8.09664C6.59898 8.44118 6.5 8.84874 6.5 9.31933C6.5 9.78992 6.59898 10.2017 6.79695 10.5546C7.00352 10.8992 7.28326 11.1681 7.63615 11.3613C7.98905 11.5462 8.38067 11.6387 8.81103 11.6387C9.25 11.6387 9.64163 11.5462 9.98592 11.3613C10.3388 11.1681 10.6142 10.8992 10.8122 10.5546C11.0188 10.2017 11.1221 9.78992 11.1221 9.31933C11.1221 8.84874 11.0188 8.44118 10.8122 8.09664C10.6142 7.7437 10.3388 7.47479 9.98592 7.28992C9.64163 7.09664 9.25 7 8.81103 7C8.38067 7 7.98905 7.09664 7.63615 7.28992C7.28326 7.47479 7.00352 7.7437 6.79695 8.09664ZM8.12676 10.1513C7.94601 9.95798 7.85563 9.68067 7.85563 9.31933C7.85563 8.95798 7.94601 8.68067 8.12676 8.48739C8.31612 8.29412 8.54421 8.19748 8.81103 8.19748C9.07786 8.19748 9.30164 8.29412 9.48239 8.48739C9.67175 8.68067 9.76643 8.95798 9.76643 9.31933C9.76643 9.68067 9.67175 9.95798 9.48239 10.1513C9.30164 10.3445 9.07786 10.4412 8.81103 10.4412C8.54421 10.4412 8.31612 10.3445 8.12676 10.1513Z"
        fill={color}
      />
      <path
        d="M14.0012 7.15126L8.19131 15.8487H9.99883L15.8087 7.15126H14.0012Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1749 12.458C12.9769 12.8025 12.8779 13.2101 12.8779 13.6807C12.8779 14.1513 12.9769 14.563 13.1749 14.916C13.3815 15.2605 13.6612 15.5294 14.0141 15.7227C14.367 15.9076 14.7586 16 15.189 16C15.6279 16 16.0196 15.9076 16.3638 15.7227C16.7167 15.5294 16.9922 15.2605 17.1901 14.916C17.3967 14.563 17.5 14.1513 17.5 13.6807C17.5 13.2101 17.3967 12.8025 17.1901 12.458C16.9922 12.105 16.7167 11.8361 16.3638 11.6513C16.0196 11.458 15.6279 11.3613 15.189 11.3613C14.7586 11.3613 14.367 11.458 14.0141 11.6513C13.6612 11.8361 13.3815 12.105 13.1749 12.458ZM14.5047 14.5126C14.3239 14.3193 14.2336 14.042 14.2336 13.6807C14.2336 13.3193 14.3239 13.042 14.5047 12.8487C14.6941 12.6555 14.9221 12.5588 15.189 12.5588C15.4558 12.5588 15.6796 12.6555 15.8603 12.8487C16.0497 13.042 16.1444 13.3193 16.1444 13.6807C16.1444 14.042 16.0497 14.3193 15.8603 14.5126C15.6796 14.7059 15.4558 14.8025 15.189 14.8025C14.9221 14.8025 14.6941 14.7059 14.5047 14.5126Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2C1.34315 2 0 3.34315 0 5V7C0 7.55228 0.460003 7.98368 0.981894 8.16437C2.15653 8.57105 3 9.68703 3 11C3 12.313 2.15653 13.4289 0.981894 13.8356C0.460003 14.0163 0 14.4477 0 15V18C0 19.6569 1.34315 21 3 21H21C22.6569 21 24 19.6569 24 18V15C24 14.4477 23.54 14.0163 23.0181 13.8356C21.8435 13.4289 21 12.313 21 11C21 9.68703 21.8435 8.57105 23.0181 8.16437C23.54 7.98368 24 7.55228 24 7V5C24 3.34315 22.6569 2 21 2H3ZM2 5C2 4.44772 2.44772 4 3 4H21C21.5523 4 22 4.44772 22 5V6.41664C20.2362 7.18734 19 8.94703 19 11C19 13.053 20.2362 14.8127 22 15.5834V18C22 18.5523 21.5523 19 21 19H3C2.44772 19 2 18.5523 2 18V15.5834C3.76376 14.8127 5 13.053 5 11C5 8.94703 3.76376 7.18734 2 6.41664V5Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.79695 8.09664C6.59898 8.44118 6.5 8.84874 6.5 9.31933C6.5 9.78992 6.59898 10.2017 6.79695 10.5546C7.00352 10.8992 7.28326 11.1681 7.63615 11.3613C7.98905 11.5462 8.38067 11.6387 8.81103 11.6387C9.25 11.6387 9.64163 11.5462 9.98592 11.3613C10.3388 11.1681 10.6142 10.8992 10.8122 10.5546C11.0188 10.2017 11.1221 9.78992 11.1221 9.31933C11.1221 8.84874 11.0188 8.44118 10.8122 8.09664C10.6142 7.7437 10.3388 7.47479 9.98592 7.28992C9.64163 7.09664 9.25 7 8.81103 7C8.38067 7 7.98905 7.09664 7.63615 7.28992C7.28326 7.47479 7.00352 7.7437 6.79695 8.09664ZM8.12676 10.1513C7.94601 9.95798 7.85563 9.68067 7.85563 9.31933C7.85563 8.95798 7.94601 8.68067 8.12676 8.48739C8.31612 8.29412 8.54421 8.19748 8.81103 8.19748C9.07786 8.19748 9.30164 8.29412 9.48239 8.48739C9.67175 8.68067 9.76643 8.95798 9.76643 9.31933C9.76643 9.68067 9.67175 9.95798 9.48239 10.1513C9.30164 10.3445 9.07786 10.4412 8.81103 10.4412C8.54421 10.4412 8.31612 10.3445 8.12676 10.1513Z"
        fill={reverseColor}
      />
      <path
        d="M14.0012 7.15126L8.19131 15.8487H9.99883L15.8087 7.15126H14.0012Z"
        fill={reverseColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1749 12.458C12.9769 12.8025 12.8779 13.2101 12.8779 13.6807C12.8779 14.1513 12.9769 14.563 13.1749 14.916C13.3815 15.2605 13.6612 15.5294 14.0141 15.7227C14.367 15.9076 14.7586 16 15.189 16C15.6279 16 16.0196 15.9076 16.3638 15.7227C16.7167 15.5294 16.9922 15.2605 17.1901 14.916C17.3967 14.563 17.5 14.1513 17.5 13.6807C17.5 13.2101 17.3967 12.8025 17.1901 12.458C16.9922 12.105 16.7167 11.8361 16.3638 11.6513C16.0196 11.458 15.6279 11.3613 15.189 11.3613C14.7586 11.3613 14.367 11.458 14.0141 11.6513C13.6612 11.8361 13.3815 12.105 13.1749 12.458ZM14.5047 14.5126C14.3239 14.3193 14.2336 14.042 14.2336 13.6807C14.2336 13.3193 14.3239 13.042 14.5047 12.8487C14.6941 12.6555 14.9221 12.5588 15.189 12.5588C15.4558 12.5588 15.6796 12.6555 15.8603 12.8487C16.0497 13.042 16.1444 13.3193 16.1444 13.6807C16.1444 14.042 16.0497 14.3193 15.8603 14.5126C15.6796 14.7059 15.4558 14.8025 15.189 14.8025C14.9221 14.8025 14.6941 14.7059 14.5047 14.5126Z"
        fill={reverseColor}
      />
    </svg>
  );
};

export default OfferIcon;
