import { useEffect } from 'react';

const useScrollIntoView = (ref, isScroll, deps) => {
  useEffect(() => {
    if (isScroll) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useScrollIntoView;
