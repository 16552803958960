import SendIcon from 'assets/icons/send-icon.svg';
import TooltipCommon from 'components/common/TooltipCommon';
import TooltipCommonMobile from 'components/common/TooltipCommon/mobile';
import {
  default as TransferenceHHMTIcon,
  default as TransferenceRemoxIcon,
} from 'assets/ver2/icons/transactions-empty-hhmt-icon.svg';
import { DASHBOARD_PREFIX, TRANSACTION_ADD_PREFIX } from 'constants';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import ButtonCommon from '../../../common/ButtonCommon';

const NoTransaction = () => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDetectDevice();
  const { pathname } = useLocation();
  const isDashboardPage = pathname.includes(DASHBOARD_PREFIX);

  const promtoionTxt = t('discount_first_time_to_desc');
  const hasPromotion = promtoionTxt !== 'discount_first_time_to_desc' ? true : false;

  return (
    <NoTransactionStyled $isDashboardPage={isDashboardPage}>
      <img
        src={isHHMTTheme ? TransferenceHHMTIcon : TransferenceRemoxIcon}
        width={isMobile ? 150 : 200}
        height={isMobile ? 150 : 200}
        alt=""
      />
      {isDashboardPage ? (
        <span
          dangerouslySetInnerHTML={{
            __html: t('discount_first_time_to_title'),
          }}
        />
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html: t('discount_first_time_to_title'),
          }}
        />
      )}

      {hasPromotion && (
        <Promotion>
          {t('discount_first_time_to_desc')}
          {isMobile || isTablet ? (
            <TooltipCommonMobile
            text={t('label_terms_conditions_text_01')}
            name={''}
            label={''}
          />
          ) : (
            <TooltipCommon text={t('label_terms_conditions_text_01')} />
          )}
        </Promotion>
      )}
      {/* {!isMobile && !isDashboardPage && ( */}
        <ButtonCommon
          value={t('button_send_money')}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
          styles={{
            paddingInline: 0,
          }}
          iconSrc={SendIcon}
          onClick={() => {
            Cookies.remove(MAKE_PAYMENT_KEY);
            navigate(`${TRANSACTION_ADD_PREFIX}`);
          }}
        />
      {/* )} */}
    </NoTransactionStyled>
  );
};

const NoTransactionStyled = styled.div`
  display: grid;
  justify-items: center;
  position: relative;
  margin-top: 0;

  & span {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: var(--ds-c-black);
  }
`;

const Promotion = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: var(--ds-c-blue);
  margin: 5px 0 16px 0;

  display: flex;
  align-items: center;
  gap: 3px;
`;

export default NoTransaction;
