import { useEffect, useState } from 'react';

const useCountdownToAction = (
  countdownInit,
  isRun,
  cb,
  deps,
  delaySec = 1,
  isLoop
) => {
  const [countdown, setCountdown] = useState(countdownInit);

  useEffect(() => {
    let timer;

    if (isRun) {
      timer = setTimeout(() => {
        setCountdown((count) => count - 1);
      }, delaySec * 1000);
    }

    if (countdown === -1) {
      const loopCallback = () => {
        if (!isLoop) return null;
        setCountdown(countdownInit);
      };
      cb(loopCallback);
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdown, ...deps]);
};

export default useCountdownToAction;
