/* eslint-disable no-unused-vars */
import { api } from 'api';
import {
  fetchReceiverInformation,
  fetchReceiversFiltered,
  getBodyPayloadFiltered,
} from 'components/Receivers/func';
import NoResult from 'components/Transactions/components/NoResult';
import ButtonLinkCommon from 'components/common/ButtonLinkCommon';
import {
  DASHBOARD_PREFIX,
  PAGE_DEFAULT,
  RECEIVERS_PREFIX,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import Cookies from 'helpers/cookies';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SET_CURRENT_RECEIVER, SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import { arrayUtils, objectUtils } from 'utils';
import NoReceiver from '../NoReceiver';
import ReceiverItem from './components/ReceiverItem';

import FormReceiverDetailsMobile from 'components/Receivers/components/FormReceiverDetails/mobile';
import Endpoint from 'components/common/Endpoint';
import { BUSINESS_OF_RECEIVER_TYPE, RECEIVER_FILTER_INITIAL } from 'constants';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import { isNull } from 'lodash';
import DeleteReceiverPopup from '../DeleteReceiverPopup';

const ReceiverList = ({
  receivers,
  setReceivers,
  searchValue,
  applyFiltered,
  currentTab,
  isFetching,
  setFetching,
  isSearched,
  setSearched,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { currentReceiver } = state;
  const { token } = useAuth();
  const { pathname } = useLocation();
  const isDashboardPage = pathname.includes(DASHBOARD_PREFIX);
  const navigate = useNavigate();

  const [isOpenReceiverDetailsPopup, setOpenReceiverDetailsPopup] =
    useState(false);
  const [isOpenDeleteReceiverPopup, setOpenDeleteReceiverPopup] =
    useState(false);

  const isShowNoResult =
    !isFetching &&
    isNull(receivers) &&
    (!objectUtils.isObjectEmpty(applyFiltered) || searchValue);
  const isShowNoTransaction = arrayUtils.isArrayEmpty(receivers);
  const isShowBlank = isNull(receivers);

  const handleOnOpenReceiverDetailsPopup = async (receiver) => {
    dispatch({ type: SET_SHOW_LOADING, payload: true });

    await fetchReceiverInformation(token, receiver?.receiverId);

    setOpenReceiverDetailsPopup(true);

    dispatch({ type: SET_SHOW_LOADING, payload: false });
  };

  const handleOnCloseReceiverDetailsPopup = () => {
    setOpenDeleteReceiverPopup(false);

    setOpenReceiverDetailsPopup(false);

    dispatch({ type: SET_CURRENT_RECEIVER, payload: null });
  };

  const handleOnOpenDeleteReceiverPopup = () => {
    setOpenDeleteReceiverPopup(true);
  };

  const handleOnCloseDeleteReceiverPopup = () => {
    setOpenDeleteReceiverPopup(false);

    if (!isOpenReceiverDetailsPopup) {
      dispatch({ type: SET_CURRENT_RECEIVER, payload: null });
    }
  };

  const handleOnDeleteReceiver = async (receiverId) => {
    try {
      await api.deleteReceiver(token, receiverId);

      let newFiltered = objectUtils.deletePropIsFalsy(applyFiltered);

      if (objectUtils.isObjectEmpty(newFiltered)) {
        newFiltered = RECEIVER_FILTER_INITIAL;
      }

      let bodyPayload = getBodyPayloadFiltered(newFiltered);

      bodyPayload = {
        ...bodyPayload,
        name: searchValue,
      };

      bodyPayload = {
        ...bodyPayload,
        name: searchValue,
        ...(currentTab === 1 && { gender: BUSINESS_OF_RECEIVER_TYPE }),
      };

      await fetchReceiversFiltered({
        token,
        body: bodyPayload,
        page: PAGE_DEFAULT,
        isDelete: true,
        setReceivers,
      });

      dispatch({ type: SET_CURRENT_RECEIVER, payload: null });

      setOpenDeleteReceiverPopup(false);

      setOpenReceiverDetailsPopup(false);
    } catch (error) {
      console.error(error?.message);
    }
  };

  const handleOnSendNow = async (receiver) => {
    await fetchReceiverInformation(token, receiver?.receiverId);

    Cookies.remove(MAKE_PAYMENT_KEY);

    navigate(`${TRANSACTION_ADD_PREFIX}?rid=${receiver?.receiverId}`);
  };

  if (isShowNoResult) {
    return <NoResult />;
  }
  if (isShowNoTransaction) {
    return <NoReceiver />;
  }
  if (isShowBlank) {
    return null;
  }
  return (
    <>
      {currentReceiver && isOpenDeleteReceiverPopup && (
        <DeleteReceiverPopup
          isOpen={currentReceiver && isOpenDeleteReceiverPopup}
          onClose={handleOnCloseDeleteReceiverPopup}
          onClick={handleOnDeleteReceiver}
        />
      )}

      {currentReceiver && isOpenReceiverDetailsPopup && (
        <FormReceiverDetailsMobile
          isOpen={currentReceiver && isOpenReceiverDetailsPopup}
          onClose={handleOnCloseReceiverDetailsPopup}
          onClick={handleOnSendNow}
          onDelete={handleOnOpenDeleteReceiverPopup}
        />
      )}

      <ReceiverListStyled>
        {receivers?.map((receiver) => (
          <ReceiverItem
            key={receiver?.receiverId}
            receiver={receiver}
            onClick={() => handleOnOpenReceiverDetailsPopup(receiver)}
          />
        ))}
        <Endpoint />
        {isDashboardPage && (
          <ButtonLinkCommon
            href={RECEIVERS_PREFIX}
            value={t('receivers_view_all_receivers')}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
            styles={{
              marginInline: 'auto',
              marginTop: '24px',
              height: 'fit-content',
              fontWeight: 'normal',
            }}
          />
        )}
      </ReceiverListStyled>
    </>
  );
};

const ReceiverListStyled = styled.div`
  width: 100%;
  height: fit-content;
`;

export default ReceiverList;
