export const screenHelper = {
  applyScaleMobileViewport: () => {
    const viewportMetaTag = document.getElementById('viewport-root');

    if (window.innerWidth < 360) {
      var ww =
        window.innerWidth < window.screen.width
          ? window.innerWidth
          : window.screen.width;
      var mw = 576;
      const ratio = ww / mw;

      if (ww < mw) {
        viewportMetaTag.setAttribute(
          'content',
          'initial-scale=' +
            ratio +
            ', maximum-scale=' +
            ratio +
            ', minimum-scale=' +
            ratio +
            ', user-scalable=no, width=' +
            ww
        );
      } else {
        viewportMetaTag.setAttribute(
          'content',
          'initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, width=' +
            ww
        );
      }
    } else {
      viewportMetaTag.setAttribute(
        'content',
        'initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no'
      );
    }
  },
};
