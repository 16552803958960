import { DESKTOP_MIN_WIDTH } from 'constants';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ButtonLinkCommon = ({
  href = '',
  value = '',
  color = 'var(--c-primary)',
  background = 'var(--bg-primary)',
  isDisabled = false,
  isFill = undefined,
  styles = {},
  className = {},
}) => {
  const colorDisabled = {
    'var(--c-primary)': 'var(--ds-c-grey-hover)',
    'var(--c-secondary)': 'var(--c-secondary-disabled)',
  };

  const colorHover = {
    'var(--c-primary)': 'var(--c-primary-hover)',
    'var(--c-secondary)': 'var(--c-secondary-hover)',
    'var(--bg-primary)': 'var(--bg-primary-hover)',
  };

  const backgroundHover = {
    'var(--bg-primary)': 'var(--bg-primary-hover)',
    'var(--ds-c-white)': 'var(--ds-c-white)',
    'var(--root-header-btn-active)': 'var(--root-header-btn-hover)',
  };

  const backgroundDisabled = {
    'var(--bg-primary)': 'var(--bg-primary-disabled)',
    'var(--ds-c-white)': 'var(--ds-c-grey-hover)',
  };

  return (
    <ButtonLinkStyled
      to={href}
      style={{ ...styles }}
      $color={color}
      $colorHover={colorHover[color]}
      $colorDisabled={colorDisabled}
      $background={background}
      $backgroundHover={backgroundHover[background]}
      $backgroundDisabled={backgroundDisabled[background]}
      disabled={isDisabled}
      $isFill={isFill}
      className={className}
    >
      {value}
    </ButtonLinkStyled>
  );
};

const ButtonLinkStyled = styled(Link)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;

  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 44px;
  border: none;
  border-radius: 12px;
  padding: 0 10px;

  color: ${(props) => props.$color}!important;
  background: ${(props) => props.$background}!important;

  &:focus,
  &:focus-visible,
  &:active {
    color: ${(props) => props.$color}!important;
    background: ${(props) => props.$background}!important;
  }

  &:disabled {
    color: ${(props) => props.$colorDisabled}!important;
    background-color: ${(props) => props.$backgroundDisabled}!important;
    cursor: not-allowed;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    &:hover {
      border: none;
      outline: none;
      box-shadow: none;
      background-color: ${(props) => props.$backgroundHover}!important;
      color: ${(props) => props.$colorHover}!important;
      border: ${(props) =>
        props.$isFill || props.$isFill === undefined
          ? 'none!important'
          : 'var(--border-primary-hover) !important'};
    }

    &:disabled {
      &:hover {
        background-color: ${(props) => props.$backgroundDisabled}!important;
      }
    }
  }
`;

export default ButtonLinkCommon;
