import FEFXLogo from 'assets/images/fefx-logo.svg';
import HHMTLogo from 'assets/images/hhmt-logo.svg';
import REMOXLogo from 'assets/images/Remox_logo_w_tagline_white.svg';
import { HOME_URL } from 'constants';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';

const Footer2 = () => {
  const { theme } = useTheme();
  const { locate } = useLang();

  return (
    <FooterStyled>
      <Wrapper>
        <BrandWrap>
          <img src={HHMTLogo} alt="hhmt" height={40} />
          <img src={REMOXLogo} alt="remox" height={35} />
          <img src={FEFXLogo} alt="fefx" height={54} />
        </BrandWrap>
        <TextWrap>
          © Eastern & Allied Pty Ltd {new Date().getFullYear()} |{' '}
          <a href={`${HOME_URL[theme]}/terms-and-conditions?lang=${locate}`}>
            Terms & Conditions
          </a>{' '}
          |{' '}
          <a href={`${HOME_URL[theme]}/privacy-policy?lang=${locate}`}>
            Privacy Policy
          </a>
          <br />
          Eastern & Allied Pty Ltd is registered with AUSTRAC
          <br />
          ABN 31 130 744 802
        </TextWrap>
      </Wrapper>
    </FooterStyled>
  );
};

const FooterStyled = styled.section`
  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    display: none;
  }

  @media screen and (min-width: 1920px) {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 95px;
    background: var(--root-footer-bg);
    display: unset;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1130px;
  height: 95px;
  background: transparent;
  margin-inline: auto;
`;
const BrandWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 448px;
  height: 54px;

  & img {
    object-fit: contain;
  }
`;
const TextWrap = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  text-align: right;

  & a {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: var(--ds-c-grey-hover);
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default Footer2;
