import { FX_ADD_TRANSACTION_PICKUP_LOCATION } from 'constants/fx';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { isArray, isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import { FreeMode, Mousewheel, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

const HorizontalSwiper = ({
  data,
  information,
  setInformation,
  currentSelected,
  setCurrentSelected,
  styles = {},
}) => {
  const { pickupLocationSelected } = useFxFormAddTransactionStore();
  const { getStatesOfPickupLocationSwiperSorted } = useFxAddTransaction();

  const dataSorted = getStatesOfPickupLocationSwiperSorted({
    data,
    currentSelected,
    pickupLocationSelected,
  });

  const handleOnClickSwiperSlideItem = (item) => {
    setCurrentSelected(item);
    const newInformation = {
      ...information,
      [FX_ADD_TRANSACTION_PICKUP_LOCATION]: null,
    };
    setInformation(newInformation);
  };
  const renderSwiperSlides = () => {
    if (isEmpty(dataSorted) || !isArray(dataSorted)) return null;
    return (
      <>
        {dataSorted.map((item) => (
          <SwiperSlide
            key={item?.text}
            className={
              currentSelected?.text === item?.text && 'swiper-slice-selected'
            }
            onClick={() => handleOnClickSwiperSlideItem(item)}
          >
            {item?.text}
          </SwiperSlide>
        ))}
      </>
    );
  };
  return (
    <SwiperStyled
      direction={'horizontal'}
      slidesPerView={3.34}
      freeMode={true}
      scrollbar={true}
      mousewheel={true}
      modules={[FreeMode, Scrollbar, Mousewheel]}
      style={styles}
    >
      {renderSwiperSlides()}
    </SwiperStyled>
  );
};

const SwiperStyled = styled(Swiper)`
  height: fit-content;
  padding-bottom: 16px;

  & .swiper-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;

    & .swiper-slide {
      ${paragraphMixin};
      font-size: 12px;
      line-height: 15px;
      font-weight: 500;
      height: fit-content;
      padding: 8px;
      border-radius: 100px;
      white-space: nowrap;
      width: fit-content !important;
      cursor: pointer;

      &.swiper-slide-active {
      }

      &.swiper-slice-selected {
        font-weight: 700;
        color: var(--ds-c-blue);
        background-color: var(--ds-bg-2);
      }
    }
  }

  & .swiper-scrollbar-horizontal {
    display: none;
  }
`;

export default HorizontalSwiper;
