import { backgroundReference, colorReference } from 'constants';
import React from 'react';
import styled from 'styled-components';

const AddTxnStep2Icon = ({
  status = 'disable' || 'in-progress' || 'completed',
}) => {
  return (
    <SvgStyled $backgroundColor={backgroundReference[status]}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_10387_897)">
          <path
            d="M11.0285 19.5C13.255 16.9922 18.3332 10.9141 18.3332 7.5C18.3332 3.35937 14.6005 0 9.99984 0C5.39914 0 1.6665 3.35937 1.6665 7.5C1.6665 10.9141 6.74463 16.9922 8.97119 19.5C9.50505 20.0977 10.4946 20.0977 11.0285 19.5ZM9.99984 5C10.7366 5 11.4431 5.26339 11.964 5.73223C12.485 6.20107 12.7776 6.83696 12.7776 7.5C12.7776 8.16304 12.485 8.79893 11.964 9.26777C11.4431 9.73661 10.7366 10 9.99984 10C9.26312 10 8.55659 9.73661 8.03565 9.26777C7.51472 8.79893 7.22206 8.16304 7.22206 7.5C7.22206 6.83696 7.51472 6.20107 8.03565 5.73223C8.55659 5.26339 9.26312 5 9.99984 5Z"
            fill={colorReference[status]}
          />
        </g>
        <defs>
          <clipPath id="clip0_10387_897">
            <rect
              width="16.6667"
              height="20"
              fill="transparent"
              transform="translate(1.6665)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgStyled>
  );
};

const SvgStyled = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

export default AddTxnStep2Icon;
