/* eslint-disable no-unused-vars */
import { RESET_EDIT_RECEIVER_INFORMATION_STORE } from 'constants';
import useStore from 'hooks/useStore';
import {
  SET_ADD_RECEIVER_INFORMATION,
  SET_EDIT_RECEIVER_CURRENT_STEP,
  SET_EDIT_RECEIVER_INFORMATION,
} from 'store/action';

const useFormAddReceiverStore = () => {
  const { state, setState } = useStore();
  const {
    currentBusinessReceiver,
    editReceiverInformation,
    editReceiverCurrentStep,
  } = state;

  const { personalDetails, residentialAddress, accountDetails } =
    editReceiverInformation || {};

  const setEditReceiverCurrentStep = (payload) =>
    setState({
      action: SET_EDIT_RECEIVER_CURRENT_STEP,
      payload,
    });

  const resetEditReceiverInformation = () => {
    setState({
      action: SET_EDIT_RECEIVER_INFORMATION,
      payload: RESET_EDIT_RECEIVER_INFORMATION_STORE,
    });
  };
  const resetAddReceiverInformation = () => {
    setState({
      action: SET_ADD_RECEIVER_INFORMATION,
      payload: RESET_EDIT_RECEIVER_INFORMATION_STORE,
    });
  };
  const resetEditReceiverCurrentStep = () => {
    setEditReceiverCurrentStep(0);
  };

  return {
    personalDetails,
    residentialAddress,
    accountDetails,
    editReceiverCurrentStep,
    setEditReceiverCurrentStep,
    resetEditReceiverInformation,
    resetAddReceiverInformation,
    resetEditReceiverCurrentStep,
  };
};

export default useFormAddReceiverStore;
