import { FX_ADD_TRANSACTION_ENTER_INFORMATION_GENERAL_DETAILS_NAME_FIELDS } from 'constants/fx';
import useLang from 'hooks/useLang';
import React from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import FormInputDatepickerValidation from './components/FormInputDatepickerValidation';
import FormInputGenderRadio from './components/FormInputGenderRadio';
import FormInputValidation from './components/FormInputValidation';

const PersonalInformation = ({
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();

  const renderNameForm = () => {
    return (
      <>
        {FX_ADD_TRANSACTION_ENTER_INFORMATION_GENERAL_DETAILS_NAME_FIELDS.map(
          (field) => (
            <FormInputValidation
              key={field.name}
              field={field}
              information={information}
              setInformation={setInformation}
              validation={validation}
              setValidation={setValidation}
            />
          )
        )}
      </>
    );
  };

  const renderDobForm = () => {
    return (
      <FormInputDatepickerValidation
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
    );
  };

  const renderGenderForm = () => {
    return (
      <FormInputGenderRadio
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
    );
  };

  return (
    <PersonalInformationStyled>
      <Label>{t('label_confirm_personal_info')}</Label>
      {renderNameForm()}
      {renderDobForm()}
      {renderGenderForm()}
    </PersonalInformationStyled>
  );
};

const PersonalInformationStyled = styled.div`
  margin-bottom: 40px;
`;

const Label = styled.p`
  ${paragraphMixin};
  font-weight: 500;
  color: var(--ds-c-blue);
  margin-bottom: 16px;
`;

export default PersonalInformation;
