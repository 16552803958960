import { LogUtil } from 'components/common/LogUtil';
import moment from 'moment';
import momenttz from 'moment-timezone';
import vi from 'moment/locale/vi';
import { stringUtils } from 'utils';

export const datePicker = {
  // eslint-disable-next-line no-useless-escape
  dateFormat: `[\"##/##/####\"]`,
  minYear: 1923,
  maxYear: new Date().getFullYear(),
  maxExpiredYear: 2050,
  SYDNEY_TIME_ZONE: 'Australia/Sydney',
  DATE_TIME_FORMAT: 'DD/MM/YYYY',
  DATE_TIME_FORMAT_YEAR_SORT: 'DD/MM/YY',
  padTo2Digits: (numberPad) => {
    return numberPad.toString().padStart(2, '0');
  },
  getDateStr: (utc) => {
    return datePicker.padTo2Digits(utc.getDate());
  },
  getMonthStr: (utc) => {
    return datePicker.padTo2Digits(
      utc.getMonth() === 0 ? 1 : utc.getMonth() + 1
    );
  },
  getYearStr: (utc) => {
    return utc.getFullYear();
  },
  getToday: () => {
    const today = new Date();

    return today;
  },
  getTodayMilliseconds: () => Date.now(),
  getDateUTC: (dd, MM, yyyy) => {
    return new Date(
      Date.UTC(parseInt(yyyy), parseInt(MM), parseInt(dd), 0, 0, 0)
    );
  },
  dateUTCToDateISO: (utc) => {
    return utc.toISOString();
  },
  dateUTCToDateStr: (utc, lengthYear) => {
    const dd = datePicker.getDateStr(utc);
    const MM = datePicker.getMonthStr(utc);
    const yyyy = datePicker.getYearStr(utc);

    return (
      dd + '/' + MM + '/' + (lengthYear ? String(yyyy).slice(lengthYear) : yyyy)
    );
  },
  dateStrToUTC: (dateStr) => {
    // dateStr with format 'dd/MM/YYYY'
    const datePicked = dateStr.split('/');
    let dd = datePicked[0];
    let MM = datePicked[1];
    let yyyy = datePicked[2];

    return datePicker.dateArrToDateUTC([yyyy, MM, dd]);
  },
  dateArrToDateUTC: (dateArr) => {
    const [yyyy, MM, dd] = dateArr;

    return datePicker.getDateUTC(dd, MM - 1, yyyy);
  },
  millisecondsToUTC: (milliseconds) => new Date(milliseconds),
  getOfAgeToUse: (utc) => {
    const AGE_MINIMUM = 18;

    const today = datePicker.getToday();

    let age = today.getFullYear() - utc.getFullYear();
    const month = today.getMonth() - utc.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < utc.getDate())) {
      age--;
    }

    return age >= AGE_MINIMUM;
  },
  getExpired: (utc) => {
    const today = datePicker.getToday();

    return utc.getTime() - today.getTime() <= 0;
  },
  getExpiredWithNumberOfDaysToCurrentDate: (utc, numberOfDays) => {
    const today = datePicker.getToday();
    const todayTimeMilliseconds = today.getTime();

    const nodTimeMilliseconds = utc.getTime() + numberOfDays * 86400 * 1000;

    return nodTimeMilliseconds - todayTimeMilliseconds < 0 ? true : false;
  },
  getDateMask: (dateStr, utc) => {
    const datePicked = dateStr
      ? dateStr.split('/')
      : datePicker.dateUTCToDateStr(utc).split('/');

    let dd = datePicked[0];
    let MM = datePicked[1];
    let yyyy = datePicked[2];

    if (dd?.toString()?.length === 1) {
      if (parseInt(dd) > 3) dd = `0${dd}`;
    }

    if (dd?.toString()?.length === 2) {
      if (parseInt(dd) > 31) dd = 31;
    }

    if (MM?.toString()?.length === 1) {
      if (parseInt(MM) > 2) MM = `0${MM}`;
    }

    if (MM?.toString()?.length === 2) {
      if (parseInt(MM) > 12) MM = 12;
    }

    return {
      dateMask: `${dd ? dd + '/' : ''}${MM ? MM + '/' : ''}${yyyy ? yyyy : ''}`,
      dd,
      MM,
      yyyy,
    };
  },
  getHoursStr: (utc) => {
    return datePicker.padTo2Digits(utc.getHours());
  },
  getMinutesStr: (utc) => {
    return datePicker.padTo2Digits(utc.getMinutes());
  },
  getSecondsStr: (utc) => {
    return datePicker.padTo2Digits(utc.getSeconds());
  },
  timeUTCToTimeStr: (utc) => {
    const hh = datePicker.getHoursStr(utc);
    const mm = datePicker.getMinutesStr(utc);
    const ss = datePicker.getSecondsStr(utc);

    return hh + ':' + mm + ':' + ss;
  },
  convert24hhmmTo12hhmm: (timeStr) => {
    let hhmm = timeStr.slice(0, -3);

    hhmm = hhmm.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [timeStr];

    if (hhmm.length > 1) {
      hhmm = hhmm.slice(1);

      hhmm[5] = +hhmm[0] < 12 ? ' AM' : ' PM';

      hhmm[0] = datePicker.padTo2Digits(+hhmm[0] % 12 || 12);
    }

    return hhmm.join(''); // return adjusted time or original string
  },
  getFullTimeString: (timestamp) => {
    const d = timestamp ? new Date(timestamp) : new Date();
    const dayNames = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const dateNumber = d.getDate();
    const dayName = dayNames[d.getDay()];
    const monthName = monthNames[d.getMonth()];
    const yearNumber = d.getFullYear();

    return `${dayName}, ${dateNumber} ${monthName} ${yearNumber}`;
  },
  getFullTimeStringTranslate: (locale, timestamp) => {
    const localeReference = {
      vi: vi,
      en: '',
    };

    return (
      moment(timestamp || new Date())
        .locale(locale, localeReference[locale])
        .format('dddd, Do MMMM YYYY') || ''
    );
  },
  isDateTimeValid: (dateTime) => {
    if (!dateTime) {
      return false;
    }
    try {
      return moment(dateTime).isValid();
    } catch (error) {
      LogUtil.error('isDateTimeValid', JSON.stringify(error));
      return false;
    }
  },
  convertToLocalDateTimeWithTimeZone: (
    dateTimeSever,
    outputFormat,
    timeZone
  ) => {
    try {
      if (!datePicker.isDateTimeValid(dateTimeSever)) {
        return '';
      }
      if (!stringUtils.isString(outputFormat)) {
        outputFormat = datePicker.DATE_TIME_FORMAT_YEAR_SORT;
      }
      if (!stringUtils.isString(timeZone)) {
        timeZone = datePicker.SYDNEY_TIME_ZONE;
      }
      /*
        Note: moment() = new Date();//current datetime of device
        momenttz.tz('2018-07-17T10:00:00', 'YYYY-MM-DDTHH:mm:ss', 'GMT')
        .local()
        .format('YYYY-MM-DDTHH')
        => "2018-07-17 17:00:00
      */
      return momenttz.tz(dateTimeSever, timeZone).format(outputFormat);
    } catch (error) {
      LogUtil.error(
        'convertToLocalDateTimeWithTimeZone',
        JSON.stringify(error)
      );
      return '';
    }
  },
  isDateUTC: (date) => {
    return JSON.stringify(new Date(date)) !== 'null';
  },
};
