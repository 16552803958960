/* eslint-disable no-unused-vars */
import useAddTransaction from 'hooks/form-add-transaction/useAddTransaction';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import ProgressBar from 'react-bootstrap/ProgressBar';
import styled from 'styled-components';

const ProcessSteps = ({ isPreview }) => {
  const { addTransactionCurrentStep: currentStep } =
    useFormAddTransactionStore();
  const { getNavbarsAndBodyContentOfSidebar } = useAddTransaction();

  const navbars = getNavbarsAndBodyContentOfSidebar();
  const navbarsLength = navbars?.length || 0;
  const isDisable = currentStep === 4;
  const progress = isPreview
    ? 100
    : Number(((currentStep / (navbarsLength - 1)) * 100).toFixed(3));

  const getStatus = (stepIndex) => {
    if (isDisable) return 'disable';

    if (isPreview) return 'completed';

    if (currentStep === stepIndex) return 'in-progress';

    if (currentStep > stepIndex) return 'completed';
  };

  return (
    <ProcessStepsStyled>
      <StepperWrapper>
        <ProgressBarStyled now={progress} />
        {navbars.map((nav, index) => (
          <StepperItem key={nav.label}>
            <nav.Icon status={getStatus(index)} />
          </StepperItem>
        ))}
      </StepperWrapper>
    </ProcessStepsStyled>
  );
};

const ProcessStepsStyled = styled.div`
  margin-top: -12px;
  margin-bottom: 16px;
`;

const StepperWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const ProgressBarStyled = styled(ProgressBar)`
  height: 1px;
  width: 100%;
  background-color: var(--ds-c-blue-remox-light);
  position: absolute;
  top: 13px;
  z-index: 0;

  & .progress-bar {
    background-color: var(--c-tertiary);
    transition: width 0.3s ease;
  }
`;

const StepperItem = styled.div`
  z-index: 1;
  background: #fff;
`;

export default ProcessSteps;
