import { api } from 'api';
import { address } from 'helpers';
import useLang from 'hooks/useLang';
import { useEffect, useState } from 'react';
import { Fade } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import {
  DESKTOP_MIN_WIDTH,
  REGISTER_ADDRESS_FINDER_NAME,
  REGISTER_ADDRESS_POST_CODE_NAME,
  REGISTER_ADDRESS_STATE_NAME,
  REGISTER_ADDRESS_STREET_NAME_NAME,
  REGISTER_ADDRESS_STREET_NO_NAME,
  REGISTER_ADDRESS_STREET_TYPE_NAME,
  REGISTER_ADDRESS_SUBURB_NAME,
  REGISTER_ADDRESS_UNITNAME_NAME,
  REGISTER_PERSONAL_DETAILS_ADDRESS_FINDER_FIELD,
  REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS,
  REGISTER_STEP_REFERENCE,
  REGISTER_STREET_TYPES,
  RESET_REGISTER_INFORMATION_STORE,
} from '../../../constants';
import useStore from '../../../hooks/useStore';
import {
  SET_REGISTER_INFORMATION,
  SET_SHOW_LOADING,
} from '../../../store/action';
import ButtonCommon from '../../common/ButtonCommon';
import ArrowDownIcon from '../../common/Icons/ArrowDownIcon';
import CustomAddressFinderDropdown from './components/CustomAddressFinderDropdown';
import FormInputAddressManuallyValidation from './components/FormInputAddressManuallyValidation';
import FormInputAddressSwitch from './components/FormInputAddressSwitch';
import { checkFormInputValidation } from './func';

const initialFormField = RESET_REGISTER_INFORMATION_STORE.residentialAddress;

const ResidentialAddress = ({
  currentStep,
  setCurrentStep,
  signupId,
  registerPayload,
  setRegisterPayload,
  handleOnToggleCancelPopup,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { registerInformation } = state;

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [addressFinder, setAddressFinder] = useState(null);

  const { isRegisterEnterAddressManuallySelected } = information;

  const getAddressStreetType = (value) => {
    return REGISTER_STREET_TYPES?.find((c) => c?.key === value);
  };

  useEffect(() => {
    const informationStore = registerInformation.residentialAddress;

    setInformation(informationStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (addressFinder) {
      const {
        fullAddress,
        buildingName,
        subdwelling,
        streetNumber,
        streetName,
        streetType,
        locality,
        state,
        postcode,
      } = addressFinder || {};

      setInformation({
        [REGISTER_ADDRESS_FINDER_NAME]: fullAddress,
        [REGISTER_ADDRESS_UNITNAME_NAME]: buildingName
          ? `${buildingName} ${subdwelling}`
          : subdwelling,
        [REGISTER_ADDRESS_STREET_NO_NAME]: streetNumber,
        [REGISTER_ADDRESS_STREET_NAME_NAME]: streetName,
        [REGISTER_ADDRESS_STREET_TYPE_NAME]: getAddressStreetType(streetType),
        [REGISTER_ADDRESS_POST_CODE_NAME]: postcode,
        [REGISTER_ADDRESS_SUBURB_NAME]: locality,
        [REGISTER_ADDRESS_STATE_NAME]: state,
        isRegisterEnterAddressManuallySelected,
      });

      const addressFinderEle = document.getElementsByName(
        REGISTER_ADDRESS_FINDER_NAME
      )[0];

      if (addressFinderEle) {
        addressFinderEle.value = fullAddress;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressFinder, isRegisterEnterAddressManuallySelected]);

  const currentFullAddress = address.addressCombine(
    information[REGISTER_ADDRESS_UNITNAME_NAME],
    information[REGISTER_ADDRESS_STREET_NO_NAME],
    information[REGISTER_ADDRESS_STREET_NAME_NAME],
    information[REGISTER_ADDRESS_STREET_TYPE_NAME]?.key,
    information[REGISTER_ADDRESS_POST_CODE_NAME],
    information[REGISTER_ADDRESS_SUBURB_NAME],
    information[REGISTER_ADDRESS_STATE_NAME]
  );

  const handleNextStep = async () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    if (!information[REGISTER_ADDRESS_FINDER_NAME]) {
      delete newInformation[REGISTER_ADDRESS_FINDER_NAME];
      delete newValidation[REGISTER_ADDRESS_FINDER_NAME];
    }

    if (!information[REGISTER_ADDRESS_UNITNAME_NAME]) {
      delete newInformation[REGISTER_ADDRESS_UNITNAME_NAME];
      delete newValidation[REGISTER_ADDRESS_UNITNAME_NAME];
    }

    // if (!information[REGISTER_ADDRESS_STREET_NO_NAME]) {
    //   delete newInformation[REGISTER_ADDRESS_STREET_NO_NAME];
    //   delete newValidation[REGISTER_ADDRESS_STREET_NO_NAME];
    // }

    delete newInformation['isRegisterEnterAddressManuallySelected'];
    delete newValidation['isRegisterEnterAddressManuallySelected'];

    const isAllowSubmit =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowSubmit) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      try {
        const payload = {
          ...registerPayload,
          onlineSignupId: signupId,
          step: 2,
          fullAddress:
            information[REGISTER_ADDRESS_FINDER_NAME] || currentFullAddress,
          unitNumber: information[REGISTER_ADDRESS_UNITNAME_NAME],
          streetNumber: information[REGISTER_ADDRESS_STREET_NO_NAME],
          streetName: information[REGISTER_ADDRESS_STREET_NAME_NAME],
          streetType: information[REGISTER_ADDRESS_STREET_TYPE_NAME]?.key,
          postCode: information[REGISTER_ADDRESS_POST_CODE_NAME],
          suburbName: information[REGISTER_ADDRESS_SUBURB_NAME],
          stateCode: information[REGISTER_ADDRESS_STATE_NAME],
        };
        setRegisterPayload(payload);

        const response = await api.saveSignup(payload);

        if (response?.status === 200) {
          dispatch({
            type: SET_REGISTER_INFORMATION,
            payload: {
              ...registerInformation,
              [REGISTER_STEP_REFERENCE[currentStep]]: information,
            },
          });

          dispatch({ type: SET_SHOW_LOADING, payload: false });

          setCurrentStep(currentStep + 1);
        }
      } catch (error) {
        console.error(error?.message);

        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }
    }

    if (!isAllowSubmit) {
      newValidation = checkFormInputValidation(
        information[REGISTER_ADDRESS_FINDER_NAME],
        REGISTER_PERSONAL_DETAILS_ADDRESS_FINDER_FIELD,
        newValidation
      );

      if (information[REGISTER_ADDRESS_UNITNAME_NAME]) {
        newValidation = checkFormInputValidation(
          information[REGISTER_ADDRESS_UNITNAME_NAME],
          REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[0],
          newValidation
        );
      }

      newValidation = checkFormInputValidation(
        information[REGISTER_ADDRESS_STREET_NO_NAME],
        REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[1],
        newValidation
      );

      newValidation = checkFormInputValidation(
        information[REGISTER_ADDRESS_STREET_NAME_NAME],
        REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[2],
        newValidation
      );

      newValidation = checkFormInputValidation(
        information[REGISTER_ADDRESS_STREET_TYPE_NAME]?.key,
        REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[3],
        newValidation
      );

      newValidation = checkFormInputValidation(
        information[REGISTER_ADDRESS_POST_CODE_NAME],
        REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[4],
        newValidation
      );

      newValidation = checkFormInputValidation(
        information[REGISTER_ADDRESS_SUBURB_NAME],
        REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[5],
        newValidation
      );

      newValidation = checkFormInputValidation(
        information[REGISTER_ADDRESS_STATE_NAME],
        REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS[6],
        newValidation
      );

      setValidation(newValidation);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  return (
    <ResidentialAddressStyled>
      <Title>
        <ArrowDownIcon
          color="var(--ds-c-grey-dark)"
          size={{ width: 14 }}
          styles={{
            transform: 'rotate(90deg)',
            marginRight: '16px',
            cursor: 'pointer',
          }}
          onClick={handlePreviousStep}
        />
        {t('label_residential_address')}
      </Title>
      <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
        <AccordionItem eventKey="1" id="register-address-accordion">
          <AccordionBody>
            {!isRegisterEnterAddressManuallySelected && (
              <CustomAddressFinderDropdown
                field={REGISTER_PERSONAL_DETAILS_ADDRESS_FINDER_FIELD}
                information={information}
                setInformation={setInformation}
                validation={validation}
                setValidation={setValidation}
                setAddressFinder={setAddressFinder}
                checkFormInputValidation={checkFormInputValidation}
              />
            )}
            <FormInputAddressSwitch
              information={information}
              setInformation={setInformation}
              setValidation={setValidation}
            />
            {isRegisterEnterAddressManuallySelected &&
              REGISTER_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS.map((field) => (
                <FormInputAddressManuallyValidation
                  key={field.name}
                  field={field}
                  information={information}
                  setInformation={setInformation}
                  validation={validation}
                  setValidation={setValidation}
                  checkFormInputValidation={checkFormInputValidation}
                />
              ))}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
      <Fade in appear>
        <NextWrap>
          <ButtonCommon
            value={t('button_cancel')}
            onClick={handleOnToggleCancelPopup}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--c-primary)"
            background="var(--ds-c-white)"
          />
          <ButtonCommon
            value={t('button_next')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            onClick={handleNextStep}
            isUseKeyDown
          />
        </NextWrap>
      </Fade>
    </ResidentialAddressStyled>
  );
};

const ResidentialAddressStyled = styled(Form)`
  position: relative;
  width: 100%;
  height: fit-content;
  padding-bottom: 96px;
  margin-top: 24px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 437px;
    margin-inline: auto;
    margin-top: 40px;
    padding-bottom: 0px;
  }
`;

const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  display: flex;
  align-items: center;
  color: var(--ds-c-grey-dark);
  margin-top: 0px;
  margin-bottom: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 32px;
    line-height: 38px;
    margin-top: 0px;
    margin-bottom: 24px;
  }
`;

const AccordionItem = styled(Accordion.Item)`
  border: none;
`;
const AccordionBody = styled(Accordion.Body)`
  padding: 0 !important;
`;

const NextWrap = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ds-c-white);
  margin-inline: -24px;
  padding: 16px 24px;
  width: 100%;
  border-top: 1px solid var(--ds-c-grey-disabled);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    height: 40px;
    border-top: none;
  }
`;

export default ResidentialAddress;
