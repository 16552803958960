import { handleApplyDiscount } from 'components/Discount/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  DISCOUNT_ACTIVE,
  DISCOUNT_COMING_SOON,
  DISCOUNT_EXPIRED,
  DISCOUNT_FULLY_REDEEMED,
  DISCOUNT_REDEEMED,
  FX_TRANSACTION_ADD_PREFIX,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import { getDiscountStatus } from 'helpers';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useToastify from 'hooks/useToastify';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const FooterActions = ({ offer, inactive, onClick, onApplyError, onClose }) => {
  const { t } = useLang();
  const { pathname } = useLocation();
  const { token } = useAuth();

  const isCreateTxn =
    pathname === `${TRANSACTION_ADD_PREFIX}` ||
    pathname.includes(`${FX_TRANSACTION_ADD_PREFIX}`);

  const { status } = getDiscountStatus(offer || null) || {};

  const { triggerToaster } = useToastify({
    content: t('label_discount_applied'),
  });

  const footerContent = useMemo(
    () => ({
      [DISCOUNT_ACTIVE]: (
        <FooterContent $isCenter={!onClick || inactive}>
          <ButtonCommon
            value={t('button_close')}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
          {onClick && !inactive && (
            <>
              {isCreateTxn ? (
                <ButtonCommon
                  value={t('button_apply')}
                  color="var(--c-primary)"
                  background="var(--bg-primary)"
                  isFill={true}
                  styles={{
                    width: '50%',
                  }}
                  onClick={() => {
                    handleApplyDiscount({
                      token,
                      item: offer,
                      onApplyError,
                      onApplySuccess: triggerToaster,
                    });
                  }}
                />
              ) : (
                <ButtonCommon
                  value={t('add_transaction_send_money_label')}
                  color="var(--c-primary)"
                  background="var(--bg-primary)"
                  isFill={true}
                  styles={{
                    paddingInline: 0,
                    width: '50%',
                    textTransform: 'capitalize',
                  }}
                  onClick={onClick}
                />
              )}
            </>
          )}
        </FooterContent>
      ),
      [DISCOUNT_COMING_SOON]: (
        <FooterContent $isCenter>
          <ButtonCommon
            value={t('button_close')}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
        </FooterContent>
      ),
      [DISCOUNT_REDEEMED]: (
        <FooterContent $isCenter>
          <ButtonCommon
            value={t('button_close')}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
        </FooterContent>
      ),
      [DISCOUNT_EXPIRED]: (
        <FooterContent $isCenter>
          <ButtonCommon
            value={t('button_close')}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
        </FooterContent>
      ),
      [DISCOUNT_FULLY_REDEEMED]: (
        <FooterContent $isCenter>
          <ButtonCommon
            value={t('button_close')}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
        </FooterContent>
      ),
    }),
    [status]
  );

  return <FooterActionsStyled>{footerContent[status]}</FooterActionsStyled>;
};

const FooterActionsStyled = styled.div`
  width: calc(100% + 32px);
  margin: 0;
  margin-inline: -16px;
  padding: 16px 16px 0 16px;
  border-top: 1px solid var(--ds-bg-2);
`;

const FooterContent = styled.div`
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: ${(props) => (props.$isCenter ? 'center' : 'space-between')};
  margin: 0px;
`;

export default FooterActions;
