/* eslint-disable no-unused-vars */
import {
  HHMT_ADD_TRANSACTION_PAYMENT_METHODS,
  REMOX_ADD_TRANSACTION_PAYMENT_METHODS,
} from 'constants';
import useLang from 'hooks/useLang';
import usePortalModal from 'hooks/usePortalModal';
import useTheme from 'hooks/useTheme';
import { functionUtils } from 'utils';
import FxPaymentInstructionPopup from '../popups/FxPaymentInstructionPopup';

const FxPaymentInstructionModal = ({
  isShow,
  title: paymentMethodName,
  content,
  action,
}) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const { closePortalModalName } = usePortalModal();

  const handleOnClick = () => {
    if (functionUtils.isFunction(action)) {
      action();
    }
    closePortalModalName();
  };

  const paymentMethodsReference = isHHMTTheme
    ? HHMT_ADD_TRANSACTION_PAYMENT_METHODS
    : REMOX_ADD_TRANSACTION_PAYMENT_METHODS;

  const paymentMethod =
    paymentMethodsReference.find((pm) => pm.method === paymentMethodName) || {};

  return (
    <FxPaymentInstructionPopup
      isOpen={isShow}
      imageSrc={paymentMethod?.iconColor}
      imagesStyles={{
        width: '62px',
        height: '50px',
        marginBottom: '16px',
      }}
      content={content || ''}
      buttonLabel2={t('button_close')}
      onClick={handleOnClick}
      isBackdropOverlap={true}
      zIndexBackdropOverlap={1055}
    />
  );
};

export default FxPaymentInstructionModal;
