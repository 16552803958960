import AEDFlag from 'assets/icons/flags/aed-flag.svg';
import ARSFlag from 'assets/icons/flags/ars-flag.svg';
import AUDFlag from 'assets/icons/flags/aud-flag.svg';
import BGDFlag from 'assets/icons/flags/bgd-flag.svg';
import BNDFlag from 'assets/icons/flags/bnd-flag.svg';
import BRLFlag from 'assets/icons/flags/brl-flag.svg';
import CANFlag from 'assets/icons/flags/can-flag.svg';
import CHFFlag from 'assets/icons/flags/chf-flag.svg';
import CHNFlag from 'assets/icons/flags/chn-flag.svg';
import CLPFlag from 'assets/icons/flags/clp-flag.svg';
import COGFlag from 'assets/icons/flags/cog-flag.svg';
import COPFlag from 'assets/icons/flags/cop-flag.svg';
import CZKFlag from 'assets/icons/flags/czk-flag.svg';
import DKKFlag from 'assets/icons/flags/dkk-flag.svg';
import EGYFlag from 'assets/icons/flags/egy-flag.svg';
import EURFlag from 'assets/icons/flags/eur-flag.svg';
import FJIFlag from 'assets/icons/flags/fji-flag.svg';
import FRAFlag from 'assets/icons/flags/fra-flag.svg';
import GBPFlag from 'assets/icons/flags/gbp-flag.svg';
import HKGFlag from 'assets/icons/flags/hkg-flag.svg';
import HUFFlag from 'assets/icons/flags/huf-flag.svg';
import IDNFlag from 'assets/icons/flags/idn-flag.svg';
import ILSFlag from 'assets/icons/flags/ils-flag.svg';
import INDFlag from 'assets/icons/flags/ind-flag.svg';
import ISKFlag from 'assets/icons/flags/isk-flag.svg';
import ITAFlag from 'assets/icons/flags/ita-flag.svg';
import JODFlag from 'assets/icons/flags/jod-flag.svg';
import JPNFlag from 'assets/icons/flags/jpn-flag.svg';
import KESFlag from 'assets/icons/flags/kes-flag.svg';
import KHMFlag from 'assets/icons/flags/khm-flag.svg';
import KORFlag from 'assets/icons/flags/kor-flag.svg';
import KWDFlag from 'assets/icons/flags/kwd-flag.svg';
import LAOFlag from 'assets/icons/flags/lao-flag.svg';
import LBNFlag from 'assets/icons/flags/lbn-flag.svg';
import LBRFlag from 'assets/icons/flags/lbr-flag.svg';
import LKAFlag from 'assets/icons/flags/lka-flag.svg';
import MADFlag from 'assets/icons/flags/mad-flag.svg';
import MOPFlag from 'assets/icons/flags/mop-flag.svg';
import MURFlag from 'assets/icons/flags/mur-flag.svg';
import MXNFlag from 'assets/icons/flags/mxn-flag.svg';
import MYSFlag from 'assets/icons/flags/mys-flag.svg';
import NOKFlag from 'assets/icons/flags/nok-flag.svg';
import NPLFlag from 'assets/icons/flags/npl-flag.svg';
import NZLFlag from 'assets/icons/flags/nzl-flag.svg';
import OMRFlag from 'assets/icons/flags/omr-flag.svg';
import PAKFlag from 'assets/icons/flags/pak-flag.svg';
import PGKFlag from 'assets/icons/flags/pgk-flag.svg';
import PHLFlag from 'assets/icons/flags/phl-flag.svg';
import PLNFlag from 'assets/icons/flags/pln-flag.svg';
import PNGFlag from 'assets/icons/flags/png-flag.svg';
import POLFlag from 'assets/icons/flags/pol-flag.svg';
import QARFlag from 'assets/icons/flags/qar-flag.svg';
import SARFlag from 'assets/icons/flags/sar-flag.svg';
import SBDFlag from 'assets/icons/flags/sbd-flag.svg';
import SEKFlag from 'assets/icons/flags/sek-flag.svg';
import SGPFlag from 'assets/icons/flags/sgp-flag.svg';
import THAFlag from 'assets/icons/flags/tha-flag.svg';
import TLSFlag from 'assets/icons/flags/tls-flag.svg';
import TONFlag from 'assets/icons/flags/ton-flag.svg';
import TOPFlag from 'assets/icons/flags/top-flag.svg';
import TURFlag from 'assets/icons/flags/tur-flag.svg';
import TWDFlag from 'assets/icons/flags/twd-flag.svg';
import UGAFlag from 'assets/icons/flags/uga-flag.svg';
import USAFlag from 'assets/icons/flags/usa-flag.svg';
import VNMFlag from 'assets/icons/flags/vnm-flag.svg';
import VUVFlag from 'assets/icons/flags/vuv-flag.svg';
import WSMFlag from 'assets/icons/flags/wsm-flag.svg';
import WSTFlag from 'assets/icons/flags/wst-flag.svg';
import XPFFlag from 'assets/icons/flags/xpf-flag.svg';
import ZARFlag from 'assets/icons/flags/zar-flag.svg';

export const COUNTRY_FLAG_REFERENCES = {
  BGD: BGDFlag,
  CAN: CANFlag,
  CHN: CHNFlag,
  COG: COGFlag,
  EGY: EGYFlag,
  FJI: FJIFlag,
  FRA: FRAFlag,
  HKG: HKGFlag,
  IDN: IDNFlag,
  IND: INDFlag,
  ITA: ITAFlag,
  JPN: JPNFlag,
  KHM: KHMFlag,
  KOR: KORFlag,
  LAO: LAOFlag,
  LBN: LBNFlag,
  LBR: LBRFlag,
  LKA: LKAFlag,
  MYS: MYSFlag,
  NPL: NPLFlag,
  NZL: NZLFlag,
  PAK: PAKFlag,
  PHL: PHLFlag,
  PNG: PNGFlag,
  POL: POLFlag,
  SGP: SGPFlag,
  THA: THAFlag,
  TLS: TLSFlag,
  TON: TONFlag,
  TUR: TURFlag,
  UGA: UGAFlag,
  USA: USAFlag,
  VNM: VNMFlag,
  WSM: WSMFlag,
  AUD: AUDFlag,
};

export const CURRENCY_FLAG_REFERENCES = {
  BDT: BGDFlag,
  CNY: CHNFlag,
  EGP: EGYFlag,
  IDR: IDNFlag,
  INR: INDFlag,
  JPY: JPNFlag,
  KRW: KORFlag,
  LKR: LKAFlag,
  MYR: MYSFlag,
  NPR: NPLFlag,
  PKR: PAKFlag,
  PHP: PHLFlag,
  SGD: SGPFlag,
  THB: THAFlag,
  TRY: TURFlag,
  USD: USAFlag,
  VND: VNMFlag,
  AUD: AUDFlag,
  EUR: EURFlag,
  GBP: GBPFlag,
  CAD: CANFlag,
  DKK: DKKFlag,
  HKD: HKGFlag,
  CHF: CHFFlag,
  FJD: FJIFlag,
  NZD: NZLFlag,
  SAR: SARFlag,
  SEK: SEKFlag,
  NOK: NOKFlag,
  BND: BNDFlag,
  AED: AEDFlag,
  TWD: TWDFlag,
  ZAR: ZARFlag,
  ILS: ILSFlag,
  MXN: MXNFlag,
  OMR: OMRFlag,
  PGK: PGKFlag,
  HUF: HUFFlag,
  CZK: CZKFlag,
  XPF: XPFFlag,
  CLP: CLPFlag,
  PLN: PLNFlag,
  BRL: BRLFlag,
  SBD: SBDFlag,
  KWD: KWDFlag,
  JOD: JODFlag,
  COP: COPFlag,
  MAD: MADFlag,
  VUV: VUVFlag,
  ISK: ISKFlag,
  KES: KESFlag,
  MUR: MURFlag,
  QAR: QARFlag,
  MOP: MOPFlag,
  ARS: ARSFlag,
  TOP: TOPFlag,
  WST: WSTFlag,
};

export const COUNTRIES = [
  { label: 'AFGHANISTAN', value: 'AFG', code: 'AF' },
  { label: 'ALAND ISLANDS', value: 'ALA', code: 'AX' },
  { label: 'ALBANIA', value: 'ALB', code: 'AL' },
  { label: 'ALGERIA', value: 'DZA', code: 'DZ' },
  { label: 'AMERICAN SAMOA', value: 'ASM', code: 'AS' },
  { label: 'ANDORRA', value: 'AND', code: 'AD' },
  { label: 'ANGOLA', value: 'AGO', code: 'AO' },
  { label: 'ANGUILLA', value: 'AIA', code: 'AI' },
  { label: 'ANTARCTICA', value: 'ATA', code: 'AQ' },
  { label: 'ANTIGUA AND BARBUDA', value: 'ATG', code: 'AG' },
  { label: 'ARGENTINA', value: 'ARG', code: 'AR' },
  { label: 'ARMENIA', value: 'ARM', code: 'AM' },
  { label: 'ARUBA', value: 'ABW', code: 'AW' },
  { label: 'AUSTRALIA', value: 'AUS', code: 'AU' },
  { label: 'AUSTRIA', value: 'AUT', code: 'AT' },
  { label: 'AZERBAIJAN', value: 'AZE', code: 'AZ' },
  { label: 'BAHAMAS', value: 'BHS', code: 'BS' },
  { label: 'BAHRAIN', value: 'BHR', code: 'BH' },
  { label: 'BANGLADESH', value: 'BGD', code: 'BD' },
  { label: 'BARBADOS', value: 'BRB', code: 'BB' },
  { label: 'BELARUS', value: 'BLR', code: 'BY' },
  { label: 'BELGIUM', value: 'BEL', code: 'BE' },
  { label: 'BELIZE', value: 'BLZ', code: 'BZ' },
  { label: 'BENIN', value: 'BEN', code: 'BJ' },
  { label: 'BERMUDA', value: 'BMU', code: 'BM' },
  { label: 'BHUTAN', value: 'BTN', code: 'BT' },
  { label: 'BOLIVIA', value: 'BOL', code: 'BO' },
  { label: 'BOSNIA AND HERZEGOVINA', value: 'BIH', code: 'BA' },
  { label: 'BOTSWANA', value: 'BWA', code: 'BW' },
  { label: 'BOUVET ISLAND', value: 'BVT', code: 'BV' },
  { label: 'BRAZIL', value: 'BRA', code: 'BR' },
  { label: 'BRITISH INDIAN OCEAN TERRITORY', value: 'IOT', code: 'IO' },
  { label: 'BRUNEI DARUSSALAM', value: 'BRN', code: 'BN' },
  { label: 'BULGARIA', value: 'BGR', code: 'BG' },
  { label: 'BURKINA FASO', value: 'BFA', code: 'BF' },
  { label: 'BURUNDI', value: 'BDI', code: 'BI' },
  { label: 'CAMBODIA', value: 'KHM', code: 'KH' },
  { label: 'CAMEROON', value: 'CMR', code: 'CM' },
  { label: 'CANADA', value: 'CAN', code: 'CA' },
  { label: 'CAPE VERDE', value: 'CPV', code: 'CV' },
  { label: 'CAYMAN ISLANDS', value: 'CYM', code: 'KY' },
  { label: 'CENTRAL AFRICAN REPUBLIC', value: 'CAF', code: 'CF' },
  { label: 'CHAD', value: 'TCD', code: 'TD' },
  { label: 'CHILE', value: 'CHL', code: 'CL' },
  { label: 'CHINA', value: 'CHN', code: 'CN' },
  { label: 'CHRISTMAS ISLAND', value: 'CXR', code: 'CX' },
  { label: 'COCOS (KEELING) ISLANDS', value: 'CCK', code: 'CC' },
  { label: 'COLOMBIA', value: 'COL', code: 'CO' },
  { label: 'COMOROS', value: 'COM', code: 'KM' },
  { label: 'CONGO', value: 'COG', code: 'CG' },
  { label: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE', value: 'COD', code: 'CD' },
  { label: 'COOK ISLANDS', value: 'COK', code: 'CK' },
  { label: 'COSTA RICA', value: 'CRI', code: 'CR' },
  { label: "COTE D'IVOIRE", value: 'CIV', code: 'CI' },
  { label: 'CROATIA', value: 'HRV', code: 'HR' },
  { label: 'CUBA', value: 'CUB', code: 'CU' },
  { label: 'CYPRUS', value: 'CYP', code: 'CY' },
  { label: 'CZECH REPUBLIC', value: 'CZE', code: 'CZ' },
  { label: 'DENMARK', value: 'DNK', code: 'DK' },
  { label: 'DJIBOUTI', value: 'DJI', code: 'DJ' },
  { label: 'DOMINICA', value: 'DMA', code: 'DM' },
  { label: 'DOMINICAN REPUBLIC', value: 'DOM', code: 'DO' },
  { label: 'ECUADOR', value: 'ECU', code: 'EC' },
  { label: 'EGYPT', value: 'EGY', code: 'EG' },
  { label: 'EL SALVADOR', value: 'SLV', code: 'SV' },
  { label: 'EQUATORIAL GUINEA', value: 'GNQ', code: 'GQ' },
  { label: 'ERITREA', value: 'ERI', code: 'ER' },
  { label: 'ESTONIA', value: 'EST', code: 'EE' },
  { label: 'ETHIOPIA', value: 'ETH', code: 'ET' },
  { label: 'FALKLAND ISLANDS (MALVINAS)', value: 'FLK', code: 'FK' },
  { label: 'FAROE ISLANDS', value: 'FRO', code: 'FO' },
  { label: 'FIJI', value: 'FJI', code: 'FJ' },
  { label: 'FINLAND', value: 'FIN', code: 'FI' },
  { label: 'FRANCE', value: 'FRA', code: 'FR' },
  { label: 'FRENCH GUIANA', value: 'GUF', code: 'GF' },
  { label: 'FRENCH POLYNESIA', value: 'PYF', code: 'PF' },
  { label: 'FRENCH SOUTHERN TERRITORIES', value: 'ATF', code: 'TF' },
  { label: 'GABON', value: 'GAB', code: 'GA' },
  { label: 'GAMBIA', value: 'GMB', code: 'GM' },
  { label: 'GEORGIA', value: 'GEO', code: 'GE' },
  { label: 'GERMANY', value: 'DEU', code: 'DE' },
  { label: 'GHANA', value: 'GHA', code: 'GH' },
  { label: 'GIBRALTAR', value: 'GIB', code: 'GI' },
  { label: 'GREECE', value: 'GRC', code: 'GR' },
  { label: 'GREENLAND', value: 'GRL', code: 'GL' },
  { label: 'GRENADA', value: 'GRD', code: 'GD' },
  { label: 'GUADELOUPE', value: 'GLP', code: 'GP' },
  { label: 'GUAM', value: 'GUM', code: 'GU' },
  { label: 'GUATEMALA', value: 'GTM', code: 'GT' },
  { label: 'GUERNSEY', value: 'GGY', code: 'GG' },
  { label: 'GUINEA', value: 'GIN', code: 'GN' },
  { label: 'GUINEA-BISSAU', value: 'GNB', code: 'GW' },
  { label: 'GUYANA', value: 'GUY', code: 'GY' },
  { label: 'HAITI', value: 'HTI', code: 'HT' },
  { label: 'HEARD ISLAND AND MCDONALD ISLANDS', value: 'HMD', code: 'HM' },
  { label: 'HOLY SEE (VATICAN CITY STATE)', value: 'VAT', code: 'VA' },
  { label: 'HONDURAS', value: 'HND', code: 'HN' },
  { label: 'HONG KONG', value: 'HKG', code: 'HK' },
  { label: 'HUNGARY', value: 'HUN', code: 'HU' },
  { label: 'ICELAND', value: 'ISL', code: 'IS' },
  { label: 'INDIA', value: 'IND', code: 'IN' },
  { label: 'INDONESIA', value: 'IDN', code: 'ID' },
  { label: 'IRAN, ISLAMIC REPUBLIC OF', value: 'IRN', code: 'IR' },
  { label: 'IRAQ', value: 'IRQ', code: 'IQ' },
  { label: 'IRELAND', value: 'IRL', code: 'IE' },
  { label: 'ISLE OF MAN', value: 'IMN', code: 'IM' },
  { label: 'ISRAEL', value: 'ISR', code: 'IL' },
  { label: 'ITALY', value: 'ITA', code: 'IT' },
  { label: 'JAMAICA', value: 'JAM', code: 'JM' },
  { label: 'JAPAN', value: 'JPN', code: 'JP' },
  { label: 'JERSEY', value: 'JEY', code: 'JE' },
  { label: 'JORDAN', value: 'JOR', code: 'JO' },
  { label: 'KAZAKHSTAN', value: 'KAZ', code: 'KZ' },
  { label: 'KENYA', value: 'KEN', code: 'KE' },
  { label: 'KIRIBATI', value: 'KIR', code: 'KI' },
  { label: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF", value: 'PRK', code: 'KP' },
  { label: 'KOREA, REPUBLIC OF', value: 'KOR', code: 'KR' },
  { label: 'KUWAIT', value: 'KWT', code: 'KW' },
  { label: 'KYRGYZSTAN', value: 'KGZ', code: 'KG' },
  { label: 'LAOS', value: 'LAO', code: 'LA' },
  { label: 'LATVIA', value: 'LVA', code: 'LV' },
  { label: 'LEBANON', value: 'LBN', code: 'LB' },
  { label: 'LESOTHO', value: 'LSO', code: 'LS' },
  { label: 'LIBERIA', value: 'LBR', code: 'LR' },
  { label: 'LIBYAN ARAB JAMAHIRIYA', value: 'LBY', code: 'LY' },
  { label: 'LIECHTENSTEIN', value: 'LIE', code: 'LI' },
  { label: 'LITHUANIA', value: 'LTU', code: 'LT' },
  { label: 'LUXEMBOURG', value: 'LUX', code: 'LU' },
  { label: 'MACAO', value: 'MAC', code: 'MO' },
  {
    label: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
    value: 'MKD',
    code: 'MK',
  },
  { label: 'MADAGASCAR', value: 'MDG', code: 'MG' },
  { label: 'MALAWI', value: 'MWI', code: 'MW' },
  { label: 'MALAYSIA', value: 'MYS', code: 'MY' },
  { label: 'MALDIVES', value: 'MDV', code: 'MV' },
  { label: 'MALI', value: 'MLI', code: 'ML' },
  { label: 'MALTA', value: 'MLT', code: 'MT' },
  { label: 'MARSHALL ISLANDS', value: 'MHL', code: 'MH' },
  { label: 'MARTINIQUE', value: 'MTQ', code: 'MQ' },
  { label: 'MAURITANIA', value: 'MRT', code: 'MR' },
  { label: 'MAURITIUS', value: 'MUS', code: 'MU' },
  { label: 'MAYOTTE', value: 'MYT', code: 'YT' },
  { label: 'MEXICO', value: 'MEX', code: 'MX' },
  { label: 'MICRONESIA, FEDERATED STATES OF', value: 'FSM', code: 'FM' },
  { label: 'MOLDOVA, REPUBLIC OF', value: 'MDA', code: 'MD' },
  { label: 'MONACO', value: 'MCO', code: 'MC' },
  { label: 'MONGOLIA', value: 'MNG', code: 'MN' },
  { label: 'MONTSERRAT', value: 'MSR', code: 'MS' },
  { label: 'MOROCCO', value: 'MAR', code: 'MA' },
  { label: 'MOZAMBIQUE', value: 'MOZ', code: 'MZ' },
  { label: 'MYANMAR', value: 'MMR', code: 'MM' },
  { label: 'NAMIBIA', value: 'NAM', code: 'NA' },
  { label: 'NAURU', value: 'NRU', code: 'NR' },
  { label: 'NEPAL', value: 'NPL', code: 'NP' },
  { label: 'NETHERLANDS', value: 'NLD', code: 'NL' },
  { label: 'NETHERLANDS ANTILLES', value: 'ANT', code: 'AN' },
  { label: 'NEW CALEDONIA', value: 'NCL', code: 'NC' },
  { label: 'NEW ZEALAND', value: 'NZL', code: 'NZ' },
  { label: 'NICARAGUA', value: 'NIC', code: 'NI' },
  { label: 'NIGER', value: 'NER', code: 'NE' },
  { label: 'NIGERIA', value: 'NGA', code: 'NG' },
  { label: 'NIUE', value: 'NIU', code: 'NU' },
  { label: 'NORFOLK ISLAND', value: 'NFK', code: 'NF' },
  { label: 'NORTHERN MARIANA ISLANDS', value: 'MNP', code: 'MP' },
  { label: 'NORWAY', value: 'NOR', code: 'NO' },
  { label: 'OMAN', value: 'OMN', code: 'OM' },
  { label: 'PAKISTAN', value: 'PAK', code: 'PK' },
  { label: 'PALAU', value: 'PLW', code: 'PW' },
  { label: 'PALESTINIAN TERRITORY, OCCUPIED', value: 'PSE', code: 'PS' },
  { label: 'PANAMA', value: 'PAN', code: 'PA' },
  { label: 'PAPUA NEW GUINEA', value: 'PNG', code: 'PG' },
  { label: 'PARAGUAY', value: 'PRY', code: 'PY' },
  { label: 'PERU', value: 'PER', code: 'PE' },
  { label: 'PHILIPPINES', value: 'PHL', code: 'PH' },
  { label: 'PITCAIRN', value: 'PCN', code: 'PN' },
  { label: 'POLAND', value: 'POL', code: 'PL' },
  { label: 'PORTUGAL', value: 'PRT', code: 'PT' },
  { label: 'PUERTO RICO', value: 'PRI', code: 'PR' },
  { label: 'QATAR', value: 'QAT', code: 'QA' },
  { label: 'REUNION', value: 'REU', code: 'RE' },
  { label: 'ROMANIA', value: 'ROU', code: 'RO' },
  { label: 'RUSSIAN FEDERATION', value: 'RUS', code: 'RU' },
  { label: 'RWANDA', value: 'RWA', code: 'RW' },
  { label: 'SAINT HELENA', value: 'SHN', code: 'SH' },
  { label: 'SAINT KITTS AND NEVIS', value: 'KNA', code: 'KN' },
  { label: 'SAINT LUCIA', value: 'LCA', code: 'LC' },
  { label: 'SAINT PIERRE AND MIQUELON', value: 'SPM', code: 'PM' },
  { label: 'SAINT VINCENT AND THE GRENADINES', value: 'VCT', code: 'VC' },
  { label: 'SAMOA', value: 'WSM', code: 'WS' },
  { label: 'SAN MARINO', value: 'SMR', code: 'SM' },
  { label: 'SAO TOME AND PRINCIPE', value: 'STP', code: 'ST' },
  { label: 'SAUDI ARABIA', value: 'SAU', code: 'SA' },
  { label: 'SENEGAL', value: 'SEN', code: 'SN' },
  { label: 'SERBIA', value: 'SRB', code: 'RS' },
  { label: 'SEYCHELLES', value: 'SYC', code: 'SC' },
  { label: 'SIERRA LEONE', value: 'SLE', code: 'SL' },
  { label: 'SINGAPORE', value: 'SGP', code: 'SG' },
  { label: 'SLOVAKIA', value: 'SVK', code: 'SK' },
  { label: 'SLOVENIA', value: 'SVN', code: 'SI' },
  { label: 'SOLOMON ISLANDS', value: 'SLB', code: 'SB' },
  { label: 'SOMALIA', value: 'SOM', code: 'SO' },
  { label: 'SOUTH AFRICA', value: 'ZAF', code: 'ZA' },
  {
    label: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
    value: 'SGS',
    code: 'GS',
  },
  { label: 'SPAIN', value: 'ESP', code: 'ES' },
  { label: 'SRI LANKA', value: 'LKA', code: 'LK' },
  { label: 'SUDAN', value: 'SDN', code: 'SD' },
  { label: 'SURINAME', value: 'SUR', code: 'SR' },
  { label: 'SVALBARD AND JAN MAYEN', value: 'SJM', code: 'SJ' },
  { label: 'SWAZILAND', value: 'SWZ', code: 'SZ' },
  { label: 'SWEDEN', value: 'SWE', code: 'SE' },
  { label: 'SWITZERLAND', value: 'CHE', code: 'CH' },
  { label: 'SYRIAN ARAB REPUBLIC', value: 'SYR', code: 'SY' },
  { label: 'TAIWAN, PROVINCE OF CHINA', value: 'TWN', code: 'TW' },
  { label: 'TAJIKISTAN', value: 'TJK', code: 'TJ' },
  { label: 'TANZANIA, UNITED REPUBLIC OF', value: 'TZA', code: 'TZ' },
  { label: 'THAILAND', value: 'THA', code: 'TH' },
  { label: 'TIMOR-LESTE', value: 'TLS', code: 'TL' },
  { label: 'TOGO', value: 'TGO', code: 'TG' },
  { label: 'TOKELAU', value: 'TKL', code: 'TK' },
  { label: 'TONGA', value: 'TON', code: 'TO' },
  { label: 'TRINIDAD AND TOBAGO', value: 'TTO', code: 'TT' },
  { label: 'TUNISIA', value: 'TUN', code: 'TN' },
  { label: 'TURKEY', value: 'TUR', code: 'TR' },
  { label: 'TURKMENISTAN', value: 'TKM', code: 'TM' },
  { label: 'TURKS AND CAICOS ISLANDS', value: 'TCA', code: 'TC' },
  { label: 'TUVALU', value: 'TUV', code: 'TV' },
  { label: 'UGANDA', value: 'UGA', code: 'UG' },
  { label: 'UKRAINE', value: 'UKR', code: 'UA' },
  { label: 'UNITED ARAB EMIRATES', value: 'ARE', code: 'AE' },
  { label: 'ENGLAND', value: 'GBR', code: 'GB' },
  { label: 'UNITED STATES', value: 'USA', code: 'US' },
  { label: 'UNITED STATES MINOR OUTLYING ISLANDS', value: 'UMI', code: 'UM' },
  { label: 'URUGUAY', value: 'URY', code: 'UY' },
  { label: 'UZBEKISTAN', value: 'UZB', code: 'UZ' },
  { label: 'VANUATU', value: 'VUT', code: 'VU' },
  { label: 'VENEZUELA', value: 'VEN', code: 'VE' },
  { label: 'VIETNAM', value: 'VNM', code: 'VN' },
  { label: 'VIRGIN ISLANDS, BRITISH', value: 'VGB', code: 'VG' },
  { label: 'VIRGIN ISLANDS, U.S.', value: 'VIR', code: 'VI' },
  { label: 'WALLIS AND FUTUNA', value: 'WLF', code: 'WF' },
  { label: 'WESTERN SAHARA', value: 'ESH', code: 'EH' },
  { label: 'YEMEN', value: 'YEM', code: 'YE' },
  { label: 'ZAMBIA', value: 'ZMB', code: 'ZM' },
  { label: 'ZIMBABWE', value: 'ZWE', code: 'ZW' },
  { label: 'SOUTH SUDAN', value: 'SSD', code: 'SS' },
];

export const COUNTRY_NAME_DOMAIN_MAPPING = [
  {
    countryName: 'INDIA',
    countryCode: 'IND',
    dmCode: 'BANK',
    currencyCode: 'INR',
  },
  {
    countryName: 'PHILIPPINES',
    countryCode: 'PHL',
    dmCode: 'BANK',
    currencyCode: 'PHP',
  },
  {
    countryName: 'CAMBODIA',
    countryCode: 'KHM',
    dmCode: 'BANK',
    currencyCode: 'USD',
  },
  {
    countryName: 'UNITED STATES',
    countryCode: 'USA',
    dmCode: 'BANK',
    currencyCode: 'USD',
  },
  {
    countryName: 'INDONESIA',
    countryCode: 'IDN',
    dmCode: 'BANK',
    currencyCode: 'IDR',
  },
  {
    countryName: 'SRI LANKA',
    countryCode: 'LKA',
    dmCode: 'BANK',
    currencyCode: 'LKR',
  },
  {
    countryName: 'MALAYSIA',
    countryCode: 'MYS',
    dmCode: 'BANK',
    currencyCode: 'MYR',
  },
  {
    countryName: 'NEPAL',
    countryCode: 'NPL',
    dmCode: 'BANK',
    currencyCode: 'NPR',
  },
  {
    countryName: 'PAKISTAN',
    countryCode: 'PAK',
    dmCode: 'BANK',
    currencyCode: 'PKR',
  },
  {
    countryName: 'SINGAPORE',
    countryCode: 'SGP',
    dmCode: 'BANK',
    currencyCode: 'SGD',
  },
  {
    countryName: 'THAILAND',
    countryCode: 'THA',
    dmCode: 'BANK',
    currencyCode: 'THB',
  },
  {
    countryName: 'JAPAN',
    countryCode: 'JPN',
    dmCode: 'BANK',
    currencyCode: 'JPY',
  },
  {
    countryName: 'BANGLADESH',
    countryCode: 'BGD',
    dmCode: 'BANK',
    currencyCode: 'BDT',
  },
  {
    countryName: 'KOREA, REPUBLIC OF',
    countryCode: 'KOR',
    dmCode: 'BANK',
    currencyCode: 'KRW',
  },
  {
    countryName: 'TURKEY',
    countryCode: 'TUR',
    dmCode: 'BANK',
    currencyCode: 'TRY',
  },
  {
    countryName: 'CHINA',
    countryCode: 'CHN',
    dmCode: 'EWALLET',
    currencyCode: 'CNY',
  },
  {
    countryName: 'EGYPT',
    countryCode: 'EGY',
    dmCode: 'BANK',
    currencyCode: 'EGP',
  },
  {
    countryName: 'VIETNAM',
    countryCode: 'VNM',
    dmCode: 'HOME',
    currencyCode: 'VND',
  },
  {
    countryName: 'HONG KONG',
    countryCode: 'HKG',
    dmCode: 'BANK',
    currencyCode: 'HKD',
  },
];
