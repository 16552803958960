import BuyCurrencyIcon from 'assets/ver2/icons/buy-currency-icon.svg';
import SearchIcon from 'assets/ver2/icons/search-icon.svg';
import SortAZIcon from 'assets/ver2/icons/sort-az-icon.svg';
import SortZAIcon from 'assets/ver2/icons/sort-za-icon.svg';
import FXRatesSection from 'components/Dashboard/components/FXRatesSection';
import FXRatesSectionMobile from 'components/Dashboard/components/FXRatesSection/mobile';
import FXTopRatesSection from 'components/Dashboard/components/FXTopRatesSection';
import FXTopRatesSectionMobile from 'components/Dashboard/components/FXTopRatesSection/mobile';
import ButtonCommon from 'components/common/ButtonCommon';
import CollapsibleSideMenu from 'components/common/CollapsibleSideMenu';
import MobileTabMenu from 'components/common/MobileTabMenu';
import NavbarFooter from 'components/common/NavbarFooter/ver2';
import CalculatorModal from 'components/fx/CalculatorModal';
import {
  DESKTOP_MIN_WIDTH,
  ORDER_EMAIL,
  SCREEN_ID_EXCHANGE_HOME,
} from 'constants';
import { datePicker } from 'helpers';
import Cookies from 'helpers/cookies';
import useFxAddTransaction, {
  FX_MAKE_PAYMENT_KEY,
} from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useEvent from 'hooks/useEvent';
import useExchangeRates from 'hooks/useExchangeRates';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import React, { useEffect, useState } from 'react';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';

const SCREEN_ID = SCREEN_ID_EXCHANGE_HOME;

const FxDashboardPage = () => {
  const { t, locate } = useLang();
  const { state, dispatch } = useStore();
  const { isMobile, isTablet } = useDetectDevice();

  const { fxCurrenciesPrefer } = useExchangeRates();
  const { getAllFXRates } = useFxAddTransaction();
  const [allFxRates, setAllFxRates] = useState(null);
  const { isMenuExpand } = state; // For Collapsible Menu

  const [topRates, setTopRates] = useState([]);
  const [remainingRates, setRemainingRates] = useState([]);
  const [filteredRates, setFilteredRates] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAscending, setIsAscending] = useState(true);
  const [isShowCalculatorModal, setShowCalculatorModal] = useState(false);

  const { resetAddTransactionInformation } = useFxFormAddTransactionStore();

  const fetchData = async () => {
    dispatch({ type: SET_SHOW_LOADING, payload: true });
    try {
      getAllFXRates(setAllFxRates);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!searchQuery) {
      fetchData();
      const interval = setInterval(fetchData, 600000);
      return () => clearInterval(interval);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (allFxRates && fxCurrenciesPrefer) {
      try {
        const { topRates, remainingRates } = getTopRatesAndRemaining(
          allFxRates,
          fxCurrenciesPrefer
        );
        setTopRates(topRates);
        setRemainingRates(remainingRates);
      } catch (error) {
        console.error(error.message);
        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }
    }
  }, [allFxRates, fxCurrenciesPrefer]);

  const getTopRatesAndRemaining = (allFxRates, fxCurrenciesPrefer) => {
    if (!Array.isArray(allFxRates) || !Array.isArray(fxCurrenciesPrefer)) {
      throw new Error(
        'Both allFxRates and fxCurrenciesPrefer should be arrays.'
      );
    }

    const filteredArray = allFxRates.filter((item) =>
      fxCurrenciesPrefer.includes(item.currencyCode)
    );

    const insertIntoTop5 = (top5Array, item) => {
      if (top5Array.length < 5) {
        top5Array.push(item);
      } else {
        let minIndex = 0;
        for (let i = 1; i < 5; i++) {
          if (
            parseFloat(top5Array[i].rate) < parseFloat(top5Array[minIndex].rate)
          ) {
            minIndex = i;
          }
        }
        if (parseFloat(item.rate) > parseFloat(top5Array[minIndex].rate)) {
          top5Array[minIndex] = item;
        }
      }
    };

    const topRates = [];
    filteredArray.forEach((item) => insertIntoTop5(topRates, item));

    // Sort topRates to match the order of fxCurrenciesPrefer
    topRates.sort(
      (a, b) =>
        fxCurrenciesPrefer.indexOf(a.currencyCode) -
        fxCurrenciesPrefer.indexOf(b.currencyCode)
    );

    const remainingRates = allFxRates.filter(
      (item) =>
        !topRates.some((topItem) => topItem.currencyCode === item.currencyCode)
    );

    setFilteredRates(remainingRates);

    return { topRates, remainingRates };
  };

  const handleSort = () => {
    setIsAscending(!isAscending);
    const sortedArray = [...filteredRates].sort((a, b) => {
      if (isAscending) {
        return a.currency.localeCompare(b.currency);
      } else {
        return b.currency.localeCompare(a.currency);
      }
    });
    setFilteredRates(sortedArray);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleOnShowCalculatorModal = () => {
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);
    resetAddTransactionInformation();
    setShowCalculatorModal(true);
  };
  const handleOnCloseCalculatorModal = () => {
    setShowCalculatorModal(false);
  };

  useEffect(() => {
    if (searchQuery) {
      setFilteredRates(
        allFxRates.filter((item) => {
          const lowerCaseQuery = searchQuery.trim().toLowerCase();
          return (
            item.currency.toLowerCase().includes(lowerCaseQuery) ||
            item.currencyCode.toLowerCase().includes(lowerCaseQuery) ||
            item.countryCode.toLowerCase().includes(lowerCaseQuery)
          );
        })
      );
    } else {
      setFilteredRates(remainingRates);
    }
  }, [searchQuery]);

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [],
    modalStyles: {},
    modalOptions: {},
  });
  useEffect(() => {
    if (!isShowEvent) return;
    showEventModal();
  }, [isShowEvent]);

  const renderSearchFilterSection = () => {
    return (
      <>
        <FilteredWrap>
          <SearchCurrencyStyled>
            <SearchInput
              type="text"
              value={searchQuery || ''}
              placeholder={t('fx_currency_search')}
              onChange={handleSearch}
              onKeyDown={handleSearch}
            />
            <SearchIconStyled src={SearchIcon} width={24} height={24} alt="" />
            <ButtonCommon
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
              isFill={false}
              styles={{
                paddingInline: 0,
              }}
              borderNameVariable="--border-filter"
              iconSrc={isAscending ? SortAZIcon : SortZAIcon}
              onClick={handleSort}
            />
          </SearchCurrencyStyled>
        </FilteredWrap>
      </>
    );
  };
  if (isMobile) {
    return (
      <>
        {isShowCalculatorModal && (
          <CalculatorModal
            isOpen={isShowCalculatorModal}
            onClose={handleOnCloseCalculatorModal}
          />
        )}

        <WrapMobile>
          <MobileTabMenu />
          <Title>{t('fx_exchange_rates_title')}</Title>
          <Wrap>{datePicker.getFullTimeStringTranslate(locate)}</Wrap>
          <FXTopRatesSectionMobile FXRatesData={topRates} />
          <Title>{t('fx_other_currencies_title')}</Title>
          {renderSearchFilterSection()}
        </WrapMobile>
        <FXRatesSectionMobile FXRatesData={filteredRates} />
        <FxDashboardMobilePageStyled>
          <NavbarFooter />
        </FxDashboardMobilePageStyled>
      </>
    );
  }
  if (isTablet) {
    return (
      <>
        {isShowCalculatorModal && (
          <CalculatorModal
            isOpen={isShowCalculatorModal}
            onClose={handleOnCloseCalculatorModal}
          />
        )}

        <WrapMobile>
          <MobileTabMenu />
          <Title>{t('fx_exchange_rates_title')}</Title>
          <Wrap>{datePicker.getFullTimeStringTranslate(locate)}</Wrap>
          <FXTopRatesSectionMobile FXRatesData={topRates} />
          <Title>{t('fx_other_currencies_title')}</Title>
          {renderSearchFilterSection()}
        </WrapMobile>
        <FXRatesSectionMobile FXRatesData={filteredRates} />
        <FxDashboardMobilePageStyled>
          <NavbarFooter />
        </FxDashboardMobilePageStyled>
      </>
    );
  }
  return (
    <>
      {isShowCalculatorModal && (
        <CalculatorModal
          isOpen={isShowCalculatorModal}
          onClose={handleOnCloseCalculatorModal}
        />
      )}

      <LayoutContainer>
        <CollapsibleSideMenu />

        <OuterContainerStyled isMenuExpand={isMenuExpand}>
          <FxDashboardPageStyled>
            <Title>{t('fx_exchange_rates_title')}</Title>
            <Wrap>
              {datePicker.getFullTimeStringTranslate(locate)}
              <ButtonCommon
                value={t('fx_buy_currency_label')}
                color="var(--c-primary)"
                background="var(--bg-primary)"
                isFill={true}
                styles={{
                  paddingInline: 0,
                }}
                buttonIconStyles={{
                  float: 'right',
                }}
                iconSrc={BuyCurrencyIcon}
                onClick={handleOnShowCalculatorModal}
              />
            </Wrap>
            <FXTopRatesSection FXRatesData={topRates} />
            <Title>{t('fx_other_currencies_title')}</Title>
            {renderSearchFilterSection()}
            <FXRatesSection FXRatesData={filteredRates} />
          </FxDashboardPageStyled>
        </OuterContainerStyled>
      </LayoutContainer>
    </>
  );
};

const LayoutContainer = styled.div`
  display: flex;
  height: 100vh;
  height: fit-content;
`;

const OuterContainerStyled = styled.div`
  flex-grow: 1;
  margin-left: ${(props) => (props.isMenuExpand ? '300px' : '98px')};
  transition: margin-left 0.3s;
  height: fit-content;
`;

const FxDashboardPageStyled = styled.div`
  margin: 70px 0px 0px 0px;
  box-shadow: none;
  height: calc(100vh - 70px);
  padding: 16px 24px;
  position: relative;
  background-color: var(--ds-c-white);
  border-radius: 12px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: fit-content;
    margin: 96px 0px 47px 0;
    padding: 24px 40px;
    max-width: calc(1440px-300px);
  }
`;

const FxDashboardMobilePageStyled = styled.div`
  margin: 0px;
  box-shadow: none;
  height: fit-content;
  padding: 0px 24px;
  position: relative;
  background-color: var(--ds-c-white);
  border-radius: 12px;
`;

const WrapMobile = styled.div`
  margin: 70px 0px 0px 0px;
  padding: 16px 24px 0 24px;
`;

// const ExchangeRatesStyled = styled.div`
//   height: fit-content;
//   margin: 120px 131px 16px 131px;
//   position: relative;
// `;

const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;
  margin: 10px 0 10px 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: var(--ds-c-black);
    margin: 0 0 10px 0;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 24px;
  margin-bottom: 8px;

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-black);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    height: 44px;
    margin-bottom: 24px;
  }
`;

const FilteredWrap = styled.div`
  height: 44px;
  margin-bottom: 10px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 16px;
  }
`;

const SearchCurrencyStyled = styled.div`
  position: relative;
  height: 44px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: unset;
  }
`;

const SearchInput = styled.input`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  border: 1px solid #b0b6bd;
  border-radius: 12px;
  background: var(--ds-c-white);
  width: calc(100% - 52px);
  height: 44px;
  padding: 10px;
  padding-right: 32px;
  float: left;
  margin-right: 8px;

  &:focus,
  &:focus-visible {
    border: 1px solid #b0b6bd !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: #b0b6bd;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    width: 287px;
  }
`;
const SearchIconStyled = styled.img`
  position: absolute;
  top: 10px;
  left: calc(100% - 84px);
  cursor: pointer;
  user-select: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    left: 254px;
  }
`;
export default FxDashboardPage;
