export const paragraphMixin = `
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--ds-c-grey-dark);
    padding: 0;
    margin: 0;
`;

export const textEllipsisSecondLineMixin = `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const scrollbarThumb = `
    &::-webkit-scrollbar {
        width: 5px;
        height: 5px !important;
    }
    &::-webkit-scrollbar-thumb {
        background: var(--ds-c-grey-hover);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
`;
