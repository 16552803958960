/* eslint-disable no-unused-vars */
import { DESKTOP_COLLAPSE_MIN_WIDTH, DESKTOP_MIN_WIDTH } from 'constants';
import { DoubleArrowRTLIcon } from 'constants/icon';
import { Fade } from 'helpers/animation';
import { useDetectDevice } from 'hooks/useDetectDevice';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { scrollbarThumb } from 'styles';

const CollapseSidebarProcessStepsLayout = ({
  titleSidebar = '',
  navbars = null,
  stepContentReference = null,
  currentStep = 0,
  isPreview = false,
  isSuccess = false,
  isHideProcessSteps = false,
}) => {
  const [isCollapsed, setCollapsed] = useState(false);
  const [isShowSidebarTitle, setShowSidebarTitle] = useState(true);
  const [isShowNavLabel, setShowNavLabel] = useState(true);

  const { isMobile, isTablet } = useDetectDevice();

  const handleOnOpenSidebar = () => {
    document.getElementById('sidebar-common').style.width = '300px';
    document.getElementById('sidebar-common-body').style.marginLeft = '300px';
    setCollapsed(false);
    const timer = setTimeout(() => {
      setShowSidebarTitle(true);
      setShowNavLabel(true);
      clearTimeout(timer);
    }, 250);
  };
  const handleOnCloseSidebar = () => {
    document.getElementById('sidebar-common').style.width = '98px';
    document.getElementById('sidebar-common-body').style.marginLeft = '98px';
    setCollapsed(true);
    setShowSidebarTitle(false);
    setShowNavLabel(false);
  };
  const handleOnCollapseToggle = () => {
    if (!isCollapsed) {
      handleOnCloseSidebar();
      return;
    }
    handleOnOpenSidebar();
  };

  const renderSidebarHeader = () => {
    if (isCollapsed) {
      return <SidebarTitle style={{ height: '40px' }}></SidebarTitle>;
    }
    return (
      <SidebarTitle style={{ height: !isShowSidebarTitle && '40px' }}>
        {isShowSidebarTitle ? titleSidebar : ''}
      </SidebarTitle>
    );
  };

  const NavbarItem = ({ Icon, label, isActive, isComplete }) => {
    const getStatus = () => {
      if (isPreview) return 'completed';
      if (isActive) return 'in-progress';
      if (isComplete) return 'completed';
      return 'disable';
    };
    return (
      <NavbarItemStyled
        $isActive={isActive}
        $isComplete={isPreview || isComplete}
        $isCollapsed={isCollapsed}
      >
        <Icon status={getStatus()} />
        {!isCollapsed && <p>{isShowNavLabel ? label : ''}</p>}
      </NavbarItemStyled>
    );
  };
  const renderNavbars = () => {
    if (isHideProcessSteps) return null;

    const navbarsLength = navbars.length;
    const renderVericalLine = (index) => {
      if (index < navbarsLength - 1)
        return (
          <ProgressLine $isCollapsed={isCollapsed}>
            <VerticalLine
              $isActive={isPreview || index < currentStep}
              $isCollapsed={isCollapsed}
            />
          </ProgressLine>
        );
      return null;
    };

    return (
      <>
        {navbars.map((nav, index) => (
          <Fragment key={nav.label}>
            <NavbarItem
              Icon={nav.Icon}
              label={nav.label}
              isActive={currentStep === index}
              isComplete={currentStep > index}
            />
            {renderVericalLine(index)}
          </Fragment>
        ))}
      </>
    );
  };

  const renderBodyContent = () => {
    return stepContentReference[currentStep];
  };

  const renderSidebarRoot = () => {
    if (isSuccess) return null;
    if (isMobile || isTablet) return null;
    return (
      <CollapseSidebarStyled id="sidebar-common">
        <Header $isCollapsed={isCollapsed}>{renderSidebarHeader()}</Header>
        <Navbars $isCollapsed={isCollapsed}>{renderNavbars()}</Navbars>
        <ToggleButton
          onClick={handleOnCollapseToggle}
          $isCollapsed={isCollapsed}
        >
          <img src={DoubleArrowRTLIcon} />
        </ToggleButton>
      </CollapseSidebarStyled>
    );
  };
  const renderBodyContentRoot = () => {
    return (
      <BodyContent id="sidebar-common-body" $isSuccess={isSuccess}>
        {renderBodyContent()}
      </BodyContent>
    );
  };

  return (
    <>
      {renderSidebarRoot()}
      {renderBodyContentRoot()}
    </>
  );
};

const CollapseSidebarStyled = styled.section`
  height: 100%;
  width: 300px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: var(--ds-c-white-default);
  overflow-x: hidden;
  padding: 40px 24px;
  padding-top: 112px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: 0.3s ease;

  & a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s ease;
    &:hover {
      color: #f1f1f1;
    }
  }

  ${scrollbarThumb};

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    left: calc(50% - ${DESKTOP_MIN_WIDTH / 2}px);
  }

  @media screen and (min-width: ${DESKTOP_COLLAPSE_MIN_WIDTH}px) {
    left: calc(50% - ${DESKTOP_COLLAPSE_MIN_WIDTH / 2}px);
  }
`;
const BodyContent = styled.div`
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  transition: margin-left 0.3s;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding: ${(props) =>
      props.$isSuccess ? '24px 40px' : '24px 90px 24px 40px'};
    margin-left: 300px;
    margin-right: ${(props) => props.$isSuccess && '300px'};
  }
`;
const ToggleButton = styled.div`
  position: absolute;
  bottom: 40px;
  right: 24px;
  cursor: pointer;
  transform: ${(props) => (props.$isCollapsed ? 'rotate(180deg)' : 'unset')};
  transition: ${(props) =>
    props.$isCollapsed ? 'margin-right 0.3s ease' : '0.4s ease'};
  margin-right: ${(props) => (props.$isCollapsed ? '12px' : 'unset')};
`;

const Header = styled.div`
  margin-bottom: 60px;
  display: flex;
  align-item: center;
  justify-content: ${(props) => (props.$isCollapsed ? 'center' : 'flex-start')};
`;
const SidebarTitle = styled.h2`
  margin: 0;
  padding: 0;

  font-family: var(--ff-primary);
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
  color: var(--ds-c-blue);
  text-transform: uppercase;
  ${Fade};
`;

const Navbars = styled.div`
  margin-inline: ${(props) => props.$isCollapsed && '11px'};
  transition: ${(props) =>
    props.$isCollapsed ? 'margin-left 0.3s ease' : '0.3s ease'};
`;
const NavbarItemStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: ${(props) => (props.$isCollapsed ? 'center' : 'flex-start')};
  background-color: transparent;
  height: 40px;
  width: 100%;
  border-radius: 8px;
  cursor: default;
  transition: 0.3s ease;

  & p {
    margin: 0;
    margin-left: 12px;
    padding: 0;

    font-family: var(--ff-primary);
    font-size: 16px;
    font-weight: ${(props) => (props.$isActive ? 600 : 500)};
    line-height: 20px;
    text-align: left;
    color: ${(props) =>
      props.$isActive || props.$isComplete
        ? 'var(--ds-c-grey-dark)'
        : 'var(--ds-c-blue-disabled)'};
`;

const ProgressLine = styled.div`
  display: flex;
  justify-content: ${(props) => (props.$isCollapsed ? 'center' : 'start')};
  height: 50px;
  width: 100%;
  margin-bottom: 10px;
`;
const VerticalLine = styled.div`
  height: 50px;
  border-left: ${(props) =>
    props.$isActive
      ? '1px solid var(--bg-primary)'
      : '1px solid var(--ds-c-blue-remox-light)'};
  margin-left: ${(props) => (props.$isCollapsed ? '-0.5px' : '13.5px')};
`;

export default CollapseSidebarProcessStepsLayout;
