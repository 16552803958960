import BuyCurrencyIcon from 'assets/ver2/icons/buy-currency-icon.svg';
import Transactions from 'components/FXTransactions';
import PaginationLazy from 'components/FXTransactions/components/Pagination/mobile';
import SearchTransaction from 'components/FXTransactions/components/SearchTransaction';
import { fetchTransactionsFiltered } from 'components/FXTransactions/func';
import TransactionsMobile from 'components/FXTransactions/mobile';
import {
  redirectToFxTransactionDetails,
  updateNotifyToReaded,
} from 'components/Profile/func';
import ButtonCommon from 'components/common/ButtonCommon';
import CollapsibleSideMenu from 'components/common/CollapsibleSideMenu';
import Endpoint from 'components/common/Endpoint';
import MobileTabMenu from 'components/common/MobileTabMenu';
import NavbarFooter from 'components/common/NavbarFooter/ver2';
import CalculatorModal from 'components/fx/CalculatorModal';
import {
  DESKTOP_MIN_WIDTH,
  FX_SEARCH_BY_DEFAULT,
  ORDER_EMAIL,
  PAGE_DEFAULT,
} from 'constants';
import { FX_TRANSACTION_TABLE_SORT_ORDER_INITIAL } from 'constants/fx';
import Cookies from 'helpers/cookies';
import { FX_MAKE_PAYMENT_KEY } from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useAuth from 'hooks/useAuth';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import { isArray, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { arrayUtils } from 'utils/func';

const ENDPOINT_ID = 'TransactionsPage-endpoint';

const TransactionsPage = () => {
  const { t } = useLang();
  const { state } = useStore();
  const { token } = useAuth();
  const { isMobile, isTablet } = useDetectDevice();
  const query = useQuery();
  const tid = query.get('tid');
  const mid = query.get('mid');
  const { refreshTransactionsList } = state;

  const { isMenuExpand, notifications } = state; // For Collapsible Menu

  const [transactions, setTransactions] = useState(null);
  const [currentPage, setCurrentPage] = useState(PAGE_DEFAULT);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [sortOrder, setSortOrder] = useState(
    FX_TRANSACTION_TABLE_SORT_ORDER_INITIAL
  );

  const [applyFiltered, setApplyFiltered] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [currentSearchBy, setCurrentSearchBy] = useState(FX_SEARCH_BY_DEFAULT);
  const [isFetching, setFetching] = useState(false);
  const [isShowCalculatorModal, setShowCalculatorModal] = useState(false);

  // const isIntersecting = useDetectEndpointVisibleOnScreenWithId(ENDPOINT_ID);
  const isFetchTransactionsInitial =
    token &&
    // isIntersecting &&
    !isArray(transactions) &&
    isEmpty(transactions) &&
    !searchValue &&
    isEmpty(applyFiltered) &&
    Object.values(sortOrder).every((value) => !value) &&
    refreshTransactionsList;

  const { resetAddTransactionInformation } = useFxFormAddTransactionStore();

  useEffect(() => {
    if (notifications && Array.isArray(notifications) && tid && mid) {
      const notiData = notifications?.find(
        (noti) => noti?.data?.tranId === tid && noti?.uuid === mid
      );

      if (notiData) {
        if (!notiData?.read) {
          updateNotifyToReaded(currentUser, notiData);
        }
        redirectToFxTransactionDetails(token, notiData, navigate);
      }
    }
  }, [notifications, tid, mid]);

  useEffect(() => {
    if (isFetchTransactionsInitial) {
      setTransactions(null);
      fetchTransactionsFiltered({
        token,
        page: PAGE_DEFAULT,
        transactions: [],
        setTransactions,
        setCurrentPage,
        setTotalPages,
        setTotalElements,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchTransactionsInitial]);

  const handleOnShowCalculatorModal = () => {
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);
    resetAddTransactionInformation();
    setShowCalculatorModal(true);
  };
  const handleOnCloseCalculatorModal = () => {
    setShowCalculatorModal(false);
  };

  const renderSearchFilterSection = () => {
    const isShowSearchFilterAction =
      (Array.isArray(transactions) &&
        !arrayUtils.isArrayEmpty(transactions) &&
        searchValue === '' &&
        totalElements > 0) ||
      searchValue !== '';

    const isNoTransaction =
      Array.isArray(transactions) && arrayUtils.isArrayEmpty(transactions);

    if (isMobile || isTablet) {
      return (
        <>
          {!isNoTransaction && (
            <FilteredWrap>
              <SearchTransaction
                applyFiltered={applyFiltered}
                currentSearchBy={currentSearchBy}
                setCurrentSearchBy={setCurrentSearchBy}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                transactions={transactions}
                setTransactions={setTransactions}
                setTotalPages={setTotalPages}
                setCurrentPage={setCurrentPage}
                setSortOrder={setSortOrder}
                setTotalElements={setTotalElements}
                setFetching={setFetching}
              />
            </FilteredWrap>
          )}
          {Boolean(transactions?.length) && (
            <PaginationLazy
              applyFiltered={applyFiltered}
              searchValue={searchValue}
              currentSearchBy={currentSearchBy}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              transactions={transactions}
              setTransactions={setTransactions}
            />
          )}
        </>
      );
    }
    return (
      <>
        {!isNoTransaction && (
          <FilteredWrap>
            <SearchTransaction
              applyFiltered={applyFiltered}
              currentSearchBy={currentSearchBy}
              setCurrentSearchBy={setCurrentSearchBy}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              transactions={transactions}
              setTransactions={setTransactions}
              setTotalPages={setTotalPages}
              setCurrentPage={setCurrentPage}
              setSortOrder={setSortOrder}
              setTotalElements={setTotalElements}
              setFetching={setFetching}
            />
            {!isMobile && !isTablet && Boolean(transactions?.length) && (
              <ButtonCommon
                value={t('fx_buy_currency_label')}
                color="var(--c-primary)"
                background="var(--bg-primary)"
                isFill={true}
                styles={{
                  paddingInline: 0,
                }}
                buttonIconStyles={{
                  float: 'right',
                }}
                iconSrc={BuyCurrencyIcon}
                onClick={handleOnShowCalculatorModal}
              />
            )}
          </FilteredWrap>
        )}
      </>
    );
  };

  if (isMobile || isTablet) {
    return (
      <>
        {isShowCalculatorModal && (
          <CalculatorModal
            isOpen={isShowCalculatorModal}
            onClose={handleOnCloseCalculatorModal}
          />
        )}

        <TransactionsStyled>
          <MobileTabMenu />
          {renderSearchFilterSection()}
          <Endpoint id={ENDPOINT_ID} />
          <TransactionsMobile
            currentPage={currentPage}
            totalPages={totalPages}
            transactions={transactions}
            setTransactions={setTransactions}
            applyFiltered={applyFiltered}
            searchValue={searchValue}
            setTotalPages={setTotalPages}
            setCurrentPage={setCurrentPage}
            totalElements={totalElements}
            setTotalElements={setTotalElements}
            isFetching={isFetching}
            setFetching={setFetching}
          />
          <NavbarFooter />
        </TransactionsStyled>
      </>
    );
  }
  return (
    <>
      {isShowCalculatorModal && (
        <CalculatorModal
          isOpen={isShowCalculatorModal}
          onClose={handleOnCloseCalculatorModal}
        />
      )}

      <LayoutContainer>
        <CollapsibleSideMenu />

        <OuterContainerStyled isMenuExpand={isMenuExpand}>
          <TransactionsStyled>
            <Title>{t('transactions_title')}</Title>
            {renderSearchFilterSection()}
            <Endpoint id={ENDPOINT_ID} />
            <Transactions
              currentPage={currentPage}
              totalPages={totalPages}
              transactions={transactions}
              setTransactions={setTransactions}
              applyFiltered={applyFiltered}
              searchValue={searchValue}
              setTotalPages={setTotalPages}
              setCurrentPage={setCurrentPage}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              totalElements={totalElements}
              setTotalElements={setTotalElements}
              isFetching={isFetching}
              setFetching={setFetching}
            />
          </TransactionsStyled>
        </OuterContainerStyled>
      </LayoutContainer>
    </>
  );
};

const LayoutContainer = styled.div`
  display: flex;
  height: calc(100vh - 70px);
`;

const OuterContainerStyled = styled.div`
  flex-grow: 1;
  margin-left: ${(props) => (props.isMenuExpand ? '300px' : '98px')};
  transition: margin-left 0.3s;
  height: fit-content;
`;

const TransactionsStyled = styled.div`
  margin: 70px 0px 0px 0px;
  box-shadow: none;
  height: calc(100vh - 70px);
  padding: 16px 24px;
  position: relative;
  background-color: var(--ds-c-white);
  border-radius: 12px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: fit-content;
    margin: 96px 50px 47px 0;
    padding: 24px 40px;
  }
`;

const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;

  color: var(--ds-c-blue-dark);
  margin-bottom: 10px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    color: var(--ds-c-black);
  }
`;

const FilteredWrap = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4px;
  margin-bottom: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 8px;
    padding-top: unset;
  }
`;

export default TransactionsPage;
