/* eslint-disable no-unused-vars */
import useStore from 'hooks/useStore';
import { isEmpty } from 'lodash';
import {
  SET_EVENTS,
  SET_SHOW_LOADING,
  SET_SHOW_PENDING,
  SET_SHOW_SEARCHING,
} from 'store/action';

const useCommonStore = () => {
  const { state, setState } = useStore();
  const {
    isLoading,
    isSearching,
    currentUser,
    portalModal,
    configOccupation,
    events,
  } = state;

  const { isShowPortalModal, namePortalModal } = portalModal || {};

  const setLoading = (isLoading = false) =>
    setState({
      action: SET_SHOW_LOADING,
      payload: isLoading,
    });

  const setPending = (isPending = false) =>
    setState({
      action: SET_SHOW_PENDING,
      payload: isPending,
    });

  const setSearching = (isSearching = false) =>
    setState({
      action: SET_SHOW_SEARCHING,
      payload: isSearching,
    });

  const setEvents = (events) => {
    setState({
      action: SET_EVENTS,
      payload: !isEmpty(events) ? events : null,
    });
  };

  return {
    // STATE
    isLoading,
    isSearching,
    currentUser,
    portalModal,
    isShowPortalModal,
    namePortalModal,
    configOccupation,
    events,
    // DISPATCH
    setLoading,
    setSearching,
    setPending,
    setEvents,
  };
};

export default useCommonStore;
