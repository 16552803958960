import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const FormInputValidation = ({
  field,
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();

  const isFieldInvalid = validation[field.name];

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    const valueTrim = value.trim();

    const newInformation = {
      ...information,
      [name]: valueTrim,
    };
    setInformation(newInformation);
  };

  const handleOnFocus = (e) => {
    const { name } = e.target;

    const newValidation = {
      ...validation,
      [name]: '',
    };
    setValidation(newValidation);
  };

  const handleFormLabelOnClick = (name) => {
    const inputEle = document.getElementsByName(name)[0];

    if (inputEle) {
      inputEle.focus();
    }
  };

  return (
    <FormGroupStyled>
      <FormLabel onClick={() => handleFormLabelOnClick(field.name)}>
        {t(field.label)}
        <span>*</span>
      </FormLabel>
      <FormControl
        className={isFieldInvalid && 'is-field-invalid'}
        type={field.type}
        name={field.name}
        placeholder={t(field.placeholder)}
        isInvalid={validation[field.name]}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
      />
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[field.name])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 42px;
  position: relative;
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: var(--ds-c-white);

  &:focus {
    border: 1px solid var(--ds-c-grey-dark);
    outline: none;
    box-shadow: none;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none;
    box-shadow: none !important;
    background-image: none;
  }

  &.is-field-invalid {
    border: 1px solid var(--ds-c-red);
    outline: none;
    box-shadow: none;
    background-image: none;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-hover);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputValidation;
