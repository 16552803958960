import { checkFormInputValidation } from 'components/common/func';
import {
  DESKTOP_MIN_WIDTH,
  LOGIN_EMAIL_NAME,
  LOGIN_FIELDS,
  LOGIN_PASSWORD_NAME,
  LOGIN_UNAUTHORIZED_INFORM,
  RESET_LOGIN_INFORMATION,
} from 'constants';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import {
  FORGOT_PASSWORD_PREFIX,
  REGISTER_PREFIX,
} from '../../constants/router';
import ButtonCommon from '../common/ButtonCommon';
import FormInputValidation from './components/FormInputValidation';

const initialField = RESET_LOGIN_INFORMATION;

const FormLogIn = (noticeDisplay) => {
  const { t } = useLang();
  const { dispatch } = useStore();
  const { login } = useAuth();

  const [information, setInformation] = useState(initialField);
  const [validation, setValidation] = useState(initialField);
  const [error, setError] = useState('');

  const handleSubmitError = (error) => {
    setError(error?.response?.data?.message?.toLowerCase());

    dispatch({ type: SET_SHOW_LOADING, payload: false });
  };

  const handleSubmit = async () => {
    let newValidation = { ...validation };

    LOGIN_FIELDS.forEach((field) => {
      newValidation = checkFormInputValidation(
        information[field.name],
        field,
        newValidation
      );
    });

    setValidation(newValidation);

    const isAllowSubmit =
      Object.values(information).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowSubmit) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      try {
        const error = await login(
          information[LOGIN_EMAIL_NAME],
          information[LOGIN_PASSWORD_NAME]
        );

        handleSubmitError(error);
      } catch (error) {
        console.error(error?.message);

        handleSubmitError(error);
      }
    }
  };

  return (
    <FormLogInStyled noticeDisplay={noticeDisplay}>
      {LOGIN_FIELDS.map((field) => (
        <FormInputValidation
          key={field.name}
          field={field}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          error={error}
          setError={setError}
        />
      ))}
      {LOGIN_UNAUTHORIZED_INFORM[error]}

      <ForgotPasswordSubmit>
        <LinkForgotPasswordStyled to={FORGOT_PASSWORD_PREFIX}>
          {t('button_forgot_password')}
        </LinkForgotPasswordStyled>
        <ButtonCommon
          value={t('button_login')}
          styles={{
            margin: '0px',
            width: '200px',
          }}
          onClick={handleSubmit}
          isUseKeyDown
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
        />
      </ForgotPasswordSubmit>
      <Register>
        {t('login_text_no_account')}{' '}
        <LinkRegisterStyled to={REGISTER_PREFIX}>
          {t('button_register_now')}
        </LinkRegisterStyled>
      </Register>
    </FormLogInStyled>
  );
};

const FormLogInStyled = styled(Form)`
  position: relative;
  width: 100%;
  height: ${(props) => (props.noticeDisplay ? 'calc(100% - 205px)' : 'calc(100% - 96px)')};
`;

const LinkForgotPasswordStyled = styled(Link)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;

  color: #002FF1;
  display: block;

  &:hover {
    color: var(--ds-c-blue-hover) !important;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const LinkRegisterStyled = styled(Link)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  color: var(--bg-primary);
  text-transform: uppercase;

  &:hover,
  &:active {
    color: var(--bg-primary-hover);
  }
`;

const ForgotPasswordSubmit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Register = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  position: absolute;
  bottom: 0px;
  color: var(--ds-c-grey-dark);
  margin-bottom: 0px;
`;

export default FormLogIn;
