const NotificationIcon = ({
  color = '#fff',
  size = { width: 36, height: 36 },
  styles = {},
  onClick = () => {},
}) => {
  return (
    <svg
      width={size.width}
      height={size.height}
      style={{ ...styles }}
      onClick={onClick}
      viewBox="0 0 158 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M78.9998 0C47.2061 0 21.4322 25.669 21.4322 57.3333V74.8294C21.4322 88.3915 17.7313 101.699 10.7252 113.328L5.4154 122.142C1.89365 127.988 0.132768 130.911 0.0121799 133.298C-0.174592 136.994 1.79907 140.466 5.07805 142.208C7.1951 143.333 10.6177 143.333 17.4629 143.333H140.537C147.382 143.333 150.805 143.333 152.922 142.208C156.201 140.466 158.175 136.994 157.988 133.297C157.867 130.911 156.106 127.988 152.585 122.142L147.275 113.328C140.268 101.699 136.567 88.3914 136.567 74.8291V57.3333C136.567 25.669 110.794 0 78.9998 0Z"
        fill={color}
      />
      <path
        d="M102.92 154.32C102.994 153.532 102.343 152.889 101.548 152.889H56.4531C55.6582 152.889 55.0069 153.532 55.0812 154.32C55.5094 158.865 57.9664 163.157 62.0394 166.402C66.5377 169.987 72.6388 172 79.0004 172C85.362 172 91.4631 169.987 95.9614 166.402C100.034 163.157 102.491 158.865 102.92 154.32Z"
        fill={color}
      />
    </svg>
  );
};

export default NotificationIcon;
