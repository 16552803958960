import FxInactivityTimePopup from 'components/fx/components/FxInactivityTimePopup';
import { useEffect, useState } from 'react';

const INACTIVITY_TIME_DEFAULT = 1800;

const useFxCheckInactivityTime = () => {
  const [isOpenInactivityPopup, setOpenInactivityPopup] = useState(false);

  let timer;

  const handleOnClickInactivityTimePopup = () => {
    timer = null;
    setOpenInactivityPopup(false);
  };

  const checkInactivityTime = () => {
    const timeOut = INACTIVITY_TIME_DEFAULT;
    const events = [
      'mousedown',
      'mousemove',
      'keypress',
      'scroll',
      'touchstart',
    ];

    const showInactivityTimePopup = () => {
      setOpenInactivityPopup(true);
    };

    const resetTimer = () => {
      clearTimeout(timer);

      timer = setTimeout(showInactivityTimePopup, timeOut * 1000);
    };

    window.addEventListener('load', resetTimer, true);

    events.forEach((name) => {
      document.addEventListener(name, resetTimer, true);
    });
  };
  useEffect(() => {
    checkInactivityTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return {
    isOpenInactivityPopup,
    FxInactivityTimePopup,
    handleOnClickInactivityTimePopup,
  };
};

export default useFxCheckInactivityTime;
