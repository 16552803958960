import {
  default as NoOfferHHMTIcon,
  default as NoOfferRemoxIcon,
} from 'assets/ver2/icons/discount-empty-gray-icon.svg';
import {
  default as NoEventHHMTIcon,
  default as NoEventRemoxIcon,
} from 'assets/ver2/icons/event-empty-gray-icon.svg';
import {
  default as NoPromotionHHMTIcon,
  default as NoPromotionRemoxIcon,
} from 'assets/ver2/icons/gift-empty-gray-icon.svg';
import { PROMOTION, VOUCHER } from 'constants';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';

const OffersEmpty = ({ offersEmptyTxt, offerDesc, type }) => {
  const { isHHMTTheme } = useTheme();
  const { isMobile } = useDetectDevice();

  const emptyImgSrc =
    type === PROMOTION
      ? isHHMTTheme
        ? NoPromotionHHMTIcon
        : NoPromotionRemoxIcon
      : type === VOUCHER
      ? isHHMTTheme
        ? NoOfferHHMTIcon
        : NoOfferRemoxIcon
      : isHHMTTheme
      ? NoEventHHMTIcon
      : NoEventRemoxIcon;

  return (
    <OffersEmptyStyled>
      <Desc>{offerDesc}</Desc>
      <img
        src={emptyImgSrc}
        width={isMobile ? 200 : 200}
        height={isMobile ? 200 : 200}
        alt=""
      />
      <span
        dangerouslySetInnerHTML={{
          __html: offersEmptyTxt,
        }}
      />
    </OffersEmptyStyled>
  );
};

const OffersEmptyStyled = styled.div`
  display: grid;
  justify-items: center;
  position: relative;

  img {
    margin-top: 40px;
  }

  & span {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: var(--ds-c-grey-dark);
    margin-bottom: 24px;
  }
`;

const Desc = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-dark-gray);
  margin: 0;
  margin-block: 16px;
  padding: 0;
`;

export default OffersEmpty;
