import { api } from 'api';
import { DEBOUNCE_SEC, DESKTOP_MIN_WIDTH } from 'constants';
import useCommonStore from 'hooks/useCommonStore';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useLang from 'hooks/useLang';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { checkFormInputValidation } from '../../func';

const FormEmailInputValidation = ({
  field,
  information,
  validation,
  setInformation,
  setValidation,
  isEmailExisted,
  setEmailExisted,
  checkFormEmailInputValidation = null,
}) => {
  const { t } = useLang();
  const { setLoading } = useCommonStore();

  const checkFormInputValidationFn =
    checkFormEmailInputValidation || checkFormInputValidation;

  const handleEmailOnChange = (name, value) => {
    if (isEmailExisted) {
      setEmailExisted(false);
    }

    return value;
  };

  const handleEmailValidation = async (value, name, validation) => {
    try {
      const { data } = await api.validateEmail(value);

      if (data) {
        const isValid = data.valid;

        validation = {
          ...validation,
          [name]: isValid ? '' : field.msgInvalid,
        };

        setLoading(false);

        return validation;
      }
    } catch (error) {
      console.error(error?.message);
      setLoading(false);
    }

    return validation;
  };
  const checkEmailValid = async (value, validation) => {
    validation = await handleEmailValidation(value, field.name, validation);
    setValidation(validation);
  };
  const { debounce: handleEmailValid } = useDebounceCallback(
    (value, validation) => {
      setLoading(true);
      checkEmailValid(value, validation);
    },
    DEBOUNCE_SEC
  );

  const handleOnChange = (e, field, isCheckEmail = true) => {
    const { value, name } = e.target;
    let valueTrim = value.trim();

    valueTrim = handleEmailOnChange(name, value);

    const newInformation = {
      ...information,
      [name]: valueTrim,
    };
    setInformation(newInformation);

    const newValidation = checkFormInputValidationFn(
      newInformation[name],
      field,
      newInformation,
      validation
    );

    const isCheckEmailValid =
      isCheckEmail && !newValidation[name] && newInformation[name];
    if (isCheckEmailValid) {
      handleEmailValid(newInformation[name], newValidation);
    } else {
      if (!validation[field.name]) {
        setValidation(newValidation);
      }
    }
  };

  const handleOnBlur = (e, field) => {
    handleOnChange(e, field, false);
  };

  const handleFormLabelOnClick = (name) => {
    const inputEle = document.getElementsByName(name)[0];

    if (inputEle) {
      inputEle.focus();
    }
  };

  return (
    <FormGroupStyled>
      <FormLabel onClick={() => handleFormLabelOnClick(field.name)}>
        {t(field.label)}
        <span>*</span>
      </FormLabel>
      <FormControl
        type={field.type}
        name={field.name}
        value={information ? information[field.name] : ''}
        placeholder={t(field.placeholder)}
        isInvalid={validation[field.name]}
        onChange={(e) => handleOnChange(e, field)}
        onBlur={(e) => handleOnBlur(e, field)}
      />
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[field.name])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: var(--ds-c-white);

  &:focus {
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormEmailInputValidation;
