import React from 'react';

const CalendarIcon = ({
  name = '',
  // color = '#ffffff',
  size = { width: 24, height: 24 },
  styles = {},
  onClick = () => {},
}) => {
  return (
    <svg
      name={name}
      width={size.width}
      height={size.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...styles }}
      onClick={onClick}
    >
      <path d="M19.9688 3.875H17.8125V1.53906C17.8125 1.24258 17.5699 1 17.2734 1H15.4766C15.1801 1 14.9375 1.24258 14.9375 1.53906V3.875H9.1875V1.53906C9.1875 1.24258 8.94492 1 8.64844 1H6.85156C6.55508 1 6.3125 1.24258 6.3125 1.53906V3.875H4.15625C2.96582 3.875 2 4.84082 2 6.03125V21.8438C2 23.0342 2.96582 24 4.15625 24H19.9688C21.1592 24 22.125 23.0342 22.125 21.8438V6.03125C22.125 4.84082 21.1592 3.875 19.9688 3.875ZM19.6992 21.8438H4.42578C4.27754 21.8438 4.15625 21.7225 4.15625 21.5742V8.1875H19.9688V21.5742C19.9688 21.7225 19.8475 21.8438 19.6992 21.8438Z" fill="black"/>
    </svg>
  );
};

export default CalendarIcon;
