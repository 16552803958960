import { api } from 'api';
import { RESET_FX_ADD_TRANSACTION_INFORMATION_STORE } from 'constants/fx';
import sw from 'helpers/sw';
import { useNavigate } from 'react-router-dom';
import {
  SET_FX_ADD_TRANSACTION_CURRENT_STEP,
  SET_FX_ADD_TRANSACTION_INFORMATION,
} from 'store/fx/action';
import {
  ACCESS_TOKEN,
  DASHBOARD_PREFIX,
  ORDER_EMAIL,
  PUSH_API_STATUS,
  RESET_ADD_TRANSACTION_INFORMATION_STORE,
  RESET_EDIT_RECEIVER_INFORMATION_STORE,
} from '../constants';
import Cookies from '../helpers/cookies';
import {
  SET_ADD_TRANSACTION_CURRENT_STEP,
  SET_ADD_TRANSACTION_INFORMATION,
  SET_CURRENT_RECEIVER,
  SET_CURRENT_USER,
  SET_EDIT_RECEIVER_INFORMATION,
  SET_EVENT_AVAILABLE,
  SET_OPEN_REFRESH_TOKEN_POPUP,
} from '../store/action';
import { MAKE_PAYMENT_KEY } from './form-add-transaction/useAddTransaction';
import { FX_MAKE_PAYMENT_KEY } from './fx/useFxAddTransaction';
import useFxStore from './fx/useFxStore';
import useCheckVersion from './useCheckVersion';
import { useDetectDevice } from './useDetectDevice';
import useFirebase, { requestNotifyPermission } from './useFirebase';
import usePortalModal from './usePortalModal';
import useStore from './useStore';

const useAuth = () => {
  const { state, dispatch } = useStore();
  const { dispatch: fxDispatch } = useFxStore();
  const { currentUser, portalModal } = state || {};
  const { firebaseAppDefault, saveDeviceToken } = useFirebase();
  const navigate = useNavigate();
  const { isDesktop } = useDetectDevice();
  const { fetchBuildVersion } = useCheckVersion();

  const token = Cookies.get(ACCESS_TOKEN);

  const isAuthenticated = token && currentUser;

  const { closePortalModalName } = usePortalModal();
  const { isShowPortalModal } = portalModal || {};

  const login = async (email, password, urlRedirect) => {
    try {
      const { data } = await api.login(email, password);

      if (data?.token) {
        const { token } = data || {};

        Cookies.set(ACCESS_TOKEN, token);

        dispatch({ type: SET_OPEN_REFRESH_TOKEN_POPUP, payload: false });

        if (isDesktop && Notification?.permission === 'denied') {
          Cookies.set(PUSH_API_STATUS, 'denied');
        }
        requestNotifyPermission(
          firebaseAppDefault,
          process.env.REACT_APP_NOTIFICATION_VAPID_KEY,
          (deviceToken) => {
            saveDeviceToken({
              token,
              isActive: true,
              deviceToken,
            });
          }
        );

        fetchBuildVersion();

        navigate(urlRedirect || `${DASHBOARD_PREFIX}`);
      }
      return null;
    } catch (error) {
      console.error(error?.message);

      dispatch({ type: SET_OPEN_REFRESH_TOKEN_POPUP, payload: false });

      return error;
    }
  };

  const logout = async () => {
    saveDeviceToken({ token, isActive: false });
    sw.unregister();
    Cookies.remove(PUSH_API_STATUS);

    Cookies.remove(ACCESS_TOKEN);

    dispatch({ type: SET_CURRENT_USER, payload: null });

    dispatch({
      type: SET_EDIT_RECEIVER_INFORMATION,
      payload: RESET_EDIT_RECEIVER_INFORMATION_STORE,
    });

    /* BEGIN - clean up send money flow */
    Cookies.remove(MAKE_PAYMENT_KEY);
    dispatch({
      type: SET_ADD_TRANSACTION_INFORMATION,
      payload: RESET_ADD_TRANSACTION_INFORMATION_STORE,
    });
    dispatch({ type: SET_ADD_TRANSACTION_CURRENT_STEP, payload: 0 });
    /* END - clean up send money flow */

    /* BEGIN - clean up buy money flow */
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);
    fxDispatch({
      type: SET_FX_ADD_TRANSACTION_INFORMATION,
      payload: RESET_FX_ADD_TRANSACTION_INFORMATION_STORE,
    });
    fxDispatch({
      type: SET_FX_ADD_TRANSACTION_CURRENT_STEP,
      payload: 0,
    });
    /* END - clean up buy money flow */

    dispatch({ type: SET_CURRENT_RECEIVER, payload: null });

    dispatch({ type: SET_OPEN_REFRESH_TOKEN_POPUP, payload: false });

    dispatch({ type: SET_EVENT_AVAILABLE, payload: null });

    fetchBuildVersion();

    if (isShowPortalModal) {
      closePortalModalName();
    }
  };

  const refreshToken = async (tryCb, catchCb) => {
    try {
      const { data } = await api.refreshToken(token);

      if (data) {
        Cookies.set(ACCESS_TOKEN, data.token);
      }

      tryCb();

      fetchBuildVersion();
    } catch (error) {
      console.error(error?.message);

      catchCb();
    }
  };

  return { token, isAuthenticated, login, logout, refreshToken };
};

export default useAuth;
