import { DESKTOP_MIN_WIDTH, RESET_BACKDROP_STORE } from 'constants/common';
import useDisableBodyScroll from 'hooks/useDisableBodyScroll';
import { useEffect } from 'react';
import styled from 'styled-components';
import useStore from '../../../hooks/useStore';
import { SET_SHOW_BACKDROP } from '../../../store/action';

const BackdropGlobal = ({ pageYOffset }) => {
  const { state, dispatch } = useStore();
  const { backdrop } = state;

  useDisableBodyScroll();

  const handleOnClick = () => {
    backdrop.onClick();

    dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });
  };

  useEffect(() => {
    return () => {
      dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <BackdropGlobalStyled
        $isBackgroundTransparent={!backdrop?.text}
        $pageYOffset={pageYOffset}
        $zIndex={backdrop?.zIndex}
        onClick={handleOnClick}
        className="backdrop-global"
      />
      {backdrop?.text && (
        <Content $pageYOffset={pageYOffset} $zIndex={backdrop?.zIndex}>
          {backdrop?.label && <label>{backdrop?.label}</label>}
          <p dangerouslySetInnerHTML={{ __html: backdrop?.text }} />
        </Content>
      )}
    </>
  );
};

const BackdropGlobalStyled = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% + ${(props) => props.$pageYOffset || 0}px);
  background: ${(props) =>
    props.$isBackgroundTransparent ? 'transparent' : '#212121'};
  opacity: 0.25;
  z-index: ${(props) => props.$zIndex || 4};
`;

const Content = styled.div`
  position: absolute;
  left: 0px;
  bottom: -${(props) => props.$pageYOffset || 0}px;
  padding: 16px 24px;
  width: 100%;
  height: 120px;
  background: #ffffff;
  border-radius: 0px;
  z-index: ${(props) => props.$zIndex + 1 || 5};

  & label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #0e1012;
    margin: 0;
    margin-bottom: 4px;
    padding: 0;
  }

  & p {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #0e1012;
    margin: 0;
    padding: 0;

    & ul {
      padding: 0;
      padding-left: 16px;
      margin: 0;
      list-style: disc;
      list-style-type: disc;

      & li {
        font-family: var(--ff-primary);
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: var(--ds-c-grey-dark);

        @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
          font-size: 14px;
          line-height: 17px;
        }

        &::before {
          content: '•';
          color: var(--ds-c-grey-dark);
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export default BackdropGlobal;
