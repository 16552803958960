/* eslint-disable no-unused-vars */
import { api } from 'api';
import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import DiscountsDrawer from 'components/Discount/components/DiscountsDrawer';
import FormPaymentByDeposit from 'components/FormAddTransaction/components/FormPaymentByDeposit';
import FormPaymentByDepositMobile from 'components/FormAddTransaction/components/FormPaymentByDeposit/mobile/ver2';
import ButtonCommon from 'components/common/ButtonCommon';
import InformPopup from 'components/common/InformPopup';
import { checkFormInputValidation } from 'components/common/func';
import BodySectionHeader from 'components/fx/components/BodySectionHeader';
import {
  CONFIG_OCCUPATION_EXTRAS,
  DESKTOP_MIN_WIDTH,
  FX_TRANSACTION_ADD_PREFIX,
  RESET_PAYMENT_BY_DEPOSIT_STORE,
  SCREEN_ID_EXCHANGE_STEP_5,
} from 'constants';
import {
  FX_ADD_TRANSACTION_OCCUPATION_NAME,
  FX_ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS,
  FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME,
  FX_ADD_TRANSACTION_PURPOSE_FIELD,
  FX_ADD_TRANSACTION_PURPOSE_NAME,
  FX_ADD_TRANSACTION_SOURCE_FIELD,
  FX_ADD_TRANSACTION_SOURCE_NAME,
} from 'constants/fx';
import Cookies from 'helpers/cookies';
import useFxAddTransaction, {
  FX_MAKE_PAYMENT_KEY,
} from 'hooks/fx/useFxAddTransaction';
import useFxDiscount from 'hooks/fx/useFxDiscount';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useFxPayment from 'hooks/fx/useFxPayment';
import useAuth from 'hooks/useAuth';
import useCommonStore from 'hooks/useCommonStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useEvent from 'hooks/useEvent';
import useLang from 'hooks/useLang';
import useScrollIntoViewDelay from 'hooks/useScrollIntoViewDelay';
import useToastify from 'hooks/useToastify';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import AdditionalInformation from '../AdditionalInformation';
import CalculatorForm from '../CalculatorForm';
import FormTransactionSummary from '../FormTransactionSummary';
import FxMakePayment from '../FxMakePayment';
import PersonalDetails from '../PersonalDetails';
import TransactionSummary from '../TransactionSummary';

const initialFormCard = RESET_PAYMENT_BY_DEPOSIT_STORE;
const initialFormField = {
  [FX_ADD_TRANSACTION_OCCUPATION_NAME]: '',
  [FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]: '',
  [FX_ADD_TRANSACTION_PURPOSE_NAME]: '',
  [FX_ADD_TRANSACTION_SOURCE_NAME]: '',
};

const SCREEN_ID = SCREEN_ID_EXCHANGE_STEP_5;

const ConfirmInformation = ({
  handleOnToggleCancelPopup,
  handleOnViewPickupLocation,
}) => {
  const { t } = useLang();
  const { isMobile, isTablet, isDesktop } = useDetectDevice();
  const { token } = useAuth();
  const navigate = useNavigate();

  const [isShowDiscountInvalidPopup, setShowDiscountInvalidPopup] =
    useState(false);
  const [isShowTransactionDetails, setShowTransactionDetails] = useState(false);
  const [isShowFormPaymentByDeposit, setShowFormPaymentByDeposit] =
    useState(false);
  const [transactionInformation, setTransactionInformation] = useState(null);
  const [cardInformation, setCardInformation] = useState(initialFormCard);
  const [cardValidation, setCardValidation] = useState(initialFormCard);
  const [errorCode, setErrorCode] = useState('');
  const [additionalInformation, setAdditionalInformation] =
    useState(initialFormField);
  const [additionalValidation, setAdditionalValidation] =
    useState(initialFormField);

  const { currentUser, configOccupation, isLoading } = useCommonStore();
  const {
    addTransactionCurrentStep: currentStep,
    setAddTransactionCurrentStep,
    discountApplied,
    discountsAvailable,
    discountsInactive,
    discountDefault,
    isHideDiscountSection,
    currencyOrders,
    isAdditionalInformationRequired,
    setConfirmInformationAdditionalInformation,
  } = useFxFormAddTransactionStore();
  const {
    isDisabled,
    isMakePayment,
    orderRef,
    fxAddTransactionTotalNumberOfSteps,
    getTitleBodySection,
    isFxAsGuest,
  } = useFxAddTransaction();
  const { handleOnPayProcess, handleOnPayByDepositProcess } = useFxPayment();
  const {
    fetchFxDiscountsAvailable,
    handleApplyFxDiscount,
    handleRemoveFxDiscountApplied,
  } = useFxDiscount();

  const isNextDisabled = false;

  const isPaymentSuccessfully =
    currentStep === fxAddTransactionTotalNumberOfSteps;

  const { isToastShowing } = useToastify({});

  // scroll to calculator form
  const validRef = useRef(null);
  useScrollIntoViewDelay(validRef, validRef, []);

  /* BEGIN - fill value into additional information initial */
  const additionalInformationInitial = async (token) => {
    try {
      const { data } = await api.getOccupation(token);

      if (data) {
        const occupationInit = configOccupation?.find(
          (o) => o.key === data.job
        );
        const previousOccupationInit = configOccupation?.find(
          (o) => o.key === data.previousJob
        );
        const occupationEle = document.getElementsByName(
          FX_ADD_TRANSACTION_OCCUPATION_NAME
        )[0];
        occupationInit &&
          occupationEle &&
          (occupationEle.value = occupationInit?.value);
        const previousOccupationEle = document.getElementsByName(
          FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME
        )[0];
        previousOccupationInit &&
          previousOccupationEle &&
          (previousOccupationEle.value = previousOccupationInit?.value);

        setAdditionalInformation({
          ...initialFormField,
          [FX_ADD_TRANSACTION_OCCUPATION_NAME]: occupationInit || '',
          [FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]:
            previousOccupationInit || '',
        });
      }
    } catch (error) {
      console.error(error?.message);
    }
  };
  useEffect(() => {
    if (isFxAsGuest) return;
    if (isEmpty(currentUser)) return;
    if (isEmpty(configOccupation)) return;
    if (isEmpty(token)) return;
    additionalInformationInitial(token);
  }, [isFxAsGuest, currentUser, configOccupation, token]);
  /* END - fill value into additional information initial */

  useEffect(() => {
    const isAllowFetchDiscountsAvailable =
      !isDisabled &&
      currencyOrders?.length &&
      isEmpty(discountApplied) &&
      isEmpty(discountsAvailable) &&
      isEmpty(discountsInactive);
    if (isAllowFetchDiscountsAvailable) {
      fetchFxDiscountsAvailable({
        token,
      });
    }
  }, [
    isDisabled,
    currencyOrders,
    discountApplied,
    discountsAvailable,
    discountsInactive,
  ]);
  useEffect(() => {
    if (isLoading || errorCode || isToastShowing) return;
    const isAutoTriggerApplyDiscount =
      !isDisabled && !isEmpty(discountDefault) && isEmpty(discountApplied);
    if (isAutoTriggerApplyDiscount) {
      handleApplyFxDiscount({
        token,
        item: discountDefault,
        onApplyError: handleApplyDiscountError,
      });
    }
  }, [isLoading, isToastShowing, isDisabled, discountDefault, discountApplied]);

  const handleApplyDiscountError = (errorCode) => {
    setErrorCode(errorCode);
    if (isEmpty(discountDefault)) {
      setShowDiscountInvalidPopup(true);
    }
  };
  const renderDiscountSection = () => {
    if (isHideDiscountSection) return null;
    return (
      <DiscountWrapper>
        <DiscountsDrawer onApplyError={handleApplyDiscountError} isFx={true} />
      </DiscountWrapper>
    );
  };

  const renderAdditionalInformation = () => {
    if (!isAdditionalInformationRequired) return null;
    return (
      <AdditionalInformation
        information={additionalInformation}
        setInformation={setAdditionalInformation}
        validation={additionalValidation}
        setValidation={setAdditionalValidation}
      />
    );
  };

  const handleOnCloseFormPaymentByDeposit = () => {
    setShowFormPaymentByDeposit(false);

    setCardValidation(initialFormCard);

    Cookies.set(FX_MAKE_PAYMENT_KEY, true);

    navigate(
      `${FX_TRANSACTION_ADD_PREFIX}?orderRef=${
        transactionInformation?.orderRef || orderRef
      }`
    );
  };

  const handleOnCloseTransactionDetailsPopup = () => {
    setShowTransactionDetails(false);
  };

  const handleBackStep = () => {
    if (isAdditionalInformationRequired) {
      setConfirmInformationAdditionalInformation(additionalInformation);
    }
    setAddTransactionCurrentStep(currentStep - 1);
  };
  const handleNextStep = () => {
    let newInformation = { ...additionalInformation };
    let newValidation = { ...additionalValidation };

    if (!isAdditionalInformationRequired) {
      delete newInformation[FX_ADD_TRANSACTION_OCCUPATION_NAME];
      delete newValidation[FX_ADD_TRANSACTION_OCCUPATION_NAME];
      delete newInformation[FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME];
      delete newValidation[FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME];
      delete newInformation[FX_ADD_TRANSACTION_PURPOSE_NAME];
      delete newValidation[FX_ADD_TRANSACTION_PURPOSE_NAME];
      delete newInformation[FX_ADD_TRANSACTION_SOURCE_NAME];
      delete newValidation[FX_ADD_TRANSACTION_SOURCE_NAME];
    } else {
      if (
        !CONFIG_OCCUPATION_EXTRAS.find(
          (occupation) =>
            occupation ===
            additionalInformation[FX_ADD_TRANSACTION_OCCUPATION_NAME]?.key
        )
      ) {
        delete newInformation[FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME];
        delete newValidation[FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME];
      }
    }

    const isAllowNext =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowNext) {
      if (isAdditionalInformationRequired) {
        setConfirmInformationAdditionalInformation(additionalInformation);
      }
      setShowTransactionDetails(true);
    } else {
      if (isAdditionalInformationRequired) {
        newValidation = checkFormInputValidation(
          additionalInformation[FX_ADD_TRANSACTION_OCCUPATION_NAME]?.key,
          FX_ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS[0],
          newValidation
        );
        if (
          CONFIG_OCCUPATION_EXTRAS.find(
            (occupation) =>
              occupation ===
              additionalInformation[FX_ADD_TRANSACTION_OCCUPATION_NAME]?.key
          )
        ) {
          newValidation = checkFormInputValidation(
            additionalInformation[FX_ADD_TRANSACTION_PREVIOUS_EMPLOYED_NAME]
              ?.key,
            FX_ADD_TRANSACTION_PERSONAL_DETAILS_OCCUPATION_FIELDS[1],
            newValidation
          );
        }
        newValidation = checkFormInputValidation(
          additionalInformation[FX_ADD_TRANSACTION_PURPOSE_NAME]?.key,
          FX_ADD_TRANSACTION_PURPOSE_FIELD,
          newValidation
        );
        newValidation = checkFormInputValidation(
          additionalInformation[FX_ADD_TRANSACTION_SOURCE_NAME]?.key,
          FX_ADD_TRANSACTION_SOURCE_FIELD,
          newValidation
        );
      }

      setAdditionalValidation(newValidation);
    }
  };
  const renderCalculatorFormMobile = () => {
    if (isDesktop) return null;
    if (isMakePayment)
      return (
        <CalculatorForm
          ref={validRef}
          isCalculatorFormAccordion={true}
          isCalculatorFormAccordionExpandable={true}
          isExpandDefault={true}
          handleOnViewPickupLocation={handleOnViewPickupLocation}
        />
      );
    return (
      <CalculatorForm
        ref={validRef}
        isCalculatorFormAccordion={true}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    );
  };
  const renderActionsButton = () => {
    return (
      <NextWrap>
        {renderCalculatorFormMobile()}
        <ActionWrapper>
          {!isMakePayment && (
            <ButtonCommonWrap>
              <ButtonCommon
                value={t('button_back')}
                onClick={handleBackStep}
                styles={{
                  margin: '0px',
                  width: !isDesktop && '100%',
                }}
                color="var(--ds-c-blue)"
                background="var(--ds-c-white)"
                borderNameVariable={isDesktop && '--border-filter'}
                isFill={false}
              />
            </ButtonCommonWrap>
          )}
          <ButtonCommon
            value={t('button_review_and_pay')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: isDesktop ? '150px' : isMakePayment ? '100%' : '50%',
            }}
            onClick={handleNextStep}
            isDisabled={false}
            isUseKeyDown={!isNextDisabled}
          />
        </ActionWrapper>
      </NextWrap>
    );
  };

  const handleCloseDiscountInvalidPopup = () => {
    setShowDiscountInvalidPopup(false);

    if (isDesktop && !errorCode) {
      const discountDrawerCloseBtn =
        document.getElementsByClassName('btn-close')[0];

      if (discountDrawerCloseBtn) {
        discountDrawerCloseBtn.click();
      }
    }

    if (errorCode) {
      setErrorCode('');

      fetchFxDiscountsAvailable({
        token,
      });
    }

    if (isShowTransactionDetails) {
      handleRemoveFxDiscountApplied();
      handleOnCloseTransactionDetailsPopup();
    }
  };
  const renderDiscountInvalidPopup = (isOpen) => {
    return (
      <InformPopup
        isOpen={isOpen}
        imageSrc={NoticeIcon}
        imagesStyles={{
          width: '124px',
          height: '100px',
          marginBottom: '8px',
        }}
        text={t('label_important')}
        content={t(
          errorCode
            ? `popup_discount_invalid_error_msg_${errorCode.toLowerCase()}`
            : `popup_discount_invalid_error_msg`
        )}
        alignContent="center"
        buttonLabel2={t('button_ok')}
        onClick={handleCloseDiscountInvalidPopup}
        isBackdropOverlap={true}
        zIndexBackdropOverlap={1055}
      />
    );
  };

  const renderNoticeSection = () => {
    return (
      <NoticeSection>
        <p>{t('fx_add_transaction_personal_information_desc')}</p>
      </NoticeSection>
    );
  };

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [],
    modalStyles: {},
    modalOptions: {},
  });
  useEffect(() => {
    if (isMakePayment) return;
    if (isPaymentSuccessfully) return;
    if (!isShowEvent) return;
    showEventModal();
  }, [isShowEvent, isMakePayment, isPaymentSuccessfully]);

  if (isPaymentSuccessfully)
    return (
      <TransactionSummary
        transactionInformation={transactionInformation}
        cardInformation={cardInformation}
      />
    );
  if (isMakePayment)
    return (
      <>
        {isShowTransactionDetails && (
          <FormTransactionSummary
            isOpen={isShowTransactionDetails}
            onClose={handleOnCloseTransactionDetailsPopup}
            onClick={() =>
              handleOnPayProcess({
                transactionInformation,
                setTransactionInformation,
                setShowTransactionDetails,
                setShowFormPaymentByDeposit,
                setErrorCode,
                setShowDiscountInvalidPopup,
              })
            }
          />
        )}
        {isShowFormPaymentByDeposit && (
          <>
            {isMobile || isTablet ? (
              <FormPaymentByDepositMobile
                isFx={true}
                isOpen={isShowFormPaymentByDeposit}
                transactionInformation={transactionInformation}
                cardInformation={cardInformation}
                setCardInformation={setCardInformation}
                cardValidation={cardValidation}
                setCardValidation={setCardValidation}
                onClose={handleOnCloseFormPaymentByDeposit}
                onClick={(urlRedirect) =>
                  handleOnPayByDepositProcess(
                    urlRedirect,
                    transactionInformation,
                    handleOnCloseFormPaymentByDeposit
                  )
                }
              />
            ) : (
              <FormPaymentByDeposit
                isFx={true}
                isOpen={isShowFormPaymentByDeposit}
                transactionInformation={transactionInformation}
                cardInformation={cardInformation}
                setCardInformation={setCardInformation}
                cardValidation={cardValidation}
                setCardValidation={setCardValidation}
                onClose={handleOnCloseFormPaymentByDeposit}
                onClick={(urlRedirect) =>
                  handleOnPayByDepositProcess(
                    urlRedirect,
                    transactionInformation,
                    handleOnCloseFormPaymentByDeposit
                  )
                }
              />
            )}
          </>
        )}
        {isShowDiscountInvalidPopup &&
          renderDiscountInvalidPopup({
            isOpen: isShowDiscountInvalidPopup,
          })}

        <BodySectionHeader
          titleBodySection={getTitleBodySection()}
          titleSection={t('add_transaction_title_payment_method')}
          CalculatorComponent={isDesktop && <CalculatorForm />}
          onToggleCancelPopup={handleOnToggleCancelPopup}
          onViewPickupLocation={handleOnViewPickupLocation}
        />
        <FxMakePayment
          renderActionsButton={renderActionsButton}
          renderDiscountSection={renderDiscountSection}
        />
      </>
    );
  return (
    <>
      {isShowTransactionDetails && (
        <FormTransactionSummary
          isOpen={isShowTransactionDetails}
          onClose={handleOnCloseTransactionDetailsPopup}
          onClick={() =>
            handleOnPayProcess({
              transactionInformation,
              setTransactionInformation,
              setShowTransactionDetails,
              setShowFormPaymentByDeposit,
              setErrorCode,
              setShowDiscountInvalidPopup,
            })
          }
        />
      )}
      {isShowFormPaymentByDeposit && (
        <>
          {isMobile || isTablet ? (
            <FormPaymentByDepositMobile
              isFx={true}
              isOpen={isShowFormPaymentByDeposit}
              transactionInformation={transactionInformation}
              cardInformation={cardInformation}
              setCardInformation={setCardInformation}
              cardValidation={cardValidation}
              setCardValidation={setCardValidation}
              onClose={handleOnCloseFormPaymentByDeposit}
              onClick={(urlRedirect) =>
                handleOnPayByDepositProcess(
                  urlRedirect,
                  transactionInformation,
                  handleOnCloseFormPaymentByDeposit
                )
              }
            />
          ) : (
            <FormPaymentByDeposit
              isFx={true}
              isOpen={isShowFormPaymentByDeposit}
              transactionInformation={transactionInformation}
              cardInformation={cardInformation}
              setCardInformation={setCardInformation}
              cardValidation={cardValidation}
              setCardValidation={setCardValidation}
              onClose={handleOnCloseFormPaymentByDeposit}
              onClick={(urlRedirect) =>
                handleOnPayByDepositProcess(
                  urlRedirect,
                  transactionInformation,
                  handleOnCloseFormPaymentByDeposit
                )
              }
            />
          )}
        </>
      )}
      {isShowDiscountInvalidPopup &&
        renderDiscountInvalidPopup({
          isOpen: isShowDiscountInvalidPopup,
        })}

      <BodySectionHeader
        titleBodySection={getTitleBodySection()}
        titleSection={t('fx_add_transaction_confirm_information')}
        CalculatorComponent={isDesktop && <CalculatorForm />}
        onToggleCancelPopup={handleOnToggleCancelPopup}
        onViewPickupLocation={handleOnViewPickupLocation}
      />
      <ConfirmInformationStyled>
        {renderNoticeSection()}
        <PersonalDetails />
        {renderDiscountSection()}
        {renderAdditionalInformation()}
        {renderActionsButton()}
      </ConfirmInformationStyled>
    </>
  );
};

const ConfirmInformationStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -8px;
  padding-inline: 0px;
  padding-bottom: 204px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 774px;
    margin-top: 0px;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

export const NextWrap = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: fit-content;
  margin-inline: -16px;
  padding-inline: 0px;
  background: transparent;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    width: 774px;
    height: fit-content;
    border-top: unset;
  }
`;
export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  width: 100%;
  background: var(--ds-c-white);
  border-top: 1px solid var(--ds-bg-2);
  padding-inline: 16px;
  margin: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 100%;
    height: 44px;
    border-top: unset;
    padding-inline: unset;
    margin: 0px;
  }
`;
export const ButtonCommonWrap = styled.div`
  width: 50%;
`;

export const DiscountWrapper = styled.div`
  border-top: 1px dashed var(--ds-bg-2);
  border-bottom: 1px dashed var(--ds-bg-2);
  padding-block: 12px;
  margin-top: 16px;
`;

const NoticeSection = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: var(--ds-c-yellow-light);
  border-radius: 8px;
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;

  & p {
    ${paragraphMixin};
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 666px;
  }
`;

export default ConfirmInformation;
