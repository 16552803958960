import useLang from 'hooks/useLang';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import CheckboxIcon from '../../../../../assets/icons/checkbox-icon.svg';
import CheckedIcon from '../../../../../assets/icons/checked-icon.svg';
import {
  DESKTOP_MIN_WIDTH,
  REGISTER_NEWS_FIELD,
} from '../../../../../constants';

const FormInputInformCheckbox = ({ information, setInformation }) => {
  const { t } = useLang();
  const { isOptinNews } = information;

  const handleOnChange = () => {
    information = {
      ...information,
      isOptinNews: !isOptinNews,
    };

    setInformation(information);
  };

  return (
    <FormCheckStyled
      type={REGISTER_NEWS_FIELD.type}
      label={t(REGISTER_NEWS_FIELD.label)}
      name={REGISTER_NEWS_FIELD.name}
      checked={isOptinNews}
      onClick={handleOnChange}
      readOnly
    />
  );
};

const FormCheckStyled = styled(Form.Check)`
  display: flex;
  align-items: start;

  label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    color: var(--ds-c-grey-dark);
    margin-left: 8px;
    cursor: text !important;
    user-select: none;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 14px;
      line-height: 17px;
    }
  }

  input:checked[type='checkbox'] {
    background-image: url(${CheckedIcon});
    background-size: cover;
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: url(${CheckboxIcon});
    background-size: cover;
    width: 26px;
    height: 26px;
    cursor: pointer !important;
    margin-top: 0px;

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    align-items: center;
  }
`;

export default FormInputInformCheckbox;
