import { useEffect } from 'react';
import useStore from './useStore';

const useDisableBodyScroll = () => {
  const { state } = useStore();
  const { backdrop } = state;

  useEffect(() => {
    const bodyEle = document.getElementsByTagName('body')[0];
    if (backdrop.isShow) {
      bodyEle.style.overflow = 'hidden';
    }

    return () => {
      bodyEle.style.overflow = 'scroll';
    };
  }, [backdrop]);
};

export default useDisableBodyScroll;
