import GiftIcon from 'assets/ver2/icons/gift-color-icon.svg';
import {
  fetchAllDiscounts,
  getDiscountsTypePromotion,
  getDiscountsTypeVoucher,
} from 'components/Discount/func';
import {
  DESKTOP_MIN_WIDTH,
  DISCOUNT_ACTIVE,
  DISCOUNT_COMING_SOON,
  DISCOUNT_EXPIRED,
  DISCOUNT_FULLY_REDEEMED,
  DISCOUNT_REDEEMED,
  PROMOTION,
  SCREEN_ID_OFFER_PROMOTIONS,
} from 'constants';
import useAuth from 'hooks/useAuth';
import useEvent from 'hooks/useEvent';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DiscountsTab from '../DiscountsTab';
import OfferSection from '../Offers/components/OfferSection';

const SCREEN_ID = SCREEN_ID_OFFER_PROMOTIONS;

const Promotions = () => {
  const { t } = useLang();
  const { token } = useAuth();

  const [promotion, setPromotion] = useState(null);
  const [voucher, setVoucher] = useState(null);

  const isFetching = useRef();

  useEffect(() => {
    if (token && !voucher && !promotion && !isFetching.current) {
      isFetching.current = true;

      fetchAllDiscounts(token, (data) => {
        setPromotion(getDiscountsTypePromotion(data));

        setVoucher(getDiscountsTypeVoucher(data));

        isFetching.current = false;
      });
    }
  }, [token]);

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [],
    modalStyles: {},
    modalOptions: {},
  });
  useEffect(() => {
    if (!isShowEvent) return;
    showEventModal();
  }, [isShowEvent]);

  if (isEmpty(promotion))
    return (
      <PromotionsStyled $isCenter={true}>
        <DiscountsTab items={[]} type={PROMOTION} />
      </PromotionsStyled>
    );
  return (
    <>
      <PromotionsStyled>
        <OfferSection
          title={t('label_offers_promotions_special')}
          descIcon={GiftIcon}
          descText={t('label_offers_promotions_special_desc')}
          emptyText={t('label_offers_promotion_empty')}
          offers={promotion?.filter((p) => p?.status === DISCOUNT_ACTIVE)}
          type={PROMOTION}
        />
        <OfferSection
          title={t('label_offers_upcoming')}
          offers={promotion?.filter((p) => p?.status === DISCOUNT_COMING_SOON)}
          type={PROMOTION}
          emptyText={t('label_offers_promotion_upcoming_empty')}
        />
        <OfferSection
          title={t('label_offers_redeemed_expired')}
          offers={promotion?.filter(
            (p) =>
              p?.status === DISCOUNT_REDEEMED ||
              p?.status === DISCOUNT_EXPIRED ||
              p?.status === DISCOUNT_FULLY_REDEEMED
          )}
          isShowDivider={false}
          type={PROMOTION}
          emptyText={t('label_offers_promotion_redeemed_expired_empty')}
        />
      </PromotionsStyled>
    </>
  );
};

const PromotionsStyled = styled.div`
  display: inline-block;
  width: 100%;
  height: fit-content;

  ${(props) =>
    props.$isCenter &&
    `
  height: calc(100vh - 248px);
  display: flex;
  align-items: center;
  justify-content: center;
  `}

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    ${(props) =>
      props.$isCenter &&
      `
    height: calc(100vh - 144px);
    `}
  }
`;

export default Promotions;
