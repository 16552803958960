import { DESKTOP_MIN_WIDTH, TABLET_MAX_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import { forwardRef, useImperativeHandle, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styled from 'styled-components';

const Drawer = forwardRef(
  ({ navTitle, NavBarToggle, ContentBody, onClose }, ref) => {
    const { t } = useLang();

    const [isShowNavbar, setShowNavbar] = useState(false);

    const handleOnToggleNavbar = () => {
      setShowNavbar(!isShowNavbar);
    };

    useImperativeHandle(ref, () => ({
      isShowNavbar,
      handleOnToggleNavbar,
    }));

    const renderNavbarToggle = () => {
      return NavBarToggle;
    };

    const renderContentBody = () => {
      return ContentBody;
    };

    return (
      <>
        <style>
          {`
          .drawer-offcanvas {
            width: 100%!important;
            height: 100%;
            border: none!important;
          }
          .drawer-offcanvas-backdrop {
            opacity: 0.5!important;
            z-index: 1056;
          }
          @media screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            .drawer-offcanvas {
              height: calc(100% - 70px);
              margin-top: 70px;
              z-index: 1056;
            }
          }
          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
            .drawer-offcanvas {
              width: 375px!important;
              z-index: 1056;
            }
            
          }
        `}
        </style>
        <DrawerStyled>
          <NavbarStyled
            expand={false}
            expanded={isShowNavbar}
            onToggle={handleOnToggleNavbar}
          >
            <NavbarToggle aria-controls="offcanvasNavbar-expand-false">
              {renderNavbarToggle()}
            </NavbarToggle>
            <NavbarOffcanvas
              id="offcanvasNavbar-expand-false"
              aria-labelledby="offcanvasNavbarLabel-expand-false"
              placement="end"
              className="drawer-offcanvas"
              backdropClassName="drawer-offcanvas-backdrop"
              backdrop="false"
              onHide={onClose}
            >
              <OffcanvasHeader closeButton>
                <OffcanvasTitle id="offcanvasNavbarLabel-expand-false">
                  {navTitle || t('label_select_currency')}
                </OffcanvasTitle>
              </OffcanvasHeader>
              <OffcanvasBody>
                <ContentBodyWrap>{renderContentBody()}</ContentBodyWrap>
              </OffcanvasBody>
            </NavbarOffcanvas>
          </NavbarStyled>
        </DrawerStyled>
      </>
    );
  }
);

const DrawerStyled = styled.div``;

const NavbarStyled = styled(Navbar)`
  display: initial;
  padding: 0;
`;
const NavbarToggle = styled(Navbar.Toggle)`
  padding: 0;
  border: none;
  width: 100%;

  &:focus {
    border: none;
    box-shadow: none;
  }
`;

const NavbarOffcanvas = styled(Navbar.Offcanvas)``;
const OffcanvasHeader = styled(Offcanvas.Header)`
  height: 56px;
  padding: 16px;
  border-bottom: 1px solid #eef2f5;

  & button {
    background-size: 14px;
    color: var(--ds-c-blue);
    filter: var(--ds-f-c-blue);
    opacity: 1;

    &:hover {
      color: var(--ds-c-blue-hover);
    }

    &:focus {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: 56px;
  }
`;
const OffcanvasTitle = styled(Offcanvas.Title)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--ds-c-blue);
  text-transform: uppercase;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const OffcanvasBody = styled(Offcanvas.Body)`
  padding: 16px;
  overflow-x: hidden;
`;

const ContentBodyWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export default Drawer;
