/* eslint-disable no-unused-vars */
import { api } from 'api';
import { preventKeyDownEnter } from 'components/common/func';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useLang from 'hooks/useLang';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import {
  DEBOUNCE_SEC,
  DESKTOP_MIN_WIDTH,
  RESET_BACKDROP_STORE,
} from '../../../../../constants/common';
import useStore from '../../../../../hooks/useStore';
import { SET_SHOW_BACKDROP } from '../../../../../store/action';
import ArrowDownIcon from '../../../../common/Icons/ArrowDownIcon';

const FormInputAddressFinderValidation = ({
  field,
  information,
  setInformation,
  validation,
  setValidation,
  addressSuggestion,
  setAddressSuggestion,
  setAddressFinder,
  checkFormInputValidation,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { backdrop, eventAvailable } = state;

  const isShowArrowDownIcon = (name) =>
    Boolean(addressSuggestion?.length) &&
    backdrop?.current === name &&
    backdrop?.isShow;

  const fetchLookupAddressSuggestion = async (value) => {
    try {
      const { data } = await api.lookupAddresses(
        value,
        'd6fe8ff05352dfff3c3a3367d7140364'
      );

      if (data?.payload) {
        setAddressSuggestion(data.payload);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };
  const checkLookupAddressSuggestion = async (value) => {
    await fetchLookupAddressSuggestion(value);

    dispatch({
      type: SET_SHOW_BACKDROP,
      payload: {
        isShow: true,
        current: field.name,
      },
    });
  };
  const { debounce: handleLookupAddressSuggestion } = useDebounceCallback(
    (value) => {
      checkLookupAddressSuggestion(value);
    },
    DEBOUNCE_SEC
  );

  const handleOnChange = (e) => {
    const { value } = e.target;
    const valueTrim = value.trim();

    if (!valueTrim) {
      setAddressSuggestion(null);

      setAddressFinder(null);

      return;
    }

    handleLookupAddressSuggestion(valueTrim);
  };

  const handleOnClick = () => {
    setInformation({
      ...information,
      [field.name]: '',
    });

    setValidation({
      ...validation,
      [field.name]: '',
    });

    document.getElementsByName(field.name)[0].value = '';

    setAddressSuggestion(null);

    setAddressFinder(null);

    dispatch({
      type: SET_SHOW_BACKDROP,
      payload: {
        isShow: false,
        current: RESET_BACKDROP_STORE.current,
        onClick: () => {
          const newValidation = checkFormInputValidation('', field, validation);
          setValidation(newValidation);
        },
      },
    });
  };

  const handleFormLabelOnClick = (name) => {
    const inputEle = document.getElementsByName(name)[0];

    if (inputEle) {
      inputEle.focus();
    }
  };

  return (
    <FormGroupStyled onKeyDown={preventKeyDownEnter}>
      <FormLabel onClick={() => handleFormLabelOnClick(field.name)}>
        {t(field.label)}
        <span>*</span>
      </FormLabel>
      <FormControl
        type={field.type}
        name={field.name}
        defaultValue={information[field.name]}
        placeholder={t(field.placeholder)}
        isInvalid={validation[field.name]}
        onChange={handleOnChange}
        onClick={handleOnClick}
      />
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[field.name])}
      </FormControlFeedback>
      {isShowArrowDownIcon(field.name) && (
        <ArrowDownIcon
          color="var(--ds-c-grey-dark)"
          size={{ width: 12, height: 12 }}
          styles={{
            position: 'absolute',
            top: '44px',
            right: '16px',
            transform: 'rotate(180deg)',
            cursor: 'pointer',
          }}
          onClick={handleOnClick}
        />
      )}
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  position: relative;
  margin-bottom: 32px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 42px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  display: block;
  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: var(--ds-c-white);

  &:focus {
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &[readonly] {
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputAddressFinderValidation;
