import InformPopup from 'components/common/InformPopup';
import {
  ADD_TRANSACTION_METHOD_BANK_NAME,
  ADD_TRANSACTION_METHOD_PICKUP_NAME,
  BANK_NAMES_VALIDATION_HOLIDAY,
  METHOD_BANK,
  METHOD_PICKUP,
} from 'constants';
import { datePicker } from 'helpers';
import { useEffect, useState } from 'react';
import { stringUtils } from 'utils';
import useLang from './useLang';
import useStore from './useStore';

const useValidationToCreateATransactionOnHoliday = ({
  isDisabled,
  countryCode,
  payoutMethod,
  payoutItem,
  currencyCode,
  receiverAmount = 0,
}) => {
  const { t } = useLang();
  const { state } = useStore();
  const { addTransactionInformation, addTransactionCurrentStep: currentStep } =
    state;

  const { receiverPayoutMethod } = addTransactionInformation;

  const [isOpenHolidayUrgentPopup, setOpenHolidayUrgentPopup] = useState(false);

  const dateUTC = datePicker.getDateUTC(2, 0, 2024);
  const isDisableCheck = datePicker.getExpired(dateUTC);

  const pickupOfficeItem = payoutItem?.pickupOffice;
  const bankItem = payoutItem?.bank;
  const bankValidation =
    BANK_NAMES_VALIDATION_HOLIDAY.find(
      (bankName) => bankName === bankItem?.bankName
    ) || '';

  const pickupOfficeItemStore =
    receiverPayoutMethod[ADD_TRANSACTION_METHOD_PICKUP_NAME]?.pickupOffice;
  const isPickupItemSelectedChanged =
    pickupOfficeItemStore &&
    pickupOfficeItemStore?.pickUpOfficeId !== pickupOfficeItem?.pickUpOfficeId;
  const bankItemStore =
    receiverPayoutMethod[ADD_TRANSACTION_METHOD_BANK_NAME]?.bank;
  const isBankItemSelectedChanged =
    bankItemStore && bankItemStore?.bankName !== bankItem?.bankName;

  const isStep1 = currentStep === 0;
  const isStep2 = currentStep === 1;

  const isVNM = countryCode === 'VNM';
  const isPHL = countryCode === 'PHL';

  const getContentMsg = () => {
    if (isVNM && payoutMethod === METHOD_PICKUP)
      return stringUtils.replaceKeyword(
        t('popup_vnm_pickup_method_new_year_holiday_msg'),
        [
          {
            key: '\n',
            value: '<br/>',
          },
        ],
        ''
      );
    if (isVNM && payoutMethod === METHOD_BANK)
      return stringUtils.replaceKeyword(
        t('popup_vnm_receiving_amount_new_year_holiday_msg'),
        [
          {
            key: '\n',
            value: '<br/>',
          },
        ],
        ''
      );

    if (isPHL && payoutMethod === METHOD_BANK)
      return stringUtils.replaceKeyword(
        t('popup_phl_receiving_amount_new_year_holiday_msg'),
        [
          {
            key: '\n',
            value: '<br/>',
          },
        ],
        ''
      );
    return '';
  };
  const HolidayUrgentPopup = ({ isOpen, setOpen }) => (
    <InformPopup
      isOpen={isOpen}
      content={getContentMsg()}
      buttonLabel2={t('button_close')}
      onClick={() => {
        setOpen(false);
      }}
      isBackdropOverlap={false}
    />
  );

  useEffect(() => {
    if (isDisabled) return;

    if (isDisableCheck) return;

    if (isStep1 && pickupOfficeItemStore && !isPickupItemSelectedChanged)
      return;

    if (isStep1 && bankItemStore && !isBankItemSelectedChanged) return;

    if (isVNM) {
      if (payoutMethod === METHOD_PICKUP) {
        if (
          isStep1 &&
          pickupOfficeItem &&
          pickupOfficeItem?.pickUpOfficeId === 61
        ) {
          // display popup message
          setOpenHolidayUrgentPopup(true);
          return;
        }
      }

      if (payoutMethod === METHOD_BANK) {
        if (isStep1 && bankItem && !bankValidation) {
          // display popup message
          setOpenHolidayUrgentPopup(true);
          return;
        }

        if (
          isStep2 &&
          bankItem &&
          bankValidation &&
          currencyCode === 'VND' &&
          receiverAmount >= 500000000
        ) {
          // display popup message
          setOpenHolidayUrgentPopup(true);
          return;
        }
      }
    }

    if (isPHL) {
      if (payoutMethod === METHOD_BANK) {
        if (
          isStep2 &&
          bankItem &&
          currencyCode === 'PHP' &&
          receiverAmount >= 50000
        ) {
          // display popup message
          setOpenHolidayUrgentPopup(true);
          return;
        }
      }
    }
  }, [
    isDisabled,
    isDisableCheck,
    pickupOfficeItemStore,
    isPickupItemSelectedChanged,
    bankItemStore,
    isBankItemSelectedChanged,
    isStep1,
    isStep2,
    countryCode,
    isVNM,
    isPHL,
    payoutMethod,
    payoutItem,
    currencyCode,
    receiverAmount,
  ]);

  return {
    isOpenHolidayUrgentPopup,
    setOpenHolidayUrgentPopup,
    HolidayUrgentPopup,
  };
};

export default useValidationToCreateATransactionOnHoliday;
