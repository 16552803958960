import { api } from 'api';
import WarnningIcon from 'assets/icons/warnning-red-icon.svg';
import FormInputValidation from 'components/FormResetPassword/components/FormInputValidation';
import {
  checkFormInputValidation,
  checkTokenExpired,
} from 'components/FormResetPassword/func';
import {
  DESKTOP_MIN_WIDTH,
  PASSWORD_LIVE_CHECKS,
  RESET_PASSWORD_FIELDS,
  RESET_PASSWORD_INFORMATION,
  RESET_PASSWORD_PASSWORD_NAME,
} from 'constants';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import ButtonCommon from '../../common/ButtonCommon';

import FormAccountActivationExpiredMobile from '../components/FormAccountActivationExpired/mobile';
import FormAccountActivationSuccessMobile from '../components/FormAccountActivationSuccess/mobile';

const initialField = RESET_PASSWORD_INFORMATION;

const FormAccountActivationMobile = () => {
  const { t } = useLang();
  const { dispatch } = useStore();
  const query = useQuery();
  const token = query.get('token');

  const [information, setInformation] = useState(initialField);
  const [validation, setValidation] = useState(initialField);
  const [isAccountActivationSuccess, setAccountActivationSuccess] =
    useState(false);
  const [isAccountActivationExpired, setAccountActivationExpired] =
    useState(false);
  const [liveCheckStatus, setLiveCheckStatus] = useState({
    0: WarnningIcon,
    1: WarnningIcon,
    2: WarnningIcon,
    3: WarnningIcon,
  });

  useEffect(() => {
    if (checkTokenExpired(token)) {
      setAccountActivationExpired(true);
    }
  }, [token]);

  const handleSubmit = async () => {
    if (checkTokenExpired(token)) {
      setAccountActivationExpired(true);

      return;
    }

    let newInformation = { ...information };
    let newValidation = { ...validation };

    RESET_PASSWORD_FIELDS.forEach((field) => {
      newValidation = checkFormInputValidation(
        information[field.name],
        field,
        newInformation,
        newValidation
      );
    });

    setValidation(newValidation);

    const isAllowSubmit =
      Object.values(information).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowSubmit) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      try {
        const { data } = await api.activate(
          query.get('token'),
          information[RESET_PASSWORD_PASSWORD_NAME]
        );

        if (data?.success) {
          setAccountActivationSuccess(true);

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      } catch (error) {
        console.error(error?.message);

        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }
    }
  };

  if (isAccountActivationExpired) {
    return <FormAccountActivationExpiredMobile />;
  }
  if (isAccountActivationSuccess) {
    return <FormAccountActivationSuccessMobile />;
  }
  return (
    <>
      <TitleH3>{t('account_activation_text_account_activation')}</TitleH3>
      <Paragraph>{t('reset_password_text_password_requirements')}</Paragraph>
      <PasswordRequires>
        {PASSWORD_LIVE_CHECKS.map((check, index) => (
          <li key={check.id}>
            <img src={liveCheckStatus[index]} alt="" />
            <Paragraph>{t(check.require)}</Paragraph>
          </li>
        ))}
      </PasswordRequires>
      <FormAccountActivationMobileStyled>
        {RESET_PASSWORD_FIELDS.map((field) => (
          <FormInputValidation
            key={field.name}
            field={field}
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            setLiveCheckStatus={setLiveCheckStatus}
          />
        ))}

        <ConfirmSubmit>
          <ButtonCommon
            value={t('button_confirm')}
            onClick={handleSubmit}
            styles={{
              margin: '0px',
              width: '100%',
            }}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
          />
        </ConfirmSubmit>
      </FormAccountActivationMobileStyled>
    </>
  );
};

const FormAccountActivationMobileStyled = styled(Form)`
  position: relative;
  width: 100%;
  height: fit-content;
  padding-bottom: 96px;
`;

const ConfirmSubmit = styled.div`
  position: fixed;
  bottom: 0px;
  background: var(--ds-c-white);
  width: 100%;
  margin-inline: -24px;
  padding: 16px 24px;
  border-top: 1px solid var(--ds-c-grey-disabled);
`;

const TitleH3 = styled.h3`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const PasswordRequires = styled.ul`
  margin: 0;
  margin-bottom: 24px;
  padding-left: 0px;

  & li {
    display: flex;
    align-items: center;
    list-style: disc;
    list-style-type: disc;

    & img {
      margin-right: 8px;
    }

    & p {
      margin-bottom: 0px;
    }
  }
`;

export default FormAccountActivationMobile;
