import { useEffect } from 'react';

const useClickEventListener = ({ className = '', callback = () => {} }) => {
  useEffect(() => {
    const navbarCloseButtonDesktop =
      document.getElementsByClassName(className)?.[0];
    const navbarCloseButtonMobile =
      document.getElementsByClassName(className)?.[1];
    if (navbarCloseButtonDesktop) {
      navbarCloseButtonDesktop.addEventListener('click', (e) => {
        e.preventDefault();
        callback();
      });
      navbarCloseButtonDesktop.addEventListener('touchstart', (e) => {
        e.preventDefault();
        callback();
      });
    }
    if (navbarCloseButtonMobile) {
      navbarCloseButtonMobile.addEventListener('click', (e) => {
        e.preventDefault();
        callback();
      });
      navbarCloseButtonMobile.addEventListener('touchstart', (e) => {
        e.preventDefault();
        callback();
      });
    }
    return () => {
      if (navbarCloseButtonDesktop) {
        navbarCloseButtonDesktop.removeEventListener('click', (e) => {
          e.preventDefault();
          callback();
        });
        navbarCloseButtonDesktop.removeEventListener('touchstart', (e) => {
          e.preventDefault();
          callback();
        });
      }
      if (navbarCloseButtonMobile) {
        navbarCloseButtonMobile.removeEventListener('click', (e) => {
          e.preventDefault();
          callback();
        });
        navbarCloseButtonMobile.removeEventListener('touchstart', (e) => {
          e.preventDefault();
          callback();
        });
      }
    };
  });
};

export default useClickEventListener;
