import {
  ACCOUNT_NUMBER_REGEX,
  ADDRESS_REGEX,
  ADDRESS_TYPE_2_REGEX,
  ADDRESS_TYPE_3_REGEX,
  BUSINESS_NAME_REGEX,
  DATE_REGEX,
  EMAIL_REGEX,
  LEAST_LOWERCASE_REGEX,
  LEAST_ONE_NUMBER_REGEX,
  LEAST_UPPERCASE_REGEX,
  LENGTH_IS_8_REGEX,
  MASTERCARD_REGEX,
  MESSAGE_REGEX,
  MOBILE_PHONE_REGEX,
  NUMBER_REGEX,
  PASSWORD_REGEX,
  REF_NUMBER_REGEX,
  TEXT_ONLY_REGEX,
  VISA_REGEX,
} from '../constants';

export const checkRegexValid = (value, regex) => {
  if (value) {
    const found = value.match(regex);
    return found ? found[0] : found;
  }

  return false;
};

export const emailValid = (value) => {
  if (value) {
    const found = value.match(EMAIL_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const passwordValid = (value) => {
  if (value) {
    const found = value.match(PASSWORD_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const fieldValid = (value) => {
  if (value) {
    return true;
  }

  return false;
};

export const mobilePhoneValid = (value) => {
  if (value) {
    const found = value.match(MOBILE_PHONE_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const textValid = (value) => {
  if (value) {
    const found = value.match(TEXT_ONLY_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const msgValid = (value) => {
  if (value) {
    const found = value.match(MESSAGE_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const numberValid = (value) => {
  if (value) {
    const found = value.match(NUMBER_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const addressValid = (value) => {
  if (value) {
    const found = value.match(ADDRESS_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const address2Valid = (value) => {
  if (typeof value === 'object') {
    value = value?.name || '';
  }

  if (value) {
    const found = value.match(ADDRESS_TYPE_2_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const address3Valid = (value) => {
  if (typeof value === 'object') {
    value = value?.name || '';
  }

  if (value) {
    const found = value.match(ADDRESS_TYPE_3_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const refNumberPhoneValid = (value) => {
  if (value) {
    const found = value.match(REF_NUMBER_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const accountNumberValid = (value) => {
  if (value) {
    const found = value.match(ACCOUNT_NUMBER_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const visaNumberValid = (value) => {
  if (value) {
    const found = value.match(VISA_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const masterCardNumberValid = (value) => {
  if (value) {
    const found = value.match(MASTERCARD_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const lengthIs8Valid = (value) => {
  if (value) {
    const found = value.match(LENGTH_IS_8_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const leastOneNumberValid = (value) => {
  if (value) {
    const found = value.match(LEAST_ONE_NUMBER_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const leastLowercaseValid = (value) => {
  if (value) {
    const found = value.match(LEAST_LOWERCASE_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const leastUppercaseValid = (value) => {
  if (value) {
    const found = value.match(LEAST_UPPERCASE_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const dateValid = (value) => {
  if (value) {
    const found = String(value).match(DATE_REGEX);
    return found ? found[0] : found;
  }

  return false;
};

export const businessNameValid = (value) => {
  if (value) {
    const found = value.match(BUSINESS_NAME_REGEX);
    return found ? found[0] : found;
  }

  return false;
};
