import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import styled from 'styled-components';

import AddIcon from 'assets/icons/add-blue-icon.svg';
import AddCircleOutlineIcon from 'assets/icons/add-receiver-outline-icon.svg';
import ArrowDownSVG from 'assets/ver2/icons/arrow-down-icon.svg';
import DeleteIcon from 'assets/icons/delete-outline-icon.svg';
import EditIcon from 'assets/icons/edit-outline-icon.svg';
import {
  getBankUpdateVerification,
  getFullNameReceiver,
  getNumberUnmaskStr,
  isIdSelected,
} from 'components/FormEditReceiver/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  ADD_BANK_ACCOUNT_HOLDER_NAME,
  ADD_BANK_ACCOUNT_NUMBER_NAME,
  ADD_BANK_BSB_NAME,
  ADD_BANK_NAME,
  ADD_BANK_SWIFT_NAME,
  DESKTOP_MIN_WIDTH,
  MOBILE_MIN_WIDTH,
} from 'constants';
import { formatter } from 'helpers';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import { arrayUtils, domUtils } from 'utils';

import {
  EDIT_RECEIVER_FULLNAME_NAME,
  EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME,
} from 'constants';
import useReceiver from 'hooks/receiver/useReceiver';
import DeleteBankAccountPopup from '../DeleteBankAccountPopup';
import FormUpdateBankAccount from '../FormAddNewBankAccount';

const CustomBanksAccordion = ({
  banks,
  countryConfig,
  information,
  setInformation,
  bankInformation,
  validation,
  setBankInformation,
  bankValidation,
  setBankValidation,
  handleOnToggleFormAddNewBank,
}) => {
  const { t } = useLang();
  const { state } = useStore();
  const { currentReceiver, editReceiverInformation } = state;
  const { personalDetails } = editReceiverInformation;
  const { isHHMTTheme } = useTheme();

  const config = useGetConfig({ countryConfig });
  const { accountHolder, accountNumber, accountSwift, accountBsb } = config;

  const accountNumberFormat = formatter.formatParse(accountNumber?.format);
  const bsbNumberFormat = formatter.formatParse(accountBsb?.format);

  const [currentBankSelected, setCurrentBankSelected] = useState(null);
  const [isOpenDeleteBankPopup, setOpenDeleteBankPopup] = useState(false);
  const [isOpenUpdateBankPopup, setOpenUpdateBankPopup] = useState(false);

  const {
    isReceiverOfBusinessType: isReceiverOfBusinessTypeStore,
    isReceiverOfIndividualType: isReceiverOfIndividualTypeStore,
  } = useReceiver();
  const { lastName, firstName } = currentReceiver || {};
  const receiverFullName =
    getFullNameReceiver({
      isReceiverOfBusinessType: isReceiverOfBusinessTypeStore,
      isReceiverOfIndividualType: isReceiverOfIndividualTypeStore,
      lastName,
      firstName,
      countryCode: countryConfig?.countryCode,
    }) || personalDetails?.[EDIT_RECEIVER_FULLNAME_NAME];

  const accountHolderName =
    bankInformation[ADD_BANK_ACCOUNT_HOLDER_NAME] || receiverFullName;

  const isReceiverOfIndividualType =
    personalDetails[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME] === 1 ||
    isReceiverOfIndividualTypeStore;
  const isReceiverOfBusinessType =
    personalDetails[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME] === 2 ||
    isReceiverOfBusinessTypeStore;

  const handleAccordionItemSelected = (bank) => {
    if (isIdSelected(bank, currentBankSelected)) {
      setCurrentBankSelected(null);

      return;
    }

    setCurrentBankSelected(bank);
  };

  const handleOnToggleDeleteBankPopup = (e, bank) => {
    e.preventDefault();
    e.stopPropagation();

    setCurrentBankSelected(bank);

    setOpenDeleteBankPopup(!isOpenDeleteBankPopup);
  };

  const handleOnClickDeleteBankPopup = () => {
    const newBanks = arrayUtils.removeItemInArrayObject(
      information?.banks,
      currentBankSelected?.id,
      'id'
    );

    const newInformation = {
      ...information,
      banks: newBanks,
    };
    setInformation(newInformation);

    setOpenDeleteBankPopup(false);
  };

  const handleOnCloseDeletePopup = () => {
    setCurrentBankSelected(null);

    setOpenDeleteBankPopup(false);
  };

  const handleOnToggleFormUpdateBank = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!isOpenUpdateBankPopup) {
      const currentBank =
        information?.banks?.find((i) => isIdSelected(i, currentBankSelected)) ||
        currentBankSelected;

      const newBankInformation = {
        id: currentBank?.id,
        [ADD_BANK_NAME]: currentBank?.bankName,
        [ADD_BANK_ACCOUNT_HOLDER_NAME]:
          currentBank?.accountName || accountHolderName,
        [ADD_BANK_ACCOUNT_NUMBER_NAME]: currentBank?.accountNumber,
        [ADD_BANK_SWIFT_NAME]: currentBank?.swiftCode,
        [ADD_BANK_BSB_NAME]: currentBank?.branchCode,
      };

      setBankInformation(newBankInformation);
    }

    setOpenUpdateBankPopup(!isOpenUpdateBankPopup);
  };

  const handleUpdateBankAccount = async (bankUpdate, bankInformation) => {
    const { bankValidate } = bankInformation || {};
    const { holderName } = bankValidate || {};

    let bank = {
      id: bankUpdate?.id,
      bankName: bankUpdate.bankName,
      accountName:
        holderName || currentReceiver
          ? bankUpdate.accountName
          : accountHolderName,
      accountNumber: bankUpdate.accountNumber,
      swiftCode: bankUpdate.swiftCode,
      branchCode: bankUpdate.branchCode,
    };

    bank = getBankUpdateVerification({
      bankValidate,
      bankUpdate: bank,
      countryCode: countryConfig?.countryCode,
      receiverFullName: accountHolderName,
    });

    const pos = information.banks.findIndex((i) => {
      return isIdSelected(i, bank);
    });
    const newBanks =
      pos !== -1
        ? arrayUtils.replaceItemInArray(information.banks, bank, pos)
        : information.banks;

    const newInformation = {
      ...information,
      banks: newBanks,
    };
    setInformation(newInformation);

    handleOnToggleFormUpdateBank();
  };

  // const renderButtonAdd = () => {
  //   if (isReceiverOfIndividualType)
  //     return (
  //       <ButtonCommon
  //         value={t('button_add_new_bank_account')}
  //         color="var(--c-tertiary)"
  //         background="var(--ds-c-white)"
  //         isFill={false}
  //         styles={{
  //           paddingInline: 0,
  //           height: '44px',
  //         }}
  //         buttonIconStyles={{
  //           height: '32px',
  //           flexDirection: 'row-reverse',
  //           gap: '6px',
  //         }}
  //         borderNameVariable="--border-secondary"
  //         iconSrc={isHHMTTheme ? AddHHMTIcon : AddRemoxIcon}
  //         onClick={handleOnToggleFormAddNewBank}
  //       />
  //     );

  //   return null;
  // };

  // const renderButtonAdd2 = () => {
  //   if (isReceiverOfBusinessType) {
  //     return (
  //       <ButtonCommon
  //         value={t('button_add_new_bank_account')}
  //         color="var(--ds-c-blue)"
  //         background="var(--ds-c-white)"
  //         borderNameVariable="--border-filter"
  //         isFill={false}
  //         styles={{
  //           height: '44px',
  //           borderRadius: '12px',
  //         }}
  //         buttonIconStyles={{
  //           height: '44px',
  //           flexDirection: 'row-reverse',
  //           gap: '0px',
  //         }}
  //         iconSrc={AddIcon}
  //         onClick={handleOnToggleFormAddNewBank}
  //       />
  //     );
  //   }

  //   return null;
  // };

  const renderBanksEmpty = () => {
    if (isReceiverOfIndividualType)
      return (
        <>
          <NoBank>
            {t('label_no_bank_account')}
            <ButtonCommon
              value={t('button_add_new_bank_account_2')}
              styles={{
                margin: '0px',
              }}
              buttonIconStyles={{
                gap: '0px',
              }}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
              borderNameVariable={'--border-filter'}
              isFill={false}
              iconSrc={AddIcon}
              onClick={handleOnToggleFormAddNewBank}
            />
          </NoBank>
        </>
      );

    if (isReceiverOfBusinessType)
      return (
        <NoBank2 $isRequired={validation}>
          {t('label_no_bank_account')}
          {/* {renderButtonAdd2()} */}
          <ButtonCommon
            value={t('button_add_new_bank_account_2')}
            styles={{
              margin: '0px',
            }}
            buttonIconStyles={{
              gap: '0px',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
            borderNameVariable={'--border-filter'}
            isFill={false}
            iconSrc={AddIcon}
            onClick={handleOnToggleFormAddNewBank}
          />
        </NoBank2>
      );

    return null;
  };

  return (
    <>
      {isOpenDeleteBankPopup && currentBankSelected && (
        <DeleteBankAccountPopup
          isOpen={isOpenDeleteBankPopup && currentBankSelected}
          accountNumber={currentBankSelected?.accountNumber}
          onClose={handleOnCloseDeletePopup}
          onClick={handleOnClickDeleteBankPopup}
        />
      )}

      {isOpenUpdateBankPopup && (
        <FormUpdateBankAccount
          isOpen={isOpenUpdateBankPopup}
          isEdit={isOpenUpdateBankPopup}
          countryConfig={countryConfig}
          currentReceiver={currentReceiver}
          information={information}
          setInformation={setInformation}
          bankInformation={bankInformation}
          setBankInformation={setBankInformation}
          bankValidation={bankValidation}
          setBankValidation={setBankValidation}
          onClose={handleOnToggleFormUpdateBank}
          onClick={handleUpdateBankAccount}
        />
      )}

      <FieldWrap $isReceiverOfBusinessType={isReceiverOfBusinessType}>
        {/* {renderButtonAdd()} */}
        {banks?.length ? (
          <>
            <AccordionStyled>
              {banks.map((bank, index) => (
                <AccordionItem
                  key={bank?.id}
                  eventKey={index}
                  onClick={() => handleAccordionItemSelected(bank)}
                >
                  <AccordionHeader
                    $height={
                      domUtils.isReady &&
                      document.getElementsByClassName(
                        `accordion-header-text-${bank?.id}`
                      )?.[0]?.offsetHeight
                    }
                  >
                    <AccordionHeaderText
                      className={`accordion-header-text-${bank?.id}`}
                    >
                      {bank?.bankName}
                    </AccordionHeaderText>
                    <AccordionHeaderBody>
                      {isIdSelected(bank, currentBankSelected) && (
                        <>
                          <Divider />
                          <FieldBodyContentIcon>
                            <FieldBodyContent>
                              {accountHolder?.visible && (
                                <FieldBodyWrap>
                                  <p>
                                    {t('label_bank_account_holder')}:{' '}
                                    {bank?.accountName || accountHolderName}
                                  </p>
                                </FieldBodyWrap>
                              )}
                              {accountNumber?.visible && (
                                <FieldBodyWrap>
                                  <p>
                                    {t('label_bank_account_number')}:{' '}
                                    {bank?.accountNumber
                                      ? formatter.mask(
                                          getNumberUnmaskStr(
                                            bank?.accountNumber,
                                            accountNumberFormat
                                          ),
                                          accountNumberFormat
                                        )
                                      : ''}
                                  </p>
                                </FieldBodyWrap>
                              )}
                              {accountSwift?.visible && (
                                <FieldBodyWrap>
                                  <p>SWIFT: {bank?.swiftCode}</p>
                                </FieldBodyWrap>
                              )}
                              {accountBsb?.visible && (
                                <FieldBodyWrap>
                                  <p>
                                    {accountBsb?.type || 'BSB'}:{' '}
                                    {bank?.branchCode
                                      ? formatter.mask(
                                          getNumberUnmaskStr(
                                            bank?.branchCode,
                                            bsbNumberFormat
                                          ),
                                          bsbNumberFormat
                                        )
                                      : ''}
                                  </p>
                                </FieldBodyWrap>
                              )}
                            </FieldBodyContent>
                            <FieldBodyIcon>
                              <EditText onClick={handleOnToggleFormUpdateBank}>
                                <EditIconStyled
                                  src={EditIcon}
                                  width={24}
                                  height={24}
                                  alt=""
                                />
                              </EditText>
                              <DeleteText
                                onClick={(e) =>
                                  handleOnToggleDeleteBankPopup(e, bank)
                                }
                              >
                                <DeleteIconStyled
                                  src={DeleteIcon}
                                  width={24}
                                  height={24}
                                  alt=""
                                />
                              </DeleteText>
                            </FieldBodyIcon>
                          </FieldBodyContentIcon>
                        </>
                      )}
                    </AccordionHeaderBody>
                  </AccordionHeader>
                </AccordionItem>
              ))}
            </AccordionStyled>
            {isReceiverOfIndividualType && (
              <ButtonCommon
                value={t('button_add_new_bank_account')}
                color="var(--ds-c-blue-hyperlink-default)"
                background="var(--ds-c-white)"
                styles={{
                  paddingInline: 0,
                  height: '20px',
                  borderRadius: 0,
                  fontWeight: 500,
                }}
                buttonIconStyles={{
                  height: '20px',
                  flexDirection: 'row-reverse',
                  gap: '6px',
                  marginTop: '16px',
                }}
                iconSrc={AddCircleOutlineIcon}
                iconStyles={{
                  filter:
                    'invert(12%) sepia(87%) saturate(6995%) hue-rotate(234deg) brightness(90%) contrast(113%)',
                }}
                onClick={handleOnToggleFormAddNewBank}
              />
            )}
          </>

        ) : (
          <>{renderBanksEmpty()}</>
        )}
      </FieldWrap>
    </>
  );
};

const FieldWrap = styled.div`
  width: 100%;
  height: fit-content;
  margin-top: ${(props) => (props.$isReceiverOfBusinessType ? '0px' : '-16px')};

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 16px;
  }
`;

const AccordionStyled = styled(Accordion)`
  width: 437px;
  margin-top: 16px;

  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) {
    width: 100%;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 24px;
  }
`;
const AccordionItem = styled(Accordion.Item)`
  border: none;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }
`;
const AccordionHeader = styled(Accordion.Header)`
  height: fit-content;
  padding: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  & button {
    padding: 0;
    background-color: var(--ds-c-white) !important;
    box-shadow: none !important;
    display: block;

    &:focus,
    &:hover {
      z-index: 1;
    }

    &::after,
    &:not(.collapsed)::after {
      background-image: url(${ArrowDownSVG});
      background-position: center;
      background-size: 24px 24px;

      position: absolute;
      top: ${(props) => `${props.$height ? (props.$height - 20) / 2 : 0}px`};
      right: 0px;
    }
  }
`;
const AccordionHeaderText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  padding: 0;
  padding-right: 20px;
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const AccordionHeaderBody = styled.div``;
const FieldBodyContentIcon = styled.div`
  display: flex;
  align-items: center;
`;
const FieldBodyContent = styled.div`
  width: 100%;
`;
const FieldBodyIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 56px;
`;
const FieldBodyWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: fit-content;
  margin-top: 8px;

  &:first-child {
    margin-top: 0px;
  }

  & p {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--ds-c-grey-dark);

    cursor: text;
    margin: 0;
    padding: 0;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const NoBank = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  width: 437px;
  height: 164px;
  background: var(--ds-c-white);
  border-radius: 12px;
  border: 1px dashed var(--ds-c-grey-hover);
  margin-top: 16px;

  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) {
    width: 100%;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 24px;
  }
`;
const NoBank2 = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${(props) => (props.$isRequired ? 'var(--ds-c-red)' : 'var(--ds-c-grey-dark)')};

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 164px;
  background: var(--ds-c-white);
  border: ${(props) =>
    props.$isRequired
      ? '1px dashed var(--ds-c-red)'
      : '1px dashed var(--ds-c-grey-hover)'};
  border-radius: 12px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Divider = styled.div`
  border-bottom: 1px solid #eef2f5;
  margin: 16px 0;
`;

const EditText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  margin: 0;
  margin-inline: 0px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
  user-select: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const EditIconStyled = styled.img`
  &:hover {
    filter: var(--ds-f-c-grey-hover);
  }
`;

const DeleteText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #e72631;

  margin: 0;
  margin-inline: 0px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
  user-select: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const DeleteIconStyled = styled.img`
  &:hover {
    filter: var(--ds-f-c-grey-hover);
  }
`;

export default CustomBanksAccordion;
