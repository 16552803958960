import {
  COUNTRY_FLAG_REFERENCES,
} from 'constants';
import styled from 'styled-components';
import useTheme from 'hooks/useTheme';

const ExchangeRateItemMobile = ({ country, rates, isActive, onClick }) => {

  const { isHHMTTheme } = useTheme();

  if (Object.keys(rates).length === 0) return null;

  return (
    <ItemWrap $isActive={isActive} onClick={onClick} >
       <FlagIcon src={COUNTRY_FLAG_REFERENCES[country]} $isHHMTActive={isActive && isHHMTTheme}  $isRemoxActive={isActive && !isHHMTTheme}/>
    </ItemWrap>
  );
};

const ItemWrap = styled.div`
  display: grid;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 22px;
  cursor: pointer;
  user-select: none;
  gap: 10px;
`;

const FlagIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 50%;
  border: 1px solid var(--ds-c-black-disabled);

  ${(props) =>
    props.$isHHMTActive
      ? `
        border: 2px solid var(--ds-c-yellow);
      `
      : ``};
  
  ${(props) =>
    props.$isRemoxActive
      ? `
        border: 2px solid var(--ds-c-sky);
      `
      : ``};   
`;


export default ExchangeRateItemMobile;
