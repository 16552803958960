import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import { SET_OPEN_REFRESH_TOKEN_POPUP } from 'store/action';
import useAuth from './useAuth';
import useStore from './useStore';

const useRefreshTokenTrigger = () => {
  const { state, dispatch } = useStore();
  const { isRefreshToken } = state || {};
  const { token } = useAuth();

  useEffect(() => {
    if (token) {
      const { exp } = jwtDecode(token);

      const listener = () => {
        if (Date.now() >= (exp - 120) * 1000) {
          !isRefreshToken &&
            dispatch({ type: SET_OPEN_REFRESH_TOKEN_POPUP, payload: true });
        }
      };

      document.addEventListener('click', listener);
      document.addEventListener('mousedown', listener);
      document.addEventListener('scroll', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('click', listener);
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('scroll', listener);
        document.removeEventListener('touchstart', listener);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, isRefreshToken]);
};

export default useRefreshTokenTrigger;
