import { ServiceAlertsModel } from './ServiceAlertsModel';

export interface ServiceAlertsByCountryCodeProps {
  isResetReceiverDropdown?: boolean;

  serviceAlertsByCountryCodeData?: ServiceAlertsModel | null;
  serviceAlertsByCountryCode?: ServiceAlertsModel | null;
  serviceAlertsByPayoutMethod?: ServiceAlertsModel | null;
  showReceiverMissingInformationModal?: (() => void) | null;
  serviceAlertsByPayoutMethodItem?: ServiceAlertsModel | null;
  showValidateBankAccountModal?: (() => void) | null;
  serviceAlertsByPayoutMethodItemChild?: ServiceAlertsModel | null;
  serviceAlertsByCurrencyCode1?: ServiceAlertsModel | null;
  serviceAlertsByCurrencyCode2?: ServiceAlertsModel | null;
  serviceAlertsByRangeOfCurrencyCode1?: ServiceAlertsModel | null;
  serviceAlertsByRangeOfCurrencyCode2?: ServiceAlertsModel | null;
}
export const ServiceAlertsByCountryCodeDefault = {
  isResetReceiverDropdown: false,

  serviceAlertsByCountryCodeData: null,
  serviceAlertsByCountryCode: null,
  serviceAlertsByPayoutMethod: null,
  showReceiverMissingInformationModal: null,
  serviceAlertsByPayoutMethodItem: null,
  showValidateBankAccountModal: null,
  serviceAlertsByPayoutMethodItemChild: null,
  serviceAlertsByCurrencyCode1: null,
  serviceAlertsByCurrencyCode2: null,
  serviceAlertsByRangeOfCurrencyCode1: null,
  serviceAlertsByRangeOfCurrencyCode2: null,
};

export interface ServiceAlertsByPaymentsProps {
  serviceAlertsByPaymentData?: ServiceAlertsModel | null;
  serviceAlertsByPaymentMethod?: ServiceAlertsModel | null;
}
export const ServiceAlertsByPaymentsDefault = {
  serviceAlertsByPaymentData: null,
  serviceAlertsByPaymentMethod: null,
};

export interface ServiceAlertsStoreModal {
  serviceAlertsStore:
    | ServiceAlertsByCountryCodeProps
    | ServiceAlertsByPaymentsProps;
}
