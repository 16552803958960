import {
  DISCOUNT_ACTIVE,
  DISCOUNT_COMING_SOON,
  DISCOUNT_EXPIRED,
  DISCOUNT_FULLY_REDEEMED,
  DISCOUNT_METHOD,
  DISCOUNT_METHOD_DISPLAY_REFERENCE,
  DISCOUNT_REDEEMED,
  PROMOTION,
} from 'constants';
import { datePicker } from './datepicker';

export const filterDiscountsAvailable = ({
  discounts,
  countryCode,
  deliveryMethod,
  currencyCode,
  isOnlyCurrency,
  sendAmount,
  receiverAmount,
  feeAmount,
  paymentMethod = '',
}) => {
  const discountsValid = discounts.filter((d) => {
    const { isActive, isComingSoon } = getDiscountStatus(d);

    if (
      (isActive || isComingSoon) &&
      (!d.country || d.country === countryCode) &&
      (!d.deliveryMethod || d.deliveryMethod === deliveryMethod) &&
      (!d.currencyCode ? true : isOnlyCurrency) &&
      (!d.currencyCode ? true : d.currencyCode === currencyCode) &&
      (Number(d.maxTxnAmount) > 0
        ? d.currencyCode
          ? receiverAmount >= (Number(d.minTxnAmount) || 0) &&
            receiverAmount <= Number(d.maxTxnAmount)
          : sendAmount >= (Number(d.minTxnAmount) || 0) &&
            sendAmount <= Number(d.maxTxnAmount)
        : true) &&
      (!d.paymentMethod || d.paymentMethod === paymentMethod) &&
      (d.discountMethod === DISCOUNT_METHOD.FLAT_FEE &&
      Number(d?.discountAmount) > 0
        ? Number(d?.discountAmount) <= Number(feeAmount)
        : true)
    ) {
      return d;
    }
  });

  return discountsValid;
};

export const getDiscountStatus = (item) => {
  const {
    redeem = 0,
    limitTxnPerCustomer = 0,
    discountMethod,
    status,
    lastRedeemAt,
    type,
  } = item || {};

  const discountMethodKey = DISCOUNT_METHOD_DISPLAY_REFERENCE[discountMethod];

  const isDiscountTypeOfPromotion = type === PROMOTION;

  // ACTIVE
  const isActive = status === DISCOUNT_ACTIVE;
  const isActiveRedeemLast = Boolean(
    redeem > 0 && limitTxnPerCustomer
      ? redeem < limitTxnPerCustomer
      : true && lastRedeemAt
  );
  const isActiveUnlimited = isActive && limitTxnPerCustomer === 0;
  const isDiscountActiveUsedHidden = Boolean(
    isActive && (limitTxnPerCustomer === 1 || isActiveUnlimited)
  );

  // COMING SOON
  const isComingSoon = status === DISCOUNT_COMING_SOON;

  // REDEEMED
  const isRedeemed = status === DISCOUNT_REDEEMED;

  // EXPIRED
  const isExpired = status === DISCOUNT_EXPIRED;

  // FULLY REDEEMED
  const isFullyRedeemed = status === DISCOUNT_FULLY_REDEEMED;

  const isDiscountCodeHidden = Boolean(
    isDiscountTypeOfPromotion || isComingSoon
  );

  return {
    status,
    discountMethodKey,
    isActive,
    isActiveRedeemLast,
    isActiveUnlimited,
    isDiscountActiveUsedHidden,
    isComingSoon,
    isDiscountCodeHidden,
    isRedeemed,
    isExpired,
    isFullyRedeemed,
  };
};

export const getDiscountUnit = (discountMethod) => {
  if (discountMethod === DISCOUNT_METHOD.PERCENT) {
    return '%';
  }
  if (discountMethod === DISCOUNT_METHOD.DOLLAR) {
    return 'AUD';
  }
  return 'AUD';
};

const chipStyle = {
  borderRadius: 10,
  backgroundColor: '#1AA758',
  paddingInline: 8,
  paddingBlock: 3,
  width: 'fit-content',
};
const chipRedeemedStyle = {
  backgroundColor: '#7A8189',
};
const chipRedeemedModalStyle = {
  backgroundColor: '#4D5054',
};
const chipComingSoonStyle = {
  backgroundColor: '#00C8FF',
};
export const chipStyles = () => ({
  [DISCOUNT_ACTIVE]: chipStyle,
  [DISCOUNT_COMING_SOON]: { ...chipStyle, ...chipComingSoonStyle },
  [DISCOUNT_REDEEMED]: { ...chipStyle, ...chipRedeemedStyle },
  [DISCOUNT_EXPIRED]: { ...chipStyle, ...chipRedeemedStyle },
  [DISCOUNT_FULLY_REDEEMED]: { ...chipStyle, ...chipRedeemedStyle },
});
export const chipModalStyles = () => ({
  [DISCOUNT_ACTIVE]: chipStyle,
  [DISCOUNT_COMING_SOON]: { ...chipStyle, ...chipComingSoonStyle },
  [DISCOUNT_REDEEMED]: { ...chipStyle, ...chipRedeemedModalStyle },
  [DISCOUNT_EXPIRED]: { ...chipStyle, ...chipRedeemedModalStyle },
  [DISCOUNT_FULLY_REDEEMED]: { ...chipStyle, ...chipRedeemedModalStyle },
});

export const getChipValue = ({ t, redeem, limitTxnPerCustomer }) => ({
  [DISCOUNT_ACTIVE]: `${redeem || 0}/${limitTxnPerCustomer || 0} ${t(
    'label_offers_status_used'
  )}`,
  [DISCOUNT_COMING_SOON]: t('label_offers_status_coming_soon'),
  [DISCOUNT_REDEEMED]: t('label_offers_status_redeemed'),
  [DISCOUNT_EXPIRED]: t('label_offers_status_expired'),
  [DISCOUNT_FULLY_REDEEMED]: t('label_offers_status_full_redeemed'),
});

export const textColor = () => ({
  [DISCOUNT_ACTIVE]: '#FFFFFF',
  [DISCOUNT_COMING_SOON]: 'var(--ds-c-grey-dark)',
  [DISCOUNT_REDEEMED]: '#B0B6BD',
  [DISCOUNT_EXPIRED]: '#B0B6BD',
  [DISCOUNT_FULLY_REDEEMED]: '#B0B6BD',
});
export const textTicketColor = () => ({
  [DISCOUNT_ACTIVE]: '#FFFFFF',
  [DISCOUNT_COMING_SOON]: '#1C3F88',
  [DISCOUNT_REDEEMED]: '#B0B6BD',
  [DISCOUNT_EXPIRED]: '#B0B6BD',
  [DISCOUNT_FULLY_REDEEMED]: '#B0B6BD',
});
export const textModalColor = () => ({
  [DISCOUNT_ACTIVE]: '#FFFFFF',
  [DISCOUNT_COMING_SOON]: '#1C3F88',
  [DISCOUNT_REDEEMED]: '#4D5054',
  [DISCOUNT_EXPIRED]: '#4D5054',
  [DISCOUNT_FULLY_REDEEMED]: '#4D5054',
});

const getValidTextByTimeZone = ({
  t,
  isAustraliaTime,
  label,
  dateStr,
  status,
}) => {
  if (isAustraliaTime) {
    if (status === DISCOUNT_COMING_SOON) {
      return `${label} 12:00 am AEST<span>*</span> - ${dateStr}<br/><p><span>*</span>${t(
        'label_australia_eastern_standard_time'
      )}</p>`;
    }

    return `${label} 11:59 pm AEST<span>*</span> - ${dateStr}<br/><p><span>*</span>${t(
      'label_australia_eastern_standard_time'
    )}</p>`;
  }

  return `${label} ${dateStr}`;
};
export const getValidText = ({
  t,
  startDate,
  endDate,
  lastRedeemAt,
  isAustraliaTime,
}) => ({
  [DISCOUNT_ACTIVE]: getValidTextByTimeZone({
    t,
    isAustraliaTime,
    label: t('label_offers_expires_on'),
    dateStr: datePicker.convertToLocalDateTimeWithTimeZone(new Date(endDate)),
  }),
  [DISCOUNT_COMING_SOON]: getValidTextByTimeZone({
    t,
    isAustraliaTime,
    label: t('label_offers_available_from'),
    dateStr: datePicker.convertToLocalDateTimeWithTimeZone(new Date(startDate)),
    status: DISCOUNT_COMING_SOON,
  }),
  [DISCOUNT_REDEEMED]: getValidTextByTimeZone({
    t,
    isAustraliaTime: false,
    label: t('label_offers_redeemed'),
    dateStr: datePicker.convertToLocalDateTimeWithTimeZone(
      new Date(lastRedeemAt)
    ),
  }),
  [DISCOUNT_EXPIRED]: getValidTextByTimeZone({
    t,
    isAustraliaTime,
    label: t('label_offers_status_expired_on'),
    dateStr: datePicker.convertToLocalDateTimeWithTimeZone(new Date(endDate)),
  }),
  [DISCOUNT_FULLY_REDEEMED]: getValidTextByTimeZone({
    t,
    isAustraliaTime,
    label: t('label_offers_status_expired_on'),
    dateStr: datePicker.convertToLocalDateTimeWithTimeZone(new Date(endDate)),
  }),
});
