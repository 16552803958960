/* eslint-disable no-unused-vars */
import AccountDetails from 'components/FormAddReceiver/components/AccountDetails/ver2';
import PersonalDetails from 'components/FormAddReceiver/components/PersonalDetails/ver2';
import ResidentialAddress from 'components/FormAddReceiver/components/ResidentialAddress/ver2';
import {
  DESKTOP_MIN_WIDTH,
  RECEIVERS_PREFIX,
  TRANSACTION_ADD_PREFIX,
} from 'constants';
import Cookies from 'helpers/cookies';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import CancelAddReceiverPopup from 'components/FormAddReceiver/components/CancelAddReceiverPopup';
import CompletedPopup from 'components/FormAddReceiver/components/CompletedPopup';
import CollapseSidebarProcessStepsLayout from 'components/common/CollapseSidebarProcessStepsLayout';
import useAddReceiver from 'hooks/form-add-receiver/useAddReceiver';
import useFormAddReceiverStore from 'hooks/form-add-receiver/useFormAddReceiverStore';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';

const AddReceiverPage = () => {
  const { t } = useLang();
  const { state } = useStore();
  const { currentReceiver } = state || {};
  const navigate = useNavigate();
  const { getNavbarsAndBodyContentOfSidebar } = useAddReceiver();

  const {
    editReceiverCurrentStep: currentStep,
    setEditReceiverCurrentStep: setCurrentStep,
    resetAddReceiverInformation,
  } = useFormAddReceiverStore();
  const { resetCurrentReceiver } = useFormAddTransactionStore();

  const [isOpenCancelPopup, setOpenCancelPopup] = useState(false);
  const [currentCountryConfig, setCurrentCountryConfig] = useState(null);

  useEffect(() => {
    return () => {
      setCurrentStep(0);
      resetAddReceiverInformation();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnToggleCancelPopup = () => {
    setOpenCancelPopup(!isOpenCancelPopup);
  };

  const handleOnClickCancelPopup = () => {
    setOpenCancelPopup(false);
    setCurrentStep(0);
    resetAddReceiverInformation();
    navigate(`${RECEIVERS_PREFIX}`);
  };

  const stepContentReference = {
    0: (
      <PersonalDetails
        countryConfig={currentCountryConfig}
        setCurrentCountryConfig={setCurrentCountryConfig}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
      />
    ),
    1: (
      <ResidentialAddress
        countryConfig={currentCountryConfig}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
      />
    ),
    2: (
      <AccountDetails
        countryConfig={currentCountryConfig}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        handleOnToggleCancelPopup={handleOnToggleCancelPopup}
      />
    ),
  };

  const handleBackToReceiverList = () => {
    resetCurrentReceiver();
    resetAddReceiverInformation();
    navigate(`${RECEIVERS_PREFIX}`);
  };

  const handleOnSendNow = async (currentReceiver) => {
    Cookies.remove(MAKE_PAYMENT_KEY);
    navigate(`${TRANSACTION_ADD_PREFIX}?rid=${currentReceiver?.receiverId}`);
  };

  return (
    <>
      {isOpenCancelPopup && (
        <CancelAddReceiverPopup
          isOpen={isOpenCancelPopup}
          onClose={handleOnToggleCancelPopup}
          onClick={handleOnClickCancelPopup}
        />
      )}

      {currentStep === 3 && currentReceiver && (
        <CompletedPopup
          isOpen={currentStep === 3 && currentReceiver}
          onClose={handleBackToReceiverList}
          onClick={() => handleOnSendNow(currentReceiver)}
        />
      )}

      {currentStep < 4 && (
        <EditReceiverStyled>
          <CollapseSidebarProcessStepsLayout
            titleSidebar={t('label_add_receiver')}
            navbars={getNavbarsAndBodyContentOfSidebar()}
            currentStep={currentStep}
            stepContentReference={stepContentReference}
            isPreview={false}
            isSuccess={currentStep === 3}
          />
        </EditReceiverStyled>
      )}
    </>
  );
};

const EditReceiverStyled = styled.div`
  margin: 70px 0px 0px 0px;
  padding: 16px;
  height: calc(100vh - 70px);
  width: 100%;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin: unset;
    margin-top: 0px;
    padding: unset;
    padding-top: 96px;
    height: fit-content;
    min-height: 100vh;
  }
`;

export default AddReceiverPage;
