import {
  fetchListPickupOffice,
  isIdSelected,
} from 'components/FormAddTransaction/func';
import {
  ADD_TRANSACTION_METHOD_PICKUP_NAME,
  DESKTOP_MIN_WIDTH,
  METHOD_PICKUP,
} from 'constants';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import useCheckServiceAlerts from 'hooks/service-alerts/useCheckServiceAlerts';
import useServiceAlertsStore from 'hooks/service-alerts/useServiceAlertsStore';
import useLang from 'hooks/useLang';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import FormInputRadioCheckbox from '../../FormInputRadioCheckbox';

const PickupPayoutInformation = ({
  countryConfig,
  information,
  setInformation,
  validation,
  setValidation,
  isDisabled,
}) => {
  const { t } = useLang();

  const [listPickupOffice, setListPickupOffice] = useState(null);

  const { setPayoutMethodItemByPICKUP } = useFormAddTransactionStore();
  const { serviceAlertsByCountryCode: serviceAlertsStore } =
    useServiceAlertsStore();
  const { checkByPayoutMethodItem } = useCheckServiceAlerts();
  const { checkServiceAlertsOfPayoutMethodItemItemDisable } =
    checkByPayoutMethodItem;

  useEffect(() => {
    if (countryConfig) {
      fetchListPickupOffice(countryConfig?.countryCode, setListPickupOffice);
    }
  }, [countryConfig]);

  const { currentPayoutItemSelectedIndex } = information;
  useEffect(() => {
    if (currentPayoutItemSelectedIndex === -1) {
      const newInformation = {
        ...information,
        [ADD_TRANSACTION_METHOD_PICKUP_NAME]: null,
        currentPayoutItemSelectedIndex: -1,
      };

      setInformation(newInformation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPayoutItemSelectedIndex]);

  const handlePickupSelected = (id, pickup) => {
    if (isDisabled) return;

    const newInformation = {
      ...information,
      [ADD_TRANSACTION_METHOD_PICKUP_NAME]: {
        dmCode: METHOD_PICKUP,
        pickupOffice: pickup,
        bank: null,
        ewallet: null,
      },
      currentPayoutItemSelectedIndex: id,
    };
    setInformation(newInformation);

    const newValidation = {
      ...validation,
      [ADD_TRANSACTION_METHOD_PICKUP_NAME]: '',
    };
    setValidation(newValidation);

    setPayoutMethodItemByPICKUP(pickup, id);
  };

  return (
    <Wrapper>
      {Boolean(listPickupOffice?.length) &&
        listPickupOffice.map((pickup) => (
          <PickupPayoutInformationStyled
            key={pickup.pickUpOfficeId}
            $isActive={
              !checkServiceAlertsOfPayoutMethodItemItemDisable({
                serviceAlertsStore,
                payoutItemId: pickup?.pickUpOfficeId,
              }) &&
              isIdSelected(
                currentPayoutItemSelectedIndex,
                pickup?.pickUpOfficeId
              )
            }
            $isDisabled={isDisabled}
          >
            <GridItem
              $isDisabled={isDisabled}
              onClick={() =>
                handlePickupSelected(pickup?.pickUpOfficeId, pickup)
              }
            >
              <FormInputRadioCheckbox
                isChecked={
                  !checkServiceAlertsOfPayoutMethodItemItemDisable({
                    serviceAlertsStore,
                    payoutItemId: pickup?.pickUpOfficeId,
                  }) &&
                  isIdSelected(
                    currentPayoutItemSelectedIndex,
                    pickup?.pickUpOfficeId
                  )
                }
              />
            </GridItem>
            <GridItem
              $isDisabled={isDisabled}
              onClick={() =>
                handlePickupSelected(pickup?.pickUpOfficeId, pickup)
              }
            >
              <Text>
                {t(
                  `add_transaction_pickup_office_name_by_id_${pickup.pickUpOfficeId}`
                )}
              </Text>
              <AddressText>
                {t('label_address')}:{' '}
                {t(
                  `add_transaction_pickup_office_address_by_id_${pickup.pickUpOfficeId}`
                )}
              </AddressText>
            </GridItem>
          </PickupPayoutInformationStyled>
        ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: var(--ds-bg-1);
  border-radius: 12px;
  padding: 8px;
`;

const PickupPayoutInformationStyled = styled.div`
  display: grid;
  grid-template-columns: 50px calc(100% - 50px);
  grid-gap: 0px;
  width: 100%;
  height: fit-content;
  padding: 10px;
  margin-bottom: 0px;
  border-bottom: 1px solid var(--ds-c-blue-remox-light);
  background-color: transparent;
  cursor: ${(props) => (props.$isDisabled ? 'not-allowed' : 'pointer')};

  ${(props) =>
    props.$isDisabled &&
    `
    background-color: #eeeeee;
    color: #7a8189;
  `};

  &:last-child {
    border-bottom: none;
  }
`;
const GridItem = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: start;
  grid-gap: 4px;

  &:nth-child(1) {
    justify-content: center;
    align-items: center;
  }

  & div {
    & div,
    input {
      cursor: ${(props) => props.$isDisabled && 'not-allowed'};
    }
  }

  & p {
    cursor: ${(props) => props.$isDisabled && 'not-allowed'};
  }
`;
const AddressText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;
  text-align: left;
  text-transform: uppercase;

  margin: 0;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const Text = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;
  text-align: left;

  margin: 0;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default PickupPayoutInformation;
