import AddReceiverIcon from 'assets/icons/add-receiver-icon.svg';
import CloseSmallIcon from 'assets/icons/close-small-icon.svg';
import AddReceiverBizIconBlueSky from 'assets/ver2/icons/add-receiver-biz-icon-blue-sky.svg';
import AddReceiverIconYellow from 'assets/ver2/icons/add-receiver-icon-yellow.svg';
import Receivers from 'components/Receivers';
import FilterReceiver from 'components/Receivers/components/FilterReceiver';
import PaginationLazy from 'components/Receivers/components/Pagination/mobile';
import SearchReceiver from 'components/Receivers/components/SearchReceiver';
import {
  convertFilteredKeyFixed,
  fetchReceiversFiltered,
  getBodyPayloadFiltered,
} from 'components/Receivers/func';
import ReceiversMobile from 'components/Receivers/mobile';
import ButtonCommon from 'components/common/ButtonCommon';
import CollapsibleSideMenu from 'components/common/CollapsibleSideMenu';
import Endpoint from 'components/common/Endpoint';
import MobileTabMenu from 'components/common/MobileTabMenu';
import NavbarFooter from 'components/common/NavbarFooter/ver2';
import {
  BUSINESS_OF_RECEIVER_TYPE,
  DESKTOP_MIN_WIDTH,
  PAGE_DEFAULT,
  RECEIVERS_ADD_SUFIX,
  RECEIVER_TABLE_SORT_ORDER_INITIAL,
  RECEIVER_TABS,
} from 'constants';
import useAuth from 'hooks/useAuth';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { isArray, isEmpty, isNull } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { arrayUtils, objectUtils, stringUtils } from 'utils';

const ENDPOINT_ID = 'ReceiversPage-endpoint';

const ReceiversPage = () => {
  const { t } = useLang();
  const { token } = useAuth();
  const navigate = useNavigate();
  const { isMobile, isTablet } = useDetectDevice();
  const { state } = useStore();
  let { refreshReceiverList } = state;

  const [receivers, setReceivers] = useState(null);
  const [currentPage, setCurrentPage] = useState(PAGE_DEFAULT);
  const [totalPages, setTotalPages] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [sortOrder, setSortOrder] = useState(RECEIVER_TABLE_SORT_ORDER_INITIAL);
  const [applyFiltered, setApplyFiltered] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isFetching, setFetching] = useState(false);
  const [isSearched, setSearched] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const { isMenuExpand } = state; // For Collapsible Menu

  // const isIntersecting = useDetectEndpointVisibleOnScreenWithId(ENDPOINT_ID);
  const isFetchReceiversInitial =
    token &&
    // isIntersecting &&
    !isArray(receivers) &&
    isEmpty(receivers) &&
    !searchValue &&
    isEmpty(applyFiltered) &&
    Object.values(sortOrder).every((value) => !value) &&
    isNull(refreshReceiverList);
  useEffect(() => {
    if (isFetchReceiversInitial) {
      setReceivers(null);
      fetchReceiversFiltered({
        token,
        page: PAGE_DEFAULT,
        setReceivers,
        setCurrentPage,
        setTotalPages,
        setTotalElements,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchReceiversInitial]);

  useEffect(() => {
    handleTabOnClick(refreshReceiverList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshReceiverList]);

  const removeFilterByFavourite = () => {
    const newApplyFiltered = {
      ...applyFiltered,
      favoriteSelected: 'all',
    };

    return newApplyFiltered;
  };
  const removeFilterByArray = (key, arr, obj, prop) => {
    const arrayFilter = arrayUtils.removeItemInArrayObject(
      arr,
      obj[prop],
      prop
    );

    if (arrayUtils.isArrayEmpty(arrayFilter)) {
      delete applyFiltered[key];

      return applyFiltered;
    }

    const newApplyFiltered = {
      ...applyFiltered,
      [key]: arrayFilter,
    };
    return newApplyFiltered;
  };
  const handleRemoveFiltered = (typeFilter, value) => {
    setFetching(true);

    const removeTypeFilteredReference = {
      countriesSelected: (key, array, country) => {
        return removeFilterByArray(key, array, country, 'code');
      },
      favoriteSelected: () => {
        return removeFilterByFavourite();
      },
    };

    const newApplyFiltered = removeTypeFilteredReference[typeFilter](
      typeFilter,
      applyFiltered[typeFilter],
      value
    );
    setApplyFiltered(newApplyFiltered);

    let bodyPayload = getBodyPayloadFiltered(newApplyFiltered);

    bodyPayload = {
      ...bodyPayload,
      name: searchValue,
      ...(currentTab === 1 && { gender: BUSINESS_OF_RECEIVER_TYPE }),
    };

    setReceivers(null);
    fetchReceiversFiltered({
      token,
      body: bodyPayload,
      page: PAGE_DEFAULT,
      setReceivers,
      setCurrentPage,
      setTotalPages,
      setTotalElements,
      setFetching,
    });
  };

  const renderFilterByArray = (key, arr) => {
    return arr.map((item, index) => (
      <Pill key={`${key}-${index}`}>
        {item.name}{' '}
        <img
          src={CloseSmallIcon}
          width={12}
          height={12}
          alt=""
          onClick={() => handleRemoveFiltered(key, item)}
        />
      </Pill>
    ));
  };
  const renderFilterByFavourite = (key) => {
    return (
      <Pill key={key}>
        {`Favourite: ${stringUtils.capitalize(applyFiltered.favoriteSelected)}`}{' '}
        <img
          src={CloseSmallIcon}
          width={12}
          height={12}
          alt=""
          onClick={() => handleRemoveFiltered(key)}
        />
      </Pill>
    );
  };
  const renderTypeFilteredReference = {
    countriesSelected: (key, array) => {
      return renderFilterByArray(key, array);
    },
    favoriteSelected: (key) => {
      if (applyFiltered[key] === 'all') return null;

      return renderFilterByFavourite(key);
    },
  };
  const renderSearchFilterSection = () => {
    const isShowFilteredPills = !objectUtils.isObjectEmpty(applyFiltered);
    const isShowSearchFilterAction =
      (Array.isArray(receivers) &&
        !arrayUtils.isArrayEmpty(receivers) &&
        searchValue === '' &&
        totalElements > 0) ||
      searchValue !== '' ||
      isShowFilteredPills;

    if (isMobile || isTablet) {
      return (
        <>
          <TitleTabsWrap>
            <TabWrap>
              {RECEIVER_TABS.map((tab, index) => (
                <ButtonCommon
                  key={tab.label}
                  value={t(tab.label)}
                  background="var(--ds-c-white)"
                  color={
                    currentTab === index
                      ? 'var(--ds-c-sky-dark)'
                      : 'var(--ds-c-grey-dark)'
                  }
                  styles={{
                    fontWeight: 500,
                    paddingInline: '16px',
                    height: '24px',
                    width: '50%',
                    float: 'left',
                    textTransform: 'uppercase',
                    borderRadius: '0px',
                    borderBottom:
                      currentTab === index
                        ? '1px solid var(--ds-c-sky-dark)'
                        : 'none',
                  }}
                  onClick={() => handleTabOnClick(index)}
                />
              ))}
            </TabWrap>
          </TitleTabsWrap>
          {isShowSearchFilterAction && (
            <>
              <SearchReceiver
                applyFiltered={applyFiltered}
                receivers={receivers}
                setReceivers={setReceivers}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                setTotalPages={setTotalPages}
                setCurrentPage={setCurrentPage}
                setSortOrder={setSortOrder}
                setTotalElements={setTotalElements}
                currentTab={currentTab}
                setFetching={setFetching}
                setSearched={setSearched}
              />
              <FilterReceiver
                applyFiltered={applyFiltered}
                searchValue={searchValue}
                setApplyFiltered={setApplyFiltered}
                setReceivers={setReceivers}
                setTotalPages={setTotalPages}
                setCurrentPage={setCurrentPage}
                setSortOrder={setSortOrder}
                setTotalElements={setTotalElements}
                currentTab={currentTab}
                setFetching={setFetching}
              />
              {Boolean(receivers?.length) && (
                <PaginationLazy
                  applyFiltered={applyFiltered}
                  searchValue={searchValue}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  receivers={receivers}
                  setReceivers={setReceivers}
                  currentTab={currentTab}
                  isSearched={isSearched}
                />
              )}
            </>
          )}
          {isShowFilteredPills && (
            <FilteredWrap>
              {Object.keys(convertFilteredKeyFixed(applyFiltered)).map(
                (key) => {
                  return renderTypeFilteredReference[key](
                    key,
                    applyFiltered[key]
                  );
                }
              )}
            </FilteredWrap>
          )}
        </>
      );
    }

    return (
      <>
        {isShowSearchFilterAction && (
          <>
            <SearchReceiver
              applyFiltered={applyFiltered}
              receivers={receivers}
              setReceivers={setReceivers}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setTotalPages={setTotalPages}
              setCurrentPage={setCurrentPage}
              setSortOrder={setSortOrder}
              setTotalElements={setTotalElements}
              currentTab={currentTab}
              setFetching={setFetching}
              setSearched={setSearched}
            />
            <FilterReceiver
              applyFiltered={applyFiltered}
              searchValue={searchValue}
              setApplyFiltered={setApplyFiltered}
              setReceivers={setReceivers}
              setTotalPages={setTotalPages}
              setCurrentPage={setCurrentPage}
              setSortOrder={setSortOrder}
              setTotalElements={setTotalElements}
              currentTab={currentTab}
              setFetching={setFetching}
            />
            {Boolean(receivers?.length) && (
              <ButtonCommon
                value={t('button_add_new_receiver')}
                color="var(--c-primary)"
                background="var(--bg-primary)"
                isFill={true}
                styles={{
                  paddingInline: 0,
                }}
                buttonIconStyles={{
                  float: 'right',
                }}
                iconSrc={AddReceiverIcon}
                onClick={() => navigate(`${RECEIVERS_ADD_SUFIX}`)}
              />
            )}
          </>
        )}
        {isShowFilteredPills && (
          <FilteredWrap>
            {Object.keys(convertFilteredKeyFixed(applyFiltered)).map((key) => {
              return renderTypeFilteredReference[key](key, applyFiltered[key]);
            })}
          </FilteredWrap>
        )}
      </>
    );
  };

  const handleTabOnClick = (tabIndex) => {
    setReceivers(null);

    setSortOrder(RECEIVER_TABLE_SORT_ORDER_INITIAL);
    fetchReceiversFiltered({
      token,
      body: {
        ...(tabIndex === 1 && { gender: BUSINESS_OF_RECEIVER_TYPE }),
      },
      page: PAGE_DEFAULT,
      setReceivers,
      setCurrentPage,
      setTotalPages,
      setTotalElements,
    });

    setCurrentTab(tabIndex);
  };

  if (isMobile || isTablet) {
    return (
      <ReceiversStyled>
        <MobileTabMenu />
        {renderSearchFilterSection()}
        <Endpoint id={ENDPOINT_ID} />
        <ReceiversMobile
          receivers={receivers}
          setReceivers={setReceivers}
          searchValue={searchValue}
          applyFiltered={applyFiltered}
          currentTab={currentTab}
          isSearched={isSearched}
          setSearched={setSearched}
        />
        <NavbarFooter />
      </ReceiversStyled>
    );
  }
  return (
    <>
      <LayoutContainer>
        <CollapsibleSideMenu />

        <OuterContainerStyled isMenuExpand={isMenuExpand}>
          <ReceiversStyled>
            <TitleTabsWrap>
              <Title>
                <Icon
                  src={
                    currentTab === 1
                      ? AddReceiverBizIconBlueSky
                      : AddReceiverIconYellow
                  }
                  width={24}
                  height={24}
                />
                {t('receivers_title')}
              </Title>
            </TitleTabsWrap>
            {renderSearchFilterSection()}
            <Endpoint id={ENDPOINT_ID} />
            <Receivers
              currentPage={currentPage}
              totalPages={totalPages}
              receivers={receivers}
              setReceivers={setReceivers}
              applyFiltered={applyFiltered}
              searchValue={searchValue}
              setTotalPages={setTotalPages}
              setCurrentPage={setCurrentPage}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              totalElements={totalElements}
              setTotalElements={setTotalElements}
              isFetching={isFetching}
              setFetching={setFetching}
              currentTab={currentTab}
              isSearched={isSearched}
              setSearched={setSearched}
            />
          </ReceiversStyled>
        </OuterContainerStyled>
      </LayoutContainer>
    </>
  );
};

const LayoutContainer = styled.div`
  display: flex;
  height: calc(100vh - 70px);
`;

const OuterContainerStyled = styled.div`
  flex-grow: 1;
  margin-left: ${(props) => (props.isMenuExpand ? '300px' : '98px')};
  transition: margin-left 0.3s;
  height: fit-content;
`;

const ReceiversStyled = styled.div`
  margin: 70px 0px 0px 0px;
  box-shadow: none;
  height: calc(100vh - 70px);
  border-radius: 12px;
  padding: 16px 24px;
  position: relative;
  background-color: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: fit-content;
    margin: 96px 0px 47px 0;
    padding: 24px 40px;
  }
`;
const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  text-transform: uppercase;

  color: var(--ds-c-black);
  margin-bottom: 2px;

  display: flex;
  align-items: center;
  gap: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    text-transform: capitalize;
  }
`;
const Icon = styled.img``;
const FilteredWrap = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
  gap: 4px;
  padding-top: 16px;
  margin-bottom: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: -16px;
    margin-top: 76px;
    padding-top: unset;
  }
`;
const Pill = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;

  padding: 4px 8px;
  min-width: fit-content;
  height: 24px;
  background: #1c3f88;
  border-radius: 100px;

  &:last-child {
    margin-right: 0px;
  }

  & img {
    margin-top: -2px;
    margin-left: 2px;
    cursor: pointer;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const TitleTabsWrap = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  margin-top: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 0px;
    margin-bottom: 8px;
  }
`;

const TabWrap = styled.div`
  height: 24px;
  background-color: transparent;
  border-radius: 0px;
  padding: 0px;
  width: 100%;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: 44px;
    width: fit-content;
    background-color: var(--ds-c-grey-disabled);
    border-radius: 100px;
    padding: 4px;
  }
`;

export default ReceiversPage;
