const SendOutlineIcon = ({
  color = 'white',
  size = { width: 24, height: 24 },
  styles = {},
  onClick = () => {},
}) => {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...styles }}
      onClick={onClick}
    >
      <path
        d="M21.4577 2.21885C21.8523 2.49223 22.0593 2.96479 21.9851 3.43735L19.4851 19.684C19.4265 20.0628 19.196 20.3948 18.86 20.5822C18.5241 20.7697 18.1217 20.7931 17.7663 20.6447L13.0943 18.7037L10.4185 21.5976C10.0708 21.9765 9.52396 22.1015 9.04348 21.914C8.56301 21.7265 8.2505 21.2618 8.2505 20.7463V17.4813C8.2505 17.3251 8.3091 17.1767 8.41457 17.0634L14.9615 9.92038C15.1881 9.67434 15.1803 9.29551 14.9459 9.06119C14.7115 8.82686 14.3326 8.81124 14.0865 9.03385L6.1411 16.091L2.69182 14.3648C2.27775 14.1578 2.01213 13.7438 2.00041 13.283C1.98869 12.8221 2.23088 12.3925 2.62932 12.1621L20.1296 2.16418C20.5476 1.92595 21.0632 1.94938 21.4577 2.21885Z"
        fill={color}
      />
    </svg>
  );
};

export default SendOutlineIcon;
