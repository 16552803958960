import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import {
  ADD_BANK_ACCOUNT_HOLDER_NAME,
  ADD_BANK_ACCOUNT_NUMBER_NAME,
  ADD_BANK_NAME,
  DESKTOP_MIN_WIDTH,
} from 'constants';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useInputFocus from 'hooks/useInputFocus';
import useLang from 'hooks/useLang';
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import { arrayUtils } from 'utils';

const BANKS_SEARCH_NAME = 'banks-search-field';

const CustomBankNameDropdown = ({
  config,
  banks,
  bankExisteds,
  information,
  setInformation,
  validation,
  setValidation,
  currentBankSelected,
  setCurrentBankSelected,
  checkBankAccountExisted,
  setSaveDisableInit,
  accountHolderName,
}) => {
  const { t } = useLang();

  const [searchValue, setSearchValue] = useState('');
  const [banksSearch, setBanksSearch] = useState(null);
  const [isShowDropdown, setShowDropdown] = useState(false);
  const [isChecking, setChecking] = useState(false);

  const isApiEnabled = config?.apiEnabled;

  const numberMaxLength = config?.max;
  const numberMinLength = config?.min;

  useInputFocus(BANKS_SEARCH_NAME);

  useEffect(() => {
    const accountNumber = information[ADD_BANK_ACCOUNT_NUMBER_NAME];
    const isCheckNumber =
      accountNumber?.length <= numberMaxLength &&
      accountNumber?.length >= numberMinLength &&
      !validation[ADD_BANK_ACCOUNT_NUMBER_NAME];

    if (isCheckNumber && isChecking) {
      handleNumberValid(
        accountNumber,
        ADD_BANK_ACCOUNT_NUMBER_NAME,
        validation,
        information,
        currentBankSelected?.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecking, currentBankSelected]);

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    if (!value) {
      setBanksSearch(null);

      return;
    }

    const newBankSearch = banks?.filter(
      (bank) =>
        bank?.name?.toLowerCase()?.includes(value?.toLowerCase()) && bank
    );
    setBanksSearch(newBankSearch);
  };

  /* eslint-disable-next-line no-unused-vars */
  const checkNumberValid = async (
    value,
    name,
    validation,
    /* eslint-disable-next-line no-unused-vars */
    information,
    /* eslint-disable-next-line no-unused-vars */
    bankId
  ) => {
    setValidation(validation);
  };
  const { debounce: handleNumberValid } = useDebounceCallback(
    (value, name, validation, information, bankId) => {
      checkNumberValid(value, name, validation, information, bankId);
    },
    0
  );

  const { debounce: handleAllowCheckAccountNumber } = useDebounceCallback(
    (validation) => {
      if (validation[ADD_BANK_ACCOUNT_NUMBER_NAME]) {
        setChecking(false);
      } else {
        setChecking(true);
      }
    },
    0
  );

  const handleBankSelected = async (bank) => {
    setCurrentBankSelected(bank);

    setSearchValue('');

    setBanksSearch(null);

    setShowDropdown(false);

    if (isApiEnabled) {
      setChecking(false);
    }

    const newInformation = {
      ...information,
      [ADD_BANK_NAME]: bank.name,
      ...(accountHolderName && {
        [ADD_BANK_ACCOUNT_HOLDER_NAME]: accountHolderName,
      }),
    };
    setInformation(newInformation);

    let newValidation = {
      ...validation,
      [ADD_BANK_ACCOUNT_NUMBER_NAME]: '',
    };

    newValidation = checkBankAccountExisted(
      bankExisteds,
      newInformation,
      newValidation
    );

    if (newValidation[ADD_BANK_ACCOUNT_NUMBER_NAME].includes('exist')) {
      setValidation(newValidation);

      return;
    }

    if (
      newInformation[ADD_BANK_ACCOUNT_NUMBER_NAME] &&
      !newValidation[ADD_BANK_ACCOUNT_NUMBER_NAME]
    ) {
      handleAllowCheckAccountNumber(newValidation);
    }

    setInformation(newInformation);

    setValidation(newValidation);

    setSaveDisableInit && setSaveDisableInit(false);
  };

  const handleDropdowOnClick = (e, cb) => {
    e.preventDefault();

    setShowDropdown(!isShowDropdown);

    cb(e);
  };

  const handleDropdownOnToggle = () => {
    setSearchValue('');
    setBanksSearch(null);

    setShowDropdown(!isShowDropdown);
  };

  const renderDropdownItems = (data) => {
    if (arrayUtils.isArrayEmpty(data)) {
      return (
        <DropdownItem
          eventKey={0}
          $isReceiversEmpty={arrayUtils.isArrayEmpty(data)}
        >
          <NoItemText>{t('form_text_no_result')}</NoItemText>
        </DropdownItem>
      );
    }

    return (
      <>
        {data?.map((item, index) => (
          <DropdownItem
            key={item.id}
            eventKey={index}
            onClick={() => handleBankSelected(item)}
          >
            {item.name}
          </DropdownItem>
        ))}
      </>
    );
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <ButtonToggle
      href="/"
      ref={ref}
      name="bank-dropdown-button-toggle"
      onClick={(e) => handleDropdowOnClick(e, onClick)}
      $isPlaceholder={!currentBankSelected?.name}
    >
      {children}
      <ArrowDownIcon
        color="var(--ds-c-grey-dark)"
        size={{ width: 14, height: 10 }}
        styles={{
          transform: isShowDropdown && 'rotate(180deg)',
        }}
      />
    </ButtonToggle>
  ));

  return (
    <BankDropdownStyled>
      <Label>
        {t('label_bank_name')}
        <span>*</span>
      </Label>
      <DropdownStyled
        onToggle={handleDropdownOnToggle}
        $isInvalid={validation[ADD_BANK_NAME]}
      >
        <Dropdown.Toggle as={CustomToggle}>
          {currentBankSelected?.name || t('form_placeholder_please_select')}
        </Dropdown.Toggle>

        <DropdownMenuWrap
          $transformY={
            document.getElementsByName('bank-dropdown-button-toggle')[0]
              ?.offsetHeight
          }
        >
          <SearchInput
            name={BANKS_SEARCH_NAME}
            value={searchValue || ''}
            placeholder={t('form_placeholder_type_to_search')}
            onChange={handleSearch}
          />
          <DropdownMenu>
            {renderDropdownItems(banksSearch || banks)}
          </DropdownMenu>
        </DropdownMenuWrap>
      </DropdownStyled>
      {validation[ADD_BANK_NAME] && (
        <FormControlFeedback>
          {t(validation[ADD_BANK_NAME])}
        </FormControlFeedback>
      )}
    </BankDropdownStyled>
  );
};

const BankDropdownStyled = styled.div`
  margin-bottom: 32px;
`;
const DropdownStyled = styled(Dropdown)`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: fit-content;
  min-height: 44px;
  background: var(--ds-c-white);
  border: ${(props) =>
    props.$isInvalid ? '1px solid var(--ds-c-red)' : '1px solid #eef2f5'};
  border-radius: 12px;
`;
const DropdownMenuWrap = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  width: 100%;
  min-height: 44px;
  height: fit-content;
  background: transparent;
  box-shadow: var(--ds-bs-4);
  position: absolute;
  inset: 0px auto auto 0px !important;
  transform: ${(props) =>
    `translate(0px, ${props.$transformY + 2}px) !important`};

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    transform: ${(props) =>
      `translate(0px, ${props.$transformY + 4}px) !important`};
  }
`;
const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  max-height: 160px;
  border-top: 1px solid #eef2f5;
  border-radius: 0 0 12px 12px;
  width: 100%;
  overflow: auto;
  background: var(--ds-c-white);
  margin-top: -1px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12) !important;
  inset: 0px auto auto 0px !important;
  transform: translate(0px, 44px) !important;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ds-c-grey-hover);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;
const DropdownItem = styled(Dropdown.Item)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  padding: 10px;
  text-transform: capitalize;
  min-height: 40px;
  height: fit-content;
  white-space: break-spaces;
  background: var(--ds-c-white);

  a:first-child {
    border-radius: 0.375rem 0.375rem 0 0;
  }

  &:hover {
    background: ${(props) =>
      props.$isReceiversEmpty ? 'var(--ds-c-white)' : 'var(--ds-c-grey-light)'};
    cursor: ${(props) => (props.$isReceiversEmpty ? 'default' : 'pointer')};
  }

  &:active {
    color: var(--ds-c-grey-dark);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const SearchInput = styled.input`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  border: none;
  border-radius: 12px 12px 0 0;
  background: var(--ds-c-white);
  width: 100%;
  min-height: 44px;
  height: fit-content;
  padding: 10px;

  &:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: initial;
    color: var(--ds-c-grey-hover);
  }
`;
const NoItemText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #b0b6bd;
  text-transform: initial;

  margin: 0px;
  padding: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ButtonToggle = styled.a`
  font-family: var(--ff-primary);
  line-height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: ${(props) =>
    props.$isPlaceholder ? '#b0b6bd' : 'var(--ds-c-grey-dark)'};
  text-decoration: none;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 8px 10px;
  padding-right: 32px;
  width: 100%;
  min-height: 44px;
  height: fit-content;
  flex-wrap: wrap;
  gap: 4px;

  &:hover {
    background: transparent;
    border: none;
    color: ${(props) =>
      props.$isPlaceholder ? '#b0b6bd' : 'var(--ds-c-grey-dark-hover)'};
  }

  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  & svg {
    position: absolute;
    right: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ds-c-grey-hover);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const Label = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  margin-bottom: 8px;

  & span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const FormControlFeedback = styled.span`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default CustomBankNameDropdown;
