import ButtonCommon from 'components/common/ButtonCommon';
import {
  DESKTOP_MIN_WIDTH,
  MOBILE_MIN_WIDTH,
  TABLET_MAX_WIDTH,
} from 'constants';
import { isFunction } from 'lodash';
import { useDetectDevice } from 'hooks/useDetectDevice';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const FxPaymentInstructionPopup = ({
  isOpen,
  imageSrc,
  imagesStyles = {},
  text,
  content,
  alignContent = 'left' || 'center' || 'right',
  buttonLabel1,
  buttonLabel2,
  onClose,
  onClick,
  isBackdropOverlap,
  zIndexBackdropOverlap,
  zIndexModal = 0,
  isClickBackdropToClose = false,
}) => {
  const isShowCloseButton = buttonLabel1 && isFunction(onClose);
  const { isMobile, isTablet } = useDetectDevice();

  const handleOnClickBackdropToClose = () => {
    if (isClickBackdropToClose) {
      onClose();
    }
  };

  return (
    <>
      <style>
        {zIndexModal &&
          `
          @media screen and (max-width: ${TABLET_MAX_WIDTH - 1}px) {
            .modal {
              z-index: ${zIndexModal || '1101'};
            }
          }
        `}
        {isBackdropOverlap
          ? `
          .alert-modal-backdrop {
            z-index: ${zIndexBackdropOverlap || '1100'};
            background: #212121;
            opacity: 0.25!important;
            display: initial!important;
          }
        `
          : `
        .alert-modal-backdrop {
          background: #212121;
          opacity: 0.25!important;
          display: initial!important;
        }
      `}
      </style>
      <ModalStyled
        show={isOpen}
        onHide={handleOnClickBackdropToClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="alert-modal-backdrop"
        centered
      >
        <ModalBody>
          {imageSrc && (
            <ImageWrap>
              <img
                src={imageSrc}
                alt=""
                width={50}
                height={50}
                style={imagesStyles}
              />
            </ImageWrap>
          )}
          {text && <Text dangerouslySetInnerHTML={{ __html: text }} />}
          {content && (
            <SubText
              $alignContent={alignContent}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </ModalBody>
        <ModalFooter
          style={{
            justifyContent: !buttonLabel1 && 'flex-end',
          }}
        >
          {isShowCloseButton && (
            <ButtonCommon
              value={buttonLabel1}
              onClick={onClose}
              styles={{
                margin: '0px',
                width: '50%',
              }}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
            />
          )}
          <ButtonCommon
            value={buttonLabel2}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: (isMobile || isTablet) ? '100%' : '100px',
            }}
            onClick={onClick}
            isUseKeyDown
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
    & .modal-dialog {
      position: absolute;
      margin: 0;
      top: 0px;
      height: 100vh;
      width: 100%;
      max-width: 100%;
      align-items: end;
      transition: ease-out 0.3s !important;
      transform: none;

      & .modal-content {
        padding: 0px 16px;
        border-radius: 0px;
        box-shadow: none;
        background: var(--ds-c-white);
        width: 100%;
        height: fit-content;
        margin-inline: auto;
        border: none;
        border-radius: 8px 8px 0 0;
      }
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    & .modal-dialog {
      transform: none;
      & .modal-content {
        padding: 16px;
        border-radius: 12px;
        box-shadow: var(--ds-bs-4);
        background: var(--ds-c-white);
        width: 452px;
        height: fit-content;
        margin-inline: auto;
        border: none;
        border-radius: 8px;
      }
    }
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  text-align: left;
  margin-bottom: 16px;
  padding-top: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 24px;
    padding-top: unset;
  }
`;
const ImageWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const Text = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: var(--ds-c-blue);
  margin: 0;
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: ${(props) => props.$alignContent};
  white-space: break-spaces;

  color: var(--ds-c-grey-dark);
  margin: 0;

  & a {
    text-decoration: none;
    color: var(--ds-c-blue);
  }

  & b {
    display: inline-block;
    margin-bottom: 8px;
  }

  & span {
    display: inline-block;
    margin-top: 8px;
    color: var(--ds-c-blue);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: center;
  margin-inline: -16px;
  padding-inline: 16px;
  padding-block: 16px;
  border-top: 1px solid var(--ds-c-grey-disabled);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding-block: unset;
    padding-inline: unset;
    margin-inline: unset;
    border-top: none;
  }
`;

export default FxPaymentInstructionPopup;
