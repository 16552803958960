import AddTxnStep1Icon from 'components/common/Icons/ver2/form-add-transaction/AddTxnStep1Icon';
import AddTxnStep2Icon from 'components/common/Icons/ver2/form-add-transaction/AddTxnStep2Icon';
import AddTxnStep3Icon from 'components/common/Icons/ver2/form-add-transaction/AddTxnStep3Icon';
import AddTxnStep4Icon from 'components/common/Icons/ver2/form-add-transaction/AddTxnStep4Icon';
import { VOUCHER } from 'constants';
import useLang from 'hooks/useLang';

export const MAKE_PAYMENT_KEY = 'isMakePayment';
export const FX_ADD_TRANSACTION_TOTAL_NUMBER_OF_STEPS = 4;

const useAddTransaction = () => {
  const { t } = useLang();

  const getNavbarsAndBodyContentOfSidebar = () => {
    return [
      {
        Icon: AddTxnStep1Icon,
        label: t('add_transaction_title_select_receiver_payout_method'),
      },
      {
        Icon: AddTxnStep2Icon,
        label: t('add_transaction_title_remittance'),
      },
      {
        Icon: AddTxnStep3Icon,
        label: t('add_transaction_title_additional_information'),
      },
      {
        Icon: AddTxnStep4Icon,
        label: t('add_transaction_title_receiver_payment'),
      },
    ];
  };

  const renderDiscountFieldLabel = (type, code) => {
    if (type === VOUCHER)
      return (
        <>
          {t('label_voucher')}{' '}
          <span className="discount-code">{`(${code})`}</span>
        </>
      );
    return <>{t('label_promotion')}</>;
  };

  return { getNavbarsAndBodyContentOfSidebar, renderDiscountFieldLabel };
};

export default useAddTransaction;
