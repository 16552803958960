import { HEADER_AGENT_CODE_REFERENCE, HHMT_THEME } from 'constants';

const agentCode =
  HEADER_AGENT_CODE_REFERENCE[
    process.env.REACT_APP_APP_NAME || HHMT_THEME
  ].toLowerCase();

export default (axios) => ({
  // OLD API - /feol/
  lookupSellCurrency(currencyCode) {
    return axios.get(`/${agentCode}/rate/sell/${currencyCode}`);
  },
  getSellCurrencies() {
    return axios.get(`/${agentCode}/currencies/sell`);
  },
  lookupBuyCurrency(currencyCode) {
    return axios.get(`/${agentCode}/rate/buy/${currencyCode}`);
  },
  getBuyCurrencies() {
    return axios.get(`/${agentCode}/currencies/buy`);
  },
  getRate(exchangeCode, currencyCode) {
    return axios.get(`/${agentCode}/rate/${exchangeCode}/${currencyCode}`);
  },
  getPickupLocationBranches() {
    return axios.get(`/pickup-location`);
  },
});
