import {
  BACKSPACE_CODE,
  CTRL_CODE,
  DELETE_CODE,
  NEXT_ARROW_CODE,
  PREV_ARROW_CODE,
} from 'components/common/func';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { format } from 'helpers';
import useLang from 'hooks/useLang';
import { useEffect } from 'react';
import styled from 'styled-components';

const SEND_AMOUNT_FROM_NAME = 'send-amount-from-field';
const SEND_AMOUNT_TO_NAME = 'send-amount-to-field';

const FormSendAmount = ({ filtered, setFiltered }) => {
  const { t } = useLang();
  const { amountFrom, amountTo } = filtered;

  useEffect(() => {
    if (!amountFrom) {
      document.getElementsByName(SEND_AMOUNT_FROM_NAME)[0].value = '';
    } else {
      document.getElementsByName(SEND_AMOUNT_FROM_NAME)[0].value =
        format.toAmountStr(amountFrom);
    }

    if (!amountTo) {
      document.getElementsByName(SEND_AMOUNT_TO_NAME)[0].value = '';
    } else {
      document.getElementsByName(SEND_AMOUNT_TO_NAME)[0].value =
        format.toAmountStr(amountTo);
    }
  }, [amountFrom, amountTo]);

  const handleOnKeyDown = (e) => {
    const ALLOW_KEYS = [
      BACKSPACE_CODE,
      DELETE_CODE,
      NEXT_ARROW_CODE,
      PREV_ARROW_CODE,
      CTRL_CODE,
    ];

    if (ALLOW_KEYS.includes(e.which)) return;

    if (e.which < 48 || e.which > 57) {
      e.preventDefault();
    }
  };

  const handleOnChange = (e) => {
    const { value, name } = e.target;
    const amount = value ? format.toStrAmount(value) : null;

    let newFiltered = {
      ...filtered,
    };

    if (amount > 30000) return;

    document.getElementsByName(name)[0].value = amount
      ? format.toAmountStr(amount)
      : null;

    if (name === SEND_AMOUNT_FROM_NAME) {
      /* eslint-disable-next-line no-empty */
      if (amount >= amountTo) {
      }

      newFiltered = {
        ...newFiltered,
        amountFrom: amount,
      };
    }

    if (name === SEND_AMOUNT_TO_NAME) {
      /* eslint-disable-next-line no-empty */
      if (amount <= amountFrom) {
      }

      newFiltered = {
        ...newFiltered,
        amountTo: amount,
      };
    }

    setFiltered(newFiltered);
  };

  return (
    <FormSendAmountStyled>
      <Label>{t('transactions_filter_by_sending_amount')}</Label>
      <AmountInputWrap>
        <InputWrap>
          <Input
            name={SEND_AMOUNT_FROM_NAME}
            type="tel"
            value={amountFrom ? format.toAmountStr(amountFrom) : ''}
            placeholder="20.00"
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
          />
          <InputLabel>
            {t('transactions_filter_by_sending_amount_from')}
          </InputLabel>
        </InputWrap>
        <InputWrap>
          <Input
            name={SEND_AMOUNT_TO_NAME}
            type="tel"
            value={amountTo ? format.toAmountStr(amountTo) : ''}
            placeholder="30,000.00"
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
          />
          <InputLabel>
            {t('transactions_filter_by_sending_amount_to')}
          </InputLabel>
        </InputWrap>
      </AmountInputWrap>
      {parseInt(amountFrom) > parseInt(amountTo) && (
        <ValidationText>{t('form_filter_amount_invalid_1')}</ValidationText>
      )}
    </FormSendAmountStyled>
  );
};

const FormSendAmountStyled = styled.div`
  padding-bottom: 70px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding-bottom: unset;
  }
`;

const Label = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-black);

  margin-bottom: 4px;
`;

const AmountInputWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const InputWrap = styled.div`
  position: relative;
  width: 49%;
  height: 44px;
`;
const Input = styled.input`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);
  text-align: right;

  background: #ffffff;
  border: 1px solid var(--ds-c-blue-remox-light);
  border-radius: 8px;
  width: 100%;
  height: 44px;
  padding: 8px 10px;
  padding-left: 60px;

  &:focus {
    border: 1px solid var(--ds-c-grey-dark);
    outline: none;
    box-shadow: none;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-blue-disabled);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const InputLabel = styled.label`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue-disabled);
  text-transform: uppercase;

  position: absolute;
  top: 12px;
  left: 10px;
`;

const ValidationText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormSendAmount;
