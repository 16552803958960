/* eslint-disable no-unused-vars */
import useLang from 'hooks/useLang';
import usePortalModal from 'hooks/usePortalModal';
import { isEmpty } from 'lodash';
import { functionUtils } from 'utils';
import PickupLocationViewDetailsPopup from '../popups/PickupLocationViewDetailsPopup';

const PickupLocationViewDetailsModal = ({ isShow, title, actionClose }) => {
  const { t } = useLang();
  const { closePortalModalName } = usePortalModal();

  const handleOnClose = () => {
    if (functionUtils.isFunction(actionClose)) {
      actionClose();
    }
    closePortalModalName();
  };

  if (isEmpty(title)) return null;
  return (
    <PickupLocationViewDetailsPopup
      isOpen={isShow}
      pickupLocationSelected={title}
      buttonLabel2={t('button_close')}
      onClick={handleOnClose}
      zIndexModal={1057}
      isBackdropOverlap={true}
      zIndexBackdropOverlap={1056}
    />
  );
};

export default PickupLocationViewDetailsModal;
