import { RESET_BACKDROP_STORE } from 'constants';
import { NoticeIcon } from 'constants/icon';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import styled from 'styled-components';
import useStore from '../../../../hooks/useStore';
import { SET_SHOW_BACKDROP } from '../../../../store/action';

const TooltipCommonMobile = ({
  text,
  name,
  label,
  icon = NoticeIcon,
  percentValue = 0,
  isUseImage = true,
}) => {
  const { dispatch } = useStore();

  useEffect(() => {
    return () => {
      dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnClick = () => {
    const payload = {
      isShow: true,
      label,
      current: name,
      text,
      zIndex: 1056,
    };
    dispatch({ type: SET_SHOW_BACKDROP, payload });
  };

  return (
    <ButtonStyled
      onClick={handleOnClick}
      $isHidden={!isUseImage && !percentValue}
    >
      {isUseImage ? (
        <Image src={icon} />
      ) : (
        <PercentValue>-{percentValue}</PercentValue>
      )}
    </ButtonStyled>
  );
};

const ButtonStyled = styled(Button)`
  padding: 0;
  margin: 0;
  margin-left: 4px;
  margin-bottom: 0px;
  color: var(--ds-c-white) !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  transition: unset !important;
  display: ${(props) => (props.$isHidden ? 'none' : 'inline-flex')};

  &:hover,
  &:focus,
  &:focus-visible,
  &:active {
    color: var(--ds-c-white) !important;
    background-color: transparent !important;
  }

  &:disabled {
    color: var(--ds-c-white) !important;
    background-color: transparent !important;
    cursor: not-allowed;
  }
`;

const PercentValue = styled.span`
  font-family: var(--ff-primary);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 1px;
  color: #fff;

  display: flex;
  align-items: center;
  padding: 3px 4px;
  border-radius: 10px !important;
  background: var(--ds-c-green-default);
  width: fit-content;
  margin: 0px !important;
`;

export default TooltipCommonMobile;
