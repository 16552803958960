import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH, LOGIN_PREFIX } from 'constants';
import useLang from 'hooks/useLang';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

const FormResetPasswordSuccess = () => {
  const { t } = useLang();
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate(`${LOGIN_PREFIX}`);
  };

  return (
    <FormResetPasswordWrap>
      <TitleH3>{t('reset_password_text_set_password_successfully')}</TitleH3>
      <Paragraph style={{ marginBottom: '24px' }}>
        {t('reset_password_text_back_to_account')}
      </Paragraph>
      <ConfirmSubmit>
        <ButtonCommon
          value={t('button_continue')}
          onClick={handleContinue}
          styles={{
            margin: '0px',
            width: '200px',
          }}
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
        />
      </ConfirmSubmit>
    </FormResetPasswordWrap>
  );
};

const FormResetPasswordWrap = styled.div`
  padding: 40px;
  background: var(--ds-c-white);
  box-shadow: 0px 4px 15px rgb(0 0 0 / 12%);
  border-radius: 40px;

  @media screen and (min-width: 1280px) {
    width: 437px;
    height: fit-content;
  }

  @media screen and (min-width: 1440px) {
    width: 517px;
    height: fit-content;
  }
`;

const TitleH3 = styled.h3`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ConfirmSubmit = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export default FormResetPasswordSuccess;
