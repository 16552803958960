import { formatter } from 'helpers';

export const getNumberMaskStr = (numberUnmaskStr, numberFormatParse) => {
  return formatter.mask(numberUnmaskStr, numberFormatParse);
};

export const getNumberUnmaskStr = (numberMaskStr, numberFormatParse) => {
  return formatter.unMask(numberMaskStr, numberFormatParse);
};

export const isIdSelected = (current, selected) => {
  return selected && current.id === selected.id;
};

export const getBanksDelete = (rootBanks, currentBanks) => {
  return rootBanks?.filter((r) => !currentBanks.find((c) => r.id === c.id));
};
export const getBanksAdd = (rootBanks, currentBanks) => {
  return rootBanks?.filter((r) => !currentBanks?.find((c) => c.id === r.id));
};
export const getBanksUpdate = (rootBanks, currentBanks) => {
  return rootBanks?.filter((r) =>
    currentBanks?.find(
      (c) =>
        c.id === r.id &&
        (c.accountNumber !== r.accountNumber ||
          c.bankName !== r.bankName ||
          c.accountName?.toUpperCase() !== r.accountName?.toUpperCase() ||
          c.swiftCode?.toUpperCase() !== r.swiftCode?.toUpperCase() ||
          c.branchCode?.toUpperCase() !== r.branchCode?.toUpperCase())
    )
  );
};

export const getEwalletsDelete = (rootEwallets, currentEwallets) => {
  return rootEwallets?.filter(
    (r) => !currentEwallets.find((c) => r.id === c.id)
  );
};
export const getEwalletsAdd = (rootEwallets, currentEwallets) => {
  return rootEwallets?.filter(
    (r) => !currentEwallets?.find((c) => c.id === r.id)
  );
};
export const checkEwalletExpiryDateChange = (cExpiryDate, rExpiryDate) => {
  if (!rExpiryDate && cExpiryDate) return true;

  if (rExpiryDate && !cExpiryDate) return true;

  if (rExpiryDate && cExpiryDate) {
    return (
      cExpiryDate?.[0] !== rExpiryDate?.[0] ||
      cExpiryDate?.[1] !== rExpiryDate?.[1] ||
      cExpiryDate?.[2] !== rExpiryDate?.[2]
    );
  }

  return false;
};
export const getEwalletsUpdate = (rootEwallets, currentEwallets) => {
  return rootEwallets?.filter((r) =>
    currentEwallets?.find(
      (c) =>
        c.id === r.id &&
        (c.ewalletNumber !== r.ewalletNumber ||
          c.ewalletProviderName !== r.ewalletProviderName ||
          checkEwalletExpiryDateChange(c?.expiryDate, r?.expiryDate))
    )
  );
};

const getBankAccountVerifyBy = (countryCode, isVerified) => {
  if (['VNM'].includes(countryCode)) {
    return isVerified ? 'NAPAS' : 'CITAD';
  }

  if (['IDN', 'MYS'].includes(countryCode)) {
    return isVerified ? 'Tranglo' : '';
  }

  return isVerified ? 'NAPAS' : '';
};
export const getBankAddVerification = ({
  bankValidate,
  bankAdd,
  countryCode,
  receiverFullName,
}) => {
  const { holderName, nameMatched, serviceStatus, valid } = bankValidate || {};
  const isServiceActive = serviceStatus === 'active';

  const isBankAddValidMatch = nameMatched && isServiceActive && valid;
  if (isBankAddValidMatch) {
    bankAdd = {
      ...bankAdd,
      isVerified: true,
      verificationNote: `CREATE RECEIVER BANK ACCOUNT, HOLDER = VALIDATEHOLDER = ${holderName}`,
      verificationType: 'RECEIVER BANK',
      verifyBy: getBankAccountVerifyBy(countryCode, true),
    };
  }

  const isBankAddValidNOTMatch = !nameMatched && isServiceActive && valid;
  if (isBankAddValidNOTMatch) {
    bankAdd = {
      ...bankAdd,
      isVerified: true,
      verificationNote: `CREATE RECEIVER BANK ACCOUNT, HOLDER = ${receiverFullName}, VALIDATEDHOLDER = ${holderName}`,
      verificationType: 'RECEIVER BANK',
      verifyBy: getBankAccountVerifyBy(countryCode, true),
    };
  }

  const isBankAddInvalid = !nameMatched && isServiceActive && !valid;
  if (isBankAddInvalid) {
    bankAdd = {
      ...bankAdd,
      isVerified: false,
      verificationNote: `CREATE RECEIVER BANK ACCOUNT, INVALID BANK ACCOUNT DETAILS`,
      verificationType: 'RECEIVER BANK',
      verifyBy: getBankAccountVerifyBy(countryCode, false),
    };
  }

  const isServiceNotAvailable = !nameMatched && !isServiceActive && !valid;
  if (isServiceNotAvailable) {
    bankAdd = {
      ...bankAdd,
      isVerified: false,
      verificationNote: '',
      verificationType: '',
      verifyBy: '',
    };
  }

  return bankAdd;
};
export const getBankUpdateVerification = ({
  bankValidate,
  bankUpdate,
  countryCode,
  receiverFullName,
}) => {
  const { holderName, nameMatched, serviceStatus, valid } = bankValidate || {};
  const isServiceActive = serviceStatus === 'active';

  const isBankUpdateValidMatch = nameMatched && isServiceActive && valid;
  if (isBankUpdateValidMatch) {
    bankUpdate = {
      ...bankUpdate,
      isVerified: true,
      verificationNote: `UPDATE RECEIVER BANK ACCOUNT, HOLDER = VALIDATEHOLDER = ${holderName}`,
      verificationType: 'RECEIVER BANK',
      verifyBy: getBankAccountVerifyBy(countryCode, true),
    };
  }

  const isBankUpdateValidNOTMatch = !nameMatched && isServiceActive && valid;
  if (isBankUpdateValidNOTMatch) {
    bankUpdate = {
      ...bankUpdate,
      isVerified: true,
      verificationNote: `UPDATE RECEIVER BANK ACCOUNT, HOLDER = ${receiverFullName}, VALIDATEHOLDER = ${holderName}`,
      verificationType: 'RECEIVER BANK',
      verifyBy: getBankAccountVerifyBy(countryCode, true),
    };
  }

  const isBankUpdateInvalid = !nameMatched && isServiceActive && !valid;
  if (isBankUpdateInvalid) {
    bankUpdate = {
      ...bankUpdate,
      isVerified: false,
      verificationNote: `UPDATE RECEIVER BANK ACCOUNT, INVALID BANK ACCOUNT DETAILS`,
      verificationType: 'RECEIVER BANK',
      verifyBy: getBankAccountVerifyBy(countryCode, false),
    };
  }

  const isServiceNotAvailable = !nameMatched && !isServiceActive && !valid;
  if (isServiceNotAvailable) {
    bankUpdate = {
      ...bankUpdate,
      isVerified: false,
      verificationNote: '',
      verificationType: '',
      verifyBy: '',
    };
  }

  return bankUpdate;
};
export const getBankSelectVerification = ({
  bankValidate,
  bankSelect,
  countryCode,
  receiverFullName,
}) => {
  const { holderName, nameMatched, serviceStatus, valid } = bankValidate || {};
  const isServiceActive = serviceStatus === 'active';

  const isBankSelectValidMatch = nameMatched && isServiceActive && valid;
  if (isBankSelectValidMatch) {
    bankSelect = {
      ...bankSelect,
      isVerified: true,
      verificationNote: `SELECT RECEIVER BANK ACCOUNT, HOLDER = VALIDATEHOLDER = ${holderName}`,
      verificationType: 'RECEIVER BANK',
      verifyBy: getBankAccountVerifyBy(countryCode, true),
    };
  }

  const isBankSelectValidNOTMatch = !nameMatched && isServiceActive && valid;
  if (isBankSelectValidNOTMatch) {
    bankSelect = {
      ...bankSelect,
      isVerified: true,
      verificationNote: `SELECT RECEIVER BANK ACCOUNT, HOLDER = ${receiverFullName}, VALIDATEDHOLDER = ${holderName}`,
      verificationType: 'RECEIVER BANK',
      verifyBy: getBankAccountVerifyBy(countryCode, true),
    };
  }

  const isBankSelectInvalid = !nameMatched && isServiceActive && !valid;
  if (isBankSelectInvalid) {
    bankSelect = {
      ...bankSelect,
      isVerified: false,
      verificationNote: `SELECT RECEIVER BANK ACCOUNT, INVALID BANK ACCOUNT DETAILS`,
      verificationType: 'RECEIVER BANK',
      verifyBy: getBankAccountVerifyBy(countryCode, false),
    };
  }

  const isServiceNotAvailable = !nameMatched && !isServiceActive && !valid;
  if (isServiceNotAvailable) {
    bankSelect = {
      ...bankSelect,
      isVerified: false,
      verificationNote: '',
      verificationType: '',
      verifyBy: '',
    };
  }

  return bankSelect;
};

export const getFullNameReceiver = ({
  isReceiverOfIndividualType,
  isReceiverOfBusinessType,
  lastName,
  firstName,
  countryCode,
}) => {
  if (isReceiverOfIndividualType) {
    if (countryCode === 'VNM') {
      if (lastName && firstName)
        return `${lastName} ${firstName}`?.toUpperCase();
    }

    if (countryCode !== 'VNM') {
      if (lastName && firstName)
        return `${firstName} ${lastName}`?.toUpperCase();
    }

    if (lastName && !firstName) return `${lastName}`?.toUpperCase();

    if (!lastName && firstName) return `${firstName}`?.toUpperCase();

    return '';
  }

  if (isReceiverOfBusinessType) {
    return firstName?.toUpperCase() || '';
  }

  return '';
};
