import ArrowIcon from 'assets/ver2/icons/arrow-down-icon.svg';
import DoubleArrowIcon from 'assets/ver2/icons/double-arrow-icon.svg';
import { TRANSACTION_FILTER_SENDING_DATE_FROM_NAME } from 'components/Transactions/components/FilterTransaction/components/CustomFilterSendingDateFromDatePicker';
import { TRANSACTION_FILTER_SENDING_DATE_TO_NAME } from 'components/Transactions/components/FilterTransaction/components/CustomFilterSendingDateToDatePicker';
import {
  DESKTOP_MIN_WIDTH,
  EN_LANG,
  REGISTER_DOB_NAME,
  TABLET_MIN_WIDTH,
  VI_LANG,
} from 'constants';
import { addDays } from 'date-fns';
import { enAU, vi } from 'date-fns/locale';
import useLang from 'hooks/useLang';
import { useEffect, useRef } from 'react';
import { Calendar } from 'react-date-range';
import styled from 'styled-components';

const CustomDatepickerSelected = ({
  isOpen,
  date,
  minDate,
  maxDate,
  styles = {},
  onChange,
  onClose,
}) => {
  const { locate } = useLang();

  const ref = useRef(null);

  const LOCALE_REFERENCE = {
    [EN_LANG]: enAU,
    [VI_LANG]: vi,
  };

  useEffect(() => {
    if (isOpen) {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        if (
          event.target.getAttribute('name') ===
          TRANSACTION_FILTER_SENDING_DATE_FROM_NAME
        )
          return;

        if (
          event.target.getAttribute('name') ===
          TRANSACTION_FILTER_SENDING_DATE_TO_NAME
        )
          return;

        if (event.target.getAttribute('name') === REGISTER_DOB_NAME) return;

        onClose();
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }
  }, [ref, onClose, isOpen]);

  return (
    <>
      {isOpen && (
        <CalendarStyled style={{ ...styles }} ref={ref}>
          <Calendar
            locale={LOCALE_REFERENCE[locate]}
            onChange={onChange}
            date={date ? new Date(date) : new Date()}
            maxDate={
              maxDate ? addDays(new Date(maxDate), 0) : addDays(new Date(), 0)
            }
            minDate={minDate && addDays(new Date(minDate), 0)}
            dateFormat="MM/DD/YYYY"
            weekdayDisplayFormat="EEEEEE"
          />
        </CalendarStyled>
      )}
    </>
  );
};

const CalendarStyled = styled.div`
  width: 100%;
  max-width: 100%;
  height: fit-content;
  display: flex;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  margin-top: 2px;
  padding: 16px 18px;
  margin-inline: auto;
  background: var(--ds-c-white);

  @media screen and (min-width: ${TABLET_MIN_WIDTH}px) {
    max-width: 326px;
  }

  & .rdrCalendarWrapper {
    width: 100%;
    height: 100%;
    background: var(--ds-c-white);
  }

  & .rdrMonthAndYearWrapper {
    padding: 15px 6px;
    height: 42px;
    background: var(--ds-c-white);

    button {
      background: url(${DoubleArrowIcon}) no-repeat;
      background-position: center;
      height: 17px;
      width: 17px;
      margin: 0;

      & i {
        display: none;
      }
    }

    button.rdrNextButton {
      transform: rotate(180deg);
    }

    & .rdrMonthAndYearPickers {
      height: 17px;
      width: 100%;
      display: flex;
      justify-content: start;

      span {
        margin: 0px;

        select {
          font-family: var(--ff-primary);
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: #0e1012;
          padding-top: 0px;
          padding-bottom: 0px;
          background: url(${ArrowIcon}) no-repeat right;

          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      & .rdrMonthPicker {
        order: 1;

        select {
          padding-left: 30px;
          padding-right: 22px;
          max-width: 134px;
        }
      }

      & .rdrYearPicker {
        order: -1;

        select {
          padding-left: 38.5px;
          padding-right: 22px;

          option {
            &:hover {
              background: var(--ds-c-grey-light);
            }

            &:active {
              color: var(--ds-c-grey-dark);
            }
          }
        }
      }
    }
  }

  & .rdrMonthsVertical {
    flex-direction: row;
    background: var(--ds-c-white);

    & .rdrMonth {
      padding: 0;
      width: 100%;

      & .rdrWeekDays {
        display: flex;
        justify-content: center;
        height: 42px;
        min-width: 322px;
        margin-inline: -18px;

        span {
          font-family: var(--ff-primary);
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;

          color: var(--ds-c-blue-hyperlink-default);
          width: 42px;
          max-width: 42px;
          height: 42px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-inline: 4px;

          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      & .rdrDays {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 322px;
        margin-inline: -18px;

        button {
          width: 42px;
          height: 42px;

          span {
            top: 0;
            left: 0;
            border-radius: 50%;
            width: 42px;
            height: 42px;

            span {
              font-family: var(--ff-primary);
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 20px;
              color: var(--ds-c-black);

              display: flex;
              justify-content: center;
              align-items: center;

              @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }

          &:hover {
            span {
              background-color: var(--bg-primary);
            }
          }

          &:active,
          &:focus,
          &:target,
          &:visited,
          &:focus-visible {
            border: none;
            box-shadow: none;
            outline: none;
            background-color: var(--bg-primary);
            border-radius: 50%;
          }
        }

        button.rdrDayPassive {
          span {
            span {
              color: var(--ds-c-blue-remox-light);
              background-color: var(--ds-c-white);
            }
          }
        }

        button.rdrDayDisabled {
          background-color: var(--ds-c-white);
          span {
            span {
              color: var(--ds-c-blue-remox-light);
            }
          }
        }

        button.rdrDayToday {
          span {
            span {
              &::after {
                background: none;
                border: var(--border-secondary);
                border-radius: 50%;
                width: 42px;
                height: 42px;
                top: 0;
                left: 0;
                transform: unset;
              }
            }
          }

          span.rdrSelected {
            background-color: var(--ds-c-white) !important;
            border: var(--border-secondary) !important;
          }
        }

        button.rdrDay {
          span.rdrSelected {
            background-color: var(--bg-primary);
          }
        }

        button.rdrDayHovered {
          span {
            span {
              font-weight: 700;
            }

            &::after {
              border: none;
              box-shadow: none;
              outline: none;
              background-color: transparent;
              border-radius: 50%;
              width: 42px;
              height: 42px;
            }
          }
        }

        button.rdrDay {
          span.rdrDayEndPreview {
            border: none;
            box-shadow: none;
            outline: none;
            background-color: transparent;
            border-radius: 50%;
            width: 42px;
            height: 42px;
          }
        }

        button.rdrDayDisabled {
          span {
            span {
              color: var(--ds-c-blue-remox-light) !important;
              background-color: var(--ds-c-white) !important;
            }
          }
        }
      }
    }
  }
`;

export default CustomDatepickerSelected;
