import { backgroundReference, colorReference } from 'constants';
import React from 'react';
import styled from 'styled-components';

const AddTxnStep3Icon = ({
  status = 'disable' || 'in-progress' || 'completed',
}) => {
  return (
    <SvgStyled $backgroundColor={backgroundReference[status]}>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="10.0001" cy="10.0001" r="8.33333" fill="transparent" />
        <path
          d="M10.0001 18.3334C12.2102 18.3334 14.3298 17.4554 15.8926 15.8926C17.4554 14.3298 18.3334 12.2102 18.3334 10.0001C18.3334 7.78994 17.4554 5.67033 15.8926 4.10753C14.3298 2.54472 12.2102 1.66675 10.0001 1.66675C7.78994 1.66675 5.67033 2.54472 4.10753 4.10753C2.54472 5.67033 1.66675 7.78994 1.66675 10.0001C1.66675 12.2102 2.54472 14.3298 4.10753 15.8926C5.67033 17.4554 7.78994 18.3334 10.0001 18.3334ZM8.698 12.6042H9.47925V10.5209H8.698C8.26506 10.5209 7.91675 10.1726 7.91675 9.73967C7.91675 9.30672 8.26506 8.95842 8.698 8.95842L10.2605 8.95842C10.6934 8.95842 11.0417 9.30672 11.0417 9.73967V12.6042H11.3022C11.7351 12.6042 12.0834 12.9526 12.0834 13.3855C12.0834 13.8184 11.7351 14.1667 11.3022 14.1667H8.698C8.26506 14.1667 7.91675 13.8184 7.91675 13.3855C7.91675 12.9526 8.26506 12.6042 8.698 12.6042ZM10.0001 5.83342C10.2763 5.83342 10.5413 5.94316 10.7367 6.13851C10.932 6.33386 11.0417 6.59881 11.0417 6.87508C11.0417 7.15135 10.932 7.4163 10.7367 7.61165C10.5413 7.807 10.2763 7.91675 10.0001 7.91675C9.72381 7.91675 9.45886 7.807 9.26351 7.61165C9.06816 7.4163 8.95842 7.15135 8.95842 6.87508C8.95842 6.59881 9.06816 6.33386 9.26351 6.13851C9.45886 5.94316 9.72381 5.83342 10.0001 5.83342Z"
          fill={colorReference[status]}
        />
      </svg>
    </SvgStyled>
  );
};

const SvgStyled = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

export default AddTxnStep3Icon;
