import styled from 'styled-components';
import { arrayUtils } from 'utils';
import TransactionTableSort from './components/TransactionTableSort';

const Transactions = ({
  currentPage,
  totalPages,
  transactions,
  setTransactions,
  searchValue,
  currentSearchBy,
  applyFiltered,
  setTotalPages,
  setCurrentPage,
  sortOrder,
  setSortOrder,
  totalElements,
  setTotalElements,
  isFetching,
  setFetching,
}) => {
  return (
    <TransactionsStyled $isEmpty={arrayUtils.isArrayEmpty(transactions)}>
      <TransactionTableSort
        currentPage={currentPage}
        totalPages={totalPages}
        transactions={transactions}
        setTransactions={setTransactions}
        searchValue={searchValue}
        currentSearchBy={currentSearchBy}
        applyFiltered={applyFiltered}
        setTotalPages={setTotalPages}
        setCurrentPage={setCurrentPage}
        sortOrder={sortOrder}
        setSortOrder={setSortOrder}
        totalElements={totalElements}
        setTotalElements={setTotalElements}
        isFetching={isFetching}
        setFetching={setFetching}
      />
    </TransactionsStyled>
  );
};

const TransactionsStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: ${(props) => (props.$isEmpty ? '24px' : '16px')};
  height: ${(props) => (props.$isEmpty ? '690px' : 'fit-content')};
  align-items: ${(props) => (props.$isEmpty ? 'center' : 'start')};
`;

export default Transactions;
