export const SET_SHOW_LOADING = 'SET_SHOW_LOADING';

export const SET_SHOW_PENDING = 'SET_SHOW_PENDING';

export const SET_SHOW_SEARCHING = 'SET_SHOW_SEARCHING';

export const SET_PORTAL_MODAL = 'SET_PORTAL_MODAL';

export const SET_SHOW_BACKDROP = 'SET_SHOW_BACKDROP';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

export const SET_EXCHANGE_RATES = 'SET_EXCHANGE_RATES';

export const SET_REMITTANCE_RATES = 'SET_REMITTANCE_RATES';

export const SET_SERVICE_ALERTS_BY_COUNTRY_CODE =
  'SET_SERVICE_ALERTS_BY_COUNTRY_CODE';
export const SET_SERVICE_ALERTS_BY_PAYMENTS = 'SET_SERVICE_ALERTS_BY_PAYMENTS';

export const SET_SERVICE_ALERTS_METHOD_CHECK =
  'SET_SERVICE_ALERTS_METHOD_CHECK';

export const SET_NEW_NOTIFICATION = 'SET_NEW_NOTIFICATION';

export const SET_CURRENT_RECEIVER = 'SET_CURRENT_RECEIVER';

export const SET_CURRENT_BUSINESS_RECEIVER = 'SET_CURRENT_BUSINESS_RECEIVER';

export const SET_REGISTER_INFORMATION = 'SET_REGISTER_INFORMATION';

export const SET_OPEN_REFRESH_TOKEN_POPUP = 'SET_OPEN_REFRESH_TOKEN_POPUP';

export const SET_EDIT_RECEIVER_INFORMATION = 'SET_EDIT_RECEIVER_INFORMATION';
export const SET_EDIT_RECEIVER_CURRENT_STEP = 'SET_EDIT_RECEIVER_CURRENT_STEP';

export const SET_ADD_RECEIVER_INFORMATION = 'SET_ADD_RECEIVER_INFORMATION';

export const SET_ADD_TRANSACTION_INFORMATION =
  'SET_ADD_TRANSACTION_INFORMATION';
export const SET_ADD_TRANSACTION_CURRENT_STEP =
  'SET_ADD_TRANSACTION_CURRENT_STEP';

export const SET_TRANSACTION_DETAILS = 'SET_TRANSACTION_DETAILS';

export const SET_TOKEN_TRANSACTION = 'SET_TOKEN_TRANSACTION';

export const SET_CONFIG_OCCUPATION = 'SET_CONFIG_OCCUPATION';

export const SET_CONFIG_RELATIONSHIP = 'SET_CONFIG_RELATIONSHIP';

export const SET_CONFIG_RELATIONSHIP_OF_BUSINESS =
  'SET_CONFIG_RELATIONSHIP_OF_BUSINESS';

export const SET_CONFIG_PURPOSE = 'SET_CONFIG_PURPOSE';

export const SET_CONFIG_PURPOSE_OF_BUSINES = 'SET_CONFIG_PURPOSE_OF_BUSINES';

export const SET_CONFIG_SOURCES_OF_WEALTH = 'SET_CONFIG_SOURCES_OF_WEALTH';

export const SET_EVENT_AVAILABLE = 'SET_EVENT_AVAILABLE';

export const SET_REFRESH_RECEIVER_LIST = 'SET_REFRESH_RECEIVER_LIST';

export const SET_REFRESH_TRANSACTION_LIST = 'SET_REFRESH_TRANSACTION_LIST';

export const SET_MENU_EXPAND = 'SET_MENU_EXPAND';

export const SET_EVENTS = 'SET_EVENTS';
