import { format } from 'helpers';
import styled from 'styled-components';
import TransactionStatus from '../../../TransactionStatus';

const TransactionItem = ({ transaction, onClick }) => {
  return (
    <TransactionItemStyled onClick={onClick}>
      <RowItem>
        <ReferenceText>{transaction.refNumber}</ReferenceText>
        <SendingAmoutText>{`AUD ${format.toAmountStr(
          transaction.amount
        )}`}</SendingAmoutText>
        <StatusText>
          <TransactionStatus transaction={transaction} />
        </StatusText>
      </RowItem>
      <RowItem style={{ gridTemplateColumns: '70% 30%' }}>
        <ReceiverText>{transaction.receiverFullname}</ReceiverText>
        <SendingDateText>{transaction.createDateStr}</SendingDateText>
      </RowItem>
    </TransactionItemStyled>
  );
};

const TransactionItemStyled = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 75px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px 10px 0 10px;
  margin-bottom: 12px;

  display: flex;
  flex-flow: row wrap;
`;

const RowItem = styled.div`
  width: 100%;
  display: grid;
  align-content: space-between;
  align-items: center;
  grid-template-columns: 32% 30% 116px;
`;

const ReferenceText = styled.p`
  font-family: var(--ff-secondary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue-hyperlink-default);

  margin: 0;
  padding: 0;
`;
const SendingAmoutText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue-dark);

  margin: 0;
  padding: 0;
`;
const SendingDateText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: var(--ds-c-blue-hover);
  text-align: right;
  height: 20px;

  margin: 0;
  padding: 0;
  padding-block: 2px;
`;
const StatusText = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: end;
`;
const ReceiverText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);
  text-align: left;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin: 0;
  padding: 0 10px 0 0;
`;

export default TransactionItem;
