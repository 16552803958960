import {
  DESKTOP_MIN_WIDTH,
  HHMT_EMAIL,
  HHMT_PHONE,
  REMOX_EMAIL,
  REMOX_PHONE,
} from 'constants';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';
import { stringUtils } from 'utils';

const AccountBlockedInform = () => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();

  return (
    <>
      <AccountLockedInformStyled
        dangerouslySetInnerHTML={{
          __html: stringUtils.replaceKeyword(t('login_account_locked'), [
            { key: 'email', value: isHHMTTheme ? HHMT_EMAIL : REMOX_EMAIL },
            { key: 'phone', value: isHHMTTheme ? HHMT_PHONE : REMOX_PHONE },
          ]),
        }}
      />
    </>
  );
};

const AccountLockedInformStyled = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: var(--ds-c-grey-dark);
  padding: 10px 16px;
  width: 100%;
  height: fit-content;
  background: var(--bg-info);
  border-radius: 12px;
  margin-bottom: -16px;

  a {
    font-weight: 700;
    color: var(--c-info);
    text-decoration: none;

    &:hover,
    &:active {
      color: var(--c-info-hover) !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    margin-bottom: 24px;
  }
`;

export default AccountBlockedInform;
