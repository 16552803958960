import {
  DESKTOP_COLLAPSE_MIN_WIDTH,
  DESKTOP_MIN_WIDTH,
  TABLET_MAX_WIDTH,
  TABLET_MIN_WIDTH,
} from 'constants';
import React from 'react';
import styled from 'styled-components';
import FXRateItem from '../../FXRateItem/mobile';
import NoResult from 'components/Dashboard/components/FXRatesSection/components/NoResult';
import { objectUtils } from 'utils';

const FXRatesSection = ({ FXRatesData }) => {

  const isShowNoResult = objectUtils.isObjectEmpty(FXRatesData);

  if (isShowNoResult) {
    return <NoResult />;
  }

  if (!FXRatesData) {
    return null;
  }
  return (
    <Wrapper>
      <RatesWrap>
        {FXRatesData.map((rate) => (
          <>
            <FXRateItem
              key={rate.currency}
              currency={rate.currencyCode}
              rate={parseFloat(rate.rate)}
              rateData={rate}
            />
          </>
        ))}
      </RatesWrap>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  height: fit-content;
  position: relative;

  font-family: var(--ff-primary);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: var(--ds-c-black);

  @media screen and (min-width: ${TABLET_MIN_WIDTH}px) and (max-width: ${TABLET_MAX_WIDTH -
    1}px) {
  }
  @media screen and (min-width: ${TABLET_MAX_WIDTH}px) and (max-width: ${DESKTOP_COLLAPSE_MIN_WIDTH -
    1}px) {
    max-width: 850px;
  }

  @media screen and (min-width: ${DESKTOP_COLLAPSE_MIN_WIDTH}px) {
    max-width: 1010px;
  }
`;

const RatesWrap = styled.div`
  // display: flex;
  gap: 12px;
  margin-bottom: 80px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    gap: 21px;
  }
`;

export default FXRatesSection;
