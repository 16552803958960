import ArrowLeft from 'assets/ver2/icons/arrow-left-blue.svg';
import ArrowRight from 'assets/ver2/icons/arrow-right-blue.svg';
import { DESKTOP_COLLAPSE_MIN_WIDTH, TABLET_MAX_WIDTH } from 'constants';
import useStore from 'hooks/useStore';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { FreeMode, Mousewheel, Navigation, Scrollbar } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import FXRateItem from '../FXRateItem';
import NoResult from 'components/Dashboard/components/FXRatesSection/components/NoResult';
import { objectUtils } from 'utils';

const FXRatesSection = ({ FXRatesData, searchQuery }) => {
  const { state } = useStore();
  const { isMenuExpand } = state; // For Collapsible Menu

  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const chunkedItems = chunkArray(FXRatesData, 5);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const isShowNoResult = objectUtils.isObjectEmpty(FXRatesData);

  useEffect(() => {
    if(!isShowNoResult){
      const handleSwiper = (swiper) => {
        if (swiper.params && swiper.navigation) {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }
      };
      const swiper = document.querySelector('.swiper').swiper;
      if (swiper) {
        handleSwiper(swiper);
      }
    }
  }, []);

  if (isShowNoResult) {
    return <NoResult />;
  }
  if (!FXRatesData) {
    return null;
  }

  return (
    <Wrapper isMenuExpand={isMenuExpand}>
      <div ref={prevRef} className="custom-prev">
        <img src={ArrowLeft} alt="Prev" />
      </div>
      <div ref={nextRef} className="custom-next">
        <img src={ArrowRight} alt="Next" />
      </div>
      <SwiperStyled
        direction={'horizontal'}
        slidesPerView={'auto'}
        freeMode={true}
        scrollbar={{ draggable: true }}
        mousewheel={true}
        grabCursor={true}
        // navigation={true}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        onSwiper={(swiper) => {
          if (swiper) {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }
        }}
        modules={[FreeMode, Mousewheel, Scrollbar, Navigation]}
      >
        {chunkedItems.map((chunk, index) => (
          <SwiperSlide key={index} style={{ width: 'auto' }}>
            <div>
              {chunk.map((item) => (
                <FXRateItem
                  key={item.currency}
                  currency={item.currencyCode}
                  rate={parseFloat(item.rate)}
                  rateData={item}
                />
              ))}
            </div>
          </SwiperSlide>
        ))}
      </SwiperStyled>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  height: fit-content;
  position: relative;

  font-family: var(--ff-primary);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: var(--ds-c-black);

  @media screen and (min-width: ${TABLET_MAX_WIDTH}px) and (max-width: ${DESKTOP_COLLAPSE_MIN_WIDTH -
    1}px) {
    max-width: ${(props) => (props.isMenuExpand ? '910px;' : '1102px;')};
  }

  @media screen and (min-width: ${DESKTOP_COLLAPSE_MIN_WIDTH}px) {
    max-width: ${(props) => (props.isMenuExpand ? '1060px;' : '1262px;')};
  }

  & .custom-prev {
    position: absolute;
    top: calc(50% - 14px);
    left: -32px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  & .custom-next {
    position: absolute;
    top: calc(50% - 14px);
    right: -32px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  & .custom-prev.swiper-button-disabled,
  & .custom-next.swiper-button-disabled {
    opacity: 0;
  }
`;

const SwiperStyled = styled(Swiper)`
  height: fit-content;
  padding-bottom: 12px;
  padding-right: 240px;

  & .swiper-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    & .swiper-slide {
      height: fit-content;
      cursor: pointer;
    }
  }
`;

export default FXRatesSection;
