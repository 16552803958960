import { useEffect } from 'react';
import { useDetectDevice } from './useDetectDevice';

const usePreventPushBottom = () => {
  const { isMobile, isTablet } = useDetectDevice();

  useEffect(() => {
    if (isMobile || isTablet) {
      if ('virtualKeyboard' in navigator) {
        navigator.virtualKeyboard.overlaysContent = true;
      }
    }
  }, [isMobile, isTablet]);
};

export default usePreventPushBottom;
