import { DESKTOP_MIN_WIDTH } from 'constants';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    :root {
        // css variable solid colors
        --ds-c-black: #000000;
        --ds-c-black-disabled: #D4DCE5;

        --ds-c-white: #ffffff;
        --ds-c-white-default: #FCFCFC;

        --ds-c-grey: #4D5054;
        --ds-c-grey-default: #62748A;
        --ds-c-grey-dark: #0E1012;
        --ds-c-grey-light: #D2D9E7;
        --ds-c-grey-neutral: #7A8189;
        --ds-c-grey-hover: #B0B6BD;
        --ds-c-grey-disabled: #EEF2F5;

        --ds-c-red: #E72631;
        --ds-c-red-light: #FAD4D6;
        --ds-c-red-hover: #F17D83;
        --ds-c-red-disabled: #F5A8AD;

        --ds-c-yellow: #FCB519;
        --ds-c-yellow-neutral: #FDC447;
        --ds-c-yellow-light: #FEF0D1;
        --ds-c-yellow-hover: #FDD375;
        --ds-c-yellow-disabled: #FEE1A3;

        --ds-c-green: #00E492;
        --ds-c-green-dark: #00C27C;
        --ds-c-green-hover: #49FFBE;
        --ds-c-green-disabled: #97FFDA;
        --ds-c-green-default: #1AA758;

        --ds-c-sky: #00C8FF;
        --ds-c-sky-hover: #A0EBFF;
        --ds-c-sky-disabled: #CDF4FF;

        --ds-c-violet: #7906A1;
        --ds-c-violet-hover: #AF6AC7;
        --ds-c-violet-disabled: #C99CDA;

        --ds-c-blue-neutral: #0AB5FD;
        --ds-c-blue-neutral-hover: #5CCFFF;
        --ds-c-blue: #1C3F88;
        --ds-c-blue-dark: #062F6D;
        --ds-c-blue-hover: #778CB8;
        --ds-c-blue-disabled: #A4B2CF;
        --ds-c-blue-light: #CEF4FF;

        --ds-c-green: #04D288;

        // NEW COLOR SCHEME
        --ds-c-bg-light-grey: #FCFCFC;
        --ds-c-blue-hyperlink-default: #002FF1;
        --ds-c-blue-hyperlink-hover: #002FF190;
        --ds-c-blue-remox-hover: #4965A0;
        --ds-c-blue-remox-light: #DCE3F1;
        --ds-c-sky-dark: #2286A1;
        --ds-c-red-alert: #EC515A;

        // css variable background color
        --ds-bg-1: #DCE3F14D;
        --ds-bg-2: #DCE3F1;
        --ds-bg-3: #4965A0;

        // css variable filter the icon color from solid colors
        --ds-f-c-grey-dark: invert(3%) sepia(4%) saturate(2828%) hue-rotate(169deg) brightness(98%) contrast(94%);
        --ds-f-c-grey-hover: invert(33%) sepia(10%) saturate(221%) hue-rotate(174deg) brightness(86%) contrast(88%);
        --ds-f-c-yellow: invert(77%) sepia(49%) saturate(1151%) hue-rotate(342deg) brightness(98%) contrast(102%);
        --ds-f-c-yellow-hover: invert(85%) sepia(28%) saturate(790%) hue-rotate(340deg) brightness(102%) contrast(98%);
        --ds-f-c-blue: invert(19%) sepia(79%) saturate(1724%) hue-rotate(201deg) brightness(80%) contrast(86%);
        --ds-f-c-blue-sky: invert(65%) sepia(90%) saturate(2925%) hue-rotate(154deg) brightness(102%) contrast(104%);
        --ds-f-c-blue-dark: invert(11%) sepia(98%) saturate(1457%) hue-rotate(199deg) brightness(93%) contrast(108%);
        --ds-f-c-blue-hover: invert(68%) sepia(3%) saturate(4109%) hue-rotate(182deg) brightness(79%) contrast(98%);
        --ds-f-c-blue-hyperlink: invert(11%) sepia(93%) saturate(7489%) hue-rotate(235deg) brightness(91%) contrast(110%);
        --ds-f-c-green: invert(59%) sepia(45%) saturate(621%) hue-rotate(94deg) brightness(94%) contrast(100%);
        --ds-f-discount-inactive: invert(20%) grayscale(65%) brightness(150%);

        // css variable gradients
        --ds-g-grey: linear-gradient(137.29deg, #4D5054 3.47%, #D2D3D4 107.8%);
        --ds-g-red: linear-gradient(137.29deg, #E72631 3.47%, #FAD4D6 107.8%);
        --ds-g-yellow: linear-gradient(137.29deg, #FCB519 3.47%, #FEF0D1 107.8%);
        --ds-g-green: linear-gradient(137.29deg, #09C649 3.47%, #CEF4DB 107.8%);
        --ds-g-sky: linear-gradient(137.29deg, #86DAF1 3.47%, #E7F8FC 107.8%);
        --ds-g-violet: linear-gradient(137.29deg, #7906A1 3.47%, #C99CDA 107.8%);
        --ds-g-blue: linear-gradient(137.29deg, #1C3F88 3.47%, #A4B2CF 107.8%);

        // css variable box-shadows
        --ds-bs-1: 0px 1px 6px rgba(0, 0, 0, 0.1);
        --ds-bs-2: 0px 2px 8px rgba(0, 0, 0, 0.1);
        --ds-bs-3: 0px 4px 8px rgba(0, 0, 0, 0.1);
        --ds-bs-4: 0px 4px 15px rgba(0, 0, 0, 0.12);
    }

    * {
        padding: 0;
        margin: 0;
        list-style: none;
        box-sizing: border-box;
    }

    body {
        background-color: var(--ds-c-white);

        .tooltip-inner {
            padding: 6px 10px;
            color: red;
            font-family: var(--ff-primary);
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            text-align: left;
            color: var(--ds-c-white);
        }
    }

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
        body {
            .tooltip-inner {
                font-size: 14px;
                line-height: 17px;
            }

            // .modal-backdrop {
            //     opacity: 0.25!important;
            // }

            img, svg, a {
                -webkit-user-drag: none;
            }
        }
    }

    body::-webkit-scrollbar, .modal::-webkit-scrollbar, .offcanvas-body::-webkit-scrollbar {
        width: 5px;
        height: 5px !important;
    }
    body::-webkit-scrollbar-thumb, .modal::-webkit-scrollbar-thumb, .offcanvas-body::-webkit-scrollbar-thumb {
        background: var(--ds-c-grey-hover);
        border-radius: 10px;
    }
    body::-webkit-scrollbar-track, .modal::-webkit-scrollbar-track, .offcanvas-bod::-webkit-scrollbar-track {
        background: transparent;
    }

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
        // .modal-backdrop {
        //     opacity: 0.25!important;
        // }
    }

    // input::placeholder {
    //     color: var(--ds-c-grey-neutral);
    //     text-transform: uppercase!important;
    // }

`;

export default GlobalStyle;
