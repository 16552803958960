import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import styled from 'styled-components';

import AddIcon from 'assets/icons/add-blue-icon.svg';
import AddCircleOutlineIcon from 'assets/icons/add-receiver-outline-icon.svg';
import ArrowDownSVG from 'assets/ver2/icons/arrow-down-icon.svg';
import DeleteIcon from 'assets/icons/delete-outline-icon.svg';
import EditIcon from 'assets/icons/edit-outline-icon.svg';
import {
  getNumberUnmaskStr,
  isIdSelected,
} from 'components/FormEditReceiver/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  ADD_EWALLET_EXPIRY_NAME,
  ADD_EWALLET_ID_NAME,
  ADD_EWALLET_NAME,
  ADD_EWALLET_NUMBER_NAME,
  DESKTOP_MIN_WIDTH,
  MOBILE_MIN_WIDTH,
} from 'constants';
import { datePicker, formatter } from 'helpers';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { arrayUtils } from 'utils';

import DeleteEwalletPopup from '../DeleteEwalletPopup';
import FormUpdateEwalletAccount from '../FormAddNewEwalletAccount';

const CustomEwalletsAccordion = ({
  ewallets,
  countryConfig,
  information,
  setInformation,
  ewalletInformation,
  setEwalletInformation,
  ewalletValidation,
  setEwalletValidation,
  handleOnToggleFormAddNewEwallet,
}) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();

  const config = useGetConfig({ countryConfig });
  const { ewalletNumber, ewalletExpire } = config;

  const numberFormat = formatter.formatParse(ewalletNumber?.format);

  const [currentEwalletSelected, setCurrentEwalletSelected] = useState(null);
  const [isOpenDeleteEwalletPopup, setOpenDeleteEwalletPopup] = useState(false);
  const [isOpenUpdateEwalletPopup, setOpenUpdateEwalletPopup] = useState(false);

  const handleAccordionItemSelected = (ewallet) => {
    if (isIdSelected(ewallet, currentEwalletSelected)) {
      setCurrentEwalletSelected(null);

      return;
    }

    setCurrentEwalletSelected(ewallet);
  };

  const handleOnToggleDeleteEwalletPopup = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setOpenDeleteEwalletPopup(!isOpenDeleteEwalletPopup);
  };

  const handleOnClickDeleteEwalletPopup = async () => {
    const newEwallets = arrayUtils.removeItemInArrayObject(
      information?.ewallets,
      currentEwalletSelected?.id,
      'id'
    );

    const newInformation = {
      ...information,
      ewallets: newEwallets,
    };
    setInformation(newInformation);

    setOpenDeleteEwalletPopup(false);
  };

  const handleOnToggleFormUpdateEwallet = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!isOpenUpdateEwalletPopup) {
      const currentEwallet =
        information?.ewallets?.find((i) =>
          isIdSelected(i, currentEwalletSelected)
        ) || currentEwalletSelected;

      const newEwalletInformation = {
        id: currentEwallet?.id,
        [ADD_EWALLET_ID_NAME]: currentEwallet?.ewalletId,
        [ADD_EWALLET_NAME]: currentEwallet?.ewalletProviderName,
        [ADD_EWALLET_NUMBER_NAME]: currentEwallet?.ewalletNumber,
        [ADD_EWALLET_EXPIRY_NAME]: currentEwallet?.expiryDate,
      };

      setEwalletInformation(newEwalletInformation);
    }

    setOpenUpdateEwalletPopup(!isOpenUpdateEwalletPopup);
  };

  const handleUpdateEwalletAccount = async (ewalletUpdate) => {
    const ewallet = {
      id: ewalletUpdate?.id,
      ewalletId: ewalletUpdate?.ewalletId,
      ewalletProviderName: ewalletUpdate?.ewalletProviderName,
      ewalletNumber: ewalletUpdate?.ewalletNumber,
      expiryDate: ewalletUpdate?.expiryDate,
    };

    const pos = information.ewallets.findIndex((i) => {
      return isIdSelected(i, ewallet);
    });
    const newEwallets =
      pos !== -1
        ? arrayUtils.replaceItemInArray(information.ewallets, ewallet, pos)
        : information.ewallets;

    const newInformation = {
      ...information,
      ewallets: newEwallets,
    };
    setInformation(newInformation);

    handleOnToggleFormUpdateEwallet();
  };

  return (
    <>
      {isOpenDeleteEwalletPopup && currentEwalletSelected && (
        <DeleteEwalletPopup
          isOpen={isOpenDeleteEwalletPopup && currentEwalletSelected}
          ewalletNumber={currentEwalletSelected?.ewalletNumber}
          onClose={() => setOpenDeleteEwalletPopup(false)}
          onClick={handleOnClickDeleteEwalletPopup}
        />
      )}
      {isOpenUpdateEwalletPopup && (
        <FormUpdateEwalletAccount
          isOpen={isOpenUpdateEwalletPopup}
          isEdit={isOpenUpdateEwalletPopup}
          countryConfig={countryConfig}
          information={information}
          setInformation={setInformation}
          ewalletInformation={ewalletInformation}
          setEwalletInformation={setEwalletInformation}
          ewalletValidation={ewalletValidation}
          setEwalletValidation={setEwalletValidation}
          onClose={handleOnToggleFormUpdateEwallet}
          onClick={handleUpdateEwalletAccount}
        />
      )}

      <FieldWrap>
        {/* <ButtonCommon
          value={t('button_add_new_ewallet_account')}
          color="var(--c-tertiary)"
          background="var(--ds-c-white)"
          isFill={false}
          styles={{
            paddingInline: 0,
            height: '44px',
          }}
          buttonIconStyles={{
            height: '32px',
            flexDirection: 'row-reverse',
            gap: '6px',
          }}
          borderNameVariable="--border-secondary"
          iconSrc={isHHMTTheme ? AddHHMTIcon : AddRemoxIcon}
          onClick={handleOnToggleFormAddNewEwallet}
        /> */}
        {ewallets?.length ? (
          <>
            <AccordionStyled>
              {ewallets.map((ewallet, index) => (
                <AccordionItem
                  key={ewallet.id}
                  eventKey={index}
                  onClick={() => handleAccordionItemSelected(ewallet)}
                >
                  <AccordionHeader>
                    <AccordionHeaderText>
                      {ewallet?.ewalletProviderName}
                    </AccordionHeaderText>
                    <AccordionHeaderBody>
                      {isIdSelected(ewallet, currentEwalletSelected) && (
                        <>
                          <Divider />
                          <FieldBodyContentIcon>
                            <FieldBodyContent>
                              {ewalletNumber?.visible && (
                                <FieldBodyWrap>
                                  <p>
                                    {t('label_ewallet_id')}:{' '}
                                    {ewallet?.ewalletNumber
                                      ? formatter.mask(
                                          getNumberUnmaskStr(
                                            ewallet?.ewalletNumber,
                                            numberFormat
                                          ),
                                          numberFormat
                                        )
                                      : ''}
                                  </p>
                                </FieldBodyWrap>
                              )}
                              {ewalletExpire?.visible && (
                                <FieldBodyWrap>
                                  <p>
                                    {t('label_expiry_date')}:{' '}
                                    {ewallet?.expiryDate &&
                                      datePicker.dateUTCToDateStr(
                                        datePicker.dateArrToDateUTC(
                                          ewallet?.expiryDate
                                        )
                                      )}
                                  </p>
                                </FieldBodyWrap>
                              )}
                            </FieldBodyContent>
                            <FieldBodyIcon>
                              <EditText onClick={handleOnToggleFormUpdateEwallet}>
                                <EditIconStyled
                                  src={EditIcon}
                                  width={24}
                                  height={24}
                                  alt=""
                                />
                              </EditText>
                              <DeleteText
                                onClick={handleOnToggleDeleteEwalletPopup}
                              >
                                <DeleteIconStyled
                                  src={DeleteIcon}
                                  width={24}
                                  height={24}
                                  alt=""
                                />
                              </DeleteText>
                            </FieldBodyIcon>
                          </FieldBodyContentIcon>
                        </>
                      )}
                    </AccordionHeaderBody>
                  </AccordionHeader>
                </AccordionItem>
              ))}
            </AccordionStyled>
            <ButtonCommon
              value={t('button_add_new_ewallet_account')}
              color="var(--ds-c-blue-hyperlink-default)"
              background="var(--ds-c-white)"
              styles={{
                paddingInline: 0,
                height: '20px',
                borderRadius: 0,
                fontWeight: 500,
              }}
              buttonIconStyles={{
                height: '20px',
                flexDirection: 'row-reverse',
                gap: '6px',
                marginTop: '16px',
              }}
              iconSrc={AddCircleOutlineIcon}
              iconStyles={{
                filter:
                  'invert(12%) sepia(87%) saturate(6995%) hue-rotate(234deg) brightness(90%) contrast(113%)',
              }}
              onClick={handleOnToggleFormAddNewEwallet}
            />
          </>
        ) : (
          <>
            <NoEwallet>
              {t('label_no_ewallet')}
              <ButtonCommon
                value={t('button_add_new_ewallet_account')}
                styles={{
                  margin: '0px',
                }}
                buttonIconStyles={{
                  gap: '0px',
                }}
                color="var(--ds-c-blue)"
                background="var(--ds-c-white)"
                borderNameVariable={'--border-filter'}
                isFill={false}
                iconSrc={AddIcon}
                onClick={handleOnToggleFormAddNewEwallet}
              />
            </NoEwallet>
          </>
        )}
      </FieldWrap>
    </>
  );
};

const FieldWrap = styled.div`
  width: 100%;
  height: fit-content;
  margin-top: -16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 16px;
  }
`;

const AccordionStyled = styled(Accordion)`
  width: 437px;
  margin-top: 16px;

  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) {
    width: 100%;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 24px;
  }
`;
const AccordionItem = styled(Accordion.Item)`
  border: none;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }
`;
const AccordionHeader = styled(Accordion.Header)`
  height: fit-content;
  padding: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  & button {
    padding: 0;
    background-color: var(--ds-c-white) !important;
    box-shadow: none !important;
    display: block;

    &:focus,
    &:hover {
      z-index: 1;
    }

    &::after,
    &:not(.collapsed)::after {
      background-image: url(${ArrowDownSVG});
      background-position: center;
      background-size: 24px 24px;

      position: absolute;
      top: 10px;
      right: 0px;
    }
  }
`;
const FieldBodyContentIcon = styled.div`
  display: flex;
  align-items: center;
`;
const FieldBodyContent = styled.div`
  width: 100%;
`;
const FieldBodyIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 56px;
`;
const AccordionHeaderText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  padding: 0;
  padding-right: 20px;
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const AccordionHeaderBody = styled.div``;
const FieldBodyWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: fit-content;
  margin-top: 8px;

  &:first-child {
    margin-top: 0px;
  }

  & p {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--ds-c-grey-dark);

    cursor: text;
    margin: 0;
    padding: 0;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const NoEwallet = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  width: 437px;
  height: 164px;
  background: var(--ds-c-white);
  border-radius: 12px;
  border: 1px dashed var(--ds-c-grey-hover);
  margin-top: 16px;

  @media screen and (min-width: ${MOBILE_MIN_WIDTH}px) {
    width: 100%;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 24px;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #eef2f5;
  margin: 16px 0;
`;

const EditText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  margin: 0;
  margin-inline: 0px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
  user-select: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const EditIconStyled = styled.img`
  &:hover {
    filter: var(--ds-f-c-grey-hover);
  }
`;

const DeleteText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #e72631;

  margin: 0;
  margin-inline: 0px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
  user-select: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const DeleteIconStyled = styled.img`
  &:hover {
    filter: var(--ds-f-c-grey-hover);
  }
`;

export default CustomEwalletsAccordion;
