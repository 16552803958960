import SwitchCheckedRemoxIcon from 'assets/ver2/icons/switch/switch-checked-icon.svg';
import SwitchboxRemoxIcon from 'assets/ver2/icons/switch/switchbox-icon.svg';
import { fetchCurrentFee } from 'components/Transactions/func';
import { getItemsConfig } from 'components/common/func';
import {
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME,
  ADD_TRANSACTION_RECEIVER_GET_NAME,
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  DESKTOP_MIN_WIDTH,
} from 'constants';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const ADD_TRANSACTION_ADD_ANOTHER_RECEIVING_CURRENCY_NAME =
  'add-transaction-add-another-receiving-currency-field';

const FormInputAddAnotherReceivingCurrencySwitch = ({
  countryConfig,
  information,
  setInformation,
  validation,
  setValidation,
  receiveLimits,
  isDisabled,
  isFetching,
  setFetching,
  setInformationChangeBackup,
  setOpenDiscountImportantPopup,
}) => {
  const { t } = useLang();
  const { state } = useStore();
  const { addTransactionInformation } = state;
  const { receiverPayoutMethod, paymentMethod } =
    addTransactionInformation || {};
  const { discountApplied } = paymentMethod || {};
  const dmCode = receiverPayoutMethod[ADD_TRANSACTION_PAYOUT_METHOD_NAME];
  const countryCode = countryConfig?.countryCode;
  const sendLimitConfig =
    countryConfig &&
    getItemsConfig(
      countryConfig?.sendLimits,
      countryConfig?.countryCode,
      null,
      true,
      null
    );
  const maxSendLimit = sendLimitConfig?.max || 30000;

  const { isAddAnotherReceivingCurrency } = information || {};

  const isAllowAddCurrency =
    !isFetching &&
    // Object.values(validation).every((value) => !value) &&
    information[ADD_TRANSACTION_YOU_SEND_NAME] &&
    information[ADD_TRANSACTION_RECEIVER_GET_NAME];

  const handleFetchCurrentFee = async (
    information,
    setInformation,
    countryCode,
    dmCode
  ) => {
    const isBackupInformationChange = discountApplied;

    await fetchCurrentFee(
      information,
      setInformation,
      countryCode,
      dmCode,
      maxSendLimit,
      (newInformation) => {
        if (isBackupInformationChange) {
          setOpenDiscountImportantPopup(true);

          setInformationChangeBackup(newInformation);
        }

        setFetching(false);
      },
      isBackupInformationChange
    );
  };

  const handleOnChange = (e) => {
    const { checked } = e.target;

    let newInformation = {
      ...information,
      isAddAnotherReceivingCurrency: checked,
    };

    if (checked) {
      newInformation = {
        ...newInformation,
        currentCurrencySelectedAnother: receiveLimits.filter(
          (r) =>
            r?.currencyCode !==
            information['currentCurrencySelected']?.currencyCode
        )?.[0],
      };
    }

    if (!checked) {
      newInformation = {
        ...newInformation,
        [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: 0,
        [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: 0,
        currentCurrencySelectedAnother: null,
      };
    }

    setFetching(true);
    handleFetchCurrentFee(newInformation, setInformation, countryCode, dmCode);
  };

  const handleOnClick = () => {
    if (
      !information[ADD_TRANSACTION_YOU_SEND_NAME] ||
      !information[ADD_TRANSACTION_RECEIVER_GET_NAME]
    ) {
      setValidation({
        ...validation,
        [ADD_TRANSACTION_YOU_SEND_NAME]: 'form_required',
        [ADD_TRANSACTION_RECEIVER_GET_NAME]: 'form_required',
      });

      return;
    }

    if (!information[ADD_TRANSACTION_YOU_SEND_NAME]) {
      setValidation({
        ...validation,
        [ADD_TRANSACTION_YOU_SEND_NAME]: 'form_required',
      });

      return;
    }

    if (!information[ADD_TRANSACTION_RECEIVER_GET_NAME]) {
      setValidation({
        ...validation,
        [ADD_TRANSACTION_RECEIVER_GET_NAME]: 'form_required',
      });

      return;
    }

    if (isAddAnotherReceivingCurrency) {
      let newInformation = {
        ...information,
        isAddAnotherReceivingCurrency: false,
      };

      newInformation = {
        ...newInformation,
        [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: 0,
        [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: 0,
        currentCurrencySelectedAnother: null,
      };

      setFetching(true);
      handleFetchCurrentFee(
        newInformation,
        setInformation,
        countryCode,
        dmCode
      );

      setValidation({
        ...validation,
        [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: '',
        [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: '',
      });
    }
  };

  return (
    <FormGroupStyled>
      <FormSwitch
        type="switch"
        label={t('button_add_another_currency')}
        name={ADD_TRANSACTION_ADD_ANOTHER_RECEIVING_CURRENCY_NAME}
        checked={isAddAnotherReceivingCurrency}
        onClick={
          isAllowAddCurrency ? (e) => handleOnChange(e) : () => handleOnClick()
        }
        $checkboxSrc={SwitchboxRemoxIcon}
        $checkedSrc={SwitchCheckedRemoxIcon}
        $isChecked={isAddAnotherReceivingCurrency}
        disabled={isDisabled || !isAllowAddCurrency}
        readOnly
      />
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 24px;
  position: relative;
`;

const FormSwitch = styled(Form.Check)`
  margin: 0;
  margin-right: 50px;

  label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    margin-left: 4px;
    user-select: none;
    height: 32px;
    display: flex;
    align-items: center;
    padding-block: 4px;
    padding-bottom: 0px;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  input:checked[type='checkbox'] {
    background-image: ${(props) => `url(${props.$checkedSrc}) !important`};
    background-position: 0.1px -2px !important;
    background-size: cover;
  }

  &.form-switch .form-check-input {
    margin-left: ${(props) => (props.$isChecked ? '-40px' : '-44px')};
    margin-right: 4px;
  }

  &.form-check-input:disabled ~ .form-check-label,
  .form-check-input[disabled] ~ .form-check-label {
    opacity: 0.7;
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: ${(props) => `url(${props.$checkboxSrc}) !important`};
    background-position: -0px -2px !important;
    background-size: cover;
    width: 50px !important;
    height: 30px !important;
    cursor: pointer !important;

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }
  }
`;

export default FormInputAddAnotherReceivingCurrencySwitch;
