/* eslint-disable no-unused-vars */
import { renderDiscountImportantPopup } from 'components/Discount/func';
import ButtonCommon from 'components/common/ButtonCommon';
import BodySectionHeader from 'components/fx/components/BodySectionHeader';
import { DESKTOP_MIN_WIDTH, SCREEN_ID_EXCHANGE_STEP_3 } from 'constants';
import {
  FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  RESET_FX_ADD_TRANSACTION_PAYMENT_METHOD,
} from 'constants/fx';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useEvent from 'hooks/useEvent';
import useLang from 'hooks/useLang';
import useScrollIntoViewDelay from 'hooks/useScrollIntoViewDelay';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import CalculatorForm from '../CalculatorForm';
import PaymentMethodsGrid from '../PaymentMethodsGrid';

const initialFormField = RESET_FX_ADD_TRANSACTION_PAYMENT_METHOD;

const SCREEN_ID = SCREEN_ID_EXCHANGE_STEP_3;

const PaymentMethod = ({
  handleOnToggleCancelPopup,
  handleOnViewPickupLocation,
}) => {
  const { t } = useLang();
  const { isDesktop } = useDetectDevice();

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [informationChangeBackup, setInformationChangeBackup] = useState(null);
  const [isOpenDiscountImportantPopup, setOpenDiscountImportantPopup] =
    useState(false);

  const { getTitleBodySection } = useFxAddTransaction();
  const {
    addTransactionCurrentStep: currentStep,
    setAddTransactionCurrentStep,
    paymentMethodName,
    setPaymentMethod,
  } = useFxFormAddTransactionStore();

  const isNextDisabled = isEmpty(paymentMethodName);

  // scroll to calculator form
  const validRef = useRef(null);
  useScrollIntoViewDelay(validRef, validRef, []);

  const handleOnClickDiscountImportantPopup = () => {
    if (informationChangeBackup) {
      setInformation(informationChangeBackup);

      const newPaymentMethod = {
        ...paymentStore,
        ...informationChangeBackup,
        discountsAvailable: [],
        discountApplied: null,
      };
      setPaymentMethod(newPaymentMethod);

      setInformationChangeBackup(null);

      setValidation(initialFormField);
    }
  };

  const handleOnCloseDiscountImportantPopup = () => {
    setInformationChangeBackup(null);
  };

  const handleBackStep = () => {
    setAddTransactionCurrentStep(currentStep - 1);

    const isCachePaymentMethod = paymentMethodName !== '';
    if (isCachePaymentMethod) {
      // setPaymentMethod(information);
    }
  };
  const handleNextStep = () => {
    const isPaymentMethodSelectedInvalid = isEmpty(paymentMethodName);
    const isAllowSubmit = !isPaymentMethodSelectedInvalid;

    if (isPaymentMethodSelectedInvalid) {
      const newValidation = {
        ...validation,
        [FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME]:
          'fx_add_transaction_choose_payment_method',
      };
      setValidation(newValidation);
      return;
    }

    if (isAllowSubmit) {
      setAddTransactionCurrentStep(currentStep + 1);
    }
  };
  const renderCalculatorFormMobile = () => {
    if (isDesktop) return null;
    return (
      <CalculatorForm
        ref={validRef}
        isCalculatorFormAccordion={true}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    );
  };
  const renderActionsButton = () => {
    return (
      <NextWrap>
        {renderCalculatorFormMobile()}
        <ActionWrapper>
          <ButtonCommonWrap>
            <ButtonCommon
              value={t('button_back')}
              onClick={handleBackStep}
              styles={{
                margin: '0px',
                width: !isDesktop && '100%',
              }}
              color="var(--ds-c-blue)"
              background="var(--ds-c-white)"
              borderNameVariable={isDesktop && '--border-filter'}
              isFill={false}
            />
          </ButtonCommonWrap>
          <ButtonCommon
            value={t('button_confirm')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: isDesktop ? '150px' : '50%',
            }}
            onClick={handleNextStep}
            isDisabled={false}
            isUseKeyDown={!isNextDisabled}
          />
        </ActionWrapper>
      </NextWrap>
    );
  };

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [],
    modalStyles: {},
    modalOptions: {},
  });
  useEffect(() => {
    if (!isShowEvent) return;
    showEventModal();
  }, [isShowEvent]);

  return (
    <>
      {isOpenDiscountImportantPopup &&
        renderDiscountImportantPopup({
          t,
          isOpen: isOpenDiscountImportantPopup,
          setOpen: setOpenDiscountImportantPopup,
          paymentMethod: paymentMethodName,
          onCloseExt: handleOnCloseDiscountImportantPopup,
          onClickExt: handleOnClickDiscountImportantPopup,
          contentReplace:
            isUpdateTran &&
            t('discount_check_invalid_desc_3', {
              discountPaymentMethod: discountDetails?.paymentMethod,
              paymentMethodSelected:
                informationChangeBackup?.[
                  FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME
                ],
            }),
        })}

      <BodySectionHeader
        titleBodySection={getTitleBodySection()}
        titleSection={t('add_transaction_title_payment_method')}
        CalculatorComponent={isDesktop && <CalculatorForm />}
        onToggleCancelPopup={handleOnToggleCancelPopup}
        onViewPickupLocation={handleOnViewPickupLocation}
      />
      <PaymentMethodStyled>
        <PaymentMethodsGrid
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          informationChangeBackup={informationChangeBackup}
          setInformationChangeBackup={setInformationChangeBackup}
          setOpenDiscountImportantPopup={setOpenDiscountImportantPopup}
          isSelectRequired={validation[FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME]}
        />
        {renderActionsButton()}
      </PaymentMethodStyled>
    </>
  );
};

const PaymentMethodStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -8px;
  padding-inline: 0px;
  padding-bottom: 204px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 100%;
    margin-top: 0px;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

export const NextWrap = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: fit-content;
  margin-inline: -16px;
  padding-inline: 0px;
  background: transparent;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    width: 666px;
    height: fit-content;
    border-top: unset;
  }
`;
export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 77px;
  width: 100%;
  background: var(--ds-c-white);
  border-top: 1px solid var(--ds-bg-2);
  padding-inline: 16px;
  margin: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 100%;
    height: 44px;
    border-top: unset;
    padding-inline: unset;
    margin: 0px;
  }
`;
export const ButtonCommonWrap = styled.div`
  width: 50%;
`;

export default PaymentMethod;
