import WarningIcon from 'assets/icons/alert-animation.gif';
import ButtonCommon from 'components/common/ButtonCommon';
import { EDIT_RECEIVER_FULLNAME_NAME } from 'constants';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

import { getFullNameReceiver } from 'components/FormEditReceiver/func';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useReceiver from 'hooks/receiver/useReceiver';
import { useEffect, useState } from 'react';
import { stringUtils } from 'utils';

const BankNumberAccountPopup = ({ isOpen, holderName, onAccept, onEdit }) => {
  const { t } = useLang();
  const { state } = useStore();
  const { currentReceiver, editReceiverInformation } = state;
  const [receiverFullName, setReceiverFullName] = useState('');

  const { isReceiverOfBusinessType, isReceiverOfIndividualType } =
    useReceiver();

  useEffect(() => {
    if (currentReceiver) {
      setReceiverFullName(
        getFullNameReceiver({
          isReceiverOfBusinessType,
          isReceiverOfIndividualType,
          lastName: currentReceiver.lastName,
          firstName: currentReceiver.firstName,
          countryCode: currentReceiver.country.code,
        })
      );
    }
  }, [currentReceiver]);

  const renderBodyContent = () => {
    return (
      <>
        {holderName ? (
          <>
            <SubText style={{ marginBottom: '24px', textAlign: 'center' }}>
              {t('popup_add_bank_account_correct_1')}
              <br />
              <b className="holder-from">{holderName}</b>
            </SubText>
            <SubText>
              <p
                dangerouslySetInnerHTML={{
                  __html: stringUtils.replaceKeyword(
                    t('popup_add_bank_account_correct_2'),
                    [
                      {
                        key: 'fullName',
                        value: currentReceiver
                          ? receiverFullName
                          : editReceiverInformation?.personalDetails[
                              EDIT_RECEIVER_FULLNAME_NAME
                            ],
                      },
                    ]
                  ),
                }}
              />
            </SubText>
          </>
        ) : (
          <SubText
            className="edit"
            dangerouslySetInnerHTML={{
              __html: t('popup_add_bank_confirm_account_correct'),
            }}
          />
        )}
      </>
    );
  };

  return (
    <>
      <style>
        {`
          .bank-number-account-modal-backdrop {
            z-index: 1055!important;
            background: #212121;
            opacity: 0.25!important;
            display: initial!important;
          }

          .modal {
            padding-right: 0px!important;
          }
        `}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="bank-number-account-modal-backdrop"
        centered
      >
        <ModalBody>
          <img src={WarningIcon} alt="alert-animation.gif" />
          {renderBodyContent()}
        </ModalBody>
        <ModalFooter>
          <ButtonCommon
            value={t('button_it_correct')}
            onClick={onAccept}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--ds-c-blue)"
            background="var(--ds-c-white)"
          />
          <ButtonCommon
            value={t('button_edit')}
            onClick={onEdit}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  z-index: 1100;
  & .modal-dialog {
    transform: none;
  }
  & .modal-content {
    padding: 24px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 328px;
    height: fit-content;
    margin-inline: auto;
    border: none;
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 32px;

  & img {
    display: block;
    margin-inline: auto;
    margin-bottom: 16px;
    width: 50px;
    height: 50px;
  }
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin: 0;

  & b {
    margin-inline: auto;
    display: table;
    text-transform: uppercase;

    &.holder-from {
      color: var(--ds-c-red);
    }

    &.holder-to {
      display: initial;
      color: var(--ds-c-blue);
    }
  }

  &.edit {
    b {
      margin-inline: unset;
      display: unset;
      text-transform: unset;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default BankNumberAccountPopup;
