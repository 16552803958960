import { checkFormInputValidation } from 'components/common/func';
import {
  ADD_TRANSACTION_MESSAGE_NAME,
  CALCULATOR_DESKTOP_WIDTH,
  DESKTOP_MIN_WIDTH,
} from 'constants';
import useLang from 'hooks/useLang';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const FormInputMessage = ({
  config,
  field,
  information,
  setInformation,
  validation,
  setValidation,
  isDisabled,
}) => {
  const { t } = useLang();

  const { required, max } = config || { max: 100 };

  const handleOnChange = (e, field) => {
    const { value, name } = e.target;

    if (isDisabled) {
      document.getElementsByName(name)[0].value = '';

      return;
    }

    if (value?.length > max) return;

    const newInformation = {
      ...information,
      [name]: value,
    };

    setInformation(newInformation);

    const newValidation = checkFormInputValidation(
      newInformation[name],
      field,
      validation
    );

    setValidation(newValidation);
  };

  const handleOnBlur = (e, field) => {
    handleOnChange(e, field);
  };

  const handleFormLabelOnClick = (e, name) => {
    e.preventDefault();
    const inputEle = document.getElementsByName(name)[0];

    if (inputEle) {
      inputEle.focus();
    }
  };

  return (
    <FormGroupStyled>
      <FormLabel onClick={(e) => handleFormLabelOnClick(e, field.name)}>
        {t(field.label)}
        {(required || field.isRequired) && <span>*</span>}
        {field.name === ADD_TRANSACTION_MESSAGE_NAME && (
          <LimitChar>{`(${
            information[field.name]?.length || 0
          }/${max})`}</LimitChar>
        )}
      </FormLabel>
      <FormControl
        as="textarea"
        type={field.type}
        name={field.name}
        value={information ? information[field.name] : ''}
        placeholder={t(field.placeholder)}
        $isInvalid={validation[field.name]}
        onChange={(e) => handleOnChange(e, field)}
        onBlur={(e) => handleOnBlur(e, field)}
        $isDisabled={isDisabled}
        readOnly={field.isReadOnly || isDisabled}
      />
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[field.name])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);

  span {
    color: var(--bg-primary);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  color: ${(props) => props.$isDisabled && 'var(--ds-c-grey-neutral)'};
  width: 100% !important;
  height: 100px !important;
  border: ${(props) =>
    props.$isInvalid
      ? '1px solid var(--ds-c-red)'
      : '1px solid var(--ds-c-grey-disabled)'};
  border-radius: 12px;
  padding: 10px !important;
  background: var(--ds-c-white);
  display: flex;
  justify-content: start;

  &:focus {
    background: var(--ds-c-white);
    border: ${(props) =>
      props.$isDisabled
        ? 'none!important'
        : '1px solid var(--ds-c-grey-dark) !important'};
    outline: none !important;
    box-shadow: none !important;
  }

  &[readonly] {
    background: ${(props) =>
      props.$isDisabled ? '#eef2f5!important' : 'var(--ds-c-white)!important'};
    cursor: not-allowed;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    color: var(--ds-c-grey-hover);

    display: flex;
    justify-content: start;
  }

  &::-webkit-resizer {
    display: none !important;
  }
`;

const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  &.invalid-feedback {
    display: block !important;
  }

  @media screen and (width: ${CALCULATOR_DESKTOP_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const LimitChar = styled.span`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #7a8189;
  margin-left: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default FormInputMessage;
