import InfoFillIcon from 'assets/icons/info-fill-icon.svg';
import {
  findDiscountDetails,
  getDiscountDefault,
  getDiscountsActiveList,
  handleRemoveDiscountApplied,
} from 'components/Discount/func';
import CloseIcon from 'components/common/Icons/CloseIcon';
import {
  DESKTOP_MIN_WIDTH,
  EN_LANG,
  MOBILE_SMALL_MAX_WIDTH,
  MOBILE_SMALL_MIN_WIDTH,
  VI_LANG,
} from 'constants';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useAutoScaleToFitContainer from 'hooks/useAutoScaleToFitContainer';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { DiscountModal } from '../DiscountModal/ver2';
import CircleRoundCutHorizontal from './CircleRoundCutHorizontal';

const DiscountApplied = ({ isFx, isMakePayment }) => {
  const { locate = EN_LANG } = useLang();
  const { state } = useStore();
  let { addTransactionInformation } = state;

  const { addTransactionInformation: fxAddTransactionInformation } =
    useFxFormAddTransactionStore();

  addTransactionInformation = isFx
    ? fxAddTransactionInformation
    : addTransactionInformation;

  const { paymentMethod } = addTransactionInformation || {};
  const { isDesktop, isSmallMobile } = useDetectDevice();

  const {
    discountsAvailable,
    discountsInactive,
    discountApplied,
    discountDetails,
  } = paymentMethod || {};
  const { code } = discountApplied || {};
  const discountsActive = getDiscountsActiveList({
    discountsAvailable,
    discountsInactive,
  });
  const discountDefault = getDiscountDefault({
    discountsAvailable,
    discountsInactive,
  });

  const [discount, setDiscount] = useState(null);
  const [isOpenDiscountModal, setOpenDiscountModal] = useState(false);

  const discountCodeContainerWidth = useMemo(() => {
    if (isDesktop) return 280;

    if (isSmallMobile) return 130;

    return 160;
  }, []);

  const discountCodeClassName = `discount-applied-code-${code}`;
  useAutoScaleToFitContainer({
    className: discountCodeClassName,
    maxWidth: discountCodeContainerWidth,
    alignHorizontal: 'left',
  });

  const handleOnCloseDiscountModal = () => {
    setOpenDiscountModal(false);
  };

  const handleOnOpenDiscountModal = () => {
    setOpenDiscountModal(true);

    if (discountDetails) {
      setDiscount(discountDetails);
    } else {
      setDiscount(findDiscountDetails(discountsActive, code));
    }
  };

  const renderRemoveDiscountButton = () => {
    if (isMakePayment) return null;
    if (
      !isEmpty(discountDefault) &&
      !isEmpty(discountApplied) &&
      discountDefault?.code === discountApplied?.code
    )
      return null;
    return (
      <CloseIcon
        color="var(--ds-c-green-default)"
        onClick={handleRemoveDiscountApplied}
        styles={{
          cursor: 'pointer',
        }}
      />
    );
  };

  return (
    <>
      {isOpenDiscountModal && discount && (
        <DiscountModal
          isOpen={isOpenDiscountModal && discount}
          discount={discount}
          onClose={handleOnCloseDiscountModal}
        />
      )}
      <DiscountAppliedStyled>
        <DiscountAppliedContent $locate={locate}>
          <CircleRoundCutHorizontal />
          <CircleRoundCutHorizontal right="calc(100% - 8px)" />
          <DiscountDetails onClick={handleOnOpenDiscountModal}>
            <ScaleCodeContainer $width={discountCodeContainerWidth}>
              <CodeText className={discountCodeClassName}>
                {code}
                <InfoImage
                  src={InfoFillIcon}
                  onClick={handleOnOpenDiscountModal}
                />
              </CodeText>
            </ScaleCodeContainer>
          </DiscountDetails>
          {renderRemoveDiscountButton()}
        </DiscountAppliedContent>
      </DiscountAppliedStyled>
    </>
  );
};

const DiscountAppliedStyled = styled.div`
  height: 44px;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
`;

const DiscountAppliedContent = styled.div`
  position: relative;
  height: 44px;
  width: ${(props) => (props.$locate === VI_LANG ? '100%' : '100%')};
  border: 2px dashed var(--ds-c-green-default);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #cbffec1a;
  padding-inline: 24px;

  @media screen and (min-width: ${MOBILE_SMALL_MIN_WIDTH}px) and (max-width: ${MOBILE_SMALL_MAX_WIDTH}px) {
    padding-inline: 16px;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 437px;
  }
`;

const DiscountDetails = styled.div`
  display: flex;
  align-items: center;
`;
const ScaleCodeContainer = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
  width: ${(props) => props.$width}px;
  height: 24px;
`;
const CodeText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: var(--ds-c-green-default);
  white-space: nowrap;
  margin-bottom: 0px;

  display: flex;
  align-items: center;
`;
const InfoImage = styled.img`
  cursor: pointer;
  margin-left: 6px;
`;

export default DiscountApplied;
