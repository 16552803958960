import ThreeDotHHMTIcon from 'assets/icons/three-dot-hhmt-icon.svg';
import ThreeDotRemoxIcon from 'assets/icons/three-dot-remox-icon.svg';
import { fetchReceiverInformation } from 'components/Receivers/func';
import {
  DESKTOP_MIN_WIDTH,
  RECEIVERS_EDIT_SUFIX,
  RECEIVER_ACTIONS,
} from 'constants';
import useAuth from 'hooks/useAuth';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const CustomActionDropdown = ({
  text,
  textStyles = {},
  styles = {},
  dropdownMenuStyles = {},
  onDelete = () => {},
  onEdit,
}) => {
  const { t } = useLang();
  const { state } = useStore();
  const { currentReceiver } = state;
  const { isHHMTTheme } = useTheme();
  const navigate = useNavigate();
  const { token } = useAuth();

  const handleEditAction = async () => {
    if (onEdit) {
      onEdit();

      return;
    }

    fetchReceiverInformation(token, currentReceiver?.receiverId);

    navigate(`${RECEIVERS_EDIT_SUFIX}/${currentReceiver?.receiverId}`);
  };

  const handleDeleteAction = () => {
    onDelete();
  };

  const CustomToggle = React.forwardRef(({ onClick }, ref) => (
    <ButtonToggle
      href="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();

        onClick(e);
      }}
      style={styles}
    >
      {text && <p style={textStyles}>{text}</p>}
      <img
        src={isHHMTTheme ? ThreeDotHHMTIcon : ThreeDotRemoxIcon}
        width={24}
        height={24}
        alt=""
      />
    </ButtonToggle>
  ));

  return (
    <>
      <ActionDropdownStyled $dropdownMenuStyles={dropdownMenuStyles}>
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} />
          <DropdownMenu>
            {RECEIVER_ACTIONS.map((action, index) => (
              <DropdownItem
                key={action.label}
                eventKey={index}
                onClick={index === 0 ? handleEditAction : handleDeleteAction}
              >
                {t(action.label)}
                <img src={action.icon} width={24} height={24} alt="" />
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </ActionDropdownStyled>
    </>
  );
};

const ActionDropdownStyled = styled.div`
  display: flex;
  align-items: center;

  & .dropdown-menu {
    transform: ${(props) => props.$dropdownMenuStyles.transform};
  }
`;

const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  height: fit-content;
  box-shadow: var(--ds-bs-4);
  border-radius: 8px;
  inset: 0px 0px auto auto !important;
  transform: translate(-8px, 32px) !important;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    transform: translate(-8px, 44px) !important;
  }
`;
const DropdownItem = styled(Dropdown.Item)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  text-transform: capitalize;
  width: 160px;
  height: 40px;
  background: transparent;

  &:hover {
    border-radius: 8px;
    background: var(--ds-c-grey-light);
  }

  &:active {
    color: var(--ds-c-grey-dark);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ButtonToggle = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ds-c-white);
  border: none;
  padding: 0;
  text-decoration: none;
  width: fit-content;
  height: fit-content;
  border: none;

  &:hover {
    opacity: 0.5;
  }

  &:focus {
    background: var(--ds-c-white);
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background: var(--ds-c-white);
    border: none;
    outline: none;
    box-shadow: none;
  }

  & p {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--c-action);

    padding: 0;
    margin: 0;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & img {
    padding-top: 3px;
    padding-bottom: 3px;
    object-fit: contain;
    filter: invert(12%) sepia(87%) saturate(6995%) hue-rotate(234deg)
      brightness(90%) contrast(113%);
  }
`;

export default CustomActionDropdown;
