import { DESKTOP_MIN_WIDTH, REGISTER_EMAIL_NAME } from 'constants';
import useLang from 'hooks/useLang';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { checkFormInputValidation } from '../../func';
import AccountExistedInform from '../AccountExistedInform';

const FormInputValidation = ({
  field,
  information,
  validation,
  setInformation,
  setValidation,
  isEmailExisted,
  setEmailExisted,
}) => {
  const { t } = useLang();

  const isShowAccountExistedInform =
    isEmailExisted && field.name === REGISTER_EMAIL_NAME;

  const handleEmailOnChange = (name, value) => {
    const isEmailField = name === REGISTER_EMAIL_NAME;

    if (isEmailField && isEmailExisted) {
      setEmailExisted(false);
    }

    return value;
  };

  const handleOnChange = (e, field) => {
    const { value, name } = e.target;
    let valueTrim = value.trim();

    valueTrim = handleEmailOnChange(name, value);

    const newInformation = {
      ...information,
      [name]: valueTrim,
    };
    setInformation(newInformation);

    const newValidation = checkFormInputValidation(
      newInformation[name],
      field,
      newInformation,
      validation
    );
    setValidation(newValidation);
  };

  const handleOnBlur = (e, field) => {
    handleOnChange(e, field);
  };

  const handleFormLabelOnClick = (name) => {
    const inputEle = document.getElementsByName(name)[0];

    if (inputEle) {
      inputEle.focus();
    }
  };

  return (
    <FormGroupStyled $isShowAccountExistedInform={isShowAccountExistedInform}>
      <FormLabel onClick={() => handleFormLabelOnClick(field.name)}>
        {t(field.label)}
        <span>*</span>
      </FormLabel>
      <FormControl
        type={field.type}
        name={field.name}
        value={information ? information[field.name] : ''}
        placeholder={t(field.placeholder)}
        isInvalid={validation[field.name]}
        onChange={(e) => handleOnChange(e, field)}
        onBlur={(e) => handleOnBlur(e, field)}
      />
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[field.name])}
      </FormControlFeedback>
      {isShowAccountExistedInform && <AccountExistedInform />}
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: ${(props) =>
    props.$isShowAccountExistedInform ? '16px' : '32px'};
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: var(--ds-c-white);

  &:focus {
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputValidation;
