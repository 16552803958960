import RadioCheckedIcon from 'assets/icons/radio-checked-icon.svg';
import RadioboxHHMTIcon from 'assets/icons/radiobox-hhmt-icon.svg';
import RadioboxRemoxIcon from 'assets/icons/radiobox-remox-icon.svg';
import { ButtonFormCheck } from 'components/FormEditReceiver/components/FormInputSelectTypeOfReceiverRadio';
import {
  DESKTOP_MIN_WIDTH,
  REGISTER_GENDER_NAME,
  REGISTER_PERSONAL_DETAILS_GENDER_FIELD,
} from 'constants';
import useLang from 'hooks/useLang';
import useScrollIntoView from 'hooks/useScrollIntoView';
import useTheme from 'hooks/useTheme';
import { useRef } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { domUtils } from 'utils';

const MALE = 'MALE';
const FEMALE = 'FEMALE';

const FormInputGenderRadio = ({
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();

  const isMale = information[REGISTER_GENDER_NAME] === MALE;
  const isFemale = information[REGISTER_GENDER_NAME] === FEMALE;

  const validRef = useRef(null);
  useScrollIntoView(validRef, validation[REGISTER_GENDER_NAME], [validation]);

  const handleOnChange = (e, value) => {
    e.preventDefault();
    e.stopPropagation();

    const newInformation = {
      ...information,
      [REGISTER_GENDER_NAME]: value,
    };
    setInformation(newInformation);

    const newValidation = {
      ...validation,
      [REGISTER_GENDER_NAME]: '',
    };
    setValidation(newValidation);
  };

  const renderFormCheckRadio1 = () => {
    const formCheckRadioClassName1 = REGISTER_GENDER_NAME + '1';

    return (
      <>
        <FormCheck
          className={`${formCheckRadioClassName1} ${isMale && 'active'}`}
          inline
          type={'radio'}
          label={t('form_gender_male')}
          name={REGISTER_GENDER_NAME}
          checked={isMale}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
          readOnly
        />
        <ButtonFormCheck
          $width={
            domUtils.getOffsetWidthByClassName(formCheckRadioClassName1) + 44
          }
          onClick={(e) => handleOnChange(e, MALE)}
        >
          {t('form_gender_male')}
        </ButtonFormCheck>
      </>
    );
  };

  const renderFormCheckRadio2 = () => {
    const formCheckRadioClassName2 = REGISTER_GENDER_NAME + '2';

    return (
      <>
        <FormCheck
          className={`${formCheckRadioClassName2} ${isFemale && 'active'}`}
          inline
          type={'radio'}
          label={t('form_gender_female')}
          name={REGISTER_GENDER_NAME}
          checked={isFemale}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
          readOnly
          style={{ marginRight: 0 }}
        />
        <ButtonFormCheck
          $width={domUtils.getOffsetWidthByClassName(formCheckRadioClassName2)}
          onClick={(e) => handleOnChange(e, FEMALE)}
        >
          {t('form_gender_female')}
        </ButtonFormCheck>
      </>
    );
  };

  return (
    <FormGroupStyled>
      <FormLabel onClick={(e) => e.preventDefault()}>
        {t(REGISTER_PERSONAL_DETAILS_GENDER_FIELD.label)}
        <span>*</span>
      </FormLabel>
      {renderFormCheckRadio1()}
      {renderFormCheckRadio2()}
      {validation[REGISTER_GENDER_NAME] && (
        <FormControlFeedback ref={validRef} hidden={false} type="invalid">
          {t(validation[REGISTER_GENDER_NAME])}
        </FormControlFeedback>
      )}
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  display: block;
  color: var(--ds-c-grey-dark);
  margin-bottom: 16px;

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
  }
`;
const FormCheck = styled(Form.Check)`
  margin: 0;
  margin-right: 44px;

  label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    margin-left: 0px;
    cursor: pointer !important;
    user-select: none;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: ${(props) => `url(${props.$checkboxSrc}) !important`};
    background-position: center !important;
    width: 16px;
    height: 16px;
    cursor: pointer !important;

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }

    &:checked[type='radio'] {
      background-image: url(${RadioCheckedIcon}) !important;
      background-position: center !important;
    }
  }

  &.active {
    label {
      font-weight: 700;
    }
  }
`;
const FormControlFeedback = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;
  margin-bottom: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputGenderRadio;
