import {
  DISCOUNT_ACTIVE,
  DISCOUNT_COMING_SOON,
  DISCOUNT_EXPIRED,
  DISCOUNT_FULLY_REDEEMED,
  DISCOUNT_REDEEMED,
} from 'constants';

export const textEventTicketColor = () => ({
  [DISCOUNT_ACTIVE]: '#FFFFFF',
  [DISCOUNT_COMING_SOON]: '#1C3F88',
  [DISCOUNT_REDEEMED]: '#1C3F88',
  [DISCOUNT_EXPIRED]: '#1C3F88',
  [DISCOUNT_FULLY_REDEEMED]: '#1C3F88',
});
