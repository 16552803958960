import {
  BACKSPACE_CODE,
  DELETE_CODE,
  checkFormInputValidation,
} from 'components/common/func';

import {
  DESKTOP_MIN_WIDTH,
  PAYMENT_BY_DEPOSIT_EXPIRY_FIELD,
  PAYMENT_BY_DEPOSIT_EXPIRY_NAME,
} from 'constants';
import { format } from 'helpers';
import card from 'helpers/creditCardHelper';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const customFormInputStyleMobile = {
  [PAYMENT_BY_DEPOSIT_EXPIRY_NAME]: {
    width: 'calc(50% - 4px)',
    float: 'left',
    marginRight: '4px',
    zIndex: 1,
  },
};

const customFormInputStyle = {
  [PAYMENT_BY_DEPOSIT_EXPIRY_NAME]: {
    width: 'calc(50% - 12px)',
    float: 'left',
    marginRight: '12px',
    zIndex: 1,
    marginBottom: '42px',
  },
};

const FormInputCardExpiryDateValidation = ({
  field,
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();
  const { isMobile, isTablet } = useDetectDevice();

  const handleCardExpiryValidation = (name, value, validation) => {
    if (value && (!card.isExpiryValid(value) || value?.length !== 5)) {
      validation = {
        ...validation,
        [name]: PAYMENT_BY_DEPOSIT_EXPIRY_FIELD.msgInvalid,
      };
    } else {
      validation = {
        ...validation,
        [name]: '',
      };
    }

    return validation;
  };
  const handleOnChange = (e, field) => {
    const { value, name } = e.target;
    const valueFormat = value ? format.toCardExpiryDateStr(value) : value;

    let newInformation = { ...information };
    let newValidation = { ...validation };

    if (valueFormat.length > 5) return;

    newInformation = {
      ...information,
      [name]: valueFormat,
    };

    setInformation(newInformation);

    newValidation = checkFormInputValidation(
      newInformation[name],
      field,
      validation
    );

    newValidation = handleCardExpiryValidation(name, value, newValidation);

    setValidation(newValidation);
  };

  const handleOnBlur = (e, field) => {
    handleOnChange(e, field);
  };

  const handleFormLabelOnClick = (e, name) => {
    e.preventDefault();
    const inputEle = document.getElementsByName(name)[0];

    if (inputEle) {
      inputEle.focus();
    }
  };

  const handleOnKeyDown = (e) => {
    if ([BACKSPACE_CODE, DELETE_CODE].includes(e.which)) {
      const newInfomation = {
        ...information,
        [field.name]: '',
      };
      setInformation(newInfomation);

      const newValidation = {
        ...validation,
        [field.name]: '',
      };
      setValidation(newValidation);
    }
  };

  return (
    <FormGroupStyled
      style={
        isMobile || isTablet
          ? customFormInputStyleMobile[field.name]
          : customFormInputStyle[field.name]
      }
    >
      <FormLabel onClick={(e) => handleFormLabelOnClick(e, field.name)}>
        {t(field.label)}
        {field.isRequired && <span>*</span>}
      </FormLabel>
      <FormControl
        type={field.type}
        name={field.name}
        value={information ? information[field.name] : ''}
        placeholder={field.placeholder}
        isInvalid={validation[field.name]}
        onChange={(e) => handleOnChange(e, field)}
        onKeyDown={handleOnKeyDown}
        onBlur={(e) => handleOnBlur(e, field)}
        readOnly={field.isReadOnly}
      />
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[field.name])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: var(--ds-c-white);

  &:focus {
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-inline: 10px !important;
  }

  &[readonly] {
    border: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputCardExpiryDateValidation;
