import CalculatorModal from 'components/fx/CalculatorModal';
import { CURRENCY_FLAG_REFERENCES, CURRENCY_NAME_REFERENCE } from 'constants';
import { format } from 'helpers';
import useFxCalculator from 'hooks/fx/useFxCalculator';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';

const FXRateItem = ({ currency, rate, rateData }) => {
  const [isShowCalculatorModal, setShowCalculatorModal] = useState(false);
  const [orderEdit, setOrderEdit] = useState(null);

  const { handleRateItemOnShowCalculatorModal } = useFxCalculator();
  const { resetAddTransactionInformation } = useFxFormAddTransactionStore();

  const handleOnShowCalculatorModal = async () => {
    handleRateItemOnShowCalculatorModal({
      sellRate: rateData,
      callback: (order) => {
        setOrderEdit(order);
        setShowCalculatorModal(true);
        resetAddTransactionInformation();
      },
    });
  };
  const handleOnCloseCalculatorModal = () => {
    setShowCalculatorModal(false);
  };
  const handleOnBuyCurrency = () => {
    handleOnShowCalculatorModal();
  };

  return (
    <>
      {isShowCalculatorModal && !isEmpty(orderEdit) && (
        <CalculatorModal
          isOpen={isShowCalculatorModal && !isEmpty(orderEdit)}
          onClose={handleOnCloseCalculatorModal}
          orderEdit={orderEdit}
        />
      )}
      <ItemWrap onClick={handleOnBuyCurrency}>
        <CurrencyCode>
          <FlagIcon src={CURRENCY_FLAG_REFERENCES[currency]} />
          <div>
            {CURRENCY_NAME_REFERENCE[currency]}
            <span>({currency})</span>
          </div>
        </CurrencyCode>
        <Rate>{format.toAmountStr2(rate.toFixed(4))}</Rate>
      </ItemWrap>
    </>
  );
};

const ItemWrap = styled.div`
  padding: 18px 8px 20px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  gap: 12px;
  width: 436px;

  border-radius: 8px;
  background: var(--ds-c-white);
  color: var(--ds-c-black);
  border: 1px solid var(--ds-c-blue-remox-light);
  cursor: pointer;
  padding: 12px;
  margin-bottom: 12px;

  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: left;
  text-transform: uppercase;

  span {
    font-weight: 700;
    color: var(--ds-c-blue);
    margin-left: 4px;
    display: inline-block;
  }
`;

const CurrencyCode = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 0;
`;

const FlagIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: cover;
  border: 1px solid var(--ds-c-blue-remox-light);
  border-radius: 50%;
`;

const Rate = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  margin: 0;
`;

export default FXRateItem;
