import Events from 'components/Offers/components/Events';
import Promotions from 'components/Offers/components/Promotions';
import ButtonCommon from 'components/common/ButtonCommon';
import { OFFERS_PREFIX, OFFER_NAV } from 'constants';
import useLang from 'hooks/useLang';
import { useState } from 'react';
import styled from 'styled-components';
import { domUtils } from 'utils';
import Offers from '..';

const OffersMobile = () => {
  const { t } = useLang();

  const [currentSubTab, setCurrentSubTab] = useState(0);

  const tabContentReference = {
    0: <Offers />,
    1: <Promotions />,
    2: <Events />,
  };

  const handleTabOnClick = (index) => {
    setCurrentSubTab(index);

    domUtils.changeAddressBarUrlWithoutReloadPage({
      prefix: OFFERS_PREFIX,
    });
  };

  return (
    <>
      <style>
        {`
          .offers-mobile-offcanvas {
            width: 100%!important;
            height: calc(100% - 70px);
            border: none!important;
          }
          .offers-mobile-offcanvas-backdrop {
            opacity: 0!important;
          }
        `}
      </style>
      <OffersMobileStyled>
        <TabWrap>
          {OFFER_NAV.map((tab, index) => (
            <ButtonCommon
              key={tab.label}
              value={t(tab.label)}
              background={
                currentSubTab === index
                  ? 'var(--c-tab-active-2)'
                  : 'transparent'
              }
              color={'var(--ds-c-blue)'}
              styles={{
                fontSize: '12px',
                lineHeight: '15px',
                width: '33.333%',
                borderRadius: '0px',
                height: '40px',
                float: 'left',
                color: 'var(--ds-c-blue)',
                ...(currentSubTab !== index && {
                  fontWeight: 500,
                  borderBottom: 'none',
                }),
                ...(currentSubTab === index && {
                  boxShadow: '0px 1px 6px 0px #0000001A',
                  borderBottom: 'none',
                  borderRadius: '6px',
                }),
                paddingInline: 0,
              }}
              onClick={() => handleTabOnClick(index)}
            />
          ))}
        </TabWrap>
        <ContentBody>{tabContentReference[currentSubTab]}</ContentBody>
      </OffersMobileStyled>
    </>
  );
};

const OffersMobileStyled = styled.div`
  position: relative;
`;

const TabWrap = styled.div`
  height: 50px;
  width: 100%;
  background-color: var(--ds-bg-1);
  padding: 5px;
  border-radius: 12px;
`;

const ContentBody = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-block: 24px;
  padding-bottom: 120px;
`;

export default OffersMobile;
