import EventPage from 'pages/EventPage';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

const EventAvailableModalMobile = ({ isOpen }) => {
  return (
    <>
      <style>
        {`
            .modal-backdrop {
                display: none;
            }
        `}
      </style>
      <ModalStyled show={isOpen} onHide={() => {}} animation={false}>
        <ModalBody>
          <EventPage />
        </ModalBody>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  z-index: 1;
  background-color: #ffffff;

  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  & .modal-dialog {
    position: absolute;
    top: 70px;
    margin: 0;
    height: 100vh;
    width: 100%;
    max-width: 100%;
    transform: none;
  }

  & .modal-content {
    padding: 0;
    border-radius: 0px;
    box-shadow: none;
    background: var(--ds-c-white);
    width: 100%;
    height: calc(100vh - 70px);
    margin-inline: auto;
    border: none;
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  padding-bottom: 91px;
`;

export default EventAvailableModalMobile;
