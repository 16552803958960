import {
  default as TransferenceHHMTIcon,
  default as TransferenceRemoxIcon,
} from 'assets/icons/no_offers.svg';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';

const OffersEmpty = ({ offersEmptyTxt, offerDesc }) => {
  const { isHHMTTheme } = useTheme();
  const { isMobile } = useDetectDevice();

  return (
    <OffersEmptyStyled>
      <Desc>{offerDesc}</Desc>
      <img
        src={isHHMTTheme ? TransferenceHHMTIcon : TransferenceRemoxIcon}
        width={isMobile ? 200 : 200}
        height={isMobile ? 200 : 200}
        alt=""
      />
      <span
        dangerouslySetInnerHTML={{
          __html: offersEmptyTxt,
        }}
      />
    </OffersEmptyStyled>
  );
};

const OffersEmptyStyled = styled.div`
  display: grid;
  justify-items: center;
  position: relative;

  img {
    margin-top: 40px;
  }

  & span {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    color: var(--c-sub-info);
    margin-bottom: 24px;
  }
`;

const Desc = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-dark-gray);
  margin: 0;
  margin-block: 16px;
  padding: 0;
`;

export default OffersEmpty;
