import NotificationIconSVG from 'assets/icons/notification-icon.svg';
import NotificationsEmptyIcon from 'assets/icons/notifications_empty-2.svg';
import NotifyAnimationBlueIcon from 'components/common/Icons/NotifyAnimationBlueIcon';
import NotifyAnimationLightIcon from 'components/common/Icons/NotifyAnimationLightIcon';
import NotificationIcon from 'components/common/Icons/NotifyIcon';
import { DESKTOP_MIN_WIDTH, PROFILE_PREFIX, TABLET_MAX_WIDTH } from 'constants';
import { datePicker } from 'helpers';
import useLang from 'hooks/useLang';
import useNotify from 'hooks/useNotify';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import { Navbar, Offcanvas } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SET_NEW_NOTIFICATION } from 'store/action';
import styled from 'styled-components';

export const NOTIFICATION_NAME = 'notification-name';

const CustomNotificationDropdownMobile = ({ isShowNavbar, setShowNavbar }) => {
  const { t, locate } = useLang();
  const { state, dispatch } = useStore();
  let { isNewNotification, notifications } = state;
  const navigate = useNavigate();
  const { isHHMTTheme } = useTheme();

  const { handleNotifyOnClick } = useNotify(true);

  let notificationsLimit;
  if (notifications && Array.isArray(notifications)) {
    notificationsLimit = notifications.slice(0, 5);
  }

  const handleOnToggleNavbar = () => {
    /* eslint-disable-next-line no-extra-boolean-cast */
    if (!Boolean(notificationsLimit)) return;

    setShowNavbar(!isShowNavbar);

    dispatch({
      type: SET_NEW_NOTIFICATION,
      payload: false,
    });
  };

  return (
    <>
      <style>
        {`
          .notification-dropdown-mobile-offcanvas {
            width: 100%!important;
            height: 100%;
            border: none!important;
          }

          @media screen and (max-width: ${TABLET_MAX_WIDTH}px) { 
            .notification-dropdown-mobile-offcanvas {
              height: calc(100% - 70px);
              margin-top: 70px;
            }
          }

          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) { 
            .notification-dropdown-mobile-offcanvas {
              width: 375px!important;
            }
          }

          .notification-dropdown-mobile-offcanvas-backdrop {
            opacity: 0.25!important;
          }
        `}
      </style>
      <CustomNotificationDropdownStyled>
        <NavbarStyled
          expand={false}
          expanded={isShowNavbar}
          onToggle={handleOnToggleNavbar}
        >
          <NavbarToggle
            aria-controls="offcanvasNavbar-expand-false"
            className="notification-mobile-icon"
          >
            {Boolean(notificationsLimit) &&
              Boolean(
                notificationsLimit?.filter(({ read }) => !read)?.length
              ) && (
                <>
                  {isNewNotification ? (
                    <>
                      {isHHMTTheme ? (
                        <NotifyAnimationLightIcon />
                      ) : (
                        <NotifyAnimationBlueIcon />
                      )}
                    </>
                  ) : (
                    <NotificationIcon
                      color={
                        isHHMTTheme ? 'var(--ds-c-white)' : 'var(--ds-c-blue)'
                      }
                      styles={{
                        padding: '9.5px',
                      }}
                    />
                  )}
                  <HaveNotify>
                    {notificationsLimit.filter(({ read }) => !read)?.length}
                  </HaveNotify>
                </>
              )}
          </NavbarToggle>
          <NavbarOffcanvas
            id="offcanvasNavbar-expand-false"
            aria-labelledby="offcanvasNavbarLabel-expand-false"
            placement="end"
            className="notification-dropdown-mobile-offcanvas"
            backdropClassName="notification-dropdown-mobile-offcanvas-backdrop"
          >
            <OffcanvasHeader closeButton>
              <OffcanvasTitle id="offcanvasNavbarLabel-expand-false">
                {t('label_notifications')}
              </OffcanvasTitle>
            </OffcanvasHeader>
            <OffcanvasBody>
              {isShowNavbar &&
                /* eslint-disable-next-line no-extra-boolean-cast */
                (Boolean(notificationsLimit?.length) ? (
                  <>
                    {notificationsLimit.map(
                      ({ createdAt, message, icon, read, uuid }, index) => (
                        <NotifyItem
                          key={uuid}
                          eventKey={index}
                          onClick={(e) =>
                            handleNotifyOnClick(e, notificationsLimit[index])
                          }
                          $isRead={!read}
                        >
                          <Title>
                            <img src={icon} width={18} height={18} alt="" />{' '}
                            {message?.[locate?.toUpperCase()]?.title ||
                              message?.[locate]?.title}{' '}
                            {!read && <MarkUnread>●</MarkUnread>}
                          </Title>
                          <Desc>
                            {message?.[locate?.toUpperCase()]?.short ||
                              message?.[locate]?.short}
                          </Desc>
                          <DateTime>
                            {`${datePicker.convert24hhmmTo12hhmm(
                              datePicker.timeUTCToTimeStr(
                                new Date(parseInt(createdAt))
                              )
                            )} - ${datePicker.dateUTCToDateStr(
                              new Date(parseInt(createdAt))
                            )}`}
                          </DateTime>
                        </NotifyItem>
                      )
                    )}
                    <SeeAllNotifications
                      href={`${PROFILE_PREFIX}?tab=2`}
                      onClick={(e) => {
                        e.preventDefault();

                        document
                          .getElementsByClassName('notification-mobile-icon')[0]
                          .click();

                        navigate(`${PROFILE_PREFIX}?tab=2`);
                      }}
                    >
                      {t('label_see_all_notifications')}
                    </SeeAllNotifications>
                  </>
                ) : (
                  <NotificationsEmpty>
                    <img
                      src={NotificationsEmptyIcon}
                      width={200}
                      height={200}
                      alt=""
                    />
                    <Desc
                      style={{
                        margin: 0,
                        color: 'var(--c-sub-info-hover)',
                      }}
                    >
                      {t('label_empty_notification')}
                    </Desc>
                  </NotificationsEmpty>
                ))}
            </OffcanvasBody>
          </NavbarOffcanvas>
        </NavbarStyled>
      </CustomNotificationDropdownStyled>
    </>
  );
};

const CustomNotificationDropdownStyled = styled.div`
  position: relative;
`;

const NavbarStyled = styled(Navbar)`
  display: initial;
  padding: 0;
  height: 40px;
  margin-right: 8px;
`;

const NavbarToggle = styled(Navbar.Toggle)`
  padding: 0;
  border: none;

  &:focus {
    border: none;
    box-shadow: none;
  }

  .navbar-toggler-icon {
    background-image: url(${NotificationIconSVG});
    background-size: 14px;
    border-color: var(--ds-c-white);
    width: 36px;
    opacity: 1;
  }
`;

const NavbarOffcanvas = styled(Navbar.Offcanvas)``;
const OffcanvasHeader = styled(Offcanvas.Header)`
  height: 70px;
  padding: 16px 24px;
  border-bottom: 1px solid #eef2f5;

  & button {
    background-size: 14px;
    color: var(--ds-c-grey-dark);
    opacity: 1;

    &:hover {
      color: var(--ds-c-grey-hover);
    }

    &:focus {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: 56px;
  }
`;
const OffcanvasTitle = styled(Offcanvas.Title)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #0e1012;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const OffcanvasBody = styled(Offcanvas.Body)`
  padding: 0px;
  padding-bottom: 50px;
`;

const HaveNotify = styled.span`
  font-family: var(--ff-primary);
  line-height: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: var(--ds-c-white);
  text-decoration: none;
  text-align: center;
  padding-right: 0px;

  position: absolute;
  top: -1px;
  left: 18px !important;
  width: 12px;
  height: 12px;
  background: #e72631;
  border-radius: 100%;
`;

const NotifyItem = styled.div`
  width: 100%;
  height: fit-content;
  padding: 8px 16px;
  background-color: ${(props) => (props.$isRead ? '#EEF2F5' : '#fff')};
  border-bottom: 1px solid #d0dce5;

  &:hover {
    background-color: ${(props) => (props.$isRead ? '#EEF2F5' : '#fff')};
  }

  &:active {
    background-color: ${(props) => (props.$isRead ? '#EEF2F5' : '#fff')};
  }
`;
const Title = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #0e1012;

  margin-bottom: 8px;
  display: flex;
  align-items: center;

  & span {
    font-family: var(--ff-secondary);
    color: var(--ds-c-blue);
    font-weight: 700;
    margin-left: 4px;
  }

  & img {
    margin-right: 8px;
  }
`;
const MarkUnread = styled.span`
  font-size: 18px;
  color: var(--ds-c-red) !important;
`;
const Desc = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--ds-c-grey-dark);
  text-wrap: wrap;

  margin-bottom: 8px;
`;
const DateTime = styled.p`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--ds-c-grey-neutral);
  text-align: right;

  margin-bottom: 0px;
`;
const SeeAllNotifications = styled.a`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--ds-c-blue);
  text-decoration: none;

  position: absolute;
  bottom: 0px;
  z-index: 3;
  background: var(--ds-c-white);
  width: 100%;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;

  &:hover {
    color: var(--ds-c-blue-hover);
  }
`;

const NotificationsEmpty = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  margin-top: 24px;
`;

export default CustomNotificationDropdownMobile;
