import { THROTTLE_SEC } from 'constants';
import { useState } from 'react';

const useThrottle = (sec = THROTTLE_SEC) => {
  const [isAllow, setAllow] = useState(true);

  const throttle = (cb) => {
    if (isAllow) {
      cb();

      setAllow(false);

      setTimeout(() => {
        setAllow(true);
      }, sec * 1000);
    }
  };

  return throttle;
};

export default useThrottle;
