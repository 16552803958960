export const address = {
  addressCombine: (
    streetUnit,
    streetNo,
    streetName,
    streetType,
    suburb,
    state,
    postalCode
  ) => {
    return `${streetUnit ? streetUnit + ' ' : ''}${
      streetNo ? streetNo + ' ' : ''
    }${streetName ? streetName + ' ' : ''}${streetType ? streetType : ''}, ${
      suburb ? suburb + ' ' : ''
    }${state ? state + ' ' : ''}${postalCode ? postalCode + ' ' : ''}`?.trim();
  },
  addressStreetCombine: (streetUnit, streetNo, streetName, streetType) => {
    return `${streetUnit ? streetUnit + ' ' : ''}${
      streetNo ? streetNo + ' ' : ''
    }${streetName ? streetName + ' ' : ''}${
      streetType ? streetType : ''
    }`?.trim();
  },
};
