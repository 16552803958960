import CustomSearchDropdown from 'components/common/CustomSearchDropdown';
import { DESKTOP_MIN_WIDTH } from 'constants';
import {
  FX_ADD_TRANSACTION_PURPOSE_FIELD,
  FX_ADD_TRANSACTION_SOURCE_FIELD,
} from 'constants/fx';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import React from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import CustomOccupationDropdown from './components/CustomOccupationDropdown';

const AdditionalInformation = ({
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();
  const { state } = useStore();
  const { configPurpose, configSourcesOfWealth } = state;
  const { isFxAsGuest, isDisabled } = useFxAddTransaction();

  return (
    <AdditionalInformationStyled $isFxAsGuest={isFxAsGuest}>
      <Label>{t('add_transaction_title_additional_information')}</Label>
      <CustomOccupationDropdown
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        isDisabled={isDisabled}
      />
      <CustomSearchDropdown
        field={FX_ADD_TRANSACTION_PURPOSE_FIELD}
        isConfigRequired={true}
        data={configPurpose}
        keyFind="key"
        keyTranslate="value"
        noResultText="form_text_no_result"
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        isDisabled={isDisabled}
      />
      <CustomSearchDropdown
        field={FX_ADD_TRANSACTION_SOURCE_FIELD}
        isConfigRequired={true}
        data={configSourcesOfWealth}
        keyFind="key"
        keyTranslate="value"
        noResultText="form_text_no_result"
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        isDisabled={isDisabled}
      />
    </AdditionalInformationStyled>
  );
};

const AdditionalInformationStyled = styled.div`
  margin-top: ${(props) => (props.$isFxAsGuest ? 0 : '16px')};
  margin-bottom: ${(props) => (props.$isFxAsGuest ? '40px' : 0)};

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: ${(props) => (props.$isFxAsGuest ? 0 : '24px')};
  }
`;

const Label = styled.p`
  ${paragraphMixin};
  font-weight: 500;
  color: var(--ds-c-blue);
  margin-bottom: 16px;
`;

export default AdditionalInformation;
