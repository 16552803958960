import CosineSimilarity from './cosineSimilarity.js'

const SimpleNameMatcher = {

  prefixes: ['mr', 'ms', 'miss', 'mrs', 'mx', 'master', 'sir', 'madam', 'dame', 'lord', 'lady', 'dr', 'prof', 'br', 'sr', 'fr', 'rev', 'pr', 'elder'],
  suffixed: ['jr', 'sr', '2', 'ii', 'iii', 'iv', 'v', 'senior', 'junior'],
  postnominals: ['phd', 'ph.d.', 'ph.d', 'esq', 'esquire', 'apr', 'rph', 'pe', 'md', 'ma', 'dmd', 'cme', 'dds', 'cpa', 'dvm'],
  exact: 100.00,
  strong: 80.00,
  medium: 70.00,
  weak: 60.00,

  stripPrefixes (fullname) {
    let fn = fullname
    const n = this.trim(fullname).split(' ')
    const firstWord = n[0]
    if (this.prefixes.includes(firstWord)) {
      fn = fullname.replace(firstWord, '')
    }
    return fn
  },

  stripSuffixes (fullname) {
    let fn = fullname
    const n = this.trim(fullname).split(' ')
    const lastWord = n[n.length - 1]

    if (this.suffixed.includes(lastWord)) {
      fn = fn.replace(lastWord, '')
    }
    if (this.postnominals.includes(lastWord)) {
      fn = fn.replace(lastWord, '')
    }
    return fn
  },

  toLowerCase (fullname) {
    return fullname ? fullname.toLowerCase() : ''
  },

  trim (fullname) {
    return fullname ? fullname.replace(/\s{2,}/g, ' ').trim() : fullname
  },

  clean (fullname) {
    // to lowercase
    let fn = this.toLowerCase(fullname)
    // remove special chars
    fn = fn.replace(/[^a-zA-Z -]/g, '')
    // remove hyphen
    fn = fn.replace(/[-]/g, ' ')
    // strip prefixes
    fn = this.stripPrefixes(fn)
    // strip suffixes
    fn = this.stripSuffixes(fn)
    // trim spaces
    fn = this.trim(fn)

    return fn
  },

  getFirstName (fullname) {
    const n = fullname.split(' ')
    return n[0]
  },

  getMiddleName (fullname) {
    return this.trim(fullname.substring(fullname.indexOf(' '), fullname.lastIndexOf(' ')))
  },

  getLastName (fullname) {
    const n = fullname.split(' ')
    return n[n.length - 1]
  },

  getFirstMiddle (fullname) {
    return fullname.substring(0, fullname.lastIndexOf(' '))
  },

  getInitials (fullname) {
    if (!fullname) {
      return null
    }
    return fullname.match(/\b(\w)/g).join(' ')
  },

  isInitial (name) {
    const words = name.split(' ')
    for (let i = 0; i < words.length; i += 1) {
      if (words[i].length === 1) {
        return true
      }
    }
    return false
  },

  checkMiddleName (name, firstMiddle) {
    const words = firstMiddle.split(' ')
    for (let i = 0; i < words.length; i += 1) {
      if (words[i] === name) {
        return true
      }
    }
    return false
  },

  checkInitial (initials1, initials2) {
    const init1 = initials1.split(' ')
    const init2 = initials2.split(' ')

    for (let i = 0; i < init2.length; i += 1) {
      if (!init1.includes(init2[i])) {
        return false
      }
    }
    return true
  },

  scores (word1, word2) {
    return word1 && word2 ? CosineSimilarity.score(word1, word2) : 0.00
  },
  
  correctScore (fScore, mScore, fmScore, lScore, iScore, i1, i2, m1, m2, checkMiddleName) {
    const firstNameScore = (fScore * 20) / 100
    const lastNameScore = (lScore * 50) / 100
    let firstMiddleScore = 0
    let middleNameScore = 0
    let initialScore = 0

    if (fmScore === 100) { // Case 1: first & middle matched 100%
      firstMiddleScore = (fmScore * 30) / 100
    } else if (m2 === '' || m2 === null) { // Case 2: CC no middle name
      middleNameScore = 10
      if (firstNameScore === 0 && checkMiddleName === true) { // fname not match, m2 == f1
        firstMiddleScore = 20
      }
    } else if (iScore === 100 && i2.length === i1.length && this.isInitial(m2)) { // Case 3: Middle name on card are initials, all matched
      initialScore = (iScore * 10) / 100

      if (firstNameScore === 0 && checkMiddleName === true) { // fname not match, f2 == m1
        firstMiddleScore = 20
      }

      if (i1 === i2) { // first & middle name are initials
        firstMiddleScore = 20
      }
    } else if (iScore !== 100 && iScore !== 0 && i2.length <= i1.length && this.isInitial(m2)) { // Case 4: Middle name on card are initials, not matched
      if (this.checkInitial(i1, i2) !== false) {
        initialScore = 10
      } else {
        initialScore = (iScore * 10) / 100
      }
    }
    return firstNameScore + middleNameScore + firstMiddleScore + lastNameScore + initialScore
  },

  match (fullname, fullname2) {
    const fn = this.clean(fullname)
    const target = this.clean(fullname2)

    // FULL NAME
    const fm1 = this.getFirstMiddle(fn)
    const f1 = this.getFirstName(fn)
    const m1 = this.getMiddleName(fn)
    const l1 = this.getLastName(fn)

    // NAME ON CARD
    const fm2 = this.getFirstMiddle(target)
    const f2 = this.getFirstName(target)
    const m2 = this.getMiddleName(target)
    const l2 = this.getLastName(target)

    const i1 = this.getInitials(fm1)
    const i2 = this.getInitials(fm2)

    const fmScore = this.scores(fm1, fm2)
    const fScore = this.scores(f1, f2)
    const mScore = this.scores(m1, m2)
    const lScore = this.scores(l1, l2)
    const iScore = this.scores(i1, i2)

    const checkMiddleName = this.checkMiddleName(f2, fm1)

    return this.correctScore(fScore, mScore, fmScore, lScore, iScore, i1, i2, m1, m2, checkMiddleName)
  }

}

export default SimpleNameMatcher
