import {
  EDIT_RECEIVER_POSTAL_NAME,
  EDIT_RECEIVER_STATE_NAME,
  EDIT_RECEIVER_STREET_NAME,
  EDIT_RECEIVER_SUBURB_NAME,
} from 'constants';
import useGetConfig from 'hooks/useGetConfig';
import CustomCitiesDropdown from '../CustomCitiesDropdown';
import CustomSuburbsDropdown from '../CustomSuburbsDropdown';
import FormAddressInputValidation from '../FormAddressInputValidation';
import FormInputPortalCodeNumberValidation from '../FormInputPortalCodeNumberValidation';

const FormInputAddressManuallyValidation = ({
  field,
  countryConfig,
  cities,
  information,
  setInformation,
  validation,
  setValidation,
  portalCodeMaskStr,
  setPortalCodeMaskStr,
}) => {
  const { isEditReceiverEnterAddressManuallySelected } = information;

  const config = useGetConfig({ countryConfig });
  const { addressStreet, addressState, addressSuburb, addressPostcode } =
    config;

  const renderFieldReference = {
    [EDIT_RECEIVER_STREET_NAME]: addressStreet?.visible ? (
      <FormAddressInputValidation
        field={field}
        isConfigRequired={addressStreet?.required}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
    ) : null,
    [EDIT_RECEIVER_STATE_NAME]: addressState?.visible ? (
      <CustomCitiesDropdown
        field={field}
        isConfigRequired={addressState?.required}
        cities={cities}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
    ) : null,
    [EDIT_RECEIVER_SUBURB_NAME]: addressSuburb?.visible ? (
      <CustomSuburbsDropdown
        field={field}
        isConfigRequired={addressSuburb?.required}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
    ) : null,
    [EDIT_RECEIVER_POSTAL_NAME]: addressPostcode?.visible ? (
      <FormInputPortalCodeNumberValidation
        field={field}
        config={addressPostcode}
        numberMaskStr={portalCodeMaskStr}
        setNumberMaskStr={setPortalCodeMaskStr}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
    ) : null,
  };

  const renderFieldManuallyReference = {
    [EDIT_RECEIVER_STREET_NAME]: addressStreet?.visible ? (
      <FormAddressInputValidation
        field={field}
        isConfigRequired={addressStreet?.required}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
    ) : null,
    [EDIT_RECEIVER_STATE_NAME]: addressState?.visible ? (
      <FormAddressInputValidation
        field={field}
        isConfigRequired={addressState?.required}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
    ) : null,
    [EDIT_RECEIVER_SUBURB_NAME]: addressSuburb?.visible ? (
      <FormAddressInputValidation
        field={field}
        isConfigRequired={addressSuburb?.required}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
    ) : null,
    [EDIT_RECEIVER_POSTAL_NAME]: addressPostcode?.visible ? (
      <FormInputPortalCodeNumberValidation
        field={field}
        config={addressPostcode}
        numberMaskStr={portalCodeMaskStr}
        setNumberMaskStr={setPortalCodeMaskStr}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
    ) : null,
  };

  return (
    <>
      {isEditReceiverEnterAddressManuallySelected
        ? renderFieldManuallyReference[field.name]
        : renderFieldReference[field.name]}
    </>
  );
};

export default FormInputAddressManuallyValidation;
