export const CURRENCY_NAME_REFERENCE = {
  AED: 'UNITED ARAB EMIRATES DIRHAM',
  ARS: 'ARGENTINE PESO',
  AUD: 'AUSTRALIAN DOLLAR',
  BBD: 'BARBADIAN DOLLAR',
  BDT: 'BANGLADESHI TAKA',
  BMD: 'BERMUDIAN DOLLAR',
  BND: 'BRUNEI DOLLAR',
  BRL: 'BRAZILIAN REAL',
  BSD: 'BAHAMIAN DOLLAR',
  CAD: 'CANADIAN DOLLAR',
  CHF: 'SWISS FRANC',
  CLP: 'CHILEAN PESO',
  CNY: 'CHINESE YUAN RENMINBI',
  COP: 'COLOMBIAN PESO',
  CZK: 'CZECH KORUNA',
  DKK: 'DANISH KRONE',
  EGP: 'EGYPTIAN POUND',
  EUR: 'EURO',
  FJD: 'FIJIAN DOLLAR',
  GBP: 'BRITISH POUND',
  GHS: 'GHANAIAN CEDI',
  HKD: 'HONG KONG DOLLAR',
  HRK: 'CROATIAN KUNA',
  HUF: 'HUNGARIAN FORINT',
  IDR: 'INDONESIAN RUPIAH',
  ILS: 'ISRAEL SHEKEL',
  INR: 'INDIAN RUPEE',
  ISK: 'ICELANDIC KRONA',
  JOD: 'JORDANIAN DINAR',
  JPY: 'JAPANESE YEN',
  KES: 'KENYAN SHILLING',
  KHR: 'CAMBODIAN RIEL',
  KRW: 'KOREAN WON',
  KWD: 'KUWAITI DINAR',
  LAK: 'LAOTIAN KIP',
  LKR: 'SRI LANKAN RUPEE',
  MAD: 'MOROCCAN DIRHAM',
  MMK: 'MYANMAR KYAT',
  MNT: 'MONGOLIAN TUGRIK',
  MOP: 'MACAU PATACA',
  MUR: 'MAURITIAN RUPEE',
  MXN: 'MEXICAN PESO',
  MYR: 'MALAYSIAN RINGGIT',
  NOK: 'NORWEGIAN KRONE',
  NPR: 'NEPALESE RUPEE',
  NZD: 'NEW ZEALAND DOLLAR',
  OMR: 'OMANI RIAL',
  PGK: 'PAPUA NEW GUINEAN KINA',
  PHP: 'PHILIPPINE PESO',
  PKR: 'PAKISTANI RUPEE',
  PLN: 'POLISH ZLOTY',
  QAR: 'QATARI RIYAL',
  RON: 'ROMANIAN LEU',
  RUB: 'RUSSIAN RUBLE',
  SAR: 'SAUDI ARABIAN RIYAL',
  SBD: 'SOLOMON ISLANDS DOLLAR',
  SEK: 'SWEDISH KRONA',
  SGD: 'SINGAPORE DOLLAR',
  SOL: 'PERUVIAN SOL',
  THB: 'THAI BAHT',
  TOP: 'TONGAN PAANGA',
  TRY: 'TURKISH LIRA',
  TWD: 'TAIWAN DOLLAR',
  UGX: 'UGANDAN SHILLING',
  USD: 'US DOLLAR',
  VND: 'VIETNAM DONG',
  VUV: 'VANUATU VATY',
  WST: 'SAMOAN TALA',
  XPF: 'FRENCH PACIFIC FRANC',
  ZAR: 'SOUTH AFRICAN RAND',
};
