import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import styled from 'styled-components';

const AccountIncorrectInform = () => {
  const { t } = useLang();

  return (
    <AccountIncorrectInformStyled>
      {t('login_bad_credentials')}
    </AccountIncorrectInformStyled>
  );
};

const AccountIncorrectInformStyled = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: var(--ds-c-grey-dark);
  padding: 10px 16px;
  width: 100%;
  height: fit-content;
  background: var(--bg-info);
  border-radius: 12px;
  margin-bottom: -16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    margin-bottom: 24px;
  }
`;

export default AccountIncorrectInform;
