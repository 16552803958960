import { useContext } from 'react';
import { store } from 'store/fx';

const useFxStore = () => {
  const context = useContext(store);
  const { state, dispatch } = context || {};

  /**
   * @param {{
   * action
   * payload
   * }}
   */
  const setState = ({ action = '', payload = null }) => {
    if (action) {
      dispatch({ type: action, payload });
    }
  };

  return { state, setState, dispatch };
};

export default useFxStore;
