import { api } from 'api';
import { dispatchStore } from 'store';
import { SET_EVENTS } from 'store/action';
import { arrayUtils } from 'utils';

export const handleGetEventsAvailable = async (token) => {
  const { data } = await api.getEventsAvailable(token);

  if (data && !arrayUtils.isArrayEmpty(data)) {
    dispatchStore({ type: SET_EVENTS, payload: data });
  }
};

export const handleGetAllEvents = async (token, cb = null) => {
  const { data } = await api.getAllEvents(token);

  if (data && !arrayUtils.isArrayEmpty(data)) {
    cb && cb(data);
  }
};

export const handleGetAllEventsPublic = async (cb = null) => {
  try {
    const { data } = await api.getEventsAvailablePublic();

    if (data && !arrayUtils.isArrayEmpty(data)) {
      dispatchStore({ type: SET_EVENTS, payload: data });

      cb && cb(data);
    }
  } catch (error) {
    console.error(error?.message);
  }
};
