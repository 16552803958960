import FormInputValidation from 'components/FormLogIn/components/FormInputValidation';
import ButtonCommon from 'components/common/ButtonCommon';
import { checkFormInputValidation } from 'components/common/func';
import {
  DESKTOP_MIN_WIDTH,
  LOGIN_EMAIL_NAME,
  LOGIN_FIELDS,
  LOGIN_PASSWORD_NAME,
  LOGIN_UNAUTHORIZED_INFORM,
  RESET_LOGIN_INFORMATION,
} from 'constants';
import { AS_GUEST, AS_REGISTER } from 'constants/fx';
import {
  FORGOT_PASSWORD_PREFIX,
  FX_TRANSACTION_ADD_PREFIX,
} from 'constants/router';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useAuth from 'hooks/useAuth';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from 'react-router-dom';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';

const initialField = RESET_LOGIN_INFORMATION;

const FormFxLogIn = () => {
  const { t } = useLang();
  const { dispatch } = useStore();
  const { login } = useAuth();
  const { isMobile, isTablet, isDesktop } = useDetectDevice();
  const navigate = useNavigate();

  const [information, setInformation] = useState(initialField);
  const [validation, setValidation] = useState(initialField);
  const [error, setError] = useState('');

  const { guestEmail, guestMobile } = useFxAddTransaction();
  const {
    setAddTransactionAsGuest,
    setConfirmInformationEmailMobile,
    setAddTransactionCurrentStep,
  } = useFxFormAddTransactionStore();

  const urlRedirect = `${FX_TRANSACTION_ADD_PREFIX}?as=${AS_REGISTER}`;
  const urlRedirectAsGuest = `${FX_TRANSACTION_ADD_PREFIX}?as=${AS_GUEST}`;

  useEffect(() => {
    if (information[LOGIN_EMAIL_NAME] || !guestEmail) return;
    const newInformation = {
      ...information,
      [LOGIN_EMAIL_NAME]: guestEmail,
    };
    setInformation(newInformation);

    const emailField = document.getElementsByName(LOGIN_EMAIL_NAME)?.[0];
    if (emailField) {
      emailField.value = guestEmail;
    }
  }, []);

  const handleSubmitError = (error) => {
    setError(error?.response?.data?.message?.toLowerCase());
    dispatch({ type: SET_SHOW_LOADING, payload: false });
  };
  const handleSubmit = async () => {
    let newValidation = { ...validation };

    LOGIN_FIELDS.forEach((field) => {
      newValidation = checkFormInputValidation(
        information[field.name],
        field,
        newValidation
      );
    });

    setValidation(newValidation);

    const isAllowSubmit =
      Object.values(information).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowSubmit) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      try {
        const error = await login(
          information[LOGIN_EMAIL_NAME],
          information[LOGIN_PASSWORD_NAME],
          urlRedirect
        );

        if (isEmpty(error)) {
          setAddTransactionAsGuest(false);
          setAddTransactionCurrentStep(1);
        }

        handleSubmitError(error);
      } catch (error) {
        console.error(error?.message);
        handleSubmitError(error);
      }
    }
  };

  const handleSkip = () => {
    setAddTransactionAsGuest(true);
    setAddTransactionCurrentStep(1);
    setConfirmInformationEmailMobile(guestEmail, guestMobile);
    navigate(urlRedirectAsGuest);
  };

  return (
    <FormFxLogInStyled>
      {LOGIN_FIELDS.map((field) => (
        <FormInputValidation
          key={field.name}
          field={field}
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          error={error}
          setError={setError}
        />
      ))}
      {LOGIN_UNAUTHORIZED_INFORM[error]}

      <ForgotPasswordSubmit>
        <LinkForgotPasswordStyled to={FORGOT_PASSWORD_PREFIX}>
          {t('button_forgot_password')}
        </LinkForgotPasswordStyled>
      </ForgotPasswordSubmit>
      <NextWrap>
        <ButtonCommon
          value={t('button_skip')}
          onClick={handleSkip}
          styles={{
            margin: '0px',
            width: '50%',
          }}
          color="var(--ds-c-blue)"
          background="var(--ds-c-white)"
          borderNameVariable={isDesktop && '--border-filter'}
          isFill={false}
        />
        <ButtonCommon
          value={t('button_login')}
          styles={{
            margin: '0px',
            width: '50%',
          }}
          onClick={handleSubmit}
          isUseKeyDown
          color="var(--c-primary)"
          background="var(--bg-primary)"
          isFill={true}
        />
      </NextWrap>
    </FormFxLogInStyled>
  );
};

const FormFxLogInStyled = styled(Form)`
  position: relative;
  width: 100%;
  height: calc(100% - 96px);
`;

const LinkForgotPasswordStyled = styled(Link)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;

  color: var(--ds-c-blue-hyperlink-default);
  display: block;

  &:hover {
    color: var(--ds-c-blue-hyperlink-default-hover) !important;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ForgotPasswordSubmit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-inline: -24px;
  padding-inline: 24px;
  background: var(--ds-c-white);
  z-index: 1;
  position: fixed;
  bottom: 0;
  border-top: 1px solid #eef2f5;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    height: 40px;
    border-top: unset;
    gap: 24px;
  }
`;

export default FormFxLogIn;
