import React from 'react';
import styled from 'styled-components';
import EventTicketsCarousel from '../components/EventTicketsCarousel';

const EventSectionMobile = ({ data }) => {
  return (
    <>
      <ContentBody>
        <EventTicketsCarousel data={data} />
      </ContentBody>
    </>
  );
};

const ContentBody = styled.div`
  width: 100%;
  height: fit-content;
`;

export default EventSectionMobile;
