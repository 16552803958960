import {
  DASHBOARD_PREFIX,
  EXCHANGE_NAV,
  FX_DASHBOARD_PREFIX,
  FX_TRANSACTIONS_PREFIX,
  ORDER_EMAIL,
  RECEIVERS_PREFIX,
  REMITTANCE_MOBILE_NAV,
  TRANSACTIONS_PREFIX,
} from 'constants';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import { FX_MAKE_PAYMENT_KEY } from 'hooks/fx/useFxAddTransaction';
import useLang from 'hooks/useLang';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const CollapsibleSideMenu = () => {
  const { t } = useLang();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [navs, setNavs] = useState([]);

  const isDashboardPage = pathname.includes(DASHBOARD_PREFIX);
  const isReceiversPage = pathname.includes(RECEIVERS_PREFIX);
  const isTransactionsPage = pathname.includes(TRANSACTIONS_PREFIX);
  // const isEditReceiverPage = pathname.includes(RECEIVERS_EDIT_SUFIX);
  // const isAddReceiverPage = pathname.includes(RECEIVERS_ADD_SUFIX);
  // const isAddTransactionPage = pathname === `${TRANSACTION_ADD_PREFIX}`;
  // const isEventPage = pathname === `${EVENT_PREFIX}`;
  const isFxDashboardPage = pathname.includes(FX_DASHBOARD_PREFIX);
  const isFxTransactionsPage = pathname.includes(FX_TRANSACTIONS_PREFIX);

  const isNavLinkActive = (href) => pathname.includes(href);

  useEffect(() => {
    if (isDashboardPage || isReceiversPage || isTransactionsPage) {
      setNavs(REMITTANCE_MOBILE_NAV);
    } else if (isFxDashboardPage || isFxTransactionsPage) {
      setNavs(EXCHANGE_NAV);
    } else {
      setNavs([]);
    }
  }, [pathname]);

  return (
    <NavLinks>
      {navs.map((nav) => (
        <NavLink
          key={nav.href}
          to={nav.href}
          onClick={() => {
            Cookies.remove(MAKE_PAYMENT_KEY);
            Cookies.remove(FX_MAKE_PAYMENT_KEY);
            Cookies.remove(ORDER_EMAIL);
            navigate(nav.href);
          }}
          $isActive={isNavLinkActive(nav.href)}
        >
          <span>{t(nav.text) || null}</span>
        </NavLink>
      ))}
    </NavLinks>
  );
};

export default CollapsibleSideMenu;

const NavLinks = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  padding: 5px;
  background-color: rgba(212, 220, 229, 0.15);
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: var(--ds-c-blue);
  margin-bottom: 16px;
  border-radius: 12px;
`;

const NavLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  border-radius: 6px;
  text-decoration: none;
  cursor: pointer;

  ${(props) =>
    props.$isActive
      ? `
        background-color: var(--ds-c-white);
        box-shadow: var(--ds-bs-4);
        color: var(--ds-c-blue-dark);
        font-weight: 700;
      `
      : ``};
`;
