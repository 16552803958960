import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import { DESKTOP_MIN_WIDTH, RESET_BACKDROP_STORE } from 'constants';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import React, { useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { SET_SHOW_BACKDROP } from 'store/action';
import styled from 'styled-components';

const SEARCH_BY_REFERENCE = {
  refNumber: 'Reference',
  receiverName: 'Receiver',
};

const SEARCH_BY_LABEL_TRANSLATE = {
  refNumber: 'label_ref_number',
  receiverName: 'label_receiver',
};

const TRANSACTION_SEARCH_DROPDOWN_NAME = 'transaction-search-dropdown-field';

const CustomSearchDropdown = ({
  searchBy,
  setSearchBy,
  setSearchValue,
  setSearched,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { backdrop } = state;

  useEffect(() => {
    return () => {
      dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSearchBy = (keySearch) => {
    setSearchValue('');

    setSearched(false);

    setSearchBy(keySearch);

    dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });
  };

  const handleDropdownOnToggle = () => {
    dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <ButtonToggle
      href="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);

        dispatch({
          type: SET_SHOW_BACKDROP,
          payload: {
            isShow: !backdrop?.isShow,
            current: TRANSACTION_SEARCH_DROPDOWN_NAME,
          },
        });
      }}
    >
      {children}&nbsp;&nbsp;
      <ArrowDownIcon
        color="var(--ds-c-grey-dark)"
        styles={{
          marginRight: '8px',
          transform:
            backdrop?.current === TRANSACTION_SEARCH_DROPDOWN_NAME &&
            backdrop?.isShow &&
            'rotate(180deg)',
        }}
      />
    </ButtonToggle>
  ));

  return (
    <SearchDropdownStyled>
      <Dropdown onToggle={handleDropdownOnToggle}>
        <Dropdown.Toggle as={CustomToggle}>
          {t(SEARCH_BY_LABEL_TRANSLATE[searchBy])}
        </Dropdown.Toggle>

        <DropdownMenu>
          {Object.keys(SEARCH_BY_REFERENCE).map((key, index) => (
            <DropdownItem
              key={index}
              eventKey={index}
              onClick={() => handleChangeSearchBy(key)}
            >
              {t(SEARCH_BY_LABEL_TRANSLATE[key])}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </SearchDropdownStyled>
  );
};

const SearchDropdownStyled = styled.div`
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;

  & .dropdown {
    padding: 10px 0px;
  }
`;

const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  height: fit-content;
  min-width: fit-content;
  box-shadow: var(--ds-bs-4);
  border-radius: 4px;
  inset: 0px 0px auto auto !important;
  transform: translate(0px, 44px) !important;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    transform: translate(0px, 44px) !important;
  }
`;
const DropdownItem = styled(Dropdown.Item)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px 8px;
  text-transform: capitalize;
  width: 115px;
  min-height: 40px;
  height: fit-content;
  white-space: break-spaces;
  background: var(--ds-c-white);

  &:hover {
    background: var(--ds-c-grey-light);
  }

  &:active {
    color: var(--ds-c-grey-dark);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const ButtonToggle = styled.a`
  font-family: var(--ff-primary);
  line-height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--ds-c-grey-dark);
  text-decoration: none;

  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
  padding: 0;
  min-width: 104px;
  height: 24px;
  border-right: 1px solid var(--ds-c-grey-hover);

  &:hover {
    color: var(--ds-c-grey-hover);

    & svg {
      opacity: 0.5;
    }
  }

  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }
`;

export default CustomSearchDropdown;
