import RadioCheckedIcon from 'assets/icons/radio-checked-icon.svg';
import RadioboxHHMTIcon from 'assets/icons/radiobox-hhmt-icon.svg';
import RadioboxRemoxIcon from 'assets/icons/radiobox-remox-icon.svg';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useTheme from 'hooks/useTheme';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const FormInputRadioCheckbox = ({ isChecked, onClick }) => {
  const { isHHMTTheme } = useTheme();

  const handleOnChange = () => {
    onClick && onClick();
  };

  return (
    <FormCheckStyled
      type="checkbox"
      label=""
      checked={isChecked}
      onChange={handleOnChange}
      $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
      readOnly={true}
    />
  );
};

const FormCheckStyled = styled(Form.Check)`
  margin: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding-left: 24px;
    margin-inline: 0px;
    margin-bottom: 4px;
  }

  label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    margin-left: 5.5px;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: ${(props) => `url(${props.$checkboxSrc}) !important`};
    width: 16px;
    height: 16px;
    cursor: pointer;
    background-color: transparent;

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }
  }

  input:checked[type='checkbox'] {
    background-image: url(${RadioCheckedIcon}) !important;
  }
`;

export default FormInputRadioCheckbox;
