/* eslint-disable no-unused-vars */
import ClockIcon from 'assets/ver2/icons/clock-small-black-icon.svg';
import LocationIcon from 'assets/ver2/icons/location-outline-black-icon.svg';
import LoginIcon from 'assets/ver2/icons/login-blue-icon.svg';
import LoginIconGreen from 'assets/ver2/icons/login-icon.svg';
import { PORTAL_NAME_MODAL_DEFINE } from 'components/common/PortalModal/config';
import SelectPickupLocationDrawer from 'components/fx/components/SelectPickupLocationDrawer';
import { DESKTOP_MIN_WIDTH } from 'constants';
import {
  FX_ADD_TRANSACTION_PICKUP_LOCATION_TYPE,
  PICKUP_LOCATION_TYPE_1,
  PICKUP_LOCATION_TYPE_2,
  PICKUP_LOCATION_TYPE_REFERENCE,
  RESET_FX_ADD_TRANSACTION_PICKUP_LOCATION,
} from 'constants/fx';
import { format } from 'helpers';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useLang from 'hooks/useLang';
import usePortalModal from 'hooks/usePortalModal';
import React from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';

const PickupLocationDetails = ({
  branches,
  information,
  setInformation,
  setTriggerOpenSelectPickupLocationDrawer,
}) => {
  const { t } = useLang();

  const { showPortalModalName } = usePortalModal();

  const {
    getPickupLocationFullAddress,
    getPickupLocationOpeningHours,
    getPickupLocationName,
  } = useFxAddTransaction();
  const {
    pickupLocationSelected,
    pickupLocationType,
    setPickupLocation,
    checkPickupLocationTypeDisable,
  } = useFxFormAddTransactionStore();

  const handleOnChangePickupLocationType = () => {
    let newPickupLocation = RESET_FX_ADD_TRANSACTION_PICKUP_LOCATION;

    if (pickupLocationType === PICKUP_LOCATION_TYPE_1) {
      const message = t('popup_change_pickup_location_by_ea_message', {
        min: format.toAmountCentStr(
          PICKUP_LOCATION_TYPE_REFERENCE[PICKUP_LOCATION_TYPE_2].collect.min
        ),
        max: format.toAmountCentStr(
          PICKUP_LOCATION_TYPE_REFERENCE[PICKUP_LOCATION_TYPE_2].collect.max
        ),
      });
      showPortalModalName({
        name: PORTAL_NAME_MODAL_DEFINE.CHANGE_PICKUP_LOCATION_TYPE_MODAL,
        title: '',
        content: message,
        action: () => {
          newPickupLocation = {
            ...newPickupLocation,
            [FX_ADD_TRANSACTION_PICKUP_LOCATION_TYPE]: PICKUP_LOCATION_TYPE_2,
          };

          setInformation(newPickupLocation);

          setPickupLocation(newPickupLocation);

          setTriggerOpenSelectPickupLocationDrawer(true);
        },
        actionClose: () => {},
      });
      return;
    }

    if (pickupLocationType === PICKUP_LOCATION_TYPE_2) {
      const message = t('popup_change_pickup_location_by_aus_message');
      showPortalModalName({
        name: PORTAL_NAME_MODAL_DEFINE.CHANGE_PICKUP_LOCATION_TYPE_MODAL,
        title: '',
        content: message,
        action: () => {
          newPickupLocation = {
            ...newPickupLocation,
            [FX_ADD_TRANSACTION_PICKUP_LOCATION_TYPE]: PICKUP_LOCATION_TYPE_1,
          };

          setInformation(newPickupLocation);

          setPickupLocation(newPickupLocation);

          setTriggerOpenSelectPickupLocationDrawer(true);
        },
        actionClose: () => {},
      });
    }
  };
  const renderChangePickupLocationTypeButton = () => {
    if (pickupLocationType === PICKUP_LOCATION_TYPE_1) {
      const isPickupLocationByAPDisabled = checkPickupLocationTypeDisable(
        PICKUP_LOCATION_TYPE_REFERENCE[PICKUP_LOCATION_TYPE_2]
      );
      if (isPickupLocationByAPDisabled) return null;
    }

    return (
      <ChangePickupLocationWrap
        style={{ marginTop: '16px' }}
        onClick={handleOnChangePickupLocationType}
      >
        <ChangePickupLocationDrawer $color="var(--ds-c-green-default)">
          <img
            style={{ transform: 'rotate(180deg)' }}
            src={LoginIconGreen}
            width={16}
            height={16}
          />
          <p>
            {pickupLocationType === PICKUP_LOCATION_TYPE_1
              ? t('label_change_pickup_location_by_ap')
              : t('label_change_pickup_location_by_ea')}
          </p>
        </ChangePickupLocationDrawer>
      </ChangePickupLocationWrap>
    );
  };

  const renderPickupLocationAddress = (item) => {
    const fullAddress = getPickupLocationFullAddress(item);

    return (
      <FieldWrap
        styles={{
          alignItems: 'start',
        }}
        style={{
          marginTop: 0,
        }}
      >
        <FieldIcon>
          <img src={LocationIcon} width={16} height={16} alt="" />
        </FieldIcon>
        <FieldValue style={{ textTransform: 'initial' }}>
          {t('label_pickup_at')}:
          <br />
          <p>{getPickupLocationName(item, pickupLocationType)}</p>
          <span>{fullAddress || '-'}</span>
          <div style={{ height: '16px' }} />
          <SelectPickupLocationDrawer
            information={information}
            setInformation={setInformation}
            pickupLocationData={branches}
            pickupLocationType={pickupLocationType}
            NavBarToggle={
              <ChangePickupLocationWrap>
                <ChangePickupLocationDrawer>
                  <p>{t('label_change_pickup_location')}</p>
                  <img src={LoginIcon} width={16} height={16} />
                </ChangePickupLocationDrawer>
              </ChangePickupLocationWrap>
            }
          />
        </FieldValue>
      </FieldWrap>
    );
  };
  const renderPickupLocationOpeningHours = (item) => {
    const openingHoursMockup = getPickupLocationOpeningHours(item);

    return (
      <FieldWrap
        styles={{
          alignItems: 'start',
        }}
        style={{
          marginTop: '10px',
        }}
      >
        <FieldIcon>
          <img src={ClockIcon} width={16} height={16} alt="" />
        </FieldIcon>
        <FieldValue style={{ textTransform: 'initial' }}>
          {t('label_opening_hours')}
          <br />
          <span dangerouslySetInnerHTML={{ __html: openingHoursMockup }} />
        </FieldValue>
      </FieldWrap>
    );
  };

  return (
    <Wrap>
      <PickupLocationDetailsWrap>
        {renderPickupLocationAddress(pickupLocationSelected)}
        {renderPickupLocationOpeningHours(pickupLocationSelected)}
      </PickupLocationDetailsWrap>
      {renderChangePickupLocationTypeButton()}
    </Wrap>
  );
};

const Wrap = styled.div`
  padding-bottom: 50px;
  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding-bottom: 0px;
  }
`;

const PickupLocationDetailsWrap = styled.div`
  width: 100%;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
`;

const FieldWrap = styled.div`
  display: flex;
  align-items: ${(props) => props?.styles?.alignItems || 'center'};
  padding: 0px;
  justify-content: start;
  width: ${(props) => (props.$isInline ? '207px' : '100%')};
  height: fit-content;
  min-height: 40px;
  float: ${(props) => props.$isFloatLeft && 'left'};
  margin-top: 16px;
  margin-right: ${(props) => props.$isFloatLeft && '32px'};
`;
const FieldIcon = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  justify-content: start;
  align-items: center;
  width: fit-content;
  margin-top: 2px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FieldValue = styled.p`
  ${paragraphMixin};
  font-weight: 600;
  text-transform: uppercase;
  color: var(--ds-c-grey-dark);

  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 4px;

  & p {
    color: var(--ds-c-blue);
    margin: 0;
    margin-top: 8px;
    font-weight: 700;
  }

  & span {
    text-transform: initial;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    color: var(--ds-c-grey-dark);
    margin-top: 8px;
  }
`;

const ChangePickupLocationWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;
const ChangePickupLocationDrawer = styled.div`
  border-radius: 100px;
  border: ${(props) =>
    props.$color
      ? `1px solid ${props.$color}`
      : '1px solid var(--ds-c-blue-hyperlink-default)'};
  display: flex;
  align-item: center;
  gap: 2px;
  padding-inline: 6px;
  cursor: pointer;

  & p {
    ${paragraphMixin};
    font-size: 12px;
    line-height: 15px;
    color: ${(props) => props.$color || 'var(--ds-c-blue-hyperlink-default)'};
    font-weight: 600;
  }
`;

export default PickupLocationDetails;
