/* eslint-disable no-unused-vars */
import NoticeIcon from 'assets/icons/notice-yellow-icon.svg';
import DiscountsDrawer from 'components/Discount/components/DiscountsDrawer';
import FormPaymentByDeposit from 'components/FormAddTransaction/components/FormPaymentByDeposit';
import FormPaymentByDepositMobile from 'components/FormAddTransaction/components/FormPaymentByDeposit/mobile/ver2';
import ButtonCommon from 'components/common/ButtonCommon';
import InformPopup from 'components/common/InformPopup';
import { checkFormInputValidation } from 'components/common/func';
import CalculatorForm from 'components/fx/FxFormAddTransaction/CalculatorForm';
import {
  ActionWrapper,
  ButtonCommonWrap,
  DiscountWrapper,
} from 'components/fx/FxFormAddTransaction/ConfirmInformation';
import FormTransactionSummary from 'components/fx/FxFormAddTransaction/FormTransactionSummary';
import FxMakePayment from 'components/fx/FxFormAddTransaction/FxMakePayment';
import { NextWrap } from 'components/fx/FxFormAddTransaction/PaymentMethod';
import TransactionSummary from 'components/fx/FxFormAddTransaction/TransactionSummary';
import BodySectionHeader from 'components/fx/components/BodySectionHeader';
import FormTransactionDetails from 'components/fx/components/FormTransactionDetails';
import {
  DESKTOP_MIN_WIDTH,
  FX_TRANSACTION_ADD_PREFIX,
  HOME_URL,
  NAV_MENUS_UNAUTHENTICATE,
  ORDER_EMAIL,
  RESET_PAYMENT_BY_DEPOSIT_STORE,
  SCREEN_ID_EXCHANGE_STEP_6,
} from 'constants';
import {
  AS_GUEST,
  FX_ADD_TRANSACTION_UPLOAD_ID_DOCUMENT_UPLOAD_DRIVER_LICENCE_FIELDS,
  FX_ADD_TRANSACTION_UPLOAD_ID_DOCUMENT_UPLOAD_PASSPORT_FIELD,
  FX_ADD_TRANSACTION_UPLOAD_ID_DRIVER_LICENCE_FRONT_NAME,
  FX_ADD_TRANSACTION_UPLOAD_ID_PASSPORT_NAME,
  RESET_FX_ADD_TRANSACTION_DOCUMENT_UPLOAD,
} from 'constants/fx';
import Cookies from 'helpers/cookies';
import useFxAddTransaction, {
  FX_MAKE_PAYMENT_KEY,
} from 'hooks/fx/useFxAddTransaction';
import useFxDiscount from 'hooks/fx/useFxDiscount';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useFxPayment from 'hooks/fx/useFxPayment';
import useAuth from 'hooks/useAuth';
import useCommonStore from 'hooks/useCommonStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useEvent from 'hooks/useEvent';
import useLang from 'hooks/useLang';
import useScrollIntoViewDelay from 'hooks/useScrollIntoViewDelay';
import useScrollToTop from 'hooks/useScrollToTop';
import useTheme from 'hooks/useTheme';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import UploadDetails from './UploadDetails';

const initialField = RESET_FX_ADD_TRANSACTION_DOCUMENT_UPLOAD;
const initialFormCard = RESET_PAYMENT_BY_DEPOSIT_STORE;

const SCREEN_ID = SCREEN_ID_EXCHANGE_STEP_6;

const DocumentUpload = ({
  handleOnToggleCancelPopup,
  handleOnViewPickupLocation,
}) => {
  const { t, locate } = useLang();
  const { theme } = useTheme();
  const { isMobile, isTablet, isDesktop } = useDetectDevice();
  const { token } = useAuth();
  const navigate = useNavigate();

  const [information, setInformation] = useState(initialField);
  const [validation, setValidation] = useState(initialField);
  const [isShowDiscountInvalidPopup, setShowDiscountInvalidPopup] =
    useState(false);
  const [isShowTransactionDetails, setShowTransactionDetails] = useState(false);
  const [isShowFormPaymentByDeposit, setShowFormPaymentByDeposit] =
    useState(false);
  const [transactionInformation, setTransactionInformation] = useState(null);
  const [cardInformation, setCardInformation] = useState(initialFormCard);
  const [cardValidation, setCardValidation] = useState(initialFormCard);
  const [errorCode, setErrorCode] = useState('');
  const [isShowReceiptDetails, setShowReceiptDetails] = useState(false);

  const { isDriverLicenceSelected } = information;

  const {
    addTransactionCurrentStep: currentStep,
    setAddTransactionCurrentStep,
    transactionDetails,
    setDocumentUpload,
    discountApplied,
    discountsAvailable,
    discountsInactive,
    discountDefault,
    isHideDiscountSection,
    currencyOrders,
    confirmInformationEmail,
  } = useFxFormAddTransactionStore();
  const {
    isDisabled,
    isMakePayment,
    orderRef,
    isCreateNewTran,
    fxAddTransactionTotalNumberOfSteps,
    getTitleBodySection,
    isFxAsGuest,
  } = useFxAddTransaction();
  const { handleOnPayProcess, handleOnPayByDepositProcess } = useFxPayment();
  const {
    fetchFxDiscountsAvailable,
    handleApplyFxDiscount,
    handleRemoveFxDiscountApplied,
  } = useFxDiscount();
  const { isLoading } = useCommonStore();

  const isNextDisabled = false;

  const isPaymentSuccessfully =
    currentStep === fxAddTransactionTotalNumberOfSteps;

  const getDocuments = (information) =>
    Object.keys(information)
      ?.map((key) => information[key]?.document)
      ?.filter(Boolean);

  useScrollToTop();

  // scroll to calculator form
  const validRef = useRef(null);
  useScrollIntoViewDelay(validRef, validRef, []);

  useEffect(() => {
    const isAllowFetchDiscountsAvailable =
      !isDisabled &&
      currencyOrders?.length &&
      isEmpty(discountApplied) &&
      isEmpty(discountsAvailable) &&
      isEmpty(discountsInactive);
    if (isAllowFetchDiscountsAvailable) {
      fetchFxDiscountsAvailable({
        token,
      });
    }
  }, [
    isDisabled,
    currencyOrders,
    discountApplied,
    discountsAvailable,
    discountsInactive,
  ]);

  useEffect(() => {
    if (isLoading || errorCode) return;
    const isAutoTriggerApplyDiscount =
      !isDisabled &&
      currencyOrders?.length &&
      !isEmpty(discountDefault) &&
      isEmpty(discountApplied);
    if (isAutoTriggerApplyDiscount) {
      handleApplyFxDiscount({
        token,
        item: discountDefault,
        onApplyError: handleApplyDiscountError,
      });
    }
  }, [isLoading, isDisabled, currencyOrders, discountDefault, discountApplied]);

  const handleApplyDiscountError = (errorCode) => {
    setErrorCode(errorCode);
    if (isEmpty(discountDefault)) {
      setShowDiscountInvalidPopup(true);
    }
  };
  const renderDiscountSection = () => {
    if (isHideDiscountSection) return null;
    return (
      <DiscountWrapper>
        <DiscountsDrawer onApplyError={handleApplyDiscountError} isFx={true} />
      </DiscountWrapper>
    );
  };

  const handleOnCloseFormPaymentByDeposit = () => {
    setShowFormPaymentByDeposit(false);

    setCardValidation(initialFormCard);

    Cookies.set(FX_MAKE_PAYMENT_KEY, true);

    navigate(
      `${FX_TRANSACTION_ADD_PREFIX}?as=${AS_GUEST}&&orderRef=${
        transactionInformation?.orderRef || orderRef
      }&&email=${confirmInformationEmail}`
    );
  };

  const handleOnCloseTransactionDetailsPopup = () => {
    setShowTransactionDetails(false);
  };

  const handleBackStep = () => {
    setDocumentUpload(information);
    setAddTransactionCurrentStep(currentStep - 1);
  };
  const handleNextStep = async () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    const documents = getDocuments(information);

    delete newInformation['isDriverLicenceSelected'];
    delete newValidation['isDriverLicenceSelected'];

    if (isDriverLicenceSelected) {
      delete newInformation[FX_ADD_TRANSACTION_UPLOAD_ID_PASSPORT_NAME];
      delete newValidation[FX_ADD_TRANSACTION_UPLOAD_ID_PASSPORT_NAME];
    }

    if (!isDriverLicenceSelected) {
      delete newInformation[
        FX_ADD_TRANSACTION_UPLOAD_ID_DRIVER_LICENCE_FRONT_NAME
      ];
      delete newValidation[
        FX_ADD_TRANSACTION_UPLOAD_ID_DRIVER_LICENCE_FRONT_NAME
      ];
    }

    const isAllowSubmit = isMakePayment
      ? true
      : Object.values(newInformation).every((value) => value) &&
        Object.values(newValidation).every((value) => !value) &&
        documents?.length;

    if (isAllowSubmit) {
      try {
        if (isCreateNewTran) {
          setDocumentUpload(information);
        }
        setShowTransactionDetails(true);
      } catch (error) {
        console.error(error?.message);
      }
    }

    if (!isAllowSubmit) {
      let fields = [];

      if (isDriverLicenceSelected) {
        fields =
          FX_ADD_TRANSACTION_UPLOAD_ID_DOCUMENT_UPLOAD_DRIVER_LICENCE_FIELDS;
      }

      if (!isDriverLicenceSelected) {
        fields = FX_ADD_TRANSACTION_UPLOAD_ID_DOCUMENT_UPLOAD_PASSPORT_FIELD;
      }

      fields.forEach((field) => {
        newValidation = checkFormInputValidation(
          information[field.name],
          field,
          newValidation
        );
      });

      setValidation(newValidation);
    }
  };
  const renderCalculatorFormMobile = () => {
    if (isDesktop) return null;
    if (isMakePayment)
      return (
        <CalculatorForm
          ref={validRef}
          isCalculatorFormAccordion={true}
          isCalculatorFormAccordionExpandable={true}
          isExpandDefault={true}
          handleOnViewPickupLocation={handleOnViewPickupLocation}
        />
      );
    return (
      <CalculatorForm
        ref={validRef}
        isCalculatorFormAccordion={true}
        handleOnViewPickupLocation={handleOnViewPickupLocation}
      />
    );
  };
  const renderActionsButton = () => {
    return (
      <NextWrap>
        {renderCalculatorFormMobile()}
        <ActionWrapper>
          {!isMakePayment && (
            <ButtonCommonWrap>
              <ButtonCommon
                value={t('button_back')}
                onClick={handleBackStep}
                styles={{
                  margin: '0px',
                  width: !isDesktop && '100%',
                }}
                color="var(--ds-c-blue)"
                background="var(--ds-c-white)"
                borderNameVariable={isDesktop && '--border-filter'}
                isFill={false}
              />
            </ButtonCommonWrap>
          )}
          <ButtonCommon
            value={t('button_review_and_pay')}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              margin: '0px',
              width: isDesktop ? '150px' : isMakePayment ? '100%' : '50%',
            }}
            onClick={handleNextStep}
            isDisabled={false}
            isUseKeyDown={!isNextDisabled}
          />
        </ActionWrapper>
      </NextWrap>
    );
  };

  const handleCloseDiscountInvalidPopup = () => {
    setShowDiscountInvalidPopup(false);

    if (isDesktop && !errorCode) {
      const discountDrawerCloseBtn =
        document.getElementsByClassName('btn-close')[0];

      if (discountDrawerCloseBtn) {
        discountDrawerCloseBtn.click();
      }
    }

    if (errorCode) {
      setErrorCode('');

      fetchFxDiscountsAvailable({
        token,
      });
    }

    if (isShowTransactionDetails) {
      handleRemoveFxDiscountApplied();
      handleOnCloseTransactionDetailsPopup();
    }
  };
  const renderDiscountInvalidPopup = (isOpen) => {
    return (
      <InformPopup
        isOpen={isOpen}
        imageSrc={NoticeIcon}
        imagesStyles={{
          width: '124px',
          height: '100px',
          marginBottom: '8px',
        }}
        text={t('label_important')}
        content={t(
          errorCode
            ? `popup_discount_invalid_error_msg_${errorCode.toLowerCase()}`
            : `popup_discount_invalid_error_msg`
        )}
        alignContent="center"
        buttonLabel2={t('button_ok')}
        onClick={handleCloseDiscountInvalidPopup}
        isBackdropOverlap={true}
        zIndexBackdropOverlap={1055}
      />
    );
  };

  const handleOnBackToTransactionSummary = () => {
    if (!isFxAsGuest) return;
    setShowReceiptDetails(false);
  };
  const handleOnNextToViewReceiptDetails = () => {
    if (!isFxAsGuest) return;
    setShowReceiptDetails(true);
  };
  const handleOnBackToHome = () => {
    if (!isFxAsGuest) return;
    Cookies.remove(FX_MAKE_PAYMENT_KEY);
    Cookies.remove(ORDER_EMAIL);
    window.location.href = `${HOME_URL[theme]}${NAV_MENUS_UNAUTHENTICATE[1].href}?lang=${locate}`;
  };

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [],
    modalStyles: {},
    modalOptions: {},
  });
  useEffect(() => {
    if (isShowReceiptDetails) return;
    if (isPaymentSuccessfully) return;
    if (isMakePayment) return;
    if (!isShowEvent) return;
    showEventModal();
  }, [isShowEvent, isShowReceiptDetails, isPaymentSuccessfully, isMakePayment]);

  if (isShowReceiptDetails)
    return (
      <>
        {!isEmpty(transactionDetails) && (
          <FormTransactionDetails
            isOpen={!isEmpty(transactionDetails)}
            onClose={null}
            onClick={handleOnBackToTransactionSummary}
            isReceipt={true}
          />
        )}
      </>
    );
  if (isPaymentSuccessfully)
    return (
      <TransactionSummary
        transactionInformation={transactionInformation}
        cardInformation={cardInformation}
        handleOnNextToViewReceiptDetails={handleOnNextToViewReceiptDetails}
        handleOnBackToHome={handleOnBackToHome}
      />
    );
  if (isMakePayment)
    return (
      <>
        {isShowTransactionDetails && (
          <FormTransactionSummary
            isOpen={isShowTransactionDetails}
            onClose={handleOnCloseTransactionDetailsPopup}
            onClick={() =>
              handleOnPayProcess({
                transactionInformation,
                setTransactionInformation,
                setShowTransactionDetails,
                setShowFormPaymentByDeposit,
                setErrorCode,
                setShowDiscountInvalidPopup,
              })
            }
          />
        )}
        {isShowFormPaymentByDeposit && (
          <>
            {isMobile || isTablet ? (
              <FormPaymentByDepositMobile
                isFx={true}
                isFxAsGuest={isFxAsGuest}
                isOpen={isShowFormPaymentByDeposit}
                transactionInformation={transactionInformation}
                cardInformation={cardInformation}
                setCardInformation={setCardInformation}
                cardValidation={cardValidation}
                setCardValidation={setCardValidation}
                onClose={handleOnCloseFormPaymentByDeposit}
                onClick={(urlRedirect) =>
                  handleOnPayByDepositProcess(
                    urlRedirect,
                    transactionInformation,
                    handleOnCloseFormPaymentByDeposit
                  )
                }
              />
            ) : (
              <FormPaymentByDeposit
                isFx={true}
                isFxAsGuest={isFxAsGuest}
                isOpen={isShowFormPaymentByDeposit}
                transactionInformation={transactionInformation}
                cardInformation={cardInformation}
                setCardInformation={setCardInformation}
                cardValidation={cardValidation}
                setCardValidation={setCardValidation}
                onClose={handleOnCloseFormPaymentByDeposit}
                onClick={(urlRedirect) =>
                  handleOnPayByDepositProcess(
                    urlRedirect,
                    transactionInformation,
                    handleOnCloseFormPaymentByDeposit
                  )
                }
              />
            )}
          </>
        )}
        {isShowDiscountInvalidPopup &&
          renderDiscountInvalidPopup({
            isOpen: isShowDiscountInvalidPopup,
          })}

        <BodySectionHeader
          titleBodySection={getTitleBodySection()}
          titleSection={t('add_transaction_title_payment_method')}
          CalculatorComponent={isDesktop && <CalculatorForm />}
          onToggleCancelPopup={handleOnToggleCancelPopup}
          onViewPickupLocation={handleOnViewPickupLocation}
        />
        <FxMakePayment
          renderActionsButton={renderActionsButton}
          renderDiscountSection={renderDiscountSection}
        />
      </>
    );
  return (
    <>
      {isShowTransactionDetails && (
        <FormTransactionSummary
          isOpen={isShowTransactionDetails}
          onClose={handleOnCloseTransactionDetailsPopup}
          onClick={() => {
            handleOnPayProcess({
              transactionInformation,
              setTransactionInformation,
              setShowTransactionDetails,
              setShowFormPaymentByDeposit,
              setErrorCode,
              setShowDiscountInvalidPopup,
            });
          }}
        />
      )}
      {isShowFormPaymentByDeposit && (
        <>
          {isMobile || isTablet ? (
            <FormPaymentByDepositMobile
              isFx={true}
              isFxAsGuest={isFxAsGuest}
              isOpen={isShowFormPaymentByDeposit}
              transactionInformation={transactionInformation}
              cardInformation={cardInformation}
              setCardInformation={setCardInformation}
              cardValidation={cardValidation}
              setCardValidation={setCardValidation}
              onClose={handleOnCloseFormPaymentByDeposit}
              onClick={(urlRedirect) =>
                handleOnPayByDepositProcess(
                  urlRedirect,
                  transactionInformation,
                  handleOnCloseFormPaymentByDeposit
                )
              }
            />
          ) : (
            <FormPaymentByDeposit
              isFx={true}
              isFxAsGuest={isFxAsGuest}
              isOpen={isShowFormPaymentByDeposit}
              transactionInformation={transactionInformation}
              cardInformation={cardInformation}
              setCardInformation={setCardInformation}
              cardValidation={cardValidation}
              setCardValidation={setCardValidation}
              onClose={handleOnCloseFormPaymentByDeposit}
              onClick={(urlRedirect) =>
                handleOnPayByDepositProcess(
                  urlRedirect,
                  transactionInformation,
                  handleOnCloseFormPaymentByDeposit
                )
              }
            />
          )}
        </>
      )}
      {isShowDiscountInvalidPopup &&
        renderDiscountInvalidPopup({
          isOpen: isShowDiscountInvalidPopup,
        })}

      <BodySectionHeader
        titleBodySection={getTitleBodySection()}
        titleSection={t('fx_add_transaction_identifications')}
        CalculatorComponent={isDesktop && <CalculatorForm />}
        onToggleCancelPopup={handleOnToggleCancelPopup}
        onViewPickupLocation={handleOnViewPickupLocation}
      />
      <DocumentUploadStyled>
        <UploadDetails
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
        />
        {renderDiscountSection()}
        {renderActionsButton()}
      </DocumentUploadStyled>
    </>
  );
};

const DocumentUploadStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -8px;
  padding-inline: 0px;
  padding-bottom: 204px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 676px;
    margin-top: 0px;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

export default DocumentUpload;
