import { useEffect } from 'react';
import { useDetectDevice } from './useDetectDevice';

const useInputFocus = (name) => {
  const { isDesktop } = useDetectDevice();

  useEffect(() => {
    const searchEle = document.getElementsByName(name)[0];

    if (isDesktop && searchEle) {
      searchEle.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);
};

export default useInputFocus;
