import { EN_LANG, I18N_REDIRECTED } from 'constants';
import Cookies from 'helpers/cookies';
import { useTranslation } from 'react-i18next';
import { domUtils } from 'utils';

const useLang = () => {
  const locate =
    localStorage?.getItem(I18N_REDIRECTED) ||
    Cookies.get(I18N_REDIRECTED) ||
    domUtils.getQueryWithName('lang') ||
    EN_LANG;

  const { t } = useTranslation(locate);

  return { t, locate };
};

export default useLang;
