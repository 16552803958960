/* eslint-disable no-unused-vars */
import { api } from 'api';
import { LogUtil } from 'components/common/LogUtil';
import {
  ACCESS_TOKEN,
  HEADER_AGENT_CODE_REFERENCE,
  HHMT_THEME,
  PUSH_API_STATUS,
} from 'constants';
import { getAuth } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getMessaging, getToken, isSupported } from 'firebase/messaging';
import Cookies from 'helpers/cookies';
import IndexedDB from 'helpers/indexedDB';
import sw from 'helpers/sw';
import { useContext, useEffect, useState } from 'react';
import { SET_SHOW_LOADING } from 'store/action';
import {
  FIREBASE_MESSAGING_DB_NAME,
  FIREBASE_MESSAGING_STORE_NAME,
  FIREBASE_MESSAGING_SW,
  firebaseConfig,
} from 'store/firebase/config';
import { firebaseContext } from '../store/firebase';
import useLang from './useLang';
import useStore from './useStore';

const TAG = 'firebase';

const accessToken = Cookies.get(ACCESS_TOKEN);
const pushApiStatus = Cookies.get(PUSH_API_STATUS);

export const getRealtimeDB = (app) => getDatabase(app);

export const auth = (app) => getAuth(app);

/* notification helpers */
export const getPathNotificationsBySenderId = (senderId, suffix = '') =>
  `/${senderId}/${
    HEADER_AGENT_CODE_REFERENCE[process.env.REACT_APP_APP_NAME || HHMT_THEME]
  }${suffix}`;
export const requestNotifyPermission = (app, vapidKey, cb) => {
  isSupported()
    .then((isSupported) => {
      if (isSupported) {
        Notification.requestPermission()
          .then((permission) => {
            if (permission === 'granted') {
              LogUtil.log(TAG, 'Notification permission granted!');

              const messaging = getMessaging(app);

              getToken(messaging, {
                vapidKey,
              })
                .then((currentToken) => {
                  if (currentToken) {
                    cb(currentToken);
                  } else {
                    LogUtil.log(TAG, "Can't get device token!");
                  }
                })
                .catch((error) => console.error(error?.message));
            } else {
              LogUtil.log(TAG, 'Notification has blocked!');
            }
          })
          .catch((error) => {
            console.error(error?.message);
          });
      } else {
        LogUtil.log(TAG, `Notification doesn't supported!`);
      }
    })
    .catch((error) => {
      console.error(error?.message);
    });
};

/* exchange rate helpers */
export const getPathExchangRate = (currencyCode, agentCode) => {
  if (currencyCode && agentCode) return `/${currencyCode}/${agentCode}/`;

  return `/`;
};

/* service alert helpers */
export const getPathServiceAlerts = (path) => {
  if (path) return `/${path}`;

  return `/`;
};

const useFirebase = () => {
  const context = useContext(firebaseContext);
  const { firebaseAppDefault } = context || {};
  const { locate } = useLang();
  const { dispatch } = useStore();

  const [deviceTokenStore, setDeviceTokenStore] = useState('');

  const getDeviceTokenStore = async () => {
    if (IndexedDB.isSupport()) {
      const fmsDatas = await IndexedDB.get(
        FIREBASE_MESSAGING_DB_NAME,
        FIREBASE_MESSAGING_STORE_NAME
      );

      if (fmsDatas?.length) {
        const { token } = fmsDatas[0];

        setDeviceTokenStore(token);
      }
    }
  };
  useEffect(() => {
    getDeviceTokenStore();
  }, []);

  const saveDeviceToken = async ({
    token = accessToken,
    isActive = false,
    lang = locate,
    deviceToken = deviceTokenStore,
  }) => {
    try {
      if (token && deviceToken) {
        await api.saveDeviceToken(
          token,
          deviceToken,
          lang?.toUpperCase(),
          isActive
        );
      }
      dispatch({ type: SET_SHOW_LOADING, payload: false });
    } catch (error) {
      console.error(error?.message);
      dispatch({ type: SET_SHOW_LOADING, payload: false });
    }
  };

  const requestPushApiSubcribe = (app) => {
    sw.register(firebaseConfig, FIREBASE_MESSAGING_SW);

    isSupported()
      .then((isSupported) => {
        if (isSupported) {
          Notification.requestPermission()
            .then((permission) => {
              if (permission === 'granted') {
                const messaging = getMessaging(app);

                getToken(messaging, {
                  vapidKey: process.env.REACT_APP_NOTIFICATION_VAPID_KEY,
                })
                  .then((deviceToken) => {
                    LogUtil.log(TAG, 'Notification has subcribed!');

                    if (
                      (deviceToken &&
                        deviceTokenStore &&
                        deviceToken !== deviceTokenStore) ||
                      (deviceToken && pushApiStatus === 'denied')
                    ) {
                      requestNotifyPermission(
                        firebaseAppDefault,
                        process.env.REACT_APP_NOTIFICATION_VAPID_KEY,
                        (deviceToken) => {
                          saveDeviceToken({
                            isActive: true,
                            deviceToken,
                          });

                          Cookies.remove(PUSH_API_STATUS);
                        }
                      );
                    }
                  })
                  .catch((error) => {
                    console.error(error?.message);
                  });
              } else {
                LogUtil.log(TAG, 'The user has blocked Notification!');
                if (Notification?.permission === 'denied') {
                  if (accessToken) {
                    saveDeviceToken({ isActive: false });
                  }
                  sw.unregister();
                }
              }
            })
            .catch((error) => {
              console.error(error?.message);
            });
        } else {
          LogUtil.log(TAG, `Notification doesn't supported!`);
        }
      })
      .catch((error) => {
        console.error(error?.message);
      });
  };

  return {
    firebaseAppDefault,
    requestPushApiSubcribe,
    saveDeviceToken,
  };
};

export default useFirebase;
