export const Fade = `
-webkit-animation: fade 0.6s ease-out;
animation: fade 0.6s ease-out;
@keyframes fade {
from {
opacity: 0;
}
to {
opacity: 1;
}
}
`;

export const SlideOutLeft = `
-webkit-animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
animation: slide-out-left 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
@-webkit-keyframes slide-out-left {
0% {
-webkit-transform: translateX(0);
transform: translateX(0);
opacity: 1;
}
100% {
-webkit-transform: translateX(-1000px);
transform: translateX(-1000px);
opacity: 0;
}
}
@keyframes slide-out-left {
0% {
-webkit-transform: translateX(0);
transform: translateX(0);
opacity: 1;
}
100% {
-webkit-transform: translateX(-1000px);
transform: translateX(-1000px);
opacity: 0;
}
}
`;
