import EventIcon from 'assets/ver2/icons/event-color-icon.svg';
import { handleGetAllEvents } from 'components/Events/func';
import {
  DESKTOP_MIN_WIDTH,
  DISCOUNT_ACTIVE,
  DISCOUNT_COMING_SOON,
  DISCOUNT_EXPIRED,
  DISCOUNT_FULLY_REDEEMED,
  DISCOUNT_REDEEMED,
  EVENT,
  SCREEN_ID_OFFER_EVENTS,
} from 'constants';
import useAuth from 'hooks/useAuth';
import useEvent from 'hooks/useEvent';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import DiscountsTab from '../DiscountsTab';
import EventSection from '../Offers/components/EventSection';

const SCREEN_ID = SCREEN_ID_OFFER_EVENTS;

const Events = () => {
  const { t } = useLang();
  const { token } = useAuth();

  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (!token) return;
    if (!isEmpty(events)) return;
    handleGetAllEvents(token, (data) => {
      setEvents(data);
    });
  }, [token, events]);

  const { isShowEvent, showEventModal } = useEvent({
    screenId: SCREEN_ID,
    params: [],
    modalStyles: {},
    modalOptions: {},
  });
  useEffect(() => {
    if (!isShowEvent) return;
    showEventModal();
  }, [isShowEvent]);

  if (isEmpty(events))
    return (
      <EventsStyled $isCenter={true}>
        <DiscountsTab items={[]} type={EVENT} />
      </EventsStyled>
    );
  return (
    <>
      <EventsStyled>
        <EventSection
          title={t('label_offers_running')}
          descIcon={EventIcon}
          descText={t('label_events_event_desc')}
          offers={events?.filter((e) => e?.status === DISCOUNT_ACTIVE)}
          emptyText={t('label_events_no_event_active_desc')}
        />
        <EventSection
          title={t('label_offers_status_coming_soon')}
          offers={events?.filter((e) => e?.status === DISCOUNT_COMING_SOON)}
          emptyText={t('label_events_no_event_coming_desc')}
        />
        <EventSection
          title={t('label_offers_status_expired')}
          offers={events?.filter(
            (e) =>
              e?.status === DISCOUNT_REDEEMED ||
              e?.status === DISCOUNT_EXPIRED ||
              e?.status === DISCOUNT_FULLY_REDEEMED
          )}
          isShowDivider={false}
          emptyText={t('label_events_no_event_past_event_desc')}
        />
      </EventsStyled>
    </>
  );
};

const EventsStyled = styled.div`
  display: inline-block;
  width: 100%;
  height: fit-content;

  ${(props) =>
    props.$isCenter &&
    `
  height: calc(100vh - 248px);
  display: flex;
  align-items: center;
  justify-content: center;
  `}

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    ${(props) =>
      props.$isCenter &&
      `
    height: calc(100vh - 144px);
    `}
  }
`;

export default Events;
