import React from 'react';

const CircleRoundCutHorizontal = ({ top, right }) => {
  return (
    <div
      style={{
        width: '20px',
        height: '20px',
        borderRadius: '50px',
        backgroundColor: 'white',
        position: 'absolute',
        top: top || '10px',
        right: right || '-10px',
        border: '2px dashed var(--ds-c-green-default)',
      }}
    >
      <div
        style={{
          width: '12px',
          height: '44px',
          backgroundColor: 'white',
          position: 'absolute',
          top: top || -14,
          right: right || -6,
        }}
      />
    </div>
  );
};

export default CircleRoundCutHorizontal;
