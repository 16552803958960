const TransactionOutlineIcon = ({ color = 'var(--ds-c-white)', size }) => {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 3.00001V3.00001C19.6569 3.00001 21 4.34315 21 6.00001L21 8.14286C21 8.47698 21 8.64405 20.9234 8.76602C20.8834 8.82962 20.8296 8.8834 20.766 8.92336C20.644 9 20.477 9 20.1429 9L15 9M18 3.00001V3.00001C16.3431 3.00001 15 4.34315 15 6.00001L15 9M18 3.00001L7 3.00001C5.11438 3.00001 4.17157 3.00001 3.58579 3.58579C3 4.17158 3 5.11439 3 7.00001L3 21L6 20L9 21L12 20L15 21L15 9"
        stroke={color || '#FBFBFB'}
        strokeWidth="1.5"
      />
      <path
        d="M7 7L11 7"
        stroke={color || '#FBFBFB'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8 11H7"
        stroke={color || '#FBFBFB'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7 15L10 15"
        stroke={color || '#FBFBFB'}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TransactionOutlineIcon;
