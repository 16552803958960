import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import styled from 'styled-components';

const DiscountAvailableSection = ({ isFx, quantity, onClick }) => {
  const { t } = useLang();

  return (
    <Section>
      <DiscountsAvailable onClick={onClick}>
        <Content>
          {`${t('form_placeholder_discount_select')} (${quantity} ${t(
            'discount_available_for_transaction'
          )})`}
        </Content>
        <ArrowDownIcon
          color="var(--ds-c-grey-dark)"
          size={{ width: 12, height: 10 }}
          styles={{
            transform: 'rotate(-90deg)',
          }}
        />
      </DiscountsAvailable>
    </Section>
  );
};

const Section = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  margin-bottom: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 437px;
  }
`;
const DiscountsAvailable = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 10px;
  border-radius: 12px;
  border: 1px solid var(--ds-bg-2);
  height: 44px;
  width: 100%;
  cursor: pointer;
`;
const Content = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue-disabled);
  margin-bottom: 0px;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
`;

export default DiscountAvailableSection;
