import {
  DESKTOP_MIN_WIDTH,
  FORGOT_PASSWORD_PREFIX,
  HHMT_EMAIL,
  HHMT_PHONE,
  REMOX_EMAIL,
  REMOX_PHONE,
} from 'constants';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import styled from 'styled-components';
import { stringUtils } from 'utils';

const FormAccountActivationExpiredMobile = () => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();

  return (
    <>
      <TitleH3>{t('account_activation_text_token_expired_title')}</TitleH3>
      <Paragraph
        style={{ marginBottom: '0px' }}
        dangerouslySetInnerHTML={{
          __html: stringUtils.replaceKeyword(
            t('account_activation_text_token_expired_desc'),
            [
              { key: 'href', value: FORGOT_PASSWORD_PREFIX },
              { key: 'email', value: isHHMTTheme ? HHMT_EMAIL : REMOX_EMAIL },
              { key: 'phone', value: isHHMTTheme ? HHMT_PHONE : REMOX_PHONE },
            ]
          ),
        }}
      />
    </>
  );
};

const TitleH3 = styled.h3`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 8px;
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: var(--ds-c-grey-dark);
  margin-bottom: 16px;

  & a {
    text-decoration: none;
    color: var(--ds-c-blue);

    &:first-child {
      font-weight: 700;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default FormAccountActivationExpiredMobile;
