export enum SERVICE_ALERTS_TYPE {
  DELAY = 'DELAY',
  DISABLE = 'DISABLE',
}

export interface MessageModel {
  en: string | null;
  vi: string | null;
  [key: string]: string | null;
}

export interface RangeOfCurrencyCodeModel {
  message: MessageModel | null;
  type: string | null;
}

export interface CurrencyCodeModel {
  message: MessageModel | null;
  type: string | null;
  range?: RangeOfCurrencyCodeModel | null;
}

export interface CurrenciesModel {
  [key: string]: CurrencyCodeModel | any;
}

export interface ServiceAlertsModel {
  message?: MessageModel | null;
  currencies?: CurrenciesModel | null;
  name?: string | null;
  type?: string | null;
  [key: string]: any;
}

export interface BankReceiverDetailModel {
  id: string;
  bankId?: string;
  bankName: string;
  accountName: string | null;
  accountNumber: string | null;
  branchCode: string | null;
  swiftCode: string | null;
  verifyBy: string | null;
  isVerified: boolean;
}
