/* eslint-disable no-unused-vars */
import HintIcon from 'assets/ver2/icons/hint-icon.svg';
import useLang from 'hooks/useLang';
import usePortalModal from 'hooks/usePortalModal';
import { isFunction } from 'lodash';
import { functionUtils } from 'utils';
import RemovePickupLocationByPayAmountChangePopup from '../popups/RemovePickupLocationByPayAmountChangePopup';

const RemovePickupLocationByPayAmountChangeModal = ({
  isShow,
  title,
  content,
  action,
  actionClose,
}) => {
  const { t } = useLang();
  const { closePortalModalName } = usePortalModal();

  const handleOnClick = () => {
    if (functionUtils.isFunction(action)) {
      action();
    }
    closePortalModalName();
  };

  const handleOnClose = () => {
    if (functionUtils.isFunction(actionClose)) {
      actionClose();
    }
    closePortalModalName();
  };

  return (
    <RemovePickupLocationByPayAmountChangePopup
      isOpen={isShow}
      imageSrc={HintIcon}
      imagesStyles={{
        width: '124px',
        height: '100px',
      }}
      text={title || ''}
      content={content || ''}
      buttonLabel1={isFunction(actionClose) ? t('button_review_order') : ''}
      onClose={handleOnClose}
      buttonLabel2={
        isFunction(actionClose) ? t('button_process') : t('button_yes')
      }
      onClick={handleOnClick}
      isBackdropOverlap={true}
      zIndexBackdropOverlap={1055}
    />
  );
};

export default RemovePickupLocationByPayAmountChangeModal;
