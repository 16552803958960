import { isProductionEnv, isStagingEnv } from 'constants';
import useCheckVersion from 'hooks/useCheckVersion';
import useLang from 'hooks/useLang';
import React from 'react';
import styled from 'styled-components';

const BuildVersionSection = () => {
  const { t } = useLang();
  const { currentVersion } = useCheckVersion();

  const envSuffix = isProductionEnv
    ? ''
    : isStagingEnv
    ? `_Staging`
    : `_Development`;

  return <Text>{`${t('label_version')}: ${currentVersion}${envSuffix}`}</Text>;
};

const Text = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);
  text-align: center;

  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 16px;
`;

export default BuildVersionSection;
