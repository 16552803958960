import { useEffect } from 'react';

const useScrollIntoViewDelay = (ref, isScroll, deps) => {
  useEffect(() => {
    const scrollTimer = setTimeout(() => {
      if (isScroll) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0.5 * 1000);

    return () => {
      clearTimeout(scrollTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useScrollIntoViewDelay;
