import { numberUtils } from 'utils';

const numberHelper = {
  removeCommaFromNumber: (amount) => {
    if (isNaN(Number(amount))) {
      return Number(0);
    }

    if (!amount) {
      return Number(amount);
    }

    const arr = amount.toString().split('');

    for (let i = 0; i < arr.length - 1; i++) {
      if (arr[i] === ',') {
        arr.splice(i, 1);
      }
    }

    return Number(arr.join(''));
  },
  removeFloat: (amount) => {
    if (isNaN(Number(amount))) {
      return Number(0);
    }

    if (!amount) {
      return Number(amount);
    }

    if (numberUtils.isFloat(amount)) {
      return parseInt(amount);
    }

    return Number(amount);
  },
  roundAudAmount: (amount) => {
    if (isNaN(Number(amount))) {
      return Number(0);
    }

    if (!amount) {
      return Number(amount);
    }

    amount = numberHelper.removeCommaFromNumber(amount);

    if (parseFloat(amount) > 0.03) {
      const arr = numberUtils
        .mathRound(parseFloat(amount))
        .toFixed(2)
        .split('');
      const lastDecimal = arr[arr.length - 1];
      const firstDecimal = arr[arr.length - 2];

      if (lastDecimal <= 3) {
        arr.splice(arr.length - 1, 1, 0);

        return Number(arr.join(''));
      } else if (lastDecimal > 3 && lastDecimal < 8) {
        arr.splice(arr.length - 1, 1, 5);

        return Number(arr.join(''));
      } else if (firstDecimal < 9) {
        arr.splice(arr.length - 2, 2, parseInt(firstDecimal) + 1, 0);

        return Number(arr.join(''));
      } else {
        return Math.round(amount);
      }
    } else {
      return Number(amount);
    }
  },
  roundVndAmount: (amount) => {
    if (isNaN(Number(amount))) {
      return Number(0);
    }

    if (!amount) {
      return Number(amount);
    }

    if (`${amount}`.length < 4) {
      return Number(amount);
    }

    amount = numberHelper.removeCommaFromNumber(amount);

    return Math.round(amount / 1000) * 1000;
  },
  roundVndAmount2: (amount) => {
    if (isNaN(Number(amount))) {
      return Number(0);
    }

    if (!amount) {
      return Number(amount);
    }

    if (`${amount}`.length < 4) {
      return 0;
    }

    const minRound = 50000;

    amount = Number(numberHelper.removeCommaFromNumber(amount));
    amount = Number(numberHelper.removeFloat(amount));

    const amountArr = parseInt(amount).toFixed().split('');
    const amountLength = parseInt(amount).toFixed().length;

    if (amount <= minRound) {
      return 0;
    }

    if (amount > minRound && amountLength === 5) {
      const tenOfThousandsNumber = Number(amountArr[amountLength - 5]);
      const thousandsNumber = Number(amountArr[amountLength - 4]);

      if (
        tenOfThousandsNumber > 5 ||
        (tenOfThousandsNumber === 5 && thousandsNumber > 0)
      ) {
        return 100000;
      }
    }

    if (amount > minRound && amountLength > 5) {
      const tenOfThousandsNumber = Number(amountArr[amountLength - 5]);
      const thousandsNumber = Number(amountArr[amountLength - 4]);

      if (
        tenOfThousandsNumber < 5 ||
        (tenOfThousandsNumber === 5 && thousandsNumber === 0)
      ) {
        return Number(amountArr.slice(0, -5).join('')) * 100000;
      }

      if (
        tenOfThousandsNumber > 5 ||
        (tenOfThousandsNumber === 5 && thousandsNumber > 0)
      ) {
        return (Number(amountArr.slice(0, -5).join('')) + 1) * 100000;
      }
    }

    return 0;
  },
};

export default numberHelper;
