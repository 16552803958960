import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';
import ButtonCommon from '../../../common/ButtonCommon';

const TableEstimatedPopup = ({ isOpen, onClose }) => {
  const { t } = useLang();
  return (
    <>
      <style>
        {`.table-estimated-backdrop {
          background: #212121;
          opacity: 0.25!important;
          display: initial!important;
        }`}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={onClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="table-estimated-backdrop"
        centered
      >
        <ModalBody>
          {t('add_transaction_bank_table_title')}
          <TableGrid>
            <GridItem
              className="option-1"
              dangerouslySetInnerHTML={{
                __html: t('label_receiving_amount'),
              }}
            />
            <GridItem
              className="option-1"
              dangerouslySetInnerHTML={{
                __html: t('add_transaction_bank_table_row_1_column_2'),
              }}
            />
            <GridItem
              className="option-1"
              dangerouslySetInnerHTML={{
                __html: t('add_transaction_bank_table_row_1_column_3'),
              }}
            />
            <GridItem className="option-2">
              {t('add_transaction_bank_table_row_2_column_1')}
            </GridItem>
            <GridItem
              className="option-2"
              dangerouslySetInnerHTML={{
                __html: t('add_transaction_bank_table_row_2_column_2'),
              }}
            />
            <GridItem
              className="option-2"
              dangerouslySetInnerHTML={{
                __html: t('add_transaction_bank_table_row_2_column_3'),
              }}
            />
            <GridItem className="option-3">
              {t('add_transaction_bank_table_row_3_column_1')}
            </GridItem>
            <GridItem className="option-3">
              {t('add_transaction_bank_table_row_3_column_2')}
            </GridItem>
          </TableGrid>
          <NoteText>{t('add_transaction_bank_table_note')}</NoteText>

          <ButtonCommon
            color="var(--c-primary)"
            background="var(--bg-primary)"
            value={t('button_close')}
            styles={{
              width: '100px',
              marginTop: '30px',
              float: 'right',
            }}
            onClick={onClose}
            isFill={true}
          />
        </ModalBody>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    max-width: fit-content;
    transform: none;
  }

  & .modal-content {
    padding: 24px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 866px;
    height: fit-content;
    margin-inline: auto;
    border: none;
  }
`;
const ModalBody = styled(Modal.Body)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  padding: 0;
  margin-bottom: 0px;
`;
const TableGrid = styled.table`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  color: var(--ds-c-grey-dark);
  margin: 0;
  margin-top: 16px;
  margin-bottom: 16px;

  width: 100%;
  display: grid;
  grid-gap: 4px;
  grid-template-columns: 33.33% 33.33% 33.33%;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const GridItem = styled.div`
  width: 100%;
  border-radius: 12px;
  padding: 10px;

  &.option-1 {
    background: var(--ds-c-grey-dark);
    text-transform: uppercase;
    color: #fff;

    & b {
      font-weight: 500;
      color: var(--bg-primary);
    }
  }

  &.option-2 {
    background: var(--ds-c-grey-light);
  }

  &.option-3 {
    background: var(--ds-c-grey-disabled);

    &:last-child {
      width: calc(200% + 4px);
    }
  }
`;
const NoteText = styled.span`
  color: var(--ds-c-red);
`;

export default TableEstimatedPopup;
