/* eslint-disable no-unused-vars */
import CustomCalculatorFormAccordion from 'components/fx/components/CustomCalculatorFormAccordion';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import { forwardRef } from 'react';
import styled from 'styled-components';

const CalculatorForm = forwardRef(
  (
    {
      isCalculatorFormAccordion = false,
      styleWrapper,
      isCalculatorFormAccordionExpandable = false,
      handleOnViewPickupLocation = () => {},
      isExpandDefault = false,
    },
    ref
  ) => {
    const { t } = useLang();

    const { getPaymentMethodIcon, parseAmountValue, renderDiscountFieldLabel } =
      useFxAddTransaction();
    const {
      currencyOrders,
      paymentMethodName,
      discountApplied,
      getServiceFeeAmount,
      getSurchargeAmount,
      getSubTotalAmount,
      getTotalPayAmount,
    } = useFxFormAddTransactionStore();

    const { discountAmount, type, code } = discountApplied || {};
    const isShowDiscountAmount = !isEmpty(discountApplied);

    const isShowPaymentMethod = Boolean(paymentMethodName);

    const sendTotalAmount = getSubTotalAmount();
    const isShowSendTotalAmount = Boolean(sendTotalAmount);

    const feeAmount = getServiceFeeAmount();
    const isShowFeeAmount = Boolean(feeAmount);

    const surchargeAmount = getSurchargeAmount();
    const isShowSurchargeField = Boolean(surchargeAmount);

    const totalPayAmount = getTotalPayAmount();

    if (isCalculatorFormAccordion) {
      return (
        <CustomCalculatorFormAccordion
          totalPayAmount={totalPayAmount}
          sendAmount={sendTotalAmount}
          feeAmount={feeAmount}
          isShowFeeAmount={isShowFeeAmount}
          isShowSurchargeField={isShowSurchargeField}
          surchargeAmount={surchargeAmount}
          discountAmount={discountAmount}
          discountApplied={discountApplied}
          isShowDiscountAmount={isShowDiscountAmount}
          handleOnViewPickupLocation={handleOnViewPickupLocation}
          isExpandable={isCalculatorFormAccordionExpandable}
          isExpandDefault={isExpandDefault}
          styleWrapper={styleWrapper}
        />
      );
    }

    return (
      <CalculatorFormStyled>
        <CalculatorDetails ref={ref}>
          <Label>{t('label_order_detail')}</Label>
          {isShowSendTotalAmount && (
            <CalculatorFieldWrap>
              <CalculatorFieldLabel>
                {t('label_sub_total')}
              </CalculatorFieldLabel>
              <CalculatorFieldValue>{`${parseAmountValue(
                sendTotalAmount
              )} AUD`}</CalculatorFieldValue>
            </CalculatorFieldWrap>
          )}
          {isShowFeeAmount && (
            <CalculatorFieldWrap>
              <CalculatorFieldLabel>
                {t('label_service_fee')}
              </CalculatorFieldLabel>
              <CalculatorFieldValue>{`${feeAmount} AUD`}</CalculatorFieldValue>
            </CalculatorFieldWrap>
          )}
          {isShowDiscountAmount && (
            <CalculatorFieldWrap>
              <CalculatorFieldLabel>
                {renderDiscountFieldLabel(type, code)}
              </CalculatorFieldLabel>
              <CalculatorFieldValue>
                -{`${discountAmount} AUD`}
              </CalculatorFieldValue>
            </CalculatorFieldWrap>
          )}
          {isShowPaymentMethod && (
            <CalculatorFieldWrap>
              <CalculatorFieldLabel>
                {t('button_pay_by')}
                <img
                  src={getPaymentMethodIcon()[paymentMethodName]}
                  height={20}
                />
              </CalculatorFieldLabel>
              {isShowSurchargeField && (
                <CalculatorFieldValue>
                  {`${t('label_surcharge')} ${surchargeAmount || 0} AUD`}
                </CalculatorFieldValue>
              )}
            </CalculatorFieldWrap>
          )}
        </CalculatorDetails>
        <CalculatorTotal>
          <CalculatorFieldWrap
            style={{
              height: '64px',
              borderBottom: '1px solid #ffffff',
              marginBottom: '13px',
            }}
          >
            <CalculatorTotalLabel>
              {t('label_total_to_pay')}
            </CalculatorTotalLabel>
            <CalculatorTotalValue>{`AUD ${parseAmountValue(
              totalPayAmount
            )}`}</CalculatorTotalValue>
          </CalculatorFieldWrap>
          <CalculatorFieldWrap>
            <Label
              style={{
                color: '#ffffff',
                marginBottom: '0px',
              }}
            >
              {t('label_buy_currency')}
            </Label>
            {!isEmpty(currencyOrders) && (
              <CurrenciesWrap>
                {currencyOrders?.map((order) => (
                  <CurrencyChip
                    key={order?.currentCurrencySelected?.currencyCode}
                  >
                    {order?.currentCurrencySelected?.currencyCode}
                  </CurrencyChip>
                ))}
              </CurrenciesWrap>
            )}
          </CalculatorFieldWrap>
        </CalculatorTotal>
      </CalculatorFormStyled>
    );
  }
);

const CalculatorFormStyled = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
`;

const CalculatorDetails = styled.div`
  margin-top: 0px;
  min-width: 437px;
  width: 437px;
  background: var(--ds-bg-2);
  border-radius: 8px;
  padding: 10px 16px;
`;
const Label = styled.p`
  margin: 0;
  margin-bottom: 10px;
  padding: 0;

  font-family: var(--ff-primary);
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  color: var(--ds-c-grey-dark);
  text-transform: uppercase;
`;
const CalculatorFieldWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const CalculatorFieldLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue);

  margin: 0;
  padding: 0;

  & span {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    &.discount-code {
      color: var(--ds-c-green-default);
    }
  }

  & img {
    margin-left: 4px;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    & span {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;
const CalculatorFieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: default;
  color: var(--ds-c-blue);

  margin: 0;
  padding: 0;

  & span {
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;

    background-color: var(--ds-c-green-default);
    padding: 2px 8px;
    border-radius: 2px;
    margin-right: 8px;
  }

  & b {
    font-weight: 500;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    & span {
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

const CalculatorTotal = styled.div`
  margin-top: 0px;
  width: 100%;
  background: var(--ds-bg-3);
  border-radius: 8px;
  padding: 10px 16px;
  display: grid;
  align-content: center;
`;
const CalculatorTotalLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-transform: uppercase;

  margin: 0;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const CalculatorTotalValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  color: #ffffff;

  margin: 0;
  padding: 0;
`;

const CurrenciesWrap = styled.div`
  display: flex;
  gap: 4px;
`;
const CurrencyChip = styled.span`
  height: 20px;
  padding-inline: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ds-c-blue-dark);
  border-radius: 100px;

  font-family: var(--ff-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: #fff;
`;

export default CalculatorForm;
