import WarnningIcon from 'assets/icons/alert-animation.gif';
import BusinessIcon from 'assets/icons/business-icon.svg';
import CountryHHMTIcon from 'assets/icons/country-hhmt-icon.svg';
import CountryRemoxIcon from 'assets/icons/country-remox-icon.svg';
import IndividualIcon from 'assets/icons/individual-icon.svg';
import LocationHHMTIcon from 'assets/icons/location-hhmt-icon.svg';
import LocationRemoxIcon from 'assets/icons/location-remox-icon.svg';
import MobileHHMTIcon from 'assets/icons/mobile-hhmt-icon.svg';
import MobileRemoxIcon from 'assets/icons/mobile-remox-icon.svg';
import PhoneHHMTIcon from 'assets/icons/phone-hhmt-icon.svg';
import PhoneRemoxIcon from 'assets/icons/phone-remox-icon.svg';
import SendIcon from 'assets/icons/send-icon.svg';
import {
  getFullNameReceiver,
  getNumberUnmaskStr,
} from 'components/FormEditReceiver/func';
import CustomActionDropdown from 'components/Receivers/components/CustomActionDropdown';
import {
  DESKTOP_MIN_WIDTH,
  GENDER_ICON_REFERENCE,
  GENDER_TRANSLATE_REFERENCE,
  METHOD_BANK,
  METHOD_EWALLET,
} from 'constants';
import { formatter } from 'helpers';
import useReceiver from 'hooks/receiver/useReceiver';
import useCountryConfig from 'hooks/useCountryConfig';
import useDeliveryMethods from 'hooks/useDeliveryMethods';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import Modal from 'react-bootstrap/Modal';
import { SET_CURRENT_RECEIVER } from 'store/action';
import styled from 'styled-components';
import ButtonCommon from '../../../common/ButtonCommon';
import CustomBanksAccordion from '../CustomBanksAccordion';
import CustomEwalletsAccordion from '../CustomEwalletsAccordion';
import FormInputFavoriteCheckbox from '../FormInputFavoriteCheckbox';

const FormReceiverDetails = ({ isOpen, onClose, onClick, onDelete }) => {
  const { state, dispatch } = useStore();
  const { currentReceiver } = state;
  const {
    receiverId,
    firstName,
    lastName,
    country,
    countryName,
    gender,
    fullAddress,
    mobileNumber,
    phoneNumber,
    banks,
    ewallets,
    favorited,
  } = currentReceiver;
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const { isReceiverOfIndividualType, isReceiverOfBusinessType } =
    useReceiver();

  const countryConfig = useCountryConfig(country?.code);
  const fullName = getFullNameReceiver({
    isReceiverOfIndividualType,
    isReceiverOfBusinessType,
    lastName,
    firstName,
    countryCode: currentReceiver?.country?.code,
  });

  const deliveryMethods = useDeliveryMethods(country?.code);

  const config = useGetConfig({ countryConfig });
  const { mobile, phone, addressPostcode } = config;

  const mobileFormat = formatter.formatParse(mobile?.format);
  const mobileNumberMask = formatter.mask(
    getNumberUnmaskStr(mobileNumber, mobileFormat),
    mobileFormat
  );

  const phoneFormat = formatter.formatParse(phone?.format);
  const phoneNumberMask = formatter.mask(
    getNumberUnmaskStr(phoneNumber, phoneFormat),
    phoneFormat
  );

  const checkReceiverSelectedMissingInformation = (receiver) => {
    if (
      !receiver.gender ||
      !receiver.fullAddress ||
      (addressPostcode?.visible && !currentReceiver.address?.postcode)
    ) {
      return true;
    }

    return false;
  };

  const renderReceiverNameForm = () => {
    if (isReceiverOfBusinessType)
      return (
        <FavoriteNameSection>
          <FormInputFavoriteCheckbox id={receiverId} isChecked={favorited} />
          <NameText>
            {isReceiverOfBusinessType && (
              <img
                src={BusinessIcon}
                width={24}
                height={24}
                style={{ marginRight: '8px' }}
              />
            )}
            {firstName}
          </NameText>
        </FavoriteNameSection>
      );

    return (
      <FavoriteNameSection>
        <FormInputFavoriteCheckbox id={receiverId} isChecked={favorited} />
        <NameText>
          {isReceiverOfIndividualType && (
            <img
              src={IndividualIcon}
              width={24}
              height={24}
              style={{ marginRight: '8px' }}
            />
          )}
          {fullName}
        </NameText>
      </FavoriteNameSection>
    );
  };

  const renderGenderForm = () => {
    if (isReceiverOfBusinessType) return null;

    return (
      <FieldWrap isInline>
        <FieldIcon>
          <img
            src={GENDER_ICON_REFERENCE[gender?.toUpperCase()]}
            width={24}
            height={24}
            alt=""
          />
        </FieldIcon>
        <FieldValue>
          {gender ? t(GENDER_TRANSLATE_REFERENCE[gender?.toUpperCase()]) : '-'}
        </FieldValue>
      </FieldWrap>
    );
  };

  const renderMobileForm = () => {
    if (countryConfig && mobile?.visible)
      return (
        <FieldWrap isInline isFloatLeft>
          <FieldIcon>
            <img
              src={isHHMTTheme ? MobileHHMTIcon : MobileRemoxIcon}
              width={24}
              height={24}
              alt=""
            />
          </FieldIcon>
          <FieldValue>{mobileNumberMask || '-'}</FieldValue>
        </FieldWrap>
      );

    return null;
  };

  const renderBanksSection = () => {
    if (deliveryMethods?.includes(METHOD_BANK))
      return (
        <CustomBanksAccordion banks={banks} countryConfig={countryConfig} />
      );
  };

  const renderEwalletsSection = () => {
    if (isReceiverOfBusinessType) return null;

    if (deliveryMethods?.includes(METHOD_EWALLET))
      return (
        <CustomEwalletsAccordion
          ewallets={ewallets}
          countryConfig={countryConfig}
        />
      );

    return null;
  };

  return (
    <>
      <style>
        {`
          .receiver-details-modal-dialog {
            max-width: fit-content;
          }
        `}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="receiver-details-modal-dialog"
      >
        <ModalHeader>
          <ModalHeaderBody>
            {renderReceiverNameForm()}
            <ActionsWrap>
              <ButtonCommon
                value={t('button_send_now')}
                color="var(--c-primary)"
                background="var(--bg-primary)"
                isFill={true}
                styles={{
                  paddingInline: 0,
                  whiteSpace: 'nowrap',
                }}
                buttonIconStyles={{
                  marginRight: '8px',
                }}
                iconSrc={SendIcon}
                onClick={() => onClick(currentReceiver)}
              />

              <CustomActionDropdown
                text={t('button_action')}
                textStyles={{
                  marginRight: '4px',
                }}
                styles={{
                  border: '1px solid var(--c-action)',
                  borderRadius: '12px',
                  height: '44px',
                  width: '100px',
                  marginRight: '8px',
                }}
                onDelete={onDelete}
              />
              <ButtonCommon
                color="var(--c-primary)"
                background="var(--ds-c-white)"
                value={t('button_close')}
                styles={{ margin: '0px', width: '60px', height: '40px' }}
                onClick={() => {
                  onClose();
                  dispatch({ type: SET_CURRENT_RECEIVER, payload: null });
                }}
              />
            </ActionsWrap>
          </ModalHeaderBody>
        </ModalHeader>
        <ModalBody>
          <FieldsWrap>
            {checkReceiverSelectedMissingInformation(currentReceiver) && (
              <FieldWrap>
                <FieldIcon>
                  <img src={WarnningIcon} width={24} height={24} alt="" />
                </FieldIcon>
                <FieldValue
                  style={{
                    textTransform: 'initial',
                    color: 'red',
                  }}
                >
                  {t('receivers_summary_missing_information')}
                </FieldValue>
              </FieldWrap>
            )}
            <FieldWrap isInline isFloatLeft>
              <FieldIcon>
                <img
                  src={isHHMTTheme ? CountryHHMTIcon : CountryRemoxIcon}
                  width={24}
                  height={24}
                  alt=""
                />
              </FieldIcon>
              <FieldValue>{countryName || '-'}</FieldValue>
            </FieldWrap>
            {renderGenderForm()}
            {renderMobileForm()}
            {countryConfig && phone?.visible && (
              <FieldWrap isInline>
                <FieldIcon>
                  <img
                    src={isHHMTTheme ? PhoneHHMTIcon : PhoneRemoxIcon}
                    width={24}
                    height={24}
                    alt=""
                  />
                </FieldIcon>
                <FieldValue>{phoneNumberMask || '-'}</FieldValue>
              </FieldWrap>
            )}
          </FieldsWrap>
          <FieldWrap
            styles={{
              alignItems: 'start',
              padding: '10px 0',
            }}
          >
            <FieldIcon>
              <img
                src={isHHMTTheme ? LocationHHMTIcon : LocationRemoxIcon}
                width={24}
                height={24}
                alt=""
              />
            </FieldIcon>
            <FieldValue>{fullAddress || '-'}</FieldValue>
          </FieldWrap>
          <Divider />
          {renderBanksSection()}
          {renderEwalletsSection()}
        </ModalBody>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
  }
  & .modal-content {
    padding: 24px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 668px;
    height: 508px;
    margin-inline: auto;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
      background: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--ds-c-grey-hover);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent !important;
    }
  }
`;
const ModalHeader = styled(Modal.Header)`
  padding: 0;
  margin-bottom: 0;
  display: block;
  padding-bottom: 16px;
  border-bottom: 1px solid #eef2f5;
`;
const ModalHeaderBody = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8px;
`;
const FavoriteNameSection = styled.div`
  display: flex;
  align-items: flex-start;
`;
const NameText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: var(--ds-c-grey-dark);
  text-align: left;

  margin: 0;
  padding: 0;
`;
const ActionsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ModalBody = styled(Modal.Body)`
  padding: 0;
`;
const FieldsWrap = styled.div`
  display: table;
  width: 100%;
`;
const FieldWrap = styled.div`
  display: flex;
  align-items: ${(props) => props?.styles?.alignItems || 'center'};
  padding: ${(props) => props?.styles?.padding};
  justify-content: start;
  width: ${(props) => (props.$isInline ? '207px' : '100%')};
  height: fit-content;
  min-height: 40px;
  float: ${(props) => props.$isFloatLeft && 'left'};
  margin-top: 16px;
  margin-right: ${(props) => props.$isFloatLeft && '32px'};
`;
const FieldIcon = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  justify-content: start;
  align-items: center;
  width: fit-content;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-grey-dark);

  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 8px;

  & span {
    color: #7a8189;
  }

  & span.payment-credit-card {
    text-transform: initial;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #eef2f5;
  margin: 32px 0;
`;

export default FormReceiverDetails;
