import styled from 'styled-components';
import { arrayUtils } from 'utils';
import ReceiverList from '../components/ReceiverList';

const ReceiversMobile = ({
  receivers,
  setReceivers,
  searchValue,
  applyFiltered,
  currentTab,
  isFetching,
  setFetching,
  isSearched,
  setSearched,
}) => {
  return (
    <ReceiversMobileStyled $isEmpty={arrayUtils.isArrayEmpty(receivers)}>
      <ReceiverList
        receivers={receivers}
        setReceivers={setReceivers}
        searchValue={searchValue}
        applyFiltered={applyFiltered}
        currentTab={currentTab}
        isFetching={isFetching}
        setFetching={setFetching}
        isSearched={isSearched}
        setSearched={setSearched}
      />
    </ReceiversMobileStyled>
  );
};

const ReceiversMobileStyled = styled.div`
  display: ${(props) => (props.$isEmpty ? 'flex' : 'block')};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(props) => (props.$isEmpty ? 'calc(100% - 200px)' : 'fit-content')};
  padding-top: ${(props) => (props.$isEmpty ? '0px' : '16px')};
  padding-bottom: ${(props) => (props.$isEmpty ? '0px' : '99px')};
`;

export default ReceiversMobile;
