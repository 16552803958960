import NotificaionAnimation from 'assets/icons/notification-animation-blue.gif';
import styled from 'styled-components';

const NotifyAnimationBlueIcon = ({
  size = { width: 36, height: 36 },
  styles = {},
  onClick = () => {},
}) => {
  return (
    <ImageStyled
      style={{ ...styles }}
      onClick={onClick}
      src={NotificaionAnimation}
      width={size.width}
      height={size.height}
      alt=""
    />
  );
};

const ImageStyled = styled.img`
  object-fit: contain;
`;

export default NotifyAnimationBlueIcon;
