import { api } from 'api';
import CustomCalculatorFormAccordion from 'components/Transactions/components/CustomCalculatorFormAccordion';
import {
  ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  DESKTOP_MIN_WIDTH,
  METHOD_EPAYMENT,
  SENDING_AMOUNT_DISCOUNT_PERCENTS,
  SURCHARGE_RATE,
} from 'constants';
import { format } from 'helpers';
import useAddTransaction from 'hooks/form-add-transaction/useAddTransaction';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import useThrottle from 'hooks/useThrottle';
import { forwardRef, lazy, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { numberUtils } from 'utils';

const TooltipCommon = lazy(() => import('components/common/TooltipCommon'));
const TooltipCommonMobile = lazy(() =>
  import('components/common/TooltipCommon/mobile')
);

const CalculatorForm = forwardRef(
  (
    {
      information,
      countryConfig,
      isCalculatorFormAccordion = false,
      styleWrapper,
      setFetching = () => {},
      isExpandDefault = false,
    },
    ref
  ) => {
    const { t } = useLang();
    const { state } = useStore();
    const { addTransactionInformation } = state;
    const remittance = information || addTransactionInformation.remittance;
    const receiverPayoutMethod = addTransactionInformation.receiverPayoutMethod;
    const {
      currentCurrencySelected,
      currentCurrencySelectedAnother,
      isAddAnotherReceivingCurrency,
    } = remittance;
    const { isMobile, isTablet } = useDetectDevice();
    const throttle = useThrottle();
    const { renderDiscountFieldLabel } = useAddTransaction();

    const [originalFee, setOriginalFee] = useState(null);

    const paymentMethod =
      addTransactionInformation?.paymentMethod[
        ADD_TRANSACTION_PAYMENT_METHOD_NAME
      ];

    const discountApplied =
      addTransactionInformation?.paymentMethod?.discountApplied;
    const { discountAmount, totalAmountAfterDiscount, type, code } =
      discountApplied || {};
    const isDiscountApplied = Boolean(Number(discountAmount));

    const isShowSurchargeField =
      paymentMethod && paymentMethod === METHOD_EPAYMENT;

    const onlyCurrencySelectedByVND =
      !isAddAnotherReceivingCurrency &&
      currentCurrencySelected?.currencyCode === 'VND';

    const getBuyCurrencies = () => {
      if (!isAddAnotherReceivingCurrency)
        return [currentCurrencySelected?.currencyCode];
      return [
        currentCurrencySelected?.currencyCode,
        currentCurrencySelectedAnother?.currencyCode,
      ];
    };

    const parseAmountValue = (value) => {
      return value && format.toAmountFloatStr(numberUtils.mathRound(value));
    };

    const dmCode = receiverPayoutMethod[ADD_TRANSACTION_PAYOUT_METHOD_NAME];

    const sendAmount = numberUtils.mathRound(
      remittance[ADD_TRANSACTION_YOU_SEND_NAME] || 0
    );
    const sendAnotherAmount = numberUtils.mathRound(
      remittance[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] || 0
    );
    const sendTotalAmount = numberUtils.mathRound(
      sendAmount + sendAnotherAmount
    );

    const feeOriginAmount = numberUtils.mathRound(originalFee?.feeAmount || 0);

    const feeAmount = sendTotalAmount
      ? numberUtils.mathRound(remittance?.feeAmount?.feeAmount || 0)
      : 0;

    const surchargeAmount =
      paymentMethod && paymentMethod === METHOD_EPAYMENT
        ? isDiscountApplied
          ? numberUtils.mathRound(totalAmountAfterDiscount * SURCHARGE_RATE) ||
            0
          : numberUtils.mathRound(
              numberUtils.mathRound(sendTotalAmount + feeAmount) *
                SURCHARGE_RATE
            )
        : 0;

    const totalPayAmount = isDiscountApplied
      ? paymentMethod === METHOD_EPAYMENT
        ? numberUtils.mathRound(
            Number(totalAmountAfterDiscount) +
              Number(totalAmountAfterDiscount) * SURCHARGE_RATE
          )
        : Number(totalAmountAfterDiscount)
      : numberUtils.mathRound(feeAmount + surchargeAmount + sendTotalAmount);

    const discountPercent = useMemo(() => {
      return SENDING_AMOUNT_DISCOUNT_PERCENTS.find((o) => {
        if (o.range.length === 1 && sendTotalAmount >= o.range[0]) return o;

        if (
          feeAmount &&
          feeOriginAmount &&
          sendTotalAmount >= o.range[0] &&
          sendTotalAmount <= o.range[1]
        )
          return o;

        return null;
      });
    }, [sendTotalAmount, feeAmount, feeOriginAmount]);

    const isShowDiscountPercent = Boolean(
      onlyCurrencySelectedByVND &&
        Boolean(discountPercent) &&
        feeAmount &&
        feeOriginAmount &&
        feeOriginAmount !== feeAmount
    );

    const isShowFeeDiscount = Boolean(
      sendTotalAmount && feeAmount && feeOriginAmount - feeAmount > 0
    );

    const fetchOriginalFee = async (
      countryCode,
      dmCode,
      sendAmount,
      isAddAnotherReceivingCurrency
    ) => {
      try {
        const { data } = await api.lookupOriginalFee(
          countryCode,
          dmCode,
          sendAmount,
          isAddAnotherReceivingCurrency
            ? 'AUD'
            : currentCurrencySelected?.currencyCode
        );

        if (data) {
          setOriginalFee(data);
        }

        setFetching(false);
      } catch (error) {
        console.error(error?.message);

        setFetching(false);
      }
    };
    useEffect(() => {
      if (!sendTotalAmount) {
        setOriginalFee(null);
        return;
      }
      if (countryConfig && sendTotalAmount && dmCode) {
        throttle(() => {
          fetchOriginalFee(
            countryConfig?.countryCode,
            dmCode,
            sendTotalAmount,
            isAddAnotherReceivingCurrency
          );
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [countryConfig, sendTotalAmount, dmCode]);

    const renderTotalLabel = () => {
      if (!paymentMethod) return t('label_sub_total');

      return t('label_total_to_pay');
    };

    if (isCalculatorFormAccordion) {
      return (
        <CustomCalculatorFormAccordion
          totalPayAmount={totalPayAmount}
          sendAmount={sendTotalAmount}
          feeAmount={feeAmount}
          isShowDiscountPercent={isShowDiscountPercent}
          discountPercent={discountPercent}
          originalFee={feeOriginAmount}
          isShowSurchargeField={isShowSurchargeField}
          surchargeAmount={surchargeAmount}
          isDiscountApplied={isDiscountApplied}
          discountAmount={discountAmount}
          isExpandDefault={isExpandDefault}
          styleWrapper={styleWrapper}
          paymentMethod={paymentMethod}
          discountApplied={discountApplied}
          isShowFeeDiscount={isShowFeeDiscount}
        />
      );
    }

    return (
      <CalculatorFormStyled>
        <CalculatorDetails ref={ref}>
          <Label>{t('label_transaction_details')}</Label>
          <CalculatorFieldWrap>
            <CalculatorFieldLabel>
              {t('label_sending_amount')}
            </CalculatorFieldLabel>
            <CalculatorFieldValue>{`${parseAmountValue(
              sendTotalAmount
            )} AUD`}</CalculatorFieldValue>
          </CalculatorFieldWrap>
          <CalculatorFieldWrap>
            <CalculatorFieldLabel>{t('label_fee')}</CalculatorFieldLabel>
            <CalculatorFieldValue>{`${parseAmountValue(
              feeOriginAmount
            )} AUD`}</CalculatorFieldValue>
          </CalculatorFieldWrap>
          {isShowFeeDiscount && (
            <CalculatorFieldWrap>
              <CalculatorFieldLabel className="label-percent">
                {t('label_discounted_amount')}{' '}
                {isShowDiscountPercent && discountPercent ? (
                  <>
                    {isMobile || isTablet ? (
                      <TooltipCommonMobile
                        name="add-transaction-calculator-form"
                        text={t(discountPercent.tooltip)}
                        icon={discountPercent.imgSrc}
                        percentValue={discountPercent.percent}
                        isUseImage={false}
                      />
                    ) : (
                      <TooltipCommon
                        text={t(discountPercent.tooltip)}
                        icon={discountPercent.imgSrc}
                        percentValue={discountPercent.percent}
                        isPopover
                        isDangerouslySetInnerHTML={true}
                        isUseImage={false}
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </CalculatorFieldLabel>
              <CalculatorFieldValue className="fee-value">
                -{`${parseAmountValue(feeOriginAmount - feeAmount)} AUD`}
              </CalculatorFieldValue>
            </CalculatorFieldWrap>
          )}
          {isDiscountApplied && (
            <CalculatorFieldWrap>
              <CalculatorFieldLabel>
                {renderDiscountFieldLabel(type, code)}
              </CalculatorFieldLabel>
              <CalculatorFieldValue>
                -{`${parseAmountValue(Number(discountAmount))} AUD`}
              </CalculatorFieldValue>
            </CalculatorFieldWrap>
          )}
          {isShowSurchargeField && (
            <CalculatorFieldWrap>
              <CalculatorFieldLabel>
                {t('label_surcharge_label')} <span>{'(1.2%)'}</span>
              </CalculatorFieldLabel>
              <CalculatorFieldValue>{`${format.toAmountFloatStr(
                surchargeAmount
              )} AUD`}</CalculatorFieldValue>
            </CalculatorFieldWrap>
          )}
        </CalculatorDetails>
        <CalculatorTotal>
          <CalculatorFieldWrap
            style={{
              height: '64px',
              borderBottom: '1px solid #ffffff',
              marginBottom: '13px',
            }}
          >
            <CalculatorTotalLabel>{renderTotalLabel()}</CalculatorTotalLabel>
            <CalculatorTotalValue>{`${parseAmountValue(
              totalPayAmount
            )} AUD`}</CalculatorTotalValue>
          </CalculatorFieldWrap>
          <CalculatorFieldWrap>
            <Label
              style={{
                color: '#ffffff',
                marginBottom: '0px',
              }}
            >
              {t('label_receiving_currency')}
            </Label>
            <CurrenciesWrap>
              {getBuyCurrencies()?.map((currencyCode) => (
                <CurrencyChip key={currencyCode}>{currencyCode}</CurrencyChip>
              ))}
            </CurrenciesWrap>
          </CalculatorFieldWrap>
        </CalculatorTotal>
      </CalculatorFormStyled>
    );
  }
);

const CalculatorFormStyled = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
`;

const CalculatorDetails = styled.div`
  margin-top: 0px;
  min-width: 437px;
  width: 437px;
  background: var(--ds-bg-2);
  border-radius: 8px;
  padding: 10px 16px;
`;
const Label = styled.p`
  margin: 0;
  margin-bottom: 10px;
  padding: 0;

  font-family: var(--ff-primary);
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
  color: var(--ds-c-grey-dark);
  text-transform: uppercase;
`;
const CalculatorFieldWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const CalculatorFieldLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue);

  margin: 0;
  padding: 0;

  & span {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    &.discount-code {
      color: var(--ds-c-green-default);
    }
  }

  &.label-percent {
    display: flex;
    align-items: center;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const CalculatorFieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-blue);

  margin: 0;
  padding: 0;

  &.fee-value {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &.summary-discount {
    font-weight: 400;
    color: #b0b6bd;
    display: flex;
    align-items: center;
  }

  & span {
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;

    background-color: var(--ds-c-green-default);
    padding: 2px 8px;
    border-radius: 2px;
    margin-right: 8px;
  }

  & b {
    font-weight: 500;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;

    & span {
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

const CalculatorTotal = styled.div`
  margin-top: 0px;
  width: 100%;
  background: var(--ds-bg-3);
  border-radius: 8px;
  padding: 10px 16px;
  display: grid;
  align-content: center;
`;
const CalculatorTotalLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  text-transform: uppercase;

  margin: 0;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const CalculatorTotalValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  text-transform: uppercase;
  color: #ffffff;

  margin: 0;
  padding: 0;
`;

const CurrenciesWrap = styled.div`
  display: flex;
  gap: 4px;
`;
const CurrencyChip = styled.span`
  height: 20px;
  padding-inline: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ds-c-blue-dark);
  border-radius: 100px;

  font-family: var(--ff-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: #fff;
`;

export default CalculatorForm;
