import moment from 'moment';
import SimpleNameMatcher from './simpleNameMatcher';
const card = {
  acceptingCards: ['Visa', 'Mastercard'],

  cardTypeFromNumber(number) {
    if (!number) {
      return '';
    }
    // Diners - Carte Blanche
    let re = new RegExp('^30[0-5]');
    if (number.match(re) != null) {
      return 'Diners - Carte Blanche';
    }

    // Diners
    re = new RegExp('^(30[6-9]|36|38)');
    if (number.match(re) != null) {
      return 'Diners';
    }

    // JCB
    re = new RegExp('^35(2[89]|[3-8][0-9])');
    if (number.match(re) != null) {
      return 'JCB';
    }

    // AMEX
    re = new RegExp('^3[47]');
    if (number.match(re) != null) {
      return 'AMEX';
    }

    // Visa Electron
    re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
    if (number.match(re) != null) {
      return 'Visa Electron';
    }

    // Visa
    re = new RegExp('^4');
    if (number.match(re) != null) {
      return 'Visa';
    }

    // Mastercard
    re = new RegExp('^5[1-5]');
    if (number.match(re) != null) {
      return 'Mastercard';
    }

    // Discover
    re = new RegExp(
      '^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)'
    );
    if (number.match(re) != null) {
      return 'Discover';
    }

    return '';
  },

  isExpiryValid(expiryStr) {
    const expiry = moment(expiryStr, 'MM/YYYY');
    const month = expiry.month() + 1;
    const year = expiry.year();

    const current = moment();
    const currentMonth = current.month() + 1;
    const currentYear = current.year();

    return (
      month >= 1 &&
      month <= 12 &&
      (year > currentYear || (year === currentYear && month >= currentMonth))
    );
  },

  isCardAccepted(cardNumber) {
    const cardType = card.cardTypeFromNumber(cardNumber);
    return this.acceptingCards.includes(cardType);
  },

  isHolderNameValid(holderName, userFullName) {
    const score = SimpleNameMatcher.match(
      userFullName?.toLowerCase(),
      holderName?.toLowerCase()
    );
    return score >= SimpleNameMatcher.strong;
  },
};

export default card;
