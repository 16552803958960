import MasterCardIcon from 'assets/icons/payments/master-card-icon.svg';
import VisaIcon from 'assets/icons/payments/visa-icon.svg';
import { checkFormInputValidation } from 'components/common/func';
import {
  DESKTOP_MIN_WIDTH,
  PAYMENT_BY_DEPOSIT_NUMBER_FIELD,
  PAYMENT_BY_DEPOSIT_NUMBER_NAME,
} from 'constants';
import { format, masterCardNumberValid, visaNumberValid } from 'helpers';
import card from 'helpers/creditCardHelper';
import useLang from 'hooks/useLang';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const FormInputPaymentCardNumberValidation = ({
  field,
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();
  const isNumber = (value) => !Number.isNaN(Number(value));

  const handleCardNumberOnChange = (name, value, validation) => {
    const isPaymentByNumberNameField = name === PAYMENT_BY_DEPOSIT_NUMBER_NAME;

    if (isPaymentByNumberNameField) {
      if (value && isNumber(value)) {
        document.getElementsByName(name)[0].value = value;
      }
    }
    if (
      isPaymentByNumberNameField &&
      value &&
      card.isCardAccepted(value) === false
    ) {
      validation = {
        ...validation,
        [name]: PAYMENT_BY_DEPOSIT_NUMBER_FIELD.msgNotAccepted,
      };
    }

    return validation;
  };

  const handleOnChange = (e, field) => {
    const { value, name } = e.target;
    let valueFormat = format.toCardNumberAmount(value);

    if (!isNumber(valueFormat)) return;
    if (valueFormat.length === 17) return;

    let newInformation = { ...information };
    let newValidation = { ...validation };

    handleCardNumberOnChange(name, valueFormat);

    newInformation = {
      ...information,
      [name]: valueFormat,
    };

    setInformation(newInformation);

    newValidation = checkFormInputValidation(
      newInformation[name],
      field,
      validation
    );

    newValidation = handleCardNumberOnChange(name, value, newValidation);

    setValidation(newValidation);
  };

  const handleOnBlur = (e, field) => {
    handleOnChange(e, field);
  };

  const handleFormLabelOnClick = (e, name) => {
    e.preventDefault();
    const inputEle = document.getElementsByName(name)[0];

    if (inputEle) {
      inputEle.focus();
    }
  };

  const renderPaymentImage = (cardNumber) => {
    if (visaNumberValid(cardNumber)) {
      return <PaymentImage src={VisaIcon} width={40} alt="visa" />;
    }

    if (masterCardNumberValid(cardNumber)) {
      return <PaymentImage src={MasterCardIcon} width={20} alt="master-card" />;
    }

    return null;
  };

  return (
    <FormGroupStyled>
      <FormLabel onClick={(e) => handleFormLabelOnClick(e, field.name)}>
        {t(field.label)}
        {field.isRequired && <span>*</span>}
      </FormLabel>
      <FormControl
        type={field.type}
        name={field.name}
        value={
          information[field.name] &&
          format.toCardNumberStr(information[field.name])
        }
        placeholder={t(field.placeholder)}
        isInvalid={validation[field.name]}
        onChange={(e) => handleOnChange(e, field)}
        onBlur={(e) => handleOnBlur(e, field)}
        readOnly={field.isReadOnly}
      />
      {card.isCardAccepted(information[PAYMENT_BY_DEPOSIT_NUMBER_NAME]) &&
        renderPaymentImage(information[field.name])}
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[field.name])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: var(--ds-c-white);

  &:focus {
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-inline: 10px !important;
  }

  &[readonly] {
    border: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const PaymentImage = styled.img`
  position: absolute;
  right: 16px;
  margin-top: -26px;
`;

export default FormInputPaymentCardNumberValidation;
