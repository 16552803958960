const LocationIcon = ({ color = 'var(--ds-c-grey-dark)', size }) => {
  return (
    <svg
      width={size?.width}
      height={size?.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5 12C19.5 17.018 14.0117 20.4027 12.4249 21.2764C12.1568 21.424 11.8432 21.424 11.5751 21.2764C9.98831 20.4027 4.5 17.018 4.5 12C4.5 7.5 8.13401 4.5 12 4.5C16 4.5 19.5 7.5 19.5 12Z"
        stroke={color || '#FBFBFB'}
      />
      <circle cx="12" cy="12" r="3.5" stroke={color || '#FBFBFB'} />
    </svg>
  );
};

export default LocationIcon;
