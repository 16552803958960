import SwitchCheckedHHMTIcon from 'assets/icons/switch-checked-hhmt-icon.svg';
import SwitchCheckedRemoxIcon from 'assets/icons/switch-checked-remox-icon.svg';
import SwitchboxHHMTIcon from 'assets/icons/switchbox-hhmt-icon.svg';
import SwitchboxRemoxIcon from 'assets/icons/switchbox-remox-icon.svg';
import {
  DESKTOP_MIN_WIDTH,
  REGISTER_ADDRESS_FINDER_NAME,
  REGISTER_ADDRESS_POST_CODE_NAME,
  REGISTER_ADDRESS_STATE_NAME,
  REGISTER_ADDRESS_STREET_NAME_NAME,
  REGISTER_ADDRESS_STREET_NO_NAME,
  REGISTER_ADDRESS_STREET_TYPE_NAME,
  REGISTER_ADDRESS_SUBURB_NAME,
  REGISTER_ADDRESS_SWITCH,
  REGISTER_ADDRESS_UNITNAME_NAME,
} from 'constants';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const FormInputAddressSwitch = ({
  information,
  setInformation,
  setValidation,
}) => {
  const { t } = useLang();
  const { isRegisterEnterAddressManuallySelected } = information;
  const { isHHMTTheme } = useTheme();

  const handleOnChange = () => {
    setInformation({
      ...information,
      isRegisterEnterAddressManuallySelected:
        !isRegisterEnterAddressManuallySelected,
    });

    setValidation({
      [REGISTER_ADDRESS_FINDER_NAME]: '',
      [REGISTER_ADDRESS_UNITNAME_NAME]: '',
      [REGISTER_ADDRESS_STREET_NO_NAME]: '',
      [REGISTER_ADDRESS_STREET_NAME_NAME]: '',
      [REGISTER_ADDRESS_STREET_TYPE_NAME]: '',
      [REGISTER_ADDRESS_POST_CODE_NAME]: '',
      [REGISTER_ADDRESS_SUBURB_NAME]: '',
      [REGISTER_ADDRESS_STATE_NAME]: '',
    });
  };

  useEffect(() => {
    const labelEle = document.getElementsByClassName('form-check-label')[2];
    if (labelEle) {
      labelEle.addEventListener('click', () => {
        handleOnChange();
      });
    }

    return () => {
      if (labelEle) {
        labelEle.removeEventListener('click', () => {
          handleOnChange();
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRegisterEnterAddressManuallySelected]);

  return (
    <FormGroupStyled>
      <FormSwitch
        type="switch"
        label={t('button_address_manual_switch')}
        name={REGISTER_ADDRESS_SWITCH}
        checked={isRegisterEnterAddressManuallySelected}
        onClick={handleOnChange}
        $checkboxSrc={isHHMTTheme ? SwitchboxHHMTIcon : SwitchboxRemoxIcon}
        $checkedSrc={
          isHHMTTheme ? SwitchCheckedHHMTIcon : SwitchCheckedRemoxIcon
        }
        readOnly
      />
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 16px;
  position: relative;
`;

const FormSwitch = styled(Form.Check)`
  margin: 0;
  margin-right: 44px;

  label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    margin-left: 4px;
    cursor: pointer !important;
    user-select: none;
    height: 32px;
    display: flex;
    align-items: center;
    padding-block: 4px;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  input:checked[type='checkbox'] {
    background-image: ${(props) => `url(${props.$checkedSrc}) !important`};
    background-position: center !important;
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: ${(props) => `url(${props.$checkboxSrc}) !important`};
    background-position: center !important;
    width: 40px !important;
    height: 24px !important;
    cursor: pointer !important;

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }
  }
`;

export default FormInputAddressSwitch;
