import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

const CatchAllRoutes = () => {
  const { isAuthenticated } = useAuth();

  const { removeFxMakePayment } = useFxAddTransaction();
  const { setAddTransactionCurrentStep, resetAddTransactionInformation } =
    useFxFormAddTransactionStore();

  useEffect(() => {
    if (!isAuthenticated) return;
    removeFxMakePayment();
    setAddTransactionCurrentStep();
    resetAddTransactionInformation();
  }, [isAuthenticated]);

  return <>{<Outlet />}</>;
};

export default CatchAllRoutes;
