import { DESKTOP_MIN_WIDTH } from 'constants';
import styled from 'styled-components';

const Footer = () => {
  return (
    <FooterStyled>
      © Eastern & Allied Pty Ltd {new Date().getFullYear()}{' '}
    </FooterStyled>
  );
};

const FooterStyled = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: flex;
  justify-content: center;
  align-items: center;
  height: 47px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: var(--ds-c-bg-light-grey);
  z-index: 100;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default Footer;
