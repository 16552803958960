import { api } from 'api';
import ButtonCommon from 'components/common/ButtonCommon';
import { checkFormInputValidation } from 'components/common/func';
import FormInputAddressManuallyValidation from 'components/FormEditReceiver/components/FormInputAddressManuallyValidation';
import FormInputAddressSwitch from 'components/FormEditReceiver/components/FormInputAddressSwitch';
import { fetchReceiversCountries } from 'components/Receivers/func';
import {
  EDIT_RECEIVER_COUNTRY_FIELD,
  EDIT_RECEIVER_COUNTRY_NAME,
  EDIT_RECEIVER_POSTAL_NAME,
  EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS,
  EDIT_RECEIVER_STATE_NAME,
  EDIT_RECEIVER_STREET_NAME,
  EDIT_RECEIVER_SUBURB_NAME,
} from 'constants';
import useAuth from 'hooks/useAuth';
import useCountryConfig from 'hooks/useCountryConfig';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';

import CustomSearchDropdown from 'components/common/CustomSearchDropdown';
import { fetchReceiverInformation } from 'components/FormAddTransaction/func';
import { stringUtils } from 'utils';

import InformPopup from 'components/common/InformPopup';
import CancelEditReceiverPopup from 'components/FormEditReceiver/components/CancelEditReceiverPopup';
import { Fade } from 'react-bootstrap';
import CancelEditAddressPopup from '../CancelEditAddressPopup';

export const formEditAddressClassName = 'add-transaction-form-edit-address';

const initialFormField = {
  [EDIT_RECEIVER_COUNTRY_NAME]: '',
  [EDIT_RECEIVER_STREET_NAME]: '',
  [EDIT_RECEIVER_STATE_NAME]: '',
  [EDIT_RECEIVER_SUBURB_NAME]: '',
  [EDIT_RECEIVER_POSTAL_NAME]: '',
};

const FormEditAddress = ({ isOpen, information: tranInformation, onClose }) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { currentReceiver, editReceiverInformation } = state;
  const { isEditReceiverEnterAddressManuallySelected } =
    editReceiverInformation.residentialAddress;
  const { token } = useAuth();
  const countryConfig = useCountryConfig(currentReceiver?.country?.code);
  const config = useGetConfig({ countryConfig });
  const { addressManualDisable } = config;

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [countries, setCountries] = useState(null);
  const [cities, setCities] = useState(null);
  const [isOpenCancelPopup, setOpenCancelPopup] = useState(false);
  const [isOpenNotChangePopup, setOpenNotChangePopup] = useState(false);
  const [isOpenUpdatePopup, setOpenUpdatePopup] = useState(false);

  const fetchCities = async (countryCode) => {
    try {
      const { data } = await api.getCities(countryCode);

      if (data) {
        setCities(data);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    fetchReceiversCountries(token, setCountries);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentReceiver) {
      const newInformation = {
        [EDIT_RECEIVER_COUNTRY_NAME]: currentReceiver?.country,
        [EDIT_RECEIVER_STREET_NAME]: currentReceiver?.address?.street,
        [EDIT_RECEIVER_STATE_NAME]: currentReceiver?.address?.city,
        [EDIT_RECEIVER_SUBURB_NAME]: currentReceiver?.address?.suburb,
        [EDIT_RECEIVER_POSTAL_NAME]: currentReceiver?.address?.postcode,
      };
      setInformation(newInformation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReceiver]);

  useEffect(() => {
    if (currentReceiver) {
      fetchCities(currentReceiver?.country?.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentReceiver]);

  const isCountryChanged = (information) => {
    if (
      information[EDIT_RECEIVER_COUNTRY_NAME]?.name?.toUpperCase() !==
      currentReceiver?.address?.country?.name?.toUpperCase()
    )
      return true;

    return false;
  };

  const isAddressChanged = (information) => {
    if (
      information[EDIT_RECEIVER_STREET_NAME]?.toUpperCase() !==
      currentReceiver?.address?.street?.toUpperCase()
    )
      return true;

    return false;
  };

  const isStateChanged = (information) => {
    if (
      information[EDIT_RECEIVER_STATE_NAME]?.name?.toUpperCase() !==
      currentReceiver?.address?.city?.name?.toUpperCase()
    )
      return true;

    return false;
  };

  const isDistrictChanged = (information) => {
    if (
      information[EDIT_RECEIVER_SUBURB_NAME]?.name?.toUpperCase() !==
      currentReceiver?.address?.suburb?.name?.toUpperCase()
    )
      return true;

    return false;
  };

  const isPostalCodeChanged = (information) => {
    if (!information[EDIT_RECEIVER_POSTAL_NAME]) {
      return false;
    }

    if (
      information[EDIT_RECEIVER_POSTAL_NAME] !==
      currentReceiver?.address?.postcode
    )
      return true;

    return false;
  };

  const checkInformationChanged = (information) => {
    return (
      isCountryChanged(information) ||
      isAddressChanged(information) ||
      isStateChanged(information) ||
      isDistrictChanged(information) ||
      isPostalCodeChanged(information)
    );
  };

  const handleOnToggleUpdatePopup = async () => {
    setOpenUpdatePopup(!isOpenUpdatePopup);
  };

  const handleOnClickUpdatePopup = () => {
    fetchReceiverInformation(
      token,
      currentReceiver.receiverId,
      tranInformation,
      0
    );

    setOpenUpdatePopup(false);

    onClose({
      cityId: information[EDIT_RECEIVER_STATE_NAME]?.id,
      suburbId: information[EDIT_RECEIVER_SUBURB_NAME]?.id,
    });
  };

  const handleOnCloseCancelPopup = () => {
    setOpenCancelPopup(false);
  };

  const handleOnToggleCancelPopup = () => {
    if (checkInformationChanged(information)) {
      setOpenCancelPopup(true);
    } else {
      onClose();
    }
  };

  const handleOnClickCancelPopup = () => {
    const newInformation = {
      [EDIT_RECEIVER_COUNTRY_NAME]: currentReceiver?.country,
      [EDIT_RECEIVER_STREET_NAME]: currentReceiver?.address?.street,
      [EDIT_RECEIVER_STATE_NAME]: currentReceiver?.address?.city,
      [EDIT_RECEIVER_SUBURB_NAME]: currentReceiver?.address?.suburb,
      [EDIT_RECEIVER_POSTAL_NAME]: currentReceiver?.address?.postcode,
    };
    setInformation(newInformation);

    setOpenCancelPopup(false);

    onClose();
  };

  const handleOnCloseCancelEditAddressPopup = () => {
    setOpenNotChangePopup(false);
  };

  const handleOnOpenCancelEditAddressPopup = () => {
    setOpenNotChangePopup(true);
  };

  const updateAddress = async () => {
    const payload = {
      id: currentReceiver.address.id,
      street: information[EDIT_RECEIVER_STREET_NAME]?.toUpperCase(),
      city: information[EDIT_RECEIVER_STATE_NAME],
      suburb: information[EDIT_RECEIVER_SUBURB_NAME],
      country: currentReceiver.address.country,
      fullAddress:
        `${information[EDIT_RECEIVER_STREET_NAME]}, ${information[EDIT_RECEIVER_SUBURB_NAME].name}, ${information[EDIT_RECEIVER_STATE_NAME].name}, ${currentReceiver.address.country.name}`?.toUpperCase(),
      postcode: information[EDIT_RECEIVER_POSTAL_NAME],
      manual: isEditReceiverEnterAddressManuallySelected,
    };

    return api.updateAddress(
      token,
      currentReceiver.receiverId,
      payload.id,
      payload
    );
  };

  const handleSave = async () => {
    if (!information[EDIT_RECEIVER_POSTAL_NAME]) {
      delete information[EDIT_RECEIVER_POSTAL_NAME];
      delete validation[EDIT_RECEIVER_POSTAL_NAME];
    }

    if (checkInformationChanged(information)) {
      const isAllowSubmit =
        Object.values(information).every((value) => value) &&
        Object.values(validation).every((value) => !value);

      if (isAllowSubmit) {
        dispatch({ type: SET_SHOW_LOADING, payload: true });

        try {
          await Promise.all([updateAddress()]);

          handleOnToggleUpdatePopup();

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        } catch (error) {
          console.error(error);

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      }

      if (!isAllowSubmit) {
        let newValidation = { ...validation };

        newValidation = checkFormInputValidation(
          information[EDIT_RECEIVER_STREET_NAME],
          EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[0],
          newValidation
        );

        newValidation = checkFormInputValidation(
          information[EDIT_RECEIVER_STATE_NAME]?.name,
          EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[1],
          newValidation
        );

        newValidation = checkFormInputValidation(
          information[EDIT_RECEIVER_SUBURB_NAME]?.name,
          EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS[2],
          newValidation
        );

        setValidation(newValidation);
      }
    } else {
      handleOnOpenCancelEditAddressPopup();
    }
  };

  return (
    <>
      {isOpenCancelPopup && (
        <CancelEditReceiverPopup
          isOpen={isOpenCancelPopup}
          onClose={handleOnCloseCancelPopup}
          onClick={handleOnClickCancelPopup}
        />
      )}

      {isOpenNotChangePopup && (
        <CancelEditAddressPopup
          isOpen={isOpenNotChangePopup}
          onClose={handleOnCloseCancelEditAddressPopup}
        />
      )}

      {isOpenUpdatePopup && (
        <InformPopup
          isOpen={isOpenUpdatePopup}
          text={t('popup_update_success_title')}
          content={stringUtils.replaceKeyword(t('popup_update_success_desc'), [
            {
              key: 'label',
              value: t('label_address'),
            },
          ])}
          buttonLabel2={t('button_close')}
          onClick={handleOnClickUpdatePopup}
          isBackdropOverlap={true}
          zIndexBackdropOverlap={1055}
        />
      )}

      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        className={formEditAddressClassName}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader>{t('label_edit_address')}</ModalHeader>
        <ModalBody>
          <CustomSearchDropdown
            field={EDIT_RECEIVER_COUNTRY_FIELD}
            data={countries}
            keyFind="name"
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            isDisabled={true}
            styles={{ display: 'none' }}
          />
          {EDIT_RECEIVER_RESIDENTIAL_ADDRESS_FIELDS.map((field) => (
            <FormInputAddressManuallyValidation
              key={field.name}
              field={field}
              countryConfig={countryConfig}
              cities={cities}
              information={information}
              setInformation={setInformation}
              validation={validation}
              setValidation={setValidation}
            />
          ))}
          {addressManualDisable && (
            <FormInputAddressSwitch
              cities={cities}
              information={information}
              setInformation={setInformation}
              validation={validation}
              setValidation={setValidation}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Fade in appear>
            <NextWrap>
              <ButtonCommon
                value={t('button_cancel')}
                onClick={handleOnToggleCancelPopup}
                styles={{
                  margin: '0px',
                  marginRight: '16px',
                  width: '80px',
                }}
                color="var(--ds-c-blue)"
                background="var(--ds-c-white)"
              />
              <ButtonCommon
                value={t('button_save')}
                color="var(--c-primary)"
                background="var(--bg-primary)"
                isFill={true}
                styles={{
                  margin: '0px',
                  width: '80px',
                }}
                onClick={handleSave}
                isUseKeyDown
              />
            </NextWrap>
          </Fade>
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
  }

  & .modal-content {
    padding: 16px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 485px;
    height: fit-content;
    margin-inline: auto;
  }
`;
const ModalHeader = styled(Modal.Header)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-green-default);

  padding: 0;
  margin-bottom: 0;
  padding-bottom: 16px;
  border: none !important;
`;

const ModalBody = styled(Modal.Body)`
  padding: 0;
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
`;

const NextWrap = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
  background: var(--ds-c-white);
  font-size: 16px;
  line-height: 20px;
  position: unset;
  margin: 0px;
  height: 44px;
`;

export default FormEditAddress;
