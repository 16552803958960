import { EN_LANG, I18N_REDIRECTED, VI_LANG } from 'constants';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const resources = {};

const options = {
  fallbackLng: [EN_LANG, VI_LANG],
  // debug: true,
  supportedLngs: [EN_LANG, VI_LANG],
  lowerCaseLng: true,
  cleanCode: true,
  preload: [EN_LANG, VI_LANG],
  resources,
  detection: {
    order: ['localStorage', 'querystring', 'cookie'],
    lookupLocalStorage: I18N_REDIRECTED,
    lookupQuerystring: 'lang',
    lookupCookie: I18N_REDIRECTED,
    caches: ['localStorage', 'cookie'],
  },
  partialBundledLanguages: true,
  react: {
    useSuspense: false,
  },
};

i18next.use(initReactI18next).use(LanguageDetector).use(Backend).init(options);
