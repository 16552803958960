import React from 'react';

const CircleRoundCutHorizontal = ({
  top,
  right,
  isBorderDashedLine = false,
}) => {
  return (
    <div
      style={{
        width: '20px',
        height: '20px',
        borderRadius: '50px',
        backgroundColor: 'white',
        position: 'absolute',
        top: top || '18px',
        right: right || '-10px',
        border: isBorderDashedLine
          ? '1px dashed var(--ds-c-blue-remox-hover)'
          : 'none',
      }}
    >
      <div
        style={{
          width: '12px',
          height: '44px',
          backgroundColor: 'white',
          position: 'absolute',
          top: top || -14,
          right: right || -5,
        }}
      />
    </div>
  );
};

export default CircleRoundCutHorizontal;
