/* eslint-disable no-unused-vars */
import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import BodyHeader from './mobile/BodyHeader';

const BodySectionHeader = ({
  titleBodySection,
  titleSection,
  CalculatorComponent = null,
  onToggleCancelPopup = () => {},
  onViewPickupLocation = () => {},
  isDisabled,
}) => {
  const { t } = useLang();
  const { isMobile, isTablet } = useDetectDevice();

  const {
    addTransactionCurrentStep: currentStep,
    pickupLocationSelected,
    pickupLocationType,
    pickupDateSelected,
  } = useFxFormAddTransactionStore();
  const { isMakePayment, getPickupLocationName } = useFxAddTransaction();

  const renderTitleCancelSection = () => (
    <TitleCancel>
      <TitleBodySection>{titleBodySection}</TitleBodySection>
      <ButtonCommon
        value={t('button_cancel')}
        color="var(--ds-c-blue-hyperlink-default)"
        background="var(--ds-c-white)"
        isFill={false}
        // iconSrc={CloseIcon}
        styles={{
          paddingInline: 0,
          fontWeight: 500,
          height: '29px',
        }}
        onClick={onToggleCancelPopup}
      />
    </TitleCancel>
  );
  const renderCalculatorSection = () => {
    if (isEmpty(CalculatorComponent)) return null;
    return CalculatorComponent;
  };
  const renderPickupLocationSection = () => {
    if (isMakePayment) return null;
    if (currentStep < 2) return null;
    return (
      <PickupLocationAndDate>
        <PickupItem>
          <p>
            <b>{t('label_pickup_at')}:</b>
            {getPickupLocationName(pickupLocationSelected, pickupLocationType)}
          </p>
          <ButtonCommon
            value={t('button_view')}
            onClick={onViewPickupLocation}
            styles={{
              margin: '0px',
              marginLeft: '4px',
              padding: '0px',
              width: 'fit-content',
              height: '20px',
              fontWeight: 500,
            }}
            color="var(--ds-c-green-default)"
            background="transparent"
            isFill={false}
          />
        </PickupItem>
        {!isEmpty(pickupDateSelected) && (
          <PickupItem>
            <p>
              <b>{t('label_pickup_on')}:</b>
              {pickupDateSelected}
            </p>
          </PickupItem>
        )}
      </PickupLocationAndDate>
    );
  };
  const renderBodyHeader = () => {
    if (isMobile || isTablet) {
      return (
        <BodyHeader
          titleSection={titleSection}
          renderTitleCancelSection={renderTitleCancelSection}
          isDisabled={isDisabled}
        />
      );
    }
    return (
      <TitleProcessStepsSection>
        {renderTitleCancelSection()}
        {renderCalculatorSection()}
        {renderPickupLocationSection()}
        <TitleSection>{titleSection}</TitleSection>
      </TitleProcessStepsSection>
    );
  };

  return <>{renderBodyHeader()}</>;
};

const TitleProcessStepsSection = styled.div``;
const TitleBodySection = styled.h2`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-blue-disabled);
  margin-bottom: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    color: var(--ds-c-grey-dark);
  }
`;
const TitleCancel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 24px;
  }
`;
const TitleSection = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-green-default);
  padding-block: 8px;
  margin-bottom: 20px;
  height: 36px;
  border-bottom: 1px solid var(--ds-c-blue-remox-light);
`;
const PickupLocationAndDate = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const PickupItem = styled.div`
  display: flex;

  & p {
    ${paragraphMixin};
    font-weight: 500;

    & b {
      margin-right: 4px;
    }
  }
`;

export default BodySectionHeader;
