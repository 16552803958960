import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ActiveItem from '../../../ExchangeRateItem/components/ActiveItem';
import ExchangeRateItemMobile from '../../../ExchangeRateItem/mobile/ver2';

const ExchangeRateSectionMobile = ({ remittanceRatesData, countries }) => {
  const { t } = useLang();
  const [current, setCurrent] = useState(null);

  const getCountryNameByCode = (countryCode) => {
    if (isEmpty(countries)) return null;
    const country = countries.find((country) => country.code === countryCode);
    return country ? country.name : '';
  };

  useEffect(() => {
    if (remittanceRatesData) {
      const countryCodes = Object.keys(remittanceRatesData);
      if (countryCodes.length > 0) {
        setCurrent(countryCodes[0]);
      }
    }
  }, [remittanceRatesData]);

  if (!remittanceRatesData) {
    return null;
  }

  return (
    <Wrapper>
      <ActiveItem
        country={current}
        countryName={getCountryNameByCode(current)}
        rates={remittanceRatesData[current]}
      />
      <RatesWrap>
        <div>
          <b>*</b>
          {t('label_exchange_rate_per_aud')}
        </div>
        <Rates>
          {Object.keys(remittanceRatesData).map((country) => (
            <>
              <ExchangeRateItemMobile
                key={country}
                country={country}
                rates={remittanceRatesData[country]}
                isActive={country === current}
                onClick={() => setCurrent(country)}
              />
            </>
          ))}
        </Rates>
      </RatesWrap>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
  height: fit-content;
  padding-bottom: 20px;

  font-family: var(--ff-primary);
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;

  & b {
    color: var(--ds-c-red);
  }
`;

const RatesWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const Rates = styled.div`
  display: flex;
  gap: 8px;
`;

export default ExchangeRateSectionMobile;
