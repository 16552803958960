import CopyIcon from 'assets/icons/copy-hhmt-icon.svg';
import { DESKTOP_MIN_WIDTH, PAYMENT_AMOUNT_NAME } from 'constants';
import { format } from 'helpers';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useLang from 'hooks/useLang';
import { useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { numberUtils } from 'utils';

const FormInputCopy = ({ className, field, value, inputStyles = {} }) => {
  const { t } = useLang();

  const [isShowCopySuccess, setShowCopySuccess] = useState(false);
  const target = useRef(null);

  // const isPaymentEmailField = field.name === PAYMENT_EMAIL_NAME;
  const isPaymentAmountField = field.name === PAYMENT_AMOUNT_NAME;

  const parseAmountFloatValue = (value) => {
    return value && format.toAmountFloatStr(numberUtils.mathRound(value));
  };

  const valueFormat =
    numberUtils.isNumber(value) && isPaymentAmountField
      ? `AUD ${parseAmountFloatValue(value)}`
      : value;

  const { debounce } = useDebounceCallback(() => {
    handleCopySuccess();
  }, 0.3);

  const handleCopySuccess = () => {
    setShowCopySuccess(false);
  };

  const handleCopy = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(value);
    }

    setShowCopySuccess(true);

    debounce();
  };

  return (
    <>
      <style>
        {`
          #copy-trigger {
            inset: auto auto -28px 170px!important;
          }
        `}
      </style>
      <Overlay target={target.current} show={isShowCopySuccess} placement="top">
        <Tooltip id="copy-trigger">{t('label_copy_success')}</Tooltip>
      </Overlay>

      <FormGroupStyled ref={target}>
        <FormLabel>{t(field.label)}:</FormLabel>
        <FormControl
          type={field.type}
          name={field.name}
          className={className}
          value={valueFormat}
          placeholder={field.placeholder}
          readOnly={true}
          style={inputStyles}
        />
        <CopyText onClick={handleCopy}>
          {t('label_copy')}{' '}
          <CopyIconStyled src={CopyIcon} width={24} height={24} alt="" />
        </CopyText>
      </FormGroupStyled>
    </>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 10px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 10px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);
  margin-bottom: 4px;

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  height: 40px;
  border: 1px solid var(--ds-c-grey-dark);
  border-radius: 12px;
  padding-inline: 10px;
  background: var(--ds-c-white);

  &:focus {
    background: var(--ds-c-white);
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &[readonly] {
    background: var(--ds-c-white);
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const CopyText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  color: var(--ds-c-blue);

  margin: 0;
  margin-right: 12px;
  padding: 0;
  padding-inline: 8px;
  display: flex;
  align-items: center;
  justify-content: end;
  height: 32px;
  background: #ffffff;
  border: 1px solid var(--ds-c-blue);
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  position: absolute;
  top: 28px;
  right: -8px;

  &:hover {
    color: var(--ds-c-blue-hover);
    border: 1px solid var(--ds-c-blue-hover);
    background: #ffffff;

    & img {
      opacity: 0.5;
    }
  }
`;
const CopyIconStyled = styled.img`
  margin-left: 4px;
`;

export default FormInputCopy;
