import { createBrowserHistory } from 'history';
import { isEmpty } from 'lodash';
import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const history = createBrowserHistory();

const initialState = {
  from: undefined,
  to: undefined,
};

const store = createContext(initialState);
const { Provider } = store;

const HistoryProvider = ({ children }) => {
  const [from, setFrom] = useState(undefined);
  const [to, setTo] = useState(undefined);

  const location = useLocation();

  useEffect(() => {
    if (isEmpty(location)) return;
    if (location.pathname === from?.pathname) return;
    setFrom(location);

    if (!isEmpty(from)) {
      setTo(from);
    }
  }, [location, from]);

  useEffect(() => {
    history.listen((update) => {
      if (update.action === 'POP') {
        setTo(from);
        setFrom(update.location);
      }
    });
  }, [from]);

  return (
    <Provider
      value={{
        from,
        to,
      }}
    >
      {children}
    </Provider>
  );
};

export { HistoryProvider, store };
