import { arrayUtils, numberUtils } from 'utils';

export const format = {
  toAmountStr(amount, zeroDecimal) {
    const arr = numberUtils.mathRound(parseFloat(amount)).toFixed(2).split('');

    const decimal =
      arr[arr.length - 3] + arr[arr.length - 2] + arr[arr.length - 1];

    arr.splice(arr.length - 3, 3, decimal);

    for (let i = arr.length - 4; i > 0; i = i - 3) {
      arr.splice(i, 0, ',');
    }

    if (!zeroDecimal) {
      if (decimal === '.00') {
        arr.splice(arr.length - 1, 1);
      }
    }

    return arr.join('');
  },
  toAmountStr2(amount) {
    const amountPaths = `${amount}`.split('.');
    const intPath = amountPaths[0];
    const decimalPath = amountPaths[1];

    if (decimalPath) {
      return intPath.length > 3
        ? `${format.toAmountStr(intPath)}.${decimalPath}`
        : amount;
    }

    return intPath.length > 3 ? format.toAmountStr(intPath) : amount;
  },
  toAmountFloatStr(amount) {
    const arr = numberUtils.mathRound(parseFloat(amount)).toFixed(2).split('');

    const decimal =
      arr[arr.length - 3] + arr[arr.length - 2] + arr[arr.length - 1];

    arr.splice(arr.length - 3, 3, decimal);

    for (let i = arr.length - 4; i > 0; i = i - 3) {
      arr.splice(i, 0, ',');
    }

    return arr.join('');
  },
  toAmountCentStr(amount) {
    return amount?.toString()?.includes('.')
      ? `${format.toAmountStr(amount?.toString()?.split('.')[0])}.${
          amount?.toString()?.split('.')[1]
        }`
      : format.toAmountStr(amount);
  },
  toStrAmount: (str) => {
    return str.split(',').join('');
  },
  toMobileNumberStr: (str) => {
    const toStrNumber = format.toMobileNumberAmount(str);

    if (str.length < 5) {
      return str;
    }

    const startStr = toStrNumber.slice(0, 4);

    let strNumberChunks = arrayUtils.chunk(Array.from(toStrNumber.slice(4)), 3);
    strNumberChunks = strNumberChunks.map((chunk) => chunk.join(''));

    if (strNumberChunks.length < 3) {
      return `${startStr} ${strNumberChunks.join(' ')}`;
    }

    if (strNumberChunks.length === 3) {
      strNumberChunks[strNumberChunks.length - 2] = strNumberChunks[
        strNumberChunks.length - 2
      ].concat(strNumberChunks[strNumberChunks.length - 1]);

      strNumberChunks.length = strNumberChunks.length - 1;

      return `${startStr} ${strNumberChunks.join(' ')}`;
    }

    return '';
  },
  toMobileNumberAmount: (str) => {
    const spaceChar = ' ';

    const validNumbers = Array.from(str).reduce((prevArray, char) => {
      const arrTemp = prevArray;

      if (char === spaceChar || isNaN(char)) {
        return arrTemp;
      }

      if (!isNaN(char)) {
        arrTemp.push(char);

        return arrTemp;
      }

      return arrTemp;
    }, []);

    return validNumbers.join('');
  },
  toMobileNumberStr2: (str) => {
    const toStrNumber = format.toMobileNumberAmount(str);

    if (str.length < 5) {
      return str;
    }

    const startStr = toStrNumber.slice(0, 4);

    let strNumberChunks = arrayUtils.chunk(Array.from(toStrNumber.slice(4)), 3);
    strNumberChunks = strNumberChunks.map((chunk) => chunk.join(''));

    if (strNumberChunks.length < 3) {
      return `(${startStr}) ${strNumberChunks.join(' ')}`;
    }

    if (strNumberChunks.length === 3) {
      strNumberChunks[strNumberChunks.length - 2] = strNumberChunks[
        strNumberChunks.length - 2
      ].concat(strNumberChunks[strNumberChunks.length - 1]);

      strNumberChunks.length = strNumberChunks.length - 1;

      return `(${startStr}) ${strNumberChunks.join(' ')}`;
    }

    return '';
  },
  toMobileNumberAmount2: (str) => {
    const toMobileStr = [...str.slice(1, 5), ...str.slice(6)].join('');

    return format.toMobileNumberAmount(toMobileStr);
  },
  toPostalCodeAmount: (str) => {
    const hyphenChar = '-';

    const validNumbers = Array.from(str).reduce((prevArray, char) => {
      const arrTemp = prevArray;

      if (char === hyphenChar || isNaN(char)) {
        return arrTemp;
      }

      if (!isNaN(char)) {
        arrTemp.push(char);

        return arrTemp;
      }

      return arrTemp;
    }, []);

    return validNumbers.join('');
  },
  toPostalCodeStr: (str, max = 7) => {
    const toStrNumber = format.toPostalCodeAmount(str);

    if (str.length < 4) {
      return str;
    }

    const startStr = toStrNumber.slice(0, 3);

    let strNumberChunks = arrayUtils.chunk(
      Array.from(toStrNumber.slice(3, max)),
      4
    );
    strNumberChunks = strNumberChunks.map((chunk) => chunk.join(''));

    return `${startStr}-${strNumberChunks.join(' ')}`;
  },
  toCardNumberStr: (str) => {
    const toStrNumber = format.toCardNumberAmount(str);

    if (str.length < 5) {
      return str;
    }

    let strNumberChunks = arrayUtils.chunk(Array.from(toStrNumber.slice(0)), 4);
    strNumberChunks = strNumberChunks.map((chunk) => chunk.join(''));

    if (strNumberChunks.length < 5) {
      return `${strNumberChunks.join(' - ')}`;
    }

    return '';
  },
  toCardNumberAmount: (str) => {
    const spaceChar = ' ';

    const validNumbers = Array.from(str).reduce((prevArray, char) => {
      const arrTemp = prevArray;

      if (char === spaceChar || isNaN(char)) {
        return arrTemp;
      }

      if (!isNaN(char)) {
        arrTemp.push(char);

        return arrTemp;
      }

      return arrTemp;
    }, []);

    return validNumbers.join('');
  },
  toCardExpiryDateStr: (str) => {
    return str
      .replace(
        /^([1-9]\/|[2-9])$/g,
        '0$1/' // 3 > 03/
      )
      .replace(
        /^(0[1-9]|1[0-2])$/g,
        '$1/' // 11 > 11/
      )
      .replace(
        /^([0-1])([3-9])$/g,
        '0$1/$2' // 13 > 01/3
      )
      .replace(
        /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
        '$1/$2' // 141 > 01/41
      )
      .replace(
        /^([0]+)\/|[0]+$/g,
        '0' // 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\\/]|^[\\/]*$/g,
        '' // To allow only digits and `/`
      )
      .replace(
        /\/\//g,
        '/' // Prevent entering more than 1 `/`
      );
  },
  toWalletExpDateStr: (str) => {
    if (str) {
      return (
        str[2].toString().padStart(2, '0') +
        '/' +
        str[1].toString().padStart(2, '0') +
        '/' +
        str[0]
      );
    }
    return null;
  },
};
