import DiscountsTabInTxn from 'components/Profile/components/DiscountsTabInTxn';
import {
  ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  DESKTOP_MIN_WIDTH,
  TABLET_MAX_WIDTH,
} from 'constants';
import { FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME } from 'constants/fx';
import Cookies from 'helpers/cookies';
import { MAKE_PAYMENT_KEY } from 'hooks/form-add-transaction/useAddTransaction';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import { useEffect, useMemo, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import styled from 'styled-components';
import DiscountApplied from '../DiscountApplied';
import DiscountAvailableSection from '../DiscountAvailableSection';

const DiscountsDrawer = ({ onApplyError, isFx = false }) => {
  const { t } = useLang();
  const { state } = useStore();
  let { addTransactionInformation } = state;

  const { addTransactionInformation: fxAddTransactionInformation } =
    useFxFormAddTransactionStore();

  addTransactionInformation = isFx
    ? fxAddTransactionInformation
    : addTransactionInformation;

  const { paymentMethod: paymentMethodStore } = addTransactionInformation || {};

  const { isMakePayment: isFxMakePayment } = useFxAddTransaction();

  const query = useQuery();
  const refNumber = query.get('refNumber');
  const isMakePayment = isFx
    ? isFxMakePayment
    : Cookies.get(MAKE_PAYMENT_KEY) || refNumber;

  const {
    discountsAvailable: discountsAvailableStore,
    discountsInactive,
    discountApplied,
  } = paymentMethodStore || {};

  const discountsAvailable = Array.isArray(discountsAvailableStore)
    ? discountsAvailableStore
    : [];

  const [isShowNavbar, setShowNavbar] = useState(false);

  const paymentMethod = isFx
    ? paymentMethodStore[FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME]
    : paymentMethodStore[ADD_TRANSACTION_PAYMENT_METHOD_NAME];

  const isShowDiscountsAvailableSection = Boolean(
    !discountApplied && paymentMethod
  );

  /* eslint-disable no-extra-boolean-cast */
  const isHideDiscountAppliedSection =
    !Boolean(discountApplied) && Boolean(isMakePayment);

  const isShowDiscountAppliedSection = Boolean(discountApplied);

  const handleOnToggleNavbar = () => {
    if (isShowDiscountAppliedSection) {
      return;
    }

    setShowNavbar(!isShowNavbar);
  };

  useEffect(() => {
    if (discountApplied) {
      setShowNavbar(false);
    }
  }, [discountApplied]);

  const navbarToggle = useMemo(() => {
    if (isShowDiscountsAvailableSection) {
      return (
        <DiscountAvailableSection
          isFx={isFx}
          quantity={discountsAvailable?.length}
          onClick={handleOnToggleNavbar}
        />
      );
    }

    if (isShowDiscountAppliedSection) {
      return <DiscountApplied isFx={isFx} isMakePayment={isMakePayment} />;
    }

    return <></>;
  }, [
    isShowDiscountsAvailableSection,
    isShowDiscountAppliedSection,
    discountsAvailable,
    discountApplied,
  ]);

  return (
    <>
      <style>
        {`
          .discount-drawer-offcanvas {
            width: 100%!important;
            height: 100%;
            border: none!important;
          }
          .discount-drawer-offcanvas-backdrop {
            opacity: 0.25!important;
          }
          @media screen and (max-width: ${TABLET_MAX_WIDTH}px) {
            .discount-drawer-offcanvas {
              height: calc(100% - 70px);
              margin-top: 70px;
            }
          }
          @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
            .discount-drawer-offcanvas {
              width: 375px!important;
            }
          }
        `}
      </style>
      <DiscountsDrawerStyled
        style={{
          display:
            ((!isShowDiscountsAvailableSection &&
              !isShowDiscountAppliedSection) ||
              isHideDiscountAppliedSection) &&
            'none',
        }}
      >
        <NavbarStyled
          expand={false}
          expanded={isShowNavbar}
          onToggle={handleOnToggleNavbar}
        >
          <NavbarToggle aria-controls="offcanvasNavbar-expand-false">
            {navbarToggle}
          </NavbarToggle>
          <NavbarOffcanvas
            id="offcanvasNavbar-expand-false"
            aria-labelledby="offcanvasNavbarLabel-expand-false"
            placement="end"
            className="discount-drawer-offcanvas"
            backdropClassName="discount-drawer-offcanvas-backdrop"
          >
            <OffcanvasHeader closeButton>
              <OffcanvasTitle id="offcanvasNavbarLabel-expand-false">
                {t('label_offers')}
              </OffcanvasTitle>
            </OffcanvasHeader>
            <OffcanvasBody>
              <ContentBody>
                <DiscountsTabInTxn
                  itemsActive={discountsAvailable}
                  itemsInactive={discountsInactive}
                  onApplyError={onApplyError}
                />
              </ContentBody>
            </OffcanvasBody>
          </NavbarOffcanvas>
        </NavbarStyled>
      </DiscountsDrawerStyled>
    </>
  );
};

const DiscountsDrawerStyled = styled.div``;

const NavbarStyled = styled(Navbar)`
  display: initial;
  padding: 0;
`;
const NavbarToggle = styled(Navbar.Toggle)`
  padding: 0;
  border: none;
  width: 100%;

  &:focus {
    border: none;
    box-shadow: none;
  }
`;

const NavbarOffcanvas = styled(Navbar.Offcanvas)``;
const OffcanvasHeader = styled(Offcanvas.Header)`
  height: 70px;
  padding: 16px 24px;
  border-bottom: 1px solid #eef2f5;

  & button {
    background-size: 14px;
    color: var(--ds-c-blue);
    filter: var(--ds-f-c-blue);
    opacity: 1;

    &:hover {
      color: var(--ds-c-blue-hover);
    }

    &:focus {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: 56px;
  }
`;
const OffcanvasTitle = styled(Offcanvas.Title)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--ds-c-blue);
  text-transform: uppercase;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const OffcanvasBody = styled(Offcanvas.Body)`
  padding: 24px;
`;

const ContentBody = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export default DiscountsDrawer;
