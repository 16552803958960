import React, { useEffect } from 'react';
import styled from 'styled-components';

const HamburgerIconAnimation = ({ isOpen, color = '#fff' }) => {
  const handleOnToggle = (isOpen) => {
    const ele = document.getElementById('hamburger-icon-animation');
    if (isOpen) {
      ele.classList.add('change');
    } else {
      ele.classList.remove('change');
    }
  };

  useEffect(() => {
    handleOnToggle(isOpen);
  }, [isOpen]);

  return (
    <Icon
      id="hamburger-icon-animation"
      className=""
      $color={color}
      onClick={handleOnToggle}
    >
      <div className="bar1"></div>
      <div className="bar2"></div>
      <div className="bar3"></div>
    </Icon>
  );
};

const Icon = styled.div`
  display: inline-block;
  cursor: pointer;
  width: 40px;
  padding-inline: 8px;
  margin-left: 4px;

  & .bar1,
  & .bar2,
  & .bar3 {
    width: 24px;
    height: 2px;
    background-color: ${(props) => props.$color};
    margin: 6px 0;
    transition: 0.4s;
  }

  &.change .bar1 {
    transform: translate(0, 8px) rotate(-45deg);
  }

  &.change .bar2 {
    opacity: 0;
  }

  &.change .bar3 {
    transform: translate(0, -8px) rotate(45deg);
  }
`;

export default HamburgerIconAnimation;
