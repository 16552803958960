import { SORT_DESC } from 'constants';
import { SORT_ASC } from 'constants';

export const sort = {
  arrayObjectByDate: (array, type, prop) => {
    const converDate = (dateStr) => {
      const dateStrs = dateStr.split('/'); // dateStrs is format by 'dd/MM/yyyy'
      const dd = dateStrs[0];
      const MM = dateStrs[1];
      const yyyy = dateStrs[2];

      return new Date(MM + '/' + dd + '/' + yyyy);
    };

    array = array.sort((e1, e2) => {
      if (type === SORT_ASC)
        return converDate(e1[prop]) > converDate(e2[prop]) ? 1 : -1;

      if (type === SORT_DESC)
        return converDate(e1[prop]) > converDate(e2[prop]) ? -1 : 1;

      return 0;
    });

    return array;
  },
  arrayObjectByNumber: (array, type, prop) => {
    array = array.sort((e1, e2) => {
      if (type === SORT_ASC)
        return parseInt(e1[prop]) > parseInt(e2[prop]) ? 1 : -1;

      if (type === SORT_DESC)
        return parseInt(e1[prop]) > parseInt(e2[prop]) ? -1 : 1;

      return 0;
    });

    return array;
  },
  arrayObjectByString: (array, type, prop) => {
    array = array.sort((e1, e2) => {
      if (type === SORT_ASC) return e1[prop] > e2[prop] ? 1 : -1;

      if (type === SORT_DESC) return e1[prop] > e2[prop] ? -1 : 1;

      return 0;
    });

    return array;
  },
};
