import LoginIcon from 'assets/ver2/icons/fx-login-icon.svg';
import FormFxLogIn from 'components/FormFxLogin';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import React from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';

const FxLoginPage = () => {
  const { t } = useLang();
  return (
    <FxLoginPageStyled>
      <TitleImage src={LoginIcon} width={123} height={100} />
      <Title>{t('fx_login_title')}</Title>
      <Desc>{t('fx_login_desc')}</Desc>
      <FormFxLogIn />
    </FxLoginPageStyled>
  );
};

const FxLoginPageStyled = styled.div`
  margin: 70px 0px 0px 0px;
  padding: 24px 24px;
  height: calc(100vh - 70px);
  width: 100%;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin: unset;
    padding: unset;
    height: fit-content;
    width: 436px;
    margin-inline: auto;
    margin-top: 104px;
  }
`;

const Title = styled.h2`
  ${paragraphMixin};
  margin-bottom: 10px;
  text-align: center;
`;

const Desc = styled.h2`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);
  margin-bottom: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const TitleImage = styled.img`
  display: block;
  margin-inline: auto;
  margin-bottom: 10px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 16px;
  }
`;

export default FxLoginPage;
