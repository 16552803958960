import { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import styled from 'styled-components';

import EwalletIcon from 'assets/icons/ewallet-icon.svg';
import ArrowDownSVG from 'assets/ver2/icons/arrow-down-icon.svg';
import { getNumberUnmaskStr } from 'components/FormEditReceiver/func';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { format, formatter } from 'helpers';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';

const CustomEwalletsAccordion = ({ ewallets, countryConfig }) => {
  const { t } = useLang();

  const [currentEwallet, setCurrentEwallet] = useState(-1);

  const config = useGetConfig({ countryConfig });
  const { ewalletNumber, ewalletExpire } = config;

  const numberFormat = formatter.formatParse(ewalletNumber?.format);

  const handleAccordionItemOnClick = (index) => {
    if (currentEwallet === index) {
      setCurrentEwallet(-1);

      return;
    }

    setCurrentEwallet(index);
  };

  return (
    <FieldWrap>
      <FieldIcon $isCenter={ewallets.length <= 1}>
        <img src={EwalletIcon} width={24} height={24} alt="" />
        {`${t('label_ewallet')} (${ewallets.length})`}
      </FieldIcon>
      {ewallets?.length ? (
        <AccordionStyled>
          {ewallets.map((ewallet, index) => (
            <AccordionItem
              key={index}
              eventKey={index}
              onClick={() => handleAccordionItemOnClick(index)}
            >
              <AccordionHeader>
                <AccordionHeaderText>
                  {ewallet?.ewalletProviderName}
                </AccordionHeaderText>
                <AccordionHeaderBody>
                  {currentEwallet === index && (
                    <>
                      <Divider />
                      {ewalletNumber?.visible && (
                        <FieldBodyWrap>
                          <p>
                            {t('label_ewallet_id')}:{' '}
                            {ewallet?.ewalletNumber
                              ? formatter.mask(
                                  getNumberUnmaskStr(
                                    ewallet?.ewalletNumber,
                                    numberFormat
                                  ),
                                  numberFormat
                                )
                              : ''}
                          </p>
                        </FieldBodyWrap>
                      )}
                      {ewalletExpire?.visible && (
                        <FieldBodyWrap>
                          <p>
                            {t('label_expiry_date')}:{' '}
                            {format.toWalletExpDateStr(ewallet?.expiryDate)}
                          </p>
                        </FieldBodyWrap>
                      )}
                    </>
                  )}
                </AccordionHeaderBody>
              </AccordionHeader>
            </AccordionItem>
          ))}
        </AccordionStyled>
      ) : (
        <NoEwallet>{t('label_no_ewallet')}</NoEwallet>
      )}
    </FieldWrap>
  );
};

const FieldWrap = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  min-height: 40px;
  margin-top: 16px;
`;
const FieldIcon = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0e1012;

  display: flex;
  justify-content: start;
  align-items: center;
  height: 72px;
  width: 170px;
  padding: ${(props) => (props.$isCenter ? '12px 0' : '22px 0')};

  & img {
    margin-right: 8px;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const AccordionStyled = styled(Accordion)`
  width: 437px;
`;
const AccordionItem = styled(Accordion.Item)`
  border: none;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0px;
  }
`;
const AccordionHeader = styled(Accordion.Header)`
  height: fit-content;
  padding: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  & button {
    padding: 0;
    background-color: var(--ds-c-white) !important;
    box-shadow: none !important;
    display: block;

    &:focus,
    &:hover {
      z-index: 1;
    }

    &::after,
    &:not(.collapsed)::after {
      background-image: url(${ArrowDownSVG});
      background-position: center;
      background-size: 24px 24px;

      position: absolute;
      top: 10px;
      right: 0px;
    }
  }
`;
const AccordionHeaderText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  padding: 0;
  padding-right: 20px;
  margin: 0;
  height: 40px;
  display: flex;
  align-items: center;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const AccordionHeaderBody = styled.div``;
const FieldBodyWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: fit-content;
  margin-top: 8px;

  & p {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--ds-c-grey-dark);

    cursor: text;
    margin: 0;
    padding: 0;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const NoEwallet = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #b0b6bd;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 437px;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #eef2f5;
  margin: 16px 0;
`;

export default CustomEwalletsAccordion;
