import { createContext, useReducer } from 'react';

import { RESET_FX_ADD_TRANSACTION_INFORMATION_STORE } from 'constants/fx';
import {
  SET_FX_ADD_TRANSACTION_AS_GUEST,
  SET_FX_ADD_TRANSACTION_CURRENT_STEP,
  SET_FX_ADD_TRANSACTION_INFORMATION,
  SET_FX_CREATE_TXN_RESPONSE,
  SET_FX_TOKEN_TRANSACTION,
  SET_FX_TRANSACTION_DETAILS,
  SET_DENOMINATIONS,
  ADD_DENOMINATION,
  REMOVE_DENOMINATION,
  CLEAR_DENOMINATIONS,
} from './action';

const initialState = {
  addTransactionInformation: RESET_FX_ADD_TRANSACTION_INFORMATION_STORE,
  addTransactionCurrentStep: 0,
  transactionDetails: null,
  tokenTransaction: '',
  isAddTransactionAsGuest: true,
  createTxnResponse: null,
  denominations: {},
};

const store = createContext(initialState);
const { Provider } = store;
let stateStore;
let dispatchStore;

const FxStateProvider = ({ children }) => {
  let [state, dispatch] = useReducer((state, action) => {
    const { type, payload } = action;

    switch (type) {
      case SET_FX_ADD_TRANSACTION_INFORMATION:
        return {
          ...state,
          addTransactionInformation: {
            ...state.addTransactionInformation,
            ...payload,
          },
        };
      case SET_FX_ADD_TRANSACTION_CURRENT_STEP:
        return {
          ...state,
          addTransactionCurrentStep: payload,
        };
      case SET_FX_TRANSACTION_DETAILS:
        return {
          ...state,
          transactionDetails: payload,
        };
      case SET_FX_TOKEN_TRANSACTION:
        return {
          ...state,
          tokenTransaction: payload,
        };
      case SET_FX_ADD_TRANSACTION_AS_GUEST:
        return {
          ...state,
          isAddTransactionAsGuest: payload,
        };
      case SET_FX_CREATE_TXN_RESPONSE:
        return {
          ...state,
          createTxnResponse: payload,
        };
      case SET_DENOMINATIONS:
        return {
          ...state,
          denominations: payload,
        };
      case ADD_DENOMINATION: {
        const { currencyCode, selection } = payload;
        return {
          ...state,
          denominations: {
            ...state.denominations,
            [currencyCode]: selection, // Update or add
          },
        };
      }
      case REMOVE_DENOMINATION: {
        const { currencyCode } = payload;
        const updatedDenominations = { ...state.denominations };
        delete updatedDenominations[currencyCode];
        return {
          ...state,
          denominations: updatedDenominations,
        };
      }
      case CLEAR_DENOMINATIONS: {
        return {
          ...state,
          denominations: {}, // Clear all
        };
      }
      default:
        return state;
    }
  }, initialState);

  stateStore = state;
  dispatchStore = dispatch;

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { FxStateProvider, dispatchStore, stateStore, store };
