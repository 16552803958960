/* eslint-disable no-unused-vars */
import usePortalModal from 'hooks/usePortalModal.jsx';
import { functionUtils } from 'utils/func.js';
import EventIframePopup from '../popups/EventIframePopup/index.jsx';

const EventIframeModal = ({
  isShow,
  modalStyles,
  modalOptions,
  actionClose,
  action,
}) => {
  const { closePortalModalName } = usePortalModal();

  const handleOnClick = () => {
    if (functionUtils.isFunction(action)) {
      action();
    }
    closePortalModalName();
  };

  const handleOnClose = () => {
    if (functionUtils.isFunction(actionClose)) {
      actionClose();
    }
    closePortalModalName();
  };

  return (
    <EventIframePopup
      isOpen={isShow}
      modalStyles={modalStyles}
      modalOptions={modalOptions}
      onClose={handleOnClose}
      onClick={handleOnClick}
      isBackdropOverlap={true}
      zIndexBackdropOverlap={1075}
      zIndexModal={1076}
    />
  );
};

export default EventIframeModal;
