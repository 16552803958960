export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const MOBILE_PHONE_REGEX = /^04\+?(\d){8}$/;

export const TEXT_ONLY_REGEX = /^[a-zA-Z ]*$/;

export const MESSAGE_REGEX = /^[a-zA-Z0-9 .,-]*$/;

export const NUMBER_REGEX = /^\d+$/;

export const AMOUNT_NUMBER_REGEX = /^[\d+.]*/;

export const ADDRESS_REGEX = /^[A-Za-z0-9 ,/-]*$/;

export const ADDRESS_TYPE_2_REGEX = /^[A-Za-z0-9 ,/-]*$/;

export const ADDRESS_TYPE_3_REGEX = /^[A-Za-z0-9 /-]*$/;

export const LENGTH_IS_8_REGEX = /^(?=.*).{8,}$/;

export const LEAST_ONE_NUMBER_REGEX = /^.*[0-9].*$/;

export const LEAST_LOWERCASE_REGEX = /^.*[a-z].*$/;

export const LEAST_UPPERCASE_REGEX = /^.*[A-Z].*$/;

export const PASSWORD_REGEX = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d].{7,}/;

export const REF_NUMBER_REGEX = /^[A-Za-z0-9-]{6,15}$/;

export const ACCOUNT_NUMBER_REGEX = /^\d{6,25}$/;

export const VISA_REGEX = /^4[0-9]{6,}$/;

export const MASTERCARD_REGEX =
  /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/;

export const DATE_REGEX = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;

export const BUSINESS_NAME_REGEX = /^[A-Za-z0-9 ().,-]*$/;

export const TEXT_TRIM_REGEX = new RegExp('[(a-zA-Z)().,-]*', 'gmi');
