const TransactionIcon = ({ color = 'var(--ds-c-grey-dark)', size }) => {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1641 9.59996L15.5999 9.59996L15.5999 21.8324L11.9999 20.6324L8.9999 21.6324L5.9999 20.6324L2.3999 21.8324V6.95701C2.39988 6.05075 2.39987 5.31016 2.47848 4.72547C2.56065 4.11431 2.73847 3.58444 3.16143 3.16149C3.58438 2.73853 4.11425 2.56071 4.72541 2.47854C5.31009 2.39993 6.05066 2.39994 6.95691 2.39996H17.9999C19.9881 2.39996 21.5999 4.01174 21.5999 5.99996L21.5999 8.16416C21.5999 8.31256 21.6 8.46357 21.5875 8.59187C21.5736 8.73556 21.5397 8.91263 21.4313 9.08519C21.3434 9.22512 21.2251 9.34343 21.0851 9.43135C20.9126 9.53978 20.7355 9.57364 20.5918 9.58757C20.4635 9.60002 20.3125 9.59999 20.1641 9.59996ZM15.5999 5.99996C15.5999 4.67448 16.6744 3.59996 17.9999 3.59997C19.3254 3.59996 20.3999 4.67448 20.3999 5.99996V8.14282C20.3999 8.22797 20.3999 8.29501 20.3988 8.35286C20.3985 8.36934 20.3981 8.38423 20.3977 8.39773C20.3842 8.39818 20.3693 8.39856 20.3528 8.39886C20.295 8.39993 20.2279 8.39996 20.1428 8.39996L15.5999 8.39996V5.99996ZM6.3999 7.00003C6.3999 6.66866 6.66853 6.40003 6.9999 6.40003L10.9999 6.40003C11.3313 6.40003 11.5999 6.66866 11.5999 7.00003C11.5999 7.3314 11.3313 7.60003 10.9999 7.60003L6.9999 7.60003C6.66853 7.60003 6.3999 7.3314 6.3999 7.00003ZM7.99986 11.6C8.33123 11.6 8.59986 11.3314 8.59986 11C8.59986 10.6686 8.33123 10.4 7.99986 10.4H6.99986C6.66848 10.4 6.39986 10.6686 6.39986 11C6.39986 11.3314 6.66848 11.6 6.99986 11.6H7.99986ZM6.3999 15C6.3999 14.6687 6.66853 14.4 6.9999 14.4H9.9999C10.3313 14.4 10.5999 14.6687 10.5999 15C10.5999 15.3314 10.3313 15.6 9.9999 15.6H6.9999C6.66853 15.6 6.3999 15.3314 6.3999 15Z"
        fill={color || '#0E1012'}
      />
    </svg>
  );
};

export default TransactionIcon;
