/* eslint-disable no-unused-vars */
import RadioCheckedIcon from 'assets/icons/radio-checked-icon.svg';
import RadioboxHHMTIcon from 'assets/icons/radiobox-hhmt-icon.svg';
import RadioboxRemoxIcon from 'assets/icons/radiobox-remox-icon.svg';
import { ButtonFormCheck } from 'components/FormEditReceiver/components/FormInputSelectTypeOfReceiverRadio';
import {
  DESKTOP_MIN_WIDTH,
  REGISTER_DRIVER_LICENCE_BACK_NAME,
  REGISTER_DRIVER_LICENCE_FRONT_NAME,
  REGISTER_DRIVER_LICENCE_SWITCH,
  REGISTER_PASSPORT_NAME,
} from 'constants';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { domUtils } from 'utils';

const FormInputDocumentUploadSwitch = ({ information, setInformation }) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();

  const { isRegisterDriverLicenceSelected } = information;

  const handleChangeDocumentUpload = (e, docType) => {
    e.preventDefault();
    e.stopPropagation();

    let newInformation = {
      ...information,
      isRegisterDriverLicenceSelected: !isRegisterDriverLicenceSelected,
    };

    if (docType === 'DL') {
      newInformation = {
        ...newInformation,
        [REGISTER_PASSPORT_NAME]: null,
      };
    }

    if (docType === 'P') {
      newInformation = {
        ...newInformation,
        [REGISTER_DRIVER_LICENCE_FRONT_NAME]: null,
        [REGISTER_DRIVER_LICENCE_BACK_NAME]: null,
      };
    }

    setInformation(newInformation);
  };

  const renderFormCheckRadio1 = () => {
    const formCheckRadioClassName1 = REGISTER_DRIVER_LICENCE_SWITCH + '1';

    return (
      <>
        <FormCheck
          inline
          type="radio"
          label={t('button_driver_licence')}
          className={`${formCheckRadioClassName1} ${
            isRegisterDriverLicenceSelected && 'active'
          }`}
          name={REGISTER_DRIVER_LICENCE_SWITCH}
          checked={isRegisterDriverLicenceSelected}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
        />
        <ButtonFormCheck
          $width={
            domUtils.getOffsetWidthByClassName(formCheckRadioClassName1) + 44
          }
          onClick={(e) => handleChangeDocumentUpload(e, 'DL')}
        >
          {t('button_driver_licence')}
        </ButtonFormCheck>
      </>
    );
  };

  const renderFormCheckRadio2 = () => {
    const formCheckRadioClassName2 = REGISTER_DRIVER_LICENCE_SWITCH + '2';

    return (
      <>
        <FormCheck
          inline
          type="radio"
          label={t('button_passport')}
          className={`${formCheckRadioClassName2} ${
            !isRegisterDriverLicenceSelected && 'active'
          }`}
          name={REGISTER_DRIVER_LICENCE_SWITCH}
          checked={!isRegisterDriverLicenceSelected}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
          style={{ marginRight: 0 }}
        />
        <ButtonFormCheck
          $width={domUtils.getOffsetWidthByClassName(formCheckRadioClassName2)}
          onClick={(e) => handleChangeDocumentUpload(e, 'P')}
        >
          {t('button_passport')}
        </ButtonFormCheck>
      </>
    );
  };

  return (
    <FormGroupStyled>
      <FormLabel onClick={(e) => e.preventDefault()}>
        {t('registration_title_upload')}
      </FormLabel>
      {renderFormCheckRadio1()}
      {renderFormCheckRadio2()}
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 8px;
  position: relative;

  img {
    width: 100%;
    height: 230px;
    object-fit: contain;
    border-radius: 12px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  display: block;
  color: var(--ds-c-grey-dark);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormCheck = styled(Form.Check)`
  margin: 0;
  margin-right: 44px;

  &:last-child {
    margin-right: 0px;
  }

  label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    margin-left: 0px;
    cursor: pointer !important;
    user-select: none;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  input:checked[type='radio'] {
    background-image: url(${RadioCheckedIcon}) !important;
    background-position: center !important;
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: ${(props) => `url(${props.$checkboxSrc}) !important`};
    background-position: center !important;
    width: 16px;
    height: 16px;
    cursor: pointer !important;

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }
  }

  &.active {
    label {
      font-weight: 700;
    }
  }
`;

export default FormInputDocumentUploadSwitch;
