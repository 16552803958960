import {
  REGISTER_EMAIL_FIELD,
  REGISTER_EMAIL_NAME,
  REGISTER_LOGIN_DETAILS_FIELDS,
  REGISTER_MOBILE_FIELD,
  REGISTER_REEMAIL_FIELD,
} from 'constants';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

import { api } from 'api';
import {
  DESKTOP_MIN_WIDTH,
  REGISTER_MOBILE_NAME,
  REGISTER_REEMAIL_NAME,
  REGISTER_STEP_REFERENCE,
  RESET_REGISTER_INFORMATION_STORE,
} from 'constants';
import { formatter } from 'helpers';
import useSmartLook from 'hooks/smartlook/useSmartLook';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import { Fade } from 'react-bootstrap';
import { SET_REGISTER_INFORMATION, SET_SHOW_LOADING } from 'store/action';
import ButtonCommon from '../../common/ButtonCommon';
import FormInputInformCheckbox from './components/FormInputInformCheckbox';
import FormInputMobileNumberValidation from './components/FormInputMobileNumberValidation';
import FormInputValidation from './components/FormInputValidation';
import { checkFormInputValidation } from './func';

const initialFormField = RESET_REGISTER_INFORMATION_STORE.logInDetails;

const LogInDetails = ({
  currentStep,
  setCurrentStep,
  setSignupId,
  registerPayload,
  setRegisterPayload,
  handleOnToggleCancelPopup,
}) => {
  const { t } = useLang();
  const query = useQuery();
  const signupIdQuery = query.get('signupId');
  const { state, dispatch } = useStore();
  const { registerInformation } = state;
  const { isMobile, isTablet } = useDetectDevice();

  const { recordAPI } = useSmartLook({ isInit: false });

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [isEmailExisted, setEmailExisted] = useState(false);
  const [numberMaskStr, setNumberMaskStr] = useState(null);

  // eslint-disable-next-line no-useless-escape
  const numberFormat = `[\"#### ### ###\"]`;
  // eslint-disable-next-line no-useless-escape
  const prefixFormat = `[\"04\"]`;

  useEffect(() => {
    const informationStore = registerInformation.logInDetails;
    setInformation(informationStore);

    setNumberMaskStr(
      formatter.mask(
        informationStore[REGISTER_MOBILE_NAME],
        formatter.formatParse(numberFormat)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerInformation]);

  const handleNextStep = async () => {
    let newInformation = { ...information };
    let newValidation = { ...validation };

    delete newInformation['isOptinNews'];
    delete newValidation['isOptinNews'];

    const isAllowSubmit =
      Object.values(newInformation).every((value) => value) &&
      Object.values(newValidation).every((value) => !value);

    if (isAllowSubmit) {
      dispatch({ type: SET_SHOW_LOADING, payload: true });

      try {
        const { data } = await api.isUsernameValid(
          information[REGISTER_EMAIL_NAME]
        );

        if (data?.valid) {
          const payload = {
            ...registerPayload,
            onlineSignupId: signupIdQuery || 0,
            step: 1,
            email: information[REGISTER_EMAIL_NAME],
            mobile: information[REGISTER_MOBILE_NAME],
            optinNews: information.isOptinNews,
          };
          setRegisterPayload(payload);

          recordAPI.identify(information[REGISTER_EMAIL_NAME]);

          const { status, data } = await api.saveSignup(payload);

          if (status === 200) {
            setSignupId(data.onlineSignupId);

            dispatch({
              type: SET_REGISTER_INFORMATION,
              payload: {
                ...registerInformation,
                [REGISTER_STEP_REFERENCE[currentStep]]: information,
              },
            });

            dispatch({ type: SET_SHOW_LOADING, payload: false });

            setCurrentStep(currentStep + 1);

            if ((isMobile || isTablet) && !signupIdQuery) {
              window.history.pushState(
                {},
                null,
                `?signupId=${data.onlineSignupId}`
              );
            }
          }
        } else {
          newValidation = {
            ...newValidation,
            [REGISTER_EMAIL_NAME]: REGISTER_LOGIN_DETAILS_FIELDS[0].msgExisted,
          };
          setValidation(newValidation);

          setEmailExisted(true);

          dispatch({ type: SET_SHOW_LOADING, payload: false });
        }
      } catch (error) {
        console.error(error?.message);

        dispatch({ type: SET_SHOW_LOADING, payload: false });
      }

      return;
    }

    if (!isAllowSubmit) {
      newValidation = checkFormInputValidation(
        information[REGISTER_EMAIL_NAME],
        REGISTER_EMAIL_FIELD,
        information,
        newValidation
      );

      newValidation = checkFormInputValidation(
        information[REGISTER_REEMAIL_NAME],
        REGISTER_REEMAIL_FIELD,
        information,
        newValidation
      );

      if (!newValidation[REGISTER_MOBILE_NAME]) {
        newValidation = checkFormInputValidation(
          information[REGISTER_MOBILE_NAME],
          REGISTER_MOBILE_FIELD,
          information,
          newValidation
        );
      }

      setValidation(newValidation);
    }
  };

  return (
    <LogInDetailsStyled>
      <Title>{t('registration_title_login_details')}</Title>
      <FormInputValidation
        field={REGISTER_EMAIL_FIELD}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        isEmailExisted={isEmailExisted}
        setEmailExisted={setEmailExisted}
      />
      <FormInputValidation
        field={REGISTER_REEMAIL_FIELD}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
        isEmailExisted={isEmailExisted}
        setEmailExisted={setEmailExisted}
      />
      <FormInputMobileNumberValidation
        field={REGISTER_MOBILE_FIELD}
        config={{
          visible: true,
          required: true,
          prefix: prefixFormat,
          format: numberFormat,
          example: '',
          max: 10,
          min: 10,
        }}
        numberMaskStr={numberMaskStr}
        setNumberMaskStr={setNumberMaskStr}
        information={information}
        setInformation={setInformation}
        validation={validation}
        setValidation={setValidation}
      />
      <FormInputInformCheckbox
        information={information}
        setInformation={setInformation}
      />
      <Fade in appear>
        <NextWrap>
          <ButtonCommon
            value={t('button_cancel')}
            onClick={handleOnToggleCancelPopup}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--c-primary)"
            background="var(--ds-c-white)"
          />
          <ButtonCommon
            value={t('button_next')}
            styles={{
              margin: '0px',
              width: '50%',
            }}
            color="var(--c-primary)"
            background="var(--bg-primary)"
            onClick={handleNextStep}
            isUseKeyDown
            isFill={true}
          />
        </NextWrap>
      </Fade>
    </LogInDetailsStyled>
  );
};

const LogInDetailsStyled = styled(Form)`
  position: relative;
  width: 100%;
  height: fit-content;
  margin-top: 24px;
  padding-bottom: 96px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    position: unset;
    width: 437px;
    height: fit-content;
    margin-top: 40px;
    margin-inline: auto;
  }
`;

const Title = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;

  display: flex;
  align-items: center;
  color: var(--ds-c-grey-dark);
  margin-top: 0px;
  margin-bottom: 16px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 32px;
    line-height: 38px;

    margin-bottom: 24px;
  }
`;

const NextWrap = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--ds-c-white);
  margin-inline: -24px;
  padding: 16px 24px;
  width: 100%;
  border-top: 1px solid var(--ds-c-grey-disabled);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    position: unset;
    margin-top: 40px;
    margin-bottom: 24px;
    margin-inline: unset;
    padding-inline: unset;
    height: 40px;
    border-top: none;
  }
`;

export default LogInDetails;
