import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { forwardRef } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { stringUtils } from 'utils';
import CheckboxIcon from '../../../../../assets/icons/checkbox-icon.svg';
import CheckedIcon from '../../../../../assets/icons/checked-icon.svg';
import RegisterSelfie from '../../../../../assets/images/register-selfie.png';
import {
  DESKTOP_MIN_WIDTH,
  HOME_URL,
  REGISTER_DOCUMENT_UPLOAD_SELFIE_CHECKBOX_FIELD,
  REGISTER_DOCUMENT_UPLOAD_SELFIE_FIELD,
  REGISTER_SELFIE_CHECKBOX,
} from '../../../../../constants';
import FormInputFileUploadValidation from '../FormInputFileUploadValidation';

const FormInputDocumentUploadSelfie = forwardRef(
  (
    {
      information,
      setInformation,
      validation,
      setValidation,
      isSelfieChecked,
      setSelfieChecked,
    },
    ref
  ) => {
    const { t, locate } = useLang();
    const { theme } = useTheme();

    const handleOnChange = () => {
      setSelfieChecked(!isSelfieChecked);

      if (!isSelfieChecked) {
        const newValidation = {
          ...validation,
          [REGISTER_SELFIE_CHECKBOX]: '',
        };
        setValidation(newValidation);
      }
    };

    const { isRegisterDriverLicenceSelected } = information;
    const docType = isRegisterDriverLicenceSelected
      ? t('button_driver_licence')
      : t('button_passport');

    return (
      <Information>
        <InformationLabel>
          {stringUtils.replaceKeyword(t('registration_title_selfie_upload_2'), [
            {
              key: 'docType',
              value: docType,
            },
          ])}
        </InformationLabel>
        <Label>
          <span>*</span>
          {t('registration_accept_file_types')}
        </Label>
        <Image2 src={RegisterSelfie} />
        <Paragraph>{t('registration_selfie_upload')}</Paragraph>
        <ParagraphList>
          <li>{t('registration_selfie_upload_requirement_1')}</li>
          <li>{t('registration_selfie_upload_requirement_2')}</li>
          <li>{t('registration_selfie_upload_requirement_3')}</li>
        </ParagraphList>
        <FormUploadWrap>
          <FormInputFileUploadValidation
            field={REGISTER_DOCUMENT_UPLOAD_SELFIE_FIELD}
            docType="SELFIE"
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
          />
          <Image src={RegisterSelfie} />
        </FormUploadWrap>
        <ParagraphCheckbox
          ref={ref}
          $isError={validation?.[REGISTER_SELFIE_CHECKBOX]}
          onClick={handleOnChange}
        >
          <FormCheckStyled
            name={REGISTER_DOCUMENT_UPLOAD_SELFIE_CHECKBOX_FIELD.name}
            type={REGISTER_DOCUMENT_UPLOAD_SELFIE_CHECKBOX_FIELD.type}
            label={REGISTER_DOCUMENT_UPLOAD_SELFIE_CHECKBOX_FIELD.label}
            checked={isSelfieChecked}
            readOnly={REGISTER_DOCUMENT_UPLOAD_SELFIE_CHECKBOX_FIELD.isReadOnly}
          />
          <p
            dangerouslySetInnerHTML={{
              __html: stringUtils.replaceKeyword(
                t('form_checkbox_acknowledge_required'),
                [
                  {
                    key: 'lang',
                    value: locate,
                  },
                  {
                    key: 'host',
                    value: HOME_URL[theme],
                  },
                ]
              ),
            }}
          />
        </ParagraphCheckbox>
      </Information>
    );
  }
);

const Information = styled.div`
  margin-top: 24px;
  padding-bottom: 96px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 0px;
    padding-bottom: 0px;
  }
`;
const InformationLabel = styled.label`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  display: block;
  color: var(--ds-c-grey-dark);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  padding: 0;
  margin: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const ParagraphCheckbox = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: ${(props) =>
    props.$isError ? 'var(--ds-c-red)' : 'var(--ds-c-grey-dark)'};
  padding: 0;
  margin: 0;
  margin-left: 8px;
  margin-top: 24px;
  cursor: pointer !important;
  user-select: none;

  a {
    color: ${(props) =>
      props.$isError ? 'var(--ds-c-red)' : 'var(--ds-c-blue)'};
    text-decoration: none;

    &:hover {
      color: ${(props) =>
        props.$isError ? 'var(--ds-c-red-hover)' : 'var(--ds-c-blue-hover)'};
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-top: 0px;
    font-size: 14px;
    line-height: 17px;
  }
`;
const ParagraphList = styled.ul`
  padding: 0;
  padding-left: 24px;
  margin-bottom: 24px;

  li {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-dark);
    padding: 0;
    margin: 0;
    list-style: disc;
    list-style-type: disc;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 0px;
  }
`;

const Label = styled.label`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  padding: 0;
  padding-block: 8px;
  margin: 0;

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const Image2 = styled.img`
  width: 100%;
  height: auto;
  margin-top: 16px;
  object-fit: cover;
  margin-top: 0px;
  margin-bottom: 10px;
  border-radius: 12px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    display: none;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 207px;
  object-fit: cover;
  margin-top: 0px;
  border-radius: 12px;
  display: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 207px;
    height: 207px;
    display: block;
    margin-top: 16px;
    object-position: left;
  }
`;

const FormUploadWrap = styled.div`
  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    display: flex;
    justify-content: space-between;
  }
`;

const FormCheckStyled = styled(Form.Check)`
  width: fit-content;
  float: left;
  margin-bottom: 70px;
  margin-right: 10px;

  input:checked[type='checkbox'] {
    background-image: url(${CheckedIcon});
    background-size: cover;
  }

  input {
    border: none;
    border-radius: 2px;
    background-image: url(${CheckboxIcon});
    background-size: cover;
    width: 26px;
    height: 26px;
    cursor: pointer !important;
    margin-top: 0px;

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 70px;
  }
`;

export default FormInputDocumentUploadSelfie;
