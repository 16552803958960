import LockFillIcon from 'assets/icons/lock-fill-icon.svg';
import LockIcon from 'assets/icons/lock-icon.svg';
import NotifyFillIcon from 'assets/icons/notification-dark-icon.svg';
import NotifyOutlineIcon from 'assets/icons/notification-outline-icon.svg';
import ProfileFillIcon from 'assets/icons/profile-dark-fill-icon.svg';
import ProfileIcon from 'assets/icons/profile-dark-icon.svg';
import {
  REGISTER_ADDRESS_FINDER_NAME,
  REGISTER_ADDRESS_POST_CODE_NAME,
  REGISTER_ADDRESS_STATE_NAME,
  REGISTER_ADDRESS_STREET_NAME_NAME,
  REGISTER_ADDRESS_STREET_NO_NAME,
  REGISTER_ADDRESS_STREET_TYPE_NAME,
  REGISTER_ADDRESS_SUBURB_NAME,
  REGISTER_ADDRESS_UNITNAME_NAME,
} from 'constants/form-register';
import { mobilePhoneValid, passwordValid, textValid } from 'helpers';

export const PROFILE_TABS = [
  {
    label: 'label_personal_details',
    iconOutline: ProfileIcon,
    iconFill: ProfileFillIcon,
  },
  {
    label: 'label_security',
    iconOutline: LockIcon,
    iconFill: LockFillIcon,
  },
  {
    label: 'label_notifications',
    iconOutline: NotifyOutlineIcon,
    iconFill: NotifyFillIcon,
  },
];

export const EDIT_PROFILE_FULL_NAME = 'edit-profile-full-name';
export const EDIT_PROFILE_FULL_NAME_FIELD = {
  label: 'form_full_name',
  name: EDIT_PROFILE_FULL_NAME,
  type: 'text',
  placeholder: '-',
  isRequired: false,
  isReadOnly: true,
};

export const EDIT_PROFILE_GENDER_NAME = 'edit-profile-gender-name';
export const EDIT_PROFILE_GENDER_FIELD = {
  label: 'form_gender',
  name: EDIT_PROFILE_GENDER_NAME,
  type: 'radio',
  isRequired: true,
  isReadOnly: false,
  msgRequired: 'form_required',
  checkValidFn: textValid,
};

export const EDIT_PROFILE_DOB_NAME = 'edit-profile-dob-name';
export const EDIT_PROFILE_DOB_FIELD = {
  label: 'form_date_of_birth',
  name: EDIT_PROFILE_DOB_NAME,
  type: 'text',
  placeholder: 'DD/MM/YYYY',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_date_of_birth_invalid_1',
  msgInvalid2: 'form_date_of_birth_invalid_2',
  msgRequired: 'form_required',
};

export const EDIT_PROFILE_EMAIL_NAME = 'edit-profile-email-name';
export const EDIT_PROFILE_EMAIL_NAME_FIELD = {
  label: 'form_email',
  name: EDIT_PROFILE_EMAIL_NAME,
  type: 'text',
  placeholder: '-',
  isRequired: false,
  isReadOnly: true,
};

export const EDIT_PROFILE_MOBILE_NAME = 'edit-profile-mobile-name';
export const EDIT_PROFILE_MOBILE_FIELD = {
  label: 'form_mobile',
  name: EDIT_PROFILE_MOBILE_NAME,
  type: 'tel',
  placeholder: 'form_mobile_placeholder_1',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_mobile_invalid_2',
  msgStartWith: 'form_mobile_invalid_3',
  msgRequired: 'form_required',
  checkValidFn: mobilePhoneValid,
};

export const EDIT_PROFILE_OCCUPATION_NAME = 'edit-profile-occupation-name';
export const EDIT_PROFILE_PREVIOUS_EMPLOYED_NAME =
  'edit-profile-previous-employed-name';
export const EDIT_PROFILE_OCCUPATION_FIELDS = [
  {
    label: 'form_occupation',
    name: EDIT_PROFILE_OCCUPATION_NAME,
    type: 'text',
    placeholder: 'form_placeholder_please_select',
    isRequired: true,
    isReadOnly: false,
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
  {
    label: 'form_previous_employed',
    name: EDIT_PROFILE_PREVIOUS_EMPLOYED_NAME,
    type: 'text',
    placeholder: 'form_placeholder_please_select',
    isRequired: true,
    isReadOnly: false,
    msgRequired: 'form_required',
    checkValidFn: textValid,
  },
];

export const RESET_EDIT_PROFILE_INFORMATION_STORE = {
  personalDetails: {
    [EDIT_PROFILE_FULL_NAME]: '',
    [EDIT_PROFILE_GENDER_NAME]: '',
    [EDIT_PROFILE_DOB_NAME]: '',
    [EDIT_PROFILE_EMAIL_NAME]: '',
    [EDIT_PROFILE_MOBILE_NAME]: '',
    [EDIT_PROFILE_OCCUPATION_NAME]: '',
    [EDIT_PROFILE_PREVIOUS_EMPLOYED_NAME]: '',
  },
  residentialAddress: {
    [REGISTER_ADDRESS_FINDER_NAME]: '',
    [REGISTER_ADDRESS_UNITNAME_NAME]: '',
    [REGISTER_ADDRESS_STREET_NO_NAME]: '',
    [REGISTER_ADDRESS_STREET_NAME_NAME]: '',
    [REGISTER_ADDRESS_STREET_TYPE_NAME]: '',
    [REGISTER_ADDRESS_POST_CODE_NAME]: '',
    [REGISTER_ADDRESS_SUBURB_NAME]: '',
    [REGISTER_ADDRESS_STATE_NAME]: '',
    isEnterAddressManuallySelected: false,
  },
};

export const EDIT_PROFILE_CURRENT_PASSWORD_NAME =
  'edit-profile-current-password-name';
export const EDIT_PROFILE_CURRENT_PASSWORD_FIELD = {
  label: 'form_current_password',
  name: EDIT_PROFILE_CURRENT_PASSWORD_NAME,
  type: 'password',
  placeholder: 'form_password_placeholder_1',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_password_incorrect_invalid',
  msgRequired: 'form_required',
  checkValidFn: passwordValid,
};

export const EDIT_PROFILE_NEW_PASSWORD_NAME = 'edit-profile-new-password-name';
export const EDIT_PROFILE_NEW_PASSWORD_FIELD = {
  label: 'form_new_password',
  name: EDIT_PROFILE_NEW_PASSWORD_NAME,
  type: 'password',
  placeholder: 'form_password_placeholder_2',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_password_invalid',
  msgRequired: 'form_required',
  checkValidFn: passwordValid,
};

export const EDIT_PROFILE_REPASSWORD_NAME = 'edit-profile-repassword-name';
export const EDIT_PROFILE_REPASSWORD_FIELD = {
  label: 'form_new_repassword',
  name: EDIT_PROFILE_REPASSWORD_NAME,
  type: 'password',
  placeholder: 'form_password_placeholder_3',
  isRequired: true,
  isReadOnly: false,
  msgInvalid: 'form_password_invalid',
  msgNotMatch: 'form_confirm_password_invalid',
  msgRequired: 'form_required',
  checkValidFn: passwordValid,
};

export const RESET_EDIT_PROFILE_PASSWORD_INFORMATION = {
  [EDIT_PROFILE_CURRENT_PASSWORD_NAME]: '',
  [EDIT_PROFILE_NEW_PASSWORD_NAME]: '',
  [EDIT_PROFILE_REPASSWORD_NAME]: '',
};

export const OFFERS_TABS = [
  {
    label: 'label_offers_promotions',
  },
  {
    label: 'label_offers_vouchers',
  },
];
