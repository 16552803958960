import JsCookies from 'js-cookie';

const getSubDomain = () => {
  const host = window.location.hostname;
  if (host.indexOf('local') > -1) {
    return host;
  }

  if (process.env.REACT_APP_ENV !== 'production') {
    return `.${host.split('.').slice(-2).join('.')}`;
  }

  return `.${host.split('.').slice(-3).join('.')}`;
};

const OPTS = {
  domain: getSubDomain(),
};

const LocalStorages = {
  set(key, value) {
    return localStorage.setItem(key, value);
  },
  get(key) {
    return localStorage.getItem(key);
  },
  remove(key) {
    return localStorage.removeItem(key);
  },
  setTheme(key, value) {
    if (window !== 'undefined') {
      document.documentElement.className = value;
    }

    return localStorage.setItem(key, value);
  },
};

const Cookies = {
  set(key, value) {
    JsCookies.set(key, value, OPTS);

    LocalStorages.set(key, value);

    return;
  },
  get(key) {
    return JsCookies.get(key) || LocalStorages.get(key);
  },
  remove(key) {
    JsCookies.remove(key, OPTS);

    LocalStorages.remove(key);

    return;
  },
  setTheme(key, value) {
    if (window !== 'undefined') {
      document.documentElement.className = value;
    }

    JsCookies.set(key, value, OPTS);

    LocalStorages.set(key, value);

    return;
  },
};

export default Cookies;
