import {
  ADD_TRANSACTION_METHOD_BANK_NAME,
  ADD_TRANSACTION_METHOD_EWALLET_NAME,
  ADD_TRANSACTION_METHOD_HOME_NAME,
  ADD_TRANSACTION_METHOD_PICKUP_NAME,
  ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME,
  ADD_TRANSACTION_RECEIVER_GET_NAME,
  ADD_TRANSACTION_RECEIVER_NAME,
  COUNTRIES,
  METHOD_BANK,
  METHOD_DEPOSIT,
  METHOD_EPAYMENT,
  METHOD_EWALLET,
  METHOD_HOME,
  METHOD_PAYID,
  METHOD_PICKUP,
} from 'constants';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';

const useServiceAlertsConfig = () => {
  const {
    currentReceiver,
    receiverPayoutMethod,
    remittance,
    paymentMethod: payment,
    addTransactionCurrentStep: currentStep,
  } = useFormAddTransactionStore();

  const { currentCurrencySelected, currentCurrencySelectedAnother } =
    remittance || {};

  const isStep1 = currentStep === 0;
  const isStep2 = currentStep === 1;
  const isStep3 = currentStep === 2;
  const isStep4 = currentStep === 3;

  const currentReceiverSelectedInformation =
    receiverPayoutMethod?.[ADD_TRANSACTION_RECEIVER_NAME];
  const countryCode = COUNTRIES.find(
    (c) => c.label === currentReceiverSelectedInformation?.countryName
  )?.value;
  // const countryCode = currentReceiver?.country?.code;
  const payoutMethod =
    receiverPayoutMethod?.[ADD_TRANSACTION_PAYOUT_METHOD_NAME];
  const payoutMethodByHome = payoutMethod === METHOD_HOME;
  const payoutMethodByPickup = payoutMethod === METHOD_PICKUP;
  const payoutMethodByBank = payoutMethod === METHOD_BANK;
  const payoutMethodByEwallet = payoutMethod === METHOD_EWALLET;
  const paymentMethod = payment?.[ADD_TRANSACTION_PAYMENT_METHOD_NAME];
  const paymentMethodByPayId = paymentMethod === METHOD_PAYID;
  const paymentMethodByDeposit = paymentMethod === METHOD_DEPOSIT;
  const paymentMethodByEpayment = paymentMethod === METHOD_EPAYMENT;
  const payoutMethodItemReferences = {
    [METHOD_HOME]: receiverPayoutMethod?.[ADD_TRANSACTION_METHOD_HOME_NAME],
    [METHOD_PICKUP]: receiverPayoutMethod?.[ADD_TRANSACTION_METHOD_PICKUP_NAME],
    [METHOD_BANK]: receiverPayoutMethod?.[ADD_TRANSACTION_METHOD_BANK_NAME],
    [METHOD_EWALLET]:
      receiverPayoutMethod?.[ADD_TRANSACTION_METHOD_EWALLET_NAME],
  };
  const payoutMethodItem = payoutMethodItemReferences?.[payoutMethod || ''];
  const cityId = payoutMethodByHome && currentReceiver?.address?.city?.id;
  const suburbId = payoutMethodByHome && currentReceiver?.address?.suburb?.id;
  const pickupId = payoutMethodItem?.pickupOffice?.id;
  const bankId = payoutMethodItem?.bank?.bankId;
  const ewalletId = payoutMethodItem?.ewallet?.ewalletId;

  const currencyCode1 = currentCurrencySelected?.currencyCode;
  const currencyCode2 = currentCurrencySelectedAnother?.currencyCode;
  const receivingAmount1 = Number(
    remittance?.[ADD_TRANSACTION_RECEIVER_GET_NAME] || 0
  );
  const receivingAmount2 = Number(
    remittance?.[ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME] || 0
  );

  return {
    isStep1,
    isStep2,
    isStep3,
    isStep4,
    currentReceiverSelectedInformation,
    countryCode,
    payoutMethod,
    paymentMethod,
    cityId,
    suburbId,
    pickupId,
    bankId,
    ewalletId,
    receivingAmount1,
    receivingAmount2,
    currencyCode1,
    currencyCode2,
    payoutMethodItem,
    payoutMethodByHome,
    payoutMethodByPickup,
    payoutMethodByBank,
    payoutMethodByEwallet,
    paymentMethodByPayId,
    paymentMethodByDeposit,
    paymentMethodByEpayment,
  };
};

export default useServiceAlertsConfig;
