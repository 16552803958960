import { HHMT_THEME, REMOX_THEME } from 'constants/theme';
import HHMTLogo from '../../assets/images/hhmt-logo.svg';
import REMOXLogo from '../../assets/images/Remox_logo_w_tagline.svg';
import DashboardIcon from '../../components/common/Icons/DashboardIcon';
import DashboardOutlineIcon from '../../components/common/Icons/DashboardOutlineIcon';
import ProfileIcon from '../../components/common/Icons/ProfileIcon';
import ProfileOutlineIcon from '../../components/common/Icons/ProfileOutlineIcon';
import ReceiverIcon from '../../components/common/Icons/ReceiverIcon';
import ReceiverOutlineIcon from '../../components/common/Icons/ReceiverOutlineIcon';
import TransactionIcon from '../../components/common/Icons/TransactionIcon';
import TransactionOutlineIcon from '../../components/common/Icons/TransactionOutlineIcon';
import {
  CONTACT_US_PREFIX,
  DASHBOARD_PREFIX,
  FAQ_PREFIX,
  FOREIGN_EXCHANGE_PREFIX,
  MONEY_TRANSFER_PREFIX,
  NOT_FOUND_PREFIX,
  PROFILE_PREFIX,
  RECEIVERS_ADD_SUFIX,
  RECEIVERS_EDIT_SUFIX,
  RECEIVERS_PREFIX,
  TRANSACTIONS_PREFIX,
  TRANSACTION_ADD_PREFIX,
} from '../router';

export const HOME_URL = {
  [HHMT_THEME]: `${process.env.REACT_APP_HHMT_WEBFLOW_URL}`,
  [REMOX_THEME]: `${process.env.REACT_APP_REMOX_WEBFLOW_URL}`,
};

export const NAV_MENUS_UNAUTHENTICATE = [
  {
    icon: null,
    text: 'label_nav_money_transfer',
    href: MONEY_TRANSFER_PREFIX,
  },
  {
    icon: null,
    text: 'label_nav_foreign_exchange',
    href: FOREIGN_EXCHANGE_PREFIX,
  },
  {
    icon: null,
    text: 'label_nav_faqs',
    href: FAQ_PREFIX,
  },
  {
    icon: null,
    text: 'label_nav_contact',
    href: CONTACT_US_PREFIX,
  },
];

export const NAV_MENUS_AUTHENTICATED = [
  {
    icon: DashboardIcon,
    iconOutline: DashboardOutlineIcon,
    text: 'dashboard_title',
    href: `${DASHBOARD_PREFIX}`,
  },
  {
    icon: TransactionIcon,
    iconOutline: TransactionOutlineIcon,
    text: 'transactions_title',
    href: `${TRANSACTIONS_PREFIX}`,
  },
  {
    icon: ReceiverIcon,
    iconOutline: ReceiverOutlineIcon,
    text: 'receivers_title',
    href: `${RECEIVERS_PREFIX}`,
  },
  {
    icon: ProfileIcon,
    iconOutline: ProfileOutlineIcon,
    text: 'profile_title',
    href: `${PROFILE_PREFIX}`,
  },
];

export const NAV_MENUS_MOBILE_AUTHENTICATED = [
  {
    icon: DashboardIcon,
    iconOutline: DashboardOutlineIcon,
    text: 'dashboard_title',
    href: `${DASHBOARD_PREFIX}`,
  },
  {
    icon: TransactionIcon,
    iconOutline: TransactionOutlineIcon,
    text: 'transactions_title',
    href: `${TRANSACTIONS_PREFIX}`,
  },
  {
    icon: null,
    text: null,
    href: null,
  },
  {
    icon: ReceiverIcon,
    iconOutline: ReceiverOutlineIcon,
    text: 'receivers_title',
    href: `${RECEIVERS_PREFIX}`,
  },
  {
    icon: ProfileIcon,
    iconOutline: ProfileOutlineIcon,
    text: 'profile_title',
    href: `${PROFILE_PREFIX}`,
  },
];

export const PRODUCT_LOGO = {
  hhmt: HHMTLogo,
  remox: REMOXLogo,
};

export const pathsHideLoginResgisterButton = [
  DASHBOARD_PREFIX,
  RECEIVERS_PREFIX,
  RECEIVERS_EDIT_SUFIX,
  RECEIVERS_ADD_SUFIX,
  TRANSACTIONS_PREFIX,
  TRANSACTION_ADD_PREFIX,
  PROFILE_PREFIX,
  NOT_FOUND_PREFIX,
];
