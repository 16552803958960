import { api } from 'api';
import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import {
  DEBOUNCE_SEC,
  DESKTOP_MIN_WIDTH,
  RESET_BACKDROP_STORE,
} from 'constants/common';
import {
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME,
} from 'constants/fx';
import useDebounceCallback from 'hooks/useDebounceCallback';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import Form from 'react-bootstrap/Form';
import { SET_SHOW_BACKDROP } from 'store/action';
import styled from 'styled-components';

const FormInputAddressPostcodeValidation = ({
  field,
  information,
  setInformation,
  validation,
  setValidation,
  postcodeSuggestion,
  setPostcodeSuggestion,
  checkFormInputValidation,
}) => {
  const { t } = useLang();
  const { state, dispatch } = useStore();
  const { backdrop } = state;
  const { isDesktop } = useDetectDevice();

  const isShowArrowDownIcon = (name) =>
    Boolean(postcodeSuggestion?.length) &&
    backdrop?.current === name &&
    backdrop?.isShow;

  const fetchLookupPostcodeSuggestion = async (value) => {
    try {
      const { data } = await api.lookupPostcodes(
        value,
        'd6fe8ff05352dfff3c3a3367d7140364'
      );

      if (data?.payload) {
        setPostcodeSuggestion(data.payload);
      }
    } catch (error) {
      console.error(error?.message);
    }
  };

  const checkLookupPostcodeSuggestion = async (value) => {
    await fetchLookupPostcodeSuggestion(value);

    dispatch({
      type: SET_SHOW_BACKDROP,
      payload: {
        isShow: true,
        current: field.name,
      },
    });
  };

  const { debounce: handleLookupPostcodeSuggestion } = useDebounceCallback(
    (value) => {
      checkLookupPostcodeSuggestion(value);
    },
    DEBOUNCE_SEC
  );

  const handleOnChange = (e, field) => {
    const { value } = e.target;
    const valueTrim = value.trim();

    let newInformation = {
      ...information,
      [field.name]: valueTrim,
    };

    if (!valueTrim) {
      setPostcodeSuggestion(null);

      newInformation = {
        ...newInformation,
        [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME]: '',
        [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME]: '',
      };
      setInformation(newInformation);

      dispatch({
        type: SET_SHOW_BACKDROP,
        payload: RESET_BACKDROP_STORE,
      });
    }

    if (valueTrim) {
      handleLookupPostcodeSuggestion(valueTrim);
    }

    setInformation(newInformation);

    const newValidation = checkFormInputValidation(
      valueTrim,
      field,
      validation
    );
    setValidation(newValidation);

    dispatch({
      type: SET_SHOW_BACKDROP,
      payload: {
        isShow: !backdrop?.isShow,
        current: field.name,
        onClick: () => {
          document.getElementsByName(field.name)[0].value = '';

          const newValidation = checkFormInputValidation(
            valueTrim,
            field,
            validation
          );
          setValidation(newValidation);
        },
      },
    });
  };

  const handleOnBlur = (e, field) => {
    const { value } = e.target;
    const valueTrim = value.trim();

    const newValidation = checkFormInputValidation(
      valueTrim,
      field,
      validation
    );
    setValidation(newValidation);
  };

  const handleOnClick = () => {
    const newInformation = {
      ...information,
      [field.name]: '',
    };

    setInformation(newInformation);

    setPostcodeSuggestion(null);

    dispatch({
      type: SET_SHOW_BACKDROP,
      current: RESET_BACKDROP_STORE.current,
      payload: {
        isShow: false,
        onClick: () => {
          document.getElementsByName(field.name)[0].value = '';

          const newValidation = checkFormInputValidation('', field, validation);
          setValidation(newValidation);
        },
      },
    });
  };

  const handleFormLabelOnClick = (name) => {
    const inputEle = document.getElementsByName(name)[0];

    if (inputEle) {
      inputEle.focus();
    }
  };

  return (
    <FormGroupStyled>
      <FormLabel onClick={() => handleFormLabelOnClick(field.name)}>
        {t(field.label)}
        <span>*</span>
      </FormLabel>
      <FormControl
        type={field.type}
        name={field.name}
        defaultValue={information[field.name]}
        placeholder={t(field.placeholder)}
        isInvalid={validation[field.name]}
        onChange={(e) => handleOnChange(e, field)}
        onBlur={(e) => handleOnBlur(e, field)}
        onClick={handleOnClick}
      />
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[field.name])}
      </FormControlFeedback>
      {isShowArrowDownIcon(field.name) && (
        <ArrowDownIcon
          color="var(--ds-c-grey-dark)"
          size={{ width: 12, height: 12 }}
          styles={{
            position: 'absolute',
            top: isDesktop ? '242px' : '232px',
            right: '16px',
            transform: 'rotate(180deg)',
            cursor: 'pointer',
          }}
          onClick={handleOnClick}
        />
      )}
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  position: relative;
  margin-bottom: 32px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  display: block;
  color: var(--ds-c-grey-dark);
  clear: both;

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: var(--ds-c-white);

  &:focus {
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &[readonly] {
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }

  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default FormInputAddressPostcodeValidation;
