import SizeBox from 'components/common/SizeBox';
import { chipModalStyles, getChipValue, getDiscountStatus } from 'helpers';
import useAutoScaleToFitContainer from 'hooks/useAutoScaleToFitContainer';
import useLang from 'hooks/useLang';
import React from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';

const HEADER_COL_WIDTH = 180;

const HeaderCodeUsage = ({ offer }) => {
  const { t } = useLang();

  const { code, redeem = 0, limitTxnPerCustomer = 0 } = offer || {};

  const { status, isDiscountActiveUsedHidden, isDiscountCodeHidden } =
    getDiscountStatus(offer || null) || {};

  const discountCodeClassName = `discount-modal-code-${code}`;
  useAutoScaleToFitContainer({
    className: discountCodeClassName,
    maxWidth: HEADER_COL_WIDTH,
    alignHorizontal: 'left',
  });

  const renderCodeText = () => {
    if (isDiscountCodeHidden) return null;
    return <CodeText className={discountCodeClassName}>{code}</CodeText>;
  };

  return (
    <HeaderCodeUsageStyled>
      <HeaderColLeft>
        <ScaleCodeContainer>{renderCodeText()}</ScaleCodeContainer>
      </HeaderColLeft>
      <HeaderColRight>
        {isDiscountActiveUsedHidden ? (
          <SizeBox height={26} />
        ) : (
          <>
            <ChipWrap style={chipModalStyles()[status]}>
              <ChipText>
                {
                  getChipValue({
                    t,
                    redeem,
                    limitTxnPerCustomer,
                  })[status]
                }
              </ChipText>
            </ChipWrap>
          </>
        )}
      </HeaderColRight>
    </HeaderCodeUsageStyled>
  );
};

const HeaderCodeUsageStyled = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 4px) calc(50% - 4px);
  gap: 8px;
  height: 40px;
`;

const HeaderColLeft = styled.div`
  flex: 1;
  display: grid;
  align-content: center;
  justify-content: left;
  justify-items: left;
`;
const HeaderColRight = styled.div`
  flex: 1;
  display: grid;
  align-content: center;
  justify-content: right;
  justify-items: right;
`;

const ChipWrap = styled.div``;
const ChipText = styled.p`
  ${paragraphMixin};
  text-align: center;
  font-size: 12px;
  line-height: normal;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
`;

const CodeText = styled.p`
  ${paragraphMixin};
  text-align: left;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0px;
  color: var(--ds-c-blue);
`;

const ScaleCodeContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: ${HEADER_COL_WIDTH}px;
  height: 20px;
`;

export default HeaderCodeUsage;
