import ArrowDownSVG from 'assets/ver2/icons/arrow-down-yellow-icon.svg';
import EditOrderBlueIcon from 'assets/ver2/icons/edit-order-blue-icon.svg';
import EditOrderIcon from 'assets/ver2/icons/edit-order-icon.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { format } from 'helpers';
import useFxAddTransaction from 'hooks/fx/useFxAddTransaction';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { isEmpty } from 'lodash';
import { Fragment, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';

const CustomCalculatorFormAccordion = ({
  totalPayAmount,
  sendAmount,
  feeAmount,
  isShowFeeAmount,
  isShowSurchargeField,
  surchargeAmount,
  discountAmount,
  discountApplied,
  isShowDiscountAmount,
  handleOnViewPickupLocation,
  isExpandable = false,
  isExpandDefault = false,
  styleWrapper = {},
}) => {
  const { t } = useLang();
  const { isDesktop } = useDetectDevice();
  const { isHHMTTheme } = useTheme();

  const [isExpand, setExpand] = useState(isExpandDefault);

  const { type, code } = discountApplied || {};

  const {
    addTransactionCurrentStep: currentStep,
    currencyOrders,
    pickupLocationSelected,
    setAddTransactionCurrentStep,
  } = useFxFormAddTransactionStore();
  const { parseAmountValue, isMakePayment, renderDiscountFieldLabel } =
    useFxAddTransaction();

  const handleAccordionItemOnClick = () => {
    if (!isExpandable) return;
    setExpand(!isExpand);
  };

  const renderCalculatorTotalLabelSection = () => {
    if (isExpandable) return <Fragment>{t('label_total_to_pay')}</Fragment>;
    let totalSubDesc = '';
    if (isShowFeeAmount && !isShowSurchargeField) {
      totalSubDesc = t('fx_add_transaction_include_service_fee');
    }
    if (!isShowFeeAmount && isShowSurchargeField) {
      totalSubDesc = t('fx_add_transaction_include_surcharge');
    }
    if (isShowFeeAmount && isShowSurchargeField) {
      totalSubDesc = t('fx_add_transaction_include_service_fee_and_surcharge');
    }
    if (currentStep === 0) {
      totalSubDesc = '';
    }
    return (
      <GridWrap>
        <GridItem>{t('label_total_to_pay')}</GridItem>
        {totalSubDesc && (
          <GridItem style={{ minHeight: '20px' }}>
            <span>{totalSubDesc}</span>
          </GridItem>
        )}
      </GridWrap>
    );
  };

  const handleOnEditOrder = () => {
    setAddTransactionCurrentStep(0);
  };
  const renderEditOrderButton = () => {
    if (currentStep === 0) return null;
    return (
      <EditOrderIconStyled
        src={isHHMTTheme ? EditOrderIcon : EditOrderBlueIcon}
        width={16}
        height={16}
        $isExpand={isExpand}
        onClick={handleOnEditOrder}
      />
    );
  };
  const renderCalculatorTotalValueSection = () => {
    if (isExpandable) {
      return (
        <Fragment>
          {`${format.toAmountCentStr(totalPayAmount)} AUD`}{' '}
          <ArrowIconStyled
            src={ArrowDownSVG}
            width={24}
            height={24}
            $isExpand={isExpand}
          />
        </Fragment>
      );
    }
    return (
      <GridWrap>
        <GridItem style={{ gap: '8px' }}>
          {`${format.toAmountCentStr(totalPayAmount)} AUD`}{' '}
          {renderEditOrderButton()}
        </GridItem>
        {currentStep !== 0 && !isDesktop && (
          <GridItem style={{ minHeight: '20px', justifyContent: 'center' }}>
            {!isEmpty(currencyOrders) && (
              <CurrenciesWrap>
                {currencyOrders?.map((order) => (
                  <CurrencyChip
                    key={order?.currentCurrencySelected?.currencyCode}
                  >
                    {order?.currentCurrencySelected?.currencyCode}
                  </CurrencyChip>
                ))}
              </CurrenciesWrap>
            )}
          </GridItem>
        )}
      </GridWrap>
    );
  };

  const renderViewPickupLocation = () => {
    if (isMakePayment) return null;
    if (currentStep < 2) return null;
    if (isExpandable) return null;
    return (
      <PickupLocationOverview>
        <PickupItem style={{ display: 'block' }}>
          <p>{t('label_pickup_at')}:</p>
          <p>
            <b>{pickupLocationSelected?.name}</b>
          </p>
        </PickupItem>
        <PickupItem>
          <ButtonCommon
            value={t('button_view')}
            onClick={handleOnViewPickupLocation}
            styles={{
              margin: '0px',
              marginLeft: '0px',
              marginRight: '8px',
              padding: '0px',
              width: 'fit-content',
              height: '20px',
              fontWeight: 500,
            }}
            color="var(--ds-c-green-default)"
            background="transparent"
            isFill={false}
          />
        </PickupItem>
      </PickupLocationOverview>
    );
  };

  return (
    <Wrapper style={styleWrapper}>
      <Collapse in={isExpand} appear>
        <div id="calculator-accordion-collapse">
          <BodyContent $isExpand={isExpand}>
            <CalculatorFieldWrap>
              <CalculatorFieldLabel>
                {t('label_sub_total')}
              </CalculatorFieldLabel>
              <CalculatorFieldValue>{`${parseAmountValue(
                sendAmount
              )} AUD`}</CalculatorFieldValue>
            </CalculatorFieldWrap>
            {isShowFeeAmount && (
              <CalculatorFieldWrap>
                <CalculatorFieldLabel>
                  {t('label_service_fee')}
                </CalculatorFieldLabel>
                <CalculatorFieldValue className="fee-value">
                  {`${feeAmount} AUD`}
                </CalculatorFieldValue>
              </CalculatorFieldWrap>
            )}
            {isShowDiscountAmount && (
              <CalculatorFieldWrap>
                <CalculatorFieldLabel>
                  {renderDiscountFieldLabel(type, code)}
                </CalculatorFieldLabel>
                <CalculatorFieldValue>
                  -{`${format.toAmountFloatStr(discountAmount)} AUD`}
                </CalculatorFieldValue>
              </CalculatorFieldWrap>
            )}
            {isShowSurchargeField && (
              <CalculatorFieldWrap>
                <CalculatorFieldLabel>
                  {t('label_surcharge_label')} <span>{`(1.2%)`}</span>
                </CalculatorFieldLabel>
                <CalculatorFieldValue>{`${surchargeAmount} AUD`}</CalculatorFieldValue>
              </CalculatorFieldWrap>
            )}
          </BodyContent>
        </div>
      </Collapse>
      {renderViewPickupLocation()}
      <AccordionButton
        onClick={handleAccordionItemOnClick}
        aria-controls="calculator-accordion-collapse"
        aria-expanded={isExpand}
        $isExpand={isExpand}
      >
        <CalculatorTotal>
          <CalculatorTotalLabel>
            {renderCalculatorTotalLabelSection()}
          </CalculatorTotalLabel>
          <CalculatorTotalValue>
            {renderCalculatorTotalValueSection()}
          </CalculatorTotalValue>
        </CalculatorTotal>
      </AccordionButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  height: fit-content;
  margin-block: 0px;
  margin-inline: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-block: 16px;
  }
`;

const AccordionButton = styled(Button)`
  padding: 0;
  padding-inline: 16px;
  padding-block: 6px;
  background-color: var(--ds-c-blue-remox-hover) !important;
  box-shadow: none !important;
  display: block;
  border-radius: 0px;
  transition: 0.6s ease;
  width: 100%;
  min-height: 52px;
  height: fit-content;
  border: none;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding-inline: 10px;
    border-radius: ${(props) =>
      props.$isExpand ? '0px 0px 8px 8px!important' : '8px!important'};
  }
`;
const EditOrderIconStyled = styled.img`
  width: 16px;
  height: 16px;
`;
const ArrowIconStyled = styled.img`
  transform: ${(props) =>
    props.$isExpand ? 'rotate(0deg)' : 'rotate(180deg)'};
  transition: 0.3s ease;
`;

const BodyContent = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue);

  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: var(--ds-c-blue-remox-light);
  padding-inline: 16px;
  border-radius: 0px;
  transition: 0.6s ease;

  & p {
    cursor: text;
    margin: 0;
    padding: 0;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    padding-inline: 10px;
    border-radius: ${(props) => (props.$isExpand ? '8px 8px 0px 0px' : '0px')};
  }
`;

const CalculatorTotal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  width: 100%;
  background: transparent;
  border-radius: 8px;
`;
const CalculatorTotalLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-white);
  text-transform: uppercase;

  margin: 0;
  padding: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const CalculatorTotalValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: var(--ds-c-white);

  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 8px;
}
`;
const GridWrap = styled.div``;
const GridItem = styled.div`
  display: flex;
  min-height: 25px;
  align-items: center;

  & span {
    font-size: 10px;
    line-height: 12.5px;
    font-weight: 400;
    text-transform: initial;
  }
`;
const CurrenciesWrap = styled.div`
  display: flex;
  gap: 4px;
`;
const CurrencyChip = styled.span`
  height: 20px;
  padding-inline: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ds-c-blue-dark);
  border-radius: 100px;

  font-family: var(--ff-primary);
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: #fff;
`;

const CalculatorFieldWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const CalculatorFieldLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue);
  display: flex;
  align-items: flex-end;
  gap: 4px;

  margin: 0;
  padding: 0;

  & span {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    &.discount-code {
      color: var(--ds-c-green-default);
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const CalculatorFieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-blue);

  margin: 0;
  padding: 0;

  &.fee-value {
    display: flex;
    align-items: center;
    gap: 8px;

    & button {
      height: fit-content;
      background-color: transparent !important;
      padding-inline: 0px;
    }
  }

  &.summary-discount {
    display: flex;
    align-items: center;
    color: #b0b6bd;

    & button {
      padding-bottom: 0px;
    }
  }

  & span {
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;

    padding: 2px 8px;
    background-color: var(--ds-c-green-default);
    border-radius: 2px;
    margin-right: 8px;
  }

  & b {
    font-weight: 500;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const PickupLocationOverview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background-color: var(--ds-bg-2);
  padding-inline: 16px;
`;
const PickupItem = styled.div`
  display: flex;

  & p {
    ${paragraphMixin};
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
  }
`;

export default CustomCalculatorFormAccordion;
