import React from 'react';

const DashboardIcon = ({ color = 'var(--ds-c-grey-dark)', size }) => {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="13"
        y="3"
        width="8"
        height="8"
        rx="2"
        fill={color || '#FBFBFB'}
      />
      <rect x="3" y="3" width="8" height="8" rx="2" fill={color || '#FBFBFB'} />
      <rect
        x="13"
        y="13"
        width="8"
        height="8"
        rx="2"
        fill={color || '#FBFBFB'}
      />
      <rect
        x="3"
        y="13"
        width="8"
        height="8"
        rx="2"
        fill={color || '#FBFBFB'}
      />
    </svg>
  );
};

export default DashboardIcon;
