/* eslint-disable no-unused-vars */
import PayidIcon from 'assets/icons/payments/payid-icon.svg';
import PoliIcon from 'assets/icons/payments/poli-icon.svg';
import VisaMasterCardIcon from 'assets/icons/payments/visa-debit-icon-color-2.svg';
import BankHHMTIcon from 'assets/icons/payout-method-bank-hhmt-icon-color.svg';
import BankREMOXIcon from 'assets/icons/payout-method-bank-remox-icon-color.svg';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  ADD_TRANSACTION_METHOD_BANK_NAME,
  ADD_TRANSACTION_METHOD_EWALLET_NAME,
  ADD_TRANSACTION_METHOD_PICKUP_NAME,
  ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  ADD_TRANSACTION_PAYOUT_METHOD_NAME,
  ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME,
  ADD_TRANSACTION_RECEIVER_GET_NAME,
  METHOD_BANK,
  METHOD_DEPOSIT,
  METHOD_EPAYMENT,
  METHOD_EWALLET,
  METHOD_HOME,
  METHOD_PAYID,
  METHOD_PICKUP,
  METHOD_POLI,
} from 'constants';
import { format, formatter } from 'helpers';
import useCountryConfig from 'hooks/useCountryConfig';
import useGetConfig from 'hooks/useGetConfig';
import useLang from 'hooks/useLang';
import useStore from 'hooks/useStore';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

import {
  getFullNameReceiver,
  getNumberUnmaskStr,
} from 'components/FormEditReceiver/func';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useReceiver from 'hooks/receiver/useReceiver';
import useTheme from 'hooks/useTheme';
import { stringUtils } from 'utils';
import CalculatorForm from '../CalculatorForm/ver2';

const FormTransactionDetails = ({ isOpen, onClose, onClick }) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const { state } = useStore();
  const { addTransactionInformation, currentReceiver } = state;

  const {
    receiverPayoutMethod,
    remittance,
    paymentMethod: paymentMethodStore,
  } = addTransactionInformation;

  const countryConfig = useCountryConfig(currentReceiver?.country?.code);
  const config = useGetConfig({ countryConfig });
  const {
    mobile: mobileConfig,
    phone: phoneConfig,
    accountNumber: accountNumberConfig,
    ewalletNumber: ewalletNumberConfig,
  } = config;
  const accountNumberFormat = formatter.formatParse(
    accountNumberConfig?.format
  );
  const ewalletNumberFormat = formatter.formatParse(
    ewalletNumberConfig?.format
  );

  const { isReceiverOfIndividualType, isReceiverOfBusinessType } =
    useReceiver();

  // receiverPayoutMethod
  const fullName = getFullNameReceiver({
    isReceiverOfIndividualType,
    isReceiverOfBusinessType,
    lastName: currentReceiver?.lastName,
    firstName: currentReceiver?.firstName,
    countryCode: currentReceiver?.country?.code,
  });
  const fullAddress = currentReceiver?.fullAddress;
  const mobile = currentReceiver?.mobile?.number;
  const mobileFormat = formatter.formatParse(mobileConfig?.format);
  const mobileNumberMask = formatter.mask(
    getNumberUnmaskStr(mobile, mobileFormat),
    mobileFormat
  );
  const phone = currentReceiver?.phone?.number;
  const phoneFormat = formatter.formatParse(phoneConfig?.format);
  const phoneNumberMask = formatter.mask(
    getNumberUnmaskStr(phone, phoneFormat),
    phoneFormat
  );
  const payoutMethod = receiverPayoutMethod[ADD_TRANSACTION_PAYOUT_METHOD_NAME];
  const bank =
    receiverPayoutMethod[ADD_TRANSACTION_METHOD_BANK_NAME]?.bank ||
    receiverPayoutMethod[ADD_TRANSACTION_METHOD_BANK_NAME];
  const pickupOffice =
    receiverPayoutMethod[ADD_TRANSACTION_METHOD_PICKUP_NAME]?.pickupOffice ||
    receiverPayoutMethod[ADD_TRANSACTION_METHOD_PICKUP_NAME];
  const ewallet =
    receiverPayoutMethod[ADD_TRANSACTION_METHOD_EWALLET_NAME]?.ewallet ||
    receiverPayoutMethod[ADD_TRANSACTION_METHOD_EWALLET_NAME];

  // remittance
  const receivedAmount = remittance[ADD_TRANSACTION_RECEIVER_GET_NAME];
  const currentCurrency = remittance['currentCurrencySelected']?.currencyCode;
  const currentRateValue = remittance?.currentRateStore?.rateValue || 0;
  const receivedAmountAnother =
    remittance[ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME];
  const currentCurrencyAnother =
    remittance['currentCurrencySelectedAnother']?.currencyCode;
  const currentRateValueAnother =
    remittance?.currentRateAnotherStore?.rateValue || 0;

  // paymentMethod
  const paymentMethod = paymentMethodStore[ADD_TRANSACTION_PAYMENT_METHOD_NAME];

  const payoutMethodReference = {
    [METHOD_PICKUP]: {
      text: pickupOffice?.fullAddress,
      spanText: '',
    },
    [METHOD_BANK]: {
      text: bank?.bankName,
      spanText: `${t('label_name').toUpperCase()}: ${
        bank?.accountName
      } <br/> ${t('label_number').toUpperCase()}: ${
        bank?.accountNumber
          ? formatter.mask(
              getNumberUnmaskStr(bank?.accountNumber, accountNumberFormat),
              accountNumberFormat
            )
          : ''
      }`,
    },
    [METHOD_HOME]: {
      text: fullAddress,
      spanText: '',
    },
    [METHOD_EWALLET]: {
      text: ewallet?.ewalletProviderName,
      spanText: `ID: ${
        ewallet?.ewalletNumber
          ? formatter.mask(
              getNumberUnmaskStr(ewallet?.ewalletNumber, ewalletNumberFormat),
              ewalletNumberFormat
            )
          : ''
      }`,
    },
  };

  const renderDescription = () => {
    if (paymentMethod === METHOD_EPAYMENT) {
      return '';
    }

    if (paymentMethod === METHOD_PAYID) {
      return stringUtils.replaceBreakLine(
        t(`add_transaction_transaction_details_desc`)
      );
    }

    if (paymentMethod === METHOD_DEPOSIT) {
      return stringUtils.replaceBreakLine(
        t(`add_transaction_transaction_details_desc`)
      );
    }

    return '';
  };

  const renderRightActionReference = {
    [METHOD_PAYID]: (
      <ButtonCommon
        value={t('button_pay_by')}
        color="var(--c-primary)"
        background="var(--bg-primary)"
        isFill={true}
        styles={{
          paddingInline: 0,
        }}
        buttonIconStyles={{ width: '50%' }}
        iconSrc={PayidIcon}
        iconStyles={{
          width: '50px',
          height: '32px',
          objectFit: 'contain',
        }}
        onClick={onClick}
        isUseKeyDown
      />
    ),
    [METHOD_POLI]: (
      <ButtonCommon
        value={t('button_pay_by')}
        color="var(--c-primary)"
        background="var(--bg-primary)"
        isFill={true}
        styles={{
          paddingInline: 0,
        }}
        buttonIconStyles={{ width: '50%' }}
        iconSrc={PoliIcon}
        iconStyles={{
          width: '50px',
          height: '32px',
          objectFit: 'contain',
        }}
        onClick={onClick}
        isUseKeyDown
      />
    ),
    [METHOD_DEPOSIT]: (
      <ButtonCommon
        value={t('button_pay_by')}
        color="var(--c-primary)"
        background="var(--bg-primary)"
        isFill={true}
        styles={{
          paddingInline: 0,
        }}
        buttonIconStyles={{ width: '50%' }}
        iconSrc={isHHMTTheme ? BankHHMTIcon : BankREMOXIcon}
        iconStyles={{
          width: '34px',
          height: '34px',
          objectFit: 'contain',
        }}
        onClick={onClick}
        isUseKeyDown
      />
    ),
    [METHOD_EPAYMENT]: (
      <ButtonCommon
        value={t('button_pay_by')}
        color="var(--c-primary)"
        background="var(--bg-primary)"
        isFill={true}
        styles={{
          paddingInline: 0,
        }}
        buttonIconStyles={{ width: '50%' }}
        iconSrc={VisaMasterCardIcon}
        iconStyles={{
          width: '80px',
          height: '40px',
          objectFit: 'cover',
        }}
        onClick={onClick}
        isUseKeyDown
      />
    ),
  };
  const renderActionsButton = () => {
    return (
      <>
        <ButtonCommon
          value={t('button_back')}
          onClick={onClose}
          styles={{
            margin: '0px',
            width: '50%',
          }}
          color="var(--ds-c-blue)"
          background="var(--ds-c-white)"
        />
        {renderRightActionReference[paymentMethod]}
      </>
    );
  };

  const renderContactNumber = () => {
    if (mobileConfig?.visible && phoneConfig?.visible) {
      if (mobileNumberMask && phoneNumberMask) {
        return `${mobileNumberMask || '-'} / ${phoneNumberMask || '-'}`;
      }

      if (mobileNumberMask && !phoneNumberMask) {
        return `${mobileNumberMask || '-'}`;
      }

      if (!mobileNumberMask && phoneNumberMask) {
        return `${phoneNumberMask || '-'}`;
      }

      return `${mobileNumberMask || '-'}`;
    }

    if (mobileConfig?.visible && !phoneConfig?.visible) {
      return `${mobileNumberMask || '-'}`;
    }

    if (!mobileConfig?.visible && phoneConfig?.visible) {
      return `${phoneNumberMask || '-'}`;
    }

    return '-';
  };

  const labelPayoutMethodReference = {
    [METHOD_HOME]: t('label_delivery_to'),
    [METHOD_PICKUP]: t('label_pickup_at'),
    [METHOD_BANK]: t('label_credit_to'),
    [METHOD_EWALLET]: t('label_credit_to'),
  };

  return (
    <>
      <ModalStyled
        show={isOpen}
        onHide={() => {}}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader>
          <HeaderText>{t('button_review_and_pay')}</HeaderText>
          <HeaderDesc>{t('add_transaction_transaction_details')}</HeaderDesc>
        </ModalHeader>
        <ModalBody>
          <FieldWrap
            styles={{
              alignItems: 'start',
              padding: '10px 0',
            }}
          >
            <FieldLabel>{t('label_receiver')}</FieldLabel>
            <FieldValue>{fullName}</FieldValue>
          </FieldWrap>
          {(mobileConfig?.visible || phoneConfig?.visible) && (
            <FieldWrap>
              <FieldLabel>{t('label_contact_number')}</FieldLabel>
              <FieldValue>{renderContactNumber()}</FieldValue>
            </FieldWrap>
          )}
          <FieldWrap
            styles={{
              alignItems: 'start',
              padding: '10px 0',
            }}
            style={{
              marginInline: '-16px',
              paddingInline: '16px',
              backgroundColor: '#FBFBFB',
            }}
          >
            <FieldLabel>{labelPayoutMethodReference[payoutMethod]}</FieldLabel>
            <FieldValue>
              {payoutMethodReference[payoutMethod]?.text}
              {Boolean(payoutMethodReference[payoutMethod]?.spanText) && (
                <>
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: payoutMethodReference[payoutMethod].spanText,
                    }}
                  />
                </>
              )}
            </FieldValue>
          </FieldWrap>
          <FieldWrap
            styles={{
              alignItems: 'start',
              padding: '10px 0',
            }}
          >
            <FieldLabel>{t('label_receiving_amount')}</FieldLabel>
            <FieldValue>
              {receivedAmount &&
                `${format.toAmountCentStr(receivedAmount)} ${currentCurrency}`}
              <br />
              <span className="rate">{`(1 AUD = ${format.toAmountCentStr(
                currentRateValue
              )} ${currentCurrency})`}</span>
              {Boolean(currentCurrencyAnother) && (
                <>
                  <br />
                  <br />
                  {`${format.toAmountCentStr(
                    receivedAmountAnother
                  )} ${currentCurrencyAnother}`}
                  <br />
                  <span className="rate">{`(1 AUD = ${format.toAmountCentStr(
                    currentRateValueAnother
                  )} ${currentCurrencyAnother})`}</span>
                </>
              )}
            </FieldValue>
          </FieldWrap>
          {Boolean(renderDescription()) && (
            <Description
              dangerouslySetInnerHTML={{ __html: renderDescription() }}
            />
          )}
          <CalculatorForm
            countryConfig={countryConfig}
            isCalculatorFormAccordion={true}
            isExpandDefault={true}
          />
        </ModalBody>
        <ModalFooter>{renderActionsButton()}</ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  & .modal-dialog {
    transform: none;
  }
  & .modal-content {
    padding: 16px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 470px;
    height: fit-content;
    margin-inline: auto;
  }
`;
const ModalHeader = styled(Modal.Header)`
  padding: 0;
  margin-bottom: 0;
  display: block;
  padding-bottom: 16px;
  border-bottom: none;
`;
const HeaderText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-green-default);

  margin: 0;
  padding: 0;
`;
const HeaderDesc = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-black);

  margin: 0;
  margin-top: 16px;
  padding: 0;
`;

const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 16px;
`;
const FieldWrap = styled.div`
  display: flex;
  align-items: ${(props) => props.styles?.alignItems || 'center'};
  justify-content: start;
  min-height: 40px;
  height: fit-content;
  padding: ${(props) => props.styles?.padding};
`;
const FieldLabel = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue);

  margin: 0;
  padding: ${(props) => props.styles?.padding || '0'};
  min-width: 150px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FieldValue = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: right;
  color: var(--ds-c-black);

  width: 100%;
  margin: 0;
  padding: 0;
  padding-left: 8px;

  & span {
    color: var(--ds-c-grey-neutral);
  }

  & span.rate {
    color: var(--ds-c-blue);
  }

  & span.credit-card {
    text-transform: initial;
    color: var(--ds-c-grey-dark);
  }

  & span.payment-method-txt {
    color: var(--ff-primary);
    line-height: 25px;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const Description = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-blue-dark);
  white-space: break-spaces;

  margin: 0;
  margin-top: 10px;
  padding: 0;
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
`;

const Divider = styled.div`
  border-bottom: 1px solid #eef2f5;
`;

export default FormTransactionDetails;
