import { DESKTOP_MIN_WIDTH } from 'constants';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import FileUploadIcon from '../../../../../assets/icons/file-upload-icon.svg';

const FileUploadImage = ({ name, label, styles }) => {
  return (
    <Wrap $styles={styles}>
      <FileUploadImageStyled
        src={FileUploadIcon}
        alt="file-upload-icon.svg"
        $styles={styles}
      />
      <InputLabel htmlFor={name}>{label}</InputLabel>
    </Wrap>
  );
};

const Wrap = styled.div`
  position: absolute;
  top: 50px;
  display: grid;
  justify-items: center;
  ${(props) => props.$styles};
`;

const FileUploadImageStyled = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 12px;
  margin-bottom: 16px;
`;
const InputLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  display: block;
  color: var(--ds-c-grey-dark);
  width: 100%;
  margin: 0;
  text-align: center;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default FileUploadImage;
