import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const CancelEditAddressPopup = ({
  isOpen,
  onClose,
  isBackdropTransparent = false,
}) => {
  const { t } = useLang();

  return (
    <>
      <style>
        {isBackdropTransparent
          ? `
          .edit-receiver-modal-backdrop {
            opacity: 0!important;
          }
        `
          : `.edit-receiver-modal-backdrop {
          background: #212121;
          opacity: 0.25!important;
          display: initial!important;
        }`}
      </style>

      <ModalStyled
        show={isOpen}
        onHide={onClose}
        aria-labelledby="contained-modal-title-vcenter"
        backdropClassName="edit-receiver-modal-backdrop"
        centered
      >
        <ModalBody>
          <SubText>{t('popup_edit_address_no_change')}</SubText>
        </ModalBody>
        <ModalFooter>
          <ButtonCommon
            value={t('button_ok')}
            onClick={onClose}
            styles={{
              margin: '0px',
              width: '50%',
              marginInline: 'auto 0px',
            }}
            color="var(--ds-c-blue)"
            background="var(--bg-primary)"
            isFill={true}
          />
        </ModalFooter>
      </ModalStyled>
    </>
  );
};

const ModalStyled = styled(Modal)`
  z-index: 1100;

  & .modal-dialog {
    transform: none;
  }

  & .modal-content {
    padding: 24px;
    border-radius: 12px;
    box-shadow: var(--ds-bs-4);
    background: var(--ds-c-white);
    width: 328px;
    height: fit-content;
    margin-inline: auto;
    border: none;
  }
`;
const ModalBody = styled(Modal.Body)`
  padding: 0;
  margin-bottom: 32px;
`;
const SubText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  margin: 0;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ModalFooter = styled(Modal.Footer)`
  border: none;
  padding: 0;
  display: flex;
`;

export default CancelEditAddressPopup;
