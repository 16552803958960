import { backgroundReference, colorReference } from 'constants';
import React from 'react';
import styled from 'styled-components';

const AddReceiverStep3Icon = ({
  status = 'disable' || 'in-progress' || 'completed',
}) => {
  return (
    <SvgStyled $backgroundColor={backgroundReference[status]}>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 15C0 16.3807 1.11929 17.5 2.5 17.5H17.5C18.8807 17.5 20 16.3807 20 15V5C20 3.61929 18.8807 2.5 17.5 2.5H2.5C1.11929 2.5 0 3.61929 0 5V15Z"
          fill={colorReference[status]}
        />
        <path
          d="M7.5 14.5834C7.5 14.3533 7.68655 14.1667 7.91667 14.1667H17.0833C17.3135 14.1667 17.5 14.3533 17.5 14.5834C17.5 14.8135 17.3135 15.0001 17.0833 15.0001H7.91667C7.68655 15.0001 7.5 14.8135 7.5 14.5834Z"
          fill="white"
        />
        <path
          d="M2.5 14.5834C2.5 14.3533 2.68655 14.1667 2.91667 14.1667H5.41667C5.64679 14.1667 5.83333 14.3533 5.83333 14.5834C5.83333 14.8135 5.64679 15.0001 5.41667 15.0001H2.91667C2.68655 15.0001 2.5 14.8135 2.5 14.5834Z"
          fill="white"
        />
        <path
          d="M12.5 5.83333C12.5 5.3731 12.8731 5 13.3333 5H16.6667C17.1269 5 17.5 5.3731 17.5 5.83333V8.33333C17.5 8.79357 17.1269 9.16667 16.6667 9.16667H13.3333C12.8731 9.16667 12.5 8.79357 12.5 8.33333V5.83333Z"
          fill="white"
        />
      </svg>
    </SvgStyled>
  );
};

const SvgStyled = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

export default AddReceiverStep3Icon;
