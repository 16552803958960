import React from 'react';

const ArrowDownIcon = ({
  color = '#ffffff',
  size = { width: 13, height: 8 },
  styles = {},
  onClick = () => {},
}) => {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 13 8"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...styles }}
      onClick={onClick}
    >
      <path d="M6.5 7L6.85355 7.35355L6.5 7.70711L6.14645 7.35355L6.5 7ZM12.8536 1.35355L6.85355 7.35355L6.14645 6.64645L12.1464 0.646447L12.8536 1.35355ZM6.14645 7.35355L0.146446 1.35355L0.853553 0.646447L6.85355 6.64645L6.14645 7.35355Z" />
    </svg>
  );
};

export default ArrowDownIcon;
