import { renderDiscountImportantPopup } from 'components/Discount/func';
import { DESKTOP_MIN_WIDTH } from 'constants';
import {
  FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME,
  RESET_FX_ADD_TRANSACTION_PAYMENT_METHOD,
} from 'constants/fx';
import useFxFormAddTransactionStore from 'hooks/fx/useFxFormAddTransactionStore';
import useLang from 'hooks/useLang';
import React, { useState } from 'react';
import styled from 'styled-components';
import PaymentMethodsGrid from '../PaymentMethodsGrid';

const initialFormField = RESET_FX_ADD_TRANSACTION_PAYMENT_METHOD;

const FxMakePayment = ({ renderActionsButton, renderDiscountSection }) => {
  const { t } = useLang();

  const [information, setInformation] = useState(initialFormField);
  const [validation, setValidation] = useState(initialFormField);
  const [informationChangeBackup, setInformationChangeBackup] = useState(null);
  const [isOpenDiscountImportantPopup, setOpenDiscountImportantPopup] =
    useState(false);

  const { paymentMethodName, setPaymentMethod } =
    useFxFormAddTransactionStore();

  const handleOnClickDiscountImportantPopup = () => {
    if (informationChangeBackup) {
      setInformation(informationChangeBackup);

      const newPaymentMethod = {
        ...paymentStore,
        ...informationChangeBackup,
        discountsAvailable: [],
        discountApplied: null,
      };
      setPaymentMethod(newPaymentMethod);

      setInformationChangeBackup(null);

      setValidation(initialFormField);
    }
  };
  const handleOnCloseDiscountImportantPopup = () => {
    setInformationChangeBackup(null);
  };

  return (
    <>
      {isOpenDiscountImportantPopup &&
        renderDiscountImportantPopup({
          t,
          isOpen: isOpenDiscountImportantPopup,
          setOpen: setOpenDiscountImportantPopup,
          paymentMethod: paymentMethodName,
          onCloseExt: handleOnCloseDiscountImportantPopup,
          onClickExt: handleOnClickDiscountImportantPopup,
          contentReplace:
            isUpdateTran &&
            t('discount_check_invalid_desc_3', {
              discountPaymentMethod: discountDetails?.paymentMethod,
              paymentMethodSelected:
                informationChangeBackup?.[
                  FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME
                ],
            }),
        })}

      <FxMakePaymentStyled>
        <PaymentMethodsGrid
          information={information}
          setInformation={setInformation}
          validation={validation}
          setValidation={setValidation}
          informationChangeBackup={informationChangeBackup}
          setInformationChangeBackup={setInformationChangeBackup}
          setOpenDiscountImportantPopup={setOpenDiscountImportantPopup}
          isSelectRequired={validation[FX_ADD_TRANSACTION_PAYMENT_METHOD_NAME]}
        />
        {renderDiscountSection()}
        {renderActionsButton()}
      </FxMakePaymentStyled>
    </>
  );
};

const FxMakePaymentStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: -8px;
  padding-inline: 0px;
  padding-bottom: 254px;
  background: var(--ds-c-white);

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    width: 100%;
    margin-top: 0px;
    padding-inline: 0px;
    padding-bottom: unset;
  }
`;

export default FxMakePayment;
