import { DESKTOP_MIN_WIDTH } from 'constants';
import useLang from 'hooks/useLang';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const FormInputValidation = ({ field, information, styles }) => {
  const { t } = useLang();

  return (
    <FormGroupStyled>
      <FormLabel>{t(field.label)}</FormLabel>
      <FormControl
        type={field.type}
        name={field.name}
        value={information ? information[field.name] : ''}
        placeholder=""
        isInvalid={false}
        readOnly={true}
        $isDisabled={true}
        className={field.name}
        style={styles}
      />
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: ${(props) =>
    props.$isDisabled ? 'var(--ds-c-grey-neutral)' : 'var(--ds-c-grey-dark)'};
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-inline: 10px;
  background: ${(props) =>
    props.$isDisabled ? 'var(--ds-c-grey-disabled)' : 'var(--ds-c-white)'};
  cursor: ${(props) => props.$isDisabled && 'not-allowed'};

  &:focus {
    color: ${(props) =>
      props.$isDisabled ? 'var(--ds-c-grey-neutral)' : 'var(--ds-c-grey-dark)'};
    background: ${(props) =>
      props.$isDisabled && 'var(--ds-c-grey-disabled)!important'};
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-inline: 10px !important;
  }

  &[readonly] {
    background: ${(props) =>
      props.$isDisabled && 'var(--ds-c-grey-disabled)!important'};
    border: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default FormInputValidation;
