import { useEffect } from 'react';

const useHideDropdown = (isShowDropdown, setShowDropdown) => {
  useEffect(() => {
    const listener = () => {
      if (isShowDropdown) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', listener);

    return () => document.removeEventListener('click', listener);
  }, [isShowDropdown, setShowDropdown]);
};

export default useHideDropdown;
