import { useEffect } from 'react';

const usePageYOffset = (setPageYOffset) => {
  useEffect(() => {
    const listener = () => {
      setPageYOffset(window.scrollY);
    };

    document.addEventListener('scroll', listener);

    return () => {
      document.removeEventListener('scroll', listener);
    };
  }, [setPageYOffset]);
};

export default usePageYOffset;
