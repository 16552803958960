import CloseSmallIcon from 'assets/icons/close-small-icon.svg';
import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import {
  DESKTOP_MIN_WIDTH,
  STATUS_CANCELLED,
  STATUS_COMPLETED,
  STATUS_DELIVERING,
  STATUS_ON_HOLD,
  STATUS_PROCESSING,
  STATUS_SUBMITTED,
  TRANSACTION_STATUS_TRANSLATE_REFERENCE,
} from 'constants';
import useInputFocus from 'hooks/useInputFocus';
import useLang from 'hooks/useLang';
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import { arrayUtils } from 'utils';
import FormInputCheckbox from '../FormInputCheckbox';

const initStatusDropdown = [
  { id: STATUS_SUBMITTED, name: STATUS_SUBMITTED, code: STATUS_SUBMITTED },
  {
    id: STATUS_DELIVERING,
    name: STATUS_DELIVERING,
    code: STATUS_DELIVERING,
  },
  {
    id: STATUS_PROCESSING,
    name: STATUS_PROCESSING,
    code: STATUS_PROCESSING,
  },
  { id: STATUS_COMPLETED, name: STATUS_COMPLETED, code: STATUS_COMPLETED },
  { id: STATUS_CANCELLED, name: STATUS_CANCELLED, code: STATUS_CANCELLED },
  { id: STATUS_ON_HOLD, name: STATUS_ON_HOLD, code: STATUS_ON_HOLD },
];

const FILTER_STATUS_SEARCH_NAME = 'filter-status-search';

const CustomFilterStatusDropdown = ({ filtered, setFiltered }) => {
  const { t } = useLang();
  const { statusSelected } = filtered;

  const [status, setStatus] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [statusSearch, setStatusSearch] = useState(null);
  const [isShowDropdown, setShowDropdown] = useState(false);

  const fetchTransactionsStatus = async () => {
    try {
      setStatus(initStatusDropdown);
    } catch (error) {
      console.error(error?.message);
    }
  };

  useEffect(() => {
    fetchTransactionsStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInputFocus(FILTER_STATUS_SEARCH_NAME);

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    if (!value) {
      setStatusSearch(status);

      return;
    }

    const newStatusSearch = status?.filter(
      (status) =>
        status?.name?.toLowerCase()?.includes(value?.toLowerCase()) && status
    );
    setStatusSearch(newStatusSearch);
  };

  const handleStatusSelected = (e, status) => {
    e.preventDefault();
    e.stopPropagation();

    let newStatusSelected = statusSelected ? [...statusSelected] : [];

    if (
      newStatusSelected?.some(
        (statusSelected) => statusSelected.code === status.code
      )
    ) {
      newStatusSelected = statusSelected?.filter(
        (statusSelected) => statusSelected.code !== status.code
      );
    } else {
      newStatusSelected.push(status);
    }

    const newFiltered = {
      ...filtered,
      statusSelected: newStatusSelected,
    };
    setFiltered(newFiltered);

    setSearchValue('');

    setStatusSearch(null);

    setShowDropdown(false);
  };

  const handleDropdowOnClick = (e, cb) => {
    e.preventDefault();

    setShowDropdown(!isShowDropdown);

    cb(e);
  };

  const handleDropdownOnToggle = () => {
    setSearchValue('');
    setStatusSearch(status);

    setShowDropdown(!isShowDropdown);
  };

  const handleDeleteStatusSelected = (e, status) => {
    e.preventDefault();
    e.stopPropagation();

    const newStatusSelected = statusSelected?.filter(
      (statusSelected) => statusSelected.code !== status.code
    );

    const newFiltered = {
      ...filtered,
      statusSelected: newStatusSelected,
    };
    setFiltered(newFiltered);
  };

  const renderDropdownItems = (data) => {
    if (arrayUtils.isArrayEmpty(data)) {
      return (
        <DropdownItem
          eventKey={0}
          isReceiversEmpty={arrayUtils.isArrayEmpty(data)}
        >
          <NoItemText>{t('form_text_no_result')}</NoItemText>
        </DropdownItem>
      );
    }

    return (
      <>
        {data?.map((item, index) => (
          <DropdownItem
            key={item.id}
            eventKey={index}
            onClick={(e) => handleStatusSelected(e, item)}
          >
            <FormInputCheckbox
              isChecked={statusSelected?.some(
                (statusSelected) => statusSelected.code === item.code
              )}
            />
            {t(TRANSACTION_STATUS_TRANSLATE_REFERENCE[item.name])}
          </DropdownItem>
        ))}
      </>
    );
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <ButtonToggle
      href="/"
      ref={ref}
      onClick={(e) => handleDropdowOnClick(e, onClick)}
    >
      {children}
      <ArrowDownIcon
        color="var(--ds-c-grey-dark)"
        size={{ width: 14, height: 10 }}
        styles={{
          transform: isShowDropdown && 'rotate(180deg)',
        }}
      />
    </ButtonToggle>
  ));

  return (
    <StatusDropdownStyled>
      <Label>{t('label_status')}</Label>
      <DropdownStyled onToggle={handleDropdownOnToggle}>
        <Dropdown.Toggle as={CustomToggle}>
          {!arrayUtils.isArrayEmpty(statusSelected)
            ? statusSelected.map((status) => (
                <Pill key={status.code}>
                  {t(TRANSACTION_STATUS_TRANSLATE_REFERENCE[status.name])}{' '}
                  <img
                    src={CloseSmallIcon}
                    width={12}
                    height={12}
                    alt=""
                    onClick={(e) => handleDeleteStatusSelected(e, status)}
                  />
                </Pill>
              ))
            : ''}
        </Dropdown.Toggle>
        {(statusSearch || status) && (
          <DropdownMenuWrap>
            <SearchInput
              name={FILTER_STATUS_SEARCH_NAME}
              value={searchValue || ''}
              placeholder={t('form_placeholder_type_to_search')}
              onChange={handleSearch}
            />
            <DropdownMenu>
              {renderDropdownItems(statusSearch || status)}
            </DropdownMenu>
          </DropdownMenuWrap>
        )}
      </DropdownStyled>
    </StatusDropdownStyled>
  );
};

const StatusDropdownStyled = styled.div`
  margin-bottom: 32px;
`;
const DropdownStyled = styled(Dropdown)`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 44px;
  height: fit-content;
  background: var(--ds-c-white);
  border: 1px solid var(--ds-c-blue-remox-light);
  border-radius: 8px;
`;
const DropdownMenuWrap = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  width: 100%;
  height: 44px;
  background: transparent;
  box-shadow: var(--ds-bs-4);
`;
const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  max-height: 160px;
  border-top: 1px solid var(--ds-c-blue-remox-light);
  border-radius: 0 0 8px 8px;
  width: 100%;
  overflow: auto;
  background: var(--ds-c-white);
  margin-top: -1px !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12) !important;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ds-c-grey-hover);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;
const DropdownItem = styled(Dropdown.Item)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-black);

  display: flex;
  align-items: center;
  padding: 10px;
  text-transform: capitalize;
  min-height: 40px;
  height: fit-content;
  white-space: break-spaces;
  background: var(--ds-c-white);

  a:first-child {
    border-radius: 0.375rem 0.375rem 0 0;
  }

  &:hover {
    background: var(--ds-c-grey-light);
  }

  &:active {
    color: var(--ds-c-black);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const SearchInput = styled.input`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: none;
  border-radius: 8px 8px 0 0;
  background: var(--ds-c-white);
  width: 100%;
  min-height: 44px;
  height: fit-content;
  padding: 10px;

  &:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: initial;
    color: var(--ds-c-blue-disabled);
  }
`;

const NoItemText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #b0b6bd;
  text-transform: initial;

  margin: 0px;
  padding: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const ButtonToggle = styled.a`
  font-family: var(--ff-primary);
  line-height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--ds-c-grey-dark);
  text-transform: capitalize;
  text-decoration: none;

  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 8px 10px;
  padding-right: 32px;
  width: 100%;
  height: fit-content;
  flex-wrap: wrap;
  gap: 4px;

  &:hover {
    background: transparent;
    border: none;
    color: var(--ds-c-grey-dark-hover);
  }

  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  & svg {
    position: absolute;
    right: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ds-c-grey-hover);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const Pill = styled.div`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;

  padding: 4px 8px;
  min-width: fit-content;
  height: 24px;
  background: var(--ds-c-blue);
  border-radius: 100px;

  &:last-child {
    margin-right: 0px;
  }

  & img {
    margin-top: -2px;
    margin-left: 2px;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;
const Label = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-black);

  margin-bottom: 4px;
`;

export default CustomFilterStatusDropdown;
