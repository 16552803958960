import styled from 'styled-components';
import AddImageIcon from '../../../../../assets/icons/add-image-icon.svg';

const FileUploadedImage = ({ name, path, alt }) => {
  return (
    <Wrap>
      <FileUploadedImageStyled name={name} src={path} alt={alt} />
      <MaskImageUploaded />
      <FileAddImage src={AddImageIcon} alt="add-image-icon.svg" />
    </Wrap>
  );
};

const Wrap = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const FileUploadedImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
  position: absolute;
`;
const MaskImageUploaded = styled.div`
  width: 100%;
  height: 100%;
  background: var(--bg-document-upload);
  opacity: 0.5;
  border-radius: 12px;
  position: absolute;
`;
const FileAddImage = styled.img`
  width: 60px;
  height: 60px;
  background: #0e1012;
  opacity: 0.3;
  border-radius: 12px;
`;

export default FileUploadedImage;
