import { useEffect, useRef } from 'react';

const useDebounceCallback = (callback, waitSec) => {
  const argsRef = useRef();
  const timeout = useRef();

  const cleanup = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  };

  useEffect(() => {
    return cleanup();
  }, []);

  const debounce = (...args) => {
    argsRef.current = args;

    cleanup();

    timeout.current = setTimeout(() => {
      if (argsRef.current) {
        callback(...argsRef.current);
      }
    }, waitSec * 1000);
  };

  return {
    debounce,
  };
};

export default useDebounceCallback;
