import { fetchRate } from 'components/FormAddTransaction/func';
import { fetchCurrentFee } from 'components/Transactions/func';
import { getItemsConfig } from 'components/common/func';
import {
  ADD_TRANSACTION_AMOUNT_ANOTHER_FIELDS,
  ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  AMOUNT_NUMBER_REGEX,
  DEBOUNCE_SEC,
  DESKTOP_MIN_WIDTH,
} from 'constants';
import { format } from 'helpers';
import numberHelper from 'helpers/numberHelper';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import { SERVICE_ALERTS_TYPE } from 'hooks/service-alerts/model/ServiceAlertsModel';
import useCheckServiceAlerts from 'hooks/service-alerts/useCheckServiceAlerts';
import useServiceAlertsModal from 'hooks/service-alerts/useServiceAlertsModal';
import useServiceAlertsStore from 'hooks/service-alerts/useServiceAlertsStore';
import useDebounceCallback from 'hooks/useDebounceCallback';
import useLang from 'hooks/useLang';
import useScrollIntoView from 'hooks/useScrollIntoView';
import useStore from 'hooks/useStore';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import Form from 'react-bootstrap/Form';
import { SET_ADD_TRANSACTION_INFORMATION } from 'store/action';
import styled from 'styled-components';
import { numberUtils, stringUtils } from 'utils';
import CustomCurrencyDropdown from '../CustomCurrencyDropdown';

const FormInputAmountAnotherValidation = forwardRef(
  (
    {
      field,
      countryConfig,
      receiveLimits,
      information,
      setInformation,
      validation,
      setValidation,
      currentRateValue,
      setCurrentRateValue,
      currentCurrencySelected,
      isDisabled,
      isFetching,
      setFetching,
      setOpenDiscountImportantPopup,
    },
    ref
  ) => {
    const { t } = useLang();
    const { state, dispatch } = useStore();
    const { addTransactionInformation } = state;
    const { remittance, paymentMethod } = addTransactionInformation || {};
    const { currentRateAnotherStore, rateAnother } = remittance || {};

    const [isPaste, setPaste] = useState(false);

    const dmCode = currentCurrencySelected?.dmCode;
    const countryCode = countryConfig?.countryCode;

    const isSendAnotherField =
      field.name === ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME;
    const isReceiverAnotherField =
      field.name === ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME;

    const { currentCurrencySelectedAnother: currentCurrencySelectedStore } =
      remittance || {};

    const { discountApplied } = paymentMethod || {};

    const { isServiceAlertsModalShowing } = useServiceAlertsModal();
    const { checkByRangeOfCurrencyCode } = useCheckServiceAlerts();
    const { handleCheckRangeOfByCurrencyCodeValid } =
      checkByRangeOfCurrencyCode('rangeOfCurrencyCode2');
    const { setReceivingAmount } = useFormAddTransactionStore();
    const { serviceAlertsByCountryCode } = useServiceAlertsStore();
    const {
      serviceAlertsByCurrencyCode2,
      serviceAlertsByRangeOfCurrencyCode2,
    } = serviceAlertsByCountryCode || {};
    const isCurrencyCodeSelectedDisable =
      serviceAlertsByCurrencyCode2?.type === SERVICE_ALERTS_TYPE.DISABLE;
    const [isCheckRangeOfByCurrencyCode, setCheckRangeOfByCurrencyCode] =
      useState(false);

    /* checking rangeOfCurrencyCode when currencyCodeSelected is changed is no longer disabled */
    useEffect(() => {
      const receivingAmount =
        information[ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME];
      const isCheckRangeOfByCurrencyCodeValid =
        isCheckRangeOfByCurrencyCode &&
        receivingAmount &&
        !isCurrencyCodeSelectedDisable;
      if (isCheckRangeOfByCurrencyCodeValid) {
        handleCheckRangeOfByCurrencyCodeValid({
          currencyCode: currentCurrencySelected?.currencyCode,
          receivingAmount,
        });

        setCheckRangeOfByCurrencyCode(false);
      }
    }, [
      isCheckRangeOfByCurrencyCode,
      isCurrencyCodeSelectedDisable,
      currentCurrencySelected,
      information,
    ]);
    useEffect(() => {
      const isResetRemitanceForm =
        !isServiceAlertsModalShowing &&
        serviceAlertsByRangeOfCurrencyCode2?.type ===
          SERVICE_ALERTS_TYPE.DISABLE;
      if (isResetRemitanceForm) {
        setInformation({
          ...information,
          [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: '',
          [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: '',
        });
      }
    }, [isServiceAlertsModalShowing, serviceAlertsByRangeOfCurrencyCode2]);

    const sendLimitConfig =
      countryConfig &&
      getItemsConfig(
        countryConfig?.sendLimits,
        countryConfig?.countryCode,
        null,
        true,
        null
      );

    const receiveLimitConfig =
      countryConfig &&
      getItemsConfig(
        countryConfig?.receiveLimits,
        countryConfig?.countryCode,
        dmCode,
        null,
        currentCurrencySelected?.currencyCode
      );

    const { maxSendLimit, minSendLimit } = countryConfig
      ? {
          maxSendLimit: Number(sendLimitConfig?.max || 30000),
          minSendLimit: Number(sendLimitConfig?.min || 20),
        }
      : {
          maxSendLimit: 30000,
          minSendLimit: 20,
        };

    const { maxReceiverLimit, minReceiverLimit } = countryConfig
      ? {
          maxReceiverLimit: Number(
            receiveLimitConfig?.maxAmount || maxSendLimit * currentRateValue
          ),
          minReceiverLimit: Number(receiveLimitConfig?.minAmount || 0),
        }
      : {
          maxReceiverLimit: 7000000000,
          minReceiverLimit: 0,
        };

    const isCurrentCurrencySelectedChanged =
      currentCurrencySelected &&
      currentCurrencySelectedStore &&
      currentCurrencySelected?.currencyCode !==
        currentCurrencySelectedStore?.currencyCode;

    const validRef = useRef(null);
    useScrollIntoView(validRef, validation[field.name], [validation]);

    useEffect(() => {
      if (
        isReceiverAnotherField &&
        countryConfig &&
        currentCurrencySelected &&
        (!currentRateAnotherStore || isCurrentCurrencySelectedChanged)
      ) {
        if (isDisabled) {
          dispatch({
            type: SET_ADD_TRANSACTION_INFORMATION,
            payload: {
              ...addTransactionInformation,
              remittance: {
                ...remittance,
                currentRateAnotherStore: rateAnother,
              },
            },
          });

          return;
        }

        fetchRate({
          countryCode: countryConfig?.countryCode,
          currency: currentCurrencySelected,
          setRate: setCurrentRateValue,
          rateProp: 'currentRateAnotherStore',
          currencyProp: 'currentCurrencySelectedAnother',
          information,
          setInformation,
        });
      }
    }, [
      countryConfig,
      isDisabled,
      currentRateAnotherStore,
      currentCurrencySelected,
      isCurrentCurrencySelectedChanged,
    ]);

    const handleSendAmountValidation = (information, validation, isDisable) => {
      let newValidation = { ...validation };

      const sendAmount = Number(
        information[ADD_TRANSACTION_YOU_SEND_NAME] || 0
      );
      const sendAnotherAmount = Number(
        information[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] || 0
      );
      const sendTotalAmount = numberUtils.mathRound(
        sendAmount + sendAnotherAmount
      );

      if ((maxSendLimit && !minSendLimit) || (maxSendLimit && minSendLimit)) {
        if (sendTotalAmount > maxSendLimit) {
          newValidation = {
            ...newValidation,
            [ADD_TRANSACTION_YOU_SEND_NAME]: stringUtils.replaceKeyword(
              t(ADD_TRANSACTION_AMOUNT_ANOTHER_FIELDS[0].msgMaxLimited),
              [
                {
                  key: 'max',
                  value: format.toAmountStr(maxSendLimit),
                },
              ]
            ),
            [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: stringUtils.replaceKeyword(
              t(ADD_TRANSACTION_AMOUNT_ANOTHER_FIELDS[0].msgMaxLimited),
              [
                {
                  key: 'max',
                  value: format.toAmountStr(maxSendLimit),
                },
              ]
            ),
          };
        }
      }

      if ((!maxSendLimit && minSendLimit) || (maxSendLimit && minSendLimit)) {
        if (sendTotalAmount < minSendLimit) {
          newValidation = {
            ...newValidation,
            [ADD_TRANSACTION_YOU_SEND_NAME]: stringUtils.replaceKeyword(
              t(ADD_TRANSACTION_AMOUNT_ANOTHER_FIELDS[0].msgMinLimited),
              [
                {
                  key: 'min',
                  value: format.toAmountStr(minSendLimit),
                },
              ]
            ),
            [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: stringUtils.replaceKeyword(
              t(ADD_TRANSACTION_AMOUNT_ANOTHER_FIELDS[0].msgMinLimited),
              [
                {
                  key: 'min',
                  value: format.toAmountStr(minSendLimit),
                },
              ]
            ),
          };
        }
      }

      if (sendTotalAmount >= minSendLimit && sendTotalAmount <= maxSendLimit) {
        newValidation = {
          ...newValidation,
          [ADD_TRANSACTION_YOU_SEND_NAME]: '',
          [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: '',
        };
      }

      if (!isDisable) {
        newValidation = handleGetReceiverAmountValidation(
          information,
          newValidation,
          true
        );
      }

      return newValidation;
    };

    const handleGetReceiverAmountValidation = (
      information,
      validation,
      isDisable = false
    ) => {
      let newValidation = { ...validation };

      const getReceiverAmount = Number(
        information[ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME] || 0
      );

      if (
        (maxReceiverLimit && !minReceiverLimit) ||
        (maxReceiverLimit && minReceiverLimit)
      ) {
        if (getReceiverAmount > maxReceiverLimit) {
          newValidation = {
            ...newValidation,
            [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]:
              stringUtils.replaceKeyword(
                t(ADD_TRANSACTION_AMOUNT_ANOTHER_FIELDS[1].msgMaxLimited),
                [
                  {
                    key: 'max',
                    value: format.toAmountStr(maxReceiverLimit),
                  },
                  {
                    key: 'currency',
                    value: currentCurrencySelected?.currencyCode,
                  },
                ]
              ),
          };
        }
      }

      if (
        (!maxReceiverLimit && minReceiverLimit) ||
        (maxReceiverLimit && minReceiverLimit)
      ) {
        if (getReceiverAmount < minReceiverLimit) {
          newValidation = {
            ...newValidation,
            [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]:
              stringUtils.replaceKeyword(
                t(ADD_TRANSACTION_AMOUNT_ANOTHER_FIELDS[1].msgMinLimited),
                [
                  {
                    key: 'min',
                    value: format.toAmountStr(minReceiverLimit),
                  },
                  {
                    key: 'currency',
                    value: currentCurrencySelected?.currencyCode,
                  },
                ]
              ),
          };
        }
      }

      if (
        getReceiverAmount >= minReceiverLimit &&
        getReceiverAmount <= maxReceiverLimit
      ) {
        newValidation = {
          ...newValidation,
          [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: '',
        };
      }

      if (!isDisable) {
        newValidation = handleSendAmountValidation(
          information,
          newValidation,
          true
        );
      }

      return newValidation;
    };

    useImperativeHandle(ref, () => ({
      handleGetReceiverAmountValidation,
    }));

    const handleSendAmountOnChange = (value, isChangeCurrency) => {
      if (isPaste && value > maxSendLimit) {
        value = Number(maxSendLimit);
      }

      let receiverValue = numberUtils.mathRound(
        numberUtils.mathRound(value) * currentRateValue
      );

      if (
        (value > maxSendLimit && validation[field.name]) ||
        (receiverValue > maxReceiverLimit &&
          validation[ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME])
      ) {
        value = Number(value);

        receiverValue = numberUtils.mathRound(
          numberUtils.mathRound(value) * currentRateValue
        );
      }

      if (currentCurrencySelected?.currencyCode === 'AUD') {
        receiverValue = Number(value);
      }

      if (currentCurrencySelected?.currencyCode === 'VND') {
        receiverValue = numberHelper.roundVndAmount(receiverValue);
      }

      if (
        currentCurrencySelected?.currencyCode !== 'VND' &&
        currentCurrencySelected?.currencyCode !== 'AUD'
      ) {
        receiverValue = numberUtils.mathRound(receiverValue);
      }

      if (isChangeCurrency) {
        receiverValue = numberUtils.mathRound(receiverValue);
      }

      document.getElementsByName(
        ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME
      )[0].value = format.toAmountCentStr(value);
      document.getElementsByName(
        ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME
      )[0].value = format.toAmountStr(receiverValue);

      value = numberUtils.mathRound(value);

      return [value, receiverValue];
    };

    const handleReceiverGetOnChange = (value) => {
      if (isPaste && value > maxReceiverLimit) {
        value = Number(maxSendLimit);
      }

      let sendValue = numberUtils.mathRound(
        numberUtils.mathRound(value) / currentRateValue
      );

      if (
        (value > maxReceiverLimit && validation[field.name]) ||
        (sendValue > maxSendLimit &&
          validation[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME])
      ) {
        value = Number(value);

        sendValue = numberUtils.mathRound(
          numberUtils.mathRound(value) / currentRateValue
        );
      }

      sendValue = numberUtils.mathRound(sendValue);

      document.getElementsByName(
        ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME
      )[0].value = format.toAmountStr(sendValue);
      document.getElementsByName(
        ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME
      )[0].value = format.toAmountCentStr(value);

      value = Number(value);

      if (currentCurrencySelected?.currencyCode !== 'VND') {
        value = numberUtils.mathRound(value);
      }

      if (currentCurrencySelected?.currencyCode === 'VND') {
        value = numberHelper.roundVndAmount(value);

        sendValue = numberUtils.mathRound(value / currentRateValue);
      }

      return [sendValue, value];
    };

    const { debounce: handleFetchCurrentFee } = useDebounceCallback(
      (information, setInformation, countryCode, dmCode) => {
        fetchCurrentFee(
          information,
          setInformation,
          countryCode,
          dmCode,
          maxSendLimit,
          () => {
            setFetching(false);

            const sendAmount =
              information[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] || 0;
            const receivingAmount =
              information[ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME] || 0;
            const currentCurrencySelectedAnother =
              information?.currentCurrencySelectedAnother;
            setReceivingAmount({
              [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: sendAmount,
              [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: receivingAmount,
              currentCurrencySelectedAnother,
              isAddAnotherReceivingCurrency: true,
            });

            setCheckRangeOfByCurrencyCode(true);
          }
        );

        setPaste(false);
      },
      DEBOUNCE_SEC
    );

    const handleOnChange = (e) => {
      const { value, name } = e.target;

      let valueTrim = value ? format.toStrAmount(value.trim()) : '';
      let sendValue;
      let receiverValue;

      if (isDisabled) {
        document.getElementsByName(name)[0].value = '';

        return;
      }

      if (valueTrim?.length <= 1 && !numberUtils.isNumber(valueTrim)) {
        document.getElementsByName(name)[0].value = '';

        return;
      }

      let newInformation = { ...information };
      let newValidation = { ...validation };

      if (!valueTrim) {
        newValidation = {
          ...newValidation,
          [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: '',
          [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: '',
        };
        setValidation(newValidation);

        document.getElementsByName(
          ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME
        )[0].value = '';
        document.getElementsByName(
          ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME
        )[0].value = '';

        newInformation = {
          ...newInformation,
          [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: '',
          [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: '',
          feeAmount: 0,
        };

        setFetching(true);
        handleFetchCurrentFee(
          newInformation,
          setInformation,
          countryCode,
          dmCode
        );

        return;
      }

      if (!numberUtils.isNumber(valueTrim)) {
        valueTrim = valueTrim.match(AMOUNT_NUMBER_REGEX)[0];

        document.getElementsByName(name)[0].value = valueTrim;
      }

      if (value.includes(' ')) {
        document.getElementsByName(name)[0].value = valueTrim;
      }

      if (isSendAnotherField) {
        [sendValue, receiverValue] = handleSendAmountOnChange(valueTrim);

        newInformation = {
          ...newInformation,
          [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: sendValue,
          [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: receiverValue,
        };
      }

      if (isReceiverAnotherField) {
        [sendValue, receiverValue] = handleReceiverGetOnChange(valueTrim);

        newInformation = {
          ...newInformation,
          [ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME]: sendValue,
          [ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME]: receiverValue,
        };
      }

      if (isSendAnotherField) {
        newValidation = handleSendAmountValidation(
          newInformation,
          newValidation
        );
      }

      if (isReceiverAnotherField) {
        newValidation = handleGetReceiverAmountValidation(
          newInformation,
          newValidation
        );
      }

      setValidation(newValidation);

      setFetching(true);
      handleFetchCurrentFee(
        newInformation,
        setInformation,
        countryCode,
        dmCode
      );
    };

    const handleFormLabelOnClick = (e, name) => {
      e.preventDefault();
      const inputEle = document.getElementsByName(name)[0];

      if (inputEle) {
        inputEle.focus();
      }
    };

    const handleFormInputOnClick = () => {
      if (discountApplied) {
        const inputEle = document.getElementsByName(field.name)[0];

        if (inputEle) {
          inputEle?.blur();
        }

        setOpenDiscountImportantPopup(true);
      }
    };

    return (
      <FormGroupStyled ref={validRef}>
        <FormLabel onClick={(e) => handleFormLabelOnClick(e, field.name)}>
          {t(field.label)}
          {field.isRequired && <span>*</span>}
        </FormLabel>
        <FormControl
          type={field.type}
          inputMode="decimal"
          autoComplete="off"
          name={field.name}
          defaultValue={
            information && information[field.name]
              ? format.toAmountCentStr(information[field.name])
              : ''
          }
          placeholder={field.placeholder}
          isInvalid={validation[field.name]}
          onChange={handleOnChange}
          onPaste={() => setPaste(true)}
          $isDisabled={isDisabled}
          readOnly={field.isReadOnly || isDisabled}
          onFocus={handleFormInputOnClick}
        />
        {isSendAnotherField && (
          <CurrencyText $isDisabled={isDisabled}>AUD</CurrencyText>
        )}
        {isReceiverAnotherField && Boolean(currentRateValue) && (
          <Rate>{`(1 AUD = ${format.toAmountStr2(currentRateValue)} ${
            currentCurrencySelected?.currencyCode
          })`}</Rate>
        )}
        {isReceiverAnotherField && (
          <CustomCurrencyDropdown
            sendName={ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME}
            receiverName={ADD_TRANSACTION_RECEIVER_GET_ANOTHER_NAME}
            countryConfig={countryConfig}
            maxSendLimit={maxSendLimit}
            receiveLimits={receiveLimits}
            currentRateValue={currentRateValue}
            setCurrentRateValue={setCurrentRateValue}
            information={information}
            setInformation={setInformation}
            validation={validation}
            setValidation={setValidation}
            currentCurrencySelected={currentCurrencySelected}
            currencyProp="currentCurrencySelectedAnother"
            rateProp="currentRateAnotherStore"
            isDisabled={isDisabled}
            handleSendAmountOnChange={handleSendAmountOnChange}
            handleGetReceiverAmountValidation={
              handleGetReceiverAmountValidation
            }
            isFetching={isFetching}
            setFetching={setFetching}
            setOpenDiscountImportantPopup={setOpenDiscountImportantPopup}
          />
        )}
        <FormControlFeedback hidden={false} type="invalid">
          {t(validation[field.name])}
        </FormControlFeedback>
      </FormGroupStyled>
    );
  }
);

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: left;

  display: block;
  color: var(--ds-c-grey-dark);

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  color: ${(props) => props.$isDisabled && 'var(--ds-c-grey-neutral)'};
  height: 44px;
  border: 1px solid var(--ds-c-grey-disabled);
  border: ${(props) => props.$isDisabled && 'none!important'};
  border-radius: 12px;
  padding-inline: 10px;
  background: var(--ds-c-white);

  &:focus {
    background: var(--ds-c-white) !important;
    border: 1px solid var(--ds-c-grey-dark) !important;
    border: ${(props) => props.$isDisabled && 'none!important'};
    color: ${(props) => props.$isDisabled && 'var(--ds-c-grey-neutral)'};
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
    padding-inline: 10px !important;
  }

  &[readonly] {
    background: ${(props) =>
      props.$isDisabled ? '#eef2f5!important' : 'var(--ds-c-white)!important'};
    cursor: not-allowed;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    color: var(--ds-c-blue-disabled);
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const CurrencyText = styled.span`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);
  color: ${(props) => props.$isDisabled && 'var(--ds-c-grey-neutral)'};

  position: absolute;
  top: 40px;
  right: 10.5px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const Rate = styled.span`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #1c3f88;

  position: absolute;
  top: 0px;
  right: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export default FormInputAmountAnotherValidation;
