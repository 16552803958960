import CustomDatepickerSelected from 'components/common/CustomDatepickerSelected';
import CalendarIcon from 'components/common/Icons/CalendarIcon';
import { BACKSPACE_CODE, DELETE_CODE } from 'components/common/func';
import {
  ADD_EWALLET_EXPIRY_FIELD,
  ADD_EWALLET_EXPIRY_NAME,
  DESKTOP_MIN_WIDTH,
} from 'constants';
import { datePicker, formatter } from 'helpers';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

const CustomExpiryDatePicker = ({
  isConfigRequired,
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();
  const { isMobile } = useDetectDevice();

  const [isShowDatePickerIcon, setShowDatePickerIcon] = useState(true);
  const [isOpenDatePicker, setOpenDatePicker] = useState(false);
  const [datePickerISO, setDatePickerISO] = useState(null);
  const [dateMaskStr, setDateMaskStr] = useState(null);

  const dateFormat = formatter.formatParse(datePicker.dateFormat);

  useEffect(() => {
    if (information[ADD_EWALLET_EXPIRY_NAME]) {
      const [yyyy, MM, dd] = information[ADD_EWALLET_EXPIRY_NAME];

      const dateUTC = datePicker.getDateUTC(dd, MM - 1, yyyy);

      const dateISO = datePicker.dateUTCToDateISO(dateUTC);
      setDatePickerISO(dateISO);

      const { dateMask } = datePicker.getDateMask('', dateUTC);
      setDateMaskStr(formatter.mask(dateMask, dateFormat));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information]);

  const handleOnChange = (dateUTC) => {
    let newInfomation = { ...information };
    let newValidation = { ...validation };

    const dateISO = datePicker.dateUTCToDateISO(dateUTC);
    const dateMask = datePicker.dateUTCToDateStr(dateUTC)?.split('/')?.join('');

    setDatePickerISO(dateISO);

    setDateMaskStr(dateMask);

    const { dd, MM, yyyy } = datePicker.getDateMask('', dateUTC);

    newInfomation = {
      ...newInfomation,
      [ADD_EWALLET_EXPIRY_NAME]: [parseInt(yyyy), parseInt(MM), parseInt(dd)],
    };
    setInformation(newInfomation);

    dateUTC.setDate(dateUTC.getDate() + 1);

    const isExpired = datePicker.getExpired(dateUTC);
    newValidation = {
      ...validation,
      [ADD_EWALLET_EXPIRY_NAME]: isExpired
        ? ADD_EWALLET_EXPIRY_FIELD.msgExpired
        : '',
    };
    setValidation(newValidation);

    setOpenDatePicker(false);
  };

  const handleInputOnChange = (e) => {
    const { value } = e.target;

    if (value?.length > 10) return;

    let newValidation = { ...validation };

    setShowDatePickerIcon(true);

    if (!value) {
      const newInfomation = {
        ...information,
        [ADD_EWALLET_EXPIRY_NAME]: '',
      };

      setDateMaskStr('');

      setInformation(newInfomation);

      return;
    }

    const { dateMask, dd, MM, yyyy } = datePicker.getDateMask(value);
    setDateMaskStr(formatter.mask(dateMask, dateFormat));

    newValidation = {
      ...newValidation,
      [ADD_EWALLET_EXPIRY_NAME]: '',
    };

    if (value.length === 10) {
      const dateUTC = datePicker.getDateUTC(dd, MM - 1, yyyy);
      const dateISO = datePicker.dateUTCToDateISO(dateUTC);
      const currentYear = new Date(dateISO).getFullYear();

      if (
        datePicker.minYear > currentYear ||
        datePicker.maxExpiredYear < currentYear
      ) {
        setShowDatePickerIcon(false);

        setDatePickerISO(null);
      } else {
        setShowDatePickerIcon(true);

        setDatePickerISO(dateISO);
      }

      const newInfomation = {
        ...information,
        [ADD_EWALLET_EXPIRY_NAME]: [parseInt(yyyy), parseInt(MM), parseInt(dd)],
      };
      setInformation(newInfomation);

      dateUTC.setDate(dateUTC.getDate() + 1);

      const isExpired = datePicker.getExpired(dateUTC);
      newValidation = {
        ...validation,
        [ADD_EWALLET_EXPIRY_NAME]: isExpired
          ? ADD_EWALLET_EXPIRY_FIELD.msgExpired
          : '',
      };
    }

    setValidation(newValidation);
  };

  const handleInputOnKeyDown = (e) => {
    if ([BACKSPACE_CODE, DELETE_CODE].includes(e.which)) {
      const newInfomation = {
        ...information,
        [ADD_EWALLET_EXPIRY_NAME]: '',
      };
      setInformation(newInfomation);

      const newValidation = {
        ...validation,
        [ADD_EWALLET_EXPIRY_NAME]: '',
      };
      setValidation(newValidation);

      setDateMaskStr('');

      setShowDatePickerIcon(true);
    }
  };

  const handleOnToggleDatePicker = () => {
    setOpenDatePicker(!isOpenDatePicker);
  };

  return (
    <FormGroupStyled>
      <FormLabel>
        {t(ADD_EWALLET_EXPIRY_FIELD.label)}
        {(isConfigRequired ?? ADD_EWALLET_EXPIRY_FIELD.isRequired) && (
          <span>*</span>
        )}
      </FormLabel>
      <FormControl
        type={ADD_EWALLET_EXPIRY_NAME.type}
        name={ADD_EWALLET_EXPIRY_NAME}
        value={formatter.mask(dateMaskStr, dateFormat)}
        placeholder={ADD_EWALLET_EXPIRY_FIELD.placeholder}
        onClick={() => setOpenDatePicker(false)}
        onChange={handleInputOnChange}
        onKeyDown={handleInputOnKeyDown}
        isInvalid={validation[ADD_EWALLET_EXPIRY_NAME]}
        readOnly={ADD_EWALLET_EXPIRY_FIELD.isReadOnly}
      />
      {isShowDatePickerIcon && (
        <CalendarIcon
          name={ADD_EWALLET_EXPIRY_NAME}
          color="var(--ds-c-grey-dark)"
          size={{ width: 24, height: 24 }}
          onClick={handleOnToggleDatePicker}
          styles={{
            position: 'absolute',
            top: '36px',
            right: '10px',
            cursor: 'pointer',
          }}
        />
      )}
      {isOpenDatePicker && (
        <CustomDatepickerSelected
          isOpen={isOpenDatePicker}
          date={datePickerISO}
          maxDate="12/12/2050"
          styles={{
            position: isMobile ? 'unset' : 'absolute',
            zIndex: 2,
            left: !isMobile && 'calc(50% - 163px)',
          }}
          onChange={handleOnChange}
          onClose={handleOnToggleDatePicker}
        />
      )}
      <FormControlFeedback hidden={false} type="invalid">
        {t(validation[ADD_EWALLET_EXPIRY_NAME])}
      </FormControlFeedback>
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: block;

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControl = styled(Form.Control)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: var(--ds-c-grey-dark);
  height: 40px;
  border: 1px solid var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding-right: 8px;
  background: var(--ds-c-white);

  &:focus {
    border: 1px solid var(--ds-c-grey-dark) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus,
  &.is-invalid {
    border: 1px solid var(--ds-c-red) !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &[readonly] {
    background: var(--ds-c-white);
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: var(--ds-c-grey-hover);

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &[type='date']::-webkit-calendar-picker-indicator {
    background: none !important;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const FormControlFeedback = styled(Form.Control.Feedback)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  display: block;
  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default CustomExpiryDatePicker;
