import {
  DESKTOP_MIN_WIDTH,
  EN_LANG,
  I18N_REDIRECTED,
  RESET_BACKDROP_STORE,
} from 'constants';
import Cookies from 'helpers/cookies';
import useAuth from 'hooks/useAuth';
import useFirebase from 'hooks/useFirebase';
import useLang from 'hooks/useLang';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import useTheme from 'hooks/useTheme';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { SET_SHOW_BACKDROP } from 'store/action';
import styled from 'styled-components';
import ArrowDownIcon from '../../../Icons/ArrowDownIcon';

const LANGUAGE_REFERENCE = {
  en: { nativeName: 'English' },
  vi: { nativeName: 'Tiếng Việt' },
};

export const LANGUAGE_NAME = 'language-field';

const CustomLangDropdown = ({ styles = {} }) => {
  const { locate } = useLang();
  const query = useQuery();
  const { isRemoxTheme } = useTheme();
  const { state, dispatch } = useStore();
  const { backdrop, currentUser } = state || {};
  const { token } = useAuth();
  const { saveDeviceToken } = useFirebase();

  const [isShowDropdown, setShowDropdown] = useState(false);

  const [currentLang, setCurrentLang] = useState(
    Object.keys(LANGUAGE_REFERENCE)[0]
  );

  useEffect(() => {
    return () => {
      dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setLanguage = (lang) => {
    i18next.changeLanguage(lang);

    setCurrentLang(lang);

    Cookies.set(I18N_REDIRECTED, lang);
  };

  useEffect(() => {
    if (isRemoxTheme) {
      setLanguage(EN_LANG);

      return;
    }

    const lang = query.get('lang');
    if (lang) {
      setLanguage(lang);
    } else {
      setLanguage(locate);
    }
  }, [isRemoxTheme, locate, query]);

  const remainingLanguage = Object.keys(LANGUAGE_REFERENCE)?.find(
    (lang) => lang !== currentLang
  );

  const handleChangeLanguage = async (lang) => {
    setLanguage(lang);

    dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });

    if (Notification?.permission === 'granted' && currentUser) {
      saveDeviceToken({ token, isActive: true, lang });
    }
  };

  const handleDropdownOnToggle = () => {
    setShowDropdown(!isShowDropdown);
    dispatch({ type: SET_SHOW_BACKDROP, payload: RESET_BACKDROP_STORE });
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <ButtonToggle
      href="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);

        dispatch({
          type: SET_SHOW_BACKDROP,
          payload: {
            isShow: !backdrop?.isShow,
            current: LANGUAGE_NAME,
          },
        });
      }}
    >
      {children}&nbsp;
      <ArrowDownIcon
        size={{
          width: 16,
          height: 16,
        }}
        styles={{
          padding: '2px',
          transform:
            backdrop?.current === LANGUAGE_NAME &&
            backdrop?.isShow &&
            'rotate(180deg)',
        }}
      />
    </ButtonToggle>
  ));

  return (
    <>
      <style>
        {isShowDropdown &&
          `
          .backdrop-global {
            z-index: 3;
          }
        `}
      </style>
      <LanguageDropdownStyled $isHidden={isRemoxTheme} style={{ ...styles }}>
        <Dropdown onToggle={handleDropdownOnToggle}>
          <Dropdown.Toggle as={CustomToggle}>{currentLang}</Dropdown.Toggle>

          <DropdownMenu>
            <DropdownItem
              eventKey={remainingLanguage}
              onClick={() => handleChangeLanguage(remainingLanguage)}
            >
              {LANGUAGE_REFERENCE[remainingLanguage].nativeName}
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </LanguageDropdownStyled>
    </>
  );
};

const LanguageDropdownStyled = styled.div`
  display: ${(props) => (props.$isHidden ? 'none' : 'flex')};
  align-items: center;
  margin-right: 2px;
  // margin-left: 0px;

  & .dropdown {
    width: 36px !important;
  }

  @media screen and (min-width: 1280px) {
    margin-left: 40px;
  }
`;
const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  height: fit-content;
  min-width: fit-content;
  box-shadow: var(--ds-bs-4);
  border-radius: 4px;
  inset: 0px 0px auto auto !important;
  transform: translate(0px, 40px) !important;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    transform: translate(0px, 40px) !important;
  }
`;
const DropdownItem = styled(Dropdown.Item)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 8px;
  text-transform: capitalize;
  width: fit-content;
  height: 40px;
  background: var(--ds-c-white);

  &:first-child {
    border-radius: 0.375rem;
  }

  &:hover {
    background: var(--ds-c-grey-light);
  }

  &:active {
    color: var(--ds-c-grey-dark);
  }
`;
const ButtonToggle = styled.a`
  font-family: var(--ff-primary);
  line-height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: var(--ds-c-white);
  text-decoration: none;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
  height: 44px;

  &:hover {
    background: transparent;
    border: none;
    color: var(--ds-c-grey-hover);
  }

  &:focus {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    height: 40px;
  }
`;

export default CustomLangDropdown;
