import jwtDecode from 'jwt-decode';
import { useEffect } from 'react';
import useAuth from './useAuth';

const useTokenExpired = () => {
  const { token, logout } = useAuth();

  useEffect(() => {
    if (token) {
      const { exp } = jwtDecode(token);

      if (Date.now() >= (exp - 60) * 1000) {
        logout();
      }
    }
  });
};

export default useTokenExpired;
