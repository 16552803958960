/* eslint-disable no-unused-vars */
import FormInputAmountValidation from 'components/FXCalculatorForm/components/FormInputAmountValidation';
import {
  fetchBuyRateCurrency,
  fetchSellCurrencies,
  fetchSellRateCurrency,
} from 'components/FXCalculatorForm/func';
import ButtonCommon from 'components/common/ButtonCommon';
import {
  CONTACT_US_PREFIX,
  EN_LANG,
  FX_CALCULATOR_TABS,
  FX_TRANSACTION_ADD_PREFIX,
  HOME_URL,
  I18N_REDIRECTED,
  ORDER_EMAIL,
} from 'constants';
import {
  FX_CALCULATOR_DESKTOP_MIN_WIDTH,
  FX_CALCULATOR_FORM_AMOUNT_FIELDS,
  FX_CALCULATOR_FORM_AMOUNT_FIELDS_YOU_SELL,
  FX_CALCULATOR_FORM_CONVERT_FROM_NAME,
  FX_CALCULATOR_FORM_TO_NAME,
} from 'constants/calculator-form';
import { AS_GUEST } from 'constants/fx';
import { datePicker, format } from 'helpers';
import Cookies from 'helpers/cookies';
import { FX_MAKE_PAYMENT_KEY } from 'hooks/fx/useFxAddTransaction';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import useQuery from 'hooks/useQuery';
import useStore from 'hooks/useStore';
import i18next from 'i18next';
import { useEffect, useState } from 'react';
import { SET_SHOW_LOADING } from 'store/action';
import styled from 'styled-components';
import { numberUtils, stringUtils } from 'utils';
import { fetchBuyCurrencies } from '../../../components/FXCalculatorForm/func';

const DEFAULT = {
  currencyCode: 'USD',
};

const initialCalculatorForm = {
  [FX_CALCULATOR_FORM_CONVERT_FROM_NAME]: null,
  [FX_CALCULATOR_FORM_TO_NAME]: null,
  currentCurrencySelected: null,
};

const FxCalculatorFormPage = () => {
  const { t, locate } = useLang();
  const query = useQuery();
  const { dispatch } = useStore();
  const { theme } = useTheme();

  const [information, setInformation] = useState(initialCalculatorForm);
  const [validation, setValidation] = useState(initialCalculatorForm);
  const [currentTab, setCurrentTab] = useState(0);
  const [currencies, setCurrencies] = useState(null);
  const [dateLatest, setDateLatest] = useState('');
  const [timeLatest, setTimeLatest] = useState('');
  const [timezoneLatest, setTimezoneLatest] = useState('');
  const [sellRateHistory, setSellRateHistory] = useState(0);
  const [buyRateHistory, setBuyRateHistory] = useState(0);

  const { currencyCode } = information['currentCurrencySelected'] || {};

  const currentMinDenomination =
    currencies?.find((c) => c.currencyCode === currencyCode)?.minDenomination ||
    1;

  const getCurrencySelected = (currencyCode, currencies) =>
    currencies?.find((c) => c?.currencyCode === currencyCode);
  const getCalculatorFormInitial = async () => {
    const currencies = await fetchSellCurrencies();
    setCurrencies(currencies);

    const currentCurrency = await fetchSellRateCurrency(DEFAULT.currencyCode);
    const { rate } = currentCurrency || {};
    setSellRateHistory(rate);

    const toValue = 1000;
    const convertFromValue = numberUtils.mathRound(toValue / rate);

    const newInformation = {
      ...initialCalculatorForm,
      [FX_CALCULATOR_FORM_CONVERT_FROM_NAME]: convertFromValue,
      [FX_CALCULATOR_FORM_TO_NAME]: toValue,
      currentCurrencySelected: getCurrencySelected(
        DEFAULT.currencyCode,
        currencies
      ),
    };
    setInformation(newInformation);
  };
  useEffect(() => {
    getCalculatorFormInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    const convertFromAmount = Number(
      information[FX_CALCULATOR_FORM_CONVERT_FROM_NAME] || 0
    );
    const toAmount = Number(information[FX_CALCULATOR_FORM_TO_NAME] || 0);

    document.getElementsByName(FX_CALCULATOR_FORM_CONVERT_FROM_NAME)[0].value =
      convertFromAmount
        ? format.toAmountStr(convertFromAmount)
        : convertFromAmount;
    document.getElementsByName(FX_CALCULATOR_FORM_TO_NAME)[0].value = toAmount
      ? format.toAmountStr(toAmount)
      : toAmount;
  }, [information]);

  useEffect(() => {
    const lang = query.get('lang') || EN_LANG;

    i18next.changeLanguage(lang);

    Cookies.set(I18N_REDIRECTED, lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const getDateTimeLatest = () => {
    const today = datePicker.getToday();
    setDateLatest(datePicker.dateUTCToDateStr(today));
    setTimeLatest(datePicker.timeUTCToTimeStr(today));
    setTimezoneLatest(
      window.Intl.DateTimeFormat().resolvedOptions().timeZone ||
        window.parent.Intl.DateTimeFormat().resolvedOptions().timeZone
    );
  };
  useEffect(() => {
    getDateTimeLatest();
  }, [information, currentTab]);

  const handleTabOnClick = async (index) => {
    dispatch({ type: SET_SHOW_LOADING, payload: true });

    let newInformation = { ...information };
    let currentCurrency;
    let currencies;
    let currentRate = 0;
    let toValue = 0;
    let convertFromValue = 0;

    const convertFromAmount = Number(
      information[FX_CALCULATOR_FORM_CONVERT_FROM_NAME]
    );
    const toAmount = Number(information[FX_CALCULATOR_FORM_TO_NAME]);

    if (index === 0) {
      currentCurrency = await fetchSellRateCurrency(currencyCode);

      currencies = await fetchSellCurrencies();

      currentRate = currentCurrency?.rate;

      toValue =
        Math.round(
          (convertFromAmount * currentRate) /
            buyRateHistory /
            currentMinDenomination
        ) * currentMinDenomination;

      convertFromValue = numberUtils.mathRound(toValue / currentRate);

      setSellRateHistory(currentRate);
    }

    if (index === 1) {
      currentCurrency = await fetchBuyRateCurrency(currencyCode);

      currencies = await fetchBuyCurrencies();

      currentRate = currentCurrency?.rate;

      convertFromValue =
        Math.round(
          (toAmount * currentRate) / sellRateHistory / currentMinDenomination
        ) * currentMinDenomination;

      toValue = numberUtils.mathRound(convertFromValue / currentRate);

      setBuyRateHistory(currentRate);
    }

    newInformation = {
      ...newInformation,
      [FX_CALCULATOR_FORM_CONVERT_FROM_NAME]: convertFromValue,
      [FX_CALCULATOR_FORM_TO_NAME]: toValue,
    };

    setCurrencies(currencies);

    setInformation(newInformation);

    setCurrentTab(index);
  };

  const handleTabActionOnClick = () => {
    if (currentTab === 0) {
      Cookies.remove(FX_MAKE_PAYMENT_KEY);
      Cookies.remove(ORDER_EMAIL);
      window.parent.location.href = `${FX_TRANSACTION_ADD_PREFIX}?as=${AS_GUEST}&&payAmount=${information[FX_CALCULATOR_FORM_CONVERT_FROM_NAME]}&&buyAmount=${information[FX_CALCULATOR_FORM_TO_NAME]}&&currencyCode=${currencyCode}&&lang=${locate}`;
      return;
    }
    window.parent.location.href = `${HOME_URL[theme]}${CONTACT_US_PREFIX}?lang=${locate}`;
  };
  return (
    <FXCalculatorFormStyled>
      <FormStyled>
        <FormHeaderTop>
          <HeaderLabel>{t('label_today_exchange_rate_2')}</HeaderLabel>
          <HeaderRate>
            <RateValue>1 AUD =</RateValue>&nbsp;
            <RateValue>
              {`${format.toAmountStr2(currentTab === 0 ? sellRateHistory : buyRateHistory)}`}&nbsp;
              {`${currencyCode || ''}`}
            </RateValue>
          </HeaderRate>
        </FormHeaderTop>
        <FormHeader>
          <TabWrap>
            {FX_CALCULATOR_TABS.map((tab, index) => (
              <ButtonCommon
                key={tab.label}
                value={t(tab.label)}
                color={
                  currentTab === index
                    ? 'var(--ds-c-blue)'
                    : 'var(--ds-c-grey-dark)'
                }
                background={
                  currentTab === index
                    ? 'var( --ds-c-white)'
                    : 'transparent'
                }
                styles={{
                  textTransform: 'uppercase',
                  fontSize: '16px',
                  lineHeight: '20px',
                  fontWeight: 500,
                  marginRight: '0px',
                  paddingInline: '0px',
                  width: '50%',
                  height: '44px',
                  float: 'left',
                  ...(currentTab === index && {
                    fontWeight: 700,
                    boxShadow: '0px 0px 8px 0px rgba(28, 63, 136, 0.15)',
                    borderRadius: '10px',
                  }),
                }}
                onClick={() => handleTabOnClick(index)}
              />
            ))}
          </TabWrap>
        </FormHeader>
        <FormBody>
          <BodySection style={{ display: 'block' }}>
            {currentTab === 0 && FX_CALCULATOR_FORM_AMOUNT_FIELDS.map((field) => (
              <FormInputAmountValidation
                key={field.name}
                field={field}
                currencies={currencies}
                currentTab={currentTab}
                information={information}
                setInformation={setInformation}
                validation={validation}
                setValidation={setValidation}
                rate={sellRateHistory}
                setRate={setSellRateHistory}
              />
            ))}
            {currentTab === 1 && FX_CALCULATOR_FORM_AMOUNT_FIELDS_YOU_SELL.map((field) => (
              <FormInputAmountValidation
                key={field.name}
                field={field}
                currencies={currencies}
                currentTab={currentTab}
                information={information}
                setInformation={setInformation}
                validation={validation}
                setValidation={setValidation}
                rate={buyRateHistory}
                setRate={setBuyRateHistory}
              />
            ))}
          </BodySection>
        </FormBody>
        <FormFooter>
          <ButtonCommon
            value={
              currentTab === 0 ? t('button_buy_now') : t('button_contact_us')
            }
            color="var(--c-primary)"
            background="var(--bg-primary)"
            isFill={true}
            styles={{
              paddingInline: '40px',
              textTransform: 'uppercase',
            }}
            onClick={handleTabActionOnClick}
          />
        </FormFooter>
      </FormStyled>
    </FXCalculatorFormStyled>
  );
};

const FXCalculatorFormStyled = styled.div`
  background-color: var(--c-fxcalculator-bg);
  width: 100%;
  border-radius: 0px;

  @media screen and (width: ${FX_CALCULATOR_DESKTOP_MIN_WIDTH}px) {
    width: 486px;
    border-radius: 24px;
  }
`;

const FormStyled = styled.div``;

const FormHeaderTop = styled.div`
  font-family: var(--ff-primary);
  height: auto;
  padding-top: 5.5px;
  padding-bottom: 5.5px;
  padding-right: 0;
  display: block;
  border-radius: 0;
  background-color: #212121;

  @media screen and (width: ${FX_CALCULATOR_DESKTOP_MIN_WIDTH}px) {
    border-radius: 24px 24px 0 0;
  }
`;

const HeaderLabel = styled.label`
  font-family: var(--ff-primary);
  font-size: 16px;
  font-weight: 500;
  color: var(--c-calculator-rate2);
  text-align: center;
  width: 100%;
  color: var(--c-calculator-rate2);
  color: #fff;
`;
const HeaderRate = styled.div`
  justify-content: center;
  align-items: center;
  padding-top: 0;
  display: flex;
`;
const RateValue = styled.p`
  color: var(--c-calculator-rate2);
  margin-bottom: 0;
  font-family: var(--ff-primary);
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  color: #fff;
`;

const FormHeader = styled.div`
  font-family: var(--ff-primary);
  height: auto;
  display: block;
  border-radius: 0;
  padding: 16px;

  @media screen and (width: ${FX_CALCULATOR_DESKTOP_MIN_WIDTH}px) {
    border-radius: 24px 24px 0 0;
  }
`;
const TabWrap = styled.div`
  height: 50px;
  width: 100%;
  background-color: var(--ds-c-grey-disabled);
  border-radius: 12px;
  padding: 3px;
`;

const FormBody = styled.div`
  padding: 0px 16px 0px 16px;
`;
const BodySection = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0;
  display: flex;
`;
const UpdateAt = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--c-fxcalculator-text2);

  margin: 0;
  padding: 0;
`;

const FormFooter = styled.div`
  border: none;
  display: flex;
  justify-content: center;
  padding: 0 16px 16px 16px;

  @media screen and (width: ${FX_CALCULATOR_DESKTOP_MIN_WIDTH}px) {
    justify-content: flex-end;
  }
`;

export default FxCalculatorFormPage;
