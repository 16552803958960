import { ENDPOINT_ID } from 'components/common/Endpoint';
import { useEffect, useRef, useState } from 'react';

const useDetectEndpointVisibleOnScreen = () => {
  const [isIntersecting, setIntersecting] = useState(false);
  const observerRef = useRef(null);

  useEffect(() => {
    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].intersectionRatio <= 0) {
        setIntersecting(false);

        return;
      }

      setIntersecting(true);
    });
  }, []);

  useEffect(() => {
    const endPointEle = document.getElementById(ENDPOINT_ID);

    if (endPointEle) {
      observerRef.current.observe(endPointEle);
    }

    return () => {
      if (endPointEle) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return isIntersecting;
};

export default useDetectEndpointVisibleOnScreen;
