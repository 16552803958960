/* eslint-disable no-unused-vars */
import { DESKTOP_MIN_WIDTH, THROTTLE_SEC } from 'constants';
import useEnterKeyDown from 'hooks/useEnterKeyDown';
import useThrottle from 'hooks/useThrottle';
import Button from 'react-bootstrap/esm/Button';
import styled from 'styled-components';

const ButtonCommon = ({
  value = '',
  color = 'var(--c-primary)',
  background = 'var(--bg-primary)',
  borderNameVariable = '',
  onClick = () => {},
  isDisabled = false,
  isUseKeyDown = false,
  isFill = undefined,
  styles = {},
  buttonIconStyles = {},
  iconSrc = '',
  iconStyles = {},
  isReverseIcon = false,
  isUseKeyDownException = false,
}) => {
  const throttle = useThrottle(THROTTLE_SEC);

  useEnterKeyDown(isUseKeyDown, onClick, isUseKeyDownException);

  const colorDisabled = {
    'var(--c-primary)': 'var(--ds-c-grey-hover)',
    'var(--c-secondary)': 'var(--c-secondary-disabled)',
  };

  const colorHover = {
    'var(--c-primary)': 'var(--c-primary-hover)',
    'var(--c-secondary)': 'var(--c-secondary-hover)',
    'var(--c-tertiary)': 'var(--c-tertiary-hover)',
    'var(--ds-c-blue)': 'var(--ds-c-blue-hover)',
    'var(--ds-c-blue-hyperlink-default)': 'var(--ds-c-blue-hyperlink-hover)',
    'var(--bg-primary)': 'var(--bg-primary-hover)',
    'var(--ds-c-grey-dark)': 'var(--ds-c-grey)',
  };

  const backgroundHover = {
    'var(--bg-primary)': 'var(--bg-primary-hover)',
    'var(--ds-c-white)': 'var(--ds-c-white)',
    'var(--root-body-bg)': 'var(--root-body-bg-hover)',
  };

  const backgroundDisabled = {
    'var(--bg-primary)': 'var(--bg-primary-disabled)',
    'var(--ds-c-white)': 'var(--ds-c-grey-hover)',
    'var(--root-body-bg)': 'var(--ds-c-blue-disabled)',
  };

  const handleOnClick = () => {
    throttle(() => {
      onClick();
    });
  };

  const renderButtonStyled = (isOnClickDisabled) => (
    <ButtonStyled
      onClick={isOnClickDisabled ? () => {} : handleOnClick}
      style={{ ...styles }}
      $styles={styles}
      $color={color}
      $colorHover={colorHover[color]}
      $colorDisabled={colorDisabled[color]}
      $background={background}
      $backgroundHover={backgroundHover[background]}
      $backgroundDisabled={backgroundDisabled[background]}
      $borderNameVariable={borderNameVariable}
      disabled={isDisabled}
      $isFill={isFill}
    >
      {value}
    </ButtonStyled>
  );

  if (iconSrc) {
    return (
      <ButtonIconStyled
        style={{ ...buttonIconStyles }}
        $color={color}
        $colorHover={colorHover[color]}
        $colorDisabled={colorDisabled[color]}
        $background={background}
        $backgroundHover={backgroundHover[background]}
        $backgroundDisabled={backgroundDisabled[background]}
        $borderNameVariable={borderNameVariable}
        disabled={isDisabled}
        $isFill={isFill}
        onClick={handleOnClick}
      >
        {isReverseIcon ? (
          <>
            <IconStyled
              src={iconSrc}
              width={24}
              height={24}
              alt=""
              style={{ ...iconStyles }}
            />
            {value && renderButtonStyled(true)}
          </>
        ) : (
          <>
            {value && renderButtonStyled(true)}
            <IconStyled
              src={iconSrc}
              width={24}
              height={24}
              alt=""
              style={{ ...iconStyles }}
            />
          </>
        )}
      </ButtonIconStyled>
    );
  }
  return <>{renderButtonStyled()}</>;
};

const ButtonStyled = styled(Button)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 44px;
  border: none;
  outline: none !important;
  border-radius: 12px;
  padding: 0 10px;
  margin: 0;
  border: ${(props) =>
    props.$borderNameVariable &&
    `var(${props.$borderNameVariable}) !important`};

  color: ${(props) => props.$color}!important;
  background: ${(props) => props.$background}!important;

  &:focus,
  &:focus-visible,
  &:active {
    color: ${(props) => props.$color}!important;
    background: ${(props) => props.$background}!important;
    outline: none !important;
  }

  &:disabled {
    color: ${(props) => props.$colorDisabled}!important;
    background-color: ${(props) => props.$backgroundDisabled}!important;
    cursor: not-allowed;
    outline: none !important;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    &:hover {
      border: none;
      outline: none;
      box-shadow: none;
      background-color: ${(props) => props.$backgroundHover}!important;
      color: ${(props) => props.$colorHover}!important;
      border: ${(props) =>
        props.$isFill || props.$isFill === undefined
          ? 'none!important'
          : `var(${props.$borderNameVariable}-hover) !important`};
      border-bottom: ${(props) => props.$styles?.borderBottom}!important;

      webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
    }

    &:disabled {
      &:hover {
        background-color: ${(props) => props.$backgroundDisabled}!important;
      }
    }

    &:not(:disabled) {
      webkit-transition: none !important;
      -moz-transition: none !important;
      -o-transition: none !important;
      transition: none !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    cursor: pointer;
  }
`;

const ButtonIconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: ${(props) => props.$background}!important;
  border-radius: 12px;
  padding: 0 10px;
  margin: 0px;
  width: fit-content;
  height: 44px;
  outline: ${(props) =>
    props.$borderNameVariable &&
    `var(${props.$borderNameVariable}) !important`};

  & button {
    border: none !important;
  }

  &:disabled {
    background-color: ${(props) => props.$backgroundDisabled}!important;
    cursor: not-allowed;

    & button {
      color: ${(props) => props.$colorDisabled}!important;
      background-color: ${(props) => props.$backgroundDisabled}!important;
      cursor: not-allowed !important;
    }
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    &:hover {
      background-color: ${(props) => props.$backgroundHover}!important;
      outline: ${(props) =>
        props.$isFill || props.$isFill === undefined
          ? 'none!important'
          : `var(${props.$borderNameVariable}-hover) !important`};
      cursor: pointer;

      & img {
        opacity: 0.65 !important;
      }

      & button {
        color: ${(props) => props.$colorHover}!important;
        background-color: ${(props) => props.$backgroundHover}!important;
        border: none !important;

        webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;

        &:not(:disabled) {
          webkit-transition: none !important;
          -moz-transition: none !important;
          -o-transition: none !important;
          transition: none !important;
        }
      }
    }

    &:disabled {
      &:hover {
        background-color: ${(props) => props.$backgroundDisabled}!important;

        & button {
          background-color: ${(props) => props.$backgroundDisabled}!important;
        }
      }
    }
  }
`;
const IconStyled = styled.img`
  cursor: pointer;
  user-select: none;
`;

export default ButtonCommon;
