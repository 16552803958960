import { checkFormInputValidation } from 'components/FormRegister/ResidentialAddress/func';
import { REGISTER_STREET_TYPES } from 'constants';
import {
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_FINDER_FIELD,
  FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS,
} from 'constants/fx';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import CustomAddressFinderDropdown from './components/CustomAddressFinderDropdown';
import FormInputAddressManuallyValidation from './components/FormInputAddressManuallyValidation';
import FormInputAddressSwitch from './components/FormInputAddressSwitch';

const ResidentialAddress = ({
  information,
  setInformation,
  validation,
  setValidation,
}) => {
  const { t } = useLang();
  const { isMobile } = useDetectDevice();

  const [addressFinder, setAddressFinder] = useState(null);

  const { isEnterAddressManuallySelected } = information;

  const getAddressStreetType = (value) => {
    return REGISTER_STREET_TYPES?.find((c) => c?.key === value);
  };

  useEffect(() => {
    if (addressFinder) {
      const {
        fullAddress,
        buildingName,
        subdwelling,
        streetNumber,
        streetName,
        streetType,
        locality,
        state,
        postcode,
      } = addressFinder || {};

      setInformation({
        ...information,
        [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME]: fullAddress,
        [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME]:
          buildingName ? `${buildingName} ${subdwelling}` : subdwelling,
        [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME]:
          streetNumber,
        [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME]:
          streetName,
        [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME]:
          getAddressStreetType(streetType),
        [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME]: postcode,
        [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME]: locality,
        [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME]: state,
        isEnterAddressManuallySelected,
      });

      const addressFinderEle = document.getElementsByName(
        FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME
      )[0];

      if (addressFinderEle) {
        addressFinderEle.value = fullAddress;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressFinder, isEnterAddressManuallySelected]);

  /* BEGIN - TEST DOCUMENT UPLOAD */
  // useEffect(() => {
  //   if (!isMobile) return;
  //   if (information[FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME])
  //     return;
  //   setInformation({
  //     ...information,
  //     [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_FINDER_NAME]:
  //       '56 CATALPA AVE, ELLENBROOK WA 6069',
  //     [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_UNITNAME_NAME]: '',
  //     [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NO_NAME]: '56',
  //     [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_NAME_NAME]:
  //       'CATALPA',
  //     [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STREET_TYPE_NAME]: {
  //       key: 'AVE',
  //       value: 'AVENUE',
  //     },
  //     [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_POST_CODE_NAME]: '6069',
  //     [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_SUBURB_NAME]: 'ELLENBROOK',
  //     [FX_ADD_TRANSACTION_ENTER_INFORMATION_ADDRESS_STATE_NAME]: 'WA',
  //     isEnterAddressManuallySelected: false,
  //   });
  // }, [isMobile, information]);
  /* END - TEST DOCUMENT UPLOAD */

  return (
    <ResidentialAddressStyled>
      <Label>{t('label_residential_address')}</Label>
      <Accordion defaultActiveKey={['0', '1']} alwaysOpen>
        <AccordionItem eventKey="1" id="register-address-accordion">
          <AccordionBody>
            <FormInputAddressSwitch
              information={information}
              setInformation={setInformation}
              validation={validation}
              setValidation={setValidation}
            />
            {!isEnterAddressManuallySelected && (
              <CustomAddressFinderDropdown
                field={
                  FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_FINDER_FIELD
                }
                information={information}
                setInformation={setInformation}
                validation={validation}
                setValidation={setValidation}
                setAddressFinder={setAddressFinder}
                checkFormInputValidation={checkFormInputValidation}
              />
            )}
            {isEnterAddressManuallySelected &&
              FX_ADD_TRANSACTION_ENTER_INFORMATION_PERSONAL_DETAILS_ADDRESS_MANUALLY_FIELDS.map(
                (field) => (
                  <FormInputAddressManuallyValidation
                    key={field.name}
                    field={field}
                    information={information}
                    setInformation={setInformation}
                    validation={validation}
                    setValidation={setValidation}
                    checkFormInputValidation={checkFormInputValidation}
                  />
                )
              )}
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </ResidentialAddressStyled>
  );
};

const ResidentialAddressStyled = styled.div`
  margin-bottom: 40px;
`;

const Label = styled.p`
  ${paragraphMixin};
  font-weight: 500;
  color: var(--ds-c-blue);
  margin-bottom: 16px;
`;

const AccordionItem = styled(Accordion.Item)`
  border: none;
`;
const AccordionBody = styled(Accordion.Body)`
  padding: 0 !important;
`;

export default ResidentialAddress;
