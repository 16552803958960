import BgModalActiveSvg from 'assets/ver2/icons/offer/modal/bg_modal_active.svg';
import BgModalWhiteSvg from 'assets/ver2/icons/offer/modal/bg_modal_coming.svg';
import BgModalInactiveSvg from 'assets/ver2/icons/offer/modal/bg_modal_disabled.svg';
import {
  DISCOUNT_ACTIVE,
  DISCOUNT_COMING_SOON,
  DISCOUNT_EXPIRED,
  DISCOUNT_FULLY_REDEEMED,
  DISCOUNT_METHOD,
  DISCOUNT_REDEEMED,
} from 'constants';
import {
  format,
  getDiscountStatus,
  getDiscountUnit,
  textModalColor,
} from 'helpers';
import useLang from 'hooks/useLang';
import React from 'react';
import styled from 'styled-components';
import { paragraphMixin } from 'styles';
import CircleRoundCutHorizontal from './CircleRoundCutHorizontal';

const HeaderAmountType = ({ offer, inactive }) => {
  const { t } = useLang();

  const { discountMethod, discountAmount } = offer || {};

  const { status, discountMethodKey, isComingSoon } =
    getDiscountStatus(offer || null) || {};

  const headerBgSvg = {
    [DISCOUNT_ACTIVE]: inactive ? BgModalInactiveSvg : BgModalActiveSvg,
    [DISCOUNT_COMING_SOON]: BgModalWhiteSvg,
    [DISCOUNT_REDEEMED]: BgModalWhiteSvg,
    [DISCOUNT_EXPIRED]: BgModalWhiteSvg,
    [DISCOUNT_FULLY_REDEEMED]: BgModalWhiteSvg,
  };

  const getAmoutUnitText = () => {
    if (discountMethod === DISCOUNT_METHOD.PERCENT) {
      return `
      ${format.toAmountStr(Number(discountAmount))}${getDiscountUnit(
        discountMethod
      )}`;
    }
    return `
    ${format.toAmountStr(Number(discountAmount))} ${getDiscountUnit(
      discountMethod
    )}`;
  };

  return (
    <HeaderAmountTypeStyled
      $backgroundImage={headerBgSvg[status]}
      $isComingSoon={isComingSoon}
    >
      <CircleRoundCutHorizontal isBorderDashedLine={isComingSoon} />
      <CircleRoundCutHorizontal
        isBorderDashedLine={isComingSoon}
        right="calc(100% - 9px)"
      />
      <AmountCurrency
        style={{
          color: textModalColor()[status],
        }}
      >
        {getAmoutUnitText()}
      </AmountCurrency>
      <OfferType
        style={{
          color: textModalColor()[status],
        }}
      >
        {t(discountMethodKey)}
      </OfferType>
    </HeaderAmountTypeStyled>
  );
};

const HeaderAmountTypeStyled = styled.div`
  width: 100%;
  height: 56px;
  padding: 6px;

  position: relative;
  background-image: ${(props) =>
    props.$backgroundImage && `url(${props.$backgroundImage})`};
  border: ${(props) =>
    props.$isComingSoon && '1px dashed var(--ds-c-blue-remox-hover)'};
  background-size: cover;
`;

const AmountCurrency = styled.p`
  ${paragraphMixin};
  color: #fff;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
`;

const OfferType = styled.p`
  ${paragraphMixin};
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
`;

export default HeaderAmountType;
