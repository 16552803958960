import AddReceiverStep1Icon from 'components/common/Icons/ver2/form-add-receiver/AddReceiverStep1Icon';
import AddReceiverStep2Icon from 'components/common/Icons/ver2/form-add-receiver/AddReceiverStep2Icon';
import AddReceiverStep3Icon from 'components/common/Icons/ver2/form-add-receiver/AddReceiverStep3Icon';
import useLang from 'hooks/useLang';

export const FX_ADD_RECEIVER_TOTAL_NUMBER_OF_STEPS = 3;

const useAddReceiver = () => {
  const { t } = useLang();

  const getNavbarsAndBodyContentOfSidebar = () => {
    return [
      {
        Icon: AddReceiverStep1Icon,
        label: t('label_general_details'),
      },
      {
        Icon: AddReceiverStep2Icon,
        label: t('label_address'),
      },
      {
        Icon: AddReceiverStep3Icon,
        label: t('label_account_details'),
      },
    ];
  };

  return { getNavbarsAndBodyContentOfSidebar };
};

export default useAddReceiver;
