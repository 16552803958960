import DeleteIcon from '../../assets/icons/delete-icon.svg';
import EditIcon from '../../assets/icons/edit-icon.svg';

export const EDIT_RECEIVER_TABS = [
  {
    label: 'label_general_details',
  },
  {
    label: 'label_address',
  },
  {
    label: 'label_account_details',
  },
];

export const FAV_RECEIVER_TABLE_HEADING = {
  Receiver: 'fullname',
  Country: 'countryName',
  Actions: 'actions',
};

export const RECEIVER_TABLE_HEADING = {
  Receiver: 'fullname',
  Country: 'countryCode',
  Actions: 'actions',
};

export const RECEIVER_TABLE_HEADING_TRANSLATE = {
  Receiver: 'label_receiver_name',
  Country: 'label_country',
  Actions: 'label_actions',
};

export const RECEIVER_TABLE_HEADING_UNABLE_SORT = ['Actions'];

export const RECEIVER_ACTIONS = [
  { label: 'button_edit', icon: EditIcon },
  { label: 'button_delete', icon: DeleteIcon },
];

export const RECEIVER_TABLE_SORT_ORDER_INITIAL = {
  Receiver: null,
  Country: null,
};

export const RECEIVER_FILTER_INITIAL = {
  favoriteSelected: 'all',
  countriesSelected: null,
};

export const RECEIVER_TABS = [
  {
    label: 'label_individual_receiver',
  },
  {
    label: 'label_business_receiver',
  },
];
