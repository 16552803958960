const CosineSimilarity = {
  wordCountMap (str) {
    const words = str.split(' ')
    const wordCount = {}
    words.forEach((w) => {
      wordCount[w] = (wordCount[w] || 0) + 1
    })
    return wordCount
  },

  addWordsToDictionary (wordCountmap, dict) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in wordCountmap) {
      if (Object.prototype.hasOwnProperty.call(wordCountmap, key)) {
        // eslint-disable-next-line no-param-reassign
        dict[key] = true
      }
    }
  },
  wordMapToVector (map, dict) {
    const wordCountVector = []

    // eslint-disable-next-line no-restricted-syntax
    for (const term in dict) {
      if (Object.prototype.hasOwnProperty.call(dict, term)) {
        wordCountVector.push(map[term] || 0)
      }
    }
    return wordCountVector
  },
  dotProduct (vecA, vecB) {
    let product = 0
    for (let i = 0; i < vecA.length; i += 1) {
      product += vecA[i] * vecB[i]
    }
    return product
  },
  magnitude (vec) {
    let sum = 0
    for (let i = 0; i < vec.length; i += 1) {
      sum += vec[i] * vec[i]
    }
    return Math.sqrt(sum)
  },
  cosineSimilarity (vecA, vecB) {
    return this.dotProduct(vecA, vecB) / (this.magnitude(vecA) * this.magnitude(vecB))
  },

  calculateSimilarity (txtA, txtB) {
    const wordCountA = this.wordCountMap(txtA)
    const wordCountB = this.wordCountMap(txtB)
    const dict = {}
    this.addWordsToDictionary(wordCountA, dict)
    this.addWordsToDictionary(wordCountB, dict)
    const vectorA = this.wordMapToVector(wordCountA, dict)
    const vectorB = this.wordMapToVector(wordCountB, dict)
    return this.cosineSimilarity(vectorA, vectorB)
  },

  score (word1, word2) {
    const score = this.calculateSimilarity(word1, word2)
    return score ? Math.round(score * 100) : 0.00
  }
}

export default CosineSimilarity
