import ReceiverBusinessIcon from 'assets/ver2/icons/receiver-business-icon.svg';
import ReceiverFemaleIcon from 'assets/ver2/icons/receiver-female-icon.svg';
import ReceiverMaleIcon from 'assets/ver2/icons/receiver-male-icon.svg';
import { FEMALE, MALE } from 'constants/common';
import { BUSINESS_OF_RECEIVER_TYPE } from 'constants/form-edit-receiver';

export const ADD_RECEIVER_STEP_REFERENCE = {
  0: 'personalDetails',
  1: 'residentialAddress',
  2: 'accountDetails',
};

export const AVATAR_OF_RECEIVER_TYPE_REFERENCE = {
  [MALE]: ReceiverMaleIcon,
  [FEMALE]: ReceiverFemaleIcon,
  [BUSINESS_OF_RECEIVER_TYPE]: ReceiverBusinessIcon,
};
