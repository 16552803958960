/* eslint-disable no-unused-vars */
import { api } from 'api';
import { getFullNameReceiver } from 'components/FormEditReceiver/func';
import {
  ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME,
  ADD_TRANSACTION_YOU_SEND_NAME,
  BUSINESS_OF_RECEIVER_TYPE,
  FEMALE,
  MALE,
  PAGE_DEFAULT,
  SIZE_PAGE_LIMIT_DEFAULT,
} from 'constants';
import { datePicker, format } from 'helpers';
import { isArray, isEmpty } from 'lodash';
import { dispatchStore } from 'store';
import { SET_SHOW_LOADING } from 'store/action';
import { arrayUtils, numberUtils, objectUtils } from 'utils';

export const convertFilteredKeyFixed = (filtered = {}) => {
  const filteredFixed = {
    countriesSelected: filtered?.countriesSelected,
    statusSelected: filtered?.statusSelected,
    dateFromSelected: filtered?.dateFromSelected,
    dateToSelected: filtered?.dateToSelected,
    amountFrom: filtered?.amountFrom,
    amountTo: filtered?.amountTo,
  };

  return objectUtils.deletePropIsFalsy(filteredFixed);
};

export const getBodyPayloadFiltered = (filtered) => {
  return {
    countryCode: filtered?.countriesSelected?.map((country) => country.code),
    status: filtered?.statusSelected?.map((status) => status.code),
    sendDateFrom: filtered?.dateFromSelected
      ? datePicker.dateUTCToDateStr(new Date(filtered?.dateFromSelected))
      : '',
    sendDateTo: filtered?.dateToSelected
      ? datePicker.dateUTCToDateStr(new Date(filtered?.dateToSelected))
      : '',
    amountFrom: filtered?.amountFrom ? parseFloat(filtered?.amountFrom) : null,
    amountTo: filtered?.amountTo ? parseFloat(filtered?.amountTo) : null,
  };
};

export const fetchTransactionsFiltered = async ({
  token = null,
  body = null,
  sort = null,
  page = PAGE_DEFAULT,
  size = SIZE_PAGE_LIMIT_DEFAULT,
  transactions = null,
  setTransactions = null,
  setCurrentPage = null,
  setTotalPages = null,
  setTotalElements = null,
  setFetching = null,
}) => {
  if (token) {
    try {
      const sortPayload = sort || {};
      let bodyPayload = body || {};

      bodyPayload = objectUtils.deletePropIsFalsy(bodyPayload);

      const { data } = await api.searchTrans(
        token,
        bodyPayload,
        sortPayload,
        page,
        size
      );

      if (data) {
        const { content, totalPages, totalElements } = data;

        if (transactions?.length) {
          setTransactions && setTransactions([...transactions, ...content]);
        } else if (!arrayUtils.isArrayEmpty(content)) {
          setTransactions && setTransactions(content);
        } else if (arrayUtils.isArrayEmpty(content)) {
          if (isArray(transactions)) {
            setTransactions([]);
          } else {
            setTransactions(null);
          }
        }

        setCurrentPage && setCurrentPage(page);

        setTotalPages && setTotalPages(totalPages);

        setTotalElements && setTotalElements(totalElements);

        setFetching && setFetching(false);
      }
    } catch (error) {
      console.error(error?.message);
    }
  }
};

export const fetchTransactionInformation = async (token, refNumber, status) => {
  const getSendAmount = (remittances) => {
    let amount = 0;

    remittances.forEach((remit) => {
      amount += remit.sendAmount;
    });

    return amount;
  };

  const getSendingDate = (createDate) => {
    return `${datePicker.padTo2Digits(createDate[2])}/${datePicker.padTo2Digits(
      createDate[1]
    )}/${createDate[0]}`;
  };

  try {
    const { data: dataTransaction } = await api.getTran(token, refNumber);

    if (dataTransaction) {
      const {
        status: statusLatest,
        payout,
        paymentMethod,
        remittance,
        creaditCardNo,
        creaditCardType,
        createDate,
        fee,
        paid,
        discountAmount,
        surcharge,
        totalPay,
        dueDateTime,
        discount,
      } = dataTransaction;

      const { data: dataReceiver } = await api.getReceiverRecord(
        token,
        refNumber
      );
      const { firstname, lastname, address, mobile, phone, gender } =
        dataReceiver;

      const isReceiverOfIndividualType = gender === MALE || gender === FEMALE;
      const isReceiverOfBusinessType = gender === BUSINESS_OF_RECEIVER_TYPE;

      const newInformation = {
        refNumber,
        status: status || statusLatest,
        fullName: getFullNameReceiver({
          isReceiverOfIndividualType,
          isReceiverOfBusinessType,
          lastName: lastname,
          firstName: firstname,
          countryCode: address?.country?.code,
        }),
        fullAddress: address?.fullAddress,
        countryCode: address?.country?.code,
        mobile: mobile && format.toMobileNumberStr(mobile?.number),
        phone: phone && format.toMobileNumberStr2(phone?.number),
        payout: {
          dmCode: payout?.dmCode,
          pickupOffice: payout?.pickupOffice,
          bank: payout?.bank,
          ewallet: payout?.ewallet,
          payoutPIN: payout?.payoutPIN,
        },
        currencyCode: remittance[0]?.currency?.code,
        receiveAmount: numberUtils.mathRound(remittance[0]?.receiveAmount),
        rateValue: remittance[0]?.rate?.rateValue,
        currencyCodeAnother: remittance[1]?.currency?.code,
        receiveAmountAnother: numberUtils.mathRound(
          remittance[1]?.receiveAmount
        ),
        rateValueAnother: remittance[1]?.rate?.rateValue,
        payment: {
          paymentMethod,
          creaditCardNo,
          creaditCardType,
        },
        sendingDate: getSendingDate(createDate),
        sendAmount: numberUtils.mathRound(getSendAmount(remittance)),
        feeAmount: numberUtils.mathRound(fee?.feeAmount),
        paid,
        discountAmount: numberUtils.mathRound(discountAmount),
        surchargeAmount: numberUtils.mathRound(surcharge),
        totalPayAmount: numberUtils.mathRound(totalPay),
        dueDateTime,
        discountApplied: discount,
      };

      return newInformation;
    }
  } catch (error) {
    console.error(error?.message);
  }
};

export const fetchLookupFee = async (
  amount,
  dmCode,
  information,
  isOnlyCurrency,
  countryCode
) => {
  if (isEmpty(countryCode) || isEmpty(dmCode)) return;

  const { currentCurrencySelected } = information || {};

  const payload = {
    countryCode,
    dmCode,
    amount,
    currencyCode: isOnlyCurrency
      ? currentCurrencySelected?.currencyCode
      : 'AUD',
  };

  try {
    const { data } = await api.lookupFee(
      payload['countryCode'],
      payload['dmCode'],
      payload['amount'],
      payload['currencyCode']
    );

    if (data) {
      return {
        ...information,
        feeAmount: data,
      };
    }
  } catch (error) {
    console.error(error?.message);
  }
};
export const fetchLookupOriginalFee = async (
  amount,
  dmCode,
  information,
  isOnlyCurrency,
  countryCode
) => {
  if (isEmpty(countryCode) || isEmpty(dmCode)) return;

  const { currentCurrencySelected } = information || {};

  const payload = {
    countryCode,
    dmCode,
    amount,
    currencyCode: isOnlyCurrency
      ? currentCurrencySelected?.currencyCode
      : 'AUD',
  };

  try {
    const { data } = await api.lookupOriginalFee(
      payload['countryCode'],
      payload['dmCode'],
      payload['amount'],
      payload['currencyCode']
    );

    if (data) {
      return {
        ...information,
        originalFee: data,
      };
    }
  } catch (error) {
    console.error(error?.message);
  }
};
export const getLookupFeeAmountLatest = async ({
  sendAmount,
  dmCode,
  isOnlyCurrency,
  information,
  countryCode,
}) => {
  if (isEmpty(countryCode) || isEmpty(dmCode)) return;

  let newInformation = {
    ...information,
  };

  if (sendAmount) {
    newInformation = await fetchLookupFee(
      sendAmount,
      dmCode,
      newInformation,
      isOnlyCurrency,
      countryCode
    );
  }

  return newInformation;
};

const lookupFee = async (amount, information, countryCode, dmCode) => {
  try {
    const isOnlyCurrency = !information?.isAddAnotherReceivingCurrency;

    const newInformation = await fetchLookupFee(
      amount,
      dmCode,
      information,
      isOnlyCurrency,
      countryCode
    );

    return newInformation;
  } catch (error) {
    console.error(error?.message);

    dispatchStore({ type: SET_SHOW_LOADING, payload: false });
  }
};
export const fetchCurrentFee = async (
  information,
  setInformation,
  countryCode,
  dmCode,
  maxSendLimit = 30000,
  callback,
  isBackup = false
) => {
  if (isEmpty(countryCode) || isEmpty(dmCode)) return;

  let newInformation = { ...information };

  const sendAmount = numberUtils.mathRound(
    newInformation[ADD_TRANSACTION_YOU_SEND_NAME] || 0
  );
  const sendAnotherAmount = numberUtils.mathRound(
    newInformation[ADD_TRANSACTION_YOU_SEND_ANOTHER_NAME] || 0
  );
  const sendTotalAmount = numberUtils.mathRound(sendAmount + sendAnotherAmount);

  if (
    parseInt(sendTotalAmount) &&
    parseInt(sendTotalAmount) <= parseInt(maxSendLimit)
  ) {
    newInformation = await lookupFee(
      sendTotalAmount,
      newInformation,
      countryCode,
      dmCode
    );

    if (newInformation) {
      !isBackup && setInformation(newInformation);

      callback && callback(newInformation);

      return;
    }
  }

  newInformation = { ...newInformation, feeAmount: 0 };

  !isBackup && setInformation(newInformation);

  callback && callback(newInformation);
};
