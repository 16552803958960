import { backgroundReference, colorReference } from 'constants';
import React from 'react';
import styled from 'styled-components';

const AddTxnStep2Icon = ({
  status = 'disable' || 'in-progress' || 'completed',
}) => {
  return (
    <SvgStyled $backgroundColor={backgroundReference[status]}>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8908_12981)">
          <path
            d="M4.46062 12.9597C4.94904 13.1309 5.42157 12.7411 5.49314 12.2285C5.61089 11.3853 5.86149 10.5845 6.2231 9.84817C5.10737 9.49825 4.29659 8.57392 3.99172 7.39509H3.521C3.41778 7.39509 3.33333 7.30469 3.33333 7.1942V6.71875C3.33333 6.60826 3.41778 6.51786 3.521 6.51786H3.85566C3.84003 6.3019 3.84315 6.03906 3.85879 5.81138H3.521C3.41778 5.81138 3.33333 5.72098 3.33333 5.61049V5.11161C3.33333 5.00112 3.41778 4.91071 3.521 4.91071H4.03864C4.49059 3.50949 5.71823 2.5 7.23674 2.5C7.56672 2.5 7.87167 2.54855 8.06246 2.58705C8.1688 2.60882 8.23605 2.72433 8.2079 2.8365L8.02024 3.5798C7.99365 3.68192 7.89825 3.74554 7.80129 3.72377C7.65586 3.69364 7.43535 3.65513 7.20859 3.65513C6.35003 3.65513 5.69164 4.15737 5.36948 4.91071H7.53231C7.65116 4.91071 7.7403 5.02958 7.71528 5.15513L7.61363 5.65402C7.60439 5.69861 7.58122 5.73849 7.54796 5.7671C7.5147 5.79571 7.47333 5.81133 7.43066 5.81138H5.13647C5.11301 6.04409 5.11614 6.27846 5.13491 6.51786H7.23518C7.35559 6.51786 7.44473 6.63672 7.41815 6.76228L7.31806 7.23772C7.30882 7.28231 7.28565 7.3222 7.25239 7.35081C7.21913 7.37941 7.17776 7.39503 7.13509 7.39509H5.35697C5.6155 8.10956 6.15772 8.63587 6.85413 8.78315C8.08615 7.03206 10.0101 5.80293 12.2285 5.49315C12.7411 5.42157 13.1309 4.94904 12.9597 4.46062C12.0491 1.86286 9.57541 0 6.66667 0C2.98477 0 0 2.98477 0 6.66667C0 9.57541 1.86286 12.0491 4.46062 12.9597Z"
            fill={colorReference[status]}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.66667 13.3333C6.66667 9.65568 9.65568 6.66667 13.3333 6.66667C17.011 6.66667 20 9.65568 20 13.3333C20 17.011 17.011 20 13.3333 20C9.65568 20 6.66667 17.011 6.66667 13.3333ZM13.6557 12.6007H12.967C12.7619 12.6007 12.6007 12.4396 12.6007 12.2344C12.6007 12.0293 12.7619 11.8681 12.967 11.8681H14.7546C15.1575 11.8681 15.4872 11.5385 15.4872 11.1355C15.4872 10.7326 15.1575 10.4029 14.7546 10.4029H14.0659V9.67033C14.0659 9.31878 13.815 9.32414 13.4834 9.33123C13.4349 9.33226 13.3847 9.33333 13.3333 9.33333C13.282 9.33333 13.2318 9.33226 13.1833 9.33123C12.8517 9.32414 12.6007 9.31878 12.6007 9.67033V10.4029C12.6007 10.4103 12.6026 10.4158 12.6044 10.4212C12.6062 10.4267 12.6081 10.4322 12.6081 10.4396C11.7729 10.6081 11.1355 11.348 11.1355 12.2344C11.1355 13.2454 11.956 14.0659 12.967 14.0659H13.6557C13.8608 14.0659 14.022 14.2271 14.022 14.4615C14.022 14.6667 13.8608 14.8278 13.6557 14.8278H11.8681C11.4652 14.8278 11.1355 15.1575 11.1355 15.5604C11.1355 15.9707 11.4652 16.293 11.8681 16.293H12.6007V16.9963C12.6007 17.3251 12.8203 17.314 13.12 17.2987C13.1876 17.2953 13.2592 17.2917 13.3333 17.2917C13.4075 17.2917 13.4791 17.2953 13.5467 17.2987C13.8464 17.314 14.0659 17.3251 14.0659 16.9963V16.2491C14.8791 16.0586 15.4872 15.3333 15.4872 14.4322C15.4872 13.4212 14.6667 12.6007 13.6557 12.6007Z"
            fill={colorReference[status]}
          />
          <path
            d="M13.6897 2.70869L15.2687 3.94234C15.5207 4.13921 15.8887 3.92785 15.852 3.60833L15.7505 2.72382C16.3928 2.66131 17.0192 2.84251 17.5267 3.23901C18.4371 3.95224 18.7296 5.20162 18.2587 6.25333C18.2235 6.32988 18.2445 6.41949 18.3104 6.47101L18.9851 6.99814C19.0734 7.06713 19.2041 7.03823 19.2568 6.93684C20.0741 5.36409 19.6981 3.39646 18.271 2.28148C17.4892 1.67064 16.5341 1.42717 15.612 1.52734L15.5253 0.771909C15.4886 0.452386 15.0837 0.342103 14.8846 0.596872L13.6314 2.20084C13.5072 2.35992 13.5331 2.58634 13.6897 2.70869Z"
            fill={colorReference[status]}
          />
          <path
            d="M5.06621 15.9318L6.80151 16.9337C6.97362 17.0331 7.03087 17.2537 6.92994 17.4285L5.9122 19.1913C5.75054 19.4713 5.33418 19.4184 5.25338 19.1071L5.06235 18.3711C4.1632 18.5986 3.18352 18.4905 2.32428 17.9944C0.755904 17.0889 0.109742 15.1927 0.7002 13.5215C0.738245 13.4138 0.863613 13.367 0.960666 13.423L1.70215 13.8511C1.77461 13.893 1.80789 13.9788 1.78373 14.0595C1.46379 15.1665 1.92726 16.363 2.92808 16.9426C3.48581 17.2646 4.13133 17.3569 4.75873 17.2055L4.53506 16.3438C4.45426 16.0325 4.78929 15.772 5.06621 15.9318Z"
            fill={colorReference[status]}
          />
        </g>
        <defs>
          <clipPath id="clip0_8908_12981">
            <rect width={20} height={20} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgStyled>
  );
};

const SvgStyled = styled.div`
  width: 28px;
  min-width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.$backgroundColor};
`;

export default AddTxnStep2Icon;
