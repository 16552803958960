import ArrowDownIcon from 'components/common/Icons/ArrowDownIcon';
import {
  fetchConfigOccupation,
  fetchConfigPurpose,
  fetchConfigPurposeOfBusiness,
  fetchConfigRelationship,
  fetchConfigRelationshipOfBusiness,
  fetchConfigSourcesOfWealth,
} from 'config';
import { DESKTOP_MIN_WIDTH } from 'constants';
import useHideDropdown from 'hooks/useHideDropdown';
import useInputFocus from 'hooks/useInputFocus';
import useLang from 'hooks/useLang';
import useScrollIntoView from 'hooks/useScrollIntoView';
import useStore from 'hooks/useStore';
import { isFunction } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components';
import { arrayUtils, stringUtils } from 'utils';

const CustomSearchDropdown = ({
  field,
  isConfigRequired,
  data,
  keyFind,
  keyTranslate,
  noResultText,
  placeholder,
  information,
  setInformation,
  validation,
  setValidation,
  onChange,
  onClick,
  isDisabled,
  isSearchHidden,
  styles,
  inputStyles,
  toggleStyles,
  isClearBoth = false,
  scrollUp=false,
}) => {
  const { t, locate } = useLang();
  const { state } = useStore();
  const {
    configOccupation,
    configRelationship,
    configRelationshipOfBusiness,
    configPurpose,
    configPurposeOfBusiness,
    configSourcesOfWealth,
  } = state || {};

  const [searchValue, setSearchValue] = useState('');
  const [dataSearch, setDataSearch] = useState(null);
  const [currentSelected, setCurrentSelected] = useState(null);
  const [isShowDropdown, setShowDropdown] = useState(false);

  const SEARCH_NAME_FIELD = `search-${field.name}`;
  const NO_RESULT_TEXT =
    t(noResultText) ||
    stringUtils.replaceKeyword(t('form_text_no_result_by_label'), [
      { key: 'label', value: t(field.label).toLowerCase() },
    ]);

  const validRef = useRef(null);
  useScrollIntoView(validRef, validation[field.name], [validation]);

  useInputFocus(SEARCH_NAME_FIELD);

  useHideDropdown(isShowDropdown, setShowDropdown);

  useEffect(() => {
    if (!configOccupation) {
      fetchConfigOccupation(locate);
    }

    if (!configRelationship) {
      fetchConfigRelationship(locate);
    }

    if (!configRelationshipOfBusiness) {
      fetchConfigRelationshipOfBusiness(locate);
    }

    if (!configPurpose) {
      fetchConfigPurpose(locate);
    }

    if (!configPurposeOfBusiness) {
      fetchConfigPurposeOfBusiness(locate);
    }

    if (!configSourcesOfWealth) {
      fetchConfigSourcesOfWealth(locate);
    }
  }, [
    configOccupation,
    configRelationship,
    configRelationshipOfBusiness,
    configPurpose,
    configPurposeOfBusiness,
    configSourcesOfWealth,
  ]);

  useEffect(() => {
    const item = data?.find(
      (c) =>
        c?.[keyFind] ===
        (information[field.name]?.[keyFind] || information[field.name])
    );
    setCurrentSelected(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information[field.name], data]);

  const handleSearch = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    if (!value) {
      setDataSearch(null);

      return;
    }

    const newDataSearch = data?.filter(
      (item) =>
        (item?.[keyTranslate]?.toLowerCase()?.includes(value?.toLowerCase()) ||
          item?.[keyFind]?.toLowerCase()?.includes(value?.toLowerCase())) &&
        item
    );
    setDataSearch(newDataSearch);
  };

  const handleItemSelected = (item) => {
    setCurrentSelected(item);

    setSearchValue('');

    setDataSearch(null);

    setShowDropdown(false);

    const newInformation = {
      ...information,
      [field.name]: item,
    };
    setInformation(newInformation);

    const newValidation = {
      ...validation,
      [field.name]: '',
    };
    setValidation(newValidation);

    onChange && onChange(item);
  };

  const handleDropdowOnClick = (e, cb) => {
    e.preventDefault();

    if (isDisabled) return;

    if (isFunction(onClick)) return;

    setShowDropdown(!isShowDropdown);

    if(scrollUp) {
      console.log('>>> handleDropdowOnClick: ', scrollUp);
      window.scrollBy({ top: 300, behavior: 'smooth' });
    }

    cb(e);
  };

  const handleDropdownOnToggle = () => {
    setSearchValue('');

    setDataSearch(null);

    setShowDropdown(!isShowDropdown);
  };

  const handleDropdownOnClick = () => {
    isFunction(onClick) && onClick();
  };

  const renderDropdownItems = (data) => {
    if (arrayUtils.isArrayEmpty(data)) {
      return (
        <DropdownItem
          key={0}
          eventKey={0}
          $isReceiversEmpty={arrayUtils.isArrayEmpty(data)}
        >
          <NoItemText>{NO_RESULT_TEXT}</NoItemText>
        </DropdownItem>
      );
    }

    return (
      <>
        {data?.map((item, index) => (
          <DropdownItem
            key={`${item?.id}-${index}`}
            eventKey={index}
            onClick={() => handleItemSelected(item)}
          >
            {t(item?.[keyTranslate]) || t(item?.[keyFind])}
          </DropdownItem>
        ))}
      </>
    );
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <ButtonToggle
      href="/"
      ref={ref}
      onClick={(e) => handleDropdowOnClick(e, onClick)}
      $isPlaceholder={!currentSelected?.[keyFind]}
      $isDisabled={isDisabled}
      $isShowDropdown={isShowDropdown}
      $isInvalid={validation[field.name]}
      style={{ ...toggleStyles }}
    >
      <p name={field.name}>{children}</p>
      {!isDisabled && data?.length > 1 && (
        <ArrowDownIcon
          color="var(--ds-c-grey-dark)"
          size={{ width: 14, height: 10 }}
          styles={{
            transform: isShowDropdown && 'rotate(180deg)',
          }}
        />
      )}
    </ButtonToggle>
  ));

  return (
    <CustomSearchDropdownStyled
      style={{ ...styles }}
      $isClearBoth={isClearBoth}
    >
      <Label ref={validRef}>
        {t(field.label)}
        {(isConfigRequired ?? field.isRequired) && <span>*</span>}
      </Label>
      <DropdownStyled
        onToggle={handleDropdownOnToggle}
        onClick={handleDropdownOnClick}
        $isDisabled={isDisabled}
        $isInvalid={validation[field.name]}
      >
        <Dropdown.Toggle as={CustomToggle}>
          {t(currentSelected?.[keyTranslate]) ||
            t(currentSelected?.[keyFind]) ||
            t(field.placeholder)}
        </Dropdown.Toggle>

        {data?.length > 1 && (
          <DropdownMenuWrap styles={inputStyles}>
            {!isSearchHidden && (
              <SearchInput
                name={SEARCH_NAME_FIELD}
                value={searchValue || ''}
                placeholder={t(
                  placeholder || 'form_placeholder_type_to_search'
                )}
                onChange={handleSearch}
              />
            )}
            <DropdownMenu $isSearchHidden={isSearchHidden}>
              {renderDropdownItems(dataSearch || data)}
            </DropdownMenu>
          </DropdownMenuWrap>
        )}
      </DropdownStyled>
      {validation[field.name] && (
        <FormControlFeedback>{t(validation[field.name])}</FormControlFeedback>
      )}
    </CustomSearchDropdownStyled>
  );
};

const CustomSearchDropdownStyled = styled.div`
  margin-bottom: 32px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    ${(props) =>
      props.$isClearBoth &&
      `
        clear: both;
      `}
  }
`;
const DropdownStyled = styled(Dropdown)`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 44px;
  height: fit-content;
  background: var(--ds-c-white);
  border: ${(props) =>
    props.$isInvalid ? '1px solid var(--ds-c-red)' : '1px solid #eef2f5'};
  border-radius: 12px;
  background: ${(props) =>
    props.$isDisabled ? '#eef2f5' : 'var(--ds-c-white)'};
`;
const DropdownMenuWrap = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  width: 100%;
  min-height: ${(props) => props.styles?.height || '44px'};
  height: fit-content;
  background: #fff;
  box-shadow: var(--ds-bs-4);
  position: absolute;
  inset: 0px auto auto 0px !important;
  border-radius: ${(props) => props.styles?.borderRadius || '12px 12px 0 0'};
  transform: ${(props) =>
    props.styles?.transform || 'translate(0px, 46px) !important'};

  & input {
    border-radius: ${(props) => props.styles?.borderRadius || '12px 12px 0 0'};
  }
`;
const DropdownMenu = styled(Dropdown.Menu)`
  padding: 0;
  border: none;
  max-height: 160px;
  border-top: 1px solid #eef2f5;
  border-radius: ${(props) =>
    props.$isSearchHidden ? '12px' : '0 0 12px 12px'};
  width: 100%;
  overflow: ${(props) => (props.$isSearchHidden ? 'hidden' : 'scroll')};
  background: var(--ds-c-white);
  margin-top: -3px !important;
  box-shadow: var(--ds-bs-3) !important;
  inset: 0px auto auto 0px !important;
  transform: translate(0px, 46px) !important;

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ds-c-grey-hover);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
`;
const DropdownItem = styled(Dropdown.Item)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  display: flex;
  align-items: center;
  padding: 10px;
  text-transform: uppercase;
  min-height: 40px;
  height: fit-content;
  white-space: break-spaces;
  background: var(--ds-c-white);

  a:first-child {
    border-radius: 0.375rem 0.375rem 0 0;
  }

  &:hover {
    background: ${(props) =>
      props.$isReceiversEmpty ? 'var(--ds-c-white)' : 'var(--ds-c-grey-light)'};
    cursor: ${(props) => (props.$isReceiversEmpty ? 'default' : 'pointer')};
  }

  &:active {
    color: var(--ds-c-grey-dark);
  }
`;
const SearchInput = styled.input`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: var(--ds-c-grey-dark);
  height: 44px;
  border: none;
  border-radius: 12px 12px 0 0;
  background: var(--ds-c-white);
  width: 100%;
  padding: 10px;

  &:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }

  &:invalid,
  &:invalid:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    background-image: none !important;
  }

  &::placeholder {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: initial;
    color: var(--ds-c-grey-hover);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const NoItemText = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #b0b6bd;
  text-transform: initial;

  margin: 0px;
  padding: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ButtonToggle = styled.a`
  font-family: var(--ff-primary);
  line-height: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) =>
    props.$isPlaceholder ? '#b0b6bd' : 'var(--ds-c-grey-dark)'};
  color: ${(props) => props.$isDisabled && 'var(--ds-c-grey-neutral)'};
  text-decoration: none;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  background: transparent;
  outline: ${(props) =>
    props.$isShowDropdown &&
    !props.$isInvalid &&
    '1px solid var(--ds-c-grey-dark) !important'};
  outline: ${(props) => props.style?.outline && props.style?.outline};
  border-radius: 12px;
  padding: 8px 10px;
  padding-right: 32px;
  width: 100%;
  min-height: 44px;
  height: fit-content;
  flex-wrap: wrap;
  gap: 4px;
  cursor: ${(props) => props.$isDisabled && 'not-allowed'};

  & p {
    margin: 0;
    padding: 0;
  }

  &:hover {
    background: transparent;
    border: none;
    color: ${(props) =>
      props.$isPlaceholder ? '#b0b6bd' : 'var(--ds-c-grey-dark-hover)'};
    color: ${(props) => props.$isDisabled && 'var(--ds-c-grey-neutral)'};
  }

  &:focus {
    background-color: transparent;
    border: none !important;
    outline: none;
    box-shadow: none;
  }

  &:active {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  & svg {
    position: absolute;
    right: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ds-c-grey-hover);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;
const Label = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--ds-c-grey-dark);

  margin-bottom: 8px;

  & span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const FormControlFeedback = styled.span`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

export default CustomSearchDropdown;
