/* eslint-disable no-unused-vars */
import ButtonCommon from 'components/common/ButtonCommon';
import { DESKTOP_MIN_WIDTH } from 'constants';
import { FX_ADD_RECEIVER_TOTAL_NUMBER_OF_STEPS } from 'hooks/form-add-receiver/useAddReceiver';
import useFormAddTransactionStore from 'hooks/form-add-transaction/useFormAddTransactionStore';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import ProcessSteps from '../ProcessSteps/ver2';

const BodySectionHeader = ({
  titleBodySection,
  titleSection,
  CalculatorComponent = null,
  onToggleCancelPopup,
  isDisabled,
}) => {
  const { t } = useLang();
  const { isMobile, isTablet } = useDetectDevice();
  const { addTransactionCurrentStep: currentStep } =
    useFormAddTransactionStore();

  const renderTitleCancelSection = () => (
    <TitleCancel>
      <TitleBodySection>{titleBodySection}</TitleBodySection>
      <ButtonCommon
        value={t('button_cancel')}
        color="var(--ds-c-blue-hyperlink-default)"
        background="var(--ds-c-white)"
        isFill={false}
        // iconSrc={CloseIcon}
        styles={{
          paddingInline: 0,
          fontWeight: 500,
          height: '29px',
        }}
        buttonIconStyles={{
          gap: '4px',
        }}
        onClick={onToggleCancelPopup}
      />
    </TitleCancel>
  );
  const renderBodyHeader = () => {
    if (isMobile || isTablet)
      return (
        <TitleProcessStepsSection>
          {renderTitleCancelSection()}
          {currentStep <= FX_ADD_RECEIVER_TOTAL_NUMBER_OF_STEPS && (
            <ProcessSteps isPreview={isDisabled} />
          )}
          <TitleSection>{titleSection}</TitleSection>
        </TitleProcessStepsSection>
      );
    return (
      <TitleProcessStepsSection>
        {renderTitleCancelSection()}
        {!isEmpty(CalculatorComponent) && CalculatorComponent}
        <TitleSection>{titleSection}</TitleSection>
      </TitleProcessStepsSection>
    );
  };

  return <>{renderBodyHeader()}</>;
};

const TitleProcessStepsSection = styled.div``;
const TitleBodySection = styled.h2`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-blue-disabled);
  margin-bottom: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    color: var(--ds-c-grey-dark);
  }
`;
const TitleCancel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 24px;
  }
`;
const TitleSection = styled.h1`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--ds-c-green-default);
  margin-bottom: 16px;
  padding-block: 8px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 20px;
    height: 36px;
    border-bottom: 1px solid var(--ds-c-blue-remox-light);
  }
`;

export default BodySectionHeader;
