/* eslint-disable no-unused-vars */
import RadioCheckedIcon from 'assets/icons/radio-checked-icon.svg';
import RadioDisableIcon from 'assets/icons/radio-disable-icon.svg';
import RadioboxHHMTIcon from 'assets/icons/radiobox-hhmt-icon.svg';
import RadioboxRemoxIcon from 'assets/icons/radiobox-remox-icon.svg';
import TooltipCommon from 'components/common/TooltipCommon';
import TooltipCommonMobile from 'components/common/TooltipCommon/mobile';
import {
  BUSINESS_OF_RECEIVER_TYPE,
  DESKTOP_MIN_WIDTH,
  EDIT_RECEIVER_FISTNAME_NAME,
  EDIT_RECEIVER_FULLNAME_NAME,
  EDIT_RECEIVER_GENDER_NAME,
  EDIT_RECEIVER_LASTNAME_NAME,
  EDIT_RECEIVER_MOBILE_NAME,
  EDIT_RECEIVER_PERSONAL_DETAILS_SELECT_TYPE_OF_RECEIVER_FIELD,
  EDIT_RECEIVER_PHONE_NAME,
  EDIT_RECEIVER_RELATIONSHIP_NAME,
  EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME,
  METHOD_BANK,
} from 'constants';
import useReceiver from 'hooks/receiver/useReceiver';
import { useDetectDevice } from 'hooks/useDetectDevice';
import useLang from 'hooks/useLang';
import useTheme from 'hooks/useTheme';
import { isFunction } from 'lodash';
import { useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';
import { domUtils } from 'utils';

const FormInputSelectTypeOfReceiverRadio = ({
  countryConfig,
  information,
  setInformation,
  validation,
  setValidation,
  isDisabled,
  isEdit = false,
  onClick,
}) => {
  const { t } = useLang();
  const { isHHMTTheme } = useTheme();
  const { isMobile, isTablet } = useDetectDevice();

  const { dmList } = countryConfig || {};

  const validRef = useRef(null);

  const isReceiverOfIndividualType =
    information[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME] === 1;
  const isReceiverOfBusinessType =
    information[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME] === 2;

  const { isCreateBusinessReceiver, isCreateIndividualReceiver } =
    useReceiver();

  const checkReceiverOfBusinessTypeDisable = () => {
    const dmOfBank = dmList?.find((dm) => dm?.name === METHOD_BANK);
    const { currencies } = dmOfBank || {};
    return !dmOfBank || currencies?.some((c) => !c?.businessReceiver);
  };
  const isReceiverOfBusinessTypeDisable = checkReceiverOfBusinessTypeDisable();

  useEffect(() => {
    if (validation[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME]) {
      validRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validation]);

  const handleOnChange = (value) => {
    const newInformation = {
      ...information,
      [EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME]: value,
      [EDIT_RECEIVER_FISTNAME_NAME]: '',
      [EDIT_RECEIVER_LASTNAME_NAME]: '',
      [EDIT_RECEIVER_FULLNAME_NAME]: '',
      [EDIT_RECEIVER_GENDER_NAME]: value === 2 ? BUSINESS_OF_RECEIVER_TYPE : '',
      [EDIT_RECEIVER_MOBILE_NAME]: '',
      [EDIT_RECEIVER_PHONE_NAME]: '',
      [EDIT_RECEIVER_RELATIONSHIP_NAME]: '',
    };
    setInformation(newInformation);

    const newValidation = {
      ...validation,
      [EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME]: '',
      [EDIT_RECEIVER_FISTNAME_NAME]: '',
      [EDIT_RECEIVER_LASTNAME_NAME]: '',
      [EDIT_RECEIVER_FULLNAME_NAME]: '',
      [EDIT_RECEIVER_GENDER_NAME]: '',
      [EDIT_RECEIVER_MOBILE_NAME]: '',
      [EDIT_RECEIVER_PHONE_NAME]: '',
      [EDIT_RECEIVER_RELATIONSHIP_NAME]: '',
    };
    setValidation(newValidation);
  };

  const renderFormCheckRadio1 = () => {
    if (isEdit && isReceiverOfBusinessType) return null;

    const handleFormCheckRadio = () => {
      if (isDisabled || isCreateIndividualReceiver) return;

      if (isCreateBusinessReceiver && isFunction(onClick)) {
        onClick(1);
        return;
      }

      handleOnChange(1);
    };

    const formCheckRadioClassName1 =
      EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME + '1';

    return (
      <>
        <FormCheck
          className={`${formCheckRadioClassName1} ${
            isReceiverOfIndividualType && 'active'
          }`}
          inline
          type="radio"
          label={t('label_individual_receiver')}
          name={EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME}
          checked={isReceiverOfIndividualType}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
          $isDisabled={isDisabled}
          readOnly={isReceiverOfIndividualType}
        />
        <ButtonFormCheck
          $width={
            domUtils.getOffsetWidthByClassName(formCheckRadioClassName1) + 44
          }
          onClick={handleFormCheckRadio}
        >
          {t('label_individual_receiver')}
        </ButtonFormCheck>
      </>
    );
  };

  const renderFormCheckRadio2 = () => {
    if (isEdit && isReceiverOfIndividualType) return null;

    const handleFormCheckRadio = () => {
      if (
        isDisabled ||
        isReceiverOfBusinessTypeDisable ||
        isCreateBusinessReceiver
      )
        return;

      if (isCreateIndividualReceiver && isFunction(onClick)) {
        onClick(2);
        return;
      }

      handleOnChange(2);
    };

    const formCheckRadioClassName2 =
      EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME + '2';

    return (
      <>
        <FormCheck
          className={`${formCheckRadioClassName2} ${
            isReceiverOfBusinessType && 'active'
          }`}
          inline
          type="radio"
          label={t('label_business_receiver')}
          name={EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME}
          checked={isReceiverOfBusinessType}
          $checkboxSrc={isHHMTTheme ? RadioboxHHMTIcon : RadioboxRemoxIcon}
          $radioDisableSrc={
            isReceiverOfBusinessTypeDisable ? RadioDisableIcon : null
          }
          $isDisabled={isDisabled}
          $isTextDisabled={isReceiverOfBusinessTypeDisable}
          style={{ marginRight: 0 }}
          readOnly={isReceiverOfBusinessType}
        />
        <ButtonFormCheck
          $width={domUtils.getOffsetWidthByClassName(formCheckRadioClassName2)}
          onClick={handleFormCheckRadio}
        >
          {t('label_business_receiver')}
        </ButtonFormCheck>
        {isReceiverOfBusinessTypeDisable && (
          <Tooltip>
            {isMobile || isTablet ? (
              <TooltipCommonMobile
                text={t('form_business_receiver_type_tooltip')}
                name={EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME}
                label={t(
                  EDIT_RECEIVER_PERSONAL_DETAILS_SELECT_TYPE_OF_RECEIVER_FIELD.label
                )}
              />
            ) : (
              <TooltipCommon text={t('form_business_receiver_type_tooltip')} />
            )}
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <FormGroupStyled>
      <FormLabel onClick={(e) => e.preventDefault()}>
        {t(EDIT_RECEIVER_PERSONAL_DETAILS_SELECT_TYPE_OF_RECEIVER_FIELD.label)}
        <span>*</span>
      </FormLabel>
      {renderFormCheckRadio1()}
      {renderFormCheckRadio2()}
      {Boolean(validation[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME]) && (
        <FormControlFeedback ref={validRef} hidden={false} type="invalid">
          {t(validation[EDIT_RECEIVER_SELECT_TYPE_OF_RECEIVER_NAME])}
        </FormControlFeedback>
      )}
    </FormGroupStyled>
  );
};

const FormGroupStyled = styled(Form.Group)`
  margin-bottom: 32px;
  position: relative;
  display: table;
  width: 100%;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    margin-bottom: 34px;
    clear: both;
  }
`;
const FormLabel = styled(Form.Label)`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;

  display: block;
  color: var(--ds-c-grey-dark);
  margin-bottom: 16px;

  span {
    color: var(--c-required);
  }

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 16px;
  }
`;
const FormCheck = styled(Form.Check)`
  margin: 0;
  margin-right: 44px;

  & label {
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    color: ${(props) =>
      props.$isTextDisabled
        ? 'var(--ds-c-grey-neutral)'
        : 'var(--ds-c-grey-dark)'};
    margin-left: 0px;
    cursor: ${(props) =>
      props.$isDisabled ? 'not-allowed !important' : 'pointer !important'};
    user-select: none;

    @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  & input {
    border: none;
    border-radius: 2px;
    background-image: ${(props) =>
      props.$radioDisableSrc
        ? `url(${props.$radioDisableSrc}) !important`
        : `url(${props.$checkboxSrc}) !important`};
    background-position: center !important;
    width: 16px;
    height: 16px;
    cursor: ${(props) =>
      props.$isDisabled ? 'not-allowed !important' : 'pointer !important'};

    &:focus,
    &:active,
    &:checked {
      background-color: transparent;
      border: none;
      border-radius: 2px;
      box-shadow: none !important;
      filter: none !important;
    }

    &:checked[type='radio'] {
      background-image: url(${RadioCheckedIcon}) !important;
      background-position: center !important;
    }
  }

  &.active {
    label {
      font-weight: 700;
    }
  }
`;
export const ButtonFormCheck = styled.button`
  margin-left: ${(props) => `-${props.$width}px`};
  width: ${(props) => `${props.$width}px`};
  opacity: 0;
  border: none;
`;
const FormControlFeedback = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  color: var(--ds-c-red);
  position: absolute;
  margin-top: 4px;
  margin-bottom: 0px;

  @media screen and (min-width: ${DESKTOP_MIN_WIDTH}px) {
    font-size: 14px;
    line-height: 17px;
  }
`;

const Tooltip = styled.div`
  display: inline-flex;
`;

export default FormInputSelectTypeOfReceiverRadio;
